<template>
  <div
    ref="ranking"
    class="histogram-container"
  >
    <div class="toy-item" v-for="(item, i) in list.slice(0, 6)">
      <div class="top-icon">
        <img v-if="i==0" :src="require('@/assets/images/toy_top_1.png')" />
        <img v-if="i==1" :src="require('@/assets/images/toy_top_2.png')" />
        <img v-if="i==2" :src="require('@/assets/images/toy_top_3.png')" />
      </div>
      <popper
    trigger="hover"
    :options="{
      placement: 'left-end',
      modifiers: { offset: { offset: '0,10px' } }
    }">
      <div class="big-img-container">
        <img class="big-img" :src="item.picture" />
        <div class="big-img-name">{{item.name}}</div>
      </div>
      <div class="toy-picture" slot="reference">
        <img :src="item.picture" />
      </div>
      </popper>
      <div class="toy-name-container" :title="item.name">
        <div class="toy-name">
          {{item.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ListPic} from "@/libs/utils.js";
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
export default {
  components: {
    'popper': Popper,
  },
  props: {
    //数据
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {

    };
  },
  computed: {

  },
  
  methods: {
    ListPic,
    initData() {
      
    }
  }
};
</script>
<style lang="scss" scoped>
.histogram-container {
  width: calc(100% - 5px);
  height: calc(100% - 24px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: 5px;
  margin-top: 6px;
  .toy-item-placeholder{
    width: 120px;
  }
  .toy-item{
    position: relative;
    margin-top: 15px;
    margin-left: 20px;
    width: 120px;
    display: flex;
    flex-direction: column;
    .top-icon{
      position: absolute;
      top: 0;
      left: -6px;
      img{
        width: 29px;
        height: 34px;
      }
    }
    .big-img-container{
      z-index: 1;
      background: #FFFFFF;
      border-radius: 8px;
      overflow: hidden;
      .big-img{
        width: 400px;
        height: 300px;
        object-fit: contain;
      }
      .big-img-name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
        padding: 20px 20px;
        font-weight: bold;
      }
    }
    
    .toy-picture{
      width: 120px;
      height: 70px;
      background: #FFFFFF;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .toy-name-container{
      margin-top: 8px;
      height: 13px;
      width: 100%;
      position: relative;
      .toy-name{  //先放大1倍在缩小一倍
        position:absolute;
        left: 0;
        top: -6px;
        width: 200%;
        font-size: 24px;
        line-height: 26px;
        font-weight: 400;
        color: #FFFFFF;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        transform: scale(0.5);
        transform-origin: left;
      }
    }
  }
}
</style>