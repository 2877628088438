<template>
  <div
    class="select"
    @click="open=!open"
  >
    <div class="select-text">{{selectText}}</div>
    <div :class="{'select-arrow': true, 'arrow-up': open, 'arrow-down': !open}"></div>
    <div
      class="select-main"
      v-if="open"
      @click.stop
    >
      <div
        class="select-item"
        v-for="(item, i) in option"
        :key="i"
        @click="selectItem(item)"
      >{{item.label}}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    //选项，格式[{value: 12, label: '选项一'}, {value: 2, label: '选项二'}],
    option: {
      type: Array,
      default: () => {
        return [];
      }
    },
    //选中的value
    value: {
      type: [String, Number],
      default: '',
    },
    //没选中显示的文字
    placeholder: {
      type: String,
      default: '请选择',
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    select: {
      set(val) {
        this.$emit('input', val);
        this.$emit('change', val);
      },
      get() {
        return this.value;
      }
    },
    //选中的文本
    selectText() {
      let index = this.option.map(item => item.value).indexOf(this.select);
      if (index != -1) {
        return this.option[index]['label'];
      } else {
        return this.placeholder;
      }
    },
  },
  mounted() {

  },
  methods: {
    selectItem(item) {
      this.select = item.value;
      this.open = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.select {
  position: relative;
  min-width: 90px;
  box-sizing: border-box;
  height: 24px;
  background: #1487de;
  display: flex;
  align-items: center;

  .select-text {
    flex: 1;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 7px;
    cursor: pointer;
  }
  .select-arrow {
    width: 5px;
    height: 5px;
    margin-right: 9px;
  }
  .arrow-up {
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    transform: rotate(45deg);
    margin-top: 3px;
  }
  .arrow-down {
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    transform: rotate(225deg);
    margin-top: -3px;
  }
  .select-main {
    z-index: 2;
    position: absolute;
    top: 24px;
    right: 0px;
    background: #1487de;
    min-width: 90px;
    // box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    .select-item {
      cursor: pointer;
      font-size: 14px;
      height: 24px;
      padding-left: 10px;
      padding-right: 9px;
      margin: 2px 0;
    }
  }
}
</style>