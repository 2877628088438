/**
 * 数据分页加载 
 * @param {*} pageSize 每页大小
 */
 export function useDataPage(pageSize = 5){
  let startIndex = 0;
  let data = [];
  function init(tmpData){
    startIndex = 0;
    data = tmpData;
  }
  /**
   * 下一页
   * @param {*} data 数组数据
   */
  function next(size=null){
    if(size == null) size = pageSize; 
    // console.log(startIndex, startIndex + size);
    let ret = data.slice(startIndex, startIndex + size);
    if(ret.length < pageSize && startIndex > 0){ //没有数据且不是第一页就加载第一页
      startIndex = 0;
      ret = [...ret, ...next(pageSize - ret.length)];
    }else{
      startIndex += size;
    }
    return ret;
  }
  return { init, next };
}