/**
 * 定时任务
 */
export function useTimer(){
  let timer = null;
  let callback = null;
  let s = 0;
  function init(tmpCallback, tmpS=3){
    callback = tmpCallback;
    s = tmpS;
  }
  /**
   * 启动
   * @param {*} callback 定时回调
   * @param {*} s 秒数 
   */
  function start(){
    if(timer != null) {
      destroy();
    }
    if(typeof callback === 'function'){
      timer = setInterval(() => {
        callback();
      }, s * 1000); //x秒执行一次
    }else{
      console.error('缺少回调函数');
    }
  }
  //释放
  function destroy() {
    if (timer != null) {
      clearInterval(timer);
      timer = null;
    }
  }

  function visibilityChangeHandle() {
    if (document.hidden) {
      destroy();
      // console.log('隐藏销毁');
    } else {
      start();
      // console.log('显示建立');
    }
  }

  /**
   * 添加隐藏处理事件
   */
  function addEvent(){
    document.addEventListener("visibilitychange", ()=>{
      visibilityChangeHandle();
    });
  }

  /**
   * 移除隐藏处理事件
   */
  function removeEvent(){
    document.removeEventListener("visibilitychange",()=>{
      visibilityChangeHandle();
    });
  }
  return { init, start, destroy, addEvent, removeEvent };
}