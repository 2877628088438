// 省份数据，name对应，chinaGeo的name
const chinaProvincialData = {
    '上海市': {'point':[121.472644, 31.231706], 'name': '上海',},
    '云南省': {'point':[102.712251, 25.040609], 'name': '云南',},
    '内蒙古自治区': {'point':[111.670801, 40.818311], 'name': '内蒙古',},
    '北京市': {'point':[116.405285, 39.904989], 'name': '北京',},
    '台湾省': {'point':[121.509062, 25.044332], 'name': '台湾',},
    '吉林省': {'point':[125.3245, 43.886841], 'name': '吉林',},
    '四川省': {'point':[104.065735, 30.659462], 'name': '四川',},
    '天津市': {'point':[117.190182, 39.125596], 'name': '天津',},
    '宁夏回族自治区': {'point':[106.278179, 38.46637], 'name': '宁夏',},
    '安徽省': {'point':[117.283042, 31.86119], 'name': '安徽',},
    '山东省': {'point':[117.000923, 36.675807], 'name': '山东',},
    '山西省': {'point':[112.549248, 37.857014], 'name': '山西',},
    '广东省': {'point':[113.280637, 23.125178], 'name': '广东',},
    '广西壮族自治区': {'point':[108.320004, 22.82402], 'name': '广西',},
    '新疆维吾尔自治区': {'point':[87.617733, 43.792818], 'name': '新疆',},
    '江苏省': {'point':[118.767413, 32.041544], 'name': '江苏',},
    '江西省': {'point':[115.892151, 28.676493], 'name': '江西',},
    '河北省': {'point':[114.502461, 38.045474], 'name': '河北',},
    '河南省': {'point':[113.665412, 34.757975], 'name': '河南',},
    '浙江省': {'point':[120.153576, 30.287459], 'name': '浙江',},
    '海南省': {'point':[110.33119, 20.031971], 'name': '海南',},
    '湖北省': {'point':[114.298572, 30.584355], 'name': '湖北',},
    '湖南省': {'point':[112.982279, 28.19409], 'name': '湖南',},
    '澳门特别行政区': {'point':[113.54909, 22.198951], 'name': '澳门',},
    '甘肃省': {'point':[103.823557, 36.058039], 'name': '甘肃',},
    '福建省': {'point':[119.306239, 26.075302], 'name': '福建',},
    '西藏自治区': {'point':[91.132212, 29.660361], 'name': '西藏',},
    '贵州省': {'point':[106.713478, 26.578343], 'name': '贵州',},
    '辽宁省': {'point':[123.429096, 41.796767], 'name': '辽宁',},
    '重庆市': {'point':[106.504962, 29.533155], 'name': '重庆',},
    '陕西省': {'point':[108.948024, 34.263161], 'name': '陕西',},
    '青海省': {'point':[101.778916, 36.623178], 'name': '青海',},
    '香港特别行政区': {'point':[114.173355, 22.320048], 'name': '香港',},
    '黑龙江省': {'point':[126.642464, 45.756967], 'name': '黑龙江',},
};
export default chinaProvincialData;
