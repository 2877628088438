<template>
  <div class="scale">
    <div class="scale-title"></div>
    <div class="scale-body">
      <div class="scale-line">
        <div class="scale-icon"></div>
        <div class="scale-text-container"><div class="scale-text">工业企业数量：共1410余家；其中木玩1093家</div></div>
      </div>
      <div class="scale-line">
        <div class="scale-icon"></div>
        <div class="scale-text-container"><div class="scale-text">规上工业企业数量：共98家；其中木玩54家</div></div>
      </div>
      <div class="scale-line">
        <div class="scale-icon"></div>
        <div class="scale-text-container"><div class="scale-text">电商企业数量：共889家（国内710家），其中跨境电商企业179家</div></div>
      </div>
      <div class="scale-line">
        <div class="scale-icon"></div>
        <div class="scale-text-container"><div class="scale-text">木玩总产值：2022年木玩产值78.1亿元</div></div>
      </div>
      <div class="scale-line">
        <div class="scale-icon"></div>
        <div class="scale-text-container"><div class="scale-text">网零指标：2022年1-12月，云和县累计实现网络零售额36.9亿元</div></div>
      </div>
      <div class="scale-line">
        <div class="scale-icon"></div>
        <div class="scale-text-container"><div class="scale-text">社零指标：2022年1-12月实现社零总额46.6亿元</div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToyRealtime } from "@/api/index.js";
import { useTimer } from "@/libs/timer.js";
import { useDataPage } from "@/libs/dataPage.js";
import config from "@/config/index.js";
export default {
  components: {

  },
  props: {
    
  },
  data() {
    return {
      config,
    };
  },
  computed: {

  },
  created(){
    if(!config.anim){ //没有动画用切换渲染数据
      this.timer = useTimer();
      this.dataPage = useDataPage(4);
      this.timer.addEvent();
    }
  },
  mounted() {
    
  },
  beforeDestroy() {
    if(!config.anim){
      //销毁定时器
      this.timer.destroy();
      this.timer.removeEvent();
    }
  },
  methods: {
    initData(refresh){
      // let postData = {refresh: refresh};
      // if(!config.anim) postData['pageSize'] = 50;
      // getToyRealtime(postData).then(res => {
      //   if (res.code == 0) {
      //     let tmp = this.selectToyData;
      //     this.selectToyData = [];

      //     this.$nextTick(() => {
      //       if (refresh == 0) {
      //         this.selectToyData = res.data.data;
      //       } else {
      //         this.selectToyData = this.handleTimitArrayTotal(tmp, res.data.data);
      //       }
      //       if(!config.anim){
      //         this.dataPage.init(this.selectToyData); 
      //         this.timer.init(()=>{
      //           this.selectToyDataOnePage = this.dataPage.next();
      //         });
      //         this.selectToyDataOnePage = this.dataPage.next();
      //         this.timer.start();
      //       }
      //     })
      //   }
      // })
    },
  }
};
</script>
<style lang="scss" scoped>
.scale {
  width: 620px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .scale-title {
    flex-shrink: 0;
    height: 42px;
    background: url(~@/assets/images/scale_title.png) no-repeat;
    background-size: 100% 100%;
  }
  .scale-body {
    flex: 1;
    overflow: hidden;
    background: url(~@/assets/images/bottom_1.png)
      rgba(18, 42, 62, 0.4) no-repeat center bottom;
    border: 0.5px solid rgba(94, 204, 255, 0.4);
    background-size: 100%;
    padding-left: 31px;
    padding-right: 10px;
    padding-top: 25px;
    box-sizing: border-box;
    .scale-line{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .scale-icon{
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #5DCBFF;
        flex-shrink: 0;
        margin-right: 21px;
      }
      .scale-text-container{
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        color: #FFFFFF;
        display: inline-block;
        // transform: scale(0.875);
        // transform-origin: top left;
        transform: scale(0.5);
        .scale-text{
          transform: scale(2);
        }
      }
    }
  }
}
</style>