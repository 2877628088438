<template>
  <div class="container">
    <!-- 顶部大标题 -->
    <div class="main-title"></div>
    <!-- 统计数 -->
    <div class="statistics-num">
      <div
        class="statistics-num-item"
        :style="{'background-image':'url('+require('@/assets/images/statistics_num/1.png')+')'}"
      >
        <div class="statistics-num-item-num">
          <animated-number
            v-if="config.anim"
            :value="toyTotal"
            :formatValue="numberFormat"
            :round="true"
            :duration="300"
          />
          <template v-else>{{numberFormat(toyTotal)}}</template>
        </div>
        <div class="statistics-num-item-text">产品总数</div>
      </div>
      <div
        class="statistics-num-item"
        :style="{'background-image':'url('+require('@/assets/images/statistics_num/2.png')+')'}"
      >
        <div class="statistics-num-item-num">
          <animated-number
            v-if="config.anim"
            :value="factoryTotal"
            :formatValue="numberFormat"
            :round="true"
            :duration="300"
          />
          <template v-else>{{numberFormat(factoryTotal)}}</template>
        </div>
        <div class="statistics-num-item-text">工厂总数</div>
      </div>
      <div
        class="statistics-num-item"
        :style="{'background-image':'url('+require('@/assets/images/statistics_num/3.png')+')'}"
      >
        <div class="statistics-num-item-num">
          <animated-number
            v-if="config.anim"
            :value="userTotal"
            :formatValue="numberFormat"
            :round="true"
            :duration="300"
          />
          <template v-else>{{numberFormat(userTotal)}}</template>
        </div>
        <div class="statistics-num-item-text">采购商总数</div>
      </div>
      <div
        class="statistics-num-item"
        :style="{'background-image':'url('+require('@/assets/images/statistics_num/4.png')+')'}"
      >
        <div class="statistics-num-item-num">
          <animated-number
            v-if="config.anim"
            :value="toyBrowseTotal"
            :formatValue="numberFormat"
            :round="true"
            :duration="300"
          />
          <template v-else>{{numberFormat(toyBrowseTotal)}}</template>
        </div>
        <div class="statistics-num-item-text">浏览产品总数</div>
      </div>
      <div
        class="statistics-num-item"
        :style="{'background-image':'url('+require('@/assets/images/statistics_num/5.png')+')'}"
      >
        <div class="statistics-num-item-num">
          <animated-number
            v-if="config.anim"
            :value="toySelectTotal"
            :formatValue="numberFormat"
            :round="true"
            :duration="300"
          />
          <template v-else>{{numberFormat(toySelectTotal)}}</template>
        </div>
        <div class="statistics-num-item-text">选中产品总数</div>
      </div>
      <!-- <div
        class="statistics-num-item"
        :style="{'background-image':'url('+require('@/assets/images/statistics_num/6.png')+')'}"
      >
        <div class="statistics-num-item-num">
          <animated-number
            v-if="config.anim"
            :value="chooseCount"
            :formatValue="numberFormat"
            :round="true"
            :duration="300"
          />
          <template v-else>{{numberFormat(chooseCount)}}</template>
        </div>
        <div class="statistics-num-item-text">国外客户选品数</div>
      </div> -->
    </div>
    <!-- 主要内容 -->
    <div class="main-container">
      <!-- 左侧 -->
      <div class="main-left">
        <!-- 访客大地图 -->
        <div class="map-container">
          <div class="container-title">
            <div class="map-title-main">
              <div class="map-title-left">访客大地图</div>
              <!-- <div class="map-title-right">全球共有{{visitCountryTotal}}个国家的国外采购商访问{{websiteTotal}}个公司独立站</div> -->
            </div>
          </div>
          <caller-map ref="callerMap" @updateCountryTotal="(num)=> {visitCountryTotal=num;}"></caller-map>
        </div>
        <!-- 实时区 -->
        <div class="actual-time-container">
          <div class="actual-time-title">
            <div class="container-title">实时区</div>
          </div>
          <div class="actual-time-main">
            <!-- 选中产品 -->
            <ActualTimeSelectToy ref="ActualTimeSelectToy"></ActualTimeSelectToy>
            <!-- 规模 -->
            <ActualTimeScale ref="ActualTimeScale"></ActualTimeScale>
          </div>

        </div>
      </div>
      <!-- 右侧 -->
      <div class="main-right">
        <!-- 排行区 -->
        <div class="ranking-container">
          <ranking
            ref="ranking"
            class="ranking-main"
          ></ranking>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// https://echarts.apache.org/zh/option.html 图表的文档
// http://ppchart.com/ 一些参数demo
import AnimatedNumber from "animated-number-vue";
import CallerMap from "./components/caller-map.vue";
import Ranking from "./components/ranking.vue";
import ActualTimeSelectToy from "./components/actual-time-select-toy.vue";
import ActualTimeScale from "./components/actual-time-scale.vue";
import { getTotal } from "@/api/index.js";
import { time } from 'echarts';
import config from "@/config/index.js";
export default {
  name: "index",
  components: {
    CallerMap,
    Ranking,
    AnimatedNumber,
    ActualTimeSelectToy,
    ActualTimeScale,
  },
  data() {
    return {
      config,
      toyTotal: 0,      //产品总数
      factoryTotal: 0,  //工厂总数
      userTotal: 0,     //采购商总数
      // chooseCount: 0,     //国外客户选品数
      toyBrowseTotal: 0,    //浏览产品总数
      toySelectTotal: 0,    //选中产品总数
      websiteTotal: 0,  //独立网站数
      visitCountryTotal: 0, //访问国家数
      
      timer: null,  //定时器
    };
  },
  mounted() {
    this.loadStatisNum();
    this.loadActualTime(0);
    //初始化定时器
    this.initTimer();
    document.addEventListener("visibilitychange", ()=>{
        this.visibilityChangeHandle();
    });
  },
  beforeDestroy() {
    //销毁定时器
    this.destroyTimer();
    document.removeEventListener("visibilitychange",()=>{
        this.visibilityChangeHandle();
    });
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`

    //销毁定时器
    this.destroyTimer();
    document.removeEventListener("visibilitychange",()=>{
        this.visibilityChangeHandle();
    });
    next();
  },
  methods: {
    // https://blog.csdn.net/weixin_47711357/article/details/110945362?spm=1001.2101.3001.6661.1&utm_medium=distribute.pc_relevant_t0.none-task-blog-2%7Edefault%7ECTRLIST%7Edefault-1-110945362-blog-106713987.pc_relevant_aa&depth_1-utm_source=distribute.pc_relevant_t0.none-task-blog-2%7Edefault%7ECTRLIST%7Edefault-1-110945362-blog-106713987.pc_relevant_aa&utm_relevant_index=1
    // 页面隐藏时清除定时任务，页面显示时重新开启定时任务，上面的连接描述标签页不是当前看的标签页内存会持续涨不会释放
    visibilityChangeHandle() {
      if (document.hidden) {
        this.destroyTimer();
      } else {
        this.initTimer();
      }
    },
    initTimer() {
      if (this.timer != null) {
        this.destroyTimer();
      }
      this.timer = setInterval(() => {
        this.loadData();
      }, 30000); //30秒执行一次
    },
    destroyTimer() {
      if (this.timer != null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    //定时加载所有数据
    loadData() {
      if(this.$route.name != 'index') return false;
      this.loadStatisNum();
      this.loadActualTime(0);
      this.$refs.callerMap.loadData();
      this.$refs.ranking.loadData();
    },
    //加载总数
    loadStatisNum() {
      getTotal({}).then(res => {
        if (res.code == 0) {
          this.toyTotal = res.data.toy_total;      //产品总数
          this.factoryTotal = res.data.factory_total;  //工厂总数
          this.userTotal = res.data.client_total;     //采购商总数
          this.toySelectTotal = res.data.select_total;    //选中产品总数
          this.toyBrowseTotal = res.data.browse_total;    //浏览总数
          // this.chooseCount = res.data.chooseCount;   //国外客户选品数
          this.websiteTotal = 0;  //网站数
          this.$refs.callerMap.setWebsiteNum(this.websiteTotal);
          
        }
      });
    },
    //加载实时区数据，refresh：1获得30秒前的数据，0实时数据
    loadActualTime(refresh = 0) {
      this.$refs.ActualTimeSelectToy.initData(refresh);
      this.$refs.ActualTimeScale.initData(refresh);
    },
    
    numberFormat(num) {
      if(typeof(num) == 'undefined' || num == null) return num;
      let str = num.toString();
      return str.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  filters: {

  },
};
</script>
<style lang="scss" scoped>
.container {
  background: url(~@/assets/images/main_bg.png) no-repeat #000511;
  background-size: 100% 100%;
  object-fit: contain;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 40px;
  .main-title {
    width: 100%;
    height: 90px;
    background: url(~@/assets/images/main_title.png) no-repeat;
    background-size: 100% 100%;
  }
  .statistics-num {
    width: 1850px;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    .statistics-num-item {
      width: 330px;
      height: 104px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .statistics-num-item-num {
        margin-left: 25px;
        margin-top: 29px;
        font-size: 24px;
        line-height: 21px;
        font-weight: bold;
        color: #23c3ff;
      }
      .statistics-num-item-text {
        margin-left: 25px;
        margin-top: 10px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        color: #d4f0fe;
      }
    }
  }
  .main-container {
    width: 1850px;
    display: flex;
    margin-top: 16.8px;
    .container-title {
      height: 42px;
      margin-left: 24px;
      display: flex;
      align-items: center;

      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
    }
    .map-title-main{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .map-title-left{

      }
      .map-title-right{
        margin-right: 40px;
      }
    }
    .main-left {
      width: 1220px;
      margin-right: 20px;
      .map-container {
        width: 1220px;
        height: 464px;
        background: url(~@/assets/images/map_bg.png) no-repeat;
        background-size: 100% 100%;
      }
      .actual-time-container {
        margin-top: 18px;
        height: 338px;
        display: flex;
        flex-direction: column;
        .actual-time-title {
          width: 1219px;
          height: 42px;
          background: url(~@/assets/images/actual_combat_title_bg.png) no-repeat;
          background-size: 100% 100%;
          flex-shrink: 0;
        }
        .actual-time-main {
          box-sizing: border-box;
          margin: 0 3px;
          flex: 1;
          overflow: hidden;
          box-shadow: inset 0px 0px 13px 0px rgba(90, 225, 254, 0.41);
          padding-top: 12px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .main-right {
      flex: 1;
      .ranking-container {
        width: 610px;
        height: 820px;
        background: url(~@/assets/images/ranking_bg.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        .ranking-main {
          flex: 1;
        }
      }
    }
  }
}
</style>