//世界地图的区域数据
const worldData = {
    type: "FeatureCollection",
    crs: {
        type: "name",
        properties: {
            name: "urn:ogc:def:crs:OGC:1.3:CRS84",
        },
    },
    features: [
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [47.97822265625001, 7.9970703125],
                        [46.97822265625001, 7.9970703125],
                        [43.98378906250002, 9.008837890624989],
                        [43.482519531250006, 9.379492187499991],
                        [43.181640625, 9.879980468749991],
                        [42.84160156250002, 10.203076171874997],
                        [42.65644531250001, 10.6],
                        [42.92275390625002, 10.999316406249989],
                        [43.24599609375002, 11.499804687499989],
                        [43.85273437500001, 10.784277343749991],
                        [44.38652343750002, 10.430224609374989],
                        [44.94296875, 10.43671875],
                        [45.81669921875002, 10.835888671874997],
                        [46.565039062500006, 10.745996093749994],
                        [47.40498046875001, 11.174023437499997],
                        [48.01923828125001, 11.139355468749997],
                        [48.57255859375002, 11.320507812499997],
                        [48.938574218750006, 11.258447265624994],
                        [50.11005859375001, 11.529296875],
                        [50.79228515625002, 11.983691406249989],
                        [51.2548828125, 11.830712890624994],
                        [51.08427734375002, 11.335644531249997],
                        [51.140625, 10.656884765624994],
                        [51.031835937500006, 10.444775390624997],
                        [51.19296875, 10.554638671874997],
                        [51.390234375, 10.422607421875],
                        [50.93007812500002, 10.33554687499999],
                        [50.825, 9.428173828124997],
                        [50.10283203125002, 8.199804687499991],
                        [49.85205078125, 7.962548828124994],
                        [49.234960937500006, 6.77734375],
                        [49.04931640625, 6.173632812499989],
                        [47.97529296875001, 4.497021484374997],
                        [46.87880859375002, 3.28564453125],
                        [46.05117187500002, 2.475146484374989],
                        [44.92021484375002, 1.81015625],
                        [43.71757812500002, 0.857861328124997],
                        [41.97988281250002, -0.973046875],
                        [41.53271484375, -1.6953125],
                        [41.521875, -1.572265625],
                        [41.42695312500001, -1.449511718750003],
                        [41.24980468750002, -1.220507812500003],
                        [40.97871093750001, -0.870312500000011],
                        [40.964453125, 2.814648437499997],
                        [41.341796875, 3.20166015625],
                        [41.61347656250001, 3.590478515624994],
                        [41.88398437500001, 3.977734375],
                        [41.91533203125002, 4.031298828124989],
                        [42.02412109375001, 4.137939453125],
                        [42.85664062500001, 4.32421875],
                        [43.12568359375001, 4.644482421874997],
                        [43.58349609375, 4.85498046875],
                        [43.988867187500006, 4.950537109374991],
                        [44.940527343750006, 4.912011718749994],
                        [47.97822265625001, 7.9970703125],
                    ],
                ],
            },
            properties: {
                name: "索马里",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [9.579979133936737, 47.05856388629306],
                        [9.409458596647225, 47.02019676540292],
                        [9.46249431093294, 47.09010747968864],
                        [9.46249431093294, 47.19858962254578],
                        [9.527658197470123, 47.27026989773668],
                        [9.579979133936737, 47.05856388629306],
                    ],
                ],
            },
            properties: {
                name: "列支敦士登",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-8.683349609375, 27.77800740805682],
                        [-13.038761787013554, 27.81190166624856],
                        [-12.948925781249926, 27.914160156250034],
                        [-11.552685546874955, 28.31010742187496],
                        [-10.486474609374994, 29.06494140625],
                        [-10.200585937499994, 29.380371093750057],
                        [-9.667089843749949, 30.10927734375005],
                        [-9.652929687499977, 30.447558593750045],
                        [-9.875488281249943, 30.717919921874966],
                        [-9.80869140624992, 31.42460937499996],
                        [-9.347460937499932, 32.086376953124955],
                        [-9.245849609375, 32.572460937499955],
                        [-8.512841796874994, 33.25244140625003],
                        [-6.900976562499949, 33.96904296874999],
                        [-6.353125, 34.77607421875001],
                        [-5.924804687499943, 35.78579101562502],
                        [-5.277832031249943, 35.90273437500002],
                        [-5.252685546874972, 35.61474609374997],
                        [-4.628320312499966, 35.206396484375006],
                        [-4.329980468749937, 35.161474609375006],
                        [-3.693261718749994, 35.27998046874998],
                        [-3.394726562499926, 35.21181640625005],
                        [-2.972216796874989, 35.40727539062499],
                        [-2.839941406249949, 35.127832031249994],
                        [-2.731396484374955, 35.13520507812498],
                        [-2.636816406249977, 35.11269531250002],
                        [-2.423730468749994, 35.12348632812498],
                        [-2.219628906249966, 35.10419921874998],
                        [-1.795605468749926, 34.751904296874955],
                        [-1.67919921875, 33.31865234375002],
                        [-1.550732421874955, 33.073583984375006],
                        [-1.510009765625, 32.877636718749955],
                        [-1.45, 32.784814453124966],
                        [-1.352148437499977, 32.70336914062497],
                        [-1.29638671875, 32.67568359375002],
                        [-1.188232421875, 32.608496093750006],
                        [-1.111035156249983, 32.55229492187502],
                        [-1.065527343749949, 32.46831054687496],
                        [-1.16259765625, 32.399169921875],
                        [-1.275341796874983, 32.089013671874966],
                        [-2.863427734374937, 32.07470703124997],
                        [-2.930859374999926, 32.04252929687499],
                        [-2.988232421874983, 31.874218749999983],
                        [-3.01738281249996, 31.834277343750017],
                        [-3.439794921874949, 31.704541015624983],
                        [-3.604589843749949, 31.686767578125],
                        [-3.700244140624989, 31.70009765625005],
                        [-3.768164062499977, 31.689550781250034],
                        [-3.837109374999983, 31.512353515624994],
                        [-3.833398437499937, 31.197802734375045],
                        [-3.626904296874955, 31.000927734374983],
                        [-4.148779296874977, 30.8095703125],
                        [-4.322851562500006, 30.698876953124994],
                        [-4.52915039062492, 30.62553710937499],
                        [-4.778515624999926, 30.552392578124994],
                        [-4.968261718749943, 30.465380859375045],
                        [-5.061914062499937, 30.326416015625057],
                        [-5.180126953124955, 30.166162109374994],
                        [-5.293652343749983, 30.058642578125045],
                        [-5.44877929687496, 29.956933593750023],
                        [-6.00429687499999, 29.83125],
                        [-6.479736328124943, 29.82036132812499],
                        [-6.520556640624989, 29.659863281249983],
                        [-6.59775390624992, 29.578955078125006],
                        [-6.635351562499949, 29.568798828124983],
                        [-6.755126953125, 29.583837890625034],
                        [-6.855566406249949, 29.601611328125017],
                        [-7.142431640624949, 29.61958007812504],
                        [-7.427685546874983, 29.425],
                        [-7.485742187499994, 29.392236328124994],
                        [-8.659912109375, 28.718603515625063],
                        [-8.683349609375, 27.900390625],
                        [-8.683349609375, 27.77800740805682],
                    ],
                ],
            },
            properties: {
                name: "摩洛哥",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-13.038761787013554, 27.81190166624856],
                        [-8.683349609375, 27.77800740805682],
                        [-8.683349609375, 27.65644531250004],
                        [-8.817822265624955, 27.65644531250004],
                        [-8.817822265624951, 27.656445312499997],
                        [-8.683349609375, 27.656445312499997],
                        [-8.683349609375, 27.2859375],
                        [-8.682861328125, 26.921337890624997],
                        [-8.6826171875, 26.72314453125],
                        [-8.682324218749983, 26.497705078124994],
                        [-8.68212890625, 26.273193359375],
                        [-8.68212890625, 26.10947265625],
                        [-8.682226562499977, 25.995507812499994],
                        [-12.016308593749983, 25.995410156250003],
                        [-12.016308593749983, 25.740136718749994],
                        [-12.016308593749983, 25.331689453124994],
                        [-12.016308593749983, 25.059375],
                        [-12.016308593749983, 24.923242187499994],
                        [-12.016308593749983, 24.378662109375],
                        [-12.016308593749983, 23.97021484375],
                        [-12.0234375, 23.467578125],
                        [-12.372900390624977, 23.318017578124994],
                        [-12.559375, 23.290820312500003],
                        [-12.620410156249989, 23.27133789062499],
                        [-13.031494140625, 23.000244140625],
                        [-13.153271484374983, 22.820507812499997],
                        [-13.12702845982141, 22.703770926339278],
                        [-13.136540684091575, 22.708182548616723],
                        [-13.094335937499977, 22.495996093749994],
                        [-13.051220703124983, 21.854785156250003],
                        [-13.041748046875, 21.713818359374997],
                        [-13.0322265625, 21.572070312500003],
                        [-13.025097656249983, 21.466796875],
                        [-13.016210937499977, 21.333935546874997],
                        [-15.231201171875, 21.331298828125],
                        [-16.964550781249983, 21.329248046874994],
                        [-17.06396484375, 20.89882812499999],
                        [-17.048046874999983, 20.80615234375],
                        [-17.098779296874994, 20.856884765624997],
                        [-16.930859374999983, 21.9],
                        [-16.35874023437495, 22.594531250000045],
                        [-16.21025390624999, 23.097900390625],
                        [-15.789257812499926, 23.792871093750023],
                        [-15.980712890624943, 23.670312500000023],
                        [-15.899316406249966, 23.844433593749955],
                        [-14.904296875000028, 24.719775390625017],
                        [-14.794921874999943, 25.404150390625006],
                        [-14.413867187499932, 26.25371093749999],
                        [-13.57578125, 26.735107421875],
                        [-13.175976562499983, 27.655712890624983],
                        [-13.038761787013554, 27.81190166624856],
                    ],
                    [
                        [-8.774365234374983, 27.460546875],
                        [-8.794873046874983, 27.120703125000034],
                        [-8.794873046874983, 27.120703125],
                        [-8.774365234374983, 27.460546875],
                    ],
                ],
            },
            properties: {
                name: "西撒哈拉",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [21.5625, 42.247509765625],
                        [21.560839843750017, 42.24765625],
                        [21.389550781250023, 42.21982421875],
                        [21.28662109375, 42.100390625],
                        [21.05976562500001, 42.171289062499994],
                        [20.778125, 42.071044921875],
                        [20.725, 41.87353515625],
                        [20.566210937500017, 41.873681640624994],
                        [20.485449218750006, 42.223388671875],
                        [20.06396484375, 42.54726562499999],
                        [20.054296875, 42.760058593749996],
                        [20.344335937500006, 42.827929687499996],
                        [20.40996305279786, 42.84373166741877],
                        [20.344335937500063, 42.82792968750002],
                        [19.670996093750006, 43.163964843749994],
                        [19.21875, 43.449951171875],
                        [19.196484375000068, 43.48500976562502],
                        [19.19160156250004, 43.52104492187499],
                        [19.19433593749997, 43.533300781250006],
                        [19.495117187500057, 43.642871093750045],
                        [19.245019531249994, 43.96503906250004],
                        [19.583789062500017, 44.04345703125003],
                        [19.118457031250074, 44.359960937500006],
                        [19.348632812500057, 44.88090820312502],
                        [19.007128906250045, 44.86918945312502],
                        [19.062890625000023, 45.13720703125],
                        [19.4, 45.2125],
                        [19.004687500000074, 45.39951171875006],
                        [19.064257812500045, 45.51499023437506],
                        [18.839062499999983, 45.83574218750002],
                        [18.905371093750006, 45.931738281250034],
                        [19.421289062500023, 46.064453125],
                        [19.61347656250001, 46.169189453125],
                        [19.84443359375001, 46.145898437499966],
                        [19.934082031250057, 46.161474609375034],
                        [20.161425781250017, 46.14189453124996],
                        [20.210156250000068, 46.12602539062502],
                        [20.241796875000034, 46.10859375000001],
                        [20.301367187500006, 46.05068359375002],
                        [20.35859375000004, 45.975488281249994],
                        [20.581152343749977, 45.86948242187506],
                        [20.65273437499999, 45.779394531250006],
                        [20.709277343750074, 45.735253906249994],
                        [20.727832031250017, 45.73740234374998],
                        [20.746875, 45.74897460937501],
                        [20.76015625000005, 45.75810546875002],
                        [20.775, 45.74980468750002],
                        [20.794042968750006, 45.467871093750034],
                        [21.431445312500017, 45.192529296874994],
                        [21.465429687500006, 45.171875],
                        [21.357031250000034, 44.99077148437502],
                        [21.532324218750063, 44.900683593750045],
                        [21.519921875000023, 44.88081054687498],
                        [21.442187500000074, 44.87338867187498],
                        [21.384375, 44.87006835937501],
                        [21.357910156250057, 44.86181640625003],
                        [21.36005859375004, 44.82666015624997],
                        [21.52314453125004, 44.79008789062499],
                        [21.63613281250005, 44.71044921875],
                        [21.909277343750034, 44.666113281250034],
                        [22.026953125, 44.61987304687503],
                        [22.093066406250074, 44.541943359374955],
                        [22.200976562500017, 44.560693359374966],
                        [22.350683593750063, 44.676123046875034],
                        [22.497656249999977, 44.70625],
                        [22.64208984375, 44.65097656249998],
                        [22.720898437499983, 44.605517578125045],
                        [22.734375, 44.56992187499998],
                        [22.700781250000063, 44.55551757812498],
                        [22.620117187500057, 44.562353515625034],
                        [22.554003906250017, 44.54033203124999],
                        [22.49453125000005, 44.43544921875002],
                        [22.687890625000023, 44.248291015625],
                        [22.42080078125005, 44.00742187500006],
                        [22.399023437500063, 43.96953125],
                        [22.36542968750004, 43.86210937500002],
                        [22.36962890625003, 43.78129882812499],
                        [22.55458984375005, 43.45449218750002],
                        [22.767578125, 43.35415039062502],
                        [22.81972656250005, 43.300732421874955],
                        [22.85957031250001, 43.252343749999966],
                        [22.97685546874999, 43.18798828125],
                        [22.799902343750006, 42.985742187499994],
                        [22.706152343750006, 42.88393554687505],
                        [22.466796875, 42.842480468749955],
                        [22.53242187500004, 42.48120117187497],
                        [22.523535156250006, 42.440966796875045],
                        [22.44570312500005, 42.35913085937497],
                        [22.42207031250004, 42.32885742187503],
                        [22.344042968750045, 42.31396484375003],
                        [22.23974609375003, 42.303110028468716],
                        [21.81464843750001, 42.303125],
                        [21.5625, 42.24750976562498],
                        [21.5625, 42.247509765625],
                    ],
                ],
            },
            properties: {
                name: "塞尔维亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [74.54140625000002, 37.02216796875],
                        [74.03886718750002, 36.825732421874996],
                        [73.116796875, 36.868554687499994],
                        [72.24980468750002, 36.734716796875],
                        [71.23291015625, 36.12177734375],
                        [71.18505859375, 36.04208984375],
                        [71.57197265625001, 35.546826171875],
                        [71.62050781250002, 35.183007812499994],
                        [70.965625, 34.53037109375],
                        [71.095703125, 34.369433593749996],
                        [71.05156250000002, 34.049707031249994],
                        [70.65400390625001, 33.952294921874994],
                        [69.8896484375, 34.007275390625],
                        [70.26113281250002, 33.289013671875],
                        [69.5015625, 33.020068359374996],
                        [69.24140625000001, 32.433544921875],
                        [69.279296875, 31.936816406249996],
                        [68.86894531250002, 31.634228515624997],
                        [68.59765625, 31.802978515625],
                        [68.16103515625002, 31.802978515625],
                        [67.57822265625, 31.506494140624994],
                        [67.737890625, 31.343945312499997],
                        [67.45283203125001, 31.234619140625],
                        [66.82929687500001, 31.263671875],
                        [66.346875, 30.802783203124996],
                        [66.23125, 29.86572265625],
                        [65.09550781250002, 29.559472656249994],
                        [64.39375, 29.544335937499994],
                        [64.09873046875, 29.391943359375],
                        [63.56757812500001, 29.497998046874997],
                        [62.4765625, 29.408349609374994],
                        [62.0009765625, 29.530419921874994],
                        [61.22441406250002, 29.749414062499994],
                        [60.843359375, 29.858691406249996],
                        [61.331640625, 30.363720703124997],
                        [61.55947265625002, 30.599365234375],
                        [61.7841796875, 30.831933593749994],
                        [61.81083984375002, 30.91328125],
                        [61.81425781250002, 31.072558593749996],
                        [61.75507812500001, 31.285302734374994],
                        [61.66015625, 31.382421875],
                        [61.34648437500002, 31.421630859375],
                        [61.11074218750002, 31.451123046874997],
                        [60.854101562500006, 31.483251953125],
                        [60.82070312500002, 31.495166015624996],
                        [60.791601562500006, 31.660595703124997],
                        [60.804296875, 31.73447265625],
                        [60.7875, 31.877197265625],
                        [60.78994140625002, 31.987109375],
                        [60.827246093750006, 32.16796875],
                        [60.82929687500001, 32.249414062499994],
                        [60.71044921875, 32.6],
                        [60.57656250000002, 32.994873046875],
                        [60.560546875, 33.137841796874994],
                        [60.9169921875, 33.505224609375],
                        [60.573828125, 33.588330078125],
                        [60.4859375, 33.7119140625],
                        [60.48574218750002, 34.094775390624996],
                        [60.642675781250006, 34.307177734374996],
                        [60.88945312500002, 34.31943359375],
                        [60.80390625000001, 34.418017578124996],
                        [60.76259765625002, 34.475244140624994],
                        [60.73613281250002, 34.491796875],
                        [60.72626953125001, 34.51826171875],
                        [60.73945312500001, 34.544726562499996],
                        [60.80234375, 34.554638671875],
                        [60.8453125, 34.587695312499996],
                        [60.91474609375001, 34.633984375],
                        [60.951171875, 34.653857421874996],
                        [61.080078125, 34.855615234374994],
                        [61.1, 35.272314453125],
                        [61.18925781250002, 35.31201171875],
                        [61.24550781250002, 35.474072265625],
                        [61.27851562500001, 35.51376953125],
                        [61.281835937500006, 35.55341796875],
                        [61.26201171875002, 35.619580078125],
                        [61.3447265625, 35.6294921875],
                        [61.62099609375002, 35.43232421875],
                        [62.08964843750002, 35.3796875],
                        [62.30781250000001, 35.170800781249994],
                        [62.688085937500006, 35.255322265625],
                        [63.056640625, 35.44580078125],
                        [63.08417968750001, 35.56806640625],
                        [63.16972656250002, 35.678125],
                        [63.129980468750006, 35.84619140625],
                        [63.8625, 36.012353515624994],
                        [64.184375, 36.14892578125],
                        [64.51103515625002, 36.340673828125],
                        [64.56582031250002, 36.427587890625],
                        [64.6025390625, 36.554541015625],
                        [64.78242187500001, 37.05927734375],
                        [64.81630859375002, 37.132080078125],
                        [64.95156250000002, 37.1935546875],
                        [65.08964843750002, 37.237939453124994],
                        [65.30361328125002, 37.24677734375],
                        [65.55498046875002, 37.251171875],
                        [65.76503906250002, 37.569140625],
                        [66.471875, 37.3447265625],
                        [66.52226562500002, 37.348486328125],
                        [66.827734375, 37.3712890625],
                        [67.06884765625, 37.334814453125],
                        [67.19550781250001, 37.235205078125],
                        [67.31972656250002, 37.2095703125],
                        [67.44169921875002, 37.2580078125],
                        [67.51728515625001, 37.266650390624996],
                        [67.546484375, 37.235644531249996],
                        [67.607421875, 37.222509765625],
                        [67.7, 37.22724609375],
                        [67.7529296875, 37.1998046875],
                        [67.75898437500001, 37.172216796875],
                        [67.76601562500002, 37.14013671875],
                        [67.83447265625, 37.064208984375],
                        [67.9580078125, 36.972021484375],
                        [68.06777343750002, 36.9498046875],
                        [68.26093750000001, 37.013085937499994],
                        [68.284765625, 37.036328125],
                        [68.29951171875001, 37.088427734374996],
                        [68.38691406250001, 37.1375],
                        [68.66914062500001, 37.2583984375],
                        [68.7232421875, 37.268017578125],
                        [68.78203125000002, 37.2580078125],
                        [68.82373046875, 37.270703125],
                        [68.8384765625, 37.30283203125],
                        [68.85537109375002, 37.316845703125],
                        [68.88525390625, 37.328076171875],
                        [68.91181640625001, 37.333935546875],
                        [68.96044921875, 37.325048828125],
                        [69.18017578125, 37.15830078125],
                        [69.26484375000001, 37.1083984375],
                        [69.30390625000001, 37.116943359375],
                        [69.35380859375002, 37.150048828124994],
                        [69.41445312500002, 37.207763671875],
                        [69.4296875, 37.290869140625],
                        [69.39921875000002, 37.399316406249994],
                        [69.42011718750001, 37.48671875],
                        [69.49208984375002, 37.553076171875],
                        [69.62578125000002, 37.594042968749996],
                        [69.8208984375, 37.6095703125],
                        [69.9849609375, 37.566162109375],
                        [70.18867187500001, 37.582470703125],
                        [70.25146484375, 37.66416015625],
                        [70.25498046875, 37.765380859375],
                        [70.19941406250001, 37.886035156249996],
                        [70.21464843750002, 37.9244140625],
                        [70.41777343750002, 38.075439453125],
                        [70.7359375, 38.42255859375],
                        [71.255859375, 38.306982421875],
                        [71.33271484375001, 38.170263671875],
                        [71.27851562500001, 37.918408203125],
                        [71.319921875, 37.90185546875],
                        [71.3896484375, 37.906298828124996],
                        [71.48779296875, 37.931884765625],
                        [71.55195312500001, 37.933154296874996],
                        [71.58222656250001, 37.910107421875],
                        [71.43291015625002, 37.1275390625],
                        [71.530859375, 36.845117187499994],
                        [71.665625, 36.696923828124994],
                        [72.65742187500001, 37.029052734375],
                        [72.8955078125, 37.267529296875],
                        [73.21113281250001, 37.408496093749996],
                        [73.38291015625, 37.462255859375],
                        [73.48134765625002, 37.4716796875],
                        [73.60468750000001, 37.446044921875],
                        [73.65712890625002, 37.43046875],
                        [73.72060546875002, 37.41875],
                        [73.73378906250002, 37.37578125],
                        [73.71728515625, 37.329443359375],
                        [73.6275390625, 37.261572265625],
                        [73.65351562500001, 37.23935546875],
                        [73.749609375, 37.231787109375],
                        [74.16708984375, 37.329443359375],
                        [74.20351562500002, 37.372460937499994],
                        [74.25966796875002, 37.415429687499994],
                        [74.659375, 37.394482421875],
                        [74.73056640625, 37.35703125],
                        [74.83046875000002, 37.2859375],
                        [74.89130859375001, 37.231640625],
                        [74.84023437500002, 37.225048828125],
                        [74.76738281250002, 37.249169921874994],
                        [74.73896484375001, 37.28564453125],
                        [74.72666015625, 37.29072265625],
                        [74.6689453125, 37.26669921875],
                        [74.55898437500002, 37.236621093749996],
                        [74.37216796875, 37.15771484375],
                        [74.37617187500001, 37.137353515624994],
                        [74.49794921875002, 37.0572265625],
                        [74.52646484375, 37.030664062499994],
                        [74.54140625000002, 37.02216796875],
                    ],
                ],
            },
            properties: {
                name: "阿富汗",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [17.57958984375, -8.099023437500009],
                            [17.643359375000017, -8.090722656250009],
                            [18.00878906250003, -8.107617187499983],
                            [18.56269531250001, -7.9359375],
                            [18.944433593750063, -8.001464843750028],
                            [19.142675781250034, -8.001464843750028],
                            [19.34082031249997, -7.966601562500031],
                            [19.369921875000045, -7.706542968749986],
                            [19.371679687500063, -7.655078124999989],
                            [19.47988281250008, -7.472167968750028],
                            [19.48378906250008, -7.279492187500026],
                            [19.527636718750017, -7.144433593749952],
                            [19.87519531250004, -6.986328124999986],
                            [19.99746093750008, -6.976464843750023],
                            [20.190039062500063, -6.9462890625],
                            [20.482226562500074, -6.915820312500017],
                            [20.59003906250001, -6.919921874999957],
                            [20.598730468750006, -6.935156249999949],
                            [20.536914062500045, -7.121777343749955],
                            [20.535839843749983, -7.182812499999955],
                            [20.558398437500045, -7.244433593749989],
                            [20.60781250000008, -7.277734375000023],
                            [20.910937500000017, -7.281445312499983],
                            [21.190332031250023, -7.284960937499989],
                            [21.751074218750034, -7.305468749999989],
                            [21.80605468750005, -7.32861328125],
                            [21.905371093750034, -8.693359374999943],
                            [21.813183593750068, -9.46875],
                            [22.19775390625, -10.040625],
                            [22.30703125000005, -10.691308593750023],
                            [22.203515625000023, -10.829492187500009],
                            [22.226171875, -11.121972656250009],
                            [22.27880859375, -11.19414062499996],
                            [22.314941406250057, -11.198632812499994],
                            [22.39296875000005, -11.159472656250003],
                            [22.486132812500045, -11.086718750000017],
                            [22.56103515625003, -11.05585937500004],
                            [22.814746093750017, -11.08027343750004],
                            [23.076269531250006, -11.087890624999986],
                            [23.463964843750034, -10.969335937499991],
                            [23.83388671875008, -11.013671874999972],
                            [23.96650390625001, -10.871777343750011],
                            [23.98388671875, -11.725],
                            [23.909375, -12.636132812500009],
                            [23.886523437500045, -12.743261718749991],
                            [23.882421875, -12.799023437499983],
                            [23.968066406250045, -12.956933593749994],
                            [23.962988281250006, -12.988476562500026],
                            [23.843164062500023, -13.0009765625],
                            [22.209570312500006, -13.0009765625],
                            [21.97890625000008, -13.0009765625],
                            [21.979101562500034, -13.798730468749994],
                            [21.979296875000074, -14.11962890625],
                            [21.979394531249994, -14.440527343750006],
                            [21.97978515624999, -15.955566406250014],
                            [22.193945312500006, -16.628125],
                            [23.380664062500017, -17.640625],
                            [22.32421875, -17.8375],
                            [20.74550781250008, -18.019726562499983],
                            [20.194335937500057, -17.86367187499999],
                            [18.95527343750004, -17.80351562499999],
                            [18.39638671875005, -17.3994140625],
                            [16.14843750000003, -17.39023437499999],
                            [14.017480468750023, -17.40888671874997],
                            [13.475976562500023, -17.04003906249997],
                            [13.179492187500017, -16.971679687499986],
                            [12.548144531250017, -17.212695312499974],
                            [12.35927734375008, -17.205859375],
                            [12.318457031250006, -17.21337890625003],
                            [12.213378906250028, -17.209960937500043],
                            [12.013964843750074, -17.168554687500034],
                            [11.902539062500011, -17.226562499999957],
                            [11.743066406250023, -17.24921875000004],
                            [11.780078125000017, -16.87128906249997],
                            [11.818945312500034, -16.704101562500014],
                            [11.750878906250023, -15.831933593749966],
                            [12.016113281250057, -15.513671874999957],
                            [12.55048828125004, -13.437792968750003],
                            [12.983203124999989, -12.775683593750017],
                            [13.4169921875, -12.52041015624998],
                            [13.597949218750017, -12.286132812500028],
                            [13.785351562499983, -11.81279296874996],
                            [13.833593750000063, -10.9296875],
                            [13.33222656250004, -9.998925781250009],
                            [12.99853515625, -9.048046875],
                            [13.358984375, -8.687207031250026],
                            [13.378515625000063, -8.369726562500006],
                            [12.82343750000004, -6.954785156249955],
                            [12.283300781250063, -6.12431640624996],
                            [13.184375, -5.85625],
                            [13.346484375000017, -5.863378906250006],
                            [13.978515625, -5.857226562500003],
                            [16.315234375000074, -5.865625],
                            [16.431445312500045, -5.90019531249996],
                            [16.53710937499997, -5.9658203125],
                            [16.697265625, -6.164257812500026],
                            [16.74296874999999, -6.618457031250003],
                            [16.813085937500063, -6.772558593749963],
                            [16.919433593750057, -6.93398437499998],
                            [16.98476562500005, -7.257421874999977],
                            [17.57958984375, -8.099023437500009],
                        ],
                    ],
                    [
                        [
                            [12.50146484375, -4.5875],
                            [12.848144531250028, -4.428906249999983],
                            [12.881054687500068, -4.445117187499989],
                            [12.971386718750068, -4.551757812499957],
                            [13.048046875000068, -4.619238281250034],
                            [13.072753906250028, -4.634765625],
                            [13.057324218750011, -4.651074218750026],
                            [12.947460937500011, -4.695312499999986],
                            [12.829687499999977, -4.73662109374996],
                            [12.451464843750017, -5.071484374999969],
                            [12.453222656250034, -5.090625],
                            [12.52236328125008, -5.148925781250028],
                            [12.484570312500011, -5.71875],
                            [12.213671875000074, -5.758691406249994],
                            [12.177148437499994, -5.324804687499977],
                            [12.018359375000074, -5.004296874999966],
                            [12.50146484375, -4.5875],
                        ],
                    ],
                ],
            },
            properties: {
                name: "安哥拉",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [20.06396484375, 42.54726562499999],
                        [20.485449218750006, 42.223388671875],
                        [20.566210937500017, 41.873681640624994],
                        [20.48896484375001, 41.272607421874994],
                        [20.709277343750017, 40.928369140624994],
                        [20.964257812500023, 40.849902343749996],
                        [20.95576171875001, 40.775292968749994],
                        [21.030859375, 40.622460937499994],
                        [20.881640625000017, 40.467919921874994],
                        [20.65742187500001, 40.1173828125],
                        [20.4560546875, 40.065576171874994],
                        [20.408007812500017, 40.049462890624994],
                        [20.383691406250023, 40.0171875],
                        [20.338476562500006, 39.991064453125],
                        [20.311132812500006, 39.979443359375],
                        [20.311328125000017, 39.95078125],
                        [20.381640625000017, 39.841796875],
                        [20.382421875, 39.802636718749994],
                        [20.206835937500017, 39.653515625],
                        [20.13105468750001, 39.66162109375],
                        [20.05976562500001, 39.699121093749994],
                        [20.022558593750006, 39.710693359375],
                        [20.001269531250017, 39.709423828125],
                        [19.851855468750017, 40.0435546875],
                        [19.322265625, 40.407080078125],
                        [19.45917968750001, 40.40537109375],
                        [19.3375, 40.663818359375],
                        [19.57568359375, 41.640429687499996],
                        [19.577539062500023, 41.7875],
                        [19.342382812500006, 41.869091796875],
                        [19.280664062500023, 42.17255859375],
                        [19.65449218750001, 42.628564453124994],
                        [19.78828125000001, 42.476171875],
                        [20.06396484375, 42.54726562499999],
                    ],
                ],
            },
            properties: {
                name: "阿尔巴尼亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [19.66230468750001, 60.187158203124994],
                            [19.53652343750005, 60.14497070312501],
                            [19.551367187500063, 60.24384765625001],
                            [19.66230468750001, 60.187158203124994],
                        ],
                    ],
                    [
                        [
                            [19.989550781250074, 60.351171875],
                            [20.258886718750063, 60.26127929687499],
                            [19.799804687500057, 60.08173828125001],
                            [19.68691406250005, 60.267626953125045],
                            [19.84765625000003, 60.22055664062506],
                            [19.823046875000074, 60.390185546875045],
                            [19.989550781250074, 60.351171875],
                        ],
                    ],
                ],
            },
            properties: {
                name: "奥兰群岛",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [1.7060546875, 42.503320312499994],
                        [1.534082031250023, 42.441699218749996],
                        [1.448828125, 42.437451171875],
                        [1.428125, 42.46132812499999],
                        [1.414843750000017, 42.548388671874996],
                        [1.428320312500006, 42.5958984375],
                        [1.501367187500023, 42.642724609374994],
                        [1.568164062500017, 42.635009765625],
                        [1.709863281250023, 42.604443359375],
                        [1.739453125000011, 42.575927734375],
                        [1.740234375, 42.55673828125],
                        [1.713964843750006, 42.525634765625],
                        [1.7060546875, 42.503320312499994],
                    ],
                ],
            },
            properties: {
                name: "安道尔",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [53.927832031250006, 24.177197265624983],
                            [53.63447265625004, 24.169775390624977],
                            [53.83378906250002, 24.258935546875023],
                            [53.927832031250006, 24.177197265624983],
                        ],
                    ],
                    [
                        [
                            [53.3322265625001, 24.258593750000045],
                            [53.19091796874997, 24.290917968749966],
                            [53.412402343750074, 24.411035156250023],
                            [53.3322265625001, 24.258593750000045],
                        ],
                    ],
                    [
                        [
                            [56.29785156250003, 25.650683593750045],
                            [56.38798828125002, 24.97919921875004],
                            [56.06386718750005, 24.73876953125],
                            [56.00058593750006, 24.953222656249977],
                            [55.795703125000074, 24.868115234374955],
                            [55.76083984375006, 24.24267578125],
                            [55.92861328125005, 24.215136718750074],
                            [55.98515625000002, 24.063378906249966],
                            [55.4684570312501, 23.94111328125001],
                            [55.53164062499999, 23.81904296875001],
                            [55.1999023437501, 23.034765625000034],
                            [55.185839843750074, 22.7041015625],
                            [55.104296875000074, 22.621484375000023],
                            [52.55507812500005, 22.932812499999955],
                            [51.592578125000074, 24.07885742187503],
                            [51.56835937500003, 24.286181640625074],
                            [51.76757812500003, 24.25439453125],
                            [51.84316406250005, 24.010888671875023],
                            [52.118554687499994, 23.97109375],
                            [52.64824218750002, 24.154638671875006],
                            [53.80175781249997, 24.069482421874966],
                            [54.14794921875003, 24.17119140624999],
                            [54.39707031250006, 24.278173828125034],
                            [54.74677734375004, 24.810449218750023],
                            [55.94121093750002, 25.793994140625017],
                            [56.08046875, 26.06264648437505],
                            [56.16748046875003, 26.047460937499977],
                            [56.144628906250006, 25.690527343750006],
                            [56.29785156250003, 25.650683593750045],
                        ],
                    ],
                ],
            },
            properties: {
                name: "阿拉伯联合酋长国",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-64.54916992187498, -54.71621093749998],
                            [-63.81542968749997, -54.725097656250014],
                            [-64.637353515625, -54.90253906250001],
                            [-64.75732421875, -54.82656249999999],
                            [-64.54916992187498, -54.71621093749998],
                        ],
                    ],
                    [
                        [
                            [-68.65322265624994, -54.85361328124999],
                            [-68.62993164062499, -52.65263671875004],
                            [-68.24013671875, -53.08183593749999],
                            [-68.43115234375, -53.0552734375],
                            [-68.48852539062497, -53.260937499999976],
                            [-68.16113281249997, -53.30644531250001],
                            [-68.00849609374995, -53.5640625],
                            [-67.29423828125002, -54.049804687500014],
                            [-66.23564453124999, -54.53349609374997],
                            [-65.17900390624993, -54.678125],
                            [-65.47114257812495, -54.91464843749999],
                            [-66.5111328125, -55.032128906249945],
                            [-67.127099609375, -54.90380859375001],
                            [-68.65322265624994, -54.85361328124999],
                        ],
                    ],
                    [
                        [
                            [-61.084716796875, -23.65644531250001],
                            [-60.83984375000003, -23.85810546874997],
                            [-59.89248046874994, -24.093554687499974],
                            [-59.18725585937497, -24.56230468749999],
                            [-57.82167968749994, -25.136425781249983],
                            [-57.56313476562494, -25.473730468749963],
                            [-57.943115234375, -26.05292968750001],
                            [-58.18149414062498, -26.30742187499999],
                            [-58.222070312499994, -26.65],
                            [-58.618603515624955, -27.13212890624996],
                            [-58.64174804687494, -27.196093750000017],
                            [-58.60483398437498, -27.314355468750037],
                            [-58.16826171874993, -27.27343749999997],
                            [-56.437158203124966, -27.553808593749977],
                            [-56.16406250000003, -27.321484374999983],
                            [-55.95146484374996, -27.325683593749957],
                            [-55.789990234374926, -27.416406249999966],
                            [-55.71464843749996, -27.41484375],
                            [-55.632910156250006, -27.35712890624997],
                            [-55.59379882812502, -27.288085937500014],
                            [-55.597265625, -27.207617187499963],
                            [-55.56489257812498, -27.15],
                            [-55.496728515624966, -27.11533203124999],
                            [-55.45063476562498, -27.068359375000014],
                            [-55.426660156249994, -27.00927734374997],
                            [-55.13593750000001, -26.931152343749957],
                            [-54.934472656249994, -26.70253906250001],
                            [-54.677734375, -26.308789062499997],
                            [-54.631933593750006, -26.005761718749994],
                            [-54.615869140624994, -25.576074218750023],
                            [-54.44394531249998, -25.625],
                            [-54.15458984374999, -25.523046874999963],
                            [-53.89116210937499, -25.66884765625001],
                            [-53.668554687500006, -26.288183593749977],
                            [-53.83818359375002, -27.121093750000014],
                            [-54.32700195312495, -27.423535156249997],
                            [-54.82910156250003, -27.55058593750003],
                            [-55.10151367187501, -27.866796874999963],
                            [-55.72548828125002, -28.20410156250003],
                            [-55.68725585937497, -28.38164062499996],
                            [-55.890527343749994, -28.370019531249994],
                            [-56.938623046874994, -29.594824218750034],
                            [-57.22465820312499, -29.782128906249994],
                            [-57.40522460937501, -30.03388671875004],
                            [-57.563867187499994, -30.139941406249974],
                            [-57.60888671875003, -30.187792968750045],
                            [-57.65087890624997, -30.295019531250034],
                            [-57.71269531249996, -30.38447265624997],
                            [-57.83120117187502, -30.495214843749963],
                            [-57.87250976562501, -30.591015625000026],
                            [-57.81059570312499, -30.85859375000001],
                            [-57.88632812499998, -30.937402343749994],
                            [-57.86840820312497, -31.104394531249994],
                            [-57.89335937499999, -31.195312499999957],
                            [-58.03339843750001, -31.416601562500006],
                            [-58.053857421874994, -31.494921874999974],
                            [-58.009667968749966, -31.534375],
                            [-57.98798828124998, -31.576171875],
                            [-58.00698242187494, -31.684960937499966],
                            [-58.04233398437495, -31.769238281249997],
                            [-58.16748046874997, -31.87265625],
                            [-58.18901367187499, -31.924218750000037],
                            [-58.16040039062503, -31.986523437500026],
                            [-58.156347656250006, -32.0515625],
                            [-58.17700195312494, -32.11904296875002],
                            [-58.16479492187494, -32.18486328125002],
                            [-58.119726562500006, -32.24892578125002],
                            [-58.12304687499997, -32.321875],
                            [-58.201171875, -32.471679687500014],
                            [-58.219970703125, -32.563964843749986],
                            [-58.17099609374998, -32.95927734374996],
                            [-58.424462890624994, -33.11152343749998],
                            [-58.54721679687498, -33.66347656249998],
                            [-58.392480468749966, -34.192968750000034],
                            [-58.52548828124998, -34.29619140625002],
                            [-58.28334960937494, -34.68349609375005],
                            [-57.54785156250003, -35.018945312499994],
                            [-57.170654296875, -35.3625],
                            [-57.35390624999994, -35.72031249999998],
                            [-57.33544921875, -36.026757812499966],
                            [-57.07617187499994, -36.296777343749994],
                            [-56.74946289062501, -36.346484375],
                            [-56.67202148437494, -36.85126953124998],
                            [-57.546972656250034, -38.085644531250026],
                            [-58.17919921874994, -38.435839843750045],
                            [-59.82832031250001, -38.83818359375003],
                            [-61.112207031249994, -38.99296875000003],
                            [-61.84790039062497, -38.961816406249994],
                            [-62.33476562499993, -38.80009765625],
                            [-62.29506835937502, -39.24326171874996],
                            [-62.053662109374955, -39.373828125],
                            [-62.179345703124994, -39.38046875000002],
                            [-62.076806640624966, -39.46152343750002],
                            [-62.131542968749926, -39.82539062499998],
                            [-62.28691406249996, -39.89531250000002],
                            [-62.40185546875003, -40.19658203125002],
                            [-62.24633789062494, -40.674609374999974],
                            [-62.39501953124997, -40.89082031249997],
                            [-62.95903320312493, -41.10966796875006],
                            [-63.621777343749955, -41.15976562499996],
                            [-64.86948242187503, -40.735839843750014],
                            [-65.13339843749998, -40.88066406250003],
                            [-64.98637695312496, -42.102050781249986],
                            [-64.53774414062494, -42.25458984374998],
                            [-64.57099609374998, -42.416015625],
                            [-64.42041015625003, -42.43378906249998],
                            [-64.10087890624993, -42.395117187500006],
                            [-64.06118164062494, -42.266113281250014],
                            [-64.228515625, -42.21826171874996],
                            [-63.795556640624994, -42.113867187500006],
                            [-63.6298828125, -42.28271484375003],
                            [-63.61733398437502, -42.695800781249986],
                            [-64.03476562499998, -42.88125],
                            [-64.48784179687499, -42.51347656250006],
                            [-64.97070312499997, -42.66630859375002],
                            [-65.02690429687496, -42.75888671874996],
                            [-64.31914062499999, -42.968945312500026],
                            [-64.83994140624998, -43.18886718749998],
                            [-65.25234374999997, -43.571875],
                            [-65.26552734375, -44.2796875],
                            [-65.64760742187502, -44.661425781250045],
                            [-65.63876953125, -45.0078125],
                            [-66.19013671874995, -44.96474609375002],
                            [-66.94140625, -45.25732421875003],
                            [-67.59956054687495, -46.05253906250003],
                            [-67.5064453125, -46.44277343749995],
                            [-66.77685546874994, -47.005859375],
                            [-65.99853515625, -47.09375],
                            [-65.73808593749999, -47.34492187499998],
                            [-65.81430664062495, -47.63818359374996],
                            [-66.22524414062502, -47.826757812500006],
                            [-65.93422851562497, -47.826757812500006],
                            [-65.81005859374997, -47.941113281250026],
                            [-67.46630859375, -48.95175781250004],
                            [-67.68486328125002, -49.2466796875],
                            [-67.82597656249999, -49.91962890625005],
                            [-68.2572265625, -50.104589843749984],
                            [-68.66757812500003, -49.75253906250003],
                            [-68.66162109374997, -49.93574218750005],
                            [-68.97958984375, -50.003027343749984],
                            [-68.59794921874996, -50.00947265624997],
                            [-68.421875, -50.15791015625001],
                            [-69.04477539062495, -50.49912109374998],
                            [-69.35859374999993, -51.028125],
                            [-69.20102539062498, -50.99365234375001],
                            [-69.03530273437497, -51.48896484375002],
                            [-69.46542968750003, -51.58447265625003],
                            [-68.96533203125003, -51.67714843749999],
                            [-68.443359375, -52.35664062500004],
                            [-69.96025390624993, -52.00820312500002],
                            [-71.91865234374995, -51.98955078125004],
                            [-72.40766601562501, -51.54082031250002],
                            [-72.34023437499997, -50.68183593749999],
                            [-72.50981445312496, -50.607519531250034],
                            [-73.15292968749998, -50.73828125000003],
                            [-73.50126953124996, -50.125292968750024],
                            [-73.55419921875, -49.463867187500014],
                            [-73.46157226562497, -49.31386718750001],
                            [-73.13525390625, -49.30068359374999],
                            [-73.03364257812501, -49.014355468750004],
                            [-72.65126953125, -48.84160156249998],
                            [-72.582861328125, -48.47539062499999],
                            [-72.35473632812497, -48.36582031250005],
                            [-72.32832031250001, -48.11005859374998],
                            [-72.517919921875, -47.87636718749998],
                            [-72.34594726562497, -47.49267578124997],
                            [-71.90498046875001, -47.201660156250014],
                            [-71.94023437499999, -46.83125],
                            [-71.69965820312501, -46.6513671875],
                            [-71.87568359374998, -46.160546875],
                            [-71.63154296874998, -45.95371093749998],
                            [-71.74619140624998, -45.57890625],
                            [-71.34931640624995, -45.33193359374995],
                            [-71.5962890625, -44.97919921875004],
                            [-72.04169921874998, -44.90419921875004],
                            [-72.06372070312503, -44.771875],
                            [-71.26113281250002, -44.763085937499966],
                            [-71.15971679687496, -44.56025390625004],
                            [-71.21259765624998, -44.44121093750003],
                            [-71.82001953124993, -44.38310546875],
                            [-71.68007812500002, -43.92958984374998],
                            [-71.90498046875001, -43.34755859374998],
                            [-71.750634765625, -43.237304687499986],
                            [-72.14643554687498, -42.990039062499974],
                            [-72.10820312499993, -42.25185546874995],
                            [-71.75, -42.04677734375001],
                            [-71.91127929687497, -41.650390624999986],
                            [-71.93212890624994, -40.69169921874999],
                            [-71.70898437499997, -40.381738281249994],
                            [-71.81831054687493, -40.17666015624995],
                            [-71.65976562499998, -40.02080078125],
                            [-71.71992187499995, -39.63525390624997],
                            [-71.53945312499997, -39.60244140624995],
                            [-71.40156249999995, -38.93505859374996],
                            [-70.858642578125, -38.60449218750003],
                            [-71.16757812499998, -37.76230468749996],
                            [-71.19218750000002, -36.84365234375004],
                            [-71.05551757812498, -36.52373046874996],
                            [-70.40478515625, -36.06171874999998],
                            [-70.41572265625001, -35.52304687500002],
                            [-70.55517578125, -35.246875],
                            [-70.39316406250003, -35.146875],
                            [-70.05205078124999, -34.30078124999997],
                            [-69.85244140625, -34.224316406250026],
                            [-69.81962890624999, -33.28378906249999],
                            [-70.08486328125002, -33.20175781249998],
                            [-70.02197265625, -32.88457031250002],
                            [-70.36376953125, -32.08349609374997],
                            [-70.25439453125, -31.957714843750026],
                            [-70.585205078125, -31.569433593749963],
                            [-70.51958007812493, -31.1484375],
                            [-70.30908203124994, -31.02265625000004],
                            [-70.15322265625, -30.360937499999963],
                            [-69.95634765624996, -30.35820312500003],
                            [-69.84428710937493, -30.175],
                            [-69.95996093749997, -30.078320312500026],
                            [-70.02680664062501, -29.324023437500017],
                            [-69.82788085937497, -29.10322265624997],
                            [-69.65693359374995, -28.413574218749986],
                            [-69.17441406249998, -27.924707031250037],
                            [-68.84633789062494, -27.153710937499994],
                            [-68.59208984375002, -27.140039062499966],
                            [-68.31865234374999, -26.973242187500006],
                            [-68.59160156249999, -26.47041015624997],
                            [-68.41450195312498, -26.153710937500023],
                            [-68.59208984375002, -25.420019531250034],
                            [-68.38422851562495, -25.091894531249977],
                            [-68.56201171875, -24.74736328125003],
                            [-68.25029296875002, -24.391992187500023],
                            [-67.35620117187503, -24.033789062499963],
                            [-67.00878906249994, -23.00136718750005],
                            [-67.19487304687493, -22.821679687500037],
                            [-66.99111328125, -22.509863281250006],
                            [-66.71171874999999, -22.216308593749986],
                            [-66.36518554687501, -22.113769531249957],
                            [-66.32246093750001, -22.053125],
                            [-66.28212890624997, -21.94746093750001],
                            [-66.24760742187496, -21.83046875],
                            [-66.22016601562495, -21.802539062499974],
                            [-66.174658203125, -21.805664062499986],
                            [-66.09858398437495, -21.83505859375002],
                            [-66.05859375, -21.87949218750002],
                            [-65.86015624999999, -22.019726562499983],
                            [-65.77104492187493, -22.099609375000014],
                            [-65.68618164062497, -22.11025390625005],
                            [-65.51879882812497, -22.094531250000045],
                            [-64.99262695312498, -22.109667968750017],
                            [-64.60551757812499, -22.228808593750045],
                            [-64.52363281250001, -22.37158203125],
                            [-64.47773437499998, -22.485351562499986],
                            [-64.44550781249998, -22.585351562500023],
                            [-64.37397460937498, -22.761035156250017],
                            [-64.32529296875, -22.82763671875],
                            [-64.30791015624993, -22.7953125],
                            [-64.26640625000002, -22.60332031249996],
                            [-63.97612304687502, -22.072558593750003],
                            [-63.92167968749993, -22.028613281250017],
                            [-62.843359375, -21.997265625000026],
                            [-62.62597656250003, -22.29042968749998],
                            [-62.54155273437496, -22.349609374999957],
                            [-62.37250976562498, -22.439160156249997],
                            [-62.21416015624996, -22.612402343750034],
                            [-61.798535156249955, -23.18203125],
                            [-61.084716796875, -23.65644531250001],
                        ],
                    ],
                ],
            },
            properties: {
                name: "阿根廷",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [46.490625, 38.90668945312498],
                        [46.1144531250001, 38.877783203125034],
                        [45.977441406249994, 39.24389648437503],
                        [45.76630859375004, 39.37846679687499],
                        [45.78447265625002, 39.54560546875001],
                        [45.456835937500074, 39.494482421875006],
                        [45.15283203125003, 39.58266601562502],
                        [45.03164062500005, 39.76513671874997],
                        [44.76826171875004, 39.70351562500005],
                        [44.28925781250004, 40.040380859375006],
                        [43.66621093750004, 40.12636718750002],
                        [43.56933593750003, 40.48237304687498],
                        [43.72265624999997, 40.71953124999999],
                        [43.43945312500003, 41.10712890625001],
                        [44.077246093750006, 41.182519531249994],
                        [44.81132812500002, 41.259375],
                        [45.001367187499994, 41.29096679687498],
                        [45.188574218750006, 41.14741210937504],
                        [45.07050781250004, 41.075585937499966],
                        [45.5875, 40.846923828125],
                        [45.37890624999997, 40.67358398437506],
                        [45.45439453125002, 40.532373046874966],
                        [45.96464843750002, 40.233789062499966],
                        [45.8859375000001, 40.024853515624955],
                        [45.57978515625004, 39.9775390625],
                        [46.202050781249994, 39.59448242187503],
                        [46.48144531249997, 39.55517578125003],
                        [46.36523437500003, 39.402490234374994],
                        [46.584765625000074, 39.22368164062499],
                        [46.400292968749994, 39.1921875],
                        [46.490625, 38.90668945312498],
                    ],
                ],
            },
            properties: {
                name: "亚美尼亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-170.72626953125, -14.351171875],
                        [-170.8205078125, -14.312109375],
                        [-170.568115234375, -14.266796875000011],
                        [-170.72626953125, -14.351171875],
                    ],
                ],
            },
            properties: {
                name: "美属萨摩亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [69.2824218750001, -49.05888671875002],
                            [69.16718750000004, -48.88291015624996],
                            [69.36875, -48.89042968749998],
                            [69.2824218750001, -49.05888671875002],
                        ],
                    ],
                    [
                        [
                            [69.18486328125002, -49.10957031250004],
                            [69.59277343749997, -48.97099609375005],
                            [69.64404296875003, -49.11738281250003],
                            [69.40507812500002, -49.18173828125],
                            [69.5423828125, -49.25566406250005],
                            [70.32021484375005, -49.05859374999996],
                            [70.55546875000007, -49.201464843750024],
                            [70.38613281250005, -49.433984374999966],
                            [70.16582031250002, -49.34296874999998],
                            [69.75996093750004, -49.430175781249986],
                            [69.98642578125006, -49.58164062500003],
                            [70.2477539062501, -49.53066406250003],
                            [70.12431640625002, -49.70439453124999],
                            [69.153125, -49.5296875],
                            [68.99296875000007, -49.704980468750016],
                            [68.81474609375002, -49.69960937499999],
                            [68.88339843750006, -49.16494140624995],
                            [68.76953125000003, -49.06591796875003],
                            [69.00244140624997, -48.661230468750006],
                            [69.13613281250005, -48.86103515625003],
                            [69.05214843750005, -49.08193359375001],
                            [69.18486328125002, -49.10957031250004],
                        ],
                    ],
                    [
                        [
                            [51.83457031250006, -46.43994140625],
                            [51.65927734375006, -46.37363281249999],
                            [51.7418945312501, -46.32685546874997],
                            [51.83457031250006, -46.43994140625],
                        ],
                    ],
                ],
            },
            properties: {
                name: "南极洲共和国",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-61.71606445312503, 17.037011718749994],
                        [-61.85966796874996, 17.013330078124966],
                        [-61.887109374999966, 17.09814453125],
                        [-61.81728515624994, 17.168945312500057],
                        [-61.71606445312503, 17.037011718749994],
                    ],
                ],
            },
            properties: {
                name: "安提瓜和巴布达",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [147.35605468750006, -43.396972656250014],
                            [147.30888671875007, -43.50078125000002],
                            [147.10498046875003, -43.43115234374996],
                            [147.28388671875004, -43.278906250000034],
                            [147.35605468750006, -43.396972656250014],
                        ],
                    ],
                    [
                        [
                            [145.04296875000003, -40.78671875],
                            [145.28300781250002, -40.76992187500002],
                            [146.31748046875006, -41.16347656250001],
                            [146.72343750000002, -41.07802734375001],
                            [146.84814453124997, -41.16806640624996],
                            [146.98984375000006, -40.99238281249997],
                            [147.45478515625004, -41.00166015624998],
                            [147.62167968750012, -40.844726562499986],
                            [147.87294921875005, -40.87255859374997],
                            [147.96875, -40.779589843750045],
                            [148.215234375, -40.85488281250002],
                            [148.34257812500007, -42.21533203124997],
                            [148.21367187500002, -41.97001953125],
                            [147.92441406250006, -42.5724609375],
                            [147.94541015625006, -43.18183593749997],
                            [147.7858398437501, -43.22001953125002],
                            [147.69892578125004, -43.12255859374997],
                            [147.64794921874997, -43.02060546874999],
                            [147.8, -42.928125],
                            [147.57382812500006, -42.84570312499997],
                            [147.4523437500001, -43.03339843750001],
                            [147.29794921875006, -42.790917968749994],
                            [147.24501953125005, -43.21591796874999],
                            [146.99697265625005, -43.15634765625002],
                            [147.07734375000004, -43.27587890625003],
                            [146.87392578125, -43.6125],
                            [146.54853515625004, -43.50888671874999],
                            [146.04316406250004, -43.547167968749974],
                            [145.99443359375007, -43.37607421875002],
                            [146.20800781249997, -43.31621093749999],
                            [145.8732421875001, -43.29238281250002],
                            [145.48759765625002, -42.92666015625004],
                            [145.19882812500006, -42.23085937500004],
                            [145.46826171874997, -42.492871093750026],
                            [145.51660156249997, -42.3544921875],
                            [145.33105468750003, -42.14707031250002],
                            [145.23486328124997, -42.19697265624997],
                            [145.23818359375, -42.01962890624999],
                            [144.76611328125003, -41.39003906249998],
                            [144.64609375000006, -40.980859375],
                            [144.71855468750002, -40.67226562500002],
                            [145.04296875000003, -40.78671875],
                        ],
                    ],
                    [
                        [
                            [148.23691406250006, -40.515136718749986],
                            [148.18779296875007, -40.592578125000045],
                            [148.11728515625012, -40.52148437499996],
                            [148.23691406250006, -40.515136718749986],
                        ],
                    ],
                    [
                        [
                            [144.784375, -40.506738281249966],
                            [144.74804687499997, -40.589453125000034],
                            [144.7833984375001, -40.434863281249974],
                            [144.784375, -40.506738281249966],
                        ],
                    ],
                    [
                        [
                            [148.32626953125006, -40.30693359375003],
                            [148.40400390625004, -40.486523437500026],
                            [148.02011718750012, -40.40419921874995],
                            [148.32626953125006, -40.30693359375003],
                        ],
                    ],
                    [
                        [
                            [148.000390625, -39.75761718750003],
                            [148.29736328125003, -39.985742187499966],
                            [148.31357421875012, -40.173535156250026],
                            [148.10566406250004, -40.26210937499995],
                            [147.76718750000012, -39.87031249999998],
                            [148.000390625, -39.75761718750003],
                        ],
                    ],
                    [
                        [
                            [143.92792968750004, -40.116113281249966],
                            [143.83857421875004, -39.90410156250003],
                            [144.00078125000007, -39.580175781250034],
                            [144.14101562500005, -39.953808593750026],
                            [143.92792968750004, -40.116113281249966],
                        ],
                    ],
                    [
                        [
                            [145.31445312500003, -38.49082031249996],
                            [145.35507812500012, -38.55703124999995],
                            [145.12841796875003, -38.52763671875],
                            [145.31445312500003, -38.49082031249996],
                        ],
                    ],
                    [
                        [
                            [137.59648437500007, -35.73867187499998],
                            [137.92890625000004, -35.72607421875],
                            [138.12343750000005, -35.85234375],
                            [137.67089843749997, -35.897949218750014],
                            [137.44843750000004, -36.07480468749999],
                            [137.20957031250012, -35.982421875],
                            [136.7550781250001, -36.03310546875002],
                            [136.540625, -35.89013671875003],
                            [136.63867187499997, -35.74882812500002],
                            [137.33408203125006, -35.59248046875004],
                            [137.58496093749997, -35.620214843750006],
                            [137.59648437500007, -35.73867187499998],
                        ],
                    ],
                    [
                        [
                            [153.53876953125004, -27.436425781250037],
                            [153.42656250000002, -27.70644531249998],
                            [153.43544921875, -27.40537109375002],
                            [153.53876953125004, -27.436425781250037],
                        ],
                    ],
                    [
                        [
                            [113.18300781250005, -26.053125],
                            [112.96425781250005, -25.78310546875001],
                            [112.94707031250002, -25.531542968750017],
                            [113.18300781250005, -26.053125],
                        ],
                    ],
                    [
                        [
                            [153.07744140625002, -25.75078125],
                            [152.97666015625012, -25.551367187499963],
                            [153.03808593750003, -25.193164062500003],
                            [153.22753906249997, -25.00576171875001],
                            [153.14375, -24.814843750000023],
                            [153.25693359375012, -24.72890625],
                            [153.35019531250012, -25.063085937499963],
                            [153.07744140625002, -25.75078125],
                        ],
                    ],
                    [
                        [
                            [151.14658203125006, -23.49082031250002],
                            [151.24013671875, -23.529687500000037],
                            [151.23828124999997, -23.77578125],
                            [151.03330078125006, -23.530175781250037],
                            [151.14658203125006, -23.49082031250002],
                        ],
                    ],
                    [
                        [
                            [115.44619140625005, -20.78779296875001],
                            [115.31806640625004, -20.850585937500014],
                            [115.43457031249997, -20.66796875000003],
                            [115.44619140625005, -20.78779296875001],
                        ],
                    ],
                    [
                        [
                            [149.04375, -20.29150390624997],
                            [148.93886718750005, -20.283691406249986],
                            [148.98105468750012, -20.153515625000026],
                            [149.04375, -20.29150390624997],
                        ],
                    ],
                    [
                        [
                            [146.27832031249997, -18.23125],
                            [146.29882812499997, -18.48476562500005],
                            [146.09882812500004, -18.251757812500003],
                            [146.27832031249997, -18.23125],
                        ],
                    ],
                    [
                        [
                            [139.45917968750004, -17.11455078124996],
                            [139.49277343750006, -16.990429687499983],
                            [139.57089843750006, -17.09443359375004],
                            [139.45917968750004, -17.11455078124996],
                        ],
                    ],
                    [
                        [
                            [139.50781250000003, -16.57304687499996],
                            [139.1595703125, -16.74169921875003],
                            [139.29296875000003, -16.467285156249986],
                            [139.58789062499997, -16.39521484374997],
                            [139.69775390624997, -16.514941406250017],
                            [139.50781250000003, -16.57304687499996],
                        ],
                    ],
                    [
                        [
                            [137.09365234375005, -15.778125],
                            [136.94267578125002, -15.711718749999989],
                            [137.00957031250007, -15.594824218749977],
                            [137.09365234375005, -15.778125],
                        ],
                    ],
                    [
                        [
                            [124.59726562500006, -15.40195312500002],
                            [124.52421875000002, -15.421484375],
                            [124.51933593750002, -15.26748046874998],
                            [124.59726562500006, -15.40195312500002],
                        ],
                    ],
                    [
                        [
                            [125.19882812500006, -14.57949218749998],
                            [125.0912109375, -14.59169921874998],
                            [125.15996093750002, -14.456054687499972],
                            [125.19882812500006, -14.57949218749998],
                        ],
                    ],
                    [
                        [
                            [136.71464843750002, -13.803906249999983],
                            [136.89082031250004, -13.786621093750014],
                            [136.74531250000004, -14.072656250000023],
                            [136.95078125000006, -14.184277343750026],
                            [136.89433593750002, -14.293066406249977],
                            [136.33544921875003, -14.211816406250037],
                            [136.42470703125, -13.864843749999963],
                            [136.6556640625, -13.675878906250006],
                            [136.71464843750002, -13.803906249999983],
                        ],
                    ],
                    [
                        [
                            [136.23740234375006, -13.824511718750003],
                            [136.12265625000012, -13.816601562499983],
                            [136.21542968750012, -13.664746093750054],
                            [136.23740234375006, -13.824511718750003],
                        ],
                    ],
                    [
                        [
                            [136.33867187500007, -11.602343749999989],
                            [136.18027343750006, -11.676757812499957],
                            [136.47929687500002, -11.465917968749991],
                            [136.33867187500007, -11.602343749999989],
                        ],
                    ],
                    [
                        [
                            [130.45927734375007, -11.679296875000034],
                            [130.60625, -11.816601562500026],
                            [130.04326171875007, -11.787304687500011],
                            [130.19755859375007, -11.658203125],
                            [130.15283203124997, -11.477539062499972],
                            [130.29492187499997, -11.33681640624998],
                            [130.45927734375007, -11.679296875000034],
                        ],
                    ],
                    [
                        [
                            [130.6188476562501, -11.376074218749991],
                            [131.02304687500006, -11.334375],
                            [131.26826171875004, -11.18984375],
                            [131.53857421874997, -11.436914062500037],
                            [130.95097656250007, -11.926464843750026],
                            [130.51191406250004, -11.617871093749955],
                            [130.38457031250002, -11.1921875],
                            [130.6188476562501, -11.376074218749991],
                        ],
                    ],
                    [
                        [
                            [136.59853515625, -11.378906249999943],
                            [136.52656250000004, -11.438867187499994],
                            [136.78027343749997, -11.0125],
                            [136.59853515625, -11.378906249999943],
                        ],
                    ],
                    [
                        [
                            [132.59335937500006, -11.302832031249991],
                            [132.48378906250005, -11.037304687499983],
                            [132.57880859375004, -10.968847656249977],
                            [132.59335937500006, -11.302832031249991],
                        ],
                    ],
                    [
                        [
                            [143.17890625000004, -11.954492187499966],
                            [143.11025390625, -12.303515625000017],
                            [143.40156250000004, -12.639941406249989],
                            [143.5866210937501, -13.443652343750031],
                            [143.54843750000012, -13.74101562499996],
                            [143.75634765625003, -14.348828124999969],
                            [143.96181640625005, -14.462890625000028],
                            [144.473046875, -14.231835937500023],
                            [144.64804687500006, -14.492480468750017],
                            [145.28769531250006, -14.943164062499989],
                            [145.42607421875002, -16.406152343749966],
                            [145.75478515625, -16.879492187500034],
                            [145.91210937499997, -16.9125],
                            [146.12587890625005, -17.63525390625],
                            [146.03222656249997, -18.272851562500037],
                            [146.3332031250001, -18.55371093749997],
                            [146.38339843750006, -18.97705078124997],
                            [147.13876953125006, -19.39316406250002],
                            [147.41855468750012, -19.378125],
                            [147.7423828125001, -19.770117187499977],
                            [148.759375, -20.28955078125003],
                            [148.88476562499997, -20.480859375],
                            [148.72998046874997, -20.4677734375],
                            [148.68369140625012, -20.58017578124999],
                            [149.20488281250007, -21.125097656249977],
                            [149.45410156249997, -21.57871093750002],
                            [149.70390625000002, -22.440527343750006],
                            [149.82246093750004, -22.389843749999983],
                            [149.97441406250007, -22.55068359374998],
                            [149.94189453125003, -22.30810546875003],
                            [150.07617187500003, -22.16445312499998],
                            [150.54130859375002, -22.55908203125],
                            [150.56855468750004, -22.38398437500004],
                            [150.67246093750012, -22.418164062499983],
                            [150.84316406250005, -23.4580078125],
                            [151.15380859375003, -23.784082031249994],
                            [151.83164062500006, -24.12294921875001],
                            [152.12988281250003, -24.59755859374998],
                            [152.45634765625007, -24.802441406249983],
                            [152.65429687499997, -25.201953125000017],
                            [152.91347656250005, -25.432128906250014],
                            [152.98496093750012, -25.816210937500003],
                            [153.16494140625, -25.964160156250045],
                            [153.11679687500006, -27.194433593750034],
                            [153.57568359375003, -28.24052734374999],
                            [153.6168945312501, -28.673046875],
                            [153.03056640625002, -30.563378906249994],
                            [152.94394531250012, -31.43486328124999],
                            [152.5592773437501, -32.045703125],
                            [152.4704101562501, -32.439062500000034],
                            [152.13652343750002, -32.678125],
                            [152.1642578125001, -32.75742187499996],
                            [151.812890625, -32.90107421875001],
                            [151.29208984375012, -33.580957031249966],
                            [151.28027343750003, -33.92666015625005],
                            [151.12480468750007, -34.00527343749998],
                            [151.23154296875006, -34.0296875],
                            [150.8712890625001, -34.49912109374996],
                            [150.80458984375, -35.01289062500001],
                            [150.19531249999997, -35.83359374999996],
                            [149.93271484375012, -37.528515625000026],
                            [149.480859375, -37.77119140625],
                            [147.87675781250002, -37.93417968749998],
                            [146.8568359375, -38.663476562499966],
                            [146.21748046875004, -38.72744140625004],
                            [146.33662109375004, -38.89423828125],
                            [146.46660156250007, -38.84033203125003],
                            [146.40000000000012, -39.14550781250003],
                            [146.1583984375001, -38.86572265624996],
                            [145.93535156250002, -38.90175781250002],
                            [145.79082031250007, -38.66699218749997],
                            [145.39726562500002, -38.53535156249998],
                            [145.54218750000004, -38.39384765625002],
                            [145.4757812500001, -38.24375],
                            [145.29277343750002, -38.237597656249974],
                            [144.95957031250012, -38.500781250000045],
                            [144.71777343749997, -38.34033203125004],
                            [144.91142578125007, -38.34404296874999],
                            [145.11992187500007, -38.091308593750014],
                            [144.89130859375004, -37.899804687499994],
                            [144.39550781250003, -38.13691406249998],
                            [144.6652343750001, -38.20996093750003],
                            [143.53896484375005, -38.82089843749998],
                            [142.45585937500002, -38.38632812499999],
                            [141.725, -38.27138671875002],
                            [141.5939453125001, -38.38779296875002],
                            [141.42421875, -38.36347656250004],
                            [141.0109375000001, -38.07695312500003],
                            [140.39042968750007, -37.89667968749998],
                            [139.78427734375012, -37.24580078124998],
                            [139.85732421875, -36.662109375],
                            [139.72900390625003, -36.37138671875002],
                            [138.9689453125001, -35.58076171874997],
                            [139.17802734375007, -35.52304687500002],
                            [139.289453125, -35.61132812499997],
                            [139.28251953125002, -35.375390624999966],
                            [138.521875, -35.6423828125],
                            [138.184375, -35.612695312499994],
                            [138.5111328125, -35.02441406249996],
                            [138.48994140625004, -34.76357421875002],
                            [138.0892578125, -34.16982421875002],
                            [137.69169921875002, -35.14296875000004],
                            [136.88359375000007, -35.23974609375004],
                            [137.01425781250012, -34.91582031250003],
                            [137.39101562500005, -34.91328124999997],
                            [137.49384765625004, -34.16113281250003],
                            [137.9318359375001, -33.57910156250003],
                            [137.85234375000007, -33.20078124999996],
                            [137.99257812500005, -33.094238281250014],
                            [137.78320312500003, -32.578125],
                            [137.79091796875, -32.82324218749996],
                            [137.44228515625, -33.1935546875],
                            [137.23730468750003, -33.62949218749999],
                            [136.43066406249997, -34.02998046875004],
                            [135.891015625, -34.660937499999974],
                            [135.96972656249997, -34.98183593749998],
                            [135.7923828125, -34.863281249999986],
                            [135.64755859375006, -34.93964843750001],
                            [135.12304687499997, -34.58574218750003],
                            [135.21679687499997, -34.48730468749996],
                            [135.45, -34.58105468749996],
                            [135.21894531250004, -33.959765625000045],
                            [134.88876953125012, -33.62636718749998],
                            [134.79101562499997, -33.32832031250001],
                            [134.60771484375002, -33.19013671875001],
                            [134.30126953124997, -33.16503906249996],
                            [134.17353515625004, -32.979101562500006],
                            [134.10039062500007, -32.748632812500034],
                            [134.22714843750006, -32.73056640624999],
                            [134.23417968750007, -32.54853515625004],
                            [133.66533203125007, -32.207226562500054],
                            [133.21210937500004, -32.18378906249998],
                            [132.75742187500012, -31.95625],
                            [132.21464843750002, -32.00712890624996],
                            [131.14365234375006, -31.49570312500005],
                            [130.78300781250002, -31.604003906249986],
                            [129.1876953125001, -31.659960937500017],
                            [127.31982421874997, -32.2640625],
                            [125.91718750000004, -32.296972656250034],
                            [124.75878906250003, -32.882714843749994],
                            [124.24375, -33.01523437499999],
                            [123.50683593749997, -33.916210937500054],
                            [122.15097656250006, -33.99179687499999],
                            [122.06113281250006, -33.874414062499966],
                            [121.40507812500007, -33.826757812500034],
                            [119.85410156250012, -33.97470703124998],
                            [119.45058593750005, -34.368261718750034],
                            [118.89531250000007, -34.47988281250004],
                            [118.13554687500002, -34.98662109374999],
                            [117.58193359375005, -35.09775390624998],
                            [116.51718750000012, -34.98789062499998],
                            [115.98671875000005, -34.795019531250034],
                            [115.56503906250012, -34.42578125000003],
                            [115.00878906250003, -34.25585937499997],
                            [114.9938476562501, -33.51533203125],
                            [115.3587890625, -33.63994140624999],
                            [115.68300781250005, -33.19287109375003],
                            [115.6984375000001, -31.694531250000054],
                            [115.07792968750007, -30.560449218750023],
                            [114.85683593750005, -29.14296875],
                            [114.16513671875012, -28.08066406250002],
                            [114.028125, -27.34726562499999],
                            [113.18476562500004, -26.182226562499963],
                            [113.32324218749997, -26.243847656249997],
                            [113.35605468750012, -26.080468750000023],
                            [113.58164062500006, -26.558105468749986],
                            [113.73369140625002, -26.59511718749998],
                            [113.83642578125003, -26.50058593749999],
                            [113.85283203125007, -26.33212890625005],
                            [113.39531250000002, -25.71328125],
                            [113.4513671875001, -25.599121093750014],
                            [113.7130859375001, -25.83076171875004],
                            [113.72373046875006, -26.129785156250037],
                            [113.85390625, -26.01445312499999],
                            [113.99199218750007, -26.32148437500001],
                            [114.09033203124997, -26.393652343749963],
                            [114.21572265625, -26.289453124999966],
                            [114.2142578125, -25.851562500000014],
                            [113.41767578125004, -24.435644531250034],
                            [113.48984375000012, -23.869628906250014],
                            [113.7570312500001, -23.418164062500054],
                            [113.79511718750004, -22.91455078125003],
                            [113.68281250000004, -22.637792968749963],
                            [114.02285156250005, -21.881445312499977],
                            [114.12392578125005, -21.828613281249957],
                            [114.14160156250003, -22.483105468749983],
                            [114.37773437500007, -22.341503906249997],
                            [114.70927734375002, -21.82343749999997],
                            [115.45615234375012, -21.49169921874997],
                            [116.0109375000001, -21.030371093749963],
                            [116.7067382812501, -20.653808593749986],
                            [117.40625, -20.72119140625003],
                            [118.19921875000003, -20.37519531249997],
                            [118.75146484374997, -20.261914062499983],
                            [119.10449218749997, -19.995312500000026],
                            [119.58593750000003, -20.03828125],
                            [120.99794921875, -19.604394531249966],
                            [121.33769531250002, -19.31992187500002],
                            [121.83378906250002, -18.477050781249986],
                            [122.34541015625004, -18.11191406250002],
                            [122.14746093749997, -17.54902343750001],
                            [122.2609375000001, -17.135742187500014],
                            [122.72041015625004, -16.78769531249999],
                            [122.97070312499997, -16.436816406250003],
                            [123.56308593750006, -17.520898437499966],
                            [123.59355468750007, -17.03037109375005],
                            [123.83105468750003, -17.120800781249997],
                            [123.8744140625, -16.918652343750026],
                            [123.4904296875001, -16.49072265624997],
                            [123.62597656249997, -16.416308593750003],
                            [123.60703125000006, -16.224023437499994],
                            [123.72890625, -16.192480468749963],
                            [123.85917968750007, -16.38232421875],
                            [124.04443359374997, -16.264941406249974],
                            [124.30039062500006, -16.388281249999977],
                            [124.77197265624997, -16.40263671874996],
                            [124.40488281250006, -16.298925781249977],
                            [124.41640625, -16.133496093750026],
                            [124.5768554687501, -16.11367187499998],
                            [124.64853515625012, -15.870214843750034],
                            [124.50429687500005, -15.972460937499989],
                            [124.38164062500002, -15.758203125000037],
                            [124.43955078125012, -15.493554687500037],
                            [124.56162109375012, -15.496289062499969],
                            [124.69257812500004, -15.273632812499997],
                            [125.06298828125003, -15.44228515624998],
                            [125.0729492187501, -15.306738281249991],
                            [124.90917968750003, -15.310058593749957],
                            [124.83906250000004, -15.160742187500006],
                            [125.03818359375012, -15.004101562499969],
                            [125.35566406250004, -15.119824218750011],
                            [125.17871093749997, -14.714746093749994],
                            [125.57978515625004, -14.483203124999989],
                            [125.62773437500002, -14.256640625000017],
                            [125.70458984374997, -14.29140625],
                            [125.66162109375003, -14.529492187500011],
                            [125.81953125000004, -14.469140624999966],
                            [125.890625, -14.61796875],
                            [126.0207031250001, -14.49453125],
                            [126.0539062500001, -13.977246093750026],
                            [126.1842773437501, -14.00205078125002],
                            [126.25849609375004, -14.163574218749972],
                            [126.403125, -14.018945312499994],
                            [126.5697265625, -14.160937499999974],
                            [126.7806640625, -13.955175781249977],
                            [126.77558593750004, -13.788476562500037],
                            [126.90322265625, -13.744140624999972],
                            [127.45761718750006, -14.031445312499969],
                            [128.18046875000007, -14.711621093749983],
                            [128.06943359375012, -15.329296874999969],
                            [128.15546875000004, -15.225585937499972],
                            [128.25468750000002, -15.298535156250011],
                            [128.175, -15.043164062500026],
                            [128.57578125000006, -14.774511718750006],
                            [129.05820312500012, -14.884375],
                            [129.21582031249997, -15.160253906249991],
                            [129.26757812500003, -14.871484375000051],
                            [129.63476562499997, -15.139746093749991],
                            [129.637109375, -14.850976562500037],
                            [129.84873046875012, -14.828906249999989],
                            [129.60468750000004, -14.647070312499977],
                            [129.69794921875004, -14.557421875000017],
                            [129.37871093750002, -14.39248046874998],
                            [129.70986328125, -13.979980468749972],
                            [129.83886718749997, -13.572949218749997],
                            [130.25976562500003, -13.30224609375],
                            [130.1349609375001, -13.145507812499957],
                            [130.1681640625001, -12.957421875],
                            [130.39990234374997, -12.68789062499999],
                            [130.61748046875007, -12.646875],
                            [130.62265625000006, -12.43105468749998],
                            [130.8673828125001, -12.557812499999955],
                            [130.87382812500007, -12.367187500000028],
                            [131.29160156250006, -12.067871093749972],
                            [131.43828125000002, -12.27695312500002],
                            [132.06406250000006, -12.28076171875],
                            [132.25322265625007, -12.186035156249972],
                            [132.41103515625, -12.295117187499997],
                            [132.51054687500002, -12.134863281250034],
                            [132.71279296875, -12.1234375],
                            [132.63046875000012, -12.035156249999972],
                            [132.67421875000005, -11.649023437499991],
                            [132.47519531250006, -11.491503906249974],
                            [132.07285156250006, -11.474707031250006],
                            [131.82246093750004, -11.302441406249997],
                            [131.96152343750006, -11.180859375000011],
                            [132.15546875000004, -11.311132812499991],
                            [132.33398437499997, -11.223535156249994],
                            [132.6828125000001, -11.505566406249997],
                            [132.96103515625012, -11.407324218749963],
                            [133.18525390625004, -11.705664062499991],
                            [133.90419921875, -11.832031249999972],
                            [134.4173828125, -12.052734375],
                            [134.73027343750002, -11.984375],
                            [135.02968750000005, -12.19375],
                            [135.2179687500001, -12.221679687499957],
                            [135.92246093750012, -11.825781250000034],
                            [135.70439453125007, -12.209863281250037],
                            [136.00849609375004, -12.19140625],
                            [136.08183593750007, -12.422460937500006],
                            [136.26064453125, -12.433789062499997],
                            [136.32851562500005, -12.305566406249994],
                            [136.24990234375, -12.173046875],
                            [136.44335937499997, -11.951464843749974],
                            [136.7194335937501, -12.226464843749952],
                            [136.89746093749997, -12.243554687499966],
                            [136.94746093750004, -12.34990234374996],
                            [136.53701171875, -12.784277343749991],
                            [136.59433593750012, -13.003808593750051],
                            [136.46103515625006, -13.225195312500034],
                            [136.29414062500004, -13.137988281250031],
                            [135.92734375000012, -13.304296874999977],
                            [135.95449218750005, -13.934863281250017],
                            [135.40517578125005, -14.758203124999966],
                            [135.4533203125001, -14.923144531250003],
                            [136.20537109375002, -15.403417968749963],
                            [136.29140625000005, -15.570117187500003],
                            [136.70488281250007, -15.685253906250011],
                            [136.78466796874997, -15.89423828125004],
                            [137.00214843750004, -15.878320312499994],
                            [137.70371093750006, -16.233007812499963],
                            [138.24501953125005, -16.718359374999977],
                            [139.00986328125006, -16.899316406249994],
                            [139.2484375, -17.328613281249957],
                            [140.03583984375004, -17.702636718749957],
                            [140.51113281250005, -17.62451171875003],
                            [140.83046875, -17.414453125000037],
                            [141.29140625, -16.46347656250002],
                            [141.62548828124997, -15.056640625000014],
                            [141.52294921875003, -14.470117187499994],
                            [141.59433593750006, -14.152832031250014],
                            [141.47255859375, -13.797558593750011],
                            [141.64541015625, -13.259082031250003],
                            [141.61357421875002, -12.943457031250006],
                            [141.92978515625006, -12.73984375],
                            [141.67773437500003, -12.491406250000011],
                            [141.68857421875012, -12.351074218750028],
                            [141.87050781250005, -11.9755859375],
                            [141.96113281250004, -12.054296874999963],
                            [142.168359375, -10.946582031249974],
                            [142.45644531250005, -10.707324218749989],
                            [142.60507812500012, -10.748242187499983],
                            [142.55273437500003, -10.874414062500023],
                            [142.7796875, -11.115332031249977],
                            [142.87255859374997, -11.821386718750034],
                            [143.17890625000004, -11.954492187499966],
                        ],
                    ],
                    [
                        [
                            [142.2748046875, -10.704785156250011],
                            [142.19140624999997, -10.762011718750031],
                            [142.1310546875001, -10.640625],
                            [142.19794921875004, -10.59199218750004],
                            [142.2748046875, -10.704785156250011],
                        ],
                    ],
                ],
            },
            properties: {
                name: "澳大利亚",
                childNum: 30,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [16.953125, 48.598828125],
                        [16.86542968750001, 48.3869140625],
                        [17.147363281250023, 48.00595703125],
                        [17.06660156250001, 47.707568359374996],
                        [16.421289062500023, 47.674462890624994],
                        [16.676562500000017, 47.536035156249994],
                        [16.44287109375, 47.39951171875],
                        [16.453417968750017, 47.006787109375],
                        [16.093066406250017, 46.86328125],
                        [15.957617187500006, 46.677636718749994],
                        [14.893261718750011, 46.605908203125],
                        [14.5498046875, 46.399707031249996],
                        [13.7, 46.520263671875],
                        [13.490039062500017, 46.555566406249994],
                        [13.3515625, 46.557910156249996],
                        [13.16875, 46.57265625],
                        [12.479199218750011, 46.672509765624994],
                        [12.38828125, 46.70263671875],
                        [12.330078125, 46.759814453124996],
                        [12.267968750000023, 46.835888671875],
                        [12.154101562500017, 46.93525390625],
                        [12.130761718750023, 46.98476562499999],
                        [12.16552734375, 47.028173828125],
                        [12.201269531250006, 47.060888671875],
                        [12.197167968750023, 47.075],
                        [12.16943359375, 47.08212890625],
                        [11.775683593750017, 46.986083984375],
                        [11.527539062500011, 46.997412109375],
                        [11.433203125, 46.983056640624994],
                        [11.244433593750017, 46.97568359375],
                        [11.133886718750006, 46.936181640624994],
                        [11.0634765625, 46.859130859375],
                        [11.025097656250011, 46.79697265625],
                        [10.993261718750006, 46.777001953124994],
                        [10.92734375, 46.769482421875],
                        [10.828906250000017, 46.775244140625],
                        [10.759765625, 46.793310546875],
                        [10.689257812500017, 46.84638671875],
                        [10.579785156250011, 46.8537109375],
                        [10.479394531250023, 46.855126953124994],
                        [10.452832031250011, 46.86494140625],
                        [10.45458984375, 46.8994140625],
                        [10.414941406250023, 46.964404296874996],
                        [10.349414062500017, 46.98476562499999],
                        [10.133496093750011, 46.851513671875],
                        [9.580273437500011, 47.057373046875],
                        [9.527539062500011, 47.270751953125],
                        [9.625878906250023, 47.467041015625],
                        [9.524023437500006, 47.52421875],
                        [9.748925781250023, 47.575537109375],
                        [9.839160156250017, 47.552294921874996],
                        [9.971582031250023, 47.505322265625],
                        [10.034082031250023, 47.473583984375],
                        [10.059863281250017, 47.449072265625],
                        [10.066308593750023, 47.393359375],
                        [10.200292968750006, 47.363427734374994],
                        [10.183007812500023, 47.27880859375],
                        [10.369140625, 47.366064453125],
                        [10.40390625, 47.4169921875],
                        [10.439453125, 47.5515625],
                        [10.482812500000023, 47.541796875],
                        [10.65869140625, 47.547216796875],
                        [10.741601562500023, 47.52412109375],
                        [10.873046875, 47.52021484375],
                        [11.0419921875, 47.393115234374996],
                        [12.185644531250006, 47.61953125],
                        [12.203808593750011, 47.646728515625],
                        [12.196875, 47.70908203125],
                        [12.209277343750017, 47.71826171875],
                        [12.268359375000017, 47.702734375],
                        [12.353540736607165, 47.70264787946429],
                        [12.492553013392856, 47.68551897321428],
                        [12.685839843750017, 47.669335937499994],
                        [12.771386718750023, 47.639404296875],
                        [12.796191406250017, 47.60703125],
                        [12.781152343750023, 47.5904296875],
                        [12.7828125, 47.56416015625],
                        [12.809375, 47.5421875],
                        [12.87890625, 47.5064453125],
                        [12.968066406250017, 47.47568359375],
                        [13.014355468750011, 47.478076171874996],
                        [13.031542968750017, 47.5080078125],
                        [13.047949218750006, 47.579150390624996],
                        [13.054101562500023, 47.655126953125],
                        [12.897656250000011, 47.721875],
                        [12.953515625000023, 47.890625],
                        [12.760351562500006, 48.106982421874996],
                        [13.215234375000023, 48.301904296874994],
                        [13.322851562500006, 48.33125],
                        [13.409375, 48.394140625],
                        [13.459863281250023, 48.56455078125],
                        [13.4716796875, 48.571826171874996],
                        [13.486621093750017, 48.581835937499996],
                        [13.636623883928596, 48.580904017857144],
                        [13.785351562500011, 48.587451171874996],
                        [13.798828125, 48.6216796875],
                        [13.802929687500011, 48.747509765625],
                        [13.814746093750017, 48.766943359375],
                        [14.049121093750017, 48.602490234375],
                        [14.691308593750023, 48.59921875],
                        [15.066796875000023, 48.997851562499996],
                        [16.057226562500006, 48.754785156249994],
                        [16.543554687500006, 48.796240234375],
                        [16.953125, 48.598828125],
                    ],
                ],
            },
            properties: {
                name: "奥地利",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [46.1144531250001, 38.877783203125034],
                            [45.4796875000001, 39.00625],
                            [44.81718750000002, 39.65043945312496],
                            [44.76826171875004, 39.70351562500005],
                            [45.03164062500005, 39.76513671874997],
                            [45.15283203125003, 39.58266601562502],
                            [45.456835937500074, 39.494482421875006],
                            [45.78447265625002, 39.54560546875001],
                            [45.76630859375004, 39.37846679687499],
                            [45.977441406249994, 39.24389648437503],
                            [46.1144531250001, 38.877783203125034],
                        ],
                    ],
                    [
                        [
                            [48.572851562500006, 41.84448242187503],
                            [49.45673828125004, 40.79985351562502],
                            [49.77597656250006, 40.583984375],
                            [50.18251953125005, 40.50478515625002],
                            [50.3659179687501, 40.279492187499955],
                            [49.91884765625005, 40.31640625000003],
                            [49.55117187499999, 40.19414062499999],
                            [49.3244140625001, 39.60834960937501],
                            [49.36279296875003, 39.349560546874955],
                            [49.16533203125002, 39.03027343750003],
                            [49.013476562500074, 39.13398437500001],
                            [48.85449218750003, 38.83881835937501],
                            [48.86875, 38.43549804687498],
                            [48.59267578125005, 38.41108398437498],
                            [47.99648437499999, 38.85375976562503],
                            [48.292089843750006, 39.01884765624999],
                            [48.10439453125005, 39.241113281249994],
                            [48.322167968749994, 39.39907226562502],
                            [47.995898437500074, 39.683935546875034],
                            [46.490625, 38.90668945312498],
                            [46.400292968749994, 39.1921875],
                            [46.584765625000074, 39.22368164062499],
                            [46.36523437500003, 39.402490234374994],
                            [46.48144531249997, 39.55517578125003],
                            [46.202050781249994, 39.59448242187503],
                            [45.57978515625004, 39.9775390625],
                            [45.8859375000001, 40.024853515624955],
                            [45.96464843750002, 40.233789062499966],
                            [45.45439453125002, 40.532373046874966],
                            [45.37890624999997, 40.67358398437506],
                            [45.5875, 40.846923828125],
                            [45.07050781250004, 41.075585937499966],
                            [45.188574218750006, 41.14741210937504],
                            [45.001367187499994, 41.29096679687498],
                            [45.2171875, 41.423193359375006],
                            [45.28095703125004, 41.449560546875034],
                            [46.086523437500006, 41.183837890625],
                            [46.43095703125002, 41.077050781249994],
                            [46.534375, 41.08857421875004],
                            [46.62636718750005, 41.15966796875006],
                            [46.66240234375002, 41.24550781250002],
                            [46.67255859375004, 41.28681640625001],
                            [46.61894531250002, 41.34375],
                            [46.30546875000002, 41.507714843749994],
                            [46.18427734375004, 41.70214843749997],
                            [46.42988281250004, 41.890966796875006],
                            [46.74931640625002, 41.812597656250006],
                            [47.31767578125002, 41.28242187500001],
                            [47.79101562499997, 41.19926757812502],
                            [48.572851562500006, 41.84448242187503],
                        ],
                    ],
                ],
            },
            properties: {
                name: "阿塞拜疆",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [30.553613281250023, -2.400097656250011],
                        [30.53369140625, -2.42626953125],
                        [30.441992187500006, -2.613476562500011],
                        [30.424218750000023, -2.6416015625],
                        [30.47333984375001, -2.6943359375],
                        [30.42402343750001, -2.824023437500003],
                        [30.433496093750023, -2.87451171875],
                        [30.515039062500023, -2.917578125],
                        [30.604296875000017, -2.935253906250011],
                        [30.70947265625, -2.977246093750011],
                        [30.7802734375, -2.98486328125],
                        [30.811132812500006, -3.116406250000011],
                        [30.79023437500001, -3.274609375000011],
                        [30.4, -3.65390625],
                        [29.947265625, -4.307324218750011],
                        [29.7177734375, -4.455859375],
                        [29.403222656250023, -4.449316406250006],
                        [29.211816406250023, -3.833789062500003],
                        [29.224414062500017, -3.053515625],
                        [29.01435546875001, -2.72021484375],
                        [29.10205078125, -2.595703125],
                        [29.390234375, -2.80859375],
                        [29.698046875000017, -2.794726562500003],
                        [29.8681640625, -2.71640625],
                        [29.93017578125, -2.339550781250011],
                        [30.117285156250006, -2.416601562500006],
                        [30.408496093750017, -2.31298828125],
                        [30.553613281250023, -2.400097656250011],
                    ],
                ],
            },
            properties: {
                name: "布隆迪",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [5.693554687500011, 50.774755859375006],
                        [5.993945312500017, 50.75043945312504],
                        [6.340917968750006, 50.451757812500034],
                        [6.116503906250045, 50.120996093749966],
                        [6.08906250000004, 50.15458984374996],
                        [6.054785156249977, 50.154296875],
                        [5.976269531250068, 50.167187499999955],
                        [5.866894531250068, 50.08281250000002],
                        [5.817382812500028, 50.01269531250003],
                        [5.7880859375, 49.96123046875002],
                        [5.744042968749994, 49.91962890624998],
                        [5.789746093749983, 49.53828125000001],
                        [5.50732421875, 49.51088867187502],
                        [4.867578125000051, 49.78813476562502],
                        [4.818652343750045, 50.153173828125034],
                        [4.545019531250063, 49.96025390624999],
                        [4.149316406250023, 49.971582031249994],
                        [4.174609375000017, 50.24648437500005],
                        [3.689355468750023, 50.30605468750002],
                        [3.595410156250068, 50.47734374999999],
                        [3.27333984375008, 50.53154296875002],
                        [3.10683593750008, 50.779443359374994],
                        [2.759375, 50.750634765624994],
                        [2.52490234375, 51.097119140624955],
                        [3.35009765625, 51.37768554687503],
                        [3.43251953125008, 51.24575195312505],
                        [3.902050781250011, 51.20766601562502],
                        [4.226171875000034, 51.38647460937503],
                        [5.03095703125004, 51.46909179687498],
                        [5.214160156250045, 51.278955078124966],
                        [5.796484375000034, 51.153076171875],
                        [5.693554687500011, 50.774755859375006],
                    ],
                ],
            },
            properties: {
                name: "比利时",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [3.595410156250011, 11.6962890625],
                        [3.553906250000011, 11.631884765624989],
                        [3.490527343750017, 11.49921875],
                        [3.48779296875, 11.395410156249994],
                        [3.638867187500011, 11.176855468749991],
                        [3.65625, 11.154589843749989],
                        [3.6953125, 11.1203125],
                        [3.71640625, 11.07958984375],
                        [3.7568359375, 10.76875],
                        [3.83447265625, 10.607421875],
                        [3.771777343750017, 10.417626953124994],
                        [3.646582031250006, 10.408984374999989],
                        [3.60205078125, 10.004541015624994],
                        [3.3251953125, 9.778466796874994],
                        [3.044921875, 9.083837890624991],
                        [2.774804687500023, 9.048535156249997],
                        [2.703125, 8.371826171875],
                        [2.68603515625, 7.873730468749997],
                        [2.719335937500006, 7.616259765624989],
                        [2.7509765625, 7.541894531249994],
                        [2.78515625, 7.476855468749989],
                        [2.783984375000017, 7.443408203124989],
                        [2.765820312500011, 7.422509765624994],
                        [2.75048828125, 7.395068359374989],
                        [2.756738281250023, 7.067919921874989],
                        [2.721386718750011, 6.980273437499989],
                        [2.731738281250017, 6.852832031249989],
                        [2.7529296875, 6.771630859374994],
                        [2.774609375000011, 6.711718749999989],
                        [2.753710937500017, 6.661767578124994],
                        [2.735644531250017, 6.595703125],
                        [2.706445312500023, 6.369238281249991],
                        [1.62265625, 6.216796875],
                        [1.777929687500006, 6.294628906249997],
                        [1.530957031250011, 6.992431640625],
                        [1.624707031250011, 6.997314453125],
                        [1.600195312500006, 9.050048828125],
                        [1.3857421875, 9.361669921874991],
                        [1.330078125, 9.996972656249994],
                        [0.763378906250011, 10.386669921874997],
                        [0.900488281250006, 10.993261718749991],
                        [1.4267578125, 11.447119140624991],
                        [1.980371093750023, 11.418408203124997],
                        [2.38916015625, 11.897070312499991],
                        [2.366015625000017, 12.221923828125],
                        [2.805273437500006, 12.383837890624989],
                        [3.595410156250011, 11.6962890625],
                    ],
                ],
            },
            properties: {
                name: "贝宁",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [0.217480468750011, 14.911474609374991],
                        [0.163867187500017, 14.497216796874994],
                        [0.382519531250011, 14.245800781249997],
                        [0.42919921875, 13.972119140624997],
                        [0.6181640625, 13.703417968750003],
                        [1.201171875, 13.357519531249991],
                        [0.988476562500011, 13.36484375],
                        [0.9873046875, 13.041894531249994],
                        [1.56494140625, 12.635400390624994],
                        [2.104589843750006, 12.701269531249991],
                        [2.226269531250011, 12.466064453125],
                        [2.072949218750011, 12.309375],
                        [2.38916015625, 11.897070312499991],
                        [1.980371093750023, 11.418408203124997],
                        [1.4267578125, 11.447119140624991],
                        [0.900488281250006, 10.993261718749991],
                        [0.49267578125, 10.954980468749994],
                        [-0.068603515625, 11.115625],
                        [-0.299462890624994, 11.166894531249994],
                        [-0.627148437499983, 10.927392578124994],
                        [-1.04248046875, 11.010058593749989],
                        [-2.829931640624977, 10.998388671874991],
                        [-2.914892578124977, 10.592333984374989],
                        [-2.791162109374994, 10.432421874999989],
                        [-2.780517578125, 9.745849609375],
                        [-2.765966796874977, 9.658056640624991],
                        [-2.706201171874994, 9.533935546875],
                        [-2.695849609374989, 9.481347656249994],
                        [-2.7171875, 9.457128906249991],
                        [-2.7666015625, 9.424707031249994],
                        [-2.816748046874977, 9.425830078124989],
                        [-2.875146484374994, 9.500927734374997],
                        [-2.90087890625, 9.534619140624997],
                        [-2.948144531249994, 9.610742187499994],
                        [-2.98828125, 9.687353515624991],
                        [-3.042626953124994, 9.720898437499997],
                        [-3.095800781249977, 9.752099609374994],
                        [-3.160693359374989, 9.849169921874989],
                        [-3.223535156249994, 9.895458984374997],
                        [-3.289697265624994, 9.882226562499994],
                        [-3.581152343749977, 9.92431640625],
                        [-3.790625, 9.9171875],
                        [-4.18115234375, 9.78173828125],
                        [-4.267187499999977, 9.743261718749991],
                        [-4.332226562499983, 9.645703125],
                        [-4.406201171874983, 9.647998046874989],
                        [-4.526611328125, 9.723486328124991],
                        [-4.625830078124977, 9.713574218749997],
                        [-4.721777343749977, 9.756542968749997],
                        [-5.262304687499977, 10.319677734374991],
                        [-5.523535156249977, 10.426025390625],
                        [-5.490478515625, 11.042382812499994],
                        [-5.250244140625, 11.375781249999989],
                        [-5.288134765624989, 11.827929687499989],
                        [-4.699316406249977, 12.076171875],
                        [-4.4287109375, 12.337597656249997],
                        [-4.480615234374994, 12.672216796874991],
                        [-4.227099609374989, 12.793701171875],
                        [-4.328710937499977, 13.119042968749994],
                        [-4.151025390624994, 13.306201171875003],
                        [-3.947314453124989, 13.402197265624991],
                        [-3.527636718749989, 13.182714843749991],
                        [-3.3017578125, 13.28076171875],
                        [-3.248632812499977, 13.658349609374994],
                        [-2.950830078124994, 13.6484375],
                        [-2.873925781249994, 13.950732421875003],
                        [-2.586718749999989, 14.227587890625003],
                        [-2.113232421874983, 14.16845703125],
                        [-1.97304687499999, 14.45654296875],
                        [-1.049560546875, 14.81953125],
                        [-0.760449218749983, 15.047753906249994],
                        [-0.235888671874989, 15.059423828124991],
                        [0.217480468750011, 14.911474609374991],
                    ],
                ],
            },
            properties: {
                name: "布基纳法索",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [91.94921875000003, 21.50805664062503],
                            [91.85947265625012, 21.532958984375057],
                            [91.90771484374997, 21.722949218750017],
                            [91.94921875000003, 21.50805664062503],
                        ],
                    ],
                    [
                        [
                            [91.87382812500002, 21.832128906249977],
                            [91.8375976562501, 21.750244140625],
                            [91.85068359375012, 21.927050781250045],
                            [91.87382812500002, 21.832128906249977],
                        ],
                    ],
                    [
                        [
                            [91.15078125000005, 22.175195312499966],
                            [91.04472656250002, 22.10517578125001],
                            [91.0794921875, 22.519726562499983],
                            [91.15078125000005, 22.175195312499966],
                        ],
                    ],
                    [
                        [
                            [91.55673828125006, 22.38222656250005],
                            [91.41132812500004, 22.475683593750006],
                            [91.45605468749997, 22.61650390624999],
                            [91.55673828125006, 22.38222656250005],
                        ],
                    ],
                    [
                        [
                            [90.77763671875007, 22.089306640624983],
                            [90.51503906250005, 22.06513671875001],
                            [90.68046875000007, 22.327490234375006],
                            [90.50292968749997, 22.835351562499994],
                            [90.59648437500002, 22.863525390625057],
                            [90.86816406250003, 22.48486328125],
                            [90.77763671875007, 22.089306640624983],
                        ],
                    ],
                    [
                        [
                            [88.94072265625002, 26.24536132812497],
                            [88.97041015625004, 26.250878906250023],
                            [88.95195312500002, 26.412109375],
                            [89.01865234375012, 26.410253906249977],
                            [89.28925781250004, 26.03759765625],
                            [89.54990234375006, 26.005273437499994],
                            [89.57275390625003, 26.13232421875003],
                            [89.67089843750003, 26.21381835937504],
                            [89.8229492187501, 25.94140625000003],
                            [89.82490234375004, 25.56015625],
                            [89.80087890625012, 25.33613281250001],
                            [89.81406250000006, 25.305371093749955],
                            [89.86630859375012, 25.293164062499955],
                            [90.11962890625003, 25.21997070312497],
                            [90.61308593750002, 25.16772460937497],
                            [92.04970703125005, 25.16948242187499],
                            [92.46835937500006, 24.94414062499999],
                            [92.38496093750004, 24.848779296875023],
                            [92.25126953125007, 24.895068359375045],
                            [92.22832031250002, 24.88134765625],
                            [92.22666015625012, 24.77099609374997],
                            [92.11748046875002, 24.493945312500017],
                            [92.06416015625004, 24.374365234375006],
                            [91.84619140624997, 24.17529296875003],
                            [91.72656250000003, 24.20507812499997],
                            [91.35019531250012, 24.06049804687501],
                            [91.16044921875007, 23.66064453125],
                            [91.359375, 23.06835937500003],
                            [91.43623046875004, 23.19990234375001],
                            [91.55351562500002, 22.991552734375006],
                            [91.61953125, 22.97968750000001],
                            [91.75097656250003, 23.053515625000017],
                            [91.75419921875007, 23.287304687499955],
                            [91.79003906249997, 23.361035156249983],
                            [91.937890625, 23.504687500000017],
                            [91.92949218750007, 23.598242187499977],
                            [91.92958984375, 23.68598632812501],
                            [91.97851562500003, 23.691992187499977],
                            [92.04404296875006, 23.677783203125017],
                            [92.24609375000003, 23.683593750000057],
                            [92.33378906250002, 23.242382812499955],
                            [92.36162109375002, 22.929003906250074],
                            [92.46445312500006, 22.734423828125045],
                            [92.49140625000004, 22.685400390625006],
                            [92.5612304687501, 22.04804687500001],
                            [92.57490234375004, 21.978076171875045],
                            [92.58281250000002, 21.940332031249994],
                            [92.5934570312501, 21.46733398437499],
                            [92.63164062500002, 21.306201171875045],
                            [92.33056640624997, 21.439794921874977],
                            [92.17958984375005, 21.293115234375023],
                            [92.32412109375, 20.791845703125063],
                            [92.0560546875, 21.1748046875],
                            [91.86337890625012, 22.350488281249966],
                            [91.7970703125001, 22.297460937500006],
                            [91.48007812500006, 22.884814453125045],
                            [91.2162109375, 22.642236328124994],
                            [90.94560546875002, 22.597021484375034],
                            [90.65625, 23.025488281250006],
                            [90.60400390624997, 23.59135742187499],
                            [90.55566406249997, 23.42153320312505],
                            [90.26914062500012, 23.455859375000017],
                            [90.59091796875012, 23.266406250000045],
                            [90.43505859374997, 22.751904296874955],
                            [90.61611328125, 22.362158203125034],
                            [90.23056640625006, 21.82978515625004],
                            [90.07119140625005, 21.887255859375017],
                            [90.20957031250006, 22.156591796875006],
                            [89.95419921875006, 22.022851562500023],
                            [89.91806640625012, 22.11616210937501],
                            [89.98515625000002, 22.466406250000063],
                            [89.81191406250005, 21.983496093750006],
                            [89.56855468750004, 21.767431640625034],
                            [89.48320312500007, 22.275537109374994],
                            [89.50058593750006, 21.914355468750045],
                            [89.35371093750004, 21.72109375],
                            [89.09394531250004, 21.872753906249983],
                            [89.05, 22.274609374999983],
                            [88.92070312500002, 22.632031249999955],
                            [88.89970703125002, 22.843505859375057],
                            [88.85058593749997, 23.040527343750057],
                            [88.928125, 23.186621093750063],
                            [88.72441406250002, 23.254980468750034],
                            [88.69765625, 23.493017578125034],
                            [88.63574218749997, 23.55],
                            [88.56738281249997, 23.674414062500034],
                            [88.69980468750006, 24.002539062500006],
                            [88.71376953125, 24.069628906250017],
                            [88.72656250000003, 24.186230468749955],
                            [88.7335937500001, 24.23090820312501],
                            [88.72353515625, 24.27490234375],
                            [88.64228515625004, 24.325976562500017],
                            [88.49853515625003, 24.34663085937504],
                            [88.3375, 24.45385742187503],
                            [88.225, 24.460644531249983],
                            [88.14550781250003, 24.485791015624955],
                            [88.07910156249997, 24.549902343750063],
                            [88.02343750000003, 24.62783203125005],
                            [88.03027343749997, 24.66445312500005],
                            [88.0451171875001, 24.713037109374994],
                            [88.1498046875, 24.914648437500034],
                            [88.1888671875, 24.92060546875001],
                            [88.27949218750004, 24.881933593750034],
                            [88.31337890625005, 24.8818359375],
                            [88.37294921875, 24.961523437499977],
                            [88.45625, 25.18842773437504],
                            [88.57382812500006, 25.18789062499999],
                            [88.92978515625012, 25.222998046875063],
                            [88.94414062500002, 25.290771484375],
                            [88.85478515625002, 25.333544921875017],
                            [88.76914062500006, 25.490478515625],
                            [88.50244140624997, 25.537011718749994],
                            [88.14746093749997, 25.811425781250023],
                            [88.1066406250001, 25.841113281250045],
                            [88.15078125000005, 26.08715820312497],
                            [88.33398437499997, 26.257519531249955],
                            [88.44042968749997, 26.369482421875034],
                            [88.38623046875003, 26.471533203125034],
                            [88.35146484375005, 26.482568359374966],
                            [88.36992187500002, 26.564111328124994],
                            [88.51826171875004, 26.517773437499955],
                            [88.68281250000004, 26.291699218749983],
                            [88.94072265625002, 26.24536132812497],
                        ],
                    ],
                ],
            },
            properties: {
                name: "孟加拉",
                childNum: 6,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [28.585351562500023, 43.742236328124996],
                        [28.465429687500006, 43.389306640624994],
                        [28.133691406250023, 43.39560546875],
                        [27.92890625000001, 43.1861328125],
                        [27.88886718750001, 42.74970703125],
                        [27.484765625000023, 42.468066406249996],
                        [28.014453125000017, 41.969042968749996],
                        [27.47480468750001, 41.946875],
                        [27.294921875, 42.079541015625],
                        [27.24433593750001, 42.09326171875],
                        [27.01171875, 42.058642578124996],
                        [26.96875, 42.02685546875],
                        [26.884863281250006, 41.991845703124994],
                        [26.615332031250006, 41.964892578124996],
                        [26.549707031250023, 41.896728515625],
                        [26.51142578125001, 41.8263671875],
                        [26.3603515625, 41.8015625],
                        [26.327246093750006, 41.772802734375],
                        [26.31796875, 41.744677734374996],
                        [26.320898437500006, 41.716552734375],
                        [26.200585937500023, 41.743798828124994],
                        [26.107421875, 41.72568359375],
                        [26.085546875, 41.704150390624996],
                        [26.066015625, 41.673242187499994],
                        [26.1435546875, 41.521533203124996],
                        [26.155175781250023, 41.434863281249996],
                        [26.135351562500006, 41.3857421875],
                        [26.06640625, 41.35068359375],
                        [25.92333984375, 41.311914062499994],
                        [25.784960937500017, 41.330419921875],
                        [25.52705078125001, 41.2998046875],
                        [25.381933593750006, 41.26435546875],
                        [25.25117187500001, 41.243554687499994],
                        [24.773730468750017, 41.356103515624994],
                        [24.595996093750017, 41.442724609375],
                        [24.5693359375, 41.4673828125],
                        [24.51826171875001, 41.552539062499996],
                        [24.487890625, 41.555224609374996],
                        [24.056054687500023, 41.527246093749994],
                        [24.03291015625001, 41.469091796875],
                        [24.011328125, 41.46005859375],
                        [23.635156250000023, 41.386767578124996],
                        [23.53583984375001, 41.386035156249996],
                        [23.433398437500017, 41.398730468749996],
                        [23.3720703125, 41.3896484375],
                        [23.23984375, 41.3849609375],
                        [23.15595703125001, 41.322070312499996],
                        [22.916015625, 41.336279296875],
                        [23.00361328125001, 41.73984375],
                        [22.836816406250023, 41.993603515625],
                        [22.344042968750017, 42.31396484375],
                        [22.42207031250001, 42.328857421875],
                        [22.445703125000023, 42.359130859375],
                        [22.523535156250006, 42.440966796874996],
                        [22.53242187500001, 42.481201171875],
                        [22.524218750000017, 42.50390625],
                        [22.43623046875001, 42.6291015625],
                        [22.466796875, 42.84248046875],
                        [22.799902343750006, 42.985742187499994],
                        [22.976855468750017, 43.18798828125],
                        [22.85957031250001, 43.25234375],
                        [22.819726562500023, 43.300732421875],
                        [22.767578125, 43.354150390624994],
                        [22.554589843750023, 43.454492187499994],
                        [22.36962890625, 43.781298828124996],
                        [22.36542968750001, 43.862109375],
                        [22.399023437500006, 43.96953125],
                        [22.420800781250023, 44.007421875],
                        [22.452529688228115, 44.0510441391688],
                        [22.547921095934313, 44.113823956634434],
                        [22.688564844478098, 44.254306249271906],
                        [23.02851562500001, 44.077978515625],
                        [22.868261718750006, 43.947900390624994],
                        [22.919042968750006, 43.83447265625],
                        [25.4970703125, 43.670800781249994],
                        [26.2158203125, 44.007275390625],
                        [27.0869140625, 44.167382812499994],
                        [27.425390625, 44.0205078125],
                        [27.88427734375, 43.987353515624996],
                        [28.221972656250017, 43.772851562499994],
                        [28.585351562500023, 43.742236328124996],
                    ],
                ],
            },
            properties: {
                name: "保加利亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [50.60722656250002, 25.883105468750003],
                        [50.57490234375001, 25.806787109374994],
                        [50.465917968750006, 25.965527343749997],
                        [50.46992187500001, 26.228955078124997],
                        [50.5859375, 26.24072265625],
                        [50.60722656250002, 25.883105468750003],
                    ],
                ],
            },
            properties: {
                name: "巴林",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-73.02685546874994, 21.19238281250003],
                            [-73.16455078125003, 20.979150390625023],
                            [-73.68115234375003, 20.9755859375],
                            [-73.68037109374995, 21.103320312500017],
                            [-73.52309570312497, 21.190820312499966],
                            [-73.23535156249997, 21.15449218750004],
                            [-73.05849609375, 21.313378906249994],
                            [-73.02685546874994, 21.19238281250003],
                        ],
                    ],
                    [
                        [
                            [-73.041015625, 22.429052734375006],
                            [-72.74726562500001, 22.32739257812497],
                            [-73.16191406250002, 22.380712890625006],
                            [-73.041015625, 22.429052734375006],
                        ],
                    ],
                    [
                        [
                            [-74.20673828124998, 22.213769531250023],
                            [-74.27690429687499, 22.183691406250006],
                            [-73.906396484375, 22.527441406250063],
                            [-73.95419921874995, 22.71552734375001],
                            [-73.84995117187503, 22.731054687500063],
                            [-73.83652343749998, 22.538427734374977],
                            [-74.20673828124998, 22.213769531250023],
                        ],
                    ],
                    [
                        [
                            [-74.05751953124997, 22.723486328125034],
                            [-74.27460937499995, 22.71166992187503],
                            [-74.30703125, 22.83959960937497],
                            [-74.05751953124997, 22.723486328125034],
                        ],
                    ],
                    [
                        [
                            [-74.84047851562494, 22.894335937500017],
                            [-75.22333984374995, 23.165332031250074],
                            [-75.13056640624998, 23.267919921875006],
                            [-75.31596679687502, 23.668359374999966],
                            [-74.84047851562494, 22.894335937500017],
                        ],
                    ],
                    [
                        [
                            [-75.66455078124997, 23.45014648437501],
                            [-76.03710937500003, 23.60278320312503],
                            [-76.01044921875001, 23.671386718750057],
                            [-75.66455078124997, 23.45014648437501],
                        ],
                    ],
                    [
                        [
                            [-74.42944335937497, 24.068066406249955],
                            [-74.55092773437502, 23.96894531250001],
                            [-74.52690429687502, 24.105078125000034],
                            [-74.42944335937497, 24.068066406249955],
                        ],
                    ],
                    [
                        [
                            [-77.65771484374994, 24.249462890624955],
                            [-77.75527343750002, 24.163476562500023],
                            [-77.61538085937494, 24.216357421875045],
                            [-77.5615234375, 24.136816406250006],
                            [-77.57373046875, 23.739160156249994],
                            [-77.77128906249999, 23.752539062499977],
                            [-77.99990234374994, 24.219824218750063],
                            [-77.65771484374994, 24.249462890624955],
                        ],
                    ],
                    [
                        [
                            [-75.30839843749999, 24.2],
                            [-75.50322265624996, 24.139062500000023],
                            [-75.40893554687503, 24.265771484374994],
                            [-75.72666015625, 24.68935546875005],
                            [-75.30839843749999, 24.2],
                        ],
                    ],
                    [
                        [
                            [-77.34755859375, 25.013867187499983],
                            [-77.56191406249997, 25.030029296875],
                            [-77.27558593750001, 25.055761718750006],
                            [-77.34755859375, 25.013867187499983],
                        ],
                    ],
                    [
                        [
                            [-77.74384765625001, 24.70742187499999],
                            [-77.74521484375, 24.463476562500034],
                            [-78.04492187499997, 24.287451171875063],
                            [-78.14580078125002, 24.493457031250017],
                            [-78.36650390624993, 24.544189453125057],
                            [-78.435302734375, 24.627587890624994],
                            [-78.24272460937493, 24.65380859375],
                            [-78.21137695312495, 25.191259765624977],
                            [-77.97529296874998, 25.084814453125063],
                            [-77.74384765625001, 24.70742187499999],
                        ],
                    ],
                    [
                        [
                            [-76.64882812499994, 25.487402343750006],
                            [-76.34379882812496, 25.33203124999997],
                            [-76.12661132812497, 25.14052734375005],
                            [-76.16953125, 24.6494140625],
                            [-76.319970703125, 24.81767578124999],
                            [-76.21376953124994, 24.822460937499983],
                            [-76.160400390625, 25.119335937499983],
                            [-76.36928710937502, 25.312597656250006],
                            [-76.62070312499998, 25.43164062500003],
                            [-76.78066406249997, 25.426855468750006],
                            [-76.71083984374997, 25.564892578124983],
                            [-76.64882812499994, 25.487402343750006],
                        ],
                    ],
                    [
                        [
                            [-78.49287109375001, 26.729052734375017],
                            [-77.92246093749998, 26.69111328125001],
                            [-78.74365234374994, 26.50068359375004],
                            [-78.98564453124996, 26.689501953125045],
                            [-78.79804687500001, 26.58242187499999],
                            [-78.59711914062493, 26.797949218750006],
                            [-78.49287109375001, 26.729052734375017],
                        ],
                    ],
                    [
                        [
                            [-77.22563476562496, 25.904199218750023],
                            [-77.40317382812498, 26.02470703124996],
                            [-77.24677734374998, 26.156347656250034],
                            [-77.238623046875, 26.561132812500006],
                            [-77.510595703125, 26.845996093750045],
                            [-77.94375, 26.90356445312503],
                            [-77.53388671874995, 26.903417968750006],
                            [-77.06635742187501, 26.530175781249994],
                            [-77.03828124999998, 26.333447265624983],
                            [-77.16728515624996, 26.240332031250006],
                            [-77.22563476562496, 25.904199218750023],
                        ],
                    ],
                ],
            },
            properties: {
                name: "巴哈马",
                childNum: 14,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [19.007128906250045, 44.86918945312502],
                        [19.348632812500057, 44.88090820312502],
                        [19.118457031250074, 44.359960937500006],
                        [19.583789062500017, 44.04345703125003],
                        [19.245019531249994, 43.96503906250004],
                        [19.495117187500057, 43.642871093750045],
                        [19.19433593749997, 43.533300781250006],
                        [19.164355468750017, 43.53544921874999],
                        [19.11279296874997, 43.52773437500002],
                        [19.080078125000057, 43.51772460937502],
                        [19.0283203125, 43.53251953125002],
                        [18.97421875, 43.54233398437498],
                        [18.95068359375, 43.52666015624999],
                        [19.036718750000034, 43.35732421875002],
                        [19.026660156250017, 43.292431640624955],
                        [18.97871093750001, 43.28540039062503],
                        [18.934667968750006, 43.339453125000034],
                        [18.85107421875003, 43.34633789062502],
                        [18.749218750000068, 43.283544921875006],
                        [18.67421875000008, 43.230810546875006],
                        [18.623632812500063, 43.027685546875034],
                        [18.488476562500068, 43.01215820312498],
                        [18.44384765625003, 42.96845703125004],
                        [18.46601562500001, 42.777246093749994],
                        [18.54589843750003, 42.64160156249997],
                        [18.436328125000017, 42.559716796874994],
                        [17.667578125000063, 42.897119140624994],
                        [17.585156250000068, 42.93837890625005],
                        [17.650488281250063, 43.006591796875],
                        [17.27382812500005, 43.44575195312501],
                        [16.300097656250017, 44.12451171875],
                        [16.10341796875008, 44.52099609375006],
                        [15.736621093750045, 44.76582031250001],
                        [15.788085937500057, 45.17895507812497],
                        [16.028320312500057, 45.18959960937502],
                        [16.29335937500005, 45.00883789062496],
                        [16.53066406250008, 45.21669921875002],
                        [16.918652343749983, 45.27656249999998],
                        [17.812792968750074, 45.078125],
                        [18.66259765625, 45.07744140624999],
                        [18.83642578125, 44.883251953124955],
                        [19.007128906250045, 44.86918945312502],
                    ],
                ],
            },
            properties: {
                name: "波斯尼亚和黑塞哥维那",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [28.14794921875, 56.142919921875],
                        [28.284277343750006, 56.055908203125],
                        [29.375, 55.938720703125],
                        [29.353417968750023, 55.784375],
                        [29.412988281250023, 55.724853515625],
                        [29.482226562500017, 55.6845703125],
                        [29.63007812500001, 55.751171875],
                        [29.6845703125, 55.7697265625],
                        [29.744140625, 55.77041015625],
                        [29.82392578125001, 55.7951171875],
                        [29.881640625000017, 55.832324218749996],
                        [29.93701171875, 55.845263671874996],
                        [30.04267578125001, 55.83642578125],
                        [30.23359375000001, 55.84521484375],
                        [30.625585937500006, 55.666259765625],
                        [30.906835937500006, 55.57001953125],
                        [30.90058593750001, 55.397412109375],
                        [30.82099609375001, 55.3302734375],
                        [30.810546875, 55.306982421875],
                        [30.814453125, 55.2787109375],
                        [30.87744140625, 55.2234375],
                        [30.958886718750023, 55.13759765625],
                        [30.97773437500001, 55.08779296875],
                        [30.97773437500001, 55.05048828125],
                        [30.829882812500017, 54.914990234375],
                        [30.804492187500017, 54.8609375],
                        [30.791015625, 54.806005859375],
                        [30.798828125, 54.783251953124996],
                        [30.984179687500017, 54.6958984375],
                        [31.12128906250001, 54.648486328124996],
                        [31.152148437500017, 54.625341796875],
                        [31.074804687500006, 54.491796875],
                        [31.18476562500001, 54.452978515625],
                        [31.299121093750017, 54.29169921875],
                        [31.403613281250017, 54.195947265625],
                        [31.62841796875, 54.111181640625],
                        [31.7919921875, 54.055908203125],
                        [31.825976562500017, 54.030712890625],
                        [31.837792968750023, 54.00078125],
                        [31.825292968750006, 53.935009765625],
                        [31.783007812500017, 53.85498046875],
                        [31.754199218750017, 53.81044921875],
                        [31.82080078125, 53.791943359375],
                        [31.9921875, 53.796875],
                        [32.20039062500001, 53.78125],
                        [32.45097656250002, 53.6533203125],
                        [32.70429687500001, 53.336328125],
                        [32.64443359375002, 53.32890625],
                        [32.57802734375002, 53.31240234375],
                        [32.469335937500006, 53.2703125],
                        [32.14199218750002, 53.091162109375],
                        [31.849707031250006, 53.106201171875],
                        [31.668261718750017, 53.200927734375],
                        [31.417871093750023, 53.196044921875],
                        [31.38837890625001, 53.184814453125],
                        [31.364550781250017, 53.138964843749996],
                        [31.30292968750001, 53.060888671875],
                        [31.2587890625, 53.01669921875],
                        [31.29511718750001, 52.989794921874996],
                        [31.35302734375, 52.933447265625],
                        [31.442773437500023, 52.86181640625],
                        [31.53515625, 52.7982421875],
                        [31.564843750000023, 52.759228515625],
                        [31.585546875, 52.532470703125],
                        [31.57734375000001, 52.312304687499996],
                        [31.6015625, 52.284814453125],
                        [31.64990234375, 52.26220703125],
                        [31.690625, 52.220654296875],
                        [31.758593750000017, 52.125830078125],
                        [31.76337890625001, 52.10107421875],
                        [31.57373046875, 52.10810546875],
                        [31.345996093750017, 52.10537109375],
                        [31.21796875000001, 52.050244140625],
                        [30.98066406250001, 52.046191406249996],
                        [30.845703125, 51.953076171875],
                        [30.755273437500023, 51.895166015625],
                        [30.667285156250017, 51.814111328125],
                        [30.583886718750023, 51.68896484375],
                        [30.533007812500017, 51.596337890624994],
                        [30.56074218750001, 51.531494140625],
                        [30.602343750000017, 51.471240234374996],
                        [30.611718750000023, 51.40634765625],
                        [30.63251953125001, 51.355419921875],
                        [30.449511718750017, 51.274316406249994],
                        [30.160742187500006, 51.477880859375],
                        [29.346484375000017, 51.382568359375],
                        [29.10205078125, 51.6275390625],
                        [29.06074218750001, 51.625439453125],
                        [29.013085937500023, 51.598925781249996],
                        [28.97773437500001, 51.57177734375],
                        [28.927539062500017, 51.562158203124994],
                        [28.849511718750023, 51.540185546874994],
                        [28.73125, 51.433398437499996],
                        [28.690234375000017, 51.438867187499994],
                        [28.647753906250017, 51.45654296875],
                        [28.599023437500023, 51.542626953124994],
                        [28.532031250000017, 51.562451171875],
                        [27.85859375000001, 51.5923828125],
                        [27.7, 51.477978515625],
                        [27.689746093750017, 51.572412109374994],
                        [27.296289062500023, 51.597412109375],
                        [27.270117187500006, 51.613574218749996],
                        [27.141992187500023, 51.75205078125],
                        [27.074121093750023, 51.76083984375],
                        [26.95283203125001, 51.75400390625],
                        [26.7734375, 51.770703125],
                        [25.785742187500006, 51.923828125],
                        [24.361914062500006, 51.867529296875],
                        [24.280078125000017, 51.774707031249996],
                        [24.126855468750023, 51.6646484375],
                        [23.978320312500017, 51.59130859375],
                        [23.951171875, 51.58505859375],
                        [23.8642578125, 51.623974609375],
                        [23.79169921875001, 51.637109375],
                        [23.706835937500017, 51.64130859375],
                        [23.61376953125, 51.525390625],
                        [23.605273437500017, 51.517919921875],
                        [23.652441406250006, 52.040380859375],
                        [23.175097656250017, 52.28662109375],
                        [23.915429687500023, 52.770263671875],
                        [23.484667968750017, 53.939794921875],
                        [23.55908203125, 53.91982421875],
                        [23.733691406250017, 53.912255859375],
                        [24.191308593750023, 53.950439453125],
                        [24.236621093750017, 53.919970703124996],
                        [24.31796875, 53.89296875],
                        [24.620703125, 53.979833984375],
                        [24.768164062500006, 53.974658203124996],
                        [24.78925781250001, 53.9982421875],
                        [24.82568359375, 54.118994140625],
                        [24.869531250000023, 54.145166015625],
                        [25.04609375000001, 54.133056640625],
                        [25.111425781250006, 54.154931640625],
                        [25.179492187500017, 54.2142578125],
                        [25.46113281250001, 54.292773437499996],
                        [25.505664062500017, 54.264941406249996],
                        [25.52734375, 54.215136718749996],
                        [25.497363281250017, 54.175244140625],
                        [25.573046875000017, 54.139892578125],
                        [25.765234375, 54.17978515625],
                        [25.702539062500023, 54.29296875],
                        [25.61689453125001, 54.310107421874996],
                        [25.557519531250023, 54.310693359375],
                        [25.54736328125, 54.331835937499996],
                        [25.56757812500001, 54.37705078125],
                        [25.62031250000001, 54.460400390625],
                        [25.68515625, 54.535791015625],
                        [25.72480468750001, 54.564257812499996],
                        [25.73164062500001, 54.590380859374996],
                        [25.722460937500017, 54.71787109375],
                        [25.859277343750023, 54.919287109375],
                        [25.964453125, 54.94716796875],
                        [26.09296875000001, 54.9623046875],
                        [26.175195312500023, 55.003271484375],
                        [26.250781250000017, 55.12451171875],
                        [26.291796875000017, 55.139599609375],
                        [26.601171875, 55.130175781249996],
                        [26.6484375, 55.20419921875],
                        [26.775683593750017, 55.273095703125],
                        [26.760156250000023, 55.293359375],
                        [26.68125, 55.306445312499996],
                        [26.49531250000001, 55.318017578125],
                        [26.457617187500006, 55.34248046875],
                        [26.469531250000017, 55.371923828125],
                        [26.51923828125001, 55.44814453125],
                        [26.56660156250001, 55.546484375],
                        [26.5908203125, 55.62265625],
                        [26.593554687500017, 55.667529296874996],
                        [27.052539062500017, 55.83056640625],
                        [27.576757812500006, 55.798779296875],
                        [28.14794921875, 56.142919921875],
                    ],
                ],
            },
            properties: {
                name: "白俄罗斯",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-87.8529296875, 17.4228515625],
                            [-87.92998046874996, 17.283007812500017],
                            [-87.826416015625, 17.546289062499994],
                            [-87.8529296875, 17.4228515625],
                        ],
                    ],
                    [
                        [
                            [-88.89404296875, 15.890625],
                            [-89.2328125, 15.888671875],
                            [-89.16147460937503, 17.81484375],
                            [-89.13354492187503, 17.970800781249977],
                            [-88.80634765624998, 17.965527343749983],
                            [-88.52299804687499, 18.445898437500063],
                            [-88.29565429687494, 18.47241210937503],
                            [-88.34926757812494, 18.358837890624983],
                            [-88.1302734375, 18.350732421875023],
                            [-88.08525390624999, 18.226123046875045],
                            [-88.27172851562494, 17.60986328125],
                            [-88.203466796875, 17.5166015625],
                            [-88.31342773437501, 16.632763671874983],
                            [-88.89404296875, 15.890625],
                        ],
                    ],
                ],
            },
            properties: {
                name: "伯利兹",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-64.73027343749999, 32.29345703125],
                        [-64.86284179687499, 32.273876953125],
                        [-64.66831054687499, 32.38193359375],
                        [-64.73027343749999, 32.29345703125],
                    ],
                ],
            },
            properties: {
                name: "百慕大",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-58.15976562499999, -20.164648437500006],
                        [-58.18017578125, -19.81787109375],
                        [-59.09052734375, -19.286230468750006],
                        [-60.00737304687499, -19.29755859375001],
                        [-61.7568359375, -19.6453125],
                        [-62.276318359375, -20.5625],
                        [-62.27666015624999, -21.066015625],
                        [-62.65097656249999, -22.233691406250003],
                        [-62.84335937499999, -21.99726562500001],
                        [-63.92167968749999, -22.028613281250003],
                        [-63.97612304687499, -22.072558593750003],
                        [-64.26640624999999, -22.603320312500003],
                        [-64.30791015624999, -22.7953125],
                        [-64.32529296874999, -22.82763671875],
                        [-64.373974609375, -22.761035156250003],
                        [-64.4455078125, -22.58535156250001],
                        [-64.477734375, -22.4853515625],
                        [-64.5236328125, -22.37158203125],
                        [-64.60551757812499, -22.228808593750003],
                        [-64.992626953125, -22.109667968750003],
                        [-65.518798828125, -22.09453125],
                        [-65.686181640625, -22.11025390625001],
                        [-65.77104492187499, -22.099609375],
                        [-65.86015624999999, -22.01972656250001],
                        [-66.05859375, -21.879492187500006],
                        [-66.098583984375, -21.835058593750006],
                        [-66.17465820312499, -21.8056640625],
                        [-66.220166015625, -21.802539062500003],
                        [-66.24760742187499, -21.83046875],
                        [-66.28212890625, -21.94746093750001],
                        [-66.3224609375, -22.053125],
                        [-66.365185546875, -22.11376953125],
                        [-66.71171874999999, -22.21630859375],
                        [-66.99111328125, -22.509863281250006],
                        [-67.19487304687499, -22.82167968750001],
                        [-67.362255859375, -22.85517578125001],
                        [-67.57993164062499, -22.891699218750006],
                        [-67.79443359375, -22.879492187500006],
                        [-67.87944335937499, -22.82294921875001],
                        [-67.88173828125, -22.49335937500001],
                        [-68.18642578125, -21.61855468750001],
                        [-68.197021484375, -21.30029296875],
                        [-68.558251953125, -20.901953125],
                        [-68.484326171875, -20.62841796875],
                        [-68.74516601562499, -20.45859375],
                        [-68.75932617187499, -20.115527343750003],
                        [-68.560693359375, -19.967089843750003],
                        [-68.559375, -19.90234375],
                        [-68.578271484375, -19.856542968750006],
                        [-68.69619140625, -19.74072265625],
                        [-68.69829101562499, -19.72109375],
                        [-68.57529296874999, -19.56015625],
                        [-68.462890625, -19.43281250000001],
                        [-68.470166015625, -19.409960937500003],
                        [-68.49199218749999, -19.381933593750006],
                        [-68.85795898437499, -19.093359375],
                        [-68.96831054687499, -18.96796875000001],
                        [-68.97885742187499, -18.81298828125],
                        [-69.026806640625, -18.65625],
                        [-69.09228515625, -18.28242187500001],
                        [-69.145458984375, -18.14404296875],
                        [-69.0939453125, -18.05048828125001],
                        [-69.28232421874999, -17.96484375],
                        [-69.31337890625, -17.943164062500003],
                        [-69.5109375, -17.50605468750001],
                        [-69.51108398437499, -17.5048828125],
                        [-69.510986328125, -17.46035156250001],
                        [-69.521923828125, -17.388964843750003],
                        [-69.645703125, -17.24853515625],
                        [-69.62485351562499, -17.2001953125],
                        [-69.020703125, -16.6421875],
                        [-69.03291015625, -16.47597656250001],
                        [-68.8427734375, -16.337890625],
                        [-69.21757812499999, -16.14912109375001],
                        [-69.4208984375, -15.640625],
                        [-69.17246093749999, -15.236621093750003],
                        [-69.37470703125, -14.962988281250006],
                        [-69.35947265624999, -14.7953125],
                        [-68.87089843749999, -14.169726562500003],
                        [-69.07412109375, -13.682812500000011],
                        [-68.97861328124999, -12.880078125000011],
                        [-68.68525390625, -12.501953125],
                        [-69.57861328125, -10.951757812500006],
                        [-69.228515625, -10.955664062500006],
                        [-68.84833984375, -11.011132812500009],
                        [-68.678369140625, -11.11279296875],
                        [-68.39799804687499, -11.01875],
                        [-68.0716796875, -10.703125],
                        [-67.99169921875, -10.674414062500006],
                        [-67.83500976562499, -10.662792968750011],
                        [-67.72177734374999, -10.68310546875],
                        [-67.416943359375, -10.389843750000011],
                        [-66.575341796875, -9.89990234375],
                        [-65.396142578125, -9.71240234375],
                        [-65.298583984375, -10.146777343750003],
                        [-65.31308593749999, -10.253027343750006],
                        [-65.395458984375, -10.392285156250011],
                        [-65.4369140625, -10.449023437500003],
                        [-65.44711914062499, -10.507421875],
                        [-65.33403320312499, -10.892773437500011],
                        [-65.32377929687499, -11.024804687500009],
                        [-65.389892578125, -11.246289062500011],
                        [-65.1857421875, -11.74951171875],
                        [-64.783447265625, -12.059375],
                        [-64.42050781249999, -12.439746093750003],
                        [-63.68857421874999, -12.47802734375],
                        [-63.3466796875, -12.680078125],
                        [-63.06748046874999, -12.669140625000011],
                        [-62.76547851562499, -12.997265625000011],
                        [-62.11801757812499, -13.159765625],
                        [-62.09477539062499, -13.241992187500003],
                        [-61.944726562499994, -13.40625],
                        [-61.87412109374999, -13.470410156250011],
                        [-61.789941406249994, -13.525585937500011],
                        [-61.57568359375, -13.524804687500009],
                        [-61.51157226562499, -13.541210937500011],
                        [-61.41606445312499, -13.526562500000011],
                        [-61.129150390625, -13.49853515625],
                        [-61.07700195312499, -13.48974609375],
                        [-60.506591796875, -13.78984375],
                        [-60.372705078124994, -14.41875],
                        [-60.273339843749994, -15.088769531250009],
                        [-60.402001953124994, -15.0927734375],
                        [-60.583203125, -15.098339843750011],
                        [-60.53046875, -15.143164062500006],
                        [-60.38046875, -15.318261718750009],
                        [-60.242333984374994, -15.479589843750006],
                        [-60.20664062499999, -15.901953125],
                        [-60.18720703125, -16.132128906250003],
                        [-60.17558593749999, -16.269335937500003],
                        [-58.53793945312499, -16.328222656250006],
                        [-58.49658203125, -16.32666015625],
                        [-58.42368164062499, -16.307910156250003],
                        [-58.37539062499999, -16.28359375],
                        [-58.345605468749994, -16.284375],
                        [-58.35039062499999, -16.490820312500006],
                        [-58.470605468749994, -16.650195312500003],
                        [-58.478125, -16.70068359375],
                        [-58.45981445312499, -16.910742187500006],
                        [-58.417382812499994, -17.08056640625],
                        [-58.39599609375, -17.23427734375001],
                        [-58.34775390624999, -17.28212890625001],
                        [-57.99091796875, -17.51289062500001],
                        [-57.905029296875, -17.532324218750006],
                        [-57.832470703125, -17.512109375],
                        [-57.78886718749999, -17.573046875],
                        [-57.780175781249994, -17.67177734375001],
                        [-57.66166992187499, -17.947363281250006],
                        [-57.58647460937499, -18.12226562500001],
                        [-57.49565429687499, -18.214648437500003],
                        [-57.57402343749999, -18.279296875],
                        [-57.725, -18.733203125],
                        [-57.783105468749994, -18.91425781250001],
                        [-57.716796875, -19.044042968750006],
                        [-58.131494140624994, -19.74453125],
                        [-57.860742187499994, -19.979589843750006],
                        [-57.887597656249994, -20.02041015625001],
                        [-57.96015625, -20.04072265625001],
                        [-58.021142578124994, -20.05517578125],
                        [-58.09375, -20.15107421875001],
                        [-58.15976562499999, -20.164648437500006],
                    ],
                ],
            },
            properties: {
                name: "玻利维亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-48.48588867187493, -27.76699218749998],
                            [-48.554589843749994, -27.81220703125004],
                            [-48.542187499999955, -27.57480468749999],
                            [-48.41489257812495, -27.399609375],
                            [-48.48588867187493, -27.76699218749998],
                        ],
                    ],
                    [
                        [
                            [-48.584423828124955, -26.401562499999983],
                            [-48.665771484375, -26.289648437500006],
                            [-48.53974609374998, -26.170312500000023],
                            [-48.584423828124955, -26.401562499999983],
                        ],
                    ],
                    [
                        [
                            [-45.26025390624997, -23.889160156249986],
                            [-45.451416015625, -23.895605468749977],
                            [-45.30234375, -23.727539062500014],
                            [-45.26025390624997, -23.889160156249986],
                        ],
                    ],
                    [
                        [
                            [-44.12929687499994, -23.14189453124999],
                            [-44.36015624999999, -23.17207031250001],
                            [-44.24287109374998, -23.074121093750037],
                            [-44.12929687499994, -23.14189453124999],
                        ],
                    ],
                    [
                        [
                            [-38.90356445312497, -13.473437499999974],
                            [-38.97758789062496, -13.523535156249963],
                            [-39.02216796874998, -13.445605468749989],
                            [-38.907128906249994, -13.401074218749983],
                            [-38.90356445312497, -13.473437499999974],
                        ],
                    ],
                    [
                        [
                            [-38.743847656249955, -13.097070312500037],
                            [-38.668115234374966, -12.880175781249989],
                            [-38.601171875, -12.99257812499998],
                            [-38.743847656249955, -13.097070312500037],
                        ],
                    ],
                    [
                        [
                            [-44.49931640625002, -2.939648437499983],
                            [-44.597753906250006, -3.037597656249943],
                            [-44.4814453125, -2.717578125000031],
                            [-44.49931640625002, -2.939648437499983],
                        ],
                    ],
                    [
                        [
                            [-44.88310546874996, -1.317871093749986],
                            [-45.020849609375034, -1.372363281249974],
                            [-44.978662109374966, -1.267285156249983],
                            [-44.88310546874996, -1.317871093749986],
                        ],
                    ],
                    [
                        [
                            [-51.83251953124997, -1.433789062499969],
                            [-51.938378906249966, -1.452636718749986],
                            [-51.680029296875006, -1.086132812500026],
                            [-51.546044921874966, -0.649609375],
                            [-51.25400390624998, -0.54140625],
                            [-51.16074218749998, -0.666699218750011],
                            [-51.27631835937498, -1.02177734374996],
                            [-51.83251953124997, -1.433789062499969],
                        ],
                    ],
                    [
                        [
                            [-49.62866210937497, -0.229199218749969],
                            [-49.11699218749999, -0.163574218750014],
                            [-48.39267578124995, -0.29736328125],
                            [-48.83359375, -1.390039062500023],
                            [-49.038476562499994, -1.5140625],
                            [-49.17270507812498, -1.41259765625],
                            [-49.233984375000034, -1.59951171874998],
                            [-49.50664062499999, -1.511621093750023],
                            [-49.587890625, -1.712402343749972],
                            [-49.805126953124955, -1.790234375000026],
                            [-50.06572265625002, -1.703808593749997],
                            [-50.50761718749999, -1.787988281250009],
                            [-50.759765625, -1.240234374999972],
                            [-50.72949218749997, -1.126757812499946],
                            [-50.57695312499999, -1.103125],
                            [-50.709619140624994, -1.07773437499999],
                            [-50.796093749999955, -0.90625],
                            [-50.6455078125, -0.27285156249998],
                            [-50.24824218749998, -0.11640625],
                            [-49.62866210937497, -0.229199218749969],
                        ],
                    ],
                    [
                        [
                            [-50.65288085937499, -0.131640624999989],
                            [-50.926367187500034, -0.327343749999983],
                            [-51.03808593749994, -0.225878906250003],
                            [-50.84218750000002, -0.050195312500009],
                            [-50.65288085937499, -0.131640624999989],
                        ],
                    ],
                    [
                        [
                            [-49.44389648437499, -0.112402343749977],
                            [-49.83007812499997, -0.093896484375023],
                            [-49.50346679687496, 0.083691406250011],
                            [-49.37231445312497, 0.001074218749963],
                            [-49.44389648437499, -0.112402343749977],
                        ],
                    ],
                    [
                        [
                            [-49.73823242187498, 0.26816406250002],
                            [-49.917089843750006, -0.023193359375014],
                            [-50.339453125, 0.043359375000051],
                            [-50.27265624999998, 0.231738281249974],
                            [-49.73823242187498, 0.26816406250002],
                        ],
                    ],
                    [
                        [
                            [-50.42612304687498, 0.139257812500048],
                            [-50.44394531249998, -0.007666015624949],
                            [-50.623925781249966, 0.054394531249983],
                            [-50.372753906249955, 0.590869140625031],
                            [-50.33227539062497, 0.259033203125028],
                            [-50.42612304687498, 0.139257812500048],
                        ],
                    ],
                    [
                        [
                            [-50.152929687500006, 0.393017578125054],
                            [-50.26132812499998, 0.359179687500003],
                            [-50.281689453124955, 0.51650390624998],
                            [-50.05883789062503, 0.638037109374963],
                            [-50.152929687500006, 0.393017578125054],
                        ],
                    ],
                    [
                        [
                            [-50.29897460937502, 1.93852539062496],
                            [-50.45610351562496, 1.910498046875034],
                            [-50.49101562499996, 2.128613281249969],
                            [-50.34199218749998, 2.14174804687498],
                            [-50.29897460937502, 1.93852539062496],
                        ],
                    ],
                    [
                        [
                            [-59.69970703125, 4.353515625],
                            [-59.73857421874993, 4.226757812500026],
                            [-59.62021484374998, 4.023144531250026],
                            [-59.557763671874966, 3.960009765625031],
                            [-59.551123046875034, 3.933544921874969],
                            [-59.854394531249994, 3.5875],
                            [-59.99433593749998, 2.689990234375031],
                            [-59.88964843749997, 2.362939453125009],
                            [-59.75522460937495, 2.27412109375004],
                            [-59.74350585937498, 2.12163085937496],
                            [-59.75175781249996, 1.962402343750028],
                            [-59.75620117187498, 1.900634765624972],
                            [-59.666601562500006, 1.746289062499969],
                            [-59.53569335937499, 1.7],
                            [-59.23120117187494, 1.376025390625031],
                            [-58.82177734374994, 1.201220703125031],
                            [-58.787207031250006, 1.208496093750014],
                            [-58.73032226562498, 1.247509765625054],
                            [-58.68461914062499, 1.28105468749996],
                            [-58.511865234374966, 1.284667968749986],
                            [-58.506054687499926, 1.438671875000011],
                            [-58.39580078124993, 1.481738281249989],
                            [-58.38037109375, 1.530224609375011],
                            [-58.34067382812498, 1.587548828125051],
                            [-58.03466796875, 1.520263671875014],
                            [-57.9828125, 1.648437500000014],
                            [-57.87343750000002, 1.667285156250045],
                            [-57.79565429687497, 1.7],
                            [-57.59443359375001, 1.704101562499986],
                            [-57.54575195312495, 1.726074218750028],
                            [-57.31748046874998, 1.963476562499991],
                            [-57.27558593749998, 1.959228515625014],
                            [-57.189599609374966, 1.981591796875037],
                            [-57.11889648437494, 2.013964843749974],
                            [-57.09267578125002, 2.005810546874997],
                            [-57.03759765625, 1.936474609374997],
                            [-56.96953124999999, 1.91640625],
                            [-56.48281249999994, 1.942138671874986],
                            [-56.019921874999966, 1.842236328124983],
                            [-55.96333007812498, 1.85708007812498],
                            [-55.929638671874955, 1.8875],
                            [-55.92163085937503, 1.976660156250006],
                            [-55.91533203124999, 2.039550781250028],
                            [-55.96196289062496, 2.09511718749998],
                            [-56.02006835937499, 2.15815429687504],
                            [-56.073632812499994, 2.236767578124969],
                            [-56.13769531249997, 2.259033203124986],
                            [-56.12939453124997, 2.299511718749969],
                            [-56.08779296875002, 2.341308593750043],
                            [-56.045117187499955, 2.364404296875037],
                            [-56.02036132812498, 2.392773437500054],
                            [-55.993505859375006, 2.497509765624983],
                            [-55.9755859375, 2.515966796875006],
                            [-55.957470703124955, 2.52045898437504],
                            [-55.730566406250006, 2.406152343750023],
                            [-55.385351562500006, 2.440625],
                            [-55.34399414062503, 2.488769531249972],
                            [-55.28603515625002, 2.49965820312498],
                            [-55.18769531249998, 2.547509765625037],
                            [-55.114111328125006, 2.539208984375037],
                            [-55.07031249999994, 2.548339843750028],
                            [-55.005810546874955, 2.592968749999983],
                            [-54.97866210937502, 2.597656250000043],
                            [-54.968408203124966, 2.548339843750028],
                            [-54.92656249999999, 2.497363281250045],
                            [-54.876074218750006, 2.450390624999969],
                            [-54.72221679687499, 2.441650390624972],
                            [-54.69741210937502, 2.359814453124997],
                            [-54.66186523437497, 2.327539062499994],
                            [-54.61625976562499, 2.326757812500006],
                            [-54.59194335937502, 2.313769531250031],
                            [-54.55048828125001, 2.293066406249991],
                            [-54.51508789062498, 2.245458984374963],
                            [-54.43310546875, 2.207519531250057],
                            [-54.13007812499998, 2.121044921875026],
                            [-53.76777343749998, 2.354833984375048],
                            [-52.90346679687502, 2.211523437499977],
                            [-52.58300781250003, 2.528906249999977],
                            [-52.327880859375, 3.18173828125002],
                            [-51.65253906249998, 4.061279296874972],
                            [-51.54707031250001, 4.31088867187502],
                            [-51.219921874999955, 4.093603515624991],
                            [-50.71440429687502, 2.134033203125],
                            [-50.458886718749994, 1.829589843749972],
                            [-49.957128906250006, 1.65986328125004],
                            [-49.898876953124955, 1.16298828124998],
                            [-50.29443359374997, 0.835742187500003],
                            [-50.755078124999955, 0.222558593749966],
                            [-51.28291015625001, -0.085205078125028],
                            [-51.98081054687498, -1.367968749999974],
                            [-52.22924804687497, -1.3625],
                            [-52.664160156250034, -1.551757812500028],
                            [-51.94755859374996, -1.586718749999946],
                            [-50.89492187500002, -0.937597656249963],
                            [-50.690039062500006, -1.761718749999986],
                            [-50.40322265625002, -2.015527343750009],
                            [-49.999218749999955, -1.831835937499974],
                            [-49.71953125000002, -1.926367187499963],
                            [-49.31367187500001, -1.731738281250003],
                            [-49.63652343749996, -2.656933593750026],
                            [-49.45751953125, -2.504589843749983],
                            [-49.21103515624998, -1.916503906249986],
                            [-48.99130859374998, -1.829785156249997],
                            [-48.71000976562496, -1.487695312500023],
                            [-48.46293945312499, -1.613964843749997],
                            [-48.349804687499926, -1.482128906249955],
                            [-48.46806640624996, -1.393847656250003],
                            [-48.44980468749998, -1.145507812499943],
                            [-48.11508789062498, -0.7375],
                            [-47.557324218749955, -0.669921874999957],
                            [-47.418652343749955, -0.765917968749974],
                            [-47.39809570312502, -0.626660156250026],
                            [-45.45859374999995, -1.35625],
                            [-45.32915039062496, -1.71728515625],
                            [-45.07636718749998, -1.466406249999949],
                            [-44.72114257812498, -1.733496093750006],
                            [-44.778515624999955, -1.798828125],
                            [-44.651269531249966, -1.745800781250026],
                            [-44.537792968749955, -2.052734374999943],
                            [-44.75634765624997, -2.265527343749952],
                            [-44.66240234375002, -2.373242187499955],
                            [-44.435449218749966, -2.168066406249991],
                            [-44.38183593749997, -2.365527343749989],
                            [-44.52011718749998, -2.40546875000004],
                            [-44.589013671874994, -2.573437499999983],
                            [-44.72304687500002, -3.204785156249997],
                            [-44.43754882812496, -2.944433593749977],
                            [-44.228613281250006, -2.471289062499949],
                            [-44.105566406250006, -2.493457031250031],
                            [-44.19267578124999, -2.809570312499943],
                            [-43.93291015624999, -2.583496093749986],
                            [-43.45512695312499, -2.502050781250006],
                            [-43.38007812499998, -2.376074218750006],
                            [-42.93671874999998, -2.465039062500011],
                            [-42.24960937499998, -2.7919921875],
                            [-41.876171874999926, -2.746582031249986],
                            [-41.479931640624955, -2.916503906249972],
                            [-40.474560546874926, -2.795605468750026],
                            [-39.96469726562498, -2.861523437499955],
                            [-38.475781249999955, -3.717480468749997],
                            [-38.04882812500003, -4.216406250000034],
                            [-37.626318359375006, -4.592089843750003],
                            [-37.30146484375001, -4.713085937499969],
                            [-37.174658203125006, -4.912402343749974],
                            [-36.590722656249966, -5.097558593749952],
                            [-35.549414062500006, -5.129394531249957],
                            [-35.39257812499994, -5.250878906250009],
                            [-34.833886718749994, -7.024414062500014],
                            [-34.83466796874998, -7.97148437499996],
                            [-35.34086914062499, -9.230664062499983],
                            [-35.76396484374993, -9.702539062500023],
                            [-35.890820312499926, -9.687011718749957],
                            [-35.88544921875001, -9.84765625],
                            [-36.39833984374994, -10.484082031249983],
                            [-36.768310546875, -10.671679687500017],
                            [-37.18281249999998, -11.06845703125002],
                            [-37.35600585937502, -11.403906249999977],
                            [-37.35922851562495, -11.252539062499963],
                            [-37.68872070312503, -12.1],
                            [-38.019238281249955, -12.591308593750028],
                            [-38.401757812499994, -12.966210937500023],
                            [-38.69096679687502, -12.623925781250009],
                            [-38.85175781250001, -12.790136718750034],
                            [-38.76372070312502, -12.9072265625],
                            [-38.835302734375034, -13.147167968750026],
                            [-39.030908203124994, -13.365136718750023],
                            [-39.08935546875, -13.588183593749989],
                            [-38.988623046875006, -13.61503906249996],
                            [-39.04814453124996, -14.043945312500028],
                            [-38.94233398437498, -14.030664062499994],
                            [-39.05957031249997, -14.654785156249957],
                            [-38.88061523437503, -15.864257812499972],
                            [-39.20288085937503, -17.178125],
                            [-39.154003906249926, -17.70390625000003],
                            [-39.650781249999966, -18.252343750000037],
                            [-39.78330078124998, -19.571777343749986],
                            [-40.001367187499994, -19.74199218750003],
                            [-40.39594726562501, -20.56943359375002],
                            [-40.78925781250001, -20.90605468750003],
                            [-40.954541015624926, -21.237890624999963],
                            [-41.04726562499999, -21.505664062499974],
                            [-41.00029296875002, -21.99902343750003],
                            [-41.70551757812498, -22.30966796874999],
                            [-41.980419921874955, -22.580664062499963],
                            [-42.042382812499966, -22.947070312500003],
                            [-42.95830078124996, -22.96708984374999],
                            [-43.154296875, -22.725195312500006],
                            [-43.22416992187502, -22.991210937500014],
                            [-43.898828124999966, -23.10146484375001],
                            [-43.97382812499998, -23.057324218749983],
                            [-43.675976562499955, -23.00947265625001],
                            [-43.86616210937498, -22.910546875000023],
                            [-44.63725585937496, -23.05546875],
                            [-44.67382812499994, -23.206640625000034],
                            [-44.56967773437495, -23.27402343749999],
                            [-45.32539062499998, -23.59970703124999],
                            [-45.464306640624955, -23.802539062500017],
                            [-45.97207031250002, -23.795507812500006],
                            [-46.86728515624998, -24.236328125000014],
                            [-47.989160156249994, -25.03574218749999],
                            [-47.92939453124998, -25.16826171874999],
                            [-48.20273437499998, -25.41650390625003],
                            [-48.18593749999994, -25.309863281249974],
                            [-48.402490234374994, -25.27207031249999],
                            [-48.47612304687499, -25.44296875],
                            [-48.73173828124993, -25.36875],
                            [-48.6921875, -25.49150390625003],
                            [-48.40117187500002, -25.59736328125001],
                            [-48.665771484375, -25.844335937499963],
                            [-48.576318359374994, -25.935449218749966],
                            [-48.61943359374996, -26.17939453125001],
                            [-48.74829101562503, -26.26865234374999],
                            [-48.55415039062498, -27.195996093749997],
                            [-48.62080078124998, -28.075585937499966],
                            [-48.799658203125006, -28.575292968749977],
                            [-49.27128906249999, -28.87119140625005],
                            [-49.745996093749966, -29.363183593749994],
                            [-50.299511718749955, -30.42578125000003],
                            [-50.92138671874997, -31.25839843750002],
                            [-52.039208984374994, -32.11484374999996],
                            [-52.063232421875, -31.830371093750017],
                            [-51.68066406249994, -31.774609375000026],
                            [-51.272167968749955, -31.476953125000037],
                            [-51.16142578124996, -31.11884765625001],
                            [-50.980078125000034, -31.09423828124997],
                            [-50.94082031249994, -30.903710937499966],
                            [-50.68930664062495, -30.70419921874999],
                            [-50.71630859374994, -30.425976562499983],
                            [-50.58193359375002, -30.438867187500037],
                            [-50.56352539062499, -30.25361328125004],
                            [-51.02495117187493, -30.36865234375003],
                            [-51.29804687499998, -30.03486328124997],
                            [-51.15727539062499, -30.364257812500014],
                            [-51.283056640625034, -30.751562499999963],
                            [-51.35908203124998, -30.674511718749983],
                            [-51.506298828124955, -31.104492187500014],
                            [-51.97246093749999, -31.383789062499986],
                            [-52.19355468749998, -31.885546874999974],
                            [-52.12739257812501, -32.1677734375],
                            [-52.652246093749994, -33.137792968750006],
                            [-53.37060546874997, -33.74218750000003],
                            [-53.39755859374995, -33.737304687500014],
                            [-53.46357421875001, -33.70986328125002],
                            [-53.531347656250034, -33.65546875000004],
                            [-53.531347656250034, -33.1708984375],
                            [-53.511865234374966, -33.10869140625003],
                            [-53.482861328124926, -33.068554687500026],
                            [-53.39521484375001, -33.01035156249998],
                            [-53.31010742187499, -32.927050781249974],
                            [-53.21406249999998, -32.82109375],
                            [-53.12558593749998, -32.73671875],
                            [-53.15727539062496, -32.680078125],
                            [-53.601708984374994, -32.40302734374997],
                            [-53.76171875, -32.05683593749997],
                            [-53.920605468749926, -31.95234375],
                            [-54.220556640625034, -31.855175781249997],
                            [-54.58764648437503, -31.48515625000003],
                            [-55.036035156249994, -31.27900390625004],
                            [-55.091162109375034, -31.31396484374997],
                            [-55.173535156249926, -31.279589843749974],
                            [-55.557324218749955, -30.8759765625],
                            [-55.60302734375003, -30.85078125000001],
                            [-55.62714843749998, -30.858105468749997],
                            [-55.650488281250034, -30.89208984375],
                            [-55.66523437500001, -30.92490234375002],
                            [-55.807763671874994, -31.036718749999977],
                            [-55.87368164062502, -31.069628906250017],
                            [-55.95200195312498, -31.08085937499999],
                            [-56.0046875, -31.079199218750006],
                            [-56.01845703125002, -30.991894531249983],
                            [-55.998974609374955, -30.837207031250003],
                            [-56.4072265625, -30.44746093750001],
                            [-56.83271484374998, -30.107226562499974],
                            [-57.120507812499994, -30.144433593749994],
                            [-57.21445312499995, -30.283398437499983],
                            [-57.55229492187496, -30.261230468749986],
                            [-57.60888671875003, -30.187792968750045],
                            [-57.563867187499994, -30.139941406249974],
                            [-57.40522460937501, -30.03388671875004],
                            [-57.22465820312499, -29.782128906249994],
                            [-56.938623046874994, -29.594824218750034],
                            [-55.890527343749994, -28.370019531249994],
                            [-55.68725585937497, -28.38164062499996],
                            [-55.72548828125002, -28.20410156250003],
                            [-55.10151367187501, -27.866796874999963],
                            [-54.82910156250003, -27.55058593750003],
                            [-54.32700195312495, -27.423535156249997],
                            [-53.83818359375002, -27.121093750000014],
                            [-53.668554687500006, -26.288183593749977],
                            [-53.89116210937499, -25.66884765625001],
                            [-54.15458984374999, -25.523046874999963],
                            [-54.44394531249998, -25.625],
                            [-54.615869140624994, -25.576074218750023],
                            [-54.61054687499998, -25.432714843750034],
                            [-54.47314453124997, -25.22021484375],
                            [-54.43623046875001, -25.12128906250001],
                            [-54.281005859375, -24.30605468750001],
                            [-54.31826171874994, -24.128125],
                            [-54.26689453124996, -24.06582031250001],
                            [-54.241796875, -24.047265624999966],
                            [-54.44023437500002, -23.90175781249998],
                            [-54.62548828125, -23.8125],
                            [-54.98266601562494, -23.974511718749966],
                            [-55.081884765625006, -23.997656249999977],
                            [-55.1943359375, -24.017480468750023],
                            [-55.28691406249993, -24.00429687499999],
                            [-55.366308593750034, -23.99101562499996],
                            [-55.41591796875002, -23.95136718749997],
                            [-55.4423828125, -23.86533203125002],
                            [-55.4423828125, -23.792578125000034],
                            [-55.458886718749966, -23.686718750000054],
                            [-55.51845703124994, -23.627246093750017],
                            [-55.53828124999998, -23.580957031249994],
                            [-55.61767578125, -22.67148437499999],
                            [-55.74663085937499, -22.51269531249997],
                            [-55.753271484375006, -22.410156250000043],
                            [-55.84916992187499, -22.307617187500014],
                            [-55.991406249999926, -22.28115234375005],
                            [-56.18984374999994, -22.28115234375005],
                            [-56.246044921874926, -22.26464843749997],
                            [-56.39487304687498, -22.092675781250023],
                            [-56.44780273437502, -22.07617187500003],
                            [-56.77519531249999, -22.261328125],
                            [-57.955908203125034, -22.109179687500003],
                            [-57.94267578124999, -21.79833984375],
                            [-57.830224609374994, -20.99794921875001],
                            [-57.91513671874998, -20.690332031249966],
                            [-57.97905273437493, -20.65732421874999],
                            [-58.00224609374996, -20.465429687499977],
                            [-58.02539062499997, -20.41582031249999],
                            [-58.05844726562495, -20.38613281249998],
                            [-58.091503906249926, -20.33320312500004],
                            [-58.124609375000034, -20.293457031250014],
                            [-58.13779296874995, -20.237304687500043],
                            [-58.15976562499998, -20.164648437499977],
                            [-58.09375, -20.15107421874997],
                            [-58.021142578124994, -20.05517578124997],
                            [-57.96015625000001, -20.04072265625004],
                            [-57.887597656249966, -20.020410156249994],
                            [-57.860742187499994, -19.97958984375002],
                            [-58.029931640624994, -19.83271484375004],
                            [-58.131494140624994, -19.74453125],
                            [-57.71679687499997, -19.044042968750034],
                            [-57.73085937499999, -18.91718750000004],
                            [-57.783105468749994, -18.91425781249997],
                            [-57.725, -18.73320312500003],
                            [-57.57402343749993, -18.279296875000014],
                            [-57.49565429687496, -18.21464843749999],
                            [-57.58647460937499, -18.122265625],
                            [-57.66166992187493, -17.94736328124999],
                            [-57.78017578125002, -17.67177734374998],
                            [-57.78886718750002, -17.573046875000017],
                            [-57.83247070312501, -17.512109375000037],
                            [-57.90502929687497, -17.53232421874999],
                            [-57.990917968749955, -17.512890625000026],
                            [-58.20556640625, -17.363085937499974],
                            [-58.347753906250006, -17.282128906249994],
                            [-58.39599609374997, -17.234277343750023],
                            [-58.417382812499994, -17.08056640624997],
                            [-58.459814453125006, -16.910742187500006],
                            [-58.478125, -16.70068359375003],
                            [-58.470605468749994, -16.650195312500045],
                            [-58.35039062500002, -16.49082031249999],
                            [-58.34560546875002, -16.284375],
                            [-58.375390624999966, -16.283593749999966],
                            [-58.423681640625034, -16.30791015625003],
                            [-58.49658203124994, -16.32666015625003],
                            [-58.537939453125034, -16.32822265624999],
                            [-60.17558593749996, -16.26933593749999],
                            [-60.187207031249955, -16.132128906250017],
                            [-60.206640625, -15.90195312500002],
                            [-60.242333984374994, -15.479589843750034],
                            [-60.38046874999998, -15.318261718750023],
                            [-60.53046874999998, -15.143164062499977],
                            [-60.58320312499998, -15.098339843749983],
                            [-60.273339843749994, -15.088769531249994],
                            [-60.372705078124994, -14.41875],
                            [-60.506591796875, -13.78984375],
                            [-61.077001953125034, -13.489746093750014],
                            [-61.129150390625, -13.498535156250028],
                            [-61.41606445312502, -13.526562499999969],
                            [-61.511572265625006, -13.541210937500011],
                            [-61.789941406249966, -13.525585937500026],
                            [-61.87412109374998, -13.470410156249983],
                            [-61.944726562499966, -13.40625],
                            [-62.09477539062499, -13.241992187499989],
                            [-62.118017578125006, -13.15976562500002],
                            [-62.765478515625034, -12.99726562500004],
                            [-63.01518554687502, -12.80556640624998],
                            [-63.067480468750006, -12.669140624999983],
                            [-63.34667968749994, -12.68007812499999],
                            [-63.68857421874998, -12.478027343749957],
                            [-64.42050781249995, -12.439746093749974],
                            [-64.783447265625, -12.059375],
                            [-65.18574218749998, -11.749511718749957],
                            [-65.389892578125, -11.246289062500011],
                            [-65.33403320312499, -10.892773437500026],
                            [-65.44711914062503, -10.507421875000034],
                            [-65.4369140625, -10.449023437499946],
                            [-65.39545898437498, -10.392285156250026],
                            [-65.31308593749998, -10.253027343749991],
                            [-65.29858398437497, -10.146777343750017],
                            [-65.39614257812494, -9.712402343749986],
                            [-66.57534179687502, -9.899902343749986],
                            [-67.41694335937495, -10.389843749999969],
                            [-67.72177734374998, -10.683105468749943],
                            [-67.83500976562496, -10.662792968749983],
                            [-67.99169921875, -10.674414062499949],
                            [-68.07167968749994, -10.703125],
                            [-68.39799804687499, -11.01875],
                            [-68.678369140625, -11.11279296875],
                            [-68.84833984374998, -11.01113281249998],
                            [-69.228515625, -10.955664062499963],
                            [-69.46254882812497, -10.948144531250023],
                            [-69.57861328125, -10.951757812499963],
                            [-69.67402343749998, -10.9541015625],
                            [-69.83979492187501, -10.93339843749996],
                            [-69.96035156249997, -10.92988281250004],
                            [-70.06630859374997, -10.982421875],
                            [-70.22006835937503, -11.04765625],
                            [-70.29038085937498, -11.064257812499974],
                            [-70.34199218750001, -11.066699218750017],
                            [-70.39228515624995, -11.058593749999972],
                            [-70.45087890624998, -11.024804687500009],
                            [-70.53325195312496, -10.946875],
                            [-70.59653320312498, -10.976855468750017],
                            [-70.642333984375, -11.010253906249986],
                            [-70.59916992187499, -9.620507812500009],
                            [-70.54111328124998, -9.4375],
                            [-70.60791015625, -9.463671875000031],
                            [-70.63691406249995, -9.478222656249969],
                            [-71.041748046875, -9.81875],
                            [-71.11528320312499, -9.852441406250009],
                            [-71.33940429687499, -9.988574218750031],
                            [-72.18159179687495, -10.003710937500003],
                            [-72.37905273437497, -9.51015625],
                            [-73.20942382812493, -9.411425781249946],
                            [-73.08984375, -9.26572265625002],
                            [-72.970361328125, -9.120117187500028],
                            [-72.97402343750002, -8.9931640625],
                            [-73.07050781249995, -8.8828125],
                            [-73.203125, -8.719335937499991],
                            [-73.30244140624995, -8.654003906250011],
                            [-73.36040039062496, -8.479296875000031],
                            [-73.39814453125001, -8.458984374999986],
                            [-73.54912109374993, -8.34580078125002],
                            [-73.73203125, -7.875390625],
                            [-73.72041015624993, -7.782519531250017],
                            [-73.76689453124999, -7.753515624999963],
                            [-73.82207031249996, -7.738964843750026],
                            [-73.89462890624998, -7.654785156250014],
                            [-73.946875, -7.611230468750023],
                            [-73.98173828124996, -7.58505859375002],
                            [-74.00205078125003, -7.556054687499966],
                            [-73.98173828124996, -7.535742187500006],
                            [-73.95849609374994, -7.506640625000031],
                            [-73.96430664062498, -7.378906250000028],
                            [-73.74946289062498, -7.335351562500037],
                            [-73.72041015624993, -7.309277343749969],
                            [-73.758203125, -7.172753906249952],
                            [-73.79301757812499, -7.135058593750003],
                            [-73.75810546874999, -6.90576171875],
                            [-73.137353515625, -6.4658203125],
                            [-73.23554687500001, -6.098437500000017],
                            [-73.209375, -6.028710937500023],
                            [-73.16289062499996, -5.933398437499974],
                            [-72.97988281249997, -5.634863281249991],
                            [-72.88706054687498, -5.122753906250026],
                            [-72.83193359374994, -5.09375],
                            [-72.69873046874997, -5.067187499999989],
                            [-72.60834960937495, -5.009570312499974],
                            [-72.46899414062497, -4.901269531250023],
                            [-72.35283203124993, -4.786035156249994],
                            [-72.25678710937501, -4.74892578124998],
                            [-71.8447265625, -4.504394531249986],
                            [-70.97368164062499, -4.350488281249994],
                            [-70.86601562499999, -4.229589843749963],
                            [-70.79951171874995, -4.173339843749957],
                            [-70.72158203124997, -4.15888671875004],
                            [-70.53066406249997, -4.167578125000034],
                            [-70.40463867187498, -4.150097656250026],
                            [-70.34365234375, -4.193652343750017],
                            [-70.31689453124994, -4.246972656250037],
                            [-70.23916015625002, -4.30117187499998],
                            [-70.12880859375, -4.286621093749943],
                            [-70.05332031249998, -4.333105468750006],
                            [-70.00395507812496, -4.327246093749963],
                            [-69.97202148437503, -4.30117187499998],
                            [-69.96591796875003, -4.2359375],
                            [-69.94819335937498, -4.200585937500009],
                            [-69.66904296875003, -2.667675781249997],
                            [-69.40024414062498, -1.194921874999977],
                            [-69.63398437500001, -0.50927734375],
                            [-70.07050781249993, -0.13886718750004],
                            [-70.05390624999993, 0.578613281250028],
                            [-69.47211914062498, 0.72993164062504],
                            [-69.15332031249994, 0.65878906250002],
                            [-69.31181640624999, 1.050488281249969],
                            [-69.85214843750003, 1.05952148437504],
                            [-69.84858398437493, 1.708740234375043],
                            [-68.17656249999999, 1.719824218749991],
                            [-68.25595703125, 1.845507812500017],
                            [-68.19379882812495, 1.987011718749983],
                            [-67.93623046874998, 1.748486328124969],
                            [-67.40043945312499, 2.116699218750028],
                            [-67.11923828124998, 1.703613281249986],
                            [-67.082275390625, 1.185400390625006],
                            [-66.87602539062499, 1.223046875000037],
                            [-66.34711914062498, 0.7671875],
                            [-66.06005859375003, 0.78535156250004],
                            [-65.68144531249999, 0.983447265624989],
                            [-65.52299804687493, 0.843408203124966],
                            [-65.55605468750002, 0.687988281250014],
                            [-65.47338867187497, 0.691259765624977],
                            [-65.10375976562497, 1.108105468749983],
                            [-64.20502929687493, 1.52949218750004],
                            [-64.00849609374995, 1.931591796874969],
                            [-63.43251953124994, 2.155566406250045],
                            [-63.389257812500006, 2.411914062500045],
                            [-64.04658203124998, 2.502392578124997],
                            [-64.22109375000002, 3.587402343749972],
                            [-64.66899414062496, 4.01181640625002],
                            [-64.788671875, 4.276025390625023],
                            [-64.57636718750001, 4.139892578125],
                            [-64.19248046874995, 4.126855468750009],
                            [-64.02148437500003, 3.929101562500051],
                            [-63.33867187500002, 3.943896484375045],
                            [-62.85698242187502, 3.593457031249969],
                            [-62.71210937499998, 4.01791992187502],
                            [-62.41064453124994, 4.156738281249972],
                            [-62.153125, 4.098388671874986],
                            [-61.82084960937496, 4.197021484375],
                            [-61.28007812500002, 4.516894531249974],
                            [-61.00283203125002, 4.535253906249991],
                            [-60.603857421875006, 4.94936523437498],
                            [-60.671972656250034, 5.164355468749989],
                            [-60.71196289062499, 5.191552734375023],
                            [-60.742138671874926, 5.202050781250037],
                            [-60.6513671875, 5.221142578125011],
                            [-60.45952148437499, 5.188085937500034],
                            [-60.40878906249998, 5.21015625],
                            [-60.33520507812497, 5.199316406250006],
                            [-60.241650390624926, 5.257958984374966],
                            [-60.14204101562498, 5.238818359374974],
                            [-59.990673828124955, 5.082861328124991],
                            [-60.14863281249998, 4.533251953125031],
                            [-59.69970703125, 4.353515625],
                        ],
                    ],
                ],
            },
            properties: {
                name: "巴西",
                childNum: 17,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-59.493310546874994, 13.081982421874997],
                        [-59.611328125, 13.102099609374989],
                        [-59.6466796875, 13.303125],
                        [-59.427636718749994, 13.152783203124997],
                        [-59.493310546874994, 13.081982421874997],
                    ],
                ],
            },
            properties: {
                name: "巴巴多斯",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [115.02675781250005, 4.899707031249989],
                            [115.1400390625, 4.899755859374991],
                            [115.290625, 4.352587890624989],
                            [115.10703125000006, 4.390429687499974],
                            [115.02675781250005, 4.899707031249989],
                        ],
                    ],
                    [
                        [
                            [115.02675781250005, 4.899707031249989],
                            [114.74667968750006, 4.718066406250017],
                            [114.84023437500005, 4.393212890625009],
                            [114.65410156250007, 4.037646484375045],
                            [114.0638671875, 4.592675781249966],
                            [114.42441406250006, 4.660400390625],
                            [114.99541015625002, 5.022363281250023],
                            [115.02675781250005, 4.899707031249989],
                        ],
                    ],
                ],
            },
            properties: {
                name: "文莱",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [91.63193359375003, 27.759960937499997],
                        [91.5947265625, 27.557666015624996],
                        [91.74306640625002, 27.442529296874994],
                        [91.85126953125001, 27.438623046874994],
                        [91.95097656249999, 27.458300781249996],
                        [91.99082031250003, 27.4501953125],
                        [92.044921875, 27.364697265624997],
                        [92.08339843750002, 27.290625],
                        [92.03115234375002, 27.214306640624997],
                        [92.00253906250003, 27.147363281249994],
                        [91.99228515625003, 27.099902343749996],
                        [91.99863281250003, 27.079296875],
                        [92.03085937500003, 27.040820312499996],
                        [92.06816406249999, 26.9751953125],
                        [92.07343750000001, 26.91484375],
                        [92.04970703125002, 26.874853515625],
                        [91.99833984374999, 26.85498046875],
                        [91.84208984374999, 26.852978515624997],
                        [91.67158203125001, 26.802001953125],
                        [91.517578125, 26.807324218749997],
                        [91.45585937499999, 26.866894531249997],
                        [91.4267578125, 26.867089843749994],
                        [91.28652343750002, 26.789941406249994],
                        [90.73964843750002, 26.771679687499997],
                        [90.34589843750001, 26.890332031249997],
                        [90.2060546875, 26.847509765625],
                        [90.12294921875002, 26.754589843749997],
                        [89.94316406249999, 26.723925781249996],
                        [89.76386718750001, 26.7015625],
                        [89.60996093750003, 26.719433593749997],
                        [89.58613281250001, 26.778955078124994],
                        [89.33212890625003, 26.8486328125],
                        [89.14824218749999, 26.816162109375],
                        [89.04091796875002, 26.865039062499996],
                        [88.85761718750001, 26.961474609374996],
                        [88.73876953125, 27.175585937499996],
                        [88.76035156250003, 27.218115234375],
                        [88.88164062499999, 27.2974609375],
                        [88.89140624999999, 27.316064453124994],
                        [88.94755859374999, 27.464013671874994],
                        [89.48066406250001, 28.059960937499994],
                        [89.53691406249999, 28.107421875],
                        [89.65273437500002, 28.15830078125],
                        [89.74980468749999, 28.188183593749997],
                        [89.81689453125, 28.256298828124997],
                        [89.89785156250002, 28.294140625],
                        [89.98105468750003, 28.311181640624994],
                        [90.34824218750003, 28.243945312499996],
                        [90.36298828125001, 28.216503906249997],
                        [90.33310546875003, 28.093994140625],
                        [90.35273437500001, 28.080224609374994],
                        [90.47734374999999, 28.070849609374996],
                        [90.63007812500001, 28.078564453124997],
                        [90.71572265625002, 28.071728515624997],
                        [91.02080078124999, 27.970068359375],
                        [91.07773437500003, 27.974462890625],
                        [91.22587890624999, 28.071240234374997],
                        [91.27304687500003, 28.078369140625],
                        [91.30683593750001, 28.064013671874996],
                        [91.36757812500002, 28.021630859374994],
                        [91.64189453124999, 27.923242187499994],
                        [91.63193359375003, 27.759960937499997],
                    ],
                ],
            },
            properties: {
                name: "不丹",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [25.2587890625, -17.793554687500006],
                        [25.242285156250006, -17.969042968750003],
                        [25.939355468750023, -18.93867187500001],
                        [26.168066406250006, -19.53828125000001],
                        [27.17822265625, -20.10097656250001],
                        [27.28076171875, -20.47871093750001],
                        [27.679296875, -20.503027343750006],
                        [27.66943359375, -21.064257812500003],
                        [28.014062500000023, -21.55419921875],
                        [29.02558593750001, -21.796875],
                        [29.042382812500023, -22.018359375],
                        [29.237207031250023, -22.07949218750001],
                        [29.315234375000017, -22.15771484375],
                        [29.36484375, -22.193945312500006],
                        [29.1298828125, -22.21328125],
                        [29.013476562500017, -22.278417968750006],
                        [28.94580078125, -22.395117187500006],
                        [28.83984375, -22.480859375],
                        [28.21015625000001, -22.693652343750003],
                        [27.812597656250006, -23.108007812500006],
                        [27.7685546875, -23.14892578125],
                        [27.085546875, -23.577929687500003],
                        [26.835058593750006, -24.240820312500006],
                        [26.617773437500006, -24.3955078125],
                        [26.451757812500006, -24.58271484375001],
                        [26.39716796875001, -24.613574218750003],
                        [26.130859375, -24.671484375],
                        [26.031835937500006, -24.702441406250003],
                        [25.912109375, -24.74746093750001],
                        [25.518164062500006, -25.66279296875001],
                        [25.21337890625, -25.75625],
                        [24.33056640625, -25.74287109375001],
                        [24.19296875, -25.632910156250006],
                        [23.969531250000017, -25.626074218750006],
                        [23.89375, -25.600878906250003],
                        [23.389257812500006, -25.29140625],
                        [23.148730468750017, -25.288671875],
                        [22.878808593750023, -25.45791015625001],
                        [22.59765625, -26.13271484375001],
                        [22.548632812500017, -26.17841796875001],
                        [22.47089843750001, -26.219042968750003],
                        [22.217578125000017, -26.38886718750001],
                        [22.090917968750006, -26.580175781250006],
                        [22.01093750000001, -26.635839843750006],
                        [21.78828125000001, -26.710058593750006],
                        [21.738085937500017, -26.80683593750001],
                        [21.694726562500023, -26.840917968750006],
                        [20.73984375, -26.84882812500001],
                        [20.641406250000017, -26.7421875],
                        [20.79316406250001, -25.915625],
                        [20.4306640625, -25.147070312500006],
                        [19.98046875, -24.77675781250001],
                        [19.977343750000017, -22.00019531250001],
                        [20.9794921875, -21.9619140625],
                        [20.97412109375, -18.31884765625],
                        [23.219335937500006, -17.99970703125001],
                        [23.599707031250006, -18.4599609375],
                        [24.243945312500017, -18.0234375],
                        [24.530566406250017, -18.052734375],
                        [24.909082031250023, -17.821386718750006],
                        [25.2587890625, -17.793554687500006],
                    ],
                ],
            },
            properties: {
                name: "博茨瓦纳",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [22.86005859375001, 10.919677734375],
                        [23.646289062500017, 9.822900390624994],
                        [23.62265625, 9.340625],
                        [23.46826171875, 9.11474609375],
                        [23.53730468750001, 8.815820312499994],
                        [24.147363281250023, 8.665625],
                        [24.291406250000023, 8.29140625],
                        [24.853320312500017, 8.137548828124991],
                        [25.20039062500001, 7.807910156249989],
                        [25.18134765625001, 7.557226562499991],
                        [25.27890625, 7.427490234375],
                        [26.36181640625, 6.635302734374989],
                        [26.30859375, 6.455322265625],
                        [26.514257812500006, 6.069238281249994],
                        [27.143945312500023, 5.722949218749989],
                        [27.4033203125, 5.109179687499989],
                        [27.071875, 5.199755859374989],
                        [26.822070312500017, 5.062402343749994],
                        [25.52509765625001, 5.31210937499999],
                        [25.065234375000017, 4.967431640624994],
                        [24.31982421875, 4.994140625],
                        [23.41718750000001, 4.663134765624989],
                        [22.864550781250017, 4.723876953125],
                        [22.422167968750017, 4.134960937499997],
                        [20.55810546875, 4.462695312499989],
                        [20.226367187500017, 4.829638671874989],
                        [19.806542968750023, 5.089306640624997],
                        [19.5009765625, 5.127490234374989],
                        [19.06855468750001, 4.891406249999989],
                        [18.594140625000023, 4.346240234374989],
                        [18.6103515625, 3.478417968749994],
                        [18.474414062500017, 3.622998046874997],
                        [18.160937500000017, 3.499804687499989],
                        [17.491601562500023, 3.687304687499989],
                        [16.610742187500023, 3.50537109375],
                        [16.468554687500017, 2.831738281249997],
                        [16.183398437500017, 2.270068359374989],
                        [16.0634765625, 2.90859375],
                        [15.128710937500017, 3.826904296875],
                        [15.063574218750006, 4.284863281249997],
                        [14.73125, 4.602392578124991],
                        [14.56298828125, 5.279931640624994],
                        [14.616894531250011, 5.865136718749994],
                        [14.43115234375, 6.038720703124994],
                        [14.7392578125, 6.27978515625],
                        [15.206738281250011, 7.206152343749991],
                        [15.480078125, 7.523779296874991],
                        [15.957617187500006, 7.507568359375],
                        [16.37890625, 7.683544921874997],
                        [16.545312500000023, 7.865478515625],
                        [16.784765625, 7.550976562499997],
                        [17.6494140625, 7.98359375],
                        [18.56416015625001, 8.0458984375],
                        [19.108691406250017, 8.656152343749994],
                        [18.886035156250017, 8.836035156249991],
                        [18.95625, 8.938867187499994],
                        [20.342089843750017, 9.127099609374994],
                        [20.773242187500017, 9.405664062499994],
                        [21.682714843750006, 10.289843749999989],
                        [21.771484375, 10.642822265625],
                        [22.49384765625001, 10.996240234374994],
                        [22.86005859375001, 10.919677734375],
                    ],
                ],
            },
            properties: {
                name: "中非",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-59.78759765624997, 43.939599609374994],
                            [-60.11748046874996, 43.95336914062506],
                            [-59.727148437500006, 44.002832031249994],
                            [-59.78759765624997, 43.939599609374994],
                        ],
                    ],
                    [
                        [
                            [-66.7625, 44.68178710937502],
                            [-66.8970703125, 44.62890625],
                            [-66.80214843749994, 44.80537109374998],
                            [-66.7625, 44.68178710937502],
                        ],
                    ],
                    [
                        [
                            [-60.961572265624966, 45.48994140625001],
                            [-61.081738281249926, 45.55781249999998],
                            [-60.91245117187498, 45.56728515625005],
                            [-60.961572265624966, 45.48994140625001],
                        ],
                    ],
                    [
                        [
                            [-73.69531249999997, 45.58549804687502],
                            [-73.85771484375002, 45.573583984375006],
                            [-73.57236328124998, 45.69448242187502],
                            [-73.69531249999997, 45.58549804687502],
                        ],
                    ],
                    [
                        [
                            [-73.56650390625003, 45.469091796875034],
                            [-73.960546875, 45.44140624999997],
                            [-73.68745117187498, 45.561425781249994],
                            [-73.47607421874997, 45.704736328124994],
                            [-73.56650390625003, 45.469091796875034],
                        ],
                    ],
                    [
                        [
                            [-61.10517578124998, 45.94472656250002],
                            [-60.86523437499997, 45.983496093750006],
                            [-61.05903320312501, 45.70336914062497],
                            [-60.73789062499995, 45.75141601562498],
                            [-60.46059570312494, 45.96870117187501],
                            [-60.733300781249994, 45.956591796875045],
                            [-60.297949218750034, 46.31123046874998],
                            [-60.22646484374994, 46.19555664062506],
                            [-59.86503906249993, 46.159521484375006],
                            [-59.8421875, 45.941552734374994],
                            [-60.67294921874995, 45.59082031250006],
                            [-61.28369140624994, 45.573876953124966],
                            [-61.44980468749995, 45.71621093750002],
                            [-61.40864257812501, 46.17036132812498],
                            [-60.87016601562499, 46.796777343749966],
                            [-60.40820312500003, 47.00351562499998],
                            [-60.332910156249966, 46.737011718749955],
                            [-60.49453125000002, 46.270263671875],
                            [-61.10517578124998, 45.94472656250002],
                        ],
                    ],
                    [
                        [
                            [-63.811279296875, 46.46870117187501],
                            [-63.68144531249993, 46.561914062499994],
                            [-63.12939453125, 46.422216796875034],
                            [-62.02373046874999, 46.42158203125001],
                            [-62.52607421875001, 46.20288085937503],
                            [-62.531347656250034, 45.977294921875],
                            [-63.02207031249998, 46.06660156249998],
                            [-62.89453125000003, 46.12358398437496],
                            [-63.056347656249955, 46.22392578124996],
                            [-62.97846679687498, 46.31635742187498],
                            [-63.21347656249998, 46.15986328124998],
                            [-63.641015624999966, 46.23046874999997],
                            [-63.758642578125034, 46.397607421874994],
                            [-64.11083984375003, 46.425439453124994],
                            [-64.13603515624999, 46.59970703125006],
                            [-64.388037109375, 46.640869140625],
                            [-63.99355468750002, 47.06157226562502],
                            [-64.08789062499997, 46.77543945312499],
                            [-63.811279296875, 46.46870117187501],
                        ],
                    ],
                    [
                        [
                            [-61.91411132812496, 47.284521484375034],
                            [-61.77255859374998, 47.25981445312499],
                            [-62.00830078124994, 47.23427734375002],
                            [-61.924707031249966, 47.425146484375006],
                            [-61.3955078125, 47.63764648437504],
                            [-61.91411132812496, 47.284521484375034],
                        ],
                    ],
                    [
                        [
                            [-54.227148437500034, 47.44135742187501],
                            [-54.32597656250002, 47.408105468749994],
                            [-54.12817382812494, 47.646826171875034],
                            [-54.227148437500034, 47.44135742187501],
                        ],
                    ],
                    [
                        [
                            [-74.70888671874997, 45.0038574218751],
                            [-73.55810546875, 45.425097656250045],
                            [-73.1595703125, 46.01005859375002],
                            [-72.10927734374997, 46.55122070312504],
                            [-71.26118164062495, 46.75625],
                            [-70.51948242187501, 47.032519531250045],
                            [-69.47104492187503, 47.96728515625006],
                            [-68.23818359374994, 48.62641601562504],
                            [-66.17817382812493, 49.21313476562503],
                            [-64.83632812499994, 49.191748046875006],
                            [-64.2162109375, 48.873632812500034],
                            [-64.51372070312493, 48.84111328124999],
                            [-64.24609374999994, 48.69111328124998],
                            [-64.34882812500001, 48.423193359375034],
                            [-65.259423828125, 48.02124023437503],
                            [-65.92670898437495, 48.188867187499994],
                            [-66.70439453125002, 48.0224609375],
                            [-66.35961914062494, 48.06064453125006],
                            [-65.84941406250002, 47.91103515625005],
                            [-65.60722656249996, 47.67001953125006],
                            [-65.00166015624995, 47.84682617187502],
                            [-64.70322265625, 47.72485351562503],
                            [-64.91220703125003, 47.36865234375003],
                            [-65.31889648437502, 47.101220703124994],
                            [-64.831396484375, 47.06079101562503],
                            [-64.88251953124993, 46.822851562500034],
                            [-64.54150390625, 46.240332031250034],
                            [-63.91591796875002, 46.165820312500045],
                            [-63.831933593749966, 46.107177734375],
                            [-64.05639648437503, 46.021337890625006],
                            [-63.70288085937494, 45.858007812500034],
                            [-62.70068359374997, 45.740576171875006],
                            [-62.750097656250006, 45.64824218750002],
                            [-62.483056640624966, 45.62182617187506],
                            [-61.955517578124955, 45.86816406249997],
                            [-61.776513671874994, 45.655615234375006],
                            [-61.49228515624998, 45.68701171875],
                            [-61.350488281249966, 45.57368164062501],
                            [-61.28198242187494, 45.441064453124994],
                            [-61.46098632812502, 45.36669921875003],
                            [-61.03154296875002, 45.29174804687506],
                            [-63.306298828124994, 44.64257812500003],
                            [-63.60400390624997, 44.68320312500006],
                            [-63.60976562499999, 44.47998046875006],
                            [-63.999707031249926, 44.64492187499999],
                            [-64.10087890624993, 44.487451171874966],
                            [-64.1669921875, 44.58666992187503],
                            [-64.28608398437493, 44.55034179687499],
                            [-64.27568359374993, 44.33408203124998],
                            [-65.48168945312497, 43.51806640625],
                            [-65.73813476562498, 43.56074218750001],
                            [-65.88691406250001, 43.79521484374999],
                            [-66.125732421875, 43.813818359375034],
                            [-66.19306640624995, 44.143847656250045],
                            [-65.86801757812498, 44.56879882812501],
                            [-66.14638671875002, 44.43593750000005],
                            [-66.090625, 44.50493164062499],
                            [-64.44814453125, 45.33745117187502],
                            [-64.13549804687497, 45.023046875],
                            [-64.09316406249997, 45.21708984375002],
                            [-63.368017578125034, 45.36479492187502],
                            [-64.87314453124998, 45.35458984375006],
                            [-64.31464843749998, 45.83569335937503],
                            [-64.48222656250002, 45.80634765624998],
                            [-64.63271484375002, 45.94663085937506],
                            [-64.77851562499998, 45.63842773437497],
                            [-65.88447265624995, 45.22290039062506],
                            [-66.10976562500002, 45.316601562499955],
                            [-66.02656249999995, 45.417578125],
                            [-66.43984374999994, 45.09589843750001],
                            [-66.87246093749997, 45.067285156249966],
                            [-67.12485351562498, 45.16943359375],
                            [-67.366943359375, 45.17377929687498],
                            [-67.43266601562496, 45.603125],
                            [-67.80224609374994, 45.7275390625],
                            [-67.806787109375, 47.08281249999999],
                            [-68.23549804687502, 47.34594726562503],
                            [-68.93720703124998, 47.21123046875002],
                            [-69.0501953125, 47.426611328125034],
                            [-69.24287109374998, 47.46298828124998],
                            [-70.00771484375002, 46.70893554687501],
                            [-70.296240234375, 45.90610351562506],
                            [-70.86503906249999, 45.27070312500001],
                            [-71.327294921875, 45.29008789062496],
                            [-71.51752929687495, 45.00756835937497],
                            [-74.663232421875, 45.00390625000003],
                            [-74.70888671874997, 45.0038574218751],
                        ],
                    ],
                    [
                        [
                            [-126.09208984374995, 49.35400390625003],
                            [-126.06401367187499, 49.26362304687501],
                            [-126.22963867187498, 49.29565429687506],
                            [-126.09208984374995, 49.35400390625003],
                        ],
                    ],
                    [
                        [
                            [-54.55439453125001, 49.5888671875],
                            [-54.786523437499966, 49.496142578125045],
                            [-54.86357421875002, 49.576074218749966],
                            [-54.55439453125001, 49.5888671875],
                        ],
                    ],
                    [
                        [
                            [-54.093701171874955, 49.74443359374999],
                            [-53.98066406250001, 49.66196289062498],
                            [-54.28613281249997, 49.595361328124994],
                            [-54.27763671875002, 49.71147460937502],
                            [-54.093701171874955, 49.74443359374999],
                        ],
                    ],
                    [
                        [
                            [-126.64121093749999, 49.605810546875006],
                            [-126.93857421874999, 49.71845703125004],
                            [-126.92583007812497, 49.837744140625006],
                            [-126.73813476562502, 49.84365234375005],
                            [-126.64121093749999, 49.605810546875006],
                        ],
                    ],
                    [
                        [
                            [-61.801123046875034, 49.093896484374966],
                            [-63.04150390624994, 49.224951171875034],
                            [-64.485205078125, 49.88696289062497],
                            [-64.13144531249995, 49.94165039062503],
                            [-62.858544921874966, 49.70546875000005],
                            [-61.817138671875, 49.28354492187498],
                            [-61.69614257812495, 49.139013671875006],
                            [-61.801123046875034, 49.093896484374966],
                        ],
                    ],
                    [
                        [
                            [-125.18413085937497, 50.09711914062498],
                            [-125.301171875, 50.4140625],
                            [-125.07402343750002, 50.22065429687501],
                            [-125.18413085937497, 50.09711914062498],
                        ],
                    ],
                    [
                        [
                            [-127.19731445312495, 50.640380859375],
                            [-125.48208007812501, 50.316796874999966],
                            [-124.83061523437499, 49.53007812500002],
                            [-123.99580078125, 49.22402343750002],
                            [-123.49702148437498, 48.58208007812499],
                            [-123.38989257812501, 48.67021484374999],
                            [-123.31064453125003, 48.41103515625002],
                            [-123.57314453124995, 48.32280273437499],
                            [-123.91694335937501, 48.386572265625034],
                            [-125.12070312500002, 48.76079101562496],
                            [-124.84965820312496, 49.02827148437501],
                            [-124.81264648437497, 49.212646484375],
                            [-124.92734374999998, 49.01420898437499],
                            [-125.489453125, 48.933789062499955],
                            [-125.82851562499998, 49.09184570312499],
                            [-125.64423828125001, 49.18579101562506],
                            [-125.95166015625001, 49.24804687500003],
                            [-125.93540039062499, 49.401464843750006],
                            [-126.51914062499999, 49.396777343750045],
                            [-126.54189453125001, 49.590478515624966],
                            [-126.13408203124997, 49.672314453124955],
                            [-126.52524414062499, 49.71958007812498],
                            [-126.90332031250001, 49.94414062499999],
                            [-127.114306640625, 49.879736328125034],
                            [-127.24980468749999, 50.13798828124996],
                            [-127.34941406249995, 50.05195312500001],
                            [-127.46713867187503, 50.163427734375006],
                            [-127.86391601562495, 50.12773437500002],
                            [-127.90585937499998, 50.44521484375002],
                            [-127.48652343749998, 50.404638671875034],
                            [-127.46591796874996, 50.58310546875006],
                            [-128.05834960937494, 50.498486328124955],
                            [-128.34604492187503, 50.744238281250006],
                            [-127.91806640624998, 50.86054687500001],
                            [-127.19731445312495, 50.640380859375],
                        ],
                    ],
                    [
                        [
                            [-55.45874023437494, 51.53652343750005],
                            [-55.58339843749994, 51.38857421875002],
                            [-56.031103515625034, 51.328369140625],
                            [-55.8, 51.033300781250034],
                            [-56.732324218749966, 50.007714843749994],
                            [-56.822167968749966, 49.613476562499955],
                            [-56.179394531249955, 50.114990234375],
                            [-56.161279296874994, 49.94013671874998],
                            [-55.50292968749997, 49.98315429687503],
                            [-56.14018554687496, 49.61914062500006],
                            [-55.869824218749955, 49.67016601562506],
                            [-56.08730468750002, 49.45195312499999],
                            [-55.375927734374955, 49.48974609374997],
                            [-55.34384765624998, 49.37290039062506],
                            [-55.22954101562496, 49.508154296875006],
                            [-55.35317382812502, 49.07944335937506],
                            [-54.50219726562503, 49.52734375],
                            [-54.44824218749997, 49.329443359375006],
                            [-53.957714843749955, 49.44184570312498],
                            [-53.61943359374996, 49.321630859375006],
                            [-53.57343750000001, 49.141210937500034],
                            [-54.16127929687494, 48.787695312500034],
                            [-53.852880859375006, 48.81132812499996],
                            [-53.966015624999955, 48.70668945312505],
                            [-53.70634765624999, 48.65551757812503],
                            [-54.11445312499998, 48.393603515625045],
                            [-53.027587890625, 48.634716796874955],
                            [-53.1357421875, 48.40185546875003],
                            [-53.60976562500002, 48.20771484375001],
                            [-53.56943359374998, 48.088085937499955],
                            [-53.869580078124926, 48.019677734374966],
                            [-53.63823242187496, 48.01464843750003],
                            [-53.863671874999966, 47.787011718749994],
                            [-53.67236328125, 47.64824218749999],
                            [-53.28271484375, 47.99785156249996],
                            [-52.86601562499993, 48.11298828124998],
                            [-53.16982421875002, 47.51210937500005],
                            [-52.945019531249955, 47.55283203124998],
                            [-52.782421874999955, 47.769433593749966],
                            [-52.653662109375034, 47.549414062500006],
                            [-53.11484375, 46.65581054687502],
                            [-53.32304687499996, 46.71835937499998],
                            [-53.589794921874955, 46.638867187499955],
                            [-53.59736328124998, 47.14599609374997],
                            [-54.00957031249993, 46.839599609375],
                            [-54.173730468749994, 46.88037109375003],
                            [-53.84951171875002, 47.440332031249994],
                            [-53.98901367187503, 47.756201171875034],
                            [-54.191845703124955, 47.85981445312501],
                            [-54.488134765625006, 47.40385742187502],
                            [-54.47392578124996, 47.54707031249998],
                            [-54.856640624999955, 47.385009765625],
                            [-55.31572265624993, 46.905712890624955],
                            [-55.78852539062498, 46.86723632812502],
                            [-55.91923828124996, 47.01689453124996],
                            [-55.49150390624996, 47.16064453125003],
                            [-54.78461914062501, 47.664746093749955],
                            [-55.366308593750034, 47.66108398437501],
                            [-55.57612304687498, 47.46523437499999],
                            [-56.12724609374999, 47.50283203125002],
                            [-55.867089843749994, 47.592333984375045],
                            [-55.85791015625, 47.81918945312498],
                            [-56.774121093749955, 47.56499023437499],
                            [-58.33686523437501, 47.73085937500002],
                            [-59.11694335937494, 47.57070312499999],
                            [-59.32065429687498, 47.736914062500006],
                            [-59.272070312500034, 47.99555664062504],
                            [-58.330224609374994, 48.52211914062502],
                            [-59.16767578124998, 48.558496093749966],
                            [-58.84179687500003, 48.74643554687498],
                            [-58.906445312499955, 48.65019531249999],
                            [-58.716455078124994, 48.59804687500002],
                            [-58.403662109375034, 49.08432617187498],
                            [-57.99052734374996, 48.987939453124966],
                            [-58.09892578124993, 49.07744140624999],
                            [-57.98007812499998, 49.229638671874994],
                            [-58.19091796875003, 49.25874023437498],
                            [-58.21337890625, 49.38666992187501],
                            [-58.01582031249998, 49.54248046874997],
                            [-57.79130859374999, 49.48999023437503],
                            [-57.92617187499999, 49.700830078124994],
                            [-57.4326171875, 50.50581054687504],
                            [-57.179589843749966, 50.614843750000034],
                            [-57.29799804687502, 50.69873046874997],
                            [-57.03593750000002, 51.01083984374998],
                            [-56.68242187500002, 51.332763671875],
                            [-56.025585937499955, 51.56835937500006],
                            [-55.6904296875, 51.471337890624994],
                            [-55.666406249999966, 51.57890624999999],
                            [-55.45874023437494, 51.53652343750005],
                        ],
                    ],
                    [
                        [
                            [-127.92465820312498, 51.47387695312497],
                            [-128.14877929687498, 51.62670898437503],
                            [-128.03173828125006, 51.708398437499966],
                            [-127.92465820312498, 51.47387695312497],
                        ],
                    ],
                    [
                        [
                            [-79.38427734374997, 51.951953125000045],
                            [-79.64375, 52.01005859374996],
                            [-79.27128906249996, 52.086816406249966],
                            [-79.38427734374997, 51.951953125000045],
                        ],
                    ],
                    [
                        [
                            [-128.36875, 52.40087890625],
                            [-128.43979492187503, 52.696386718750006],
                            [-128.24726562499998, 52.784375],
                            [-128.36875, 52.40087890625],
                        ],
                    ],
                    [
                        [
                            [-80.73168945312494, 52.74726562499998],
                            [-82.03925781249998, 53.04990234374998],
                            [-81.84731445312494, 53.18627929687497],
                            [-81.135595703125, 53.20581054687503],
                            [-80.73168945312494, 52.74726562499998],
                        ],
                    ],
                    [
                        [
                            [-131.7537109375, 53.195556640625],
                            [-131.63466796874997, 52.92216796874999],
                            [-131.97177734374998, 52.87983398437498],
                            [-131.45522460937502, 52.70170898437502],
                            [-131.59057617187494, 52.578222656250006],
                            [-131.25971679687495, 52.415917968749966],
                            [-131.31992187499998, 52.30307617187498],
                            [-131.142626953125, 52.291113281250034],
                            [-131.221533203125, 52.15361328124999],
                            [-132.16508789062493, 52.783300781250034],
                            [-132.14375, 52.99931640624999],
                            [-132.54677734374997, 53.1375],
                            [-131.7537109375, 53.195556640625],
                        ],
                    ],
                    [
                        [
                            [-128.55244140624998, 52.93974609375002],
                            [-128.50991210937502, 52.51860351562502],
                            [-128.678955078125, 52.289648437500006],
                            [-128.74633789062494, 52.763378906249955],
                            [-128.89980468749997, 52.67382812500003],
                            [-129.175927734375, 52.964941406250006],
                            [-129.033251953125, 53.27993164062505],
                            [-128.63266601562498, 53.1125],
                            [-128.55244140624998, 52.93974609375002],
                        ],
                    ],
                    [
                        [
                            [-129.167724609375, 53.11787109374998],
                            [-129.32387695312502, 53.142138671875045],
                            [-129.23818359374997, 53.33007812500006],
                            [-129.167724609375, 53.11787109374998],
                        ],
                    ],
                    [
                        [
                            [-129.84858398437498, 53.167919921874955],
                            [-130.51757812500003, 53.54423828124999],
                            [-130.45200195312498, 53.63115234375002],
                            [-129.94472656250002, 53.436376953125034],
                            [-129.75483398437498, 53.244775390624994],
                            [-129.84858398437498, 53.167919921874955],
                        ],
                    ],
                    [
                        [
                            [-130.236279296875, 53.95854492187502],
                            [-130.38422851562504, 53.84394531250001],
                            [-130.703173828125, 53.892236328124994],
                            [-130.44799804687497, 54.08901367187502],
                            [-130.236279296875, 53.95854492187502],
                        ],
                    ],
                    [
                        [
                            [-132.65551757812503, 54.12749023437496],
                            [-132.30336914062497, 54.098876953125],
                            [-132.16611328124998, 53.95522460937505],
                            [-132.53466796875, 53.651708984375034],
                            [-132.18696289062504, 53.68481445312503],
                            [-132.134423828125, 54.03427734374998],
                            [-131.66762695312502, 54.14135742187503],
                            [-131.957421875, 53.308691406250034],
                            [-132.34726562500003, 53.18920898437503],
                            [-132.747509765625, 53.310498046874955],
                            [-132.425, 53.33696289062502],
                            [-132.84501953125, 53.507714843749994],
                            [-133.07949218749997, 53.837011718750034],
                            [-133.04838867187493, 54.15893554687497],
                            [-132.65551757812503, 54.12749023437496],
                        ],
                    ],
                    [
                        [
                            [-130.92714843749997, 54.47905273437499],
                            [-130.90683593750003, 54.63178710937504],
                            [-130.75800781249998, 54.61376953125],
                            [-130.92714843749997, 54.47905273437499],
                        ],
                    ],
                    [
                        [
                            [-130.57534179687497, 54.769677734374966],
                            [-130.2140625, 55.02587890625003],
                            [-130.34941406249996, 54.814550781250034],
                            [-130.57534179687497, 54.769677734374966],
                        ],
                    ],
                    [
                        [
                            [-79.97758789062499, 56.20703125000006],
                            [-80.057470703125, 56.28735351562497],
                            [-79.57973632812502, 56.466357421875045],
                            [-79.97758789062499, 56.20703125000006],
                        ],
                    ],
                    [
                        [
                            [-78.93559570312496, 56.26606445312498],
                            [-79.17548828124998, 55.88505859374999],
                            [-79.18212890625, 56.21215820312503],
                            [-79.4951171875, 55.87475585937503],
                            [-79.76474609374995, 55.80678710937505],
                            [-79.54472656249999, 56.12836914062501],
                            [-79.9875, 55.89213867187502],
                            [-79.45888671875, 56.53974609374998],
                            [-79.53632812499995, 56.180078124999966],
                            [-79.27241210937493, 56.600439453125006],
                            [-78.93559570312496, 56.26606445312498],
                        ],
                    ],
                    [
                        [
                            [-61.743603515624955, 57.55458984375005],
                            [-61.6375, 57.41606445312499],
                            [-62.01123046875003, 57.54848632812505],
                            [-61.743603515624955, 57.55458984375005],
                        ],
                    ],
                    [
                        [
                            [-79.71650390624998, 57.515527343749994],
                            [-79.80844726562498, 57.44243164062502],
                            [-79.74257812499997, 57.60795898437499],
                            [-79.71650390624998, 57.515527343749994],
                        ],
                    ],
                    [
                        [
                            [-69.16005859375, 59.04023437500001],
                            [-69.35283203125002, 58.96074218749999],
                            [-69.30322265625003, 59.144873046875006],
                            [-69.16005859375, 59.04023437500001],
                        ],
                    ],
                    [
                        [
                            [-64.40703125, 60.367089843749966],
                            [-64.44194335937496, 60.2978515625],
                            [-64.73793945312497, 60.37563476562502],
                            [-64.83642578124997, 60.50102539062499],
                            [-64.40703125, 60.367089843749966],
                        ],
                    ],
                    [
                        [
                            [-68.23378906250002, 60.24091796875001],
                            [-68.36787109374998, 60.314746093750045],
                            [-68.08759765624998, 60.58784179687501],
                            [-67.81884765624994, 60.449511718750074],
                            [-68.23378906250002, 60.24091796875001],
                        ],
                    ],
                    [
                        [
                            [-78.531640625, 60.72856445312499],
                            [-78.66889648437498, 60.716894531250006],
                            [-78.24169921875, 60.818652343750045],
                            [-78.531640625, 60.72856445312499],
                        ],
                    ],
                    [
                        [
                            [-64.83261718749998, 61.366064453125006],
                            [-65.43212890625, 61.649511718750034],
                            [-64.78964843750003, 61.662207031250034],
                            [-64.83261718749998, 61.366064453125006],
                        ],
                    ],
                    [
                        [
                            [-65.03056640624999, 61.879052734374966],
                            [-64.89658203124995, 61.73330078125005],
                            [-65.23535156249997, 61.89770507812506],
                            [-65.03056640624999, 61.879052734374966],
                        ],
                    ],
                    [
                        [
                            [-79.54531250000002, 62.41171875000006],
                            [-79.28647460937495, 62.247656250000034],
                            [-79.32392578124995, 62.02607421875001],
                            [-79.81611328124995, 61.59462890625002],
                            [-80.26518554687496, 61.818212890625006],
                            [-80.26005859374996, 62.10903320312502],
                            [-79.9267578125, 62.39287109375002],
                            [-79.54531250000002, 62.41171875000006],
                        ],
                    ],
                    [
                        [
                            [-64.82382812499998, 62.558740234374994],
                            [-64.46503906249998, 62.535937500000045],
                            [-64.47832031250002, 62.417871093749966],
                            [-64.901220703125, 62.421044921874994],
                            [-64.82382812499998, 62.558740234374994],
                        ],
                    ],
                    [
                        [
                            [-70.33706054687497, 62.548730468749994],
                            [-70.76606445312498, 62.596875],
                            [-71.22011718750002, 62.873925781249966],
                            [-70.44262695312497, 62.73378906250002],
                            [-70.33706054687497, 62.548730468749994],
                        ],
                    ],
                    [
                        [
                            [-82.00048828124997, 62.95419921874998],
                            [-82.02583007812498, 62.73007812499998],
                            [-82.56826171875002, 62.403222656249994],
                            [-83.01582031249998, 62.20991210937498],
                            [-83.69887695312497, 62.16025390624998],
                            [-83.91049804687498, 62.45415039062499],
                            [-83.37641601562498, 62.904931640624994],
                            [-82.00048828124997, 62.95419921874998],
                        ],
                    ],
                    [
                        [
                            [-77.87670898437497, 63.470556640625034],
                            [-77.53271484374997, 63.233642578125],
                            [-77.94243164062496, 63.11440429687502],
                            [-78.536767578125, 63.423730468749994],
                            [-77.87670898437497, 63.470556640625034],
                        ],
                    ],
                    [
                        [
                            [-76.67758789062503, 63.393945312499966],
                            [-77.36474609374994, 63.588330078124955],
                            [-77.13369140624997, 63.68203125000002],
                            [-76.65244140624998, 63.503564453124994],
                            [-76.67758789062503, 63.393945312499966],
                        ],
                    ],
                    [
                        [
                            [-84.91962890624995, 65.26108398437503],
                            [-84.50112304687497, 65.45844726562501],
                            [-84.08486328125, 65.21782226562502],
                            [-82.05, 64.64428710937506],
                            [-81.67612304687498, 64.21264648437503],
                            [-81.88710937499997, 64.01640625000002],
                            [-80.82895507812495, 64.08994140625],
                            [-80.30205078124999, 63.76220703125003],
                            [-81.04638671875003, 63.461572265624966],
                            [-82.378125, 63.706787109375],
                            [-82.46708984375002, 63.92695312500001],
                            [-83.30395507812497, 64.14379882812506],
                            [-84.63291015625, 63.30922851562502],
                            [-85.39262695312496, 63.119677734375045],
                            [-85.76894531249997, 63.70034179687502],
                            [-87.15190429687499, 63.58564453125001],
                            [-86.93203124999997, 63.90166015625002],
                            [-86.252099609375, 64.13686523437497],
                            [-86.37426757812503, 64.56582031249997],
                            [-86.074609375, 65.533837890625],
                            [-85.55468750000003, 65.91865234374995],
                            [-85.17622070312501, 65.746875],
                            [-85.23994140624993, 65.51030273437499],
                            [-84.91962890624995, 65.26108398437503],
                        ],
                    ],
                    [
                        [
                            [-84.67475585937498, 65.575],
                            [-85.096337890625, 65.756201171875],
                            [-85.14960937500001, 66.01538085937506],
                            [-84.75737304687496, 65.85893554687505],
                            [-84.67475585937498, 65.575],
                        ],
                    ],
                    [
                        [
                            [-83.72597656249997, 65.796728515625],
                            [-83.23374023437495, 65.71503906249995],
                            [-83.332421875, 65.63105468749998],
                            [-84.11826171874995, 65.77177734375007],
                            [-84.40717773437501, 66.13100585937497],
                            [-83.78696289062495, 65.96577148437498],
                            [-83.72597656249997, 65.796728515625],
                        ],
                    ],
                    [
                        [
                            [-108.09272460937501, 67.00517578124999],
                            [-107.80551757812493, 66.99858398437507],
                            [-107.94394531249999, 66.8578125],
                            [-108.09272460937501, 67.00517578124999],
                        ],
                    ],
                    [
                        [
                            [-62.681542968749966, 67.05629882812502],
                            [-62.87163085937499, 67.06259765625006],
                            [-62.41679687499996, 67.18847656250003],
                            [-62.681542968749966, 67.05629882812502],
                        ],
                    ],
                    [
                        [
                            [-107.89985351562497, 67.40180664062495],
                            [-107.95024414062503, 67.31821289062498],
                            [-108.15224609374997, 67.429443359375],
                            [-108.04897460937498, 67.664892578125],
                            [-107.89985351562497, 67.40180664062495],
                        ],
                    ],
                    [
                        [
                            [-73.621728515625, 67.783837890625],
                            [-74.573388671875, 67.82866210937507],
                            [-74.70654296875003, 68.06708984374995],
                            [-73.49375, 68.00063476562502],
                            [-73.40717773437498, 67.79306640625],
                            [-73.621728515625, 67.783837890625],
                        ],
                    ],
                    [
                        [
                            [-86.59555664062498, 67.7359375],
                            [-86.89252929687498, 67.836572265625],
                            [-86.95981445312503, 68.10024414062497],
                            [-86.70209960937501, 68.30561523437498],
                            [-86.42114257812497, 68.18344726562503],
                            [-86.59555664062498, 67.7359375],
                        ],
                    ],
                    [
                        [
                            [-75.67587890624998, 68.32250976562506],
                            [-75.078125, 68.17314453124999],
                            [-75.20195312499996, 67.45917968750001],
                            [-75.78007812499996, 67.28354492187503],
                            [-76.94418945312498, 67.25029296875002],
                            [-77.30439453125001, 67.68510742187505],
                            [-77.12587890624997, 67.94707031250002],
                            [-76.59580078124998, 68.27895507812497],
                            [-75.67587890624998, 68.32250976562506],
                        ],
                    ],
                    [
                        [
                            [-78.98271484374999, 68.19282226562501],
                            [-79.17475585937493, 68.26445312500002],
                            [-78.95258789062495, 68.35302734375006],
                            [-78.98271484374999, 68.19282226562501],
                        ],
                    ],
                    [
                        [
                            [-104.54067382812497, 68.405908203125],
                            [-105.05136718749999, 68.55903320312501],
                            [-104.60200195312503, 68.56152343749997],
                            [-104.54067382812497, 68.405908203125],
                        ],
                    ],
                    [
                        [
                            [-74.880859375, 68.34868164062505],
                            [-75.40024414062503, 68.52548828125],
                            [-75.28740234374996, 68.68774414062503],
                            [-74.98364257812497, 68.64760742187502],
                            [-74.880859375, 68.34868164062505],
                        ],
                    ],
                    [
                        [
                            [-101.84589843749994, 68.58632812499997],
                            [-102.30815429687497, 68.681982421875],
                            [-102.01337890624995, 68.82539062500001],
                            [-101.73295898437495, 68.75341796875],
                            [-101.84589843749994, 68.58632812499997],
                        ],
                    ],
                    [
                        [
                            [-100.21723632812497, 68.80668945312502],
                            [-100.59653320312496, 68.76640625000007],
                            [-100.56547851562495, 69.02680664062501],
                            [-100.21723632812497, 68.80668945312502],
                        ],
                    ],
                    [
                        [
                            [-99.99467773437502, 69.01352539062503],
                            [-100.19570312500002, 68.991455078125],
                            [-100.153125, 69.12949218750003],
                            [-99.99467773437502, 69.01352539062503],
                        ],
                    ],
                    [
                        [
                            [-79.21064453124995, 68.845458984375],
                            [-79.24267578125, 69.04926757812495],
                            [-78.33256835937496, 69.38603515624999],
                            [-78.77919921875, 68.95048828124999],
                            [-79.21064453124995, 68.845458984375],
                        ],
                    ],
                    [
                        [
                            [-90.1998046875, 69.419091796875],
                            [-90.33027343749993, 69.252197265625],
                            [-90.49204101562503, 69.369873046875],
                            [-90.1998046875, 69.419091796875],
                        ],
                    ],
                    [
                        [
                            [-76.99536132812503, 69.14375],
                            [-77.37939453125, 69.2740234375],
                            [-77.18754882812502, 69.440087890625],
                            [-76.66884765625002, 69.36616210937504],
                            [-76.99536132812503, 69.14375],
                        ],
                    ],
                    [
                        [
                            [-101.171728515625, 69.39707031250003],
                            [-101.31289062499998, 69.57607421875],
                            [-101.00063476562497, 69.4619140625],
                            [-101.171728515625, 69.39707031250003],
                        ],
                    ],
                    [
                        [
                            [-95.51367187499997, 69.57363281250002],
                            [-95.43745117187498, 69.37846679687505],
                            [-95.73012695312502, 69.34755859374997],
                            [-95.80620117187499, 69.56049804687501],
                            [-95.89345703125, 69.35175781250004],
                            [-95.87583007812495, 69.60600585937505],
                            [-95.51367187499997, 69.57363281250002],
                        ],
                    ],
                    [
                        [
                            [-67.91469726562494, 69.54096679687504],
                            [-68.22138671874998, 69.61674804687502],
                            [-67.908837890625, 69.68183593749995],
                            [-67.91469726562494, 69.54096679687504],
                        ],
                    ],
                    [
                        [
                            [-78.02910156249993, 69.71489257812502],
                            [-78.03999023437495, 69.6083984375],
                            [-78.84819335937502, 69.4828125],
                            [-78.02910156249993, 69.71489257812502],
                        ],
                    ],
                    [
                        [
                            [-79.43066406250003, 69.78779296874995],
                            [-79.55283203124995, 69.63085937500006],
                            [-80.04750976562502, 69.63432617187505],
                            [-79.97783203124993, 69.50966796874997],
                            [-80.794775390625, 69.68925781250005],
                            [-80.42421875000002, 69.797607421875],
                            [-79.43066406250003, 69.78779296874995],
                        ],
                    ],
                    [
                        [
                            [-97.439453125, 69.64267578125006],
                            [-96.29995117187494, 69.34438476562505],
                            [-95.7513671875, 68.89765624999998],
                            [-95.26777343749998, 68.82607421874997],
                            [-96.40156249999995, 68.47070312500003],
                            [-97.47202148437498, 68.543701171875],
                            [-98.320556640625, 68.84272460937498],
                            [-98.70380859374993, 68.80278320312502],
                            [-98.90449218749995, 68.93242187500005],
                            [-99.25400390625002, 68.86318359374997],
                            [-99.49467773437493, 68.95957031249998],
                            [-99.455712890625, 69.13120117187503],
                            [-98.45595703124997, 69.33466796875001],
                            [-98.54599609375, 69.57290039062497],
                            [-98.04135742187498, 69.456640625],
                            [-98.20048828124996, 69.79697265625006],
                            [-97.79072265624998, 69.86162109374999],
                            [-97.439453125, 69.64267578125006],
                        ],
                    ],
                    [
                        [
                            [-86.91303710937501, 70.11323242187501],
                            [-86.55766601562499, 69.99531249999995],
                            [-87.3232421875, 70.08012695312502],
                            [-86.91303710937501, 70.11323242187501],
                        ],
                    ],
                    [
                        [
                            [-74.70888671874997, 45.0038574218751],
                            [-74.76245117187494, 44.99907226562502],
                            [-74.99614257812496, 44.970117187499966],
                            [-75.40126953124997, 44.77226562499999],
                            [-75.81933593749997, 44.468017578125],
                            [-76.18579101562503, 44.24223632812502],
                            [-76.819970703125, 43.62880859375011],
                            [-77.59653320312492, 43.62861328125007],
                            [-78.45825195312497, 43.63149414062511],
                            [-78.72041015624993, 43.62495117187501],
                            [-78.84555664062492, 43.58334960937506],
                            [-79.171875, 43.466552734375085],
                            [-79.0830566406249, 43.33139648437509],
                            [-79.05922851562494, 43.27807617187506],
                            [-79.066064453125, 43.10610351562502],
                            [-79.02617187499996, 43.01733398437506],
                            [-78.98076171874993, 42.98061523437502],
                            [-78.91508789062496, 42.90913085937504],
                            [-79.17373046875, 42.74853515625],
                            [-80.24755859374991, 42.366015625000045],
                            [-81.02822265624997, 42.247167968750006],
                            [-81.50732421874997, 42.10346679687504],
                            [-81.97416992187496, 41.88872070312499],
                            [-82.43906249999989, 41.6748535156251],
                            [-82.69003906249995, 41.675195312499994],
                            [-83.141943359375, 41.97587890624996],
                            [-83.10952148437497, 42.25068359375001],
                            [-82.54531249999997, 42.62470703124998],
                            [-82.19038085937495, 43.47407226562501],
                            [-82.137841796875, 43.570898437500034],
                            [-82.48505859374993, 45.08374023437503],
                            [-82.55107421874987, 45.3473632812501],
                            [-82.91933593749994, 45.51796875000002],
                            [-83.59267578125, 45.81713867187506],
                            [-83.46948242187503, 45.99467773437499],
                            [-83.61596679687503, 46.116845703124994],
                            [-83.97778320312494, 46.08491210937507],
                            [-84.12319335937497, 46.50292968749997],
                            [-84.44047851562496, 46.49814453125006],
                            [-84.66577148437503, 46.54326171875002],
                            [-84.87597656249994, 46.89990234375003],
                            [-85.07006835937497, 46.97993164062498],
                            [-85.65224609375, 47.21997070312503],
                            [-86.67216796874996, 47.636425781249955],
                            [-87.20800781249997, 47.848486328125006],
                            [-87.74389648437497, 48.06054687500003],
                            [-88.37817382812497, 48.30307617187506],
                            [-89.45566406249992, 47.99624023437508],
                            [-90.79731445312495, 48.13105468750001],
                            [-91.04345703124991, 48.19370117187498],
                            [-91.38720703124997, 48.05854492187498],
                            [-92.00517578125002, 48.301855468750006],
                            [-92.3484375, 48.276611328125],
                            [-92.41459960937493, 48.276611328125],
                            [-92.50058593749995, 48.43535156250002],
                            [-92.83671875, 48.567773437499994],
                            [-93.25795898437497, 48.62885742187501],
                            [-93.37788085937498, 48.61655273437498],
                            [-93.70771484374995, 48.525439453125074],
                            [-93.85161132812496, 48.607275390625034],
                            [-94.6208984374999, 48.7426269531251],
                            [-94.71279296874997, 48.863427734374994],
                            [-94.80346679687497, 49.0029296875],
                            [-94.86040039062493, 49.258593750000045],
                            [-94.85434570312495, 49.304589843749994],
                            [-95.15527343749997, 49.3696777343751],
                            [-95.16206054687493, 48.991748046875045],
                            [-95.39790039062493, 48.99316406249997],
                            [-96.25068359374993, 48.99316406249997],
                            [-96.67705078124993, 48.99316406249997],
                            [-97.52983398437493, 48.99316406249997],
                            [-98.80898437499995, 48.99316406249997],
                            [-104.77832031249997, 48.993115234375125],
                            [-110.7476562499999, 48.993066406250136],
                            [-116.71704101562493, 48.993066406250136],
                            [-118.84892578124993, 48.993066406250136],
                            [-119.27534179687494, 48.993066406250136],
                            [-119.70170898437495, 48.99301757812495],
                            [-120.98085937499995, 48.99301757812495],
                            [-122.78876953124994, 48.99301757812495],
                            [-122.82670898437495, 49.028417968750034],
                            [-122.9241699218749, 49.07465820312504],
                            [-122.96269531249993, 49.07460937500005],
                            [-123.06328125, 48.97773437500001],
                            [-123.22944335937493, 49.260498046875085],
                            [-122.87910156249995, 49.39892578125003],
                            [-123.27675781249997, 49.34394531250001],
                            [-123.1875, 49.680322265624994],
                            [-123.53056640624989, 49.39731445312506],
                            [-124.02861328125002, 49.602880859375006],
                            [-123.99262695312497, 49.736181640625006],
                            [-123.81718749999993, 49.58657226562508],
                            [-123.58247070312498, 49.68125],
                            [-123.87441406250005, 49.736816406250114],
                            [-123.82543945312493, 50.14423828124998],
                            [-123.94589843749995, 50.18393554687509],
                            [-123.9849121093749, 49.87558593749998],
                            [-124.28125, 49.77211914062502],
                            [-124.78237304687492, 50.02011718749992],
                            [-125.05668945312495, 50.418652343750125],
                            [-124.8598632812499, 50.872412109375006],
                            [-125.05878906249993, 50.51386718749998],
                            [-125.4763183593749, 50.49716796874995],
                            [-125.53935546874996, 50.64902343749998],
                            [-125.64130859374994, 50.46621093750005],
                            [-126.09433593749995, 50.497607421875045],
                            [-126.44746093750004, 50.58774414062492],
                            [-125.90410156250002, 50.704931640625006],
                            [-126.51435546875, 50.679394531250125],
                            [-126.37460937499995, 50.83735351562498],
                            [-126.5217773437499, 50.86606445312498],
                            [-126.51733398437497, 51.0568359375001],
                            [-126.63178710937494, 50.915136718750006],
                            [-127.057568359375, 50.86752929687509],
                            [-127.70810546875, 51.15117187499996],
                            [-127.41967773437496, 51.608056640625136],
                            [-126.69145507812502, 51.70341796875002],
                            [-127.33872070312489, 51.70737304687495],
                            [-127.66870117187497, 51.47758789062502],
                            [-127.85053710937498, 51.67319335937509],
                            [-127.79536132812493, 52.19101562500006],
                            [-127.43793945312504, 52.356152343750125],
                            [-127.24223632812496, 52.39511718750009],
                            [-126.71396484374989, 52.060693359374994],
                            [-127.19399414062498, 52.45766601562502],
                            [-126.95136718749994, 52.7510253906251],
                            [-127.01933593750002, 52.8424804687501],
                            [-127.06621093749989, 52.65268554687498],
                            [-127.79189453124994, 52.28935546875002],
                            [-128.10224609374993, 51.78842773437495],
                            [-128.3576171875, 52.1588867187501],
                            [-128.0375, 52.318164062500045],
                            [-127.94023437499996, 52.545166015625085],
                            [-128.27153320312493, 52.3629882812501],
                            [-128.05327148437487, 52.91069335937496],
                            [-128.3650390624999, 52.82578125000006],
                            [-128.52470703125002, 53.1406738281251],
                            [-129.08090820312492, 53.36728515625006],
                            [-129.1715820312499, 53.53359375000002],
                            [-128.8545898437499, 53.70454101562504],
                            [-128.90561523437492, 53.559326171875114],
                            [-128.5421386718749, 53.420654296875114],
                            [-128.13271484375002, 53.417773437500045],
                            [-127.92783203125, 53.274707031250045],
                            [-128.2072265624999, 53.483203125000074],
                            [-128.67553710937494, 53.55458984375005],
                            [-128.76367187500003, 53.746875],
                            [-128.5321289062499, 53.85810546875007],
                            [-128.959375, 53.84145507812505],
                            [-129.2578613281249, 53.417968750000085],
                            [-129.56372070312506, 53.251464843750114],
                            [-130.33525390625002, 53.723925781250074],
                            [-130.04331054687495, 54.13354492187503],
                            [-129.62602539062493, 54.23027343750002],
                            [-130.08422851562503, 54.18139648437503],
                            [-130.4302734375, 54.42099609374998],
                            [-129.56064453124995, 55.46254882812508],
                            [-129.79516601562503, 55.559570312500114],
                            [-130.04848632812494, 55.05727539062511],
                            [-130.01406249999997, 55.950537109375006],
                            [-130.09785156249995, 56.10927734375002],
                            [-130.41313476562487, 56.12250976562507],
                            [-130.47709960937496, 56.230566406250034],
                            [-130.649072265625, 56.26367187500003],
                            [-131.471875, 56.55673828125006],
                            [-131.82426757812496, 56.58999023437508],
                            [-131.86616210937495, 56.792822265625006],
                            [-132.1042968749999, 56.85678710937509],
                            [-132.062890625, 56.95336914062503],
                            [-132.33798828124992, 57.07944335937498],
                            [-132.27939453124998, 57.14536132812506],
                            [-132.23217773437494, 57.198535156250074],
                            [-132.30166015625005, 57.2763183593751],
                            [-132.44248046874986, 57.40673828125003],
                            [-132.55048828124995, 57.499902343749994],
                            [-133.00141601562495, 57.948974609375],
                            [-133.27529296875, 58.22285156250004],
                            [-133.54638671874997, 58.50346679687499],
                            [-134.21850585937503, 58.849902343750045],
                            [-134.32963867187505, 58.93969726562506],
                            [-134.39306640625, 59.009179687499994],
                            [-134.67724609374997, 59.19926757812499],
                            [-134.94375, 59.28828125000001],
                            [-135.05102539062491, 59.57866210937502],
                            [-135.36787109374998, 59.743310546874994],
                            [-135.70258789062504, 59.72875976562506],
                            [-136.3218261718749, 59.604833984375034],
                            [-136.27797851562494, 59.48032226562506],
                            [-136.46635742187493, 59.459082031250006],
                            [-136.57875976562494, 59.15224609375002],
                            [-136.81328125000002, 59.15004882812511],
                            [-137.12622070312491, 59.04096679687507],
                            [-137.2775390624999, 58.988183593749994],
                            [-137.43857421874995, 58.903125],
                            [-137.52089843749994, 58.91538085937506],
                            [-137.59331054687493, 59.22626953124998],
                            [-138.317626953125, 59.611132812500074],
                            [-138.86875, 59.94575195312501],
                            [-139.18515624999986, 60.083593750000034],
                            [-139.13696289062494, 60.17270507812506],
                            [-139.07924804687497, 60.279443359375136],
                            [-139.07924804687497, 60.3437011718751],
                            [-139.23476562499997, 60.339746093749994],
                            [-139.67631835937505, 60.32832031249998],
                            [-139.97329101562497, 60.183154296875074],
                            [-140.45283203125004, 60.29970703125002],
                            [-140.5254394531249, 60.21835937499995],
                            [-140.76274414062505, 60.25913085937509],
                            [-141.00214843750004, 60.300244140625125],
                            [-141.00214843750004, 60.884667968749994],
                            [-141.00214843750004, 61.761279296875045],
                            [-141.00214843750004, 63.22226562499998],
                            [-141.00214843750004, 64.09887695312506],
                            [-141.00214843750004, 65.55991210937498],
                            [-141.00214843750004, 66.43652343750006],
                            [-141.00214843750004, 67.89755859374998],
                            [-141.00214843750004, 68.77416992187506],
                            [-141.00214843750004, 69.65078125000011],
                            [-139.18154296874997, 69.51552734375008],
                            [-137.25996093749998, 68.96411132812503],
                            [-136.12236328124993, 68.88222656250002],
                            [-135.258837890625, 68.68432617187503],
                            [-135.93901367187487, 68.9741699218751],
                            [-135.575537109375, 69.02695312500003],
                            [-135.91020507812487, 69.11147460937502],
                            [-135.6914550781249, 69.31118164062502],
                            [-135.29282226562486, 69.30786132812506],
                            [-135.1408203124999, 69.46782226562496],
                            [-134.45683593749993, 69.47763671875],
                            [-134.40893554687494, 69.68178710937502],
                            [-133.87978515624997, 69.50771484375011],
                            [-134.17431640624991, 69.25283203125005],
                            [-133.16313476562496, 69.43388671874999],
                            [-132.91533203125002, 69.62963867187506],
                            [-132.40390625, 69.65874023437496],
                            [-132.48847656249993, 69.73808593749996],
                            [-132.16342773437498, 69.70498046875014],
                            [-131.13637695312497, 69.90688476562505],
                            [-130.66547851562495, 70.12705078124998],
                            [-129.944970703125, 70.09091796875006],
                            [-129.675634765625, 70.19296875000009],
                            [-129.64829101562495, 69.9977539062501],
                            [-130.83208007812487, 69.65146484375006],
                            [-131.9377929687499, 69.5347167968751],
                            [-132.8174804687499, 69.20576171875004],
                            [-133.41831054687492, 68.84428710937493],
                            [-133.138037109375, 68.74658203125011],
                            [-133.33666992187497, 68.83525390625005],
                            [-132.57763671874997, 68.84780273437514],
                            [-132.71894531249998, 69.07919921875],
                            [-131.78837890625002, 69.43198242187495],
                            [-131.32470703124997, 69.36118164062509],
                            [-131.06342773437504, 69.45068359375003],
                            [-130.97065429687495, 69.20908203125],
                            [-130.1176269531249, 69.720068359375],
                            [-128.89892578124994, 69.96616210937506],
                            [-129.15791015624995, 69.80009765624999],
                            [-129.05434570312502, 69.70107421875005],
                            [-128.85302734375003, 69.7510253906251],
                            [-127.68378906249994, 70.26035156249995],
                            [-128.17011718749998, 70.41845703125],
                            [-127.99101562499992, 70.57382812500003],
                            [-127.22597656249992, 70.29614257812497],
                            [-126.25043945312495, 69.54526367187492],
                            [-125.52495117187495, 69.35156250000009],
                            [-125.171875, 69.42797851562503],
                            [-125.35693359374991, 69.62597656250003],
                            [-124.767919921875, 69.99003906249996],
                            [-124.99038085937494, 70.02661132812511],
                            [-124.55502929687488, 70.15122070312509],
                            [-124.40693359374991, 69.76743164062506],
                            [-124.12460937499995, 69.6899902343751],
                            [-124.33808593749991, 69.36484374999995],
                            [-123.5284179687499, 69.38935546874995],
                            [-123.02578125, 69.81000976562504],
                            [-122.07006835937499, 69.81616210937506],
                            [-120.96245117187502, 69.66040039062511],
                            [-120.13999023437488, 69.38056640625013],
                            [-117.22695312499998, 68.913427734375],
                            [-116.05947265625, 68.83701171875006],
                            [-116.2434082031249, 68.9740722656251],
                            [-115.44228515624994, 68.94091796875009],
                            [-114.62016601562496, 68.74609375],
                            [-113.96440429687495, 68.39907226562502],
                            [-114.09594726562491, 68.26679687500007],
                            [-114.76528320312494, 68.27021484375004],
                            [-115.12705078124992, 68.13203124999995],
                            [-115.43447265624994, 67.90234375000006],
                            [-115.13320312499994, 67.819189453125],
                            [-112.50302734374993, 67.6819335937501],
                            [-110.9900390624999, 67.79082031250007],
                            [-110.07392578124995, 67.99291992187506],
                            [-109.63037109374991, 67.73271484374996],
                            [-109.03803710937504, 67.69116210937503],
                            [-108.85200195312497, 67.42197265625009],
                            [-108.61333007812493, 67.59804687500008],
                            [-107.98872070312495, 67.2563964843751],
                            [-107.99130859374995, 67.09516601562513],
                            [-108.49604492187493, 67.09228515625006],
                            [-107.25947265624998, 66.39853515624995],
                            [-107.71035156250001, 66.74003906250007],
                            [-107.7250976562499, 66.98413085937506],
                            [-107.15649414062497, 66.88173828124997],
                            [-107.9583984375, 67.81860351562506],
                            [-107.79829101562498, 68.03691406249996],
                            [-106.42426757812491, 68.20058593750008],
                            [-105.7501953125, 68.59228515625011],
                            [-106.45805664062496, 68.51645507812495],
                            [-106.60849609374988, 68.35737304687504],
                            [-107.61933593749994, 68.3310546875],
                            [-107.73417968749989, 68.17373046875011],
                            [-108.3228027343749, 68.15410156250002],
                            [-108.71811523437488, 68.29746093750009],
                            [-108.31347656249996, 68.61079101562498],
                            [-106.16445312499992, 68.91987304687507],
                            [-105.68559570312489, 68.82817382812505],
                            [-105.3774414062499, 68.413818359375],
                            [-104.65317382812488, 68.23007812500003],
                            [-104.48681640624991, 68.06318359374998],
                            [-103.47412109374993, 68.11503906250005],
                            [-102.32036132812489, 67.73564453125005],
                            [-101.55498046874992, 67.69316406250007],
                            [-100.21293945312489, 67.83857421875004],
                            [-98.92045898437502, 67.72578124999998],
                            [-98.41210937499991, 67.80717773437505],
                            [-98.63154296875004, 68.0725585937501],
                            [-97.45493164062486, 67.61699218750002],
                            [-97.20654296874989, 67.85507812500003],
                            [-97.73911132812495, 67.97817382812505],
                            [-98.19252929687494, 67.92299804687502],
                            [-98.65048828124989, 68.36352539062506],
                            [-98.21855468750002, 68.31743164062507],
                            [-97.7942382812499, 68.38759765625],
                            [-97.9250976562499, 68.523681640625],
                            [-97.41035156249993, 68.49653320312498],
                            [-96.97670898437497, 68.25541992187505],
                            [-96.43066406249991, 68.3105957031251],
                            [-96.72207031250005, 68.03876953124998],
                            [-95.9703125, 68.24912109375],
                            [-96.36914062499991, 67.50976562500003],
                            [-96.14145507812489, 67.27182617187503],
                            [-95.71992187499998, 67.31679687500014],
                            [-95.77768554687495, 67.18461914062505],
                            [-95.41591796875005, 67.15556640624999],
                            [-95.41889648437504, 67.01323242187493],
                            [-96.42255859374995, 67.05175781249997],
                            [-95.7875488281249, 66.616796875],
                            [-96.03686523437489, 66.9375],
                            [-95.39965820312503, 66.94946289062509],
                            [-95.25874023437493, 67.26254882812492],
                            [-95.65048828124986, 67.73745117187505],
                            [-95.46069335937503, 68.02138671875],
                            [-94.74443359374993, 68.07089843749995],
                            [-93.44892578124998, 68.61889648437503],
                            [-93.85244140624994, 69.00034179687495],
                            [-94.06489257812495, 68.78476562500006],
                            [-94.600439453125, 68.80322265625011],
                            [-94.08364257812497, 69.12309570312507],
                            [-94.254736328125, 69.31376953125002],
                            [-93.61948242187492, 69.41699218750009],
                            [-93.74853515624991, 69.2261230468751],
                            [-93.5322753906249, 69.48090820312495],
                            [-94.2708007812499, 69.45512695312505],
                            [-94.63383789062496, 69.64965820312506],
                            [-94.82250976562494, 69.577783203125],
                            [-95.96494140624989, 69.80278320312499],
                            [-96.5513671875, 70.21030273437506],
                            [-96.29770507812492, 70.51137695312511],
                            [-95.87861328124998, 70.54897460937514],
                            [-95.88632812499986, 70.69428710937507],
                            [-96.25800781249993, 70.64228515625013],
                            [-96.54892578124995, 70.80874023437511],
                            [-96.44658203124996, 71.23989257812502],
                            [-96.06201171874997, 71.41386718749993],
                            [-95.5642578124999, 71.33676757812503],
                            [-95.40625, 71.49165039062498],
                            [-95.87231445312494, 71.57314453125005],
                            [-94.73486328124994, 71.98295898437507],
                            [-94.30834960937491, 71.76489257812506],
                            [-93.74628906249998, 71.742822265625],
                            [-92.94868164062493, 71.26210937500011],
                            [-92.98144531249994, 70.8522460937501],
                            [-91.56406249999995, 70.1782714843751],
                            [-92.32050781250004, 70.2353515625],
                            [-92.51186523437494, 70.10385742187503],
                            [-91.976708984375, 70.03867187500009],
                            [-92.88779296874989, 69.66821289062511],
                            [-92.31166992187494, 69.67290039062499],
                            [-91.91196289062495, 69.53125],
                            [-91.20180664062494, 69.64477539062494],
                            [-91.43994140624997, 69.52568359375002],
                            [-90.4155761718749, 69.45698242187507],
                            [-90.89228515625004, 69.26728515624995],
                            [-91.23720703125005, 69.28554687500014],
                            [-90.47900390624994, 68.88115234374999],
                            [-90.57363281250005, 68.47470703124998],
                            [-90.20478515625004, 68.25747070312511],
                            [-89.27954101562491, 69.25546875000003],
                            [-88.22353515625, 68.91503906249997],
                            [-87.81357421874986, 68.34570312499997],
                            [-87.89267578125, 68.24814453125],
                            [-88.34697265624993, 68.28828125000001],
                            [-88.313818359375, 67.95034179687508],
                            [-87.359375, 67.17724609374997],
                            [-86.56079101562491, 67.48212890625007],
                            [-85.64316406249992, 68.69970703124997],
                            [-84.86757812499994, 68.77333984375005],
                            [-85.10664062499995, 68.84404296875007],
                            [-84.86220703125, 69.07397460937503],
                            [-85.38676757812493, 69.23188476562504],
                            [-85.50737304687487, 69.84526367187493],
                            [-82.61835937499993, 69.69106445312514],
                            [-82.39023437499989, 69.60087890625007],
                            [-82.75483398437493, 69.49438476562506],
                            [-82.30986328124996, 69.41000976562509],
                            [-82.22753906249997, 69.24887695312495],
                            [-81.37783203125005, 69.18564453125003],
                            [-81.95791015624991, 68.88364257812498],
                            [-81.38090820312496, 68.85004882812504],
                            [-81.28154296874987, 68.65722656250003],
                            [-81.91484374999993, 68.4587890625001],
                            [-82.55268554687504, 68.44648437500007],
                            [-82.22241210937489, 68.145263671875],
                            [-82.0125, 68.19389648437496],
                            [-81.97646484374997, 67.86201171875001],
                            [-81.2943359375, 67.497412109375],
                            [-81.46757812499996, 67.0698730468751],
                            [-83.40644531249998, 66.37124023437508],
                            [-84.53847656249994, 66.97280273437505],
                            [-84.84575195312502, 67.02871093750008],
                            [-85.11372070312498, 66.90693359375013],
                            [-84.73774414062504, 66.93359375000006],
                            [-84.223046875, 66.68247070312506],
                            [-83.86904296875, 66.2135742187501],
                            [-84.29306640624995, 66.29179687500005],
                            [-84.628076171875, 66.20771484374998],
                            [-85.603857421875, 66.56826171875005],
                            [-86.708154296875, 66.52304687500009],
                            [-86.68510742187502, 66.36040039062499],
                            [-85.95874023437491, 66.11904296875002],
                            [-87.45288085937503, 65.33896484375009],
                            [-87.96997070312503, 65.34892578124999],
                            [-89.7494140625, 65.93603515625006],
                            [-89.88969726562487, 65.86855468749997],
                            [-91.42724609374994, 65.94790039062497],
                            [-91.04111328124989, 65.82983398437509],
                            [-90.98344726562496, 65.91923828124999],
                            [-89.92407226562497, 65.78027343750011],
                            [-88.97402343749994, 65.34829101562502],
                            [-87.02753906249995, 65.19809570312498],
                            [-88.10561523437497, 64.18330078125001],
                            [-88.81772460937489, 63.99223632812499],
                            [-89.20063476562493, 64.11376953125006],
                            [-89.13154296874998, 63.96850585937494],
                            [-89.61582031249995, 64.030615234375],
                            [-89.8113281249999, 64.18056640625],
                            [-90.04165039062494, 64.14086914062509],
                            [-89.85571289062497, 63.9569824218751],
                            [-90.16816406250004, 63.978759765625085],
                            [-90.15473632812498, 63.68964843749998],
                            [-90.81191406249991, 63.580908203125034],
                            [-91.98222656249996, 63.82241210937502],
                            [-92.33842773437496, 63.787646484375045],
                            [-93.69633789062493, 64.14716796875013],
                            [-93.55981445312491, 63.865283203125074],
                            [-93.27021484374998, 63.840869140625074],
                            [-93.37851562499992, 63.94848632812497],
                            [-92.15688476562491, 63.691699218750045],
                            [-92.46508789062491, 63.55507812500011],
                            [-91.84184570312496, 63.69755859374999],
                            [-90.97006835937489, 63.442773437500136],
                            [-90.69858398437492, 63.06386718750005],
                            [-91.44897460937503, 62.804052734375034],
                            [-92.3612792968749, 62.81938476562496],
                            [-91.93583984374993, 62.59238281250009],
                            [-92.55141601562491, 62.546728515625034],
                            [-92.76596679687492, 62.34995117187509],
                            [-92.52797851562494, 62.16840820312504],
                            [-93.20537109374993, 62.364941406250125],
                            [-92.90551757812503, 62.21513671874996],
                            [-93.3330566406249, 61.93291015625002],
                            [-93.58178710937494, 61.94204101562511],
                            [-93.31201171874997, 61.76728515625004],
                            [-93.91274414062497, 61.48144531250006],
                            [-94.509375, 60.60454101562493],
                            [-94.76171874999991, 60.498242187500125],
                            [-94.78828124999998, 59.26787109374993],
                            [-94.95732421874996, 59.068847656250085],
                            [-94.28706054687493, 58.716015625000125],
                            [-94.33222656249998, 58.297363281250114],
                            [-94.12319335937494, 58.73671875000008],
                            [-93.1787597656249, 58.72563476562496],
                            [-92.43281249999993, 57.3203125],
                            [-92.7981445312499, 56.921972656250034],
                            [-90.89746093750003, 57.25693359375006],
                            [-88.94848632812489, 56.85131835937503],
                            [-88.07509765624997, 56.46728515624994],
                            [-87.48242187499991, 56.021289062500045],
                            [-85.55932617187491, 55.54018554687508],
                            [-85.21801757812491, 55.348974609375034],
                            [-85.3652832031249, 55.07929687499998],
                            [-85.06093749999997, 55.285644531250085],
                            [-83.91059570312493, 55.314648437499955],
                            [-82.39326171874998, 55.067822265625125],
                            [-82.219384765625, 54.8134765625],
                            [-82.42416992187486, 54.2445800781251],
                            [-82.14145507812492, 53.81762695312497],
                            [-82.29155273437496, 53.03071289062507],
                            [-81.5994140624999, 52.432617187500085],
                            [-81.82788085937489, 52.22421875000009],
                            [-81.46621093749994, 52.204492187500136],
                            [-80.588037109375, 51.667236328125114],
                            [-80.4433105468749, 51.38857421875002],
                            [-80.85122070312497, 51.125],
                            [-80.47832031249993, 51.30732421874998],
                            [-80.10356445312487, 51.282861328125136],
                            [-79.34790039062494, 50.76264648437504],
                            [-79.737451171875, 51.186279296875],
                            [-79.33867187500002, 51.62817382812497],
                            [-79.04052734375003, 51.46376953125005],
                            [-78.90317382812495, 51.200292968750034],
                            [-78.73134765624994, 51.497460937499994],
                            [-78.98164062499993, 51.774560546875136],
                            [-78.44809570312495, 52.26137695312502],
                            [-78.74414062499994, 52.65537109374998],
                            [-79.10034179687497, 53.65664062500005],
                            [-78.99604492187493, 54.00249023437499],
                            [-79.241796875, 54.098876953125085],
                            [-79.14672851562491, 54.16923828125002],
                            [-79.71235351562495, 54.6718261718751],
                            [-77.77529296874994, 55.291259765625],
                            [-76.60405273437496, 56.19956054687495],
                            [-76.52558593749998, 56.8917968750001],
                            [-76.80981445312497, 57.65795898437506],
                            [-77.15678710937496, 58.018896484375034],
                            [-78.51508789062493, 58.68237304687503],
                            [-77.76069335937498, 59.38002929687505],
                            [-77.72617187499995, 59.67587890624992],
                            [-77.34907226562495, 59.57895507812509],
                            [-77.48530273437493, 59.684570312500114],
                            [-77.28920898437494, 60.0220214843751],
                            [-77.58588867187498, 60.088183593750074],
                            [-77.45288085937497, 60.1458007812501],
                            [-77.6814453124999, 60.427099609375034],
                            [-77.503564453125, 60.54272460937497],
                            [-77.7908203124999, 60.63984375000004],
                            [-77.58955078124993, 60.808593750000114],
                            [-78.18134765624995, 60.81914062499996],
                            [-77.51435546874998, 61.55629882812505],
                            [-78.02138671874997, 61.8320800781251],
                            [-78.13339843749986, 62.28227539062496],
                            [-77.372412109375, 62.572509765625114],
                            [-75.81689453124991, 62.31586914062507],
                            [-75.7898437499999, 62.17958984375002],
                            [-75.3412109375, 62.312109375],
                            [-74.63256835937497, 62.115673828125125],
                            [-74.6458007812499, 62.21113281250004],
                            [-73.70507812499991, 62.47314453124994],
                            [-72.68696289062498, 62.12456054687499],
                            [-72.771630859375, 61.840429687500006],
                            [-72.50556640624998, 61.922656250000074],
                            [-72.22612304687487, 61.83159179687499],
                            [-72.04003906249991, 61.68027343750006],
                            [-72.21586914062502, 61.58725585937495],
                            [-71.86611328125, 61.68852539062499],
                            [-71.63828124999995, 61.6171875],
                            [-71.85439453124991, 61.43979492187492],
                            [-71.42270507812489, 61.158935546875085],
                            [-70.27929687499991, 61.06865234374999],
                            [-69.99243164062491, 60.8564941406251],
                            [-69.50332031249994, 61.04042968750011],
                            [-69.40473632812493, 60.84677734375009],
                            [-69.75947265624998, 60.440234375000045],
                            [-69.67373046874994, 60.07587890625007],
                            [-70.65483398437496, 60.02622070312506],
                            [-69.73393554687493, 59.918017578125045],
                            [-69.68188476562489, 59.34174804687507],
                            [-69.3440429687499, 59.303076171875006],
                            [-69.53164062499994, 58.86923828125009],
                            [-69.64838867187493, 58.82080078125],
                            [-69.78417968749994, 58.95571289062511],
                            [-70.15434570312496, 58.76059570312498],
                            [-69.78989257812486, 58.689306640625034],
                            [-69.27109374999986, 58.88393554687505],
                            [-68.69819335937495, 58.904541015625],
                            [-68.38115234374993, 58.74350585937506],
                            [-68.22939453124994, 58.48457031250007],
                            [-68.35654296874989, 58.163232421875136],
                            [-69.04082031249996, 57.902490234375136],
                            [-68.41357421874997, 58.0517578125],
                            [-68.02104492187493, 58.48530273437504],
                            [-67.88828124999989, 58.29575195312495],
                            [-68.06386718750005, 58.13896484374999],
                            [-67.75595703124992, 58.4045898437501],
                            [-67.6782714843749, 57.99111328125008],
                            [-67.5696289062499, 58.21347656250006],
                            [-66.72216796874991, 58.49101562499996],
                            [-66.36240234374989, 58.791162109374994],
                            [-66.0023925781249, 58.43120117187502],
                            [-66.04306640624995, 58.82065429687495],
                            [-65.72099609374996, 59.02377929687495],
                            [-65.38354492187494, 59.06020507812508],
                            [-65.7, 59.21333007812501],
                            [-65.4117187499999, 59.31499023437496],
                            [-65.47509765624994, 59.47031249999998],
                            [-65.03823242187494, 59.38789062500007],
                            [-65.40742187499993, 59.53935546875002],
                            [-65.4333984374999, 59.776513671874994],
                            [-65.02817382812495, 59.77070312500007],
                            [-65.17172851562489, 59.90800781249996],
                            [-64.81733398437498, 60.3310546875],
                            [-64.49941406250005, 60.26826171875001],
                            [-64.41958007812494, 60.17138671874997],
                            [-64.76845703124997, 60.01210937500005],
                            [-64.28349609374993, 60.06406249999998],
                            [-64.22631835937491, 59.741210937500085],
                            [-64.05605468750005, 59.82255859374996],
                            [-63.7501953124999, 59.51259765625005],
                            [-63.945458984374994, 59.380175781250074],
                            [-63.775878906249915, 59.277148437500045],
                            [-63.539892578124864, 59.332861328125034],
                            [-63.41513671874995, 59.194384765625074],
                            [-63.97114257812498, 59.053808593750034],
                            [-63.24843749999991, 59.068310546874955],
                            [-63.28212890624994, 58.86738281250007],
                            [-63.05029296874997, 58.87817382812494],
                            [-62.87387695312489, 58.67246093749998],
                            [-63.537060546874926, 58.329931640625006],
                            [-63.209960937499886, 58.46694335937502],
                            [-62.593847656249864, 58.47402343750005],
                            [-62.81206054687502, 58.20039062500007],
                            [-63.26152343749993, 58.014697265625074],
                            [-62.486230468749966, 58.15405273437506],
                            [-62.30566406249997, 57.97226562499995],
                            [-61.95864257812505, 57.91176757812508],
                            [-61.9679687499999, 57.61191406250009],
                            [-62.495556640624926, 57.489208984375125],
                            [-61.92114257812497, 57.42080078125005],
                            [-61.977441406249966, 57.24794921875002],
                            [-61.33374023437494, 57.01059570312498],
                            [-61.37163085937502, 56.68081054687511],
                            [-62.497265624999926, 56.80170898437504],
                            [-61.73774414062498, 56.52602539062502],
                            [-61.940429687499886, 56.423583984375114],
                            [-61.42529296874994, 56.360644531250074],
                            [-61.713085937499955, 56.230957031250114],
                            [-61.364697265624926, 56.2160156250001],
                            [-61.30112304687495, 56.04716796874999],
                            [-61.4495117187499, 55.99570312499998],
                            [-61.08935546874997, 55.86635742187511],
                            [-60.74326171874989, 55.94145507812493],
                            [-60.56210937499995, 55.727001953125125],
                            [-60.341015624999926, 55.78466796874997],
                            [-60.40830078124995, 55.649560546874994],
                            [-60.19238281249994, 55.4809082031251],
                            [-60.617138671874955, 55.060205078124994],
                            [-59.75878906249997, 55.3095703125],
                            [-59.68906249999989, 55.19633789062502],
                            [-59.43789062500005, 55.175927734375136],
                            [-59.837792968749994, 54.813964843750114],
                            [-59.25957031249996, 55.19995117187506],
                            [-58.99711914062496, 55.149462890625074],
                            [-58.780175781249994, 54.838378906250114],
                            [-58.39814453124998, 54.77412109374998],
                            [-57.96245117187493, 54.875732421875085],
                            [-57.40449218750004, 54.59086914062496],
                            [-57.69926757812496, 54.38657226562506],
                            [-58.435205078124966, 54.228125],
                            [-58.63320312499999, 54.04956054687497],
                            [-59.8230468749999, 53.83442382812504],
                            [-60.14492187499994, 53.59614257812498],
                            [-60.395410156249994, 53.653320312500085],
                            [-60.1002929687499, 53.48696289062511],
                            [-60.329492187499966, 53.26611328125006],
                            [-58.652050781249926, 53.97788085937495],
                            [-57.935986328124955, 54.09116210937492],
                            [-58.31748046874989, 54.11445312500007],
                            [-58.192089843749926, 54.228173828125136],
                            [-57.4160644531249, 54.162744140625136],
                            [-57.134960937499926, 53.79184570312506],
                            [-57.524072265624966, 53.61142578125006],
                            [-57.331738281249955, 53.469091796875034],
                            [-56.84086914062496, 53.73945312500004],
                            [-56.46499023437505, 53.76503906250011],
                            [-55.96611328125002, 53.4711425781251],
                            [-55.79794921874995, 53.211962890625045],
                            [-55.80283203124989, 52.64316406249998],
                            [-56.324902343749926, 52.54453124999998],
                            [-55.74648437499994, 52.4745605468751],
                            [-55.7771484374999, 52.3642578125],
                            [-56.01171874999997, 52.394482421875125],
                            [-55.695214843749994, 52.13779296875006],
                            [-56.97597656250005, 51.45766601562505],
                            [-58.510351562500006, 51.295068359375136],
                            [-59.88632812499992, 50.316406250000085],
                            [-61.72485351562503, 50.10405273437499],
                            [-61.91953124999989, 50.2328613281251],
                            [-62.71542968749995, 50.30166015625008],
                            [-66.49550781249991, 50.2118652343751],
                            [-66.94116210937503, 49.993701171875045],
                            [-67.37202148437495, 49.348437500000045],
                            [-68.28193359374998, 49.197167968750136],
                            [-69.67387695312496, 48.19916992187504],
                            [-71.01826171874993, 48.455615234375045],
                            [-69.86552734374993, 48.17226562500005],
                            [-69.775, 48.09809570312504],
                            [-69.9944335937499, 47.73989257812508],
                            [-70.70585937499996, 47.13979492187505],
                            [-71.26777343749995, 46.79594726562499],
                            [-71.87958984374998, 46.68681640624996],
                            [-72.98100585937493, 46.209716796875085],
                            [-73.4766113281249, 45.738232421874955],
                            [-74.03784179687494, 45.501855468750136],
                            [-74.31508789062494, 45.531054687500045],
                            [-73.97382812499995, 45.345117187499994],
                            [-74.70888671874997, 45.0038574218751],
                        ],
                    ],
                    [
                        [
                            [-96.78232421874998, 72.93662109375],
                            [-97.0927734375, 72.99692382812503],
                            [-96.86240234374995, 73.18881835937506],
                            [-96.78232421874998, 72.93662109375],
                        ],
                    ],
                    [
                        [
                            [-114.52153320312502, 72.592919921875],
                            [-113.57807617187501, 72.65209960937506],
                            [-113.2923828125, 72.94980468750003],
                            [-112.75361328125001, 72.98603515624995],
                            [-111.26972656249994, 72.71372070312498],
                            [-111.895166015625, 72.35610351562497],
                            [-111.67509765625002, 72.30014648437503],
                            [-110.20512695312495, 72.66127929687497],
                            [-110.66083984374998, 73.00820312500002],
                            [-110.00844726562494, 72.983642578125],
                            [-108.75498046875002, 72.55107421874999],
                            [-108.18823242187501, 71.72377929687502],
                            [-107.812841796875, 71.62617187500004],
                            [-107.30600585937496, 71.89467773437502],
                            [-108.23740234374999, 73.14990234375003],
                            [-108.029052734375, 73.34873046875003],
                            [-106.48212890624998, 73.19619140624997],
                            [-105.41513671874995, 72.788330078125],
                            [-104.38593749999997, 71.57695312500005],
                            [-104.51479492187502, 71.06425781250005],
                            [-103.58457031249995, 70.63085937500003],
                            [-103.07719726562497, 70.50883789062505],
                            [-103.04956054687503, 70.65507812499999],
                            [-101.67631835937495, 70.27827148437495],
                            [-101.56240234375001, 70.135009765625],
                            [-101.04267578125, 70.11079101562504],
                            [-100.98237304687497, 69.67988281250001],
                            [-101.483837890625, 69.85019531250006],
                            [-101.64765624999997, 69.69853515625007],
                            [-102.18212890624997, 69.845947265625],
                            [-102.59589843749997, 69.71791992187502],
                            [-102.62109374999996, 69.55151367187506],
                            [-103.464892578125, 69.64448242187498],
                            [-103.04892578124999, 69.47177734375006],
                            [-103.12021484374995, 69.20458984374997],
                            [-102.44677734374997, 69.476318359375],
                            [-102.04594726562493, 69.46484374999997],
                            [-101.85712890625001, 69.02397460937505],
                            [-102.89506835937499, 68.8236328125],
                            [-104.57143554687501, 68.87211914062502],
                            [-105.105859375, 68.92041015625],
                            [-105.019580078125, 69.08125],
                            [-106.27016601562497, 69.19458007812497],
                            [-106.65908203124997, 69.439599609375],
                            [-107.43989257812497, 69.00214843749995],
                            [-108.36499023437497, 68.93476562499998],
                            [-109.47211914062501, 68.67670898437498],
                            [-113.12773437500002, 68.49414062500003],
                            [-113.61684570312501, 68.8384765625],
                            [-113.69414062499995, 69.19501953124998],
                            [-115.61811523437495, 69.28295898437506],
                            [-116.51347656249993, 69.42460937500005],
                            [-117.19541015624995, 70.05405273437503],
                            [-114.59233398437497, 70.31245117187498],
                            [-112.63789062499997, 70.225244140625],
                            [-111.63256835937497, 70.30883789062497],
                            [-113.75727539062503, 70.69072265625005],
                            [-115.99091796874997, 70.586279296875],
                            [-117.58706054687498, 70.62954101562502],
                            [-118.2640625, 70.888330078125],
                            [-118.26909179687493, 71.03471679687505],
                            [-115.30341796874997, 71.49370117187505],
                            [-117.93564453125003, 71.39208984375003],
                            [-118.22646484374995, 71.46708984375005],
                            [-117.742333984375, 71.65932617187502],
                            [-118.58300781250003, 71.64902343749998],
                            [-118.98769531249997, 71.7642578125],
                            [-118.94462890624997, 71.98554687499995],
                            [-118.21347656249998, 72.26289062499998],
                            [-118.481298828125, 72.42768554687498],
                            [-118.13310546874995, 72.63281250000003],
                            [-114.63823242187499, 73.37265625000003],
                            [-114.20639648437495, 73.29780273437495],
                            [-114.05170898437497, 73.07099609375004],
                            [-114.52153320312502, 72.592919921875],
                        ],
                    ],
                    [
                        [
                            [-105.28891601562499, 72.919921875],
                            [-106.92153320312497, 73.479833984375],
                            [-106.61396484375001, 73.69560546875002],
                            [-105.31796874999995, 73.76713867187502],
                            [-104.5875, 73.57807617187495],
                            [-104.62172851562495, 73.3111328125],
                            [-105.28891601562499, 72.919921875],
                        ],
                    ],
                    [
                        [
                            [-79.53730468749998, 73.65449218749998],
                            [-78.2865234375, 73.66582031250007],
                            [-77.20654296874997, 73.49956054687505],
                            [-76.18339843749999, 72.84306640625005],
                            [-77.83593750000003, 72.89682617187498],
                            [-79.3193359375, 72.75771484375],
                            [-79.820703125, 72.82631835937502],
                            [-80.18330078124995, 73.22465820312499],
                            [-80.77641601562502, 73.33417968750001],
                            [-80.84887695312503, 73.72124023437499],
                            [-79.53730468749998, 73.65449218749998],
                        ],
                    ],
                    [
                        [
                            [-86.58935546874997, 71.01079101562507],
                            [-85.64384765624999, 71.15244140624998],
                            [-85.09487304687497, 71.15195312500006],
                            [-84.82373046874997, 71.02861328125005],
                            [-84.69941406249995, 71.63144531250003],
                            [-85.33906249999998, 71.69726562500003],
                            [-85.91162109375, 71.98652343749998],
                            [-85.321875, 72.23315429687506],
                            [-84.28374023437499, 72.04448242187499],
                            [-84.84199218749995, 72.30815429687505],
                            [-84.62304687500003, 72.37656250000003],
                            [-85.34111328124993, 72.42153320312497],
                            [-85.64990234374997, 72.72216796875003],
                            [-85.26210937500002, 72.95400390625],
                            [-84.25664062499999, 72.79672851562503],
                            [-85.454736328125, 73.10546875000003],
                            [-84.41606445312496, 73.45649414062495],
                            [-83.781884765625, 73.41689453125],
                            [-83.72983398437495, 73.57587890624995],
                            [-81.946142578125, 73.72983398437506],
                            [-81.40615234374997, 73.634521484375],
                            [-80.27724609375, 72.77016601562502],
                            [-81.229345703125, 72.31171874999998],
                            [-80.61147460937497, 72.450830078125],
                            [-80.925146484375, 71.90766601562501],
                            [-80.18193359374996, 72.20878906250007],
                            [-79.884375, 72.17719726562501],
                            [-80.10893554687499, 72.33217773437497],
                            [-79.83129882812503, 72.44628906250003],
                            [-79.000244140625, 72.27202148437507],
                            [-79.00781250000003, 72.04291992187501],
                            [-78.58510742187497, 71.880615234375],
                            [-78.86274414062495, 72.100830078125],
                            [-78.69926757812496, 72.35141601562498],
                            [-77.51650390624997, 72.17778320312505],
                            [-78.48427734374994, 72.47060546875002],
                            [-77.75322265624996, 72.72475585937502],
                            [-75.70429687499998, 72.57153320312497],
                            [-75.05268554687493, 72.22636718749999],
                            [-75.92280273437501, 71.71723632812501],
                            [-74.90317382812503, 72.10048828125002],
                            [-74.20932617187498, 71.978662109375],
                            [-74.31572265624999, 71.84267578125],
                            [-75.20478515625001, 71.70913085937497],
                            [-74.70078125, 71.67558593750005],
                            [-74.99619140624998, 71.21811523437503],
                            [-74.48808593750002, 71.64838867187501],
                            [-73.8140625, 71.77143554687495],
                            [-74.197265625, 71.404150390625],
                            [-73.71284179687498, 71.58759765624998],
                            [-73.18061523437501, 71.282861328125],
                            [-73.27822265625, 71.53798828125],
                            [-72.901953125, 71.67778320312507],
                            [-71.64067382812499, 71.51625976562502],
                            [-71.22939453124997, 71.33876953125],
                            [-71.49501953124997, 71.10512695312502],
                            [-71.93793945312498, 71.09428710937502],
                            [-72.63271484374994, 70.83076171874998],
                            [-71.74252929687495, 71.046875],
                            [-71.370849609375, 70.97514648437499],
                            [-70.82607421874994, 71.10874023437503],
                            [-70.67265625, 71.05219726562498],
                            [-70.76171874999997, 70.79223632812503],
                            [-71.89018554687502, 70.43154296875002],
                            [-71.27587890625, 70.50029296874999],
                            [-71.42944335937503, 70.12778320312503],
                            [-70.97978515624999, 70.5810546875],
                            [-69.94980468750003, 70.84501953125005],
                            [-68.49575195312502, 70.61025390625],
                            [-68.363525390625, 70.48125],
                            [-70.05771484375, 70.042626953125],
                            [-68.77822265625, 70.20356445312501],
                            [-69.00830078124997, 69.97895507812501],
                            [-68.74404296874997, 69.94140625],
                            [-68.05908203124997, 70.317236328125],
                            [-67.36367187499994, 70.03442382812503],
                            [-67.22163085937495, 69.73071289062506],
                            [-68.02041015625, 69.77006835937499],
                            [-69.25078124999999, 69.51191406249998],
                            [-68.51303710937498, 69.57729492187497],
                            [-67.236962890625, 69.460107421875],
                            [-66.71674804687495, 69.31186523437498],
                            [-66.70742187500002, 69.16821289062503],
                            [-68.40629882812499, 69.23222656250002],
                            [-69.040625, 69.09799804687503],
                            [-68.41552734375, 69.17207031250001],
                            [-67.8326171875, 69.06596679687499],
                            [-67.88320312500002, 68.78398437499999],
                            [-69.31909179687497, 68.85698242187505],
                            [-68.21040039062495, 68.702978515625],
                            [-67.9384765625, 68.524169921875],
                            [-66.74272460937502, 68.45776367187497],
                            [-67.032958984375, 68.32607421874997],
                            [-66.923095703125, 68.06572265625005],
                            [-66.72900390624997, 68.12900390625006],
                            [-66.66269531249995, 68.03442382812497],
                            [-66.63095703124998, 68.21064453124998],
                            [-66.21240234374997, 68.280419921875],
                            [-66.44394531249998, 67.83383789062506],
                            [-65.94238281250003, 68.07094726562505],
                            [-65.86435546875003, 67.92285156249997],
                            [-65.50908203124996, 67.96826171875],
                            [-65.40126953125002, 67.67485351562499],
                            [-65.41533203124996, 67.87924804687498],
                            [-64.92231445312495, 68.03164062500002],
                            [-65.02109375, 67.78754882812495],
                            [-64.63779296875, 67.84023437500002],
                            [-63.850195312500034, 67.56606445312502],
                            [-64.00795898437502, 67.34731445312497],
                            [-64.69995117187494, 67.35053710937501],
                            [-63.83623046874993, 67.26411132812498],
                            [-63.59160156250002, 67.3775390625],
                            [-63.040136718750034, 67.235009765625],
                            [-63.70156249999994, 66.82236328125003],
                            [-62.962304687499966, 66.94926757812505],
                            [-62.37973632812495, 66.90537109375],
                            [-62.12358398437499, 67.046728515625],
                            [-61.35341796874994, 66.689208984375],
                            [-61.52783203124994, 66.55810546875003],
                            [-62.12333984374993, 66.64306640625003],
                            [-61.57080078125, 66.37290039062506],
                            [-61.95634765624993, 66.30932617187497],
                            [-62.553125, 66.40683593750003],
                            [-62.53359374999994, 66.22700195312498],
                            [-61.99160156250002, 66.03530273437502],
                            [-62.624121093750006, 66.01625976562505],
                            [-62.381982421874966, 65.83330078124999],
                            [-62.65888671874998, 65.63994140625002],
                            [-63.16894531249997, 65.65732421875],
                            [-63.45874023437494, 65.85302734375],
                            [-63.42089843749997, 65.70859374999998],
                            [-63.651074218749955, 65.66098632812506],
                            [-63.33745117187493, 65.61674804687502],
                            [-63.36337890624998, 65.22973632812503],
                            [-63.606591796874966, 64.92807617187503],
                            [-64.345703125, 65.17241210937499],
                            [-64.26967773437497, 65.40078124999997],
                            [-64.55507812500002, 65.1166015625],
                            [-65.401611328125, 65.764013671875],
                            [-64.44536132812496, 66.31713867187497],
                            [-65.0044921875, 66.07773437500003],
                            [-65.82573242187499, 65.996923828125],
                            [-65.65634765625003, 66.204736328125],
                            [-66.06372070312497, 66.13271484374997],
                            [-66.986328125, 66.62749023437505],
                            [-67.07685546874995, 66.52548828125006],
                            [-67.30732421874993, 66.5697265625],
                            [-67.22539062499993, 66.31025390624998],
                            [-67.88339843749995, 66.46743164062502],
                            [-67.18320312499995, 66.03442382812503],
                            [-67.350439453125, 65.92973632812502],
                            [-67.82802734374997, 65.96518554687503],
                            [-68.45991210937498, 66.249267578125],
                            [-68.74892578125, 66.200048828125],
                            [-68.21718750000002, 66.078857421875],
                            [-68.18671874999993, 65.87099609375002],
                            [-67.86645507812497, 65.773681640625],
                            [-67.936767578125, 65.56489257812501],
                            [-67.56962890624999, 65.64355468749997],
                            [-67.11796874999999, 65.44038085937495],
                            [-67.3365234375, 65.34658203125005],
                            [-66.69741210937502, 64.81518554687506],
                            [-66.63549804687503, 65.00034179687503],
                            [-66.21464843749999, 64.72241210937497],
                            [-65.93852539062496, 64.88574218750003],
                            [-65.2748046875, 64.63154296875004],
                            [-65.52934570312499, 64.50478515624997],
                            [-65.074609375, 64.43666992187502],
                            [-65.21298828125003, 64.30327148437502],
                            [-65.580322265625, 64.29384765624997],
                            [-65.16987304687495, 64.02817382812503],
                            [-64.67846679687503, 64.027978515625],
                            [-64.79814453124999, 63.91596679687498],
                            [-64.4109375, 63.70634765625002],
                            [-64.66464843749995, 63.24536132812497],
                            [-65.19184570312498, 63.764257812500006],
                            [-65.06894531249998, 63.26347656250002],
                            [-64.67236328125003, 62.921972656250006],
                            [-65.16279296875001, 62.93261718750003],
                            [-65.10849609374998, 62.62646484375],
                            [-66.22402343749994, 63.10717773437497],
                            [-66.228662109375, 62.99096679687503],
                            [-66.41445312500002, 63.027197265625034],
                            [-66.65498046874998, 63.264746093750006],
                            [-66.69746093749993, 63.069531249999955],
                            [-67.89326171874993, 63.733740234375006],
                            [-67.72255859374997, 63.422753906249966],
                            [-68.49375, 63.725488281249994],
                            [-68.91108398437498, 63.703222656250006],
                            [-68.141259765625, 63.17231445312501],
                            [-67.67597656249998, 63.093554687500045],
                            [-67.73696289062497, 63.00957031249999],
                            [-65.98017578125001, 62.20888671875002],
                            [-66.12387695312498, 61.89306640625],
                            [-68.53588867187503, 62.25561523437506],
                            [-69.12558593749998, 62.423974609374966],
                            [-69.604736328125, 62.76772460937502],
                            [-70.23613281250002, 62.76337890625001],
                            [-70.801416015625, 62.91049804687506],
                            [-71.10576171874999, 63.00224609375002],
                            [-70.94604492187497, 63.12070312499998],
                            [-71.34726562499998, 63.066113281249955],
                            [-71.99223632812493, 63.41616210937505],
                            [-71.380859375, 63.580322265625],
                            [-72.29013671874995, 63.72797851562498],
                            [-72.17426757812498, 63.893408203125006],
                            [-72.49843749999994, 63.82348632812497],
                            [-73.45454101562495, 64.39926757812503],
                            [-73.27128906250002, 64.58251953125],
                            [-73.91035156249998, 64.578125],
                            [-74.064794921875, 64.42465820312498],
                            [-74.13046874999998, 64.6078125],
                            [-74.46123046874996, 64.64467773437505],
                            [-74.68139648437497, 64.8306640625],
                            [-74.91943359374997, 64.76552734374997],
                            [-74.69472656250002, 64.49658203124997],
                            [-75.71503906249995, 64.52436523437495],
                            [-75.76669921875, 64.39194335937498],
                            [-76.85615234374998, 64.23764648437498],
                            [-77.76049804687503, 64.36015624999999],
                            [-78.04521484374993, 64.499267578125],
                            [-78.09560546875, 64.93925781250002],
                            [-77.36088867187496, 65.19653320312503],
                            [-77.32670898437493, 65.453125],
                            [-75.82832031249993, 65.22705078125003],
                            [-75.45209960937495, 64.84160156250002],
                            [-75.35712890624995, 65.00874023437495],
                            [-75.79868164062503, 65.297509765625],
                            [-75.16630859374999, 65.28393554687497],
                            [-74.13847656250002, 65.50346679687502],
                            [-73.55078125000003, 65.48525390625005],
                            [-74.41640624999997, 66.16708984375003],
                            [-73.03325195312502, 66.72817382812505],
                            [-72.78881835937494, 67.030615234375],
                            [-72.22001953124999, 67.25429687500002],
                            [-73.28447265624993, 68.35698242187505],
                            [-73.82050781249998, 68.36293945312502],
                            [-73.82211914062495, 68.68598632812501],
                            [-74.11796875000002, 68.70092773437506],
                            [-73.9892578125, 68.54863281250002],
                            [-74.2701171875, 68.54121093750001],
                            [-74.89296875, 68.80815429687505],
                            [-74.71669921874997, 69.04550781249998],
                            [-76.58505859375, 68.69873046875003],
                            [-76.55722656250003, 69.00947265625001],
                            [-75.9537109375, 69.03081054687502],
                            [-75.64775390625002, 69.212548828125],
                            [-76.46494140624995, 69.46943359375001],
                            [-76.23408203125001, 69.66210937500003],
                            [-76.742333984375, 69.57290039062497],
                            [-77.08994140625, 69.63510742187503],
                            [-76.85859374999995, 69.775390625],
                            [-77.591650390625, 69.84560546875002],
                            [-77.77402343750003, 70.23852539062503],
                            [-78.28281250000003, 70.229150390625],
                            [-79.06640624999997, 70.60356445312507],
                            [-79.40522460937498, 70.40073242187503],
                            [-78.86284179687499, 70.24189453125001],
                            [-78.88964843750003, 69.97749023437495],
                            [-79.51542968749996, 69.88759765625005],
                            [-81.65195312500003, 70.09462890625002],
                            [-80.92172851562503, 69.73090820312501],
                            [-81.56469726562503, 69.94272460937498],
                            [-82.29384765624997, 69.83691406250003],
                            [-83.14995117187493, 70.00908203125002],
                            [-83.85908203124998, 69.96274414062498],
                            [-85.43237304687497, 70.11137695312507],
                            [-85.780029296875, 70.03666992187505],
                            [-86.32202148437503, 70.14541015625],
                            [-86.396875, 70.46533203124997],
                            [-87.838134765625, 70.24658203125],
                            [-88.78271484374997, 70.49448242187503],
                            [-89.45590820312498, 71.06171874999995],
                            [-87.84492187499995, 70.94438476562505],
                            [-87.14008789062498, 71.01162109374997],
                            [-89.80537109374993, 71.46230468750005],
                            [-89.86152343750001, 72.41191406250005],
                            [-88.70517578124998, 73.40327148437495],
                            [-87.71977539062496, 73.72290039062497],
                            [-85.95078124999998, 73.85014648437505],
                            [-84.94677734375, 73.72163085937498],
                            [-86.00053710937499, 73.31254882812505],
                            [-86.65629882812502, 72.72402343750005],
                            [-86.21845703124998, 71.89912109375004],
                            [-85.02338867187495, 71.35322265625001],
                            [-86.58935546874997, 71.01079101562507],
                        ],
                    ],
                    [
                        [
                            [-100.00190429687497, 73.9458984375],
                            [-99.15795898437499, 73.73159179687497],
                            [-97.66997070312499, 73.88774414062499],
                            [-97.1705078125, 73.82485351562497],
                            [-97.001708984375, 73.66650390625003],
                            [-97.62587890624997, 73.50229492187498],
                            [-97.27250976562502, 73.38681640624998],
                            [-98.42177734375002, 72.94101562500003],
                            [-97.63632812499998, 73.02763671874999],
                            [-97.128125, 72.62758789062502],
                            [-96.59208984374996, 72.71025390624999],
                            [-96.44560546874996, 72.55244140624998],
                            [-96.80146484374998, 72.32241210937502],
                            [-96.61342773437494, 71.83383789062506],
                            [-97.58227539062497, 71.62968750000005],
                            [-98.18134765624998, 71.66245117187503],
                            [-98.32270507812501, 71.85234375000002],
                            [-98.19863281249994, 71.44086914062501],
                            [-98.66289062499993, 71.302099609375],
                            [-99.22363281249996, 71.387109375],
                            [-100.594482421875, 72.15234375000003],
                            [-101.20854492187495, 72.31699218749998],
                            [-101.72392578124996, 72.31489257812501],
                            [-102.70874023437496, 72.76450195312503],
                            [-102.20400390624998, 73.077294921875],
                            [-101.27319335937497, 72.7216796875],
                            [-100.48476562500002, 72.77294921874997],
                            [-100.395703125, 72.97700195312498],
                            [-100.128125, 72.90668945312495],
                            [-100.53637695312497, 73.19785156250003],
                            [-99.82514648437503, 73.2138671875],
                            [-100.36611328125001, 73.359033203125],
                            [-100.88935546875003, 73.27534179687501],
                            [-101.52319335937501, 73.48637695312502],
                            [-100.97578124999995, 73.59975585937502],
                            [-100.5216796875, 73.44931640625],
                            [-100.96298828125002, 73.79140625],
                            [-99.99111328125, 73.79516601562503],
                            [-100.00190429687497, 73.9458984375],
                        ],
                    ],
                    [
                        [
                            [-98.270361328125, 73.86850585937498],
                            [-98.97392578124997, 73.81206054687502],
                            [-99.4169921875, 73.89541015625002],
                            [-97.69824218749997, 74.10869140625005],
                            [-98.270361328125, 73.86850585937498],
                        ],
                    ],
                    [
                        [
                            [-93.17084960937498, 74.16098632812506],
                            [-92.22270507812502, 73.97236328124998],
                            [-90.62744140625, 73.95170898437505],
                            [-90.38139648437496, 73.82475585937502],
                            [-92.11791992187497, 72.75380859375],
                            [-94.21132812499997, 72.75693359375],
                            [-93.77055664062496, 72.66821289062506],
                            [-93.55517578124994, 72.42114257812497],
                            [-94.03754882812498, 72.02875976562498],
                            [-95.00786132812496, 72.01279296875],
                            [-95.60214843749998, 72.88447265624995],
                            [-95.63291015625003, 73.69545898437497],
                            [-94.697607421875, 73.66357421874997],
                            [-95.134130859375, 73.88125],
                            [-94.97353515625, 74.04140625000002],
                            [-93.17084960937498, 74.16098632812506],
                        ],
                    ],
                    [
                        [
                            [-119.73632812499997, 74.11264648437498],
                            [-119.20595703125002, 74.19799804687503],
                            [-119.11796874999995, 74.01552734375],
                            [-118.54399414062499, 74.24462890625003],
                            [-117.51484375000001, 74.23173828124999],
                            [-115.51069335937501, 73.61875],
                            [-115.446875, 73.43886718750002],
                            [-118.96157226562497, 72.68413085937499],
                            [-119.51284179687501, 72.30268554687501],
                            [-120.17988281250001, 72.21264648437506],
                            [-120.61933593750001, 71.50576171875002],
                            [-121.47216796875003, 71.38901367187503],
                            [-121.74936523437502, 71.44477539062501],
                            [-123.09565429687503, 71.09379882812502],
                            [-124.00776367187494, 71.67744140624998],
                            [-125.29667968749999, 71.973046875],
                            [-125.84531250000002, 71.978662109375],
                            [-123.79726562499997, 73.76816406250003],
                            [-124.69624023437497, 74.34819335937499],
                            [-121.50415039062497, 74.54511718749998],
                            [-119.56264648437494, 74.23281250000002],
                            [-119.73632812499997, 74.11264648437498],
                        ],
                    ],
                    [
                        [
                            [-97.35551757812496, 74.52631835937495],
                            [-97.75, 74.51054687500005],
                            [-97.41650390624994, 74.62656250000003],
                            [-97.35551757812496, 74.52631835937495],
                        ],
                    ],
                    [
                        [
                            [-95.306640625, 74.50541992187505],
                            [-95.850732421875, 74.58247070312504],
                            [-95.51020507812498, 74.63676757812499],
                            [-95.306640625, 74.50541992187505],
                        ],
                    ],
                    [
                        [
                            [-104.11992187499995, 75.03632812500004],
                            [-104.88740234374998, 75.14775390624999],
                            [-104.34619140624996, 75.42993164062503],
                            [-103.64350585937497, 75.18657226562499],
                            [-104.11992187499995, 75.03632812500004],
                        ],
                    ],
                    [
                        [
                            [-93.54257812499995, 75.0279296875],
                            [-93.57309570312495, 74.66884765625005],
                            [-94.53452148437498, 74.63671874999997],
                            [-96.59960937499997, 75.03178710937499],
                            [-95.95463867187493, 75.44379882812501],
                            [-94.878173828125, 75.63002929687502],
                            [-93.90908203125002, 75.42250976562502],
                            [-93.54257812499995, 75.0279296875],
                        ],
                    ],
                    [
                        [
                            [-96.07856445312495, 75.510107421875],
                            [-96.91513671875003, 75.37968749999999],
                            [-96.98281249999997, 75.50981445312505],
                            [-96.367822265625, 75.65463867187506],
                            [-96.07856445312495, 75.510107421875],
                        ],
                    ],
                    [
                        [
                            [-94.52656249999995, 75.74931640624999],
                            [-94.901220703125, 75.93076171875],
                            [-94.53789062499996, 75.99643554687506],
                            [-94.52656249999995, 75.74931640624999],
                        ],
                    ],
                    [
                        [
                            [-118.328125, 75.57968749999998],
                            [-118.81713867187503, 75.52211914062497],
                            [-119.39458007812499, 75.617333984375],
                            [-117.63369140624998, 76.11508789062498],
                            [-118.328125, 75.57968749999998],
                        ],
                    ],
                    [
                        [
                            [-79.0630859375, 75.92587890624998],
                            [-79.63876953124995, 75.84291992187505],
                            [-79.00932617187499, 76.14589843750005],
                            [-79.0630859375, 75.92587890624998],
                        ],
                    ],
                    [
                        [
                            [-102.22734374999995, 76.014892578125],
                            [-102.00800781250003, 75.93940429687498],
                            [-102.57958984375003, 75.78022460937498],
                            [-103.31474609374996, 75.76420898437499],
                            [-103.04150390624999, 75.91884765624997],
                            [-103.98525390624997, 75.93310546875003],
                            [-103.80078124999994, 76.03701171874997],
                            [-104.24248046874996, 76.04697265625006],
                            [-104.35063476562497, 76.18232421875001],
                            [-102.72802734374999, 76.30703125],
                            [-102.22734374999995, 76.014892578125],
                        ],
                    ],
                    [
                        [
                            [-104.02285156249998, 76.58310546875003],
                            [-103.05131835937495, 76.44985351562497],
                            [-103.31137695312499, 76.34755859375],
                            [-104.35751953124995, 76.33461914062502],
                            [-104.58569335937499, 76.60649414062499],
                            [-104.07451171875003, 76.66611328124998],
                            [-104.02285156249998, 76.58310546875003],
                        ],
                    ],
                    [
                        [
                            [-97.70092773437497, 76.46650390624998],
                            [-97.89052734374997, 75.7603515625],
                            [-97.40751953124999, 75.67250976562497],
                            [-97.33603515624998, 75.41982421875],
                            [-97.65332031249997, 75.50776367187498],
                            [-97.87822265624996, 75.41611328125003],
                            [-97.67431640624997, 75.127294921875],
                            [-98.04531249999997, 75.20083007812497],
                            [-98.12094726562503, 75.03271484375],
                            [-100.234375, 75.00771484374997],
                            [-100.48349609374995, 75.18842773437501],
                            [-100.14570312499995, 75.24614257812505],
                            [-100.71191406250003, 75.40634765625],
                            [-99.19458007812499, 75.698388671875],
                            [-102.58740234375001, 75.51367187500003],
                            [-102.79750976562501, 75.59965820312505],
                            [-102.14472656249998, 75.87504882812502],
                            [-100.97280273437498, 75.79843750000003],
                            [-101.414990234375, 75.84584960937502],
                            [-101.87211914062496, 76.08310546875003],
                            [-101.52895507812495, 76.21728515625003],
                            [-102.1046875, 76.33120117187505],
                            [-101.41518554687495, 76.42490234375003],
                            [-99.86547851562499, 75.92421875],
                            [-100.11284179687502, 76.11723632812507],
                            [-99.54106445312497, 76.14628906250005],
                            [-100.41420898437495, 76.242529296875],
                            [-99.97773437500003, 76.31245117187495],
                            [-100.82973632812497, 76.52387695312495],
                            [-99.8140625, 76.6322265625],
                            [-98.89033203125, 76.46557617187497],
                            [-98.71083984374994, 76.69384765625003],
                            [-97.70092773437497, 76.46650390624998],
                        ],
                    ],
                    [
                        [
                            [-101.22612304687497, 76.57934570312497],
                            [-101.61308593749995, 76.60458984375006],
                            [-100.26914062499998, 76.73413085937497],
                            [-101.22612304687497, 76.57934570312497],
                        ],
                    ],
                    [
                        [
                            [-108.29238281250001, 76.05712890625],
                            [-107.72348632812502, 75.99541015625002],
                            [-108.020703125, 75.80478515625],
                            [-107.21621093749997, 75.89155273437501],
                            [-106.91352539062503, 75.67963867187501],
                            [-106.67700195312499, 76.02373046875002],
                            [-105.63266601562493, 75.94536132812505],
                            [-105.51948242187497, 75.63237304687505],
                            [-106.09262695312495, 75.08945312500003],
                            [-107.15341796874996, 74.9271484375],
                            [-108.47475585937495, 74.94721679687501],
                            [-108.83129882812501, 75.06489257812498],
                            [-112.51933593749997, 74.41684570312503],
                            [-113.67158203124997, 74.45302734375005],
                            [-114.31269531250003, 74.71508789062506],
                            [-112.835986328125, 74.9755859375],
                            [-111.67109375, 75.01943359374997],
                            [-111.09345703125001, 75.25629882812498],
                            [-113.71176757812499, 75.06860351562503],
                            [-113.85332031249996, 75.259375],
                            [-113.46708984374996, 75.41611328125003],
                            [-114.01650390624998, 75.43427734375001],
                            [-114.16845703124994, 75.23950195312503],
                            [-114.51381835937497, 75.27548828125],
                            [-114.45175781250002, 75.08789062499997],
                            [-115.02011718749999, 74.97617187500003],
                            [-115.41318359374995, 75.11499023437497],
                            [-115.72885742187496, 74.968115234375],
                            [-116.47607421874996, 75.17177734375],
                            [-117.56523437499997, 75.23334960937504],
                            [-117.25761718750002, 75.45952148437502],
                            [-116.07714843749996, 75.49296874999999],
                            [-115.14184570312501, 75.67851562500005],
                            [-116.42563476562498, 75.58535156249997],
                            [-117.16362304687496, 75.64487304687503],
                            [-116.80214843749995, 75.77158203124998],
                            [-114.99150390625002, 75.896337890625],
                            [-116.66455078124999, 75.95756835937505],
                            [-116.20986328125, 76.19443359374998],
                            [-114.77861328124999, 76.17260742187497],
                            [-115.82216796874997, 76.27001953125003],
                            [-114.99848632812503, 76.4974609375],
                            [-114.19394531249999, 76.45146484375005],
                            [-113.82348632812501, 76.20683593750002],
                            [-112.69760742187496, 76.20170898437505],
                            [-111.05268554687495, 75.54853515625001],
                            [-108.94716796875, 75.54179687499999],
                            [-108.94477539062495, 75.69897460937503],
                            [-109.8705078125, 75.929052734375],
                            [-109.48681640624999, 76.14467773437497],
                            [-110.31445312500001, 76.369384765625],
                            [-109.09824218749996, 76.811865234375],
                            [-108.46699218749997, 76.73759765625007],
                            [-108.29238281250001, 76.05712890625],
                        ],
                    ],
                    [
                        [
                            [-89.72646484374994, 76.50742187499998],
                            [-90.55625, 76.73457031249998],
                            [-90.13632812499995, 76.83696289062505],
                            [-89.69541015625, 76.74116210937498],
                            [-89.72646484374994, 76.50742187499998],
                        ],
                    ],
                    [
                        [
                            [-113.56069335937494, 76.74326171874998],
                            [-114.83525390624999, 76.79467773437497],
                            [-113.89165039062495, 76.89487304687503],
                            [-113.56069335937494, 76.74326171874998],
                        ],
                    ],
                    [
                        [
                            [-94.29497070312493, 76.91245117187498],
                            [-93.23002929687496, 76.77026367187497],
                            [-93.53457031250002, 76.44770507812498],
                            [-92.99536132812494, 76.62041015624999],
                            [-91.305029296875, 76.68076171875003],
                            [-90.54262695312494, 76.495751953125],
                            [-91.41508789062496, 76.45585937500005],
                            [-89.28452148437498, 76.30161132812506],
                            [-89.40659179687498, 76.18916015624998],
                            [-91.40732421874998, 76.22006835937506],
                            [-89.27758789062497, 75.79506835937497],
                            [-89.64604492187499, 75.5650390625],
                            [-88.91669921874998, 75.45395507812503],
                            [-88.64497070312495, 75.65844726562503],
                            [-88.201318359375, 75.51201171875005],
                            [-87.72973632812503, 75.57563476562495],
                            [-87.53911132812502, 75.48486328125003],
                            [-87.25693359374998, 75.61772460937499],
                            [-85.95146484374993, 75.39501953125],
                            [-85.97299804687498, 75.5287109375],
                            [-83.931982421875, 75.81894531250003],
                            [-83.23710937499993, 75.75083007812503],
                            [-82.153662109375, 75.83105468750003],
                            [-80.32197265624998, 75.62910156250001],
                            [-79.50908203125002, 75.25981445312499],
                            [-80.38198242187494, 75.03417968750003],
                            [-79.40141601562502, 74.91762695312502],
                            [-79.944482421875, 74.83364257812505],
                            [-80.34775390624998, 74.90297851562505],
                            [-80.26274414062499, 74.58447265625],
                            [-81.94018554687494, 74.47270507812505],
                            [-82.73579101562495, 74.53027343749997],
                            [-83.5220703125, 74.90146484375],
                            [-83.53188476562494, 74.58569335937497],
                            [-84.42553710937503, 74.50810546875007],
                            [-85.06142578125, 74.60693359375003],
                            [-85.133447265625, 74.517431640625],
                            [-85.44233398437495, 74.6005859375],
                            [-85.80800781249994, 74.49897460937498],
                            [-88.42304687499995, 74.49414062499997],
                            [-88.53496093749993, 74.83173828125001],
                            [-89.55869140624995, 74.55473632812507],
                            [-90.55327148437499, 74.61274414062498],
                            [-90.88022460937498, 74.8177734375],
                            [-91.13457031250002, 74.64985351562498],
                            [-91.54912109375002, 74.65556640624999],
                            [-92.3892578125, 75.263330078125],
                            [-92.18510742187499, 75.84653320312498],
                            [-93.09174804687495, 76.35400390624997],
                            [-95.27387695312498, 76.26440429687503],
                            [-96.03969726562494, 76.48671875000002],
                            [-95.65097656249998, 76.58466796874998],
                            [-96.88071289062495, 76.73833007812505],
                            [-96.40156249999995, 76.79721679687503],
                            [-96.75830078124997, 76.97177734374998],
                            [-95.84951171875002, 77.06621093750005],
                            [-94.29497070312493, 76.91245117187498],
                        ],
                    ],
                    [
                        [
                            [-115.55126953125001, 77.36328125],
                            [-116.32919921874996, 77.137060546875],
                            [-115.81005859374999, 76.939111328125],
                            [-116.25273437500002, 76.90141601562505],
                            [-115.94628906250003, 76.71127929687503],
                            [-116.99921874999995, 76.531591796875],
                            [-117.23359375000001, 76.28154296875005],
                            [-117.99296874999999, 76.40581054687505],
                            [-117.88081054687497, 76.80507812500005],
                            [-118.79140624999994, 76.51298828125005],
                            [-119.080712890625, 76.12407226562505],
                            [-119.58037109375, 76.32651367187498],
                            [-119.52612304687496, 75.99721679687505],
                            [-119.91289062499997, 75.85883789062501],
                            [-120.40888671874995, 75.82563476562498],
                            [-120.84838867187496, 76.18266601562499],
                            [-121.21347656249999, 75.98369140625005],
                            [-122.53305664062498, 75.95092773437503],
                            [-122.59272460937497, 76.16206054687495],
                            [-122.90278320312498, 76.13471679687498],
                            [-122.51938476562503, 76.353173828125],
                            [-121.56113281250003, 76.453466796875],
                            [-119.09018554687496, 77.30507812500002],
                            [-116.84355468749995, 77.33955078124995],
                            [-117.03974609374995, 77.46513671875005],
                            [-116.51132812500003, 77.54760742187497],
                            [-115.55126953125001, 77.36328125],
                        ],
                    ],
                    [
                        [
                            [-89.83325195312503, 77.26762695312505],
                            [-90.22827148437503, 77.21245117187499],
                            [-90.99321289062499, 77.32949218750002],
                            [-91.01904296875003, 77.64389648437503],
                            [-89.83896484375003, 77.49140624999998],
                            [-89.83325195312503, 77.26762695312505],
                        ],
                    ],
                    [
                        [
                            [-104.55815429687497, 77.14174804687497],
                            [-105.21508789062496, 77.18208007812501],
                            [-106.03559570312495, 77.73984375000006],
                            [-105.58789062499997, 77.73598632812497],
                            [-104.54223632812501, 77.33774414062503],
                            [-104.55815429687497, 77.14174804687497],
                        ],
                    ],
                    [
                        [
                            [-95.484375, 77.79199218750003],
                            [-93.30097656249995, 77.73979492187505],
                            [-93.54394531249997, 77.466650390625],
                            [-95.98706054687497, 77.484130859375],
                            [-96.19458007812497, 77.70053710937503],
                            [-95.484375, 77.79199218750003],
                        ],
                    ],
                    [
                        [
                            [-101.6935546875, 77.69658203125005],
                            [-102.37783203124995, 77.728125],
                            [-102.44770507812498, 77.88061523437506],
                            [-101.19321289062493, 77.82978515624998],
                            [-101.00205078124998, 77.73510742187497],
                            [-101.6935546875, 77.69658203125005],
                        ],
                    ],
                    [
                        [
                            [-113.83247070312497, 77.75463867187506],
                            [-114.28720703124998, 77.72148437500005],
                            [-114.98041992187498, 77.91542968750002],
                            [-114.33037109374997, 78.07753906250002],
                            [-113.83247070312497, 77.75463867187506],
                        ],
                    ],
                    [
                        [
                            [-110.45805664062496, 78.10322265625001],
                            [-109.62226562499995, 78.07475585937499],
                            [-110.865625, 77.834130859375],
                            [-110.15273437500002, 77.76293945312506],
                            [-110.19848632812501, 77.52451171874998],
                            [-112.37265625000002, 77.36411132812498],
                            [-113.16435546875002, 77.5302734375],
                            [-113.21518554687498, 77.90351562500001],
                            [-110.45805664062496, 78.10322265625001],
                        ],
                    ],
                    [
                        [
                            [-109.81596679687499, 78.65039062500003],
                            [-109.48447265624995, 78.31640625],
                            [-111.16918945312499, 78.38627929687505],
                            [-111.51748046874997, 78.27470703125005],
                            [-112.13125, 78.366064453125],
                            [-113.22304687499998, 78.29790039062505],
                            [-112.85585937499997, 78.46684570312502],
                            [-110.877587890625, 78.73505859375004],
                            [-109.81596679687499, 78.65039062500003],
                        ],
                    ],
                    [
                        [
                            [-96.20449218749994, 78.53129882812499],
                            [-94.91538085937495, 78.39052734375002],
                            [-95.32924804687497, 78.22504882812495],
                            [-94.93427734374998, 78.07563476562498],
                            [-96.98964843749994, 77.80600585937503],
                            [-97.65815429687498, 78.090625],
                            [-96.944677734375, 78.15185546874997],
                            [-98.04951171874995, 78.325927734375],
                            [-98.33261718749998, 78.77353515625006],
                            [-97.38232421875, 78.78291015625001],
                            [-96.20449218749994, 78.53129882812499],
                        ],
                    ],
                    [
                        [
                            [-103.42602539062499, 79.315625],
                            [-102.57617187499996, 78.87939453125003],
                            [-101.70366210937502, 79.07890625000002],
                            [-101.128125, 78.80166015625002],
                            [-100.43549804687503, 78.8203125],
                            [-99.60942382812495, 78.58305664062507],
                            [-99.16640625000002, 77.85693359375003],
                            [-100.27465820312503, 77.83271484374995],
                            [-101.07412109375001, 78.19384765625],
                            [-102.60698242187502, 78.24892578125002],
                            [-102.73134765624995, 78.37104492187495],
                            [-103.94658203124999, 78.26000976562497],
                            [-104.76357421874998, 78.35166015625],
                            [-104.90961914062498, 78.55263671875],
                            [-103.57050781250003, 78.53984375000005],
                            [-104.02084960937502, 78.63491210937497],
                            [-103.37158203125, 78.73632812500003],
                            [-104.18500976562498, 78.78129882812505],
                            [-104.15195312499999, 78.989892578125],
                            [-104.89550781249996, 78.80815429687502],
                            [-104.74677734375003, 79.02709960937503],
                            [-105.53564453124999, 79.03251953125007],
                            [-105.51455078124995, 79.24248046875002],
                            [-105.38769531249994, 79.32358398437503],
                            [-103.42602539062499, 79.315625],
                        ],
                    ],
                    [
                        [
                            [-98.79160156249995, 79.98110351562505],
                            [-98.94521484375, 79.72407226562498],
                            [-100.05683593749997, 79.89824218750005],
                            [-100.05327148437496, 80.093359375],
                            [-99.15322265625001, 80.12421874999998],
                            [-98.79160156249995, 79.98110351562505],
                        ],
                    ],
                    [
                        [
                            [-91.88554687499999, 81.13286132812505],
                            [-90.64301757812498, 80.59370117187498],
                            [-89.23559570312494, 80.51064453125002],
                            [-88.85732421874997, 80.16621093750001],
                            [-88.19990234374998, 80.11147460937497],
                            [-88.5248046875, 80.41801757812507],
                            [-87.675, 80.37211914062505],
                            [-87.92231445312501, 80.09770507812499],
                            [-86.97719726562502, 79.89423828125001],
                            [-87.29516601562494, 79.58017578124998],
                            [-86.33696289062496, 79.63496093749995],
                            [-86.00703124999998, 79.47944335937498],
                            [-85.6478515625, 79.61142578125006],
                            [-85.04213867187497, 79.2845703125],
                            [-86.95717773437502, 78.97490234375005],
                            [-87.61738281249995, 78.67631835937505],
                            [-88.04018554687494, 78.99531250000004],
                            [-87.98286132812498, 78.53706054687501],
                            [-88.74160156250002, 78.58403320312499],
                            [-88.82241210937497, 78.18588867187498],
                            [-90.037109375, 78.60683593750002],
                            [-89.52568359374999, 78.15961914062495],
                            [-90.29721679687495, 78.32802734374997],
                            [-90.614404296875, 78.14985351562501],
                            [-92.35126953125001, 78.312890625],
                            [-92.8482421875, 78.46010742187497],
                            [-91.86689453124998, 78.54267578125001],
                            [-93.26660156249997, 78.60830078124997],
                            [-93.63442382812502, 78.75092773437498],
                            [-93.15986328124998, 78.77563476562503],
                            [-94.11459960937498, 78.92890625000001],
                            [-92.54721679687495, 79.28261718750002],
                            [-91.29990234375003, 79.372705078125],
                            [-92.82192382812497, 79.44990234375001],
                            [-93.93315429687496, 79.29072265624998],
                            [-94.11030273437498, 79.40156250000001],
                            [-95.10317382812502, 79.289892578125],
                            [-95.66289062500002, 79.52734374999997],
                            [-94.40185546874997, 79.736328125],
                            [-95.73935546874995, 79.66015625000003],
                            [-96.58906249999995, 79.91665039062497],
                            [-96.77324218749999, 80.13579101562502],
                            [-94.64589843749994, 80.04873046874997],
                            [-94.26259765625002, 80.19487304687499],
                            [-95.40507812499996, 80.13500976562506],
                            [-96.39409179687493, 80.31503906250003],
                            [-95.549072265625, 80.36660156249997],
                            [-95.92695312499998, 80.72065429687498],
                            [-93.92792968749995, 80.55917968750003],
                            [-95.51474609375003, 80.83813476562503],
                            [-94.98051757812499, 81.04965820312503],
                            [-93.28671874999998, 81.10029296874998],
                            [-94.22011718749997, 81.33076171875004],
                            [-93.03466796874997, 81.3462890625],
                            [-91.88554687499999, 81.13286132812505],
                        ],
                    ],
                    [
                        [
                            [-69.4888671875, 83.01679687499998],
                            [-66.42255859374998, 82.92685546875003],
                            [-68.46933593749995, 82.65336914062502],
                            [-65.29902343749995, 82.79960937500005],
                            [-64.98388671874997, 82.90229492187501],
                            [-64.50400390625, 82.77841796874998],
                            [-63.641015624999966, 82.81259765625003],
                            [-63.246777343749926, 82.4501953125],
                            [-62.47519531249995, 82.51958007812502],
                            [-61.392480468749994, 82.44189453125],
                            [-61.61538085937502, 82.18442382812503],
                            [-64.43579101562497, 81.74262695312501],
                            [-66.62573242187497, 81.61640624999995],
                            [-68.68852539062493, 81.29331054687503],
                            [-64.78007812499993, 81.49287109375001],
                            [-69.55068359375, 80.38325195312498],
                            [-70.71259765625001, 80.53959960937505],
                            [-70.264892578125, 80.23359374999998],
                            [-72.05595703124996, 80.12324218749995],
                            [-70.56840820312493, 80.09370117187498],
                            [-71.387841796875, 79.76176757812505],
                            [-72.43652343750003, 79.69438476562499],
                            [-74.39448242187495, 79.87407226562499],
                            [-73.47246093749996, 79.7564453125],
                            [-73.36152343750001, 79.50400390625],
                            [-75.50341796875, 79.41416015625],
                            [-76.898828125, 79.5123046875],
                            [-75.60273437499998, 79.23955078125005],
                            [-74.48120117187503, 79.22949218750006],
                            [-74.64091796874996, 79.03554687499997],
                            [-78.58164062499998, 79.075],
                            [-77.88276367187498, 78.9423828125],
                            [-76.255859375, 79.00683593749997],
                            [-74.486328125, 78.75009765624998],
                            [-74.87861328124998, 78.54482421875],
                            [-76.41611328124995, 78.51152343750005],
                            [-75.19345703125, 78.327734375],
                            [-75.86596679687497, 78.00981445312499],
                            [-78.01259765624997, 77.94604492187506],
                            [-78.07617187500003, 77.51904296875],
                            [-78.70849609374997, 77.34213867187503],
                            [-80.57304687499996, 77.31479492187506],
                            [-81.65908203124997, 77.52543945312499],
                            [-81.3013671875, 77.34404296875007],
                            [-82.056787109375, 77.29653320312497],
                            [-81.75634765624997, 77.20400390625005],
                            [-79.49726562500001, 77.19609375000005],
                            [-78.97919921874998, 76.89287109374999],
                            [-78.28886718750002, 76.97797851562501],
                            [-77.98330078124994, 76.75498046875006],
                            [-78.284326171875, 76.57124023437501],
                            [-80.79970703124997, 76.173583984375],
                            [-80.97451171874994, 76.470068359375],
                            [-81.71738281250003, 76.494970703125],
                            [-82.52983398437499, 76.723291015625],
                            [-82.23315429687494, 76.46582031250003],
                            [-83.88569335937501, 76.453125],
                            [-84.22377929687497, 76.67534179687499],
                            [-84.27534179687498, 76.35654296875006],
                            [-85.141259765625, 76.30458984375005],
                            [-86.45371093750003, 76.58486328125002],
                            [-86.68022460937499, 76.37661132812497],
                            [-87.35419921874998, 76.44804687500005],
                            [-87.48979492187499, 76.58583984374997],
                            [-87.49755859374997, 76.38627929687499],
                            [-88.39599609374997, 76.40527343750003],
                            [-88.49584960937497, 76.77285156249997],
                            [-88.54580078125002, 76.42089843750003],
                            [-89.36962890624997, 76.474462890625],
                            [-89.49975585937503, 76.82680664062502],
                            [-88.39814453124995, 77.10395507812501],
                            [-86.81225585937497, 77.18491210937498],
                            [-87.68144531249996, 77.43637695312503],
                            [-88.01699218750002, 77.78471679687505],
                            [-86.75507812499998, 77.86372070312498],
                            [-85.58847656249998, 77.46113281250004],
                            [-84.73867187499997, 77.36103515624998],
                            [-83.72128906249998, 77.41420898437497],
                            [-82.7103515625, 77.84951171875002],
                            [-82.5953125, 77.99213867187504],
                            [-83.77939453125, 77.53261718750002],
                            [-85.28935546874996, 77.55903320312498],
                            [-85.54755859374998, 77.92768554687495],
                            [-84.61542968749998, 78.19570312500002],
                            [-84.22270507812499, 78.176025390625],
                            [-84.91035156249993, 78.23969726562501],
                            [-84.78320312499997, 78.52758789062506],
                            [-85.5859375, 78.10957031249998],
                            [-86.21777343750003, 78.08120117187497],
                            [-85.92006835937494, 78.34287109374998],
                            [-86.91323242187494, 78.126806640625],
                            [-87.5517578125, 78.17661132812503],
                            [-86.80791015624999, 78.77436523437495],
                            [-85.00375976562495, 78.912255859375],
                            [-83.27143554687501, 78.77031250000002],
                            [-81.75009765624995, 78.97578124999995],
                            [-82.43876953125002, 78.903662109375],
                            [-84.41201171875002, 78.99658203125003],
                            [-84.38359375000002, 79.1185546875],
                            [-83.57587890624995, 79.05366210937501],
                            [-86.42075195312498, 79.84521484374997],
                            [-86.49853515625003, 80.25825195312501],
                            [-83.72363281250003, 80.22895507812501],
                            [-81.68837890625, 79.685791015625],
                            [-80.47592773437498, 79.60625],
                            [-80.12446289062495, 79.66948242187507],
                            [-81.01015625000002, 79.693115234375],
                            [-82.98701171874995, 80.32260742187498],
                            [-76.86298828124995, 80.86479492187505],
                            [-78.71621093749994, 80.95166015624997],
                            [-76.88510742187503, 81.43027343750006],
                            [-81.00703125000001, 80.6548828125],
                            [-82.88432617187502, 80.57753906249997],
                            [-82.22236328124998, 80.77231445312503],
                            [-84.41782226562495, 80.52675781250002],
                            [-86.250341796875, 80.56577148437506],
                            [-86.60307617187499, 80.66401367187498],
                            [-85.63930664062494, 80.92460937500007],
                            [-83.288818359375, 81.14794921875],
                            [-85.780859375, 81.03505859375],
                            [-87.32988281250002, 80.669775390625],
                            [-88.00366210937497, 80.675390625],
                            [-89.16689453125, 80.94130859375],
                            [-86.47675781249993, 81.03574218750006],
                            [-84.94121093750002, 81.28623046875],
                            [-87.27509765624995, 81.080810546875],
                            [-89.623046875, 81.032470703125],
                            [-89.94731445312499, 81.17265625000005],
                            [-89.20869140624998, 81.25009765625003],
                            [-89.67368164062503, 81.32861328125003],
                            [-87.59702148437498, 81.52583007812498],
                            [-88.47905273437502, 81.56464843749998],
                            [-90.41630859374996, 81.40537109375003],
                            [-89.82167968749997, 81.63486328124998],
                            [-91.29238281250002, 81.57124023437498],
                            [-91.64755859374998, 81.68383789062503],
                            [-88.06318359375001, 82.09648437500007],
                            [-87.01821289062502, 81.95874023437497],
                            [-86.62680664062495, 82.05102539062503],
                            [-85.04482421874997, 81.9828125],
                            [-86.615625, 82.21855468750007],
                            [-84.89682617187503, 82.44941406250001],
                            [-82.63369140625002, 82.07729492187497],
                            [-82.53691406250002, 82.24726562499995],
                            [-79.465625, 81.85112304687499],
                            [-82.44755859374993, 82.39501953125003],
                            [-81.68115234375003, 82.51865234375],
                            [-82.11684570312497, 82.62866210937503],
                            [-80.8625, 82.57153320312503],
                            [-81.01015625000002, 82.77905273437503],
                            [-78.748779296875, 82.67939453124998],
                            [-80.15493164062497, 82.91113281250003],
                            [-77.61806640624997, 82.89584960937503],
                            [-76.009375, 82.53515625],
                            [-75.565625, 82.60854492187502],
                            [-77.12490234374994, 83.00854492187497],
                            [-74.41416015624995, 83.01313476562501],
                            [-72.65869140625, 82.72163085937495],
                            [-73.44189453124994, 82.90483398437499],
                            [-72.811669921875, 83.08120117187502],
                            [-71.98320312499996, 83.10141601562498],
                            [-70.94038085937495, 82.90224609375],
                            [-71.08481445312498, 83.08266601562497],
                            [-69.96992187499995, 83.11611328125005],
                            [-69.4888671875, 83.01679687499998],
                        ],
                    ],
                ],
            },
            properties: {
                name: "加拿大",
                childNum: 110,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [9.527658197470123, 47.27026989773668],
                        [9.46249431093294, 47.19858962254578],
                        [9.46249431093294, 47.09010747968864],
                        [9.409458596647225, 47.02019676540292],
                        [9.579979133936737, 47.05856388629306],
                        [9.580273437500011, 47.057373046875],
                        [10.133496093750011, 46.851513671875],
                        [10.349414062500017, 46.98476562499999],
                        [10.414941406250023, 46.964404296874996],
                        [10.45458984375, 46.8994140625],
                        [10.452832031250011, 46.86494140625],
                        [10.406054687500017, 46.73486328125],
                        [10.39794921875, 46.6650390625],
                        [10.4306640625, 46.550048828125],
                        [10.195507812500011, 46.62109375],
                        [10.1375, 46.61435546875],
                        [10.087011718750006, 46.599902343749996],
                        [10.061230468750011, 46.546777343749994],
                        [10.038281250000011, 46.483203125],
                        [10.045605468750011, 46.447900390624994],
                        [10.081933593750023, 46.420751953125],
                        [10.109667968750017, 46.362841796874996],
                        [10.128320312500023, 46.238232421875],
                        [10.08056640625, 46.227978515625],
                        [10.041015625, 46.238085937499996],
                        [9.939257812500017, 46.36181640625],
                        [9.884472656250011, 46.3677734375],
                        [9.787792968750011, 46.346044921875],
                        [9.639453125000017, 46.2958984375],
                        [9.57958984375, 46.29609375],
                        [9.528710937500023, 46.306201171874996],
                        [9.427636718750023, 46.482324218749994],
                        [9.399316406250023, 46.4806640625],
                        [9.304394531250011, 46.495556640625],
                        [9.203417968750017, 46.21923828125],
                        [9.11874162946429, 46.014892578125],
                        [8.97551618303573, 45.81677455357143],
                        [8.74961495535715, 46.02246372767857],
                        [8.818554687500011, 46.0771484375],
                        [8.458398437500023, 46.245898437499996],
                        [8.370703125, 46.445117187499996],
                        [8.298535156250011, 46.40341796875],
                        [8.23193359375, 46.341210937499994],
                        [8.08154296875, 46.256005859374994],
                        [7.9931640625, 46.015917968749996],
                        [7.327929687500017, 45.912353515625],
                        [7.129003906250006, 45.880419921874996],
                        [7.055761718750006, 45.90380859375],
                        [7.02109375, 45.92578125],
                        [6.953710937500006, 46.017138671874996],
                        [6.897265625000017, 46.0517578125],
                        [6.772070312500006, 46.16513671875],
                        [6.758105468750017, 46.415771484375],
                        [6.578222656250006, 46.437353515625],
                        [6.428906250000011, 46.430517578125],
                        [6.321875, 46.393701171874994],
                        [6.234667968750017, 46.3326171875],
                        [6.199414062500011, 46.19306640625],
                        [6.086621093750011, 46.147021484374996],
                        [6.006640625000017, 46.142333984375],
                        [5.971484375000017, 46.151220703125],
                        [5.970019531250017, 46.214697265625],
                        [6.0361328125, 46.238085937499996],
                        [6.095898437500011, 46.27939453125],
                        [6.129687500000017, 46.5669921875],
                        [6.41015625, 46.755419921874996],
                        [6.429003906250017, 46.832275390625],
                        [6.45625, 46.94833984375],
                        [6.624804687500017, 47.004345703125],
                        [6.666894531250023, 47.026513671874994],
                        [6.688085937500006, 47.058251953124994],
                        [6.820703125000023, 47.16318359375],
                        [6.952050781250023, 47.2671875],
                        [6.978515625, 47.302050781249996],
                        [7.000585937500006, 47.322509765625],
                        [7.000585937500006, 47.339453125],
                        [6.900390625, 47.39423828125],
                        [6.968359375, 47.45322265625],
                        [7.136035156250017, 47.48984375],
                        [7.343164062500023, 47.43310546875],
                        [7.615625, 47.592724609375],
                        [8.454003906250023, 47.59619140625],
                        [8.559472656250023, 47.6240234375],
                        [8.570507812500011, 47.63779296875],
                        [8.567089843750011, 47.651904296874996],
                        [8.55234375, 47.659130859375],
                        [8.451757812500006, 47.651806640625],
                        [8.413281250000011, 47.6626953125],
                        [8.403417968750006, 47.687792968749996],
                        [8.435742187500011, 47.731347656249994],
                        [8.572656250000023, 47.775634765625],
                        [9.524023437500006, 47.52421875],
                        [9.625878906250023, 47.467041015625],
                        [9.527539062500011, 47.270751953125],
                        [9.527658197470123, 47.27026989773668],
                    ],
                ],
            },
            properties: {
                name: "瑞士",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-67.28886718749999, -55.776855468749964],
                            [-67.55996093749997, -55.72480468750002],
                            [-67.39736328124997, -55.58515625],
                            [-67.28886718749999, -55.776855468749964],
                        ],
                    ],
                    [
                        [
                            [-67.07993164062498, -55.15380859374996],
                            [-67.33969726562495, -55.292578124999984],
                            [-67.4947265625, -55.177441406249976],
                            [-68.07001953124995, -55.22109374999999],
                            [-68.30136718750003, -54.98066406250003],
                            [-67.245263671875, -54.977636718750034],
                            [-67.07993164062498, -55.15380859374996],
                        ],
                    ],
                    [
                        [
                            [-69.70297851562503, -54.91904296875],
                            [-68.90078125000002, -55.01777343750004],
                            [-68.45800781249997, -54.95966796875002],
                            [-68.61328124999997, -55.155566406250045],
                            [-68.28266601562495, -55.25517578125],
                            [-68.04833984375, -55.6431640625],
                            [-68.86704101562498, -55.45019531250003],
                            [-68.89008789062498, -55.2412109375],
                            [-69.19262695312497, -55.171875],
                            [-69.35922851562498, -55.300683593749945],
                            [-69.18085937499995, -55.47480468749998],
                            [-69.41181640624995, -55.44423828124997],
                            [-69.97978515625002, -55.14746093749999],
                            [-69.88442382812494, -54.88203125000001],
                            [-69.70297851562503, -54.91904296875],
                        ],
                    ],
                    [
                        [
                            [-70.9916015625, -54.86796874999999],
                            [-70.80483398437497, -54.96767578124996],
                            [-70.41752929687493, -54.908886718749976],
                            [-70.29785156249997, -55.11376953124997],
                            [-70.47558593749994, -55.17705078124998],
                            [-71.43720703125001, -54.88925781249997],
                            [-70.9916015625, -54.86796874999999],
                        ],
                    ],
                    [
                        [
                            [-71.390478515625, -54.03281250000002],
                            [-71.02192382812495, -54.111816406250036],
                            [-71.14326171874998, -54.374023437499986],
                            [-71.473291015625, -54.23115234375001],
                            [-71.94853515624999, -54.300878906250006],
                            [-72.21044921874997, -54.04775390624995],
                            [-71.996484375, -53.884863281249984],
                            [-71.390478515625, -54.03281250000002],
                        ],
                    ],
                    [
                        [
                            [-72.92324218749997, -53.481640625],
                            [-72.88222656249997, -53.578320312499976],
                            [-72.48227539062503, -53.58808593750001],
                            [-72.20541992187503, -53.80742187500002],
                            [-72.408544921875, -54.00380859374997],
                            [-72.87099609375, -54.12656250000002],
                            [-72.76376953125, -53.86484375],
                            [-73.03945312499994, -53.83281250000004],
                            [-73.08076171875001, -53.99804687499995],
                            [-73.21064453125001, -53.98583984374995],
                            [-73.31435546875, -53.72919921874998],
                            [-73.845458984375, -53.54580078125001],
                            [-73.44707031249993, -53.41005859374998],
                            [-72.92324218749997, -53.481640625],
                        ],
                    ],
                    [
                        [
                            [-74.38574218749994, -52.92236328125001],
                            [-73.65400390624998, -53.06982421875003],
                            [-73.13520507812498, -53.35390625],
                            [-73.56728515625, -53.3068359375],
                            [-73.86694335937494, -53.096875],
                            [-74.27021484374995, -53.08154296875002],
                            [-74.71201171874998, -52.74873046874998],
                            [-74.38574218749994, -52.92236328125001],
                        ],
                    ],
                    [
                        [
                            [-68.62993164062499, -52.65263671875004],
                            [-68.65322265624994, -54.85361328124999],
                            [-69.48627929687493, -54.85888671875],
                            [-69.72343750000002, -54.71210937500003],
                            [-70.49716796875, -54.80957031249999],
                            [-71.83154296874997, -54.62617187500002],
                            [-71.92773437500003, -54.52871093749997],
                            [-71.80014648437498, -54.433984374999945],
                            [-71.07993164062498, -54.444238281249994],
                            [-70.79726562500002, -54.32724609374996],
                            [-70.70112304687498, -54.48544921875004],
                            [-70.31098632812498, -54.52851562500002],
                            [-70.86308593749993, -54.11044921875003],
                            [-70.86772460937499, -53.88417968750002],
                            [-70.53129882812502, -53.627343750000016],
                            [-70.37973632812495, -53.98671874999995],
                            [-70.62983398437493, -54.005566406249976],
                            [-70.53530273437494, -54.136132812500016],
                            [-70.16899414062502, -54.37929687499999],
                            [-69.74184570312494, -54.30585937500005],
                            [-69.25317382812494, -54.557421875000045],
                            [-69.04433593749997, -54.40673828124999],
                            [-69.98813476562503, -54.10908203125001],
                            [-70.15112304687503, -53.88808593750002],
                            [-70.09111328124996, -53.72177734374998],
                            [-69.35595703125003, -53.41630859375001],
                            [-69.63701171874999, -53.33408203125004],
                            [-70.32929687499998, -53.37763671875003],
                            [-70.44335937499994, -53.085546875000034],
                            [-70.130615234375, -52.942773437499994],
                            [-70.38012695312494, -52.75195312500002],
                            [-69.93544921874997, -52.82109374999998],
                            [-69.41406249999997, -52.48623046874997],
                            [-69.16704101562499, -52.66757812499997],
                            [-68.78979492187497, -52.576757812500034],
                            [-68.62993164062499, -52.65263671875004],
                        ],
                    ],
                    [
                        [
                            [-74.82294921874993, -51.63017578125001],
                            [-74.53681640624998, -51.96513671875004],
                            [-74.69448242187497, -52.27919921874999],
                            [-74.85180664062494, -52.27070312500003],
                            [-75.10537109375, -51.78886718750001],
                            [-74.82294921874993, -51.63017578125001],
                        ],
                    ],
                    [
                        [
                            [-74.55864257812499, -51.27705078125001],
                            [-74.62036132812497, -51.395703125000026],
                            [-75.04736328125, -51.39833984375003],
                            [-75.28911132812496, -51.625390625000016],
                            [-75.15366210937498, -51.278808593750014],
                            [-74.73666992187503, -51.20761718749999],
                            [-74.55864257812499, -51.27705078125001],
                        ],
                    ],
                    [
                        [
                            [-75.302001953125, -50.67998046875005],
                            [-75.411376953125, -50.76435546875001],
                            [-75.42763671875002, -50.48056640625002],
                            [-75.11533203124998, -50.510449218749976],
                            [-75.302001953125, -50.67998046875005],
                        ],
                    ],
                    [
                        [
                            [-75.05478515625, -50.29609375],
                            [-75.44912109374997, -50.34335937500004],
                            [-75.32666015624997, -50.01181640625],
                            [-74.8759765625, -50.10996093750001],
                            [-75.05478515625, -50.29609375],
                        ],
                    ],
                    [
                        [
                            [-75.106689453125, -48.83652343750001],
                            [-75.38994140624999, -49.15917968750002],
                            [-75.64116210937499, -49.195410156250034],
                            [-75.48764648437498, -49.082421875000016],
                            [-75.58310546874998, -48.85888671874995],
                            [-75.106689453125, -48.83652343750001],
                        ],
                    ],
                    [
                        [
                            [-74.47617187499998, -49.14785156250002],
                            [-74.59472656249997, -50.00664062500001],
                            [-74.76298828124996, -50.01142578125001],
                            [-74.88041992187502, -49.72587890625001],
                            [-74.72382812499998, -49.42382812500003],
                            [-74.960107421875, -49.533007812499974],
                            [-75.06601562499998, -49.85234375000002],
                            [-75.54980468749994, -49.79130859375002],
                            [-75.30585937499998, -49.49404296875003],
                            [-75.46748046874995, -49.35888671875003],
                            [-75.08603515624998, -49.27021484375],
                            [-75.21015624999995, -49.14804687499998],
                            [-74.94921875, -48.960156249999976],
                            [-74.89624023437503, -48.73320312500002],
                            [-74.54609374999993, -48.76689453125004],
                            [-74.47617187499998, -49.14785156250002],
                        ],
                    ],
                    [
                        [
                            [-75.51025390624997, -48.76347656250005],
                            [-75.65092773437496, -48.58632812500002],
                            [-75.57148437499993, -48.095898437500026],
                            [-75.39140625000002, -48.01972656249997],
                            [-75.15849609374999, -48.62265624999996],
                            [-75.51025390624997, -48.76347656250005],
                        ],
                    ],
                    [
                        [
                            [-74.56728515625, -48.591992187500026],
                            [-74.92304687499998, -48.62646484375003],
                            [-75.21289062499997, -48.141699218750034],
                            [-75.19829101562502, -47.974609375000014],
                            [-74.895654296875, -47.839355468749986],
                            [-74.56728515625, -48.591992187500026],
                        ],
                    ],
                    [
                        [
                            [-75.11220703124997, -47.8376953125],
                            [-75.26103515625002, -47.76386718749998],
                            [-74.92646484374998, -47.72314453125003],
                            [-75.11220703124997, -47.8376953125],
                        ],
                    ],
                    [
                        [
                            [-74.31289062500002, -45.69150390625002],
                            [-74.46552734374995, -45.757226562499994],
                            [-74.68984375, -45.66259765625],
                            [-74.310546875, -45.17265625000002],
                            [-74.31289062500002, -45.69150390625002],
                        ],
                    ],
                    [
                        [
                            [-73.63217773437498, -44.82148437499997],
                            [-73.81845703125, -44.65214843750002],
                            [-73.72392578124993, -44.544238281249974],
                            [-73.63217773437498, -44.82148437499997],
                        ],
                    ],
                    [
                        [
                            [-72.98613281249999, -44.780078124999974],
                            [-73.22846679687498, -44.85996093749999],
                            [-73.39707031249998, -44.77431640624995],
                            [-73.44506835937497, -44.641015624999966],
                            [-73.20771484374993, -44.33496093749997],
                            [-72.7763671875, -44.50859374999999],
                            [-72.98613281249999, -44.780078124999974],
                        ],
                    ],
                    [
                        [
                            [-73.73535156249997, -44.39453125000003],
                            [-74.00205078125003, -44.59091796874998],
                            [-73.728173828125, -45.195898437500034],
                            [-74.016259765625, -45.344921875000026],
                            [-74.61777343749998, -44.64794921874996],
                            [-74.50180664062498, -44.47353515624995],
                            [-74.09721679687496, -44.38935546875004],
                            [-73.99492187499999, -44.140234375],
                            [-73.70322265624998, -44.27412109375001],
                            [-73.73535156249997, -44.39453125000003],
                        ],
                    ],
                    [
                        [
                            [-73.81064453125003, -43.827246093750006],
                            [-73.95566406249998, -43.921972656250034],
                            [-74.14296874999997, -43.872167968750006],
                            [-73.81064453125003, -43.827246093750006],
                        ],
                    ],
                    [
                        [
                            [-73.77338867187498, -43.3458984375],
                            [-74.114404296875, -43.35791015624996],
                            [-74.387353515625, -43.231640625],
                            [-74.03666992187496, -41.79550781249998],
                            [-73.52783203124997, -41.89628906249999],
                            [-73.42290039062499, -42.192871093750014],
                            [-73.47080078124998, -42.46630859375004],
                            [-73.78925781249993, -42.58574218750003],
                            [-73.43632812499996, -42.9365234375],
                            [-73.74965820312494, -43.15908203124995],
                            [-73.77338867187498, -43.3458984375],
                        ],
                    ],
                    [
                        [
                            [-78.80415039062501, -33.646484374999986],
                            [-78.98945312499993, -33.66171874999998],
                            [-78.87744140625003, -33.57519531250003],
                            [-78.80415039062501, -33.646484374999986],
                        ],
                    ],
                    [
                        [
                            [-109.27998046874994, -27.14042968749996],
                            [-109.434130859375, -27.171289062500023],
                            [-109.39047851562499, -27.068359375000014],
                            [-109.27998046874994, -27.14042968749996],
                        ],
                    ],
                    [
                        [
                            [-67.19487304687493, -22.821679687500037],
                            [-67.00878906249994, -23.00136718750005],
                            [-67.35620117187503, -24.033789062499963],
                            [-68.25029296875002, -24.391992187500023],
                            [-68.56201171875, -24.74736328125003],
                            [-68.38422851562495, -25.091894531249977],
                            [-68.59208984375002, -25.420019531250034],
                            [-68.41450195312498, -26.153710937500023],
                            [-68.59160156249999, -26.47041015624997],
                            [-68.31865234374999, -26.973242187500006],
                            [-68.59208984375002, -27.140039062499966],
                            [-68.84633789062494, -27.153710937499994],
                            [-69.17441406249998, -27.924707031250037],
                            [-69.65693359374995, -28.413574218749986],
                            [-69.82788085937497, -29.10322265624997],
                            [-70.02680664062501, -29.324023437500017],
                            [-69.95996093749997, -30.078320312500026],
                            [-69.84428710937493, -30.175],
                            [-69.95634765624996, -30.35820312500003],
                            [-70.15322265625, -30.360937499999963],
                            [-70.30908203124994, -31.02265625000004],
                            [-70.51958007812493, -31.1484375],
                            [-70.585205078125, -31.569433593749963],
                            [-70.25439453125, -31.957714843750026],
                            [-70.36376953125, -32.08349609374997],
                            [-70.02197265625, -32.88457031250002],
                            [-70.08486328125002, -33.20175781249998],
                            [-69.81962890624999, -33.28378906249999],
                            [-69.85244140625, -34.224316406250026],
                            [-70.05205078124999, -34.30078124999997],
                            [-70.39316406250003, -35.146875],
                            [-70.55517578125, -35.246875],
                            [-70.41572265625001, -35.52304687500002],
                            [-70.40478515625, -36.06171874999998],
                            [-71.05551757812498, -36.52373046874996],
                            [-71.19218750000002, -36.84365234375004],
                            [-71.16757812499998, -37.76230468749996],
                            [-70.858642578125, -38.60449218750003],
                            [-71.40156249999995, -38.93505859374996],
                            [-71.53945312499997, -39.60244140624995],
                            [-71.71992187499995, -39.63525390624997],
                            [-71.65976562499998, -40.02080078125],
                            [-71.81831054687493, -40.17666015624995],
                            [-71.70898437499997, -40.381738281249994],
                            [-71.93212890624994, -40.69169921874999],
                            [-71.91127929687497, -41.650390624999986],
                            [-71.75, -42.04677734375001],
                            [-72.10820312499993, -42.25185546874995],
                            [-72.14643554687498, -42.990039062499974],
                            [-71.750634765625, -43.237304687499986],
                            [-71.90498046875001, -43.34755859374998],
                            [-71.68007812500002, -43.92958984374998],
                            [-71.82001953124993, -44.38310546875],
                            [-71.21259765624998, -44.44121093750003],
                            [-71.15971679687496, -44.56025390625004],
                            [-71.26113281250002, -44.763085937499966],
                            [-72.06372070312503, -44.771875],
                            [-72.04169921874998, -44.90419921875004],
                            [-71.5962890625, -44.97919921875004],
                            [-71.34931640624995, -45.33193359374995],
                            [-71.74619140624998, -45.57890625],
                            [-71.63154296874998, -45.95371093749998],
                            [-71.87568359374998, -46.160546875],
                            [-71.69965820312501, -46.6513671875],
                            [-71.94023437499999, -46.83125],
                            [-71.90498046875001, -47.201660156250014],
                            [-72.34594726562497, -47.49267578124997],
                            [-72.517919921875, -47.87636718749998],
                            [-72.32832031250001, -48.11005859374998],
                            [-72.35473632812497, -48.36582031250005],
                            [-72.582861328125, -48.47539062499999],
                            [-72.65126953125, -48.84160156249998],
                            [-73.03364257812501, -49.014355468750004],
                            [-73.13525390625, -49.30068359374999],
                            [-73.46157226562497, -49.31386718750001],
                            [-73.55419921875, -49.463867187500014],
                            [-73.50126953124996, -50.125292968750024],
                            [-73.15292968749998, -50.73828125000003],
                            [-72.50981445312496, -50.607519531250034],
                            [-72.34023437499997, -50.68183593749999],
                            [-72.40766601562501, -51.54082031250002],
                            [-71.91865234374995, -51.98955078125004],
                            [-69.96025390624993, -52.00820312500002],
                            [-68.443359375, -52.35664062500004],
                            [-69.24101562499996, -52.20546874999997],
                            [-69.62031249999995, -52.46474609374995],
                            [-70.79511718749995, -52.76875],
                            [-70.99584960937497, -53.77929687499997],
                            [-71.29775390625002, -53.88339843750004],
                            [-72.1744140625, -53.632324218749964],
                            [-72.41289062500002, -53.35019531250004],
                            [-71.94169921874993, -53.23408203125001],
                            [-71.89169921874998, -53.523535156250006],
                            [-71.79145507812498, -53.48457031249997],
                            [-71.74052734374999, -53.232617187499976],
                            [-71.28896484375002, -53.03369140624995],
                            [-71.22714843750003, -52.810644531249984],
                            [-71.38774414062496, -52.76425781250004],
                            [-72.27802734374998, -53.13232421874997],
                            [-72.54892578125, -53.4607421875],
                            [-73.05273437499997, -53.24345703125005],
                            [-72.72768554687502, -52.7623046875],
                            [-72.453466796875, -52.814453124999964],
                            [-72.11757812499997, -52.65],
                            [-71.51127929687502, -52.60537109375],
                            [-72.22568359374998, -52.52099609374995],
                            [-72.43769531250001, -52.62578124999998],
                            [-72.71210937499995, -52.53554687499999],
                            [-73.12246093749997, -53.073925781249976],
                            [-73.64521484374998, -52.83701171875003],
                            [-73.2408203125, -52.707128906250034],
                            [-73.12392578125, -52.487988281249976],
                            [-73.24414062499997, -52.62402343749998],
                            [-73.58569335937503, -52.68574218750003],
                            [-74.01445312499999, -52.63935546875],
                            [-74.26494140624993, -52.1048828125],
                            [-73.83447265625, -52.23398437500001],
                            [-73.68432617187494, -52.07773437499998],
                            [-73.26044921874993, -52.157812500000034],
                            [-72.79501953124998, -51.94951171875005],
                            [-72.57084960937496, -52.200097656249945],
                            [-72.67705078125002, -52.38466796874998],
                            [-72.52333984374997, -52.255468750000034],
                            [-72.62460937499998, -51.94648437499997],
                            [-72.48964843750002, -51.76367187500003],
                            [-72.76123046875, -51.57324218749996],
                            [-73.16875, -51.45390624999998],
                            [-72.60004882812495, -51.79912109374997],
                            [-73.51816406250003, -52.04101562499996],
                            [-73.75263671874993, -51.795507812500034],
                            [-74.19667968749997, -51.68056640624997],
                            [-73.92978515624995, -51.61787109374999],
                            [-73.93950195312499, -51.26630859375005],
                            [-74.81474609374996, -51.06289062499999],
                            [-75.09467773437495, -50.68125],
                            [-74.68574218749995, -50.662011718749945],
                            [-74.77587890625003, -50.46992187499998],
                            [-74.64448242187498, -50.360937499999984],
                            [-74.365576171875, -50.487890625],
                            [-74.13940429687503, -50.81777343749997],
                            [-73.80654296875, -50.93837890625003],
                            [-73.654443359375, -50.49267578125],
                            [-73.97802734375003, -50.827050781249994],
                            [-74.18559570312493, -50.485351562500014],
                            [-73.95034179687497, -50.510546875],
                            [-74.62958984374998, -50.19404296875],
                            [-74.333740234375, -49.97460937499997],
                            [-73.95859374999998, -49.994726562499984],
                            [-74.32392578124995, -49.783398437500004],
                            [-74.29082031249996, -49.604101562499984],
                            [-73.83637695312493, -49.609375],
                            [-74.09443359374993, -49.42968749999998],
                            [-73.93496093749994, -49.02089843750001],
                            [-74.2212890625, -49.500585937500034],
                            [-74.36655273437503, -49.40048828124998],
                            [-74.34101562499998, -48.59570312499998],
                            [-74.00908203124996, -48.475],
                            [-74.47441406249999, -48.46396484374996],
                            [-74.58466796874998, -47.999023437500014],
                            [-73.39106445312498, -48.14589843750001],
                            [-73.60991210937499, -47.993945312500045],
                            [-73.71586914062499, -47.65546875000001],
                            [-73.94086914062498, -47.92939453125004],
                            [-74.22705078124994, -47.96894531250001],
                            [-74.654931640625, -47.702246093750034],
                            [-74.5337890625, -47.567675781249974],
                            [-74.24296874999999, -47.67929687499998],
                            [-74.13408203125002, -47.590820312499986],
                            [-74.48266601562497, -47.43046875],
                            [-74.15839843749998, -47.18251953125002],
                            [-74.31357421874998, -46.78818359374998],
                            [-74.45419921875003, -46.76679687499997],
                            [-74.51225585937496, -46.88515625000002],
                            [-75.00595703125, -46.74111328124998],
                            [-74.98417968750002, -46.51210937499995],
                            [-75.54033203124999, -46.69873046874996],
                            [-75.43037109374995, -46.93457031249996],
                            [-75.70639648437498, -46.70527343749997],
                            [-74.924462890625, -46.159667968750014],
                            [-75.06669921874993, -45.874902343749994],
                            [-74.15786132812497, -45.7671875],
                            [-74.122705078125, -45.49619140625002],
                            [-73.95717773437494, -45.40439453124998],
                            [-73.825, -45.446875],
                            [-74.01992187500002, -46.055859375],
                            [-74.39296875, -46.21738281250005],
                            [-73.96757812500002, -46.15410156250003],
                            [-73.87871093749993, -45.846875],
                            [-73.73525390624994, -45.81171875],
                            [-73.70815429687502, -46.070312500000014],
                            [-73.94863281249997, -46.533105468749966],
                            [-73.845361328125, -46.56601562500002],
                            [-73.59184570312493, -45.89912109375004],
                            [-73.73076171874999, -45.47998046875],
                            [-73.26621093749995, -45.346191406250014],
                            [-72.933837890625, -45.45234374999997],
                            [-73.44497070312497, -45.23818359374995],
                            [-73.36245117187502, -44.97822265625001],
                            [-72.73896484375001, -44.73417968750003],
                            [-72.680078125, -44.59394531249997],
                            [-72.66386718749999, -44.43642578124995],
                            [-73.26508789062498, -44.16865234375001],
                            [-73.22446289062498, -43.89794921875003],
                            [-73.06879882812495, -43.86201171874998],
                            [-72.99658203125, -43.63154296875001],
                            [-73.07597656250002, -43.323632812499994],
                            [-72.75800781249998, -43.039453125],
                            [-72.84804687500002, -42.66914062499997],
                            [-72.77392578125003, -42.505175781250045],
                            [-72.63183593750003, -42.509667968749994],
                            [-72.77324218749996, -42.257714843749994],
                            [-72.63105468749995, -42.199804687500006],
                            [-72.412353515625, -42.388183593750014],
                            [-72.49941406249997, -41.98085937499999],
                            [-72.82407226562503, -41.90878906249996],
                            [-72.36040039062499, -41.64912109375],
                            [-72.31826171875, -41.49902343749997],
                            [-72.54238281250002, -41.690625],
                            [-72.95283203124995, -41.51474609374998],
                            [-73.24179687499995, -41.78085937500002],
                            [-73.62402343750003, -41.77363281249997],
                            [-73.73515625000002, -41.74248046875002],
                            [-73.62392578125, -41.581347656250045],
                            [-73.81074218749995, -41.51748046875001],
                            [-73.96586914062493, -41.118261718750034],
                            [-73.67099609375, -39.96318359374999],
                            [-73.41040039062503, -39.78916015624998],
                            [-73.22646484375002, -39.22441406250003],
                            [-73.52021484375001, -38.509375],
                            [-73.46479492187498, -38.04033203125003],
                            [-73.66181640624998, -37.69853515625003],
                            [-73.66240234375002, -37.341015625000026],
                            [-73.60166015624998, -37.18847656250003],
                            [-73.21596679687502, -37.16689453124998],
                            [-73.11806640624997, -36.68837890625002],
                            [-72.58735351562493, -35.759667968749994],
                            [-72.62392578125002, -35.5857421875],
                            [-72.22377929687494, -35.096191406250014],
                            [-72.00283203124997, -34.16533203125],
                            [-71.66435546875002, -33.65263671875],
                            [-71.74296875, -33.09511718750001],
                            [-71.45224609374998, -32.65957031250001],
                            [-71.70893554687495, -30.62802734375002],
                            [-71.66948242187499, -30.33037109374996],
                            [-71.40039062499997, -30.142968749999966],
                            [-71.31572265624996, -29.649707031250017],
                            [-71.51923828124993, -28.926464843750026],
                            [-71.30673828124998, -28.672460937499963],
                            [-71.08652343749998, -27.814453124999957],
                            [-70.92578125, -27.588671874999974],
                            [-70.64658203124998, -26.329394531250017],
                            [-70.71372070312498, -25.78417968749997],
                            [-70.44536132812502, -25.17265624999999],
                            [-70.57412109374994, -24.644335937500003],
                            [-70.39233398437494, -23.565917968749957],
                            [-70.59335937499995, -23.255468750000034],
                            [-70.56318359374995, -23.057031250000023],
                            [-70.33168945312494, -22.848632812500014],
                            [-70.08002929687501, -21.356835937500037],
                            [-70.19702148437494, -20.725390625],
                            [-70.15742187499995, -19.70585937500003],
                            [-70.41826171874999, -18.345605468750023],
                            [-69.92636718749998, -18.206054687500014],
                            [-69.80258789062498, -17.990234375000014],
                            [-69.85209960937493, -17.70380859375001],
                            [-69.68476562499995, -17.649804687500023],
                            [-69.58642578125, -17.57324218749997],
                            [-69.51093749999998, -17.50605468749997],
                            [-69.31337890624997, -17.943164062500017],
                            [-69.28232421875003, -17.96484375],
                            [-69.09394531249993, -18.05048828125004],
                            [-69.14545898437495, -18.14404296875],
                            [-69.09228515624994, -18.28242187500004],
                            [-69.02680664062493, -18.65625],
                            [-68.97885742187503, -18.81298828125003],
                            [-68.96831054687502, -18.967968749999983],
                            [-68.85795898437499, -19.09335937500005],
                            [-68.62055664062495, -19.29667968749999],
                            [-68.54785156249997, -19.341113281249974],
                            [-68.49199218749996, -19.381933593750034],
                            [-68.47016601562495, -19.409960937499974],
                            [-68.46289062499997, -19.43281250000001],
                            [-68.57529296874998, -19.56015625000002],
                            [-68.69829101562499, -19.721093750000037],
                            [-68.69619140625, -19.74072265625003],
                            [-68.57827148437494, -19.856542968750006],
                            [-68.559375, -19.902343750000014],
                            [-68.56069335937502, -19.96708984374996],
                            [-68.75932617187499, -20.115527343750003],
                            [-68.74516601562493, -20.45859375],
                            [-68.48432617187498, -20.628417968749957],
                            [-68.55825195312497, -20.90195312499999],
                            [-68.197021484375, -21.30029296874997],
                            [-68.18642578124997, -21.618554687499966],
                            [-67.88173828124997, -22.493359375000026],
                            [-67.87944335937496, -22.822949218750026],
                            [-67.57993164062495, -22.89169921874999],
                            [-67.36225585937493, -22.85517578125001],
                            [-67.19487304687493, -22.821679687500037],
                        ],
                    ],
                ],
            },
            properties: {
                name: "智利",
                childNum: 26,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [110.88876953125006, 19.99194335937497],
                            [111.01367187500003, 19.65546875000001],
                            [110.64091796875002, 19.291210937499955],
                            [110.45126953125012, 18.747949218750023],
                            [110.06738281249997, 18.447558593750045],
                            [109.51933593750007, 18.21826171875003],
                            [108.7015625, 18.535253906250034],
                            [108.66552734375003, 19.304101562499994],
                            [109.27666015625002, 19.761132812500023],
                            [109.17744140625004, 19.768457031250023],
                            [109.26347656250007, 19.882666015625006],
                            [110.1715820312501, 20.053710937500057],
                            [110.58818359375002, 19.976367187500017],
                            [110.6517578125, 20.137744140625017],
                            [110.88876953125006, 19.99194335937497],
                        ],
                    ],
                    [
                        [
                            [110.38515625000005, 21.093164062499966],
                            [110.52158203125006, 21.083105468750063],
                            [110.50390625000003, 20.96772460937501],
                            [110.28095703125004, 21.001171874999983],
                            [110.38515625000005, 21.093164062499966],
                        ],
                    ],
                    [
                        [
                            [112.64375, 21.63964843750003],
                            [112.525, 21.62304687500003],
                            [112.64765625000004, 21.710253906250017],
                            [112.64375, 21.63964843750003],
                        ],
                    ],
                    [
                        [
                            [112.79023437500004, 21.601855468750045],
                            [112.78203125000007, 21.772265625000045],
                            [112.86259765625002, 21.75263671875004],
                            [112.79023437500004, 21.601855468750045],
                        ],
                    ],
                    [
                        [
                            [118.1830078125, 24.496289062499983],
                            [118.0905273437501, 24.446142578125063],
                            [118.10380859375002, 24.552343750000034],
                            [118.1830078125, 24.496289062499983],
                        ],
                    ],
                    [
                        [
                            [119.82089843750006, 25.45698242187504],
                            [119.70029296875012, 25.432714843750063],
                            [119.72255859375005, 25.638818359375023],
                            [119.83837890625003, 25.591064453125],
                            [119.82089843750006, 25.45698242187504],
                        ],
                    ],
                    [
                        [
                            [121.2513671875, 28.086425781250057],
                            [121.13154296875004, 28.062597656250006],
                            [121.20546875, 28.204394531250017],
                            [121.2513671875, 28.086425781250057],
                        ],
                    ],
                    [
                        [
                            [122.29589843750003, 29.96342773437499],
                            [122.02402343750012, 30.01333007812505],
                            [121.96943359375004, 30.143115234375017],
                            [122.28447265625007, 30.068017578124994],
                            [122.29589843750003, 29.96342773437499],
                        ],
                    ],
                    [
                        [
                            [121.86269531250005, 31.492285156249977],
                            [121.519921875, 31.549609375000017],
                            [121.2111328125001, 31.80537109375001],
                            [121.86269531250005, 31.492285156249977],
                        ],
                    ],
                    [
                        [
                            [130.52695312500012, 42.535400390625],
                            [130.24667968750012, 42.744824218749955],
                            [130.24033203125006, 42.891796874999955],
                            [129.89824218750002, 42.998144531250034],
                            [129.69785156250012, 42.448144531249994],
                            [129.3136718750001, 42.41357421874997],
                            [128.92343750000006, 42.038232421874966],
                            [128.04521484375007, 41.9875],
                            [128.28925781250004, 41.60742187500006],
                            [128.14941406249997, 41.38774414062496],
                            [127.17968750000003, 41.531347656250006],
                            [126.95478515625004, 41.76948242187501],
                            [126.74306640625, 41.724853515625],
                            [125.98906250000002, 40.904638671875034],
                            [124.8893554687501, 40.459814453125006],
                            [124.36210937500002, 40.004052734374994],
                            [124.10576171875002, 39.84101562499998],
                            [123.65087890625003, 39.881591796875],
                            [122.8400390625001, 39.600830078125],
                            [121.98232421875, 39.05317382812498],
                            [121.67724609374997, 39.00341796875006],
                            [121.64990234375003, 38.865087890625034],
                            [121.16357421874997, 38.73164062500001],
                            [121.10673828125002, 38.920800781249994],
                            [121.6798828125001, 39.10869140625002],
                            [121.62763671875004, 39.22016601562498],
                            [121.81845703125006, 39.38652343750002],
                            [121.27548828125006, 39.38476562500003],
                            [121.26748046875, 39.544677734375],
                            [121.51757812499997, 39.638964843750045],
                            [121.51738281250002, 39.84482421875006],
                            [121.8009765625001, 39.950537109375006],
                            [122.27500000000012, 40.541845703125034],
                            [121.83486328125005, 40.97426757812502],
                            [121.72929687500002, 40.84614257812504],
                            [121.1745117187501, 40.901269531249994],
                            [120.47910156250006, 40.23095703125003],
                            [119.39111328125003, 39.75249023437499],
                            [118.976953125, 39.182568359374955],
                            [118.29785156249997, 39.067089843749955],
                            [118.04091796875, 39.22675781249998],
                            [117.86572265625003, 39.191259765625034],
                            [117.61669921875003, 38.852880859375034],
                            [117.5578125000001, 38.625146484374994],
                            [117.76669921875012, 38.311669921874994],
                            [118.01494140625007, 38.18339843749996],
                            [118.94003906250006, 38.04277343750002],
                            [119.08916015625007, 37.70073242187496],
                            [118.95263671875003, 37.33115234374998],
                            [119.28740234375002, 37.138281250000034],
                            [119.76054687500007, 37.15507812499999],
                            [120.31152343750003, 37.62270507812505],
                            [120.2572265625, 37.67900390624996],
                            [120.75, 37.83393554687501],
                            [121.64023437500012, 37.46035156250002],
                            [122.05664062500003, 37.528906250000034],
                            [122.66699218750003, 37.40283203125003],
                            [122.4466796875, 37.06811523437503],
                            [122.51972656250004, 36.94682617187502],
                            [122.34091796875012, 36.83222656250004],
                            [121.93271484375006, 36.95947265625003],
                            [121.05380859375006, 36.61137695312499],
                            [120.81083984375007, 36.6328125],
                            [120.89580078125007, 36.44414062500002],
                            [120.71152343750006, 36.41328125000004],
                            [120.6378906250001, 36.129931640625045],
                            [120.39306640625003, 36.053857421874994],
                            [120.32773437500006, 36.228173828124994],
                            [120.18330078125004, 36.20244140624999],
                            [120.094140625, 36.11889648437503],
                            [120.28476562500006, 35.98442382812499],
                            [119.42968749999997, 35.301416015624994],
                            [119.16533203125002, 34.84882812499998],
                            [119.20097656250002, 34.748437499999966],
                            [120.26669921875006, 34.274023437500034],
                            [120.87109374999997, 33.016503906249994],
                            [120.8532226562501, 32.66137695312503],
                            [121.34169921875005, 32.42504882812503],
                            [121.40390625000006, 32.20625],
                            [121.85634765625, 31.816455078125045],
                            [121.86630859375006, 31.703564453124955],
                            [121.68085937500004, 31.71215820312503],
                            [121.351953125, 31.85878906250005],
                            [120.97353515625, 31.86938476562497],
                            [120.52011718750006, 32.10585937500002],
                            [120.03593750000002, 31.93627929687503],
                            [120.7155273437501, 31.983740234375006],
                            [120.7877929687501, 31.81977539062501],
                            [121.66064453124997, 31.319726562499994],
                            [121.87792968750003, 30.91699218750003],
                            [121.41894531249997, 30.789794921875057],
                            [120.8214843750001, 30.354638671875023],
                            [120.44980468750006, 30.38784179687505],
                            [120.19462890625002, 30.241308593750034],
                            [120.49453125, 30.303076171875006],
                            [120.63339843750006, 30.133154296875034],
                            [121.25800781250004, 30.30410156250005],
                            [121.67792968750004, 29.979101562500006],
                            [122.08291015625005, 29.870361328125057],
                            [121.50625, 29.484570312499955],
                            [121.94121093750002, 29.605908203124983],
                            [121.91777343750007, 29.13500976562497],
                            [121.71748046875004, 29.25634765625],
                            [121.48710937500007, 29.193164062500017],
                            [121.67968749999997, 28.953125],
                            [121.54003906250003, 28.931884765625],
                            [121.6625, 28.851416015625034],
                            [121.47519531250006, 28.64140625],
                            [121.60996093750006, 28.29213867187505],
                            [121.27226562500002, 28.222119140624983],
                            [121.14570312500004, 28.32666015624997],
                            [120.95859375000006, 28.037011718750023],
                            [120.74765625000006, 28.00996093750001],
                            [120.83300781249997, 27.891455078125034],
                            [120.58750000000012, 27.580761718749983],
                            [120.60751953125012, 27.41240234374996],
                            [120.2787109375, 27.097070312500023],
                            [120.08671875000007, 26.67158203125004],
                            [119.88222656250005, 26.610449218750006],
                            [119.82421874999997, 26.84638671875001],
                            [119.71044921874997, 26.728662109375023],
                            [119.58818359375002, 26.784960937500045],
                            [119.8810546875001, 26.33417968750004],
                            [119.46308593750004, 26.05468750000003],
                            [119.13945312500007, 26.12177734375001],
                            [119.33203124999997, 25.94873046875003],
                            [119.61875000000012, 26.003564453124994],
                            [119.53945312500005, 25.59125976562504],
                            [119.6224609375, 25.391162109375017],
                            [119.180078125, 25.449804687499977],
                            [119.285546875, 25.232226562500074],
                            [118.97753906249997, 25.209277343750017],
                            [118.90908203125005, 24.92890625000001],
                            [118.63691406250004, 24.835546874999977],
                            [118.65703125000002, 24.621435546874977],
                            [118.0871093750001, 24.627001953125045],
                            [118.00595703125006, 24.48198242187499],
                            [117.84267578125005, 24.47431640625004],
                            [118.0560546875, 24.24609374999997],
                            [117.62822265625002, 23.836718750000074],
                            [117.46640625000012, 23.84057617187497],
                            [117.36767578124997, 23.58862304687497],
                            [117.29082031250007, 23.71435546875],
                            [117.08251953124997, 23.578759765625023],
                            [116.91064453124997, 23.646679687499983],
                            [116.86093750000006, 23.453076171874983],
                            [116.62939453124997, 23.353857421875034],
                            [116.69882812500006, 23.277783203124983],
                            [116.53828125000004, 23.17968749999997],
                            [116.47070312499997, 22.945898437500034],
                            [116.25185546875005, 22.981347656249994],
                            [115.85214843750006, 22.801562500000045],
                            [115.64042968750002, 22.853417968750023],
                            [115.49833984375002, 22.718847656250063],
                            [115.19580078125003, 22.81728515625005],
                            [114.85380859375007, 22.616796875000063],
                            [114.65166015625002, 22.755273437500023],
                            [114.55419921874997, 22.52890625],
                            [114.26601562500005, 22.540966796874983],
                            [114.01542968750007, 22.51191406250001],
                            [113.61962890624997, 22.861425781249977],
                            [113.6205078125, 23.12749023437499],
                            [113.51972656250004, 23.102099609375074],
                            [113.33105468749997, 22.912011718749966],
                            [113.55302734375002, 22.594042968750045],
                            [113.54912109375002, 22.225195312500034],
                            [113.14902343750012, 22.075],
                            [113.08876953125, 22.207958984374983],
                            [112.95390625000007, 21.907324218750034],
                            [112.80859374999997, 21.944628906250074],
                            [112.58632812500005, 21.77685546875],
                            [112.35966796875007, 21.97802734375003],
                            [112.30498046875002, 21.74169921875003],
                            [111.94394531250012, 21.84965820312499],
                            [111.60273437500004, 21.55908203125003],
                            [111.01689453125007, 21.51171874999997],
                            [110.56718750000002, 21.21406250000001],
                            [110.41093750000007, 21.33813476562497],
                            [110.15400390625004, 20.944628906250017],
                            [110.36542968750004, 20.837597656249955],
                            [110.31308593750012, 20.67167968749999],
                            [110.51152343750007, 20.51826171875001],
                            [110.34472656249997, 20.29482421875005],
                            [109.88251953125004, 20.364062500000045],
                            [109.96835937500006, 20.448144531250023],
                            [109.66259765625003, 20.91689453125005],
                            [109.68125000000012, 21.13164062499999],
                            [109.93076171875012, 21.480566406250034],
                            [109.6869140625, 21.52460937500004],
                            [109.56640624999997, 21.690576171874994],
                            [109.54404296875012, 21.537939453125006],
                            [109.14863281250004, 21.425537109375],
                            [109.1017578125001, 21.59047851562505],
                            [108.77167968750004, 21.63046875],
                            [108.59375, 21.901025390624994],
                            [108.47988281250005, 21.904638671875006],
                            [108.50214843750004, 21.633447265624994],
                            [108.32480468750006, 21.693505859374994],
                            [108.24628906250004, 21.55839843749999],
                            [107.97265624999997, 21.507958984375023],
                            [107.75927734374997, 21.655029296875057],
                            [107.35117187500012, 21.60888671874997],
                            [106.97099609375002, 21.923925781250034],
                            [106.66357421875003, 21.97890625000005],
                            [106.55039062500006, 22.501367187499994],
                            [106.78027343749997, 22.778906250000034],
                            [106.54179687500007, 22.908349609375023],
                            [106.2790039062501, 22.857470703125045],
                            [106.14843749999997, 22.970068359375006],
                            [105.8429687500001, 22.922802734374955],
                            [105.27539062500003, 23.34521484375003],
                            [104.86474609375003, 23.136376953125023],
                            [104.68730468750002, 22.822216796874983],
                            [104.37177734375004, 22.704052734374983],
                            [104.14306640624997, 22.800146484375006],
                            [103.94150390625006, 22.540087890625045],
                            [103.62021484375006, 22.782031250000045],
                            [103.49296875000007, 22.587988281250034],
                            [103.32666015625003, 22.769775390625057],
                            [102.98193359374997, 22.4482421875],
                            [102.47089843750004, 22.75092773437501],
                            [102.40644531250004, 22.70800781249997],
                            [102.2370117187501, 22.466015624999983],
                            [102.1759765625001, 22.414648437500006],
                            [102.12744140624997, 22.379199218750045],
                            [101.84179687500003, 22.38847656249999],
                            [101.75996093750004, 22.490332031250034],
                            [101.73876953124997, 22.495263671874994],
                            [101.70751953125003, 22.486572265625],
                            [101.67148437500006, 22.462304687500023],
                            [101.64619140625004, 22.405419921874966],
                            [101.61992187500002, 22.32744140624999],
                            [101.56787109374997, 22.27636718749997],
                            [101.52451171875006, 22.25366210937497],
                            [101.7365234375001, 21.826513671874977],
                            [101.74394531250007, 21.77797851562505],
                            [101.74726562500004, 21.605761718750045],
                            [101.72294921875007, 21.31494140625003],
                            [101.80058593750007, 21.212597656249983],
                            [101.78349609375007, 21.204150390625017],
                            [101.728125, 21.156396484374994],
                            [101.7047851562501, 21.15014648437503],
                            [101.54238281250005, 21.23427734375005],
                            [101.2814453125001, 21.184130859375045],
                            [101.24785156250007, 21.197314453125045],
                            [101.22441406250002, 21.223730468750034],
                            [101.21181640625, 21.278222656250023],
                            [101.2199218750001, 21.34243164062505],
                            [101.17539062500006, 21.407519531250074],
                            [101.19667968750005, 21.522070312500063],
                            [101.1388671875001, 21.567480468749977],
                            [101.07978515625004, 21.75585937499997],
                            [100.60458984375012, 21.471777343750006],
                            [100.14765625000004, 21.480517578125017],
                            [99.94072265625007, 21.75874023437504],
                            [99.9176757812501, 22.02802734375001],
                            [99.19296875000006, 22.12597656249997],
                            [99.50712890625002, 22.959130859374994],
                            [99.41806640625006, 23.069238281250023],
                            [98.86376953125003, 23.191259765625034],
                            [98.8322265625001, 23.624365234374977],
                            [98.67675781250003, 23.905078125000045],
                            [98.83505859375006, 24.121191406250034],
                            [98.2125, 24.110644531250017],
                            [97.56455078125012, 23.911035156250023],
                            [97.7082031250001, 24.228759765625],
                            [97.53144531250004, 24.49169921875003],
                            [97.58330078125002, 24.77480468750005],
                            [97.73789062500006, 24.869873046875057],
                            [97.8195312500001, 25.251855468749994],
                            [98.01074218749997, 25.292529296875017],
                            [98.14287109375007, 25.571093750000017],
                            [98.33378906250007, 25.586767578125006],
                            [98.65625, 25.86357421874999],
                            [98.56406250000006, 26.072412109374994],
                            [98.68554687499997, 26.189355468750023],
                            [98.7384765625001, 26.785742187500006],
                            [98.65117187500007, 27.572460937499983],
                            [98.4525390625, 27.6572265625],
                            [98.29882812499997, 27.550097656250045],
                            [98.06162109375012, 28.185888671874977],
                            [97.59921875000006, 28.51704101562504],
                            [97.53789062500002, 28.510205078124983],
                            [97.43144531250002, 28.353906250000023],
                            [97.35644531249997, 28.254492187500006],
                            [97.32158929493812, 28.217097107438057],
                            [97.3027336276825, 28.08710519614969],
                            [97.34382779482424, 27.982305259167095],
                            [97.04929369561631, 27.76000444316393],
                            [96.96494598325154, 27.699301564540924],
                            [96.19423412199573, 28.04146177926422],
                            [95.73730002295082, 28.117613231051525],
                            [95.11298892962586, 27.748338353239472],
                            [94.07167814294401, 27.588707868507477],
                            [93.61247595136224, 27.323800298697016],
                            [93.30681393470121, 26.786120363519142],
                            [92.74319481218781, 26.833531317384058],
                            [92.04974640832253, 26.874866505386724],
                            [92.07342257335648, 26.915311275859864],
                            [92.06813426293174, 26.9752569185349],
                            [92.02985139563152, 27.03987087331446],
                            [91.99856592104459, 27.079255842602592],
                            [91.99177981607339, 27.100605151743654],
                            [92.0025114452454, 27.147290053160265],
                            [92.03101585307499, 27.214271359861193],
                            [92.08387457645458, 27.29090135496722],
                            [92.04520857607581, 27.364442429033787],
                            [91.99069061380867, 27.450181624174498],
                            [91.95099838734396, 27.45828799115413],
                            [91.85276579410389, 27.438593286730903],
                            [91.74366351462741, 27.442853010105477],
                            [91.59505352446729, 27.557262710287986],
                            [91.63193359375012, 27.759960937499983],
                            [91.64189453125002, 27.923242187500023],
                            [91.36259958579089, 28.02438066407592],
                            [91.27304687500012, 28.078369140625],
                            [91.22587890625007, 28.071240234374983],
                            [91.07773437500012, 27.974462890624977],
                            [91.02080078125002, 27.970068359374977],
                            [90.71572265625, 28.071728515624983],
                            [90.63007812500004, 28.078564453124955],
                            [90.47734375000007, 28.07084960937499],
                            [90.3527343750001, 28.080224609375023],
                            [90.33310546875012, 28.093994140625],
                            [90.36298828125004, 28.21650390625001],
                            [90.34824218750006, 28.24394531249999],
                            [90.22080078125006, 28.27773437500005],
                            [90.10449218749997, 28.302050781250017],
                            [89.98105468750006, 28.311181640625023],
                            [89.8978515625, 28.29414062500001],
                            [89.81689453125003, 28.25629882812501],
                            [89.74980468750002, 28.18818359375001],
                            [89.65273437500005, 28.158300781250034],
                            [89.53691406250007, 28.10742187499997],
                            [89.4806640625001, 28.059960937499994],
                            [88.89140625000002, 27.316064453124966],
                            [88.83251953125003, 27.36284179687499],
                            [88.7648437500001, 27.429882812499983],
                            [88.74902343749997, 27.521875],
                            [88.82988281250002, 27.76738281249999],
                            [88.84882812500004, 27.86865234375],
                            [88.80371093750003, 28.006933593750034],
                            [88.57792968750002, 28.093359375000034],
                            [88.42597656250004, 28.01166992187501],
                            [88.27519531250007, 27.968847656250006],
                            [88.14111328125003, 27.94892578125001],
                            [88.10898437500006, 27.933007812499966],
                            [88.10976562500005, 27.870605468750057],
                            [87.8607421875, 27.886083984375006],
                            [87.62255859374997, 27.81518554687503],
                            [87.29072265625004, 27.821923828124994],
                            [87.14140625000002, 27.838330078124955],
                            [87.02011718750006, 27.928662109374983],
                            [86.9337890625001, 27.96845703125001],
                            [86.84238281250012, 27.99916992187505],
                            [86.750390625, 28.022070312500006],
                            [86.71962890625005, 28.070654296875034],
                            [86.69052734375006, 28.09492187500001],
                            [86.61445312500004, 28.10302734374997],
                            [86.55449218750007, 28.08520507812497],
                            [86.51689453125007, 27.963525390624966],
                            [86.40869140625003, 27.928662109374983],
                            [86.32861328124997, 27.95952148437496],
                            [86.2179687500001, 28.022070312500006],
                            [86.13701171875002, 28.114355468750063],
                            [86.07871093750006, 28.08359375],
                            [86.0641601562501, 27.934716796874966],
                            [85.99453125000005, 27.910400390625],
                            [85.95410156249997, 27.92822265624997],
                            [85.92167968750002, 27.989697265624983],
                            [85.84023437500005, 28.135351562499977],
                            [85.75947265625004, 28.220654296874955],
                            [85.67832031250012, 28.277441406249977],
                            [85.41064453125003, 28.27602539062505],
                            [85.21210937500004, 28.292626953124966],
                            [85.1224609375, 28.315966796875017],
                            [85.08857421875004, 28.37226562500001],
                            [85.121484375, 28.484277343750023],
                            [85.16015624999997, 28.571875],
                            [85.15908203125, 28.592236328124983],
                            [85.1263671875, 28.602636718750063],
                            [85.06914062500007, 28.60966796874999],
                            [84.85507812500006, 28.553613281250023],
                            [84.796875, 28.560205078125023],
                            [84.2287109375001, 28.911767578124966],
                            [84.17558593750002, 29.036376953125057],
                            [84.12783203125005, 29.15629882812496],
                            [84.10136718750002, 29.21997070312497],
                            [84.02197265624997, 29.25385742187504],
                            [83.93593750000005, 29.27949218750001],
                            [83.58349609375003, 29.18359375000003],
                            [83.15546875000004, 29.612646484375034],
                            [82.22070312500003, 30.063867187500023],
                            [82.04335937500005, 30.326757812500034],
                            [81.8548828125, 30.362402343750006],
                            [81.64189453125007, 30.3875],
                            [81.4171875000001, 30.33759765625001],
                            [81.25507812500004, 30.09331054687499],
                            [81.17714843750005, 30.039892578125034],
                            [80.98544921875006, 30.23710937499999],
                            [80.87353515625003, 30.290576171875045],
                            [80.19121093750002, 30.56840820312496],
                            [80.20712890625006, 30.683740234375023],
                            [79.92451171875004, 30.888769531250034],
                            [79.66425781250004, 30.96523437499999],
                            [79.38847656250007, 31.064208984375],
                            [79.10712890625004, 31.402636718750017],
                            [78.74355468750005, 31.323779296875017],
                            [78.7550781250001, 31.55029296875],
                            [78.69345703125006, 31.740380859374994],
                            [78.72558593750003, 31.983789062500023],
                            [78.49589843750002, 32.21577148437504],
                            [78.4552734375001, 32.30034179687502],
                            [78.41748046874997, 32.466699218749994],
                            [78.38964843749997, 32.51987304687498],
                            [78.73671875, 32.55839843750002],
                            [78.75351562500012, 32.49926757812506],
                            [78.91894531249997, 32.35820312500002],
                            [79.16992187500003, 32.497216796874994],
                            [79.14550781250003, 33.00146484375006],
                            [79.10283203125007, 33.05253906249996],
                            [79.13515625000005, 33.17192382812496],
                            [79.1125, 33.22626953125001],
                            [78.94843750000004, 33.346533203125006],
                            [78.86503906250002, 33.43110351562501],
                            [78.78378906250006, 33.80878906250004],
                            [78.72666015625006, 34.013378906249955],
                            [78.97060546875, 34.22822265625004],
                            [78.93642578125, 34.35195312500002],
                            [78.86484375000006, 34.39033203125001],
                            [78.32695312500007, 34.60639648437498],
                            [78.15849609375002, 34.94648437499998],
                            [78.07578125000006, 35.13491210937502],
                            [78.0426757812501, 35.47978515625002],
                            [77.79941406250006, 35.49589843750002],
                            [77.44648437500004, 35.47558593750006],
                            [77.29482421875005, 35.508154296875034],
                            [77.09003906250004, 35.55205078124999],
                            [76.87890625000003, 35.61328125000003],
                            [76.76689453125002, 35.661718750000034],
                            [76.72753906250003, 35.67866210937504],
                            [76.63183593749997, 35.729394531249966],
                            [76.56347656249997, 35.77299804687499],
                            [76.55126953124997, 35.887060546875034],
                            [76.50205078125006, 35.87822265625002],
                            [76.38574218750003, 35.837158203125],
                            [76.25166015625004, 35.8109375],
                            [76.17783203125012, 35.810546875],
                            [76.14785156250005, 35.82900390625002],
                            [76.07089843750006, 35.983007812500034],
                            [75.91230468750004, 36.048974609374994],
                            [75.97441406250007, 36.38242187500006],
                            [75.9518554687501, 36.458105468750034],
                            [75.9330078125, 36.52158203124998],
                            [75.840234375, 36.64970703124999],
                            [75.7721679687501, 36.694921875000034],
                            [75.6671875000001, 36.741992187500045],
                            [75.57373046874997, 36.75932617187502],
                            [75.46025390625002, 36.725048828124955],
                            [75.42421875000005, 36.73823242187498],
                            [75.37685546875, 36.88369140625005],
                            [75.34667968749997, 36.913476562499966],
                            [75.05390625000004, 36.98715820312498],
                            [74.94912109375, 36.96835937500006],
                            [74.88925781250006, 36.95244140625002],
                            [74.69218750000007, 37.035742187500006],
                            [74.60058593749997, 37.03666992187502],
                            [74.54140625, 37.02216796875001],
                            [74.52646484375006, 37.03066406250005],
                            [74.49794921875, 37.057226562500034],
                            [74.37617187500004, 37.13735351562502],
                            [74.37216796875006, 37.15771484375],
                            [74.558984375, 37.23662109374999],
                            [74.66894531250003, 37.266699218750006],
                            [74.72666015625006, 37.29072265625001],
                            [74.7389648437501, 37.28564453125003],
                            [74.76738281250002, 37.249169921874966],
                            [74.840234375, 37.22504882812504],
                            [74.89130859375004, 37.231640624999955],
                            [75.11875, 37.38569335937498],
                            [74.8942382812501, 37.60141601562498],
                            [74.81230468750002, 38.46030273437498],
                            [74.27744140625, 38.659765625000034],
                            [74.02558593750004, 38.53984375000002],
                            [73.80166015625, 38.60688476562501],
                            [73.69609375000007, 38.85429687499996],
                            [73.8052734375, 38.968652343749994],
                            [73.60732421875, 39.229199218749955],
                            [73.63632812500006, 39.396679687499955],
                            [73.63164062500007, 39.44887695312502],
                            [73.82294921875004, 39.48896484375004],
                            [73.90712890625, 39.578515624999966],
                            [73.9146484375, 39.60649414062499],
                            [73.88251953125004, 39.71455078124998],
                            [73.83974609375005, 39.76284179687505],
                            [73.8353515625, 39.800146484375006],
                            [73.85625, 39.828662109375045],
                            [73.88457031250002, 39.87792968750006],
                            [73.93876953125002, 39.97880859374999],
                            [73.99160156250005, 40.04311523437502],
                            [74.83046875, 40.32851562499999],
                            [74.80126953124997, 40.428515625000045],
                            [74.83515625000004, 40.482617187499955],
                            [74.865625, 40.493505859375034],
                            [75.0044921875, 40.44951171874996],
                            [75.11132812499997, 40.4541015625],
                            [75.24101562500002, 40.48027343750002],
                            [75.52080078125002, 40.627539062500006],
                            [75.55556640625, 40.625195312499955],
                            [75.6771484375, 40.305810546874994],
                            [75.87197265625, 40.30322265625],
                            [76.25830078124997, 40.43076171875006],
                            [76.3185546875001, 40.352246093749955],
                            [76.39638671875005, 40.389794921874966],
                            [76.4801757812501, 40.44951171874996],
                            [76.57792968750002, 40.577880859375],
                            [76.62216796875006, 40.66235351562497],
                            [76.6398437500001, 40.74223632812499],
                            [76.66113281249997, 40.77963867187498],
                            [76.70839843750005, 40.818115234375],
                            [76.82402343750002, 40.982324218749966],
                            [76.90771484374997, 41.02416992187497],
                            [76.98662109375002, 41.039160156250006],
                            [77.58173828125004, 40.99277343750006],
                            [77.71933593750012, 41.024316406249994],
                            [77.81523437500002, 41.05561523437498],
                            [77.9564453125, 41.05068359375005],
                            [78.1234375, 41.07563476562498],
                            [78.34628906250012, 41.28144531249998],
                            [78.36240234375012, 41.37163085937496],
                            [78.44287109374997, 41.41752929687499],
                            [78.742578125, 41.56005859375],
                            [79.29355468750006, 41.78281249999998],
                            [79.76611328124997, 41.89887695312501],
                            [79.84042968750012, 41.99575195312502],
                            [79.90966796875003, 42.014990234375034],
                            [80.21621093750005, 42.03242187500004],
                            [80.23515625000007, 42.04345703124997],
                            [80.24619140625012, 42.05981445312503],
                            [80.209375, 42.190039062500006],
                            [80.20224609375012, 42.73447265624998],
                            [80.53896484375005, 42.873486328124955],
                            [80.39023437500006, 43.043115234374966],
                            [80.78574218750006, 43.16157226562504],
                            [80.35527343750002, 44.09726562500006],
                            [80.48154296875006, 44.71464843749999],
                            [79.871875, 44.88378906249997],
                            [80.05917968750012, 45.006445312500006],
                            [81.69199218750012, 45.34936523437497],
                            [81.94492187500006, 45.16083984375001],
                            [82.26660156249997, 45.21909179687498],
                            [82.52148437500003, 45.12548828125],
                            [82.61162109375007, 45.424267578124955],
                            [82.31523437500002, 45.59492187499998],
                            [83.02949218750004, 47.18593750000002],
                            [84.016015625, 46.97050781250002],
                            [84.66660156250006, 46.97236328125004],
                            [84.78613281249997, 46.83071289062505],
                            [85.484765625, 47.06352539062496],
                            [85.65664062500005, 47.254638671875],
                            [85.52597656250006, 47.915625],
                            [85.7494140625, 48.38505859374999],
                            [86.54941406250012, 48.52861328125002],
                            [86.8083007812501, 49.04970703125002],
                            [87.32285156250012, 49.085791015625006],
                            [87.41669921875004, 49.07661132812501],
                            [87.5158203125001, 49.122412109375006],
                            [87.7625, 49.16582031249996],
                            [87.81425781250002, 49.162304687499955],
                            [87.87216796875012, 49.000146484374966],
                            [87.74316406250003, 48.88164062499999],
                            [87.83183593750007, 48.79165039062505],
                            [88.02792968750006, 48.735595703125],
                            [88.06005859375003, 48.707177734374966],
                            [87.9796875000001, 48.55512695312498],
                            [88.30996093750005, 48.47207031250002],
                            [88.41396484375, 48.403417968750006],
                            [88.51708984374997, 48.384472656249955],
                            [88.56679687500005, 48.31743164062496],
                            [88.57597656250007, 48.220166015624955],
                            [88.68183593750004, 48.170556640624994],
                            [88.83828125000005, 48.101708984374994],
                            [88.91777343750007, 48.089013671874966],
                            [89.04765625000007, 48.002539062500034],
                            [89.47919921875004, 48.02905273437503],
                            [89.5609375, 48.00395507812496],
                            [89.778125, 47.82700195312498],
                            [89.83134765625002, 47.82329101562502],
                            [89.91044921875007, 47.844335937500034],
                            [89.95869140625004, 47.88632812499998],
                            [90.02792968750012, 47.877685546875],
                            [90.1032226562501, 47.74541015624996],
                            [90.19101562500012, 47.70209960937501],
                            [90.31328125000007, 47.67617187499999],
                            [90.33066406250006, 47.655175781249966],
                            [90.42519531250005, 47.50410156250001],
                            [90.49619140625012, 47.28515625],
                            [90.64335937500007, 47.10029296874998],
                            [90.71552734375004, 47.00385742187498],
                            [90.7990234375001, 46.98515624999999],
                            [90.86992187500002, 46.95449218750005],
                            [90.91054687500005, 46.88325195312501],
                            [90.9857421875, 46.7490234375],
                            [90.9115234375, 46.270654296874994],
                            [90.94755859375002, 46.17729492187499],
                            [90.99677734375004, 46.10498046875],
                            [91.00175781250007, 46.03579101562502],
                            [90.6618164062501, 45.525244140625006],
                            [90.87724609375002, 45.19609375000002],
                            [91.05, 45.217431640624994],
                            [91.584375, 45.07651367187498],
                            [92.42382812499997, 45.008935546874994],
                            [92.57890625000002, 45.01098632812506],
                            [92.78789062500007, 45.035742187500034],
                            [93.51621093750012, 44.944482421874994],
                            [94.71201171875012, 44.35083007812503],
                            [95.35029296875004, 44.27807617187503],
                            [95.32558593750005, 44.03935546874999],
                            [95.52558593750004, 43.953955078125006],
                            [95.85957031250004, 43.27597656249998],
                            [96.38544921875004, 42.72036132812502],
                            [97.20566406250012, 42.78979492187506],
                            [99.46787109375012, 42.568212890625034],
                            [99.98378906250005, 42.67734375000006],
                            [100.08632812500005, 42.67075195312506],
                            [100.51904296875003, 42.61679687499998],
                            [101.09199218750004, 42.55131835937496],
                            [101.49531250000004, 42.53876953124998],
                            [101.57910156249997, 42.52353515624998],
                            [101.65996093750002, 42.50004882812499],
                            [101.97294921875002, 42.21586914062502],
                            [102.15664062500005, 42.158105468749966],
                            [102.57519531249997, 42.09208984375002],
                            [103.07285156250006, 42.00595703125006],
                            [103.7111328125001, 41.75131835937506],
                            [103.99726562500004, 41.796972656250034],
                            [104.30517578124997, 41.84614257812501],
                            [104.49824218750004, 41.87700195312499],
                            [104.49824218750004, 41.65869140625],
                            [104.86035156250003, 41.64375],
                            [104.98203125000012, 41.59550781250002],
                            [105.05058593750002, 41.61591796875001],
                            [105.1154296875001, 41.66328124999998],
                            [105.19707031250002, 41.738037109375],
                            [105.31435546875005, 41.77089843750005],
                            [105.86757812500005, 41.993994140625034],
                            [106.77001953125003, 42.28872070312502],
                            [108.17119140625002, 42.44731445312502],
                            [108.68730468750002, 42.416113281250034],
                            [109.33984374999997, 42.43837890625005],
                            [109.44316406250002, 42.455957031249994],
                            [110.40039062499997, 42.77368164062497],
                            [111.00722656250005, 43.34140624999998],
                            [111.878125, 43.68017578125],
                            [111.93173828125012, 43.81494140625],
                            [111.40224609375005, 44.367285156250006],
                            [111.89804687500006, 45.064062500000034],
                            [112.03261718750005, 45.08164062500006],
                            [112.11289062500006, 45.06293945312498],
                            [112.41132812500004, 45.05820312499998],
                            [112.49931640625002, 45.01093750000004],
                            [112.59677734375006, 44.917675781249955],
                            [112.7067382812501, 44.883447265624994],
                            [113.04941406250006, 44.81035156250002],
                            [113.3009765625001, 44.79165039062502],
                            [113.50791015625006, 44.76235351562502],
                            [113.58701171875006, 44.745703125],
                            [113.65263671875002, 44.76347656249999],
                            [113.87705078125012, 44.89619140625001],
                            [114.03027343749997, 44.942578124999955],
                            [114.08027343750004, 44.97114257812501],
                            [114.41914062500004, 45.20258789062501],
                            [114.56015625000012, 45.38999023437498],
                            [114.73876953124997, 45.41962890624998],
                            [114.91923828125007, 45.378271484375006],
                            [115.16259765624997, 45.390234375000034],
                            [115.6810546875, 45.45825195312503],
                            [116.19765625, 45.739355468750006],
                            [116.240625, 45.795996093750006],
                            [116.22910156250012, 45.84575195312502],
                            [116.21298828125012, 45.88691406249998],
                            [116.56259765625012, 46.28979492187497],
                            [116.85908203125004, 46.387939453125],
                            [117.3333984375, 46.36201171875004],
                            [117.35693359375003, 46.391308593749955],
                            [117.35634765625, 46.436669921874966],
                            [117.39218750000012, 46.53759765625003],
                            [117.40556640625007, 46.57089843750006],
                            [117.43808593750012, 46.58623046874999],
                            [117.546875, 46.58828125000005],
                            [117.74121093749997, 46.51816406250006],
                            [118.07128906249997, 46.666601562500006],
                            [118.15683593750006, 46.678564453125034],
                            [118.30869140625012, 46.71704101562497],
                            [118.40439453125006, 46.70317382812499],
                            [118.58046875, 46.69189453125],
                            [118.64873046875002, 46.70166015625006],
                            [118.72294921875007, 46.69189453125],
                            [118.8439453125001, 46.76020507812498],
                            [118.95712890625006, 46.73486328124997],
                            [119.16210937499997, 46.638671875],
                            [119.33183593750002, 46.61381835937499],
                            [119.47402343750005, 46.626660156249955],
                            [119.62021484375006, 46.60395507812504],
                            [119.70664062500006, 46.60600585937502],
                            [119.74746093750005, 46.62719726562497],
                            [119.86718750000003, 46.67216796874999],
                            [119.89785156250005, 46.857812499999966],
                            [119.71113281250004, 47.15],
                            [119.08193359375, 47.654150390625034],
                            [119.01757812500003, 47.68535156249999],
                            [118.88027343750005, 47.72509765625],
                            [118.75996093750004, 47.75761718749996],
                            [118.69052734375012, 47.822265625],
                            [118.56777343750005, 47.94326171875005],
                            [118.49843750000005, 47.98398437499998],
                            [117.76835937500002, 47.98789062499998],
                            [117.3507812500001, 47.65219726562498],
                            [117.28593750000002, 47.666357421875034],
                            [117.06972656250005, 47.80639648437506],
                            [116.95166015624997, 47.836572265624966],
                            [116.90117187500007, 47.85307617187496],
                            [116.76054687500002, 47.869775390624994],
                            [116.65195312500012, 47.86450195312497],
                            [116.51347656250007, 47.839550781249955],
                            [116.37822265625002, 47.84404296874999],
                            [116.31718750000002, 47.85986328125],
                            [116.2311523437501, 47.85820312500002],
                            [116.07480468750012, 47.78955078125],
                            [115.99384765625004, 47.71132812500005],
                            [115.89824218750002, 47.68691406250005],
                            [115.6164062500001, 47.874804687500045],
                            [115.52509765625004, 48.13085937499997],
                            [115.63945312500007, 48.18623046874998],
                            [115.785546875, 48.24824218750001],
                            [115.7965820312501, 48.346337890624994],
                            [115.7916992187501, 48.455712890624994],
                            [115.8205078125001, 48.57724609375006],
                            [116.6833007812501, 49.82377929687499],
                            [117.8734375, 49.51347656250002],
                            [118.4515625, 49.84448242187503],
                            [119.25986328125012, 50.06640625000003],
                            [119.34628906250012, 50.278955078124994],
                            [119.16367187500006, 50.40600585937503],
                            [120.06689453125003, 51.60068359375006],
                            [120.74980468750007, 52.096533203125006],
                            [120.65615234375, 52.56665039062503],
                            [120.0675781250001, 52.632910156250034],
                            [120.09453125000007, 52.787207031250034],
                            [120.98544921875012, 53.28457031250002],
                            [123.6078125, 53.546533203124994],
                            [124.81230468750002, 53.133837890625045],
                            [125.075, 53.20366210937496],
                            [125.64902343750012, 53.042285156250045],
                            [126.34169921875, 52.36201171875001],
                            [126.92480468749997, 51.10014648437496],
                            [127.30703125000005, 50.70795898437501],
                            [127.33720703125007, 50.35014648437502],
                            [127.590234375, 50.20898437500003],
                            [127.55078124999997, 49.801806640625045],
                            [127.99960937500006, 49.56860351562506],
                            [128.70400390625, 49.60014648437499],
                            [129.0651367187501, 49.374658203124966],
                            [129.49814453125012, 49.38881835937502],
                            [130.1959960937501, 48.89165039062499],
                            [130.553125, 48.861181640625006],
                            [130.5521484375, 48.602490234374955],
                            [130.80429687500012, 48.34150390624998],
                            [130.7326171875001, 48.01923828124998],
                            [130.96191406249997, 47.70932617187498],
                            [132.47626953125004, 47.714990234374994],
                            [132.7072265625001, 47.94726562500006],
                            [133.14404296875003, 48.10566406249998],
                            [133.46835937500006, 48.09716796875003],
                            [134.29335937500005, 48.37343750000002],
                            [134.66523437500004, 48.25390625],
                            [134.56601562500006, 48.02250976562502],
                            [134.75234375, 47.71542968749998],
                            [134.1676757812501, 47.30219726562501],
                            [133.86132812500003, 46.24775390625004],
                            [133.43642578125, 45.60468750000004],
                            [133.18603515625003, 45.49482421875004],
                            [133.1134765625001, 45.130712890625006],
                            [132.93603515624997, 45.029931640624994],
                            [131.85185546875002, 45.32685546875001],
                            [131.44687500000012, 44.984033203124966],
                            [130.9816406250001, 44.844335937500034],
                            [131.2552734375, 44.07158203124999],
                            [131.25732421875003, 43.378076171874994],
                            [131.06855468750004, 42.90224609375005],
                            [130.42480468749997, 42.72705078124997],
                            [130.52695312500012, 42.535400390625],
                        ],
                    ],
                    [
                        [
                            [113.9977539062501, 22.210498046875045],
                            [113.83886718749997, 22.24169921875003],
                            [114.04394531250003, 22.33339843750005],
                            [113.9977539062501, 22.210498046875045],
                        ],
                    ],
                    [
                        [
                            [114.01542968750007, 22.51191406250001],
                            [114.26601562500005, 22.540966796874983],
                            [114.26796875, 22.295556640624966],
                            [113.93730468750002, 22.364990234375],
                            [114.01542968750007, 22.51191406250001],
                        ],
                    ],
                    [],
                    [
                        [
                            [118.4074218750001, 24.522119140624994],
                            [118.43271484375006, 24.414355468750074],
                            [118.29511718750004, 24.436328125000017],
                            [118.4074218750001, 24.522119140624994],
                        ],
                    ],
                    [
                        [
                            [121.00878906249997, 22.62036132812497],
                            [120.83984375000003, 21.925],
                            [120.2328125, 22.71791992187505],
                            [120.0724609375001, 23.149755859375006],
                            [120.13212890625007, 23.652929687500034],
                            [121.040625, 25.032812500000034],
                            [121.59365234375, 25.275341796874983],
                            [121.92900390625002, 24.973730468749977],
                            [121.39746093750003, 23.172509765625023],
                            [121.00878906249997, 22.62036132812497],
                        ],
                    ],
                ],
            },
            properties: {
                name: "中国",
                childNum: 15,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-5.262304687499977, 10.319677734374991],
                        [-4.72177734374992, 9.756542968750026],
                        [-4.625830078125006, 9.713574218749969],
                        [-4.526611328124943, 9.723486328125034],
                        [-4.406201171874926, 9.647998046875031],
                        [-4.332226562499955, 9.645703125],
                        [-4.18115234375, 9.78173828125],
                        [-3.790625, 9.917187499999983],
                        [-3.581152343749977, 9.924316406250014],
                        [-3.289697265625023, 9.882226562500051],
                        [-3.223535156249937, 9.895458984374997],
                        [-3.160693359374932, 9.849169921874974],
                        [-3.095800781249949, 9.752099609375009],
                        [-3.042626953124937, 9.72089843750004],
                        [-2.988281249999972, 9.687353515624963],
                        [-2.900878906249943, 9.534619140625026],
                        [-2.875146484374937, 9.500927734374997],
                        [-2.816748046874949, 9.425830078124974],
                        [-2.766601562499943, 9.424707031250009],
                        [-2.7171875, 9.457128906250048],
                        [-2.695849609374989, 9.481347656250009],
                        [-2.686132812499977, 9.43173828125002],
                        [-2.705761718749983, 9.351367187499989],
                        [-2.74692382812492, 9.04511718750004],
                        [-2.689892578124955, 9.02509765625004],
                        [-2.649218750000017, 8.956591796875031],
                        [-2.600390625000017, 8.800439453125023],
                        [-2.505859375000028, 8.208740234375],
                        [-2.538281249999955, 8.171630859374986],
                        [-2.61171875, 8.147558593749963],
                        [-2.619970703125006, 8.12109375],
                        [-2.600976562499937, 8.082226562499983],
                        [-2.613378906249977, 8.046679687500017],
                        [-2.668847656249994, 8.022216796875014],
                        [-2.789746093749955, 7.931933593750003],
                        [-2.959082031249977, 7.454541015624997],
                        [-3.227148437499977, 6.749121093749991],
                        [-2.998291015624972, 5.711328125000051],
                        [-2.793652343749955, 5.600097656250028],
                        [-2.754980468749977, 5.432519531249994],
                        [-2.815673828125, 5.153027343749997],
                        [-3.168701171874972, 5.203027343749966],
                        [-3.199951171874943, 5.3544921875],
                        [-3.347558593749994, 5.13066406249996],
                        [-4.120166015625017, 5.309716796875023],
                        [-4.60888671875, 5.235888671875003],
                        [-4.037207031249977, 5.23012695312498],
                        [-4.899707031249932, 5.138330078125023],
                        [-5.282373046874994, 5.210253906250017],
                        [-5.36752929687492, 5.15078125],
                        [-5.061816406249989, 5.13066406249996],
                        [-5.913769531249926, 5.0109375],
                        [-7.544970703124989, 4.351318359375],
                        [-7.574658203124983, 4.572314453124989],
                        [-7.585058593749977, 4.916748046875],
                        [-7.39990234375, 5.550585937499989],
                        [-7.454394531249989, 5.841308593749972],
                        [-7.636132812499994, 5.90771484375],
                        [-7.730371093749994, 5.919042968749991],
                        [-7.800927734374994, 6.038916015624991],
                        [-7.833251953125, 6.076367187499983],
                        [-7.855517578125017, 6.150146484375],
                        [-7.888623046875011, 6.234863281250028],
                        [-7.981591796874937, 6.2861328125],
                        [-8.287109375, 6.31904296875004],
                        [-8.587890625, 6.490527343749989],
                        [-8.324511718749989, 6.920019531249991],
                        [-8.408740234374989, 7.411816406249997],
                        [-8.429980468749989, 7.601855468749989],
                        [-8.351757812499926, 7.590576171875],
                        [-8.231884765624955, 7.556738281250034],
                        [-8.205957031249994, 7.590234375000023],
                        [-8.115429687499926, 7.760742187500028],
                        [-8.126855468749937, 7.867724609374974],
                        [-8.00986328124992, 8.078515625000023],
                        [-8.048583984375, 8.169726562500045],
                        [-8.140625, 8.181445312500031],
                        [-8.217138671874949, 8.219677734375011],
                        [-8.256103515625, 8.253710937500017],
                        [-8.244140624999943, 8.407910156249983],
                        [-8.236962890624994, 8.455664062500034],
                        [-7.953125, 8.477734375],
                        [-7.823583984374977, 8.467675781249994],
                        [-7.738964843749983, 8.375244140624986],
                        [-7.696093749999932, 8.375585937499977],
                        [-7.71958007812492, 8.643017578125011],
                        [-7.950976562499989, 8.786816406249997],
                        [-7.938183593749983, 8.97978515624996],
                        [-7.902099609375, 9.017089843750014],
                        [-7.777978515624937, 9.080859375000031],
                        [-7.799804687499943, 9.115039062499989],
                        [-7.839404296875017, 9.151611328124972],
                        [-7.918066406249949, 9.188525390625031],
                        [-7.896191406249955, 9.415869140624991],
                        [-8.136962890624972, 9.49570312499999],
                        [-8.155175781249937, 9.973193359375017],
                        [-7.990625, 10.1625],
                        [-7.661132812500028, 10.427441406250011],
                        [-7.385058593749989, 10.340136718749989],
                        [-7.01708984375, 10.143261718750026],
                        [-6.950341796874994, 10.342333984374989],
                        [-6.693261718750023, 10.34946289062502],
                        [-6.669335937499937, 10.39218750000002],
                        [-6.69199218749992, 10.512011718750017],
                        [-6.686132812499977, 10.578027343750051],
                        [-6.676367187499949, 10.633789062500043],
                        [-6.654150390624949, 10.65644531250004],
                        [-6.482617187499983, 10.561230468749997],
                        [-6.250244140625, 10.717919921875037],
                        [-6.190673828124943, 10.400292968749994],
                        [-6.192626953124972, 10.369433593750003],
                        [-6.241308593749949, 10.279199218750009],
                        [-6.238378906249977, 10.26162109374998],
                        [-6.117187499999972, 10.201904296874986],
                        [-6.034570312499937, 10.194824218750057],
                        [-5.907568359375006, 10.307226562500034],
                        [-5.896191406249983, 10.354736328125028],
                        [-5.843847656249977, 10.389550781250023],
                        [-5.694287109374983, 10.433203125000034],
                        [-5.556591796874983, 10.439941406249986],
                        [-5.382275390625011, 10.314013671875003],
                        [-5.262304687499977, 10.319677734374991],
                    ],
                ],
            },
            properties: {
                name: "科特迪瓦",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [15.480078125, 7.523779296874991],
                        [15.206738281250011, 7.206152343749991],
                        [14.7392578125, 6.27978515625],
                        [14.43115234375, 6.038720703124994],
                        [14.616894531250011, 5.865136718749994],
                        [14.56298828125, 5.279931640624994],
                        [14.73125, 4.602392578124991],
                        [15.063574218750006, 4.284863281249997],
                        [15.128710937500017, 3.826904296875],
                        [16.0634765625, 2.90859375],
                        [16.183398437500017, 2.270068359374989],
                        [16.059375, 1.676220703124997],
                        [15.741601562500023, 1.914990234374997],
                        [14.902441406250006, 2.012304687499991],
                        [14.578906250000017, 2.199121093749994],
                        [13.293554687500006, 2.161572265624997],
                        [13.2203125, 2.256445312499991],
                        [11.558984375000023, 2.302197265624997],
                        [11.348437500000017, 2.299707031249994],
                        [11.328710937500006, 2.167431640624997],
                        [11.096582031250023, 2.16748046875],
                        [10.790917968750023, 2.16757812499999],
                        [9.979882812500023, 2.167773437499989],
                        [9.8701171875, 2.21328125],
                        [9.8369140625, 2.242382812499997],
                        [9.830371093750017, 2.275488281249991],
                        [9.826171875, 2.297802734374997],
                        [9.80078125, 2.304443359375],
                        [9.82177734375, 2.539257812499997],
                        [9.948437500000011, 3.079052734374997],
                        [9.672070312500011, 3.53759765625],
                        [9.765722656250006, 3.623828124999989],
                        [9.642382812500017, 3.611767578124997],
                        [9.55615234375, 3.798046875],
                        [9.739648437500023, 3.852929687499994],
                        [9.639941406250017, 3.96533203125],
                        [9.688867187500023, 4.056396484375],
                        [9.483691406250017, 4.066113281249997],
                        [9.42529296875, 3.922314453124997],
                        [9.000097656250006, 4.091601562499989],
                        [8.918261718750017, 4.553759765624989],
                        [8.660351562500011, 4.670996093749991],
                        [8.65625, 4.516357421875],
                        [8.53955078125, 4.571875],
                        [8.715625, 5.046875],
                        [8.997167968750006, 5.917724609375],
                        [9.490234375, 6.418652343749997],
                        [9.779882812500006, 6.76015625],
                        [9.820703125000023, 6.783935546875],
                        [9.874218750000011, 6.803271484374989],
                        [10.038867187500017, 6.92138671875],
                        [10.1435546875, 6.996435546874991],
                        [10.167773437500017, 6.959179687499997],
                        [10.185546875, 6.912792968749997],
                        [10.205468750000023, 6.8916015625],
                        [10.293066406250006, 6.876757812499989],
                        [10.413183593750006, 6.877734374999989],
                        [10.60625, 7.063085937499991],
                        [10.954199218750006, 6.7765625],
                        [11.032519531250017, 6.697900390624994],
                        [11.1064453125, 6.457714843749997],
                        [11.1533203125, 6.437939453124997],
                        [11.2373046875, 6.450537109374991],
                        [11.401757812500023, 6.533935546875],
                        [11.551660156250023, 6.697265625],
                        [11.580078125, 6.888867187499997],
                        [11.657519531250017, 6.9515625],
                        [11.861425781250006, 7.11640625],
                        [11.767382812500017, 7.272265624999989],
                        [11.809179687500006, 7.345068359374991],
                        [12.016015625000023, 7.589746093749994],
                        [12.2333984375, 8.282324218749991],
                        [12.403515625000011, 8.595556640624991],
                        [12.582714843750011, 8.624121093749991],
                        [12.651562500000011, 8.667773437499989],
                        [12.7822265625, 8.81787109375],
                        [12.806542968750023, 8.886621093749994],
                        [12.875683593750011, 9.303515624999989],
                        [12.929492187500017, 9.42626953125],
                        [13.19873046875, 9.563769531249989],
                        [13.269921875000023, 10.036181640624989],
                        [13.41455078125, 10.171435546874989],
                        [13.535351562500011, 10.60507812499999],
                        [13.699902343750011, 10.873144531249991],
                        [13.89208984375, 11.140087890624997],
                        [13.9814453125, 11.211865234374997],
                        [14.056738281250006, 11.245019531249994],
                        [14.143261718750011, 11.24853515625],
                        [14.202343750000011, 11.268164062499991],
                        [14.559765625000011, 11.492285156249991],
                        [14.619726562500006, 12.150976562499991],
                        [14.518945312500023, 12.298242187499994],
                        [14.272851562500023, 12.356494140624989],
                        [14.184863281250017, 12.447216796874997],
                        [14.06396484375, 13.07851562499999],
                        [14.244824218750011, 13.07734375],
                        [14.461718750000017, 13.021777343749989],
                        [14.847070312500023, 12.502099609374994],
                        [15.08125, 11.845507812499989],
                        [15.029882812500006, 11.11367187499999],
                        [15.132226562500023, 10.648486328124989],
                        [15.276074218750011, 10.357373046874997],
                        [15.654882812500006, 10.0078125],
                        [14.243261718750006, 9.979736328125],
                        [13.977246093750011, 9.691552734374994],
                        [14.332324218750017, 9.20351562499999],
                        [15.1162109375, 8.557324218749997],
                        [15.5498046875, 7.787890624999989],
                        [15.480078125, 7.523779296874991],
                    ],
                ],
            },
            properties: {
                name: "喀麦隆",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [27.4033203125, 5.109179687499989],
                        [27.7880859375, 4.644677734374994],
                        [28.19208984375001, 4.350244140624994],
                        [28.427539062500017, 4.324169921874997],
                        [28.72705078125, 4.504980468749991],
                        [29.224902343750017, 4.391894531249989],
                        [29.469628906250023, 4.61181640625],
                        [29.676855468750006, 4.5869140625],
                        [30.194921875, 3.98193359375],
                        [30.50830078125, 3.835693359375],
                        [30.586718750000017, 3.62421875],
                        [30.757226562500023, 3.62421875],
                        [30.83857421875001, 3.49072265625],
                        [30.90644531250001, 3.408935546875],
                        [30.754003906250006, 3.041796874999989],
                        [30.8466796875, 2.847021484374991],
                        [30.728613281250006, 2.455371093749989],
                        [31.176367187500006, 2.270068359374989],
                        [31.252734375000017, 2.044580078124994],
                        [29.94287109375, 0.819238281249994],
                        [29.934472656250023, 0.4990234375],
                        [29.717675781250023, 0.098339843749997],
                        [29.576953125000017, -1.387890625000011],
                        [29.196582031250017, -1.719921875000011],
                        [29.13154296875001, -2.195117187500003],
                        [28.876367187500023, -2.400292968750009],
                        [28.893945312500023, -2.635058593750003],
                        [29.01435546875001, -2.72021484375],
                        [29.224414062500017, -3.053515625],
                        [29.211816406250023, -3.833789062500003],
                        [29.403222656250023, -4.449316406250006],
                        [29.404199218750023, -4.496679687500006],
                        [29.32568359375, -4.835644531250011],
                        [29.32343750000001, -4.898828125],
                        [29.3427734375, -4.983105468750011],
                        [29.542382812500023, -5.499804687500003],
                        [29.594140625000023, -5.65078125],
                        [29.60703125, -5.72265625],
                        [29.59638671875001, -5.775976562500006],
                        [29.490820312500006, -5.965429687500006],
                        [29.480078125, -6.025],
                        [29.50625, -6.172070312500011],
                        [29.540820312500017, -6.313867187500009],
                        [29.590625, -6.394433593750009],
                        [29.70966796875001, -6.616894531250011],
                        [29.798144531250017, -6.69189453125],
                        [29.961816406250023, -6.803125],
                        [30.10625, -6.9150390625],
                        [30.212695312500017, -7.037890625],
                        [30.31318359375001, -7.203710937500006],
                        [30.40673828125, -7.460644531250011],
                        [30.75117187500001, -8.193652343750003],
                        [28.89814453125001, -8.485449218750006],
                        [28.869531250000023, -8.785839843750011],
                        [28.400683593750017, -9.224804687500011],
                        [28.60419921875001, -9.678808593750006],
                        [28.6455078125, -10.550195312500009],
                        [28.383398437500006, -11.566699218750003],
                        [28.482519531250006, -11.812109375],
                        [29.064355468750023, -12.348828125000011],
                        [29.48554687500001, -12.41845703125],
                        [29.508203125000023, -12.228222656250011],
                        [29.79511718750001, -12.155468750000011],
                        [29.775195312500017, -13.438085937500006],
                        [29.55419921875, -13.248925781250009],
                        [29.20185546875001, -13.398339843750009],
                        [29.014257812500006, -13.368847656250011],
                        [28.730078125, -12.925488281250011],
                        [28.550878906250006, -12.836132812500011],
                        [28.412890625000017, -12.51806640625],
                        [27.573828125, -12.22705078125],
                        [27.1591796875, -11.579199218750006],
                        [26.824023437500017, -11.965234375],
                        [26.025976562500006, -11.89013671875],
                        [25.349414062500017, -11.623046875],
                        [25.28876953125001, -11.21240234375],
                        [24.3779296875, -11.417089843750006],
                        [24.36572265625, -11.1298828125],
                        [23.96650390625001, -10.871777343750011],
                        [23.901171875000017, -10.983203125],
                        [23.833886718750023, -11.013671875],
                        [23.463964843750006, -10.969335937500006],
                        [23.076269531250006, -11.087890625],
                        [22.814746093750017, -11.080273437500011],
                        [22.56103515625, -11.055859375000011],
                        [22.486132812500017, -11.08671875],
                        [22.392968750000023, -11.159472656250003],
                        [22.31494140625, -11.198632812500009],
                        [22.27880859375, -11.194140625],
                        [22.226171875, -11.121972656250009],
                        [22.203515625000023, -10.829492187500009],
                        [22.307031250000023, -10.691308593750009],
                        [22.19775390625, -10.040625],
                        [21.81318359375001, -9.46875],
                        [21.905371093750006, -8.693359375],
                        [21.806054687500023, -7.32861328125],
                        [21.751074218750006, -7.30546875],
                        [21.190332031250023, -7.284960937500003],
                        [20.910937500000017, -7.281445312500011],
                        [20.607812500000023, -7.277734375],
                        [20.558398437500017, -7.244433593750003],
                        [20.53583984375001, -7.182812500000011],
                        [20.536914062500017, -7.121777343750011],
                        [20.598730468750006, -6.93515625],
                        [20.59003906250001, -6.919921875],
                        [20.482226562500017, -6.915820312500003],
                        [20.190039062500006, -6.9462890625],
                        [19.997460937500023, -6.976464843750009],
                        [19.87519531250001, -6.986328125],
                        [19.527636718750017, -7.144433593750009],
                        [19.483789062500023, -7.279492187500011],
                        [19.479882812500023, -7.47216796875],
                        [19.371679687500006, -7.655078125],
                        [19.369921875000017, -7.70654296875],
                        [19.3408203125, -7.966601562500003],
                        [19.142675781250006, -8.00146484375],
                        [18.944433593750006, -8.00146484375],
                        [18.56269531250001, -7.9359375],
                        [18.0087890625, -8.107617187500011],
                        [17.643359375000017, -8.090722656250009],
                        [17.57958984375, -8.099023437500009],
                        [16.984765625000023, -7.257421875],
                        [16.91943359375, -6.933984375],
                        [16.813085937500006, -6.772558593750006],
                        [16.742968750000017, -6.618457031250003],
                        [16.697265625, -6.164257812500011],
                        [16.537109375, -5.9658203125],
                        [16.431445312500017, -5.900195312500003],
                        [16.315234375000017, -5.865625],
                        [13.978515625, -5.857226562500003],
                        [13.346484375000017, -5.863378906250006],
                        [13.184375, -5.85625],
                        [12.452929687500017, -6.00048828125],
                        [12.213671875000017, -5.758691406250009],
                        [12.484570312500011, -5.71875],
                        [12.451464843750017, -5.071484375000011],
                        [12.502734375000017, -5.036914062500003],
                        [12.573535156250017, -4.99658203125],
                        [12.59619140625, -4.978417968750009],
                        [12.8296875, -4.736621093750003],
                        [12.947460937500011, -4.6953125],
                        [13.057324218750011, -4.651074218750011],
                        [13.07275390625, -4.634765625],
                        [13.08740234375, -4.601953125],
                        [13.136621093750023, -4.604296875],
                        [13.414941406250023, -4.83740234375],
                        [13.659570312500023, -4.721484375],
                        [13.717089843750017, -4.454492187500009],
                        [13.94091796875, -4.484667968750003],
                        [14.358300781250023, -4.299414062500006],
                        [14.449804687500006, -4.449511718750003],
                        [14.365429687500011, -4.585546875],
                        [14.410742187500006, -4.83125],
                        [14.707910156250023, -4.881738281250009],
                        [15.990039062500017, -3.766210937500006],
                        [16.217382812500006, -3.0302734375],
                        [16.21533203125, -2.177832031250006],
                        [16.54072265625001, -1.840136718750003],
                        [16.8798828125, -1.225878906250003],
                        [17.752832031250023, -0.549023437500011],
                        [18.072167968750023, 2.01328125],
                        [18.49091796875001, 2.924414062499991],
                        [18.6103515625, 3.478417968749994],
                        [18.594140625000023, 4.346240234374989],
                        [19.06855468750001, 4.891406249999989],
                        [19.5009765625, 5.127490234374989],
                        [19.806542968750023, 5.089306640624997],
                        [20.226367187500017, 4.829638671874989],
                        [20.55810546875, 4.462695312499989],
                        [22.422167968750017, 4.134960937499997],
                        [22.864550781250017, 4.723876953125],
                        [23.41718750000001, 4.663134765624989],
                        [24.31982421875, 4.994140625],
                        [25.065234375000017, 4.967431640624994],
                        [25.52509765625001, 5.31210937499999],
                        [26.822070312500017, 5.062402343749994],
                        [27.071875, 5.199755859374989],
                        [27.4033203125, 5.109179687499989],
                    ],
                ],
            },
            properties: {
                name: "刚果金",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [18.6103515625, 3.478417968749994],
                        [18.49091796875001, 2.924414062499991],
                        [18.072167968750023, 2.01328125],
                        [17.752832031250023, -0.549023437500011],
                        [16.8798828125, -1.225878906250003],
                        [16.54072265625001, -1.840136718750003],
                        [16.21533203125, -2.177832031250006],
                        [16.217382812500006, -3.0302734375],
                        [15.990039062500017, -3.766210937500006],
                        [14.707910156250023, -4.881738281250009],
                        [14.410742187500006, -4.83125],
                        [14.365429687500011, -4.585546875],
                        [14.449804687500006, -4.449511718750003],
                        [14.358300781250023, -4.299414062500006],
                        [13.94091796875, -4.484667968750003],
                        [13.717089843750017, -4.454492187500009],
                        [13.659570312500023, -4.721484375],
                        [13.414941406250023, -4.83740234375],
                        [13.136621093750023, -4.604296875],
                        [13.08740234375, -4.601953125],
                        [13.07275390625, -4.634765625],
                        [13.048046875000011, -4.619238281250006],
                        [12.971386718750011, -4.5517578125],
                        [12.881054687500011, -4.445117187500003],
                        [12.84814453125, -4.428906250000011],
                        [12.50146484375, -4.5875],
                        [12.018359375000017, -5.004296875],
                        [11.777539062500011, -4.565820312500009],
                        [11.130175781250017, -3.916308593750003],
                        [11.234472656250006, -3.690820312500009],
                        [11.504296875000023, -3.5203125],
                        [11.685742187500011, -3.68203125],
                        [11.8798828125, -3.665917968750009],
                        [11.934179687500006, -3.318554687500011],
                        [11.715429687500006, -3.176953125000011],
                        [11.760156250000023, -2.983105468750011],
                        [11.537792968750011, -2.83671875],
                        [11.60546875, -2.342578125],
                        [12.064453125, -2.41259765625],
                        [12.446386718750006, -2.329980468750009],
                        [12.43212890625, -1.928906250000011],
                        [12.590429687500006, -1.826855468750011],
                        [12.793554687500006, -1.931835937500011],
                        [12.991992187500017, -2.313378906250009],
                        [13.464941406250006, -2.395410156250009],
                        [13.733789062500023, -2.138476562500003],
                        [13.886914062500011, -2.465429687500006],
                        [13.993847656250011, -2.490625],
                        [14.199804687500006, -2.354199218750011],
                        [14.162890625000017, -2.217578125],
                        [14.383984375000011, -1.890039062500009],
                        [14.47412109375, -0.573437500000011],
                        [13.860058593750011, -0.203320312500011],
                        [13.949609375000023, 0.353808593749989],
                        [14.32421875, 0.62421875],
                        [14.429882812500011, 0.901464843749991],
                        [14.180859375000011, 1.370214843749991],
                        [13.851367187500017, 1.41875],
                        [13.21630859375, 1.2484375],
                        [13.172167968750017, 1.78857421875],
                        [13.293554687500006, 2.161572265624997],
                        [14.578906250000017, 2.199121093749994],
                        [14.902441406250006, 2.012304687499991],
                        [15.741601562500023, 1.914990234374997],
                        [16.059375, 1.676220703124997],
                        [16.183398437500017, 2.270068359374989],
                        [16.468554687500017, 2.831738281249997],
                        [16.610742187500023, 3.50537109375],
                        [17.491601562500023, 3.687304687499989],
                        [18.160937500000017, 3.499804687499989],
                        [18.474414062500017, 3.622998046874997],
                        [18.6103515625, 3.478417968749994],
                    ],
                ],
            },
            properties: {
                name: "刚果布",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-71.31972656249997, 11.861914062500048],
                        [-71.95810546875, 11.66640625],
                        [-72.24848632812501, 11.196435546875009],
                        [-72.690087890625, 10.835839843749994],
                        [-72.86933593750001, 10.49125976562496],
                        [-73.00654296874998, 9.789160156250006],
                        [-73.36621093749997, 9.194140625000017],
                        [-73.05839843749999, 9.259570312500031],
                        [-72.79638671874997, 9.10898437499999],
                        [-72.66542968749994, 8.62758789062498],
                        [-72.39033203124995, 8.287060546874969],
                        [-72.47197265624996, 7.524267578124991],
                        [-72.20771484374995, 7.37026367187498],
                        [-72.00664062499993, 7.032617187500023],
                        [-71.12861328124993, 6.98671875],
                        [-70.73715820312503, 7.090039062499997],
                        [-70.12919921874999, 6.95361328125],
                        [-69.42714843749997, 6.123974609374997],
                        [-68.47177734375, 6.156542968749974],
                        [-67.85917968749999, 6.289892578124963],
                        [-67.48198242187499, 6.18027343750002],
                        [-67.47387695312503, 5.929980468750003],
                        [-67.82490234374995, 5.270458984375026],
                        [-67.85527343750002, 4.506884765624989],
                        [-67.66162109375, 3.864257812499986],
                        [-67.3111328125, 3.41586914062502],
                        [-67.85908203124998, 2.793603515624994],
                        [-67.61870117187496, 2.793603515624994],
                        [-67.21083984375, 2.390136718750043],
                        [-66.87602539062499, 1.223046875000037],
                        [-67.082275390625, 1.185400390625006],
                        [-67.11923828124998, 1.703613281249986],
                        [-67.40043945312499, 2.116699218750028],
                        [-67.93623046874998, 1.748486328124969],
                        [-68.19379882812495, 1.987011718749983],
                        [-68.25595703125, 1.845507812500017],
                        [-68.17656249999999, 1.719824218749991],
                        [-69.84858398437493, 1.708740234375043],
                        [-69.85214843750003, 1.05952148437504],
                        [-69.31181640624999, 1.050488281249969],
                        [-69.15332031249994, 0.65878906250002],
                        [-69.47211914062498, 0.72993164062504],
                        [-70.05390624999993, 0.578613281250028],
                        [-70.07050781249993, -0.13886718750004],
                        [-69.63398437500001, -0.50927734375],
                        [-69.40024414062498, -1.194921874999977],
                        [-69.66904296875003, -2.667675781249997],
                        [-69.94819335937498, -4.200585937500009],
                        [-69.96591796875003, -4.2359375],
                        [-70.16752929687499, -4.050195312500009],
                        [-70.24028320312496, -3.882714843749994],
                        [-70.2984375, -3.844238281249972],
                        [-70.33950195312502, -3.814355468750009],
                        [-70.73510742187497, -3.781542968749989],
                        [-70.09584960937494, -2.658203125000014],
                        [-70.16474609374995, -2.639843750000011],
                        [-70.24443359375002, -2.606542968749977],
                        [-70.29462890624995, -2.552539062499989],
                        [-70.57587890624995, -2.418261718749989],
                        [-70.64799804687499, -2.405761718750014],
                        [-70.70537109374996, -2.341992187499983],
                        [-70.91455078125003, -2.218554687499974],
                        [-70.96855468750002, -2.206835937499989],
                        [-71.02729492187498, -2.225781250000026],
                        [-71.11337890625003, -2.245410156250031],
                        [-71.19638671874998, -2.313085937499963],
                        [-71.39697265625, -2.334082031249977],
                        [-71.55947265624997, -2.224218749999977],
                        [-71.75253906249995, -2.15273437499998],
                        [-71.80273437499997, -2.166308593749989],
                        [-71.86728515624998, -2.227734374999983],
                        [-71.932470703125, -2.288671874999963],
                        [-71.98427734375, -2.326562499999952],
                        [-72.21845703125001, -2.400488281250006],
                        [-72.94111328124998, -2.394042968750028],
                        [-72.9896484375, -2.33974609374998],
                        [-73.15449218749993, -2.278222656249966],
                        [-73.19697265624995, -1.830273437500011],
                        [-73.49628906249993, -1.69306640625004],
                        [-73.66430664062497, -1.248828124999946],
                        [-73.86318359374997, -1.19667968749998],
                        [-73.92695312500001, -1.125195312499983],
                        [-73.98681640625003, -1.098144531249986],
                        [-74.05439453124995, -1.028613281250031],
                        [-74.18076171875, -0.997753906249955],
                        [-74.24638671874999, -0.970605468750023],
                        [-74.28388671874998, -0.927832031250006],
                        [-74.33442382812498, -0.85087890624996],
                        [-74.41787109375, -0.580664062499977],
                        [-74.46518554687498, -0.517675781250034],
                        [-74.51386718749993, -0.470117187500023],
                        [-74.555078125, -0.429882812499997],
                        [-74.61635742187494, -0.370019531249966],
                        [-74.691650390625, -0.335253906249989],
                        [-74.75537109375003, -0.298632812499989],
                        [-74.78046874999998, -0.24453125],
                        [-74.80175781249997, -0.200097656249994],
                        [-75.13837890624998, -0.050488281249969],
                        [-75.28447265624999, -0.10654296875002],
                        [-75.77666015624999, 0.08925781249998],
                        [-76.27060546874998, 0.439404296874997],
                        [-76.49462890624997, 0.23544921875002],
                        [-77.396337890625, 0.393896484374963],
                        [-77.46767578124997, 0.636523437500017],
                        [-77.702880859375, 0.837841796874997],
                        [-78.1806640625, 0.968554687499974],
                        [-78.85966796874996, 1.455371093750031],
                        [-79.02543945312499, 1.623681640625037],
                        [-78.79296874999994, 1.848730468749963],
                        [-78.576904296875, 1.773779296874977],
                        [-78.59169921875, 2.356640624999969],
                        [-78.41689453125, 2.483496093749963],
                        [-78.06665039062494, 2.509130859375034],
                        [-77.81357421875, 2.716357421874974],
                        [-77.076806640625, 3.913281250000026],
                        [-77.26352539062503, 3.893212890625023],
                        [-77.27802734374995, 4.058496093750023],
                        [-77.35820312499996, 3.944726562500037],
                        [-77.40874023437496, 4.24775390625004],
                        [-77.52070312499993, 4.212792968750023],
                        [-77.35351562499997, 4.398291015624977],
                        [-77.28632812499995, 4.72172851562496],
                        [-77.373291015625, 5.323974609375],
                        [-77.53442382812497, 5.537109374999986],
                        [-77.24926757812497, 5.780175781250037],
                        [-77.46943359374995, 6.176757812500014],
                        [-77.368798828125, 6.575585937499994],
                        [-77.90117187499999, 7.229345703125048],
                        [-77.76191406249995, 7.698828125000034],
                        [-77.53828124999995, 7.56625976562502],
                        [-77.19599609374995, 7.972460937500003],
                        [-77.47851562499994, 8.498437500000037],
                        [-77.37421874999993, 8.65830078125002],
                        [-76.85185546875002, 8.09047851562498],
                        [-76.924658203125, 7.973193359374974],
                        [-76.78657226562493, 7.931591796875026],
                        [-76.7720703125, 8.310546875000043],
                        [-76.92045898437496, 8.573730468750014],
                        [-76.27685546875, 8.989111328124991],
                        [-76.02724609374997, 9.365771484374989],
                        [-75.63935546874998, 9.450439453125014],
                        [-75.680029296875, 9.729785156249989],
                        [-75.53857421874997, 10.205175781250034],
                        [-75.708349609375, 10.143408203124963],
                        [-75.44599609374995, 10.610888671874989],
                        [-74.84458007812498, 11.109716796875006],
                        [-74.330224609375, 10.996679687499991],
                        [-74.51625976562497, 10.8625],
                        [-74.40087890625, 10.76523437499999],
                        [-74.14291992187503, 11.320849609375031],
                        [-73.31337890624997, 11.295751953124991],
                        [-72.275, 11.88925781250002],
                        [-72.13574218749994, 12.188574218749977],
                        [-71.71455078124993, 12.41997070312496],
                        [-71.26210937499997, 12.335302734375034],
                        [-71.13730468750003, 12.04633789062504],
                        [-71.31972656249997, 11.861914062500048],
                    ],
                ],
            },
            properties: {
                name: "哥伦比亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [43.788671875, -12.307031250000023],
                            [43.85898437500006, -12.368261718749977],
                            [43.66367187500006, -12.342871093749949],
                            [43.63134765624997, -12.247070312499972],
                            [43.788671875, -12.307031250000023],
                        ],
                    ],
                    [
                        [
                            [44.476367187500074, -12.08154296875],
                            [44.504980468750006, -12.356542968749991],
                            [44.220117187499994, -12.171386718750014],
                            [44.476367187500074, -12.08154296875],
                        ],
                    ],
                    [
                        [
                            [43.46582031249997, -11.901269531249966],
                            [43.226660156250006, -11.75185546874998],
                            [43.2990234375001, -11.374511718750028],
                            [43.39296875000005, -11.408593749999952],
                            [43.46582031249997, -11.901269531249966],
                        ],
                    ],
                ],
            },
            properties: {
                name: "科摩罗",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-24.308251953124966, 14.856298828124991],
                            [-24.44052734374992, 14.834814453124963],
                            [-24.496875, 14.980273437500017],
                            [-24.329492187499937, 15.019482421875011],
                            [-24.308251953124966, 14.856298828124991],
                        ],
                    ],
                    [
                        [
                            [-23.18212890624997, 15.136767578125017],
                            [-23.210253906250017, 15.32353515625006],
                            [-23.119335937499955, 15.26840820312502],
                            [-23.18212890624997, 15.136767578125017],
                        ],
                    ],
                    [
                        [
                            [-23.444238281249994, 15.00795898437498],
                            [-23.5046875, 14.916113281250006],
                            [-23.70537109374999, 14.96132812499998],
                            [-23.74809570312499, 15.328515625],
                            [-23.444238281249994, 15.00795898437498],
                        ],
                    ],
                    [
                        [
                            [-22.917724609375, 16.237255859374955],
                            [-22.69262695312497, 16.169042968750006],
                            [-22.710107421874994, 16.043359374999966],
                            [-22.95927734374996, 16.045117187499983],
                            [-22.917724609375, 16.237255859374955],
                        ],
                    ],
                    [
                        [
                            [-24.08769531249999, 16.62250976562501],
                            [-24.03271484374997, 16.57202148437503],
                            [-24.243066406250023, 16.599414062500017],
                            [-24.32236328124992, 16.49311523437504],
                            [-24.398095703124966, 16.61840820312497],
                            [-24.08769531249999, 16.62250976562501],
                        ],
                    ],
                    [
                        [
                            [-22.888330078124966, 16.659082031249994],
                            [-22.980615234374937, 16.700878906249983],
                            [-22.93291015624999, 16.84101562500004],
                            [-22.888330078124966, 16.659082031249994],
                        ],
                    ],
                    [
                        [
                            [-24.88706054687495, 16.81811523437497],
                            [-25.09306640624999, 16.83251953125],
                            [-24.936474609374983, 16.92211914062503],
                            [-24.88706054687495, 16.81811523437497],
                        ],
                    ],
                    [
                        [
                            [-25.169824218749994, 16.94648437500001],
                            [-25.308300781249955, 16.93583984374999],
                            [-25.337109374999955, 17.091015624999983],
                            [-25.03466796875, 17.176464843749983],
                            [-24.979687499999983, 17.09472656250003],
                            [-25.169824218749994, 16.94648437500001],
                        ],
                    ],
                ],
            },
            properties: {
                name: "佛得角",
                childNum: 8,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-83.6419921875, 10.917236328125],
                        [-83.346826171875, 10.315380859374997],
                        [-82.77841796874999, 9.66953125],
                        [-82.56357421874999, 9.57666015625],
                        [-82.56923828125, 9.55820312499999],
                        [-82.58652343749999, 9.538818359375],
                        [-82.64409179687499, 9.505859375],
                        [-82.801025390625, 9.591796875],
                        [-82.843994140625, 9.57080078125],
                        [-82.86015624999999, 9.511474609375],
                        [-82.88896484374999, 9.481005859374989],
                        [-82.925048828125, 9.469042968749989],
                        [-82.93984375, 9.449169921874997],
                        [-82.94033203125, 9.060107421874989],
                        [-82.88134765625, 9.055859375],
                        [-82.78305664062499, 8.990283203124989],
                        [-82.741162109375, 8.951708984374989],
                        [-82.72783203124999, 8.916064453124989],
                        [-82.91704101562499, 8.740332031249991],
                        [-82.855712890625, 8.635302734374989],
                        [-82.84477539062499, 8.489355468749991],
                        [-82.86162109374999, 8.45351562499999],
                        [-83.02734375, 8.337744140624991],
                        [-82.879345703125, 8.070654296874991],
                        [-83.12333984374999, 8.353076171874989],
                        [-83.16240234374999, 8.588183593749989],
                        [-83.4697265625, 8.706835937499989],
                        [-83.29150390625, 8.406005859375],
                        [-83.54375, 8.445849609374989],
                        [-83.73408203125, 8.614453125],
                        [-83.613720703125, 8.804052734374991],
                        [-83.73691406249999, 9.150292968749994],
                        [-84.58159179687499, 9.568359375],
                        [-84.71494140624999, 9.8994140625],
                        [-85.23564453124999, 10.242089843749994],
                        [-85.2365234375, 10.107373046874997],
                        [-84.88642578125, 9.820947265624994],
                        [-85.07705078125, 9.60195312499999],
                        [-85.31455078124999, 9.8109375],
                        [-85.62485351562499, 9.902441406249991],
                        [-85.84965820312499, 10.292041015624989],
                        [-85.667236328125, 10.745019531249994],
                        [-85.90800781249999, 10.897558593749991],
                        [-85.7443359375, 11.06210937499999],
                        [-85.5841796875, 11.189453125],
                        [-84.9091796875, 10.9453125],
                        [-84.6341796875, 11.045605468749997],
                        [-83.91928710937499, 10.7353515625],
                        [-83.71293945312499, 10.785888671875],
                        [-83.6419921875, 10.917236328125],
                    ],
                ],
            },
            properties: {
                name: "哥斯达黎加",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-82.56176757812503, 21.571679687500023],
                            [-82.959619140625, 21.441308593750023],
                            [-83.18378906250001, 21.59345703125004],
                            [-82.97358398437498, 21.592285156250057],
                            [-83.08251953124997, 21.791406250000023],
                            [-82.99121093750003, 21.942724609375034],
                            [-82.71455078124998, 21.890283203125023],
                            [-82.56176757812503, 21.571679687500023],
                        ],
                    ],
                    [
                        [
                            [-77.66899414062493, 21.951953125000045],
                            [-77.91855468749998, 22.088085937499983],
                            [-77.63369140624994, 22.054003906250074],
                            [-77.66899414062493, 21.951953125000045],
                        ],
                    ],
                    [
                        [
                            [-77.87939453125, 22.127539062500034],
                            [-78.04165039062502, 22.201269531250034],
                            [-77.99921874999998, 22.298730468749994],
                            [-77.87939453125, 22.127539062500034],
                        ],
                    ],
                    [
                        [
                            [-81.83745117187499, 23.163037109374955],
                            [-81.26235351562497, 23.156835937500034],
                            [-81.14462890624998, 23.054931640625057],
                            [-80.65014648437494, 23.10307617187499],
                            [-80.36489257812502, 22.943408203125074],
                            [-79.82026367187498, 22.887011718750045],
                            [-79.27568359374999, 22.407617187499994],
                            [-78.68647460937493, 22.366845703125023],
                            [-77.63681640624995, 21.79736328125],
                            [-77.49711914062502, 21.78833007812503],
                            [-77.58315429687497, 21.889257812499977],
                            [-77.49726562499995, 21.871630859375045],
                            [-77.14414062499995, 21.643603515625017],
                            [-77.36616210937498, 21.612646484375034],
                            [-77.25288085937498, 21.483496093750006],
                            [-77.0986328125, 21.589013671875023],
                            [-76.86743164062497, 21.330419921875006],
                            [-75.72294921874996, 21.111035156249983],
                            [-75.59580078125, 20.99467773437499],
                            [-75.72456054687493, 20.71455078125004],
                            [-74.882568359375, 20.65063476562497],
                            [-74.51313476562495, 20.384570312500045],
                            [-74.16748046874997, 20.292187499999955],
                            [-74.15371093750002, 20.168554687500006],
                            [-75.11640624999995, 19.901416015625017],
                            [-75.151611328125, 20.008349609375045],
                            [-75.29047851562495, 19.893115234375017],
                            [-76.15844726562497, 19.98974609374997],
                            [-77.715087890625, 19.85546874999997],
                            [-77.10380859374999, 20.407519531250017],
                            [-77.22958984374995, 20.64375],
                            [-78.11635742187497, 20.761865234374994],
                            [-78.49077148437493, 21.05371093750003],
                            [-78.72768554687497, 21.592724609374955],
                            [-79.35742187500003, 21.58515625000001],
                            [-80.23134765625, 21.872167968750063],
                            [-80.48544921874998, 22.1234375],
                            [-81.03564453124997, 22.073583984375063],
                            [-81.18549804687495, 22.26796875000005],
                            [-81.284375, 22.109423828125074],
                            [-81.84941406249993, 22.21367187499999],
                            [-82.077734375, 22.3876953125],
                            [-81.71035156250002, 22.496679687500006],
                            [-81.83881835937498, 22.672460937500034],
                            [-82.73803710937497, 22.689257812500074],
                            [-83.37963867187503, 22.222998046875034],
                            [-83.90073242187495, 22.17011718750001],
                            [-84.03095703124993, 21.94311523437503],
                            [-84.502587890625, 21.776171875000045],
                            [-84.50136718750002, 21.930273437499977],
                            [-84.88720703125003, 21.856982421875074],
                            [-84.32636718749998, 22.074316406250034],
                            [-84.36127929687498, 22.37890625],
                            [-84.04492187500003, 22.666015625000057],
                            [-83.25781249999997, 22.967578125000017],
                            [-81.83745117187499, 23.163037109374955],
                        ],
                    ],
                ],
            },
            properties: {
                name: "古巴",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-68.75107421874999, 12.059765625],
                        [-68.9951171875, 12.141845703125],
                        [-69.15888671875, 12.380273437499994],
                        [-68.75107421874999, 12.059765625],
                    ],
                ],
            },
            properties: {
                name: "CuraÃ§ao",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-81.36953124999997, 19.34887695312497],
                            [-81.10712890624995, 19.305175781250057],
                            [-81.40478515624994, 19.278417968750006],
                            [-81.36953124999997, 19.34887695312497],
                        ],
                    ],
                    [
                        [
                            [-79.823388671875, 19.711914062500057],
                            [-79.90620117187501, 19.702539062499994],
                            [-79.74228515625, 19.757128906250017],
                            [-79.823388671875, 19.711914062500057],
                        ],
                    ],
                ],
            },
            properties: {
                name: "开曼群岛",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [34.004492187500006, 35.065234375],
                        [33.47578125000001, 35.000341796875],
                        [33.3837890625, 35.1626953125],
                        [32.91953125, 35.087841796875],
                        [32.71269531250002, 35.171044921874994],
                        [32.8798828125, 35.180566406249994],
                        [32.94160156250001, 35.390429687499996],
                        [33.60761718750001, 35.354150390624994],
                        [34.55605468750002, 35.662060546875],
                        [33.941992187500006, 35.292041015624996],
                        [34.004492187500006, 35.065234375],
                    ],
                ],
            },
            properties: {
                name: "N. 塞浦路斯",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [32.71269531250002, 35.171044921874994],
                        [32.91953125, 35.087841796875],
                        [33.3837890625, 35.1626953125],
                        [33.47578125000001, 35.000341796875],
                        [34.004492187500006, 35.065234375],
                        [34.05019531250002, 34.98837890625],
                        [33.69941406250001, 34.969873046874994],
                        [33.007910156250006, 34.569580078125],
                        [32.44902343750002, 34.729443359375],
                        [32.31718750000002, 34.9533203125],
                        [32.30097656250001, 35.082958984375],
                        [32.71269531250002, 35.171044921874994],
                    ],
                ],
            },
            properties: {
                name: "塞浦路斯",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [14.809375, 50.858984375],
                        [14.895800781250017, 50.861376953124996],
                        [14.98291015625, 50.886572265625],
                        [14.99375, 51.01435546875],
                        [16.007226562500023, 50.611621093749996],
                        [16.2822265625, 50.655615234375],
                        [16.419726562500017, 50.573632812499994],
                        [16.210351562500023, 50.423730468749994],
                        [16.63916015625, 50.1021484375],
                        [16.989648437500023, 50.2369140625],
                        [16.88007812500001, 50.427050781249996],
                        [17.41523437500001, 50.254785156249994],
                        [17.702246093750006, 50.307177734374996],
                        [17.627050781250006, 50.11640625],
                        [17.874804687500017, 49.972265625],
                        [18.0283203125, 50.03525390625],
                        [18.562402343750023, 49.879345703125],
                        [18.83222656250001, 49.510791015624996],
                        [18.160937500000017, 49.257373046874996],
                        [18.0859375, 49.06513671875],
                        [17.75849609375001, 48.888134765625],
                        [17.135644531250023, 48.841064453125],
                        [16.953125, 48.598828125],
                        [16.543554687500006, 48.796240234375],
                        [16.057226562500006, 48.754785156249994],
                        [15.066796875000023, 48.997851562499996],
                        [14.691308593750023, 48.59921875],
                        [14.049121093750017, 48.602490234375],
                        [13.814746093750017, 48.766943359375],
                        [13.769921875000023, 48.815966796874996],
                        [13.684960937500023, 48.876708984375],
                        [13.547656250000017, 48.95966796875],
                        [13.440722656250017, 48.95556640625],
                        [13.401171875000017, 48.977587890624996],
                        [12.916699218750011, 49.33046875],
                        [12.68115234375, 49.414501953125],
                        [12.390527343750023, 49.739648437499994],
                        [12.5125, 49.87744140625],
                        [12.09921875, 50.310986328125],
                        [12.134863281250006, 50.3109375],
                        [12.1748046875, 50.288378906249996],
                        [12.231152343750011, 50.244873046875],
                        [12.27734375, 50.181445312499996],
                        [12.3056640625, 50.205712890624994],
                        [12.549023437500011, 50.393408203125],
                        [13.016406250000017, 50.490380859374994],
                        [13.18115234375, 50.510498046875],
                        [14.369042968750023, 50.898730468749996],
                        [14.319726562500023, 51.03779296875],
                        [14.545703125000017, 50.993945312499996],
                        [14.559667968750006, 50.954931640625],
                        [14.59521484375, 50.918603515624994],
                        [14.623828125000017, 50.91474609375],
                        [14.613574218750017, 50.85556640625],
                        [14.658203125, 50.8326171875],
                        [14.723339843750011, 50.814697265625],
                        [14.766503906250023, 50.818310546875],
                        [14.797460937500006, 50.842333984374996],
                        [14.809375, 50.858984375],
                    ],
                ],
            },
            properties: {
                name: "捷克",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [14.1982421875, 53.919042968750034],
                            [13.92578125, 53.879052734374966],
                            [13.827734375, 54.12724609374999],
                            [14.1982421875, 53.919042968750034],
                        ],
                    ],
                    [
                        [
                            [13.709179687500011, 54.382714843749994],
                            [13.707324218750074, 54.281152343749994],
                            [13.190039062500034, 54.32563476562501],
                            [13.336816406249994, 54.697119140625006],
                            [13.65761718750008, 54.55957031249997],
                            [13.709179687500011, 54.382714843749994],
                        ],
                    ],
                    [
                        [
                            [9.739746093750028, 54.82553710937498],
                            [10.022167968750011, 54.673925781250006],
                            [9.86865234375, 54.47246093749999],
                            [10.731542968750006, 54.31625976562506],
                            [11.013378906250068, 54.37915039062497],
                            [11.008593750000074, 54.18115234374997],
                            [10.810742187500068, 54.075146484374955],
                            [10.917773437500045, 53.99531250000004],
                            [11.39960937500004, 53.94462890625002],
                            [12.111328125, 54.168310546875006],
                            [12.57539062500004, 54.467382812500006],
                            [13.028613281250017, 54.411035156249994],
                            [13.448046875000017, 54.14086914062503],
                            [13.724218750000063, 54.153222656249966],
                            [13.865527343750074, 53.85336914062498],
                            [14.258886718750006, 53.729638671874994],
                            [14.298730468750051, 53.55644531249999],
                            [14.41455078125, 53.28349609374996],
                            [14.412304687500011, 53.216748046874955],
                            [14.410937500000074, 53.19902343749999],
                            [14.368554687500051, 53.105566406250034],
                            [14.293164062500068, 53.026757812499966],
                            [14.138867187500068, 52.93286132812503],
                            [14.128613281250011, 52.87822265625002],
                            [14.253710937500017, 52.78251953124996],
                            [14.514062500000023, 52.645605468750034],
                            [14.619433593750017, 52.52851562499998],
                            [14.569726562499994, 52.431103515624955],
                            [14.554589843750023, 52.35966796874996],
                            [14.573925781250068, 52.31416015625001],
                            [14.615625, 52.277636718750045],
                            [14.679882812500068, 52.25],
                            [14.752539062500034, 52.08183593750002],
                            [14.601660156250034, 51.832373046875006],
                            [14.738671875000051, 51.62714843750004],
                            [14.7109375, 51.54492187499997],
                            [14.724707031250063, 51.523876953124955],
                            [14.90595703125004, 51.463330078124955],
                            [14.935546875000028, 51.435351562500045],
                            [14.9638671875, 51.095117187499994],
                            [14.917480468750057, 51.00874023437498],
                            [14.814257812499989, 50.871630859375045],
                            [14.809375, 50.858984375000034],
                            [14.797460937500034, 50.84233398437502],
                            [14.766503906250051, 50.81831054687501],
                            [14.72333984375004, 50.81469726562497],
                            [14.658203125, 50.832617187500006],
                            [14.613574218750045, 50.85556640625006],
                            [14.623828125000017, 50.91474609375004],
                            [14.595214843750057, 50.91860351562502],
                            [14.559667968750006, 50.954931640625034],
                            [14.545703124999989, 50.99394531249999],
                            [14.319726562500051, 51.037792968749955],
                            [14.36904296875008, 50.89873046874996],
                            [13.18115234375, 50.510498046875],
                            [13.016406250000017, 50.490380859374994],
                            [12.549023437500011, 50.393408203125034],
                            [12.3056640625, 50.205712890624994],
                            [12.27734375, 50.18144531250002],
                            [12.231152343749983, 50.24487304687497],
                            [12.174804687500057, 50.28837890624996],
                            [12.134863281250006, 50.31093750000002],
                            [12.099218750000034, 50.31098632812504],
                            [12.089843749999972, 50.30175781250003],
                            [12.089746093750051, 50.2685546875],
                            [12.294598214285761, 50.13608119419641],
                            [12.5125, 49.87744140625],
                            [12.390527343750051, 49.739648437499994],
                            [12.68115234375, 49.41450195312501],
                            [12.91669921875004, 49.33046875000002],
                            [13.401171875000074, 48.97758789062499],
                            [13.440722656250045, 48.95556640625003],
                            [13.547656250000074, 48.95966796874998],
                            [13.684960937500051, 48.87670898437506],
                            [13.769921875000051, 48.81596679687502],
                            [13.814746093750017, 48.76694335937498],
                            [13.802929687500011, 48.74750976562501],
                            [13.798828124999972, 48.62167968750006],
                            [13.785351562499983, 48.58745117187502],
                            [13.486621093750074, 48.58183593750002],
                            [13.471679687500028, 48.57182617187502],
                            [13.459863281250023, 48.564550781250034],
                            [13.409375, 48.39414062500006],
                            [13.322851562500006, 48.33125],
                            [13.215234375000023, 48.301904296874994],
                            [12.760351562500063, 48.10698242187499],
                            [12.95351562500008, 47.890625],
                            [12.897656250000068, 47.721875],
                            [13.054101562500051, 47.655126953125034],
                            [13.047949218750034, 47.57915039062502],
                            [13.031542968750074, 47.50800781250001],
                            [13.01435546875004, 47.478076171875045],
                            [12.968066406250017, 47.475683593750006],
                            [12.878906250000057, 47.506445312500034],
                            [12.809375, 47.542187499999955],
                            [12.782812500000034, 47.56416015624998],
                            [12.781152343750051, 47.590429687500006],
                            [12.796191406249989, 47.60703125],
                            [12.771386718750023, 47.63940429687503],
                            [12.685839843750074, 47.66933593750002],
                            [12.209277343750074, 47.71826171875003],
                            [12.196875, 47.709082031250034],
                            [12.203808593750011, 47.64672851562503],
                            [12.185644531250063, 47.61953125],
                            [11.041992187500028, 47.39311523437496],
                            [10.98085937499999, 47.39814453125001],
                            [10.893945312500051, 47.470458984375],
                            [10.870605468750028, 47.500781250000045],
                            [10.873046874999972, 47.52021484375001],
                            [10.741601562500023, 47.52412109375001],
                            [10.65869140625, 47.547216796875006],
                            [10.482812500000051, 47.54179687499996],
                            [10.439453125000028, 47.55156249999999],
                            [10.403906250000063, 47.41699218750003],
                            [10.369140625, 47.366064453125034],
                            [10.18300781250008, 47.27880859375003],
                            [10.200292968750063, 47.36342773437505],
                            [10.066308593750023, 47.39335937500002],
                            [10.064575892857171, 47.42369419642856],
                            [10.059863281250045, 47.44907226562498],
                            [10.034082031250023, 47.47358398437501],
                            [9.971582031249994, 47.50532226562498],
                            [9.839160156250017, 47.55229492187496],
                            [9.748925781250023, 47.575537109375006],
                            [9.524023437500034, 47.52421875000002],
                            [8.572656250000023, 47.775634765625],
                            [8.435742187500011, 47.73134765625002],
                            [8.403417968750006, 47.687792968750045],
                            [8.413281250000068, 47.66269531249998],
                            [8.451757812500006, 47.65180664062498],
                            [8.552343750000063, 47.65913085937498],
                            [8.56708984375004, 47.65190429687502],
                            [8.57050781250004, 47.63779296874998],
                            [8.55947265625008, 47.62402343750003],
                            [8.477636718750034, 47.61269531250002],
                            [8.454003906249994, 47.59619140625003],
                            [7.615625, 47.59272460937504],
                            [7.616601562500023, 48.15678710937502],
                            [8.134863281250006, 48.97358398437498],
                            [7.450585937500051, 49.152197265625034],
                            [6.735449218750006, 49.16059570312498],
                            [6.344335937500006, 49.45273437499998],
                            [6.4873046875, 49.798486328124994],
                            [6.204882812500017, 49.915136718750034],
                            [6.13818359375, 49.97431640625001],
                            [6.10976562500008, 50.034375],
                            [6.116503906250045, 50.120996093749966],
                            [6.340917968750006, 50.451757812500034],
                            [5.993945312500017, 50.75043945312504],
                            [6.048437500000034, 50.90488281250006],
                            [5.857519531250034, 51.030126953125006],
                            [6.129980468750034, 51.14741210937501],
                            [6.198828125000034, 51.45],
                            [5.948730468750057, 51.80268554687501],
                            [6.800390625, 51.96738281249998],
                            [6.724511718749994, 52.080224609374966],
                            [7.035156250000057, 52.38022460937498],
                            [6.748828125000074, 52.464013671874994],
                            [6.710742187500045, 52.61787109374998],
                            [7.033007812500045, 52.65136718749997],
                            [7.197265625000028, 53.28227539062499],
                            [7.074316406250034, 53.477636718750006],
                            [7.285253906250034, 53.68134765625001],
                            [8.00927734375, 53.69072265624999],
                            [8.108496093750063, 53.46767578125002],
                            [8.245214843750006, 53.44531249999997],
                            [8.333886718750051, 53.606201171875],
                            [8.495214843750063, 53.39423828124998],
                            [8.618945312500045, 53.875],
                            [9.20556640625, 53.85595703124997],
                            [9.783984375000074, 53.554638671874955],
                            [9.31201171875, 53.859130859375],
                            [8.92041015625, 53.96533203125006],
                            [8.906640625000023, 54.26079101562502],
                            [8.625781250000017, 54.35395507812501],
                            [8.951855468750011, 54.46757812499996],
                            [8.670312500000023, 54.903417968750034],
                            [9.739746093750028, 54.82553710937498],
                        ],
                    ],
                    [
                        [
                            [8.307714843750034, 54.786962890625034],
                            [8.451464843750017, 55.05537109374998],
                            [8.3798828125, 54.89985351562501],
                            [8.629589843750068, 54.891748046874966],
                            [8.307714843750034, 54.786962890625034],
                        ],
                    ],
                ],
            },
            properties: {
                name: "德国",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [43.24599609375002, 11.499804687499989],
                        [42.92275390625002, 10.999316406249989],
                        [42.557714843750006, 11.080761718749997],
                        [41.79824218750002, 10.98046875],
                        [41.79267578125001, 11.68603515625],
                        [42.378515625, 12.46640625],
                        [42.40859375000002, 12.494384765625],
                        [42.45, 12.521337890624991],
                        [42.47939453125002, 12.513623046874997],
                        [42.703710937500006, 12.380322265624997],
                        [42.76748046875002, 12.4228515625],
                        [42.825292968750006, 12.5693359375],
                        [42.86591796875001, 12.622802734375],
                        [42.88330078125, 12.621289062499997],
                        [43.00566406250002, 12.662304687499997],
                        [43.11669921875, 12.70859375],
                        [43.353515625, 12.367041015624991],
                        [43.38027343750002, 12.091259765624997],
                        [42.64003906250002, 11.560107421874989],
                        [42.52177734375002, 11.572167968749994],
                        [42.58378906250002, 11.496777343749997],
                        [43.04277343750002, 11.588476562499991],
                        [43.24599609375002, 11.499804687499989],
                    ],
                ],
            },
            properties: {
                name: "吉布提",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-61.281689453125, 15.2490234375],
                        [-61.37539062499999, 15.227294921875],
                        [-61.45810546874999, 15.633105468750003],
                        [-61.277246093749994, 15.526708984374991],
                        [-61.281689453125, 15.2490234375],
                    ],
                ],
            },
            properties: {
                name: "多米尼加",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [11.361425781250006, 54.891650390625045],
                            [11.739550781250017, 54.80742187500002],
                            [11.765917968750074, 54.67944335937506],
                            [11.457421875000023, 54.628857421874955],
                            [11.035546875000051, 54.77309570312505],
                            [11.058593750000028, 54.940576171874966],
                            [11.361425781250006, 54.891650390625045],
                        ],
                    ],
                    [
                        [
                            [12.549218750000051, 54.96577148437504],
                            [12.11884765625004, 54.91440429687506],
                            [12.274023437500034, 55.064111328124994],
                            [12.549218750000051, 54.96577148437504],
                        ],
                    ],
                    [
                        [
                            [10.061230468750068, 54.88637695312502],
                            [9.80625, 54.90600585937503],
                            [9.78125, 55.06904296875001],
                            [10.061230468750068, 54.88637695312502],
                        ],
                    ],
                    [
                        [
                            [10.734082031250011, 54.750732421875],
                            [10.621679687500006, 54.851416015625006],
                            [10.95107421875008, 55.15620117187501],
                            [10.734082031250011, 54.750732421875],
                        ],
                    ],
                    [
                        [
                            [15.087695312500017, 55.021875],
                            [14.684179687500063, 55.10224609375004],
                            [14.765332031250068, 55.296728515625034],
                            [15.132617187500017, 55.14453125000003],
                            [15.087695312500017, 55.021875],
                        ],
                    ],
                    [
                        [
                            [10.645117187500006, 55.60981445312498],
                            [10.785253906250034, 55.13339843749998],
                            [10.44277343750008, 55.04877929687498],
                            [9.988769531250028, 55.163183593750006],
                            [9.860644531250045, 55.515478515625034],
                            [10.645117187500006, 55.60981445312498],
                        ],
                    ],
                    [
                        [
                            [12.665722656250068, 55.596533203125006],
                            [12.550878906250034, 55.55625],
                            [12.59921875, 55.68022460937502],
                            [12.665722656250068, 55.596533203125006],
                        ],
                    ],
                    [
                        [
                            [12.56875, 55.785058593749966],
                            [12.215039062500011, 55.46650390624998],
                            [12.413085937500028, 55.28618164062502],
                            [12.089941406250006, 55.18813476562505],
                            [12.050390625000034, 54.81533203125002],
                            [11.8623046875, 54.77260742187502],
                            [11.653808593750057, 55.186914062499966],
                            [11.286328125000068, 55.20444335937498],
                            [10.978906250000051, 55.721533203125006],
                            [11.322265625000028, 55.752539062500006],
                            [11.627734375000074, 55.95688476562498],
                            [11.819726562500023, 55.69765625000002],
                            [11.86640625000004, 55.968164062499966],
                            [12.218945312499983, 56.11865234374997],
                            [12.578710937500006, 56.06406250000006],
                            [12.56875, 55.785058593749966],
                        ],
                    ],
                    [
                        [
                            [11.052148437500051, 57.25253906250006],
                            [10.873828125000045, 57.26225585937499],
                            [11.174511718750011, 57.322900390624994],
                            [11.052148437500051, 57.25253906250006],
                        ],
                    ],
                    [
                        [
                            [9.739746093750028, 54.82553710937498],
                            [8.670312500000023, 54.903417968750034],
                            [8.61591796875004, 55.41821289062503],
                            [8.132128906250074, 55.59980468749998],
                            [8.16396484375008, 56.60688476562498],
                            [8.671679687500045, 56.49565429687496],
                            [8.88808593750008, 56.73505859374998],
                            [9.06708984375004, 56.79384765625005],
                            [9.196386718750006, 56.70166015625],
                            [9.2548828125, 57.01171875000003],
                            [8.992773437499977, 57.01611328125003],
                            [8.771972656250028, 56.72529296875004],
                            [8.468359375, 56.66455078125],
                            [8.284082031250023, 56.85234374999999],
                            [8.618554687500051, 57.11127929687498],
                            [9.43359375, 57.17431640625003],
                            [9.96230468750008, 57.580957031249994],
                            [10.609960937500034, 57.73691406249998],
                            [10.282714843750057, 56.620507812499994],
                            [10.926171875000051, 56.44326171875002],
                            [10.753417968750028, 56.24199218749999],
                            [10.31875, 56.212890625],
                            [10.18300781250008, 55.86518554687504],
                            [9.903710937500023, 55.84282226562502],
                            [10.02363281250004, 55.76142578125004],
                            [9.591113281250017, 55.49321289062502],
                            [9.670996093750063, 55.26640624999999],
                            [9.453710937500006, 55.03955078125006],
                            [9.732324218750023, 54.96801757812506],
                            [9.739746093750028, 54.82553710937498],
                        ],
                    ],
                ],
            },
            properties: {
                name: "丹麦",
                childNum: 10,
                cp: [10.2768332, 56.1773879],
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-71.647216796875, 19.195947265624994],
                        [-71.746484375, 19.285839843749997],
                        [-71.71147460937499, 19.486572265625],
                        [-71.75742187499999, 19.688183593749997],
                        [-71.779248046875, 19.718164062499994],
                        [-71.6673828125, 19.8486328125],
                        [-70.95415039062499, 19.913964843749994],
                        [-70.19384765625, 19.63803710937499],
                        [-69.95683593749999, 19.671875],
                        [-69.739404296875, 19.29921875],
                        [-69.23247070312499, 19.27182617187499],
                        [-69.60595703125, 19.206494140624997],
                        [-69.62363281249999, 19.117822265624994],
                        [-68.684765625, 18.90478515625],
                        [-68.33916015624999, 18.611523437499997],
                        [-68.68740234375, 18.21494140624999],
                        [-68.9349609375, 18.408007812500003],
                        [-69.27451171874999, 18.43984375],
                        [-69.770654296875, 18.443554687499997],
                        [-70.479931640625, 18.21728515625],
                        [-70.644677734375, 18.336230468750003],
                        [-71.02783203125, 18.273193359375],
                        [-71.43896484375, 17.63559570312499],
                        [-71.63173828125, 17.773632812499997],
                        [-71.768310546875, 18.03916015624999],
                        [-71.76376953124999, 18.20395507812499],
                        [-71.737255859375, 18.270800781250003],
                        [-71.7619140625, 18.34130859375],
                        [-71.87255859375, 18.416210937499997],
                        [-71.940380859375, 18.512597656249994],
                        [-72.000390625, 18.597900390625],
                        [-71.98686523437499, 18.6103515625],
                        [-71.86650390624999, 18.614160156249994],
                        [-71.74321289062499, 18.73291015625],
                        [-71.72705078125, 18.80322265625],
                        [-71.733642578125, 18.856396484374997],
                        [-71.80712890625, 18.987011718749997],
                        [-71.647216796875, 19.195947265624994],
                    ],
                ],
            },
            properties: {
                name: "多米尼加共和国",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [8.207617187500006, 36.518945312499994],
                        [8.348730468750006, 36.36796875],
                        [8.318066406250011, 35.654931640624994],
                        [8.31640625, 35.403125],
                        [8.35986328125, 35.299609375],
                        [8.394238281250011, 35.203857421875],
                        [8.312109375, 35.084619140624994],
                        [8.27685546875, 34.9794921875],
                        [8.24560546875, 34.73408203125],
                        [7.513867187500011, 34.080517578125],
                        [7.534375, 33.717919921874994],
                        [7.877246093750017, 33.172119140625],
                        [8.1125, 33.055322265624994],
                        [8.333398437500023, 32.543603515624994],
                        [9.044042968750006, 32.07236328125],
                        [9.160253906250006, 31.621337890625],
                        [9.224023437500023, 31.373681640624994],
                        [9.51875, 30.229394531249994],
                        [9.310253906250011, 30.115234375],
                        [9.805273437500006, 29.176953125],
                        [9.916015625, 27.785693359374996],
                        [9.74755859375, 27.330859375],
                        [9.883203125000023, 26.630810546874997],
                        [9.491406250000011, 26.333740234375],
                        [9.4482421875, 26.067138671875],
                        [10.000683593750011, 25.332080078125003],
                        [10.255859375, 24.591015625],
                        [10.395898437500023, 24.485595703125],
                        [10.686132812500006, 24.55136718749999],
                        [11.507617187500017, 24.314355468749994],
                        [11.967871093750006, 23.517871093750003],
                        [7.481738281250017, 20.873095703125003],
                        [5.836621093750011, 19.479150390624994],
                        [4.227636718750006, 19.142773437499997],
                        [3.3564453125, 18.986621093750003],
                        [3.119726562500006, 19.103173828124994],
                        [3.255859375, 19.4109375],
                        [3.130273437500023, 19.85019531249999],
                        [1.685449218750023, 20.378369140624997],
                        [1.610644531250017, 20.555566406249994],
                        [1.165722656250011, 20.817431640625003],
                        [1.1455078125, 21.102246093749997],
                        [-1.947900390624994, 23.124804687500003],
                        [-4.822607421874977, 24.99560546875],
                        [-8.683349609375, 27.2859375],
                        [-8.683349609375, 27.656445312499997],
                        [-8.683349609375, 27.900390625],
                        [-8.659912109375, 28.718603515625],
                        [-7.485742187499994, 29.392236328124994],
                        [-7.427685546874983, 29.425],
                        [-7.142431640624977, 29.619580078124997],
                        [-6.855566406249977, 29.601611328124996],
                        [-6.755126953125, 29.583837890625],
                        [-6.635351562499977, 29.568798828124997],
                        [-6.597753906249977, 29.578955078125],
                        [-6.520556640624989, 29.659863281249997],
                        [-6.479736328125, 29.820361328124996],
                        [-6.00429687499999, 29.83125],
                        [-5.448779296874989, 29.956933593749994],
                        [-5.293652343749983, 30.058642578124996],
                        [-5.180126953124983, 30.166162109374994],
                        [-4.96826171875, 30.465380859374996],
                        [-4.778515624999983, 30.552392578124994],
                        [-4.529150390624977, 30.625537109374996],
                        [-4.322851562499977, 30.698876953124994],
                        [-4.148779296874977, 30.8095703125],
                        [-3.626904296874983, 31.000927734374997],
                        [-3.833398437499994, 31.197802734374996],
                        [-3.837109374999983, 31.512353515624994],
                        [-3.768164062499977, 31.68955078125],
                        [-3.700244140624989, 31.700097656249994],
                        [-3.604589843749977, 31.686767578125],
                        [-3.439794921874977, 31.704541015624997],
                        [-3.017382812499989, 31.834277343749996],
                        [-2.988232421874983, 31.87421875],
                        [-2.930859374999983, 32.042529296874996],
                        [-2.863427734374994, 32.07470703125],
                        [-1.275341796874983, 32.089013671874994],
                        [-1.16259765625, 32.399169921875],
                        [-1.111035156249983, 32.552294921874996],
                        [-1.188232421875, 32.60849609375],
                        [-1.29638671875, 32.675683593749994],
                        [-1.352148437499977, 32.703369140625],
                        [-1.45, 32.784814453124994],
                        [-1.510009765625, 32.87763671875],
                        [-1.550732421874983, 33.073583984375],
                        [-1.67919921875, 33.318652343749996],
                        [-1.795605468749983, 34.751904296875],
                        [-2.131787109374983, 34.970849609374994],
                        [-2.190771484374977, 35.02978515625],
                        [-2.219628906249994, 35.10419921875],
                        [-1.673632812499989, 35.18310546875],
                        [-0.426123046874977, 35.8615234375],
                        [-0.048242187499994, 35.8328125],
                        [0.312207031250011, 36.162353515625],
                        [0.9716796875, 36.4439453125],
                        [2.593359375, 36.60068359375],
                        [2.972851562500011, 36.784472656249996],
                        [3.779003906250011, 36.89619140625],
                        [4.758105468750017, 36.896337890625],
                        [5.29541015625, 36.648242187499996],
                        [6.486523437500011, 37.085742187499996],
                        [6.927539062500017, 36.91943359375],
                        [7.238476562500011, 36.968505859375],
                        [7.204296875000011, 37.0923828125],
                        [7.910449218750017, 36.856347656249994],
                        [8.576562500000023, 36.93720703125],
                        [8.601269531250011, 36.833935546875],
                        [8.207617187500006, 36.518945312499994],
                    ],
                ],
            },
            properties: {
                name: "阿尔及利亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-80.131591796875, -2.973144531249957],
                            [-80.27294921875003, -2.995898437499974],
                            [-80.22368164062502, -2.753125],
                            [-80.08076171874995, -2.668847656249966],
                            [-79.90903320312495, -2.725585937499972],
                            [-80.131591796875, -2.973144531249957],
                        ],
                    ],
                    [
                        [
                            [-90.42392578125, -1.339941406250034],
                            [-90.51953124999994, -1.299121093749974],
                            [-90.47719726562494, -1.22099609374996],
                            [-90.42392578125, -1.339941406250034],
                        ],
                    ],
                    [
                        [
                            [-89.41889648437498, -0.911035156249966],
                            [-89.60859374999998, -0.888574218750009],
                            [-89.28784179687503, -0.689843750000023],
                            [-89.41889648437498, -0.911035156249966],
                        ],
                    ],
                    [
                        [
                            [-90.33486328125, -0.771582031249977],
                            [-90.54213867187502, -0.676464843749955],
                            [-90.53168945312493, -0.581445312499966],
                            [-90.26938476562498, -0.48466796874996],
                            [-90.19272460937498, -0.658789062500006],
                            [-90.33486328125, -0.771582031249977],
                        ],
                    ],
                    [
                        [
                            [-91.42597656249995, -0.460839843749994],
                            [-91.61074218749994, -0.44394531250002],
                            [-91.64667968749998, -0.284472656249946],
                            [-91.46015625000001, -0.255664062500031],
                            [-91.42597656249995, -0.460839843749994],
                        ],
                    ],
                    [
                        [
                            [-90.57392578124993, -0.333984375],
                            [-90.8677734375, -0.271386718750037],
                            [-90.78037109374998, -0.160449218749989],
                            [-90.57392578124993, -0.333984375],
                        ],
                    ],
                    [
                        [
                            [-91.27216796874998, 0.025146484374986],
                            [-90.799658203125, -0.752050781249991],
                            [-90.90551757812497, -0.94052734375002],
                            [-91.13105468750001, -1.019628906249977],
                            [-91.41904296874998, -0.996679687500006],
                            [-91.49541015624999, -0.860937499999977],
                            [-91.120947265625, -0.559082031250028],
                            [-91.36918945312493, -0.287207031249977],
                            [-91.42885742187502, -0.023388671874955],
                            [-91.59682617187497, 0.002099609374994],
                            [-91.36137695312496, 0.125830078124977],
                            [-91.27216796874998, 0.025146484374986],
                        ],
                    ],
                    [
                        [
                            [-78.90922851562502, 1.252783203124977],
                            [-78.99169921875003, 1.293212890625043],
                            [-78.89980468749997, 1.359765625],
                            [-78.90922851562502, 1.252783203124977],
                        ],
                    ],
                    [
                        [
                            [-75.28447265624999, -0.10654296875002],
                            [-75.62626953124999, -0.122851562499974],
                            [-75.63203125000001, -0.157617187500037],
                            [-75.56059570312502, -0.200097656249994],
                            [-75.49106445312498, -0.24833984374996],
                            [-75.42470703124997, -0.408886718749983],
                            [-75.259375, -0.59013671874996],
                            [-75.24960937499998, -0.951855468750026],
                            [-75.34819335937499, -0.966796874999957],
                            [-75.38012695312503, -0.94023437499996],
                            [-75.40805664062503, -0.92431640625],
                            [-75.42041015624997, -0.962207031250003],
                            [-75.570556640625, -1.53125],
                            [-76.08979492187501, -2.133105468749974],
                            [-76.6791015625, -2.562597656249991],
                            [-77.860595703125, -2.981640625000011],
                            [-78.240380859375, -3.472558593750009],
                            [-78.345361328125, -3.397363281249966],
                            [-78.64799804687499, -4.248144531250006],
                            [-78.68603515625003, -4.562402343749994],
                            [-78.86152343749998, -4.665039062499943],
                            [-78.90761718749997, -4.714453124999977],
                            [-78.92578125, -4.770703124999983],
                            [-78.91420898437497, -4.818652343749974],
                            [-78.919189453125, -4.858398437499986],
                            [-78.97539062499999, -4.873242187499997],
                            [-78.99526367187497, -4.908007812499974],
                            [-79.03330078124998, -4.96914062499999],
                            [-79.07626953125003, -4.990625],
                            [-79.18666992187497, -4.958203124999983],
                            [-79.26811523437493, -4.957617187499949],
                            [-79.33095703124997, -4.92783203125002],
                            [-79.39941406249997, -4.840039062499983],
                            [-79.45576171874998, -4.766210937499949],
                            [-79.50190429687495, -4.670605468750011],
                            [-79.51616210937493, -4.539160156249963],
                            [-79.57768554687496, -4.50058593750002],
                            [-79.638525390625, -4.454882812500031],
                            [-79.71098632812502, -4.467578124999946],
                            [-79.79726562500002, -4.47636718749996],
                            [-79.8451171875, -4.445898437499977],
                            [-79.962890625, -4.390332031250026],
                            [-80.06352539062499, -4.327539062500023],
                            [-80.13955078125002, -4.296093750000011],
                            [-80.19746093750001, -4.311035156249943],
                            [-80.293359375, -4.416796875],
                            [-80.38349609374998, -4.46367187499996],
                            [-80.424169921875, -4.461425781250028],
                            [-80.47856445312499, -4.430078125000037],
                            [-80.48847656249995, -4.393652343749991],
                            [-80.44384765625003, -4.335839843750023],
                            [-80.35288085937495, -4.208496093750014],
                            [-80.453759765625, -4.205175781249963],
                            [-80.48847656249995, -4.165527343749972],
                            [-80.49345703124999, -4.119140625000014],
                            [-80.510009765625, -4.06953125000004],
                            [-80.49013671874994, -4.010058593750003],
                            [-80.43720703125001, -3.978613281249991],
                            [-80.30327148437499, -4.005078124999969],
                            [-80.26689453124993, -3.948828124999963],
                            [-80.23051757812499, -3.924023437499969],
                            [-80.19414062499996, -3.905859375],
                            [-80.24375, -3.576757812500006],
                            [-80.32465820312498, -3.387890625],
                            [-79.96333007812501, -3.15771484375],
                            [-79.72988281249997, -2.579101562499972],
                            [-79.842138671875, -2.0673828125],
                            [-79.92558593749996, -2.548535156249969],
                            [-80.03017578124994, -2.556738281249949],
                            [-80.00664062499993, -2.353808593750003],
                            [-80.28471679687502, -2.706738281249955],
                            [-80.93217773437493, -2.269140624999977],
                            [-80.76059570312498, -1.934570312500028],
                            [-80.90239257812499, -1.078906249999974],
                            [-80.55390624999998, -0.847949218749989],
                            [-80.45546875, -0.585449218749986],
                            [-80.282373046875, -0.620507812500023],
                            [-80.48227539062503, -0.368261718749963],
                            [-80.046142578125, 0.155371093750048],
                            [-80.08828124999997, 0.78476562500002],
                            [-78.89965820312503, 1.20625],
                            [-78.85966796874996, 1.455371093750031],
                            [-78.1806640625, 0.968554687499974],
                            [-77.702880859375, 0.837841796874997],
                            [-77.46767578124997, 0.636523437500017],
                            [-77.396337890625, 0.393896484374963],
                            [-76.49462890624997, 0.23544921875002],
                            [-76.27060546874998, 0.439404296874997],
                            [-75.77666015624999, 0.08925781249998],
                            [-75.28447265624999, -0.10654296875002],
                        ],
                    ],
                ],
            },
            properties: {
                name: "厄瓜多尔",
                childNum: 9,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [34.24531250000001, 31.208300781249996],
                        [34.904296875, 29.47734375],
                        [34.736425781250006, 29.27060546875],
                        [34.39970703125002, 28.01601562499999],
                        [34.22011718750002, 27.764306640624994],
                        [33.76025390625, 28.04765625],
                        [33.24775390625001, 28.567724609375],
                        [32.56572265625002, 29.973974609375],
                        [32.35976562500002, 29.630664062499996],
                        [32.89824218750002, 28.565234375],
                        [33.54707031250001, 27.898144531249997],
                        [33.5498046875, 27.607373046874997],
                        [33.84931640625001, 27.184912109375],
                        [33.959082031250006, 26.6490234375],
                        [35.19414062500002, 24.475146484375003],
                        [35.78388671875001, 23.937792968750003],
                        [35.54082031250002, 23.920654296875],
                        [35.50439453125, 23.779296875],
                        [35.697851562500006, 22.946191406249994],
                        [36.22968750000001, 22.628808593749994],
                        [36.87138671875002, 21.996728515624994],
                        [31.434472656250023, 21.995849609375],
                        [31.486132812500017, 22.14780273437499],
                        [31.400292968750023, 22.202441406250003],
                        [31.260644531250023, 22.00229492187499],
                        [31.092675781250023, 21.994873046875],
                        [28.036425781250017, 21.995361328125],
                        [24.980273437500017, 21.995849609375],
                        [24.980273437500017, 25.5888671875],
                        [24.980273437500017, 29.181884765625],
                        [24.703222656250006, 30.201074218749994],
                        [24.96142578125, 30.678515625],
                        [24.85273437500001, 31.334814453125],
                        [25.150488281250006, 31.654980468749997],
                        [25.382226562500023, 31.51279296875],
                        [25.89326171875001, 31.620898437499996],
                        [27.248046875, 31.377880859374997],
                        [27.5400390625, 31.212695312499996],
                        [28.51484375000001, 31.050439453124994],
                        [29.072070312500017, 30.830273437499997],
                        [29.929785156250006, 31.227490234374997],
                        [30.22265625, 31.2583984375],
                        [30.395117187500006, 31.4576171875],
                        [30.92353515625001, 31.566845703124997],
                        [30.56298828125, 31.4169921875],
                        [31.001757812500017, 31.462792968749994],
                        [31.082910156250023, 31.603320312499996],
                        [31.5244140625, 31.458251953125],
                        [31.888964843750017, 31.54140625],
                        [32.13603515625002, 31.341064453125],
                        [31.8921875, 31.482470703124996],
                        [31.77109375, 31.292578125],
                        [32.10175781250001, 31.092822265624996],
                        [32.281835937500006, 31.200878906249997],
                        [32.21621093750002, 31.29375],
                        [32.60332031250002, 31.06875],
                        [33.66650390625, 31.130419921874996],
                        [34.19814453125002, 31.322607421875],
                        [34.24531250000001, 31.208300781249996],
                    ],
                ],
            },
            properties: {
                name: "埃及",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [40.141210937500006, 15.696142578125034],
                            [40.399023437500006, 15.579882812500045],
                            [39.975195312500006, 15.612451171875023],
                            [39.94746093750004, 15.696142578125034],
                            [40.07050781250004, 15.676611328125034],
                            [39.93994140625003, 15.744531250000023],
                            [39.9567382812501, 15.889404296875057],
                            [40.141210937500006, 15.696142578125034],
                        ],
                    ],
                    [
                        [
                            [40.07646484375002, 16.082421875000023],
                            [40.11005859375004, 15.985742187500051],
                            [39.99609375000003, 16.04267578125001],
                            [40.07646484375002, 16.082421875000023],
                        ],
                    ],
                    [
                        [
                            [40.938574218750006, 13.983105468749997],
                            [40.82011718750002, 14.111669921874991],
                            [40.22148437500002, 14.431152343749972],
                            [39.531835937500006, 14.53671875],
                            [39.198046875000074, 14.479394531250037],
                            [39.1354492187501, 14.581884765625034],
                            [39.07421874999997, 14.628222656249974],
                            [39.02382812499999, 14.628222656249974],
                            [38.99570312500006, 14.586865234374983],
                            [38.81201171875003, 14.482324218750009],
                            [38.50439453124997, 14.42441406250002],
                            [38.43144531250002, 14.428613281249994],
                            [38.221484375000074, 14.649658203124986],
                            [38.002539062500006, 14.737109375000045],
                            [37.94345703125006, 14.810546875],
                            [37.884179687499994, 14.852294921874972],
                            [37.82031250000003, 14.708496093749986],
                            [37.70839843750005, 14.45722656250004],
                            [37.64843750000003, 14.32255859375006],
                            [37.571191406249994, 14.149072265624966],
                            [37.546777343749994, 14.143847656249974],
                            [37.507226562499994, 14.156396484375037],
                            [37.257226562499994, 14.453759765625051],
                            [37.024511718750006, 14.271972656250057],
                            [36.81191406250005, 14.315039062500034],
                            [36.67910156250005, 14.307568359375026],
                            [36.542382812499994, 14.25820312499999],
                            [36.52431640625005, 14.256835937499986],
                            [36.492285156250006, 14.544335937500023],
                            [36.470800781250006, 14.736474609375009],
                            [36.448144531249994, 14.940087890625009],
                            [36.42675781249997, 15.132080078125043],
                            [36.566015625, 15.362109375],
                            [36.9137695312501, 16.296191406250045],
                            [36.887792968750006, 16.624658203124994],
                            [36.9787109375001, 16.800585937500045],
                            [36.9757812500001, 16.866552734375006],
                            [36.99521484375006, 17.020556640625017],
                            [37.00898437500004, 17.058886718750017],
                            [37.06152343749997, 17.061279296875057],
                            [37.16953125000006, 17.04140625],
                            [37.41103515625005, 17.061718749999955],
                            [37.452929687500074, 17.108691406250017],
                            [37.51015625, 17.28813476562499],
                            [37.54746093750006, 17.32412109375005],
                            [37.78242187500004, 17.458007812500057],
                            [38.253515625, 17.584765625000017],
                            [38.26728515625004, 17.616699218750057],
                            [38.28984375000002, 17.637011718750017],
                            [38.34736328125004, 17.68359375],
                            [38.37373046875004, 17.717333984375045],
                            [38.42246093750006, 17.823925781249983],
                            [38.60947265625006, 18.00507812500004],
                            [39.03447265625002, 17.085546875000034],
                            [39.298925781250006, 15.921093750000011],
                            [39.78554687499999, 15.124853515624991],
                            [39.86376953124997, 15.470312500000034],
                            [40.20410156250003, 15.014111328124983],
                            [41.17646484375004, 14.620312500000054],
                            [41.65820312499997, 13.983056640624994],
                            [42.24511718749997, 13.587646484374986],
                            [42.39931640625005, 13.212597656249969],
                            [42.522851562499994, 13.221484375],
                            [42.796191406250074, 12.864257812500057],
                            [42.96953125000002, 12.808349609375028],
                            [42.99902343750003, 12.899511718750048],
                            [43.08291015625005, 12.824609374999966],
                            [43.11669921874997, 12.708593749999963],
                            [43.00566406250002, 12.66230468750004],
                            [42.88330078124997, 12.621289062500026],
                            [42.86591796875004, 12.622802734374986],
                            [42.82529296875006, 12.569335937500014],
                            [42.767480468749994, 12.422851562500014],
                            [42.70371093750006, 12.380322265625054],
                            [42.479394531249994, 12.513623046875026],
                            [42.45, 12.521337890625006],
                            [42.40859375, 12.494384765625014],
                            [42.37851562500006, 12.46640625],
                            [42.28994140625005, 12.570214843750009],
                            [42.225, 12.661962890624963],
                            [42.13427734374997, 12.771435546874969],
                            [41.95214843749997, 12.88232421875],
                            [41.85957031250004, 13.025878906250028],
                            [41.76503906250005, 13.183935546874991],
                            [41.362890625, 13.499804687500031],
                            [40.938574218750006, 13.983105468749997],
                        ],
                    ],
                ],
            },
            properties: {
                name: "厄立特里亚",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-17.887939453125, 27.809570312500057],
                            [-17.984765625000023, 27.646386718750023],
                            [-18.160546874999937, 27.76147460937503],
                            [-17.887939453125, 27.809570312500057],
                        ],
                    ],
                    [
                        [
                            [-15.400585937499955, 28.147363281250023],
                            [-15.436767578124972, 27.810693359375023],
                            [-15.71030273437492, 27.784082031250023],
                            [-15.809472656249966, 27.994482421874977],
                            [-15.682763671874994, 28.15405273437497],
                            [-15.400585937499955, 28.147363281250023],
                        ],
                    ],
                    [
                        [
                            [-17.184667968749977, 28.02197265624997],
                            [-17.324902343749955, 28.11767578125003],
                            [-17.25859375, 28.203173828125045],
                            [-17.103759765624943, 28.111132812500017],
                            [-17.184667968749977, 28.02197265624997],
                        ],
                    ],
                    [
                        [
                            [-16.33447265624997, 28.37993164062499],
                            [-16.41821289062497, 28.15141601562496],
                            [-16.65800781249999, 28.007177734374977],
                            [-16.905322265625017, 28.33959960937503],
                            [-16.12363281249992, 28.57597656249996],
                            [-16.33447265624997, 28.37993164062499],
                        ],
                    ],
                    [
                        [
                            [-14.196777343749943, 28.169287109375063],
                            [-14.332617187500006, 28.056005859374977],
                            [-14.49179687499992, 28.100927734374977],
                            [-14.231982421875017, 28.21582031250003],
                            [-14.003369140624983, 28.706689453125023],
                            [-13.85722656249996, 28.73803710937503],
                            [-13.928027343749989, 28.25346679687499],
                            [-14.196777343749943, 28.169287109375063],
                        ],
                    ],
                    [
                        [
                            [-17.83427734374999, 28.49321289062496],
                            [-18.00078124999999, 28.758251953124955],
                            [-17.928808593749977, 28.844580078125063],
                            [-17.7265625, 28.724462890625006],
                            [-17.83427734374999, 28.49321289062496],
                        ],
                    ],
                    [
                        [
                            [-13.715966796874966, 28.911230468750034],
                            [-13.85991210937496, 28.869091796874983],
                            [-13.823632812499966, 29.013330078124966],
                            [-13.463574218749955, 29.237207031250023],
                            [-13.477929687499966, 29.00659179687503],
                            [-13.715966796874966, 28.911230468750034],
                        ],
                    ],
                    [
                        [
                            [1.593945312500068, 38.672070312499955],
                            [1.40576171875, 38.670996093750006],
                            [1.436328125000017, 38.768212890624994],
                            [1.593945312500068, 38.672070312499955],
                        ],
                    ],
                    [
                        [
                            [1.445214843750051, 38.91870117187503],
                            [1.223339843750068, 38.90385742187502],
                            [1.3486328125, 39.080810546875],
                            [1.564453125, 39.12104492187504],
                            [1.623632812499977, 39.03881835937497],
                            [1.445214843750051, 38.91870117187503],
                        ],
                    ],
                    [
                        [
                            [3.145312500000017, 39.79008789062499],
                            [3.461816406250023, 39.69775390625003],
                            [3.072851562500006, 39.30126953124997],
                            [2.799804687500057, 39.38505859374999],
                            [2.700585937500023, 39.54213867187502],
                            [2.49951171875, 39.47788085937498],
                            [2.37001953125008, 39.57207031249999],
                            [3.15869140625, 39.97050781249999],
                            [3.145312500000017, 39.79008789062499],
                        ],
                    ],
                    [
                        [
                            [4.293652343750011, 39.84184570312499],
                            [3.8671875, 39.958740234375],
                            [3.853417968750051, 40.06303710937502],
                            [4.22578125000004, 40.032373046874966],
                            [4.293652343750011, 39.84184570312499],
                        ],
                    ],
                    [
                        [
                            [-1.794042968749949, 43.407324218750006],
                            [-1.410693359374932, 43.240087890625034],
                            [-1.460839843749937, 43.05175781250006],
                            [-1.300048828124943, 43.10097656250002],
                            [-0.586425781249943, 42.798974609374966],
                            [0.631640625000045, 42.689599609374994],
                            [0.696875, 42.84511718750005],
                            [1.428320312499977, 42.59589843749998],
                            [1.414843750000074, 42.54838867187499],
                            [1.448828124999977, 42.43745117187504],
                            [1.534082031250051, 42.44169921875002],
                            [1.7060546875, 42.50332031250005],
                            [1.859765625000051, 42.457080078125045],
                            [1.927929687500068, 42.42631835937499],
                            [2.032714843750028, 42.353515625],
                            [3.21142578125, 42.43115234375],
                            [3.248046875, 41.94423828125002],
                            [3.0048828125, 41.76743164062506],
                            [2.082617187500063, 41.287402343750045],
                            [1.032910156250068, 41.06206054687496],
                            [0.714648437500074, 40.822851562500006],
                            [0.891113281250057, 40.72236328125004],
                            [0.59609375000008, 40.614501953125],
                            [-0.327001953124949, 39.519873046875006],
                            [-0.204931640624949, 39.062597656250034],
                            [0.20156250000008, 38.75917968750002],
                            [-0.520800781249989, 38.317285156249966],
                            [-0.814648437500011, 37.76992187500002],
                            [-0.721582031249966, 37.63105468749998],
                            [-1.327539062499937, 37.561132812500034],
                            [-1.640966796874949, 37.38696289062497],
                            [-2.111523437499983, 36.77666015624999],
                            [-4.366845703124994, 36.71811523437506],
                            [-4.67412109374996, 36.506445312500006],
                            [-5.171484374999949, 36.423779296874955],
                            [-5.3609375, 36.134912109374994],
                            [-5.62548828125, 36.02592773437499],
                            [-6.040673828124937, 36.18842773437498],
                            [-6.38413085937492, 36.63701171874996],
                            [-6.216796875000028, 36.91357421875],
                            [-6.396191406249983, 36.831640625],
                            [-6.863769531250028, 37.27890625],
                            [-7.406152343749937, 37.17944335937497],
                            [-7.44394531249992, 37.72827148437497],
                            [-6.957568359374932, 38.18789062499999],
                            [-7.106396484374983, 38.181005859375006],
                            [-7.343017578124943, 38.45742187500002],
                            [-6.997949218749994, 39.05644531250002],
                            [-7.53569335937496, 39.66157226562501],
                            [-7.117675781249972, 39.681689453125045],
                            [-6.975390624999932, 39.79838867187502],
                            [-6.896093749999949, 40.02182617187506],
                            [-7.032617187499966, 40.16791992187498],
                            [-6.8101562499999, 40.343115234375034],
                            [-6.928466796874972, 41.009130859375006],
                            [-6.2125, 41.53203125],
                            [-6.542187499999955, 41.672509765624994],
                            [-6.61826171874992, 41.9423828125],
                            [-7.147119140625023, 41.98115234374998],
                            [-7.40361328124996, 41.833691406249955],
                            [-8.152490234374937, 41.81196289062498],
                            [-8.266064453124983, 42.13740234375001],
                            [-8.777148437500017, 41.941064453124994],
                            [-8.887207031249943, 42.105273437500045],
                            [-8.690917968749943, 42.274169921875],
                            [-8.815820312499966, 42.285253906250034],
                            [-8.730029296874989, 42.411718750000034],
                            [-8.8115234375, 42.64033203124998],
                            [-9.033105468750023, 42.593847656250006],
                            [-8.927197265624926, 42.79858398437497],
                            [-9.235205078124977, 42.97690429687498],
                            [-9.178076171874977, 43.17402343749998],
                            [-8.248925781249937, 43.43940429687498],
                            [-8.256738281249937, 43.57988281249999],
                            [-8.004687499999932, 43.69438476562496],
                            [-7.503613281249983, 43.73994140625001],
                            [-7.060986328124955, 43.55395507812503],
                            [-5.846679687499943, 43.645068359375045],
                            [-4.52304687499992, 43.41572265625004],
                            [-3.604638671874966, 43.51948242187504],
                            [-3.045605468749926, 43.37158203125],
                            [-2.875048828125017, 43.454443359375006],
                            [-2.337109374999926, 43.32802734375002],
                            [-1.794042968749949, 43.407324218750006],
                        ],
                    ],
                ],
            },
            properties: {
                name: "西班牙",
                childNum: 12,
                cp: [-2.9366964, 40.3438963],
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [22.61738281250004, 58.62124023437502],
                            [23.323242187500057, 58.45083007812502],
                            [22.730273437500045, 58.23066406250001],
                            [22.371679687499977, 58.217138671875006],
                            [21.996875, 57.93134765624998],
                            [22.187695312500068, 58.15434570312502],
                            [21.88212890624999, 58.262353515624994],
                            [21.862304687500057, 58.497167968750034],
                            [22.61738281250004, 58.62124023437502],
                        ],
                    ],
                    [
                        [
                            [23.343554687500017, 58.550341796875045],
                            [23.10908203125004, 58.65922851562502],
                            [23.332812500000045, 58.648583984374994],
                            [23.343554687500017, 58.550341796875045],
                        ],
                    ],
                    [
                        [
                            [22.923730468750023, 58.826904296875],
                            [22.54218750000001, 58.68999023437499],
                            [22.411035156250023, 58.863378906250034],
                            [22.05625, 58.94360351562506],
                            [22.6494140625, 59.08710937499998],
                            [22.90986328125004, 58.99121093749997],
                            [22.923730468750023, 58.826904296875],
                        ],
                    ],
                    [
                        [
                            [28.0125, 59.484277343749966],
                            [28.15107421875004, 59.374414062499966],
                            [27.434179687500006, 58.787255859374994],
                            [27.502441406250057, 58.221337890624994],
                            [27.778515625000068, 57.87070312500006],
                            [27.542089843750063, 57.799414062500006],
                            [27.4, 57.66679687499999],
                            [27.35195312500005, 57.528125],
                            [26.96601562500001, 57.60913085937506],
                            [26.532617187499994, 57.53100585937503],
                            [26.29804687500001, 57.60107421875],
                            [25.66015625, 57.920166015625],
                            [25.27265625000001, 58.009375],
                            [25.11103515625004, 58.06342773437498],
                            [24.45888671875005, 57.907861328124994],
                            [24.3625, 57.86616210937501],
                            [24.322558593750074, 57.87060546875003],
                            [24.529101562500045, 58.35424804687497],
                            [24.114843750000034, 58.26611328125006],
                            [23.767578125000057, 58.36083984374997],
                            [23.50927734375003, 58.65854492187498],
                            [23.680761718750063, 58.787158203125074],
                            [23.43203125, 58.920654296875],
                            [23.494433593750017, 59.19565429687498],
                            [24.083398437500023, 59.29189453125005],
                            [24.38037109375003, 59.47265625],
                            [25.44375, 59.52114257812502],
                            [25.50927734374997, 59.63901367187506],
                            [26.974707031250006, 59.450634765624955],
                            [28.0125, 59.484277343749966],
                        ],
                    ],
                ],
            },
            properties: {
                name: "爱沙尼亚",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [38.43144531250002, 14.428613281249994],
                        [38.50439453125, 14.424414062499991],
                        [38.81201171875, 14.482324218749994],
                        [38.995703125, 14.586865234374997],
                        [39.02382812500002, 14.628222656250003],
                        [39.07421875, 14.628222656250003],
                        [39.13544921875001, 14.581884765624991],
                        [39.19804687500002, 14.479394531249994],
                        [39.531835937500006, 14.53671875],
                        [40.22148437500002, 14.43115234375],
                        [40.82011718750002, 14.111669921874991],
                        [40.938574218750006, 13.983105468749997],
                        [41.362890625, 13.499804687500003],
                        [41.76503906250002, 13.183935546874991],
                        [41.85957031250001, 13.02587890625],
                        [41.9521484375, 12.88232421875],
                        [42.13427734375, 12.771435546874997],
                        [42.225, 12.661962890624991],
                        [42.28994140625002, 12.570214843749994],
                        [42.378515625, 12.46640625],
                        [41.79267578125001, 11.68603515625],
                        [41.79824218750002, 10.98046875],
                        [42.557714843750006, 11.080761718749997],
                        [42.92275390625002, 10.999316406249989],
                        [42.65644531250001, 10.6],
                        [42.84160156250002, 10.203076171874997],
                        [43.181640625, 9.879980468749991],
                        [43.482519531250006, 9.379492187499991],
                        [43.98378906250002, 9.008837890624989],
                        [46.97822265625001, 7.9970703125],
                        [47.97822265625001, 7.9970703125],
                        [44.940527343750006, 4.912011718749994],
                        [43.988867187500006, 4.950537109374991],
                        [43.58349609375, 4.85498046875],
                        [43.12568359375001, 4.644482421874997],
                        [42.85664062500001, 4.32421875],
                        [42.02412109375001, 4.137939453125],
                        [41.91533203125002, 4.031298828124989],
                        [41.88398437500001, 3.977734375],
                        [41.73769531250002, 3.979052734374989],
                        [41.48193359375, 3.96328125],
                        [41.37246093750002, 3.946191406249994],
                        [41.22089843750001, 3.943554687499997],
                        [41.02080078125002, 4.057470703124991],
                        [40.765234375, 4.27304687499999],
                        [39.84218750000002, 3.851464843749994],
                        [39.79033203125002, 3.754248046874991],
                        [39.65751953125002, 3.577832031249997],
                        [39.49443359375002, 3.456103515624989],
                        [38.608007812500006, 3.60009765625],
                        [38.45156250000002, 3.604833984374991],
                        [38.22529296875001, 3.618994140624991],
                        [38.08613281250001, 3.64882812499999],
                        [37.15458984375002, 4.254541015624994],
                        [36.90556640625002, 4.411474609374991],
                        [36.02197265625, 4.468115234374991],
                        [35.76308593750002, 4.808007812499994],
                        [35.75615234375002, 4.950488281249989],
                        [35.779296875, 5.105566406249991],
                        [35.80029296875, 5.156933593749997],
                        [35.74501953125002, 5.343994140625],
                        [35.325292968750006, 5.364892578124994],
                        [35.2646484375, 5.412060546874997],
                        [35.26386718750001, 5.457910156249994],
                        [35.26835937500002, 5.492285156249991],
                        [34.98359375000001, 5.858300781249994],
                        [34.71064453125001, 6.660302734374994],
                        [34.06425781250002, 7.225732421874994],
                        [33.902441406250006, 7.509521484375],
                        [32.99892578125002, 7.899511718749991],
                        [33.28105468750002, 8.437255859375],
                        [33.95332031250001, 8.443505859374994],
                        [34.07275390625, 8.545263671874991],
                        [34.078125, 9.461523437499991],
                        [34.31123046875001, 10.190869140624997],
                        [34.34394531250001, 10.658642578124997],
                        [34.571875, 10.880175781249989],
                        [34.77128906250002, 10.746191406249991],
                        [34.93144531250002, 10.864794921874989],
                        [35.1123046875, 11.816552734374994],
                        [35.67021484375002, 12.623730468749997],
                        [36.12519531250001, 12.75703125],
                        [36.52431640625002, 14.2568359375],
                        [36.54238281250002, 14.25820312499999],
                        [36.67910156250002, 14.307568359374997],
                        [36.81191406250002, 14.315039062499991],
                        [37.024511718750006, 14.27197265625],
                        [37.25722656250002, 14.453759765624994],
                        [37.50722656250002, 14.156396484374994],
                        [37.54677734375002, 14.143847656250003],
                        [37.57119140625002, 14.149072265624994],
                        [37.6484375, 14.322558593750003],
                        [37.70839843750002, 14.457226562499997],
                        [37.8203125, 14.70849609375],
                        [37.88417968750002, 14.852294921875],
                        [37.943457031250006, 14.810546875],
                        [38.002539062500006, 14.737109375],
                        [38.22148437500002, 14.649658203125],
                        [38.43144531250002, 14.428613281249994],
                    ],
                ],
            },
            properties: {
                name: "埃塞俄比亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [22.17509765624999, 60.370751953124994],
                            [22.41552734375003, 60.30336914062505],
                            [22.36054687500004, 60.165576171875045],
                            [22.07714843750003, 60.286328124999955],
                            [22.17509765624999, 60.370751953124994],
                        ],
                    ],
                    [
                        [
                            [21.450878906250068, 60.529589843750045],
                            [21.3, 60.47978515625002],
                            [21.224707031250006, 60.62060546875003],
                            [21.450878906250068, 60.529589843750045],
                        ],
                    ],
                    [
                        [
                            [21.2177734375, 63.241308593750034],
                            [21.415625, 63.19736328125006],
                            [21.25341796875, 63.152001953124966],
                            [21.08388671875008, 63.277539062499955],
                            [21.2177734375, 63.241308593750034],
                        ],
                    ],
                    [
                        [
                            [24.848242187500034, 64.99101562499999],
                            [24.576562500000023, 65.04287109375],
                            [24.970605468750023, 65.05532226562502],
                            [24.848242187500034, 64.99101562499999],
                        ],
                    ],
                    [
                        [
                            [28.96582031250003, 69.02197265625],
                            [28.414062500000057, 68.90415039062506],
                            [28.77285156250005, 68.84003906249995],
                            [28.470703125000057, 68.48837890625],
                            [28.685156250000034, 68.189794921875],
                            [29.343847656250006, 68.06186523437506],
                            [29.988085937500017, 67.66826171874999],
                            [29.066210937500045, 66.89174804687497],
                            [30.102734375000097, 65.72626953125004],
                            [29.715917968750063, 65.62456054687502],
                            [29.608007812500006, 65.248681640625],
                            [29.826953125000017, 65.14506835937502],
                            [29.60419921875004, 64.968408203125],
                            [30.072851562500063, 64.76503906250005],
                            [30.04189453125005, 64.44335937499997],
                            [30.513769531250006, 64.2],
                            [30.50390625000003, 64.02060546875],
                            [29.991503906250074, 63.73515625000002],
                            [31.180859375000097, 63.208300781250074],
                            [31.533984375000017, 62.885400390624994],
                            [31.18671875000004, 62.48139648437504],
                            [29.69013671875004, 61.54609375000001],
                            [27.797656250000074, 60.53613281250003],
                            [26.53466796874997, 60.412890625000074],
                            [26.56933593750003, 60.62456054687502],
                            [26.377734375000074, 60.42407226562503],
                            [25.955957031250023, 60.474218750000034],
                            [26.03583984375004, 60.34150390625001],
                            [25.75800781250004, 60.26752929687504],
                            [25.65644531250004, 60.33320312499998],
                            [24.44560546874999, 60.021289062500045],
                            [23.46357421875004, 59.986230468749994],
                            [23.021289062500074, 59.81601562500006],
                            [23.19843750000001, 60.02182617187498],
                            [22.911718750000063, 60.20971679687497],
                            [22.749804687500017, 60.057275390624994],
                            [22.462695312500045, 60.029199218749966],
                            [22.5849609375, 60.380566406249955],
                            [21.436035156250057, 60.596386718749955],
                            [21.605957031250057, 61.59155273437503],
                            [21.255957031250063, 61.98964843750005],
                            [21.143847656250045, 62.73999023437506],
                            [21.650976562500063, 63.039306640625],
                            [21.545117187499983, 63.204296874999955],
                            [22.31972656250005, 63.310449218749994],
                            [22.532324218750034, 63.647851562499994],
                            [23.598925781250074, 64.04091796874997],
                            [24.557910156250045, 64.801025390625],
                            [25.288183593750063, 64.8603515625],
                            [25.34785156250004, 65.47924804687497],
                            [24.674902343750006, 65.67070312499999],
                            [24.628027343750034, 65.85917968750002],
                            [24.15546875000004, 65.80527343750006],
                            [23.700292968750034, 66.25263671874998],
                            [23.988574218750045, 66.81054687500003],
                            [23.64150390625005, 67.12939453124997],
                            [23.733593750000068, 67.42290039062499],
                            [23.454882812500045, 67.46025390625007],
                            [23.63886718750004, 67.95439453125002],
                            [22.854101562500034, 68.36733398437502],
                            [21.99746093750005, 68.52060546874998],
                            [20.622167968750006, 69.036865234375],
                            [21.065722656250017, 69.04174804687503],
                            [21.06611328125001, 69.21411132812497],
                            [21.59375, 69.273583984375],
                            [22.410937500000074, 68.719873046875],
                            [23.324023437500017, 68.64897460937502],
                            [23.85400390625, 68.80590820312503],
                            [24.94140625000003, 68.59326171875006],
                            [25.748339843750017, 68.99013671875],
                            [26.07246093750004, 69.69155273437497],
                            [26.525390625000057, 69.91503906250003],
                            [27.127539062500063, 69.90649414062497],
                            [27.747851562500045, 70.06484375],
                            [29.14160156250003, 69.67143554687505],
                            [29.33339843750005, 69.47299804687503],
                            [28.846289062500006, 69.17690429687502],
                            [28.96582031250003, 69.02197265625],
                        ],
                    ],
                ],
            },
            properties: {
                name: "芬兰",
                childNum: 5,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [178.48789062500018, -18.97412109375],
                            [177.95869140624998, -19.121582031250014],
                            [178.33427734375013, -18.93447265625001],
                            [178.48789062500018, -18.97412109375],
                        ],
                    ],
                    [
                        [
                            [179.34931640625015, -18.10234375000003],
                            [179.25351562500018, -18.030566406249974],
                            [179.30644531250013, -17.944042968750026],
                            [179.34931640625015, -18.10234375000003],
                        ],
                    ],
                    [
                        [
                            [178.28017578124994, -17.37197265625001],
                            [178.59160156249996, -17.651464843750006],
                            [178.66767578125004, -18.080859375],
                            [177.95546875000005, -18.264062500000023],
                            [177.32138671875, -18.077539062500037],
                            [177.26396484375007, -17.86347656250004],
                            [177.5044921875, -17.539550781250043],
                            [177.81796875000012, -17.38847656249999],
                            [178.28017578124994, -17.37197265625001],
                        ],
                    ],
                    [
                        [
                            [180, -16.96308593750001],
                            [179.89697265625003, -16.96406250000004],
                            [180, -16.785742187500034],
                            [180, -16.96308593750001],
                        ],
                    ],
                    [
                        [
                            [-179.97490234374996, -16.92480468750003],
                            [-180, -16.96298828124999],
                            [-180, -16.907812500000034],
                            [-180, -16.82431640624999],
                            [-180, -16.78554687499999],
                            [-179.86098632812502, -16.68828124999999],
                            [-179.97490234374996, -16.92480468750003],
                        ],
                    ],
                    [
                        [
                            [-179.92944335937503, -16.502832031250037],
                            [-179.999951171875, -16.540039062499986],
                            [-179.900927734375, -16.431542968749994],
                            [-179.92944335937503, -16.502832031250037],
                        ],
                    ],
                    [
                        [
                            [179.99921875000004, -16.168554687499977],
                            [179.56416015625004, -16.636914062499997],
                            [179.56816406249996, -16.747460937499966],
                            [179.93037109375004, -16.51943359375005],
                            [179.9279296875001, -16.74443359374996],
                            [179.41933593750005, -16.80654296875001],
                            [179.20234375000004, -16.71269531249999],
                            [179.00683593750003, -16.90019531249999],
                            [178.70664062500018, -16.97617187500002],
                            [178.4974609375, -16.78789062500003],
                            [178.58359375000012, -16.621875],
                            [178.80507812499994, -16.631445312500034],
                            [179.55175781250003, -16.249902343750023],
                            [180, -16.15292968749999],
                            [179.99921875000004, -16.168554687499977],
                        ],
                    ],
                ],
            },
            properties: {
                name: "斐济",
                childNum: 7,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-61.01875, -51.7857421875],
                            [-60.87597656250003, -51.79423828125004],
                            [-60.94755859374996, -51.94628906250002],
                            [-61.14501953125003, -51.83945312500001],
                            [-61.01875, -51.7857421875],
                        ],
                    ],
                    [
                        [
                            [-60.28623046874995, -51.461914062500014],
                            [-59.38759765625002, -51.35996093750003],
                            [-59.26806640625, -51.42753906250003],
                            [-59.92138671874997, -51.969531250000045],
                            [-60.246337890625, -51.98642578125003],
                            [-60.35346679687498, -52.13994140625004],
                            [-60.686376953125034, -52.18837890624996],
                            [-60.96142578125003, -52.05732421874999],
                            [-60.23847656249998, -51.771972656250036],
                            [-60.58251953125, -51.71269531250004],
                            [-60.24516601562493, -51.638867187500004],
                            [-60.56845703124998, -51.357812499999945],
                            [-60.28623046874995, -51.461914062500014],
                        ],
                    ],
                    [
                        [
                            [-60.11171875000002, -51.39589843749998],
                            [-60.275341796874955, -51.28056640625002],
                            [-60.06982421875, -51.307910156249996],
                            [-60.11171875000002, -51.39589843749998],
                        ],
                    ],
                    [
                        [
                            [-58.85019531249995, -51.26992187499998],
                            [-58.42583007812502, -51.32421875000003],
                            [-58.508935546874994, -51.48359375],
                            [-58.271582031250034, -51.57470703124999],
                            [-58.25922851562501, -51.417089843750034],
                            [-57.976513671874955, -51.384375],
                            [-57.80849609375002, -51.51796875],
                            [-57.96044921874997, -51.58320312500003],
                            [-57.79179687499999, -51.63613281249998],
                            [-58.68349609375002, -51.93623046875001],
                            [-58.65278320312498, -52.09921875],
                            [-59.19584960937496, -52.01767578125],
                            [-59.06801757812502, -52.17304687500003],
                            [-59.341503906249955, -52.19599609375],
                            [-59.395654296874966, -52.308007812499994],
                            [-59.64873046875002, -52.134375],
                            [-59.57080078124994, -51.92539062500003],
                            [-59.05952148437498, -51.685449218749994],
                            [-59.09663085937498, -51.49140624999998],
                            [-58.85019531249995, -51.26992187499998],
                        ],
                    ],
                ],
            },
            properties: {
                name: "福克兰群岛",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [55.79736328125003, -21.33935546875003],
                            [55.36269531250005, -21.27363281250004],
                            [55.23281250000005, -21.05839843749999],
                            [55.311328125000074, -20.90410156249999],
                            [55.661914062500074, -20.90625],
                            [55.8390625000001, -21.13857421874998],
                            [55.79736328125003, -21.33935546875003],
                        ],
                    ],
                    [
                        [
                            [45.180273437500006, -12.97675781250004],
                            [45.069433593750006, -12.895605468750034],
                            [45.09238281250006, -12.653027343749997],
                            [45.22314453124997, -12.752148437500026],
                            [45.180273437500006, -12.97675781250004],
                        ],
                    ],
                    [
                        [
                            [-51.65253906249998, 4.061279296874972],
                            [-52.327880859375, 3.18173828125002],
                            [-52.58300781250003, 2.528906249999977],
                            [-52.90346679687502, 2.211523437499977],
                            [-53.76777343749998, 2.354833984375048],
                            [-54.13007812499998, 2.121044921875026],
                            [-54.43310546875, 2.207519531250057],
                            [-54.51508789062498, 2.245458984374963],
                            [-54.55048828125001, 2.293066406249991],
                            [-54.59194335937502, 2.313769531250031],
                            [-54.61625976562499, 2.326757812500006],
                            [-54.60473632812497, 2.335791015624991],
                            [-54.56840820312502, 2.342578125000031],
                            [-54.53593749999999, 2.343310546875003],
                            [-54.48554687500001, 2.416113281250006],
                            [-54.402001953124966, 2.46152343750002],
                            [-54.25673828125002, 2.713720703124977],
                            [-54.19550781249998, 2.817871093750057],
                            [-54.17070312499999, 2.993603515624969],
                            [-54.203125, 3.138183593750028],
                            [-54.18803710937499, 3.178759765625031],
                            [-54.063183593749955, 3.353320312499989],
                            [-54.00957031249993, 3.448535156250017],
                            [-54.03422851562499, 3.62939453125],
                            [-54.350732421874994, 4.054101562500023],
                            [-54.47968749999998, 4.836523437499991],
                            [-53.91992187499997, 5.768994140624983],
                            [-52.899316406249966, 5.425048828124986],
                            [-52.29052734375003, 4.942187500000031],
                            [-52.324609374999966, 4.770898437500037],
                            [-52.21997070312494, 4.862792968750014],
                            [-52.05810546875003, 4.717382812499963],
                            [-52.00292968749997, 4.352294921875014],
                            [-51.82753906250002, 4.635693359375026],
                            [-51.65253906249998, 4.061279296874972],
                        ],
                    ],
                    [
                        [
                            [-60.826269531250006, 14.494482421874991],
                            [-61.063720703125, 14.467089843750017],
                            [-61.01132812499998, 14.601904296875034],
                            [-61.21333007812501, 14.848583984375011],
                            [-60.927148437499966, 14.755175781249989],
                            [-60.826269531250006, 14.494482421874991],
                        ],
                    ],
                    [
                        [
                            [-61.23046875000003, 15.889941406250074],
                            [-61.310742187499955, 15.894677734374966],
                            [-61.25, 16.006298828124983],
                            [-61.23046875000003, 15.889941406250074],
                        ],
                    ],
                    [
                        [
                            [-61.58955078125001, 16.006933593750006],
                            [-61.759423828124966, 16.062060546875045],
                            [-61.74804687499997, 16.355273437500017],
                            [-61.55234374999998, 16.270898437499966],
                            [-61.58955078125001, 16.006933593750006],
                        ],
                    ],
                    [
                        [
                            [-61.3271484375, 16.230419921874983],
                            [-61.522167968749955, 16.22802734375003],
                            [-61.47119140624994, 16.506640625000045],
                            [-61.17260742187497, 16.25610351562497],
                            [-61.3271484375, 16.230419921874983],
                        ],
                    ],
                    [
                        [
                            [9.480371093750023, 42.80541992187503],
                            [9.550683593750051, 42.12973632812506],
                            [9.186132812500034, 41.38491210937502],
                            [8.80751953125008, 41.58837890625],
                            [8.886816406249977, 41.70068359375003],
                            [8.621875, 41.93071289062502],
                            [8.700976562500045, 42.09560546875002],
                            [8.565625, 42.35771484374996],
                            [8.81484375000008, 42.60791015625003],
                            [9.313378906250023, 42.71318359374999],
                            [9.363183593750051, 43.01738281249996],
                            [9.480371093750023, 42.80541992187503],
                        ],
                    ],
                    [
                        [
                            [-1.17832031249992, 45.904052734375],
                            [-1.213574218750011, 45.81660156250004],
                            [-1.388671874999972, 46.05039062500006],
                            [-1.17832031249992, 45.904052734375],
                        ],
                    ],
                    [
                        [
                            [5.789746093749983, 49.53828125000001],
                            [5.823437500000011, 49.50507812499998],
                            [5.9013671875, 49.48974609374997],
                            [5.928906250000011, 49.47753906249997],
                            [5.959472656250028, 49.45463867187502],
                            [6.01142578125004, 49.44545898437502],
                            [6.074121093750023, 49.45463867187502],
                            [6.119921875000017, 49.485205078125034],
                            [6.181054687500051, 49.498925781249966],
                            [6.344335937500006, 49.45273437499998],
                            [6.735449218750006, 49.16059570312498],
                            [7.450585937500051, 49.152197265625034],
                            [8.134863281250006, 48.97358398437498],
                            [7.616601562500023, 48.15678710937502],
                            [7.615625, 47.59272460937504],
                            [7.343164062499994, 47.43310546875003],
                            [7.136035156249989, 47.489843750000034],
                            [6.968359375000034, 47.453222656250034],
                            [6.900390625000028, 47.39423828125001],
                            [7.000585937500034, 47.339453125000034],
                            [7.000585937500034, 47.32250976562506],
                            [6.978515625000057, 47.30205078124996],
                            [6.95205078125008, 47.26718750000006],
                            [6.820703125000051, 47.163183593750006],
                            [6.688085937500034, 47.05825195312505],
                            [6.66689453125008, 47.026513671874966],
                            [6.624804687500017, 47.00434570312498],
                            [6.45625, 46.948339843750034],
                            [6.438646763392874, 46.774418247767855],
                            [6.129687500000045, 46.56699218750006],
                            [6.118111049107182, 46.447459542410726],
                            [6.095898437500011, 46.279394531250006],
                            [5.970019531250045, 46.214697265625034],
                            [5.971484375000074, 46.151220703125006],
                            [6.006640625000045, 46.14233398437506],
                            [6.086621093750068, 46.14702148437502],
                            [6.19941406250004, 46.19306640624998],
                            [6.234667968750045, 46.332617187500006],
                            [6.321875, 46.39370117187502],
                            [6.428906250000011, 46.43051757812506],
                            [6.578222656250034, 46.437353515625034],
                            [6.758105468750017, 46.41577148437497],
                            [6.772070312500006, 46.16513671874998],
                            [6.897265625000017, 46.05175781249997],
                            [6.953710937500063, 46.017138671875045],
                            [7.00390625, 45.95883789062506],
                            [7.021093750000034, 45.92578124999997],
                            [6.790917968750023, 45.740869140624966],
                            [7.146386718750051, 45.381738281249994],
                            [7.07832031250004, 45.23994140624998],
                            [6.634765625000028, 45.06816406249996],
                            [6.99267578125, 44.82729492187502],
                            [6.900195312499989, 44.33574218749996],
                            [7.318554687500068, 44.13798828125002],
                            [7.637207031250057, 44.16484375],
                            [7.4931640625, 43.767138671875045],
                            [6.570214843750023, 43.199072265625034],
                            [6.115917968750011, 43.07236328124998],
                            [5.406542968750074, 43.228515625],
                            [5.05976562500004, 43.44453125000004],
                            [4.712109375000011, 43.373291015625],
                            [3.910839843750011, 43.563085937500034],
                            [3.258886718750063, 43.193212890625006],
                            [3.051757812500057, 42.915136718750006],
                            [3.21142578125, 42.43115234375],
                            [2.032714843750028, 42.353515625],
                            [1.927929687500068, 42.42631835937499],
                            [1.859765625000051, 42.457080078125045],
                            [1.7060546875, 42.50332031250005],
                            [1.709863281250051, 42.604443359374955],
                            [1.568164062500045, 42.63500976562506],
                            [1.501367187500023, 42.64272460937502],
                            [1.428320312499977, 42.59589843749998],
                            [0.696875, 42.84511718750005],
                            [0.631640625000045, 42.689599609374994],
                            [-0.586425781249943, 42.798974609374966],
                            [-1.300048828124943, 43.10097656250002],
                            [-1.460839843749937, 43.05175781250006],
                            [-1.410693359374932, 43.240087890625034],
                            [-1.794042968749949, 43.407324218750006],
                            [-1.484863281249943, 43.56376953124999],
                            [-1.245507812499937, 44.55986328124999],
                            [-1.07695312499996, 44.68984375],
                            [-1.152880859374989, 44.764013671875006],
                            [-1.245214843749977, 44.66669921874998],
                            [-1.081005859374983, 45.532421874999955],
                            [-0.548486328124966, 45.00058593750006],
                            [-0.790771484375028, 45.46801757812497],
                            [-1.195996093749983, 45.714453125],
                            [-1.03173828125, 45.741064453125006],
                            [-1.14628906249996, 46.311376953125034],
                            [-1.786523437499937, 46.51484375000001],
                            [-2.059375, 46.81030273437497],
                            [-2.01889648437492, 47.03764648437502],
                            [-2.197070312499989, 47.16293945312506],
                            [-2.027587890625028, 47.27358398437502],
                            [-1.742529296874949, 47.21596679687502],
                            [-1.97539062499996, 47.31069335937505],
                            [-2.503125, 47.31206054687496],
                            [-2.427685546874983, 47.47089843749998],
                            [-2.770312499999989, 47.513867187499955],
                            [-2.787207031249949, 47.62553710937496],
                            [-4.312109374999949, 47.82290039062502],
                            [-4.678808593749949, 48.03950195312501],
                            [-4.32944335937492, 48.169970703125045],
                            [-4.577148437499943, 48.2900390625],
                            [-4.241406249999926, 48.30366210937501],
                            [-4.719384765624966, 48.363134765625034],
                            [-4.7625, 48.45024414062502],
                            [-4.531201171874983, 48.61997070312506],
                            [-3.231445312499972, 48.84082031250003],
                            [-2.692333984374983, 48.53681640624998],
                            [-2.446191406249937, 48.64829101562506],
                            [-2.00371093749996, 48.58208007812499],
                            [-1.905712890624955, 48.69711914062506],
                            [-1.376464843749972, 48.65258789062503],
                            [-1.565478515624932, 48.805517578125034],
                            [-1.583105468749977, 49.20239257812506],
                            [-1.856445312499972, 49.68378906249998],
                            [-1.258642578124949, 49.68017578125006],
                            [-1.138525390624977, 49.38789062500001],
                            [-0.163476562499937, 49.296777343749994],
                            [0.41689453125008, 49.448388671874994],
                            [0.129394531250028, 49.508447265624966],
                            [0.186718749999983, 49.703027343749994],
                            [1.245507812500051, 49.99824218750001],
                            [1.5927734375, 50.25219726562506],
                            [1.672265625000023, 50.885009765625],
                            [2.52490234375, 51.097119140624955],
                            [2.759375, 50.750634765624994],
                            [3.10683593750008, 50.779443359374994],
                            [3.27333984375008, 50.53154296875002],
                            [3.595410156250068, 50.47734374999999],
                            [3.689355468750023, 50.30605468750002],
                            [4.174609375000017, 50.24648437500005],
                            [4.149316406250023, 49.971582031249994],
                            [4.545019531250063, 49.96025390624999],
                            [4.818652343750045, 50.153173828125034],
                            [4.867578125000051, 49.78813476562502],
                            [5.50732421875, 49.51088867187502],
                            [5.789746093749983, 49.53828125000001],
                        ],
                    ],
                ],
            },
            properties: {
                name: "法国",
                childNum: 10,
                cp: [2.8719426, 46.8222422],
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-7.186865234374949, 62.139306640624966],
                            [-7.116796874999977, 62.046826171874955],
                            [-7.379101562499926, 62.07480468749998],
                            [-7.186865234374949, 62.139306640624966],
                        ],
                    ],
                    [
                        [
                            [-6.631054687499955, 62.22788085937498],
                            [-6.655810546874932, 62.09360351562498],
                            [-6.840527343749983, 62.119287109374994],
                            [-6.725195312499949, 61.95146484374999],
                            [-7.17216796874996, 62.28559570312501],
                            [-6.631054687499955, 62.22788085937498],
                        ],
                    ],
                    [
                        [
                            [-6.406054687499932, 62.258642578125034],
                            [-6.544140624999926, 62.20561523437499],
                            [-6.554589843749994, 62.35566406250001],
                            [-6.406054687499932, 62.258642578125034],
                        ],
                    ],
                ],
            },
            properties: {
                name: "法罗群岛",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [158.31484375, 6.813671875],
                            [158.18339843750002, 6.801269531250057],
                            [158.13476562499997, 6.944824218749986],
                            [158.29462890625004, 6.951074218750023],
                            [158.31484375, 6.813671875],
                        ],
                    ],
                    [
                        [
                            [138.14267578125006, 9.50068359375004],
                            [138.06708984375004, 9.419042968750006],
                            [138.18583984375007, 9.593310546874989],
                            [138.14267578125006, 9.50068359375004],
                        ],
                    ],
                ],
            },
            properties: {
                name: "密克罗尼西亚",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [13.293554687500006, 2.161572265624997],
                        [13.172167968750017, 1.78857421875],
                        [13.21630859375, 1.2484375],
                        [13.851367187500017, 1.41875],
                        [14.180859375000011, 1.370214843749991],
                        [14.429882812500011, 0.901464843749991],
                        [14.32421875, 0.62421875],
                        [13.949609375000023, 0.353808593749989],
                        [13.860058593750011, -0.203320312500011],
                        [14.47412109375, -0.573437500000011],
                        [14.383984375000011, -1.890039062500009],
                        [14.162890625000017, -2.217578125],
                        [14.199804687500006, -2.354199218750011],
                        [13.993847656250011, -2.490625],
                        [13.886914062500011, -2.465429687500006],
                        [13.733789062500023, -2.138476562500003],
                        [13.464941406250006, -2.395410156250009],
                        [12.991992187500017, -2.313378906250009],
                        [12.793554687500006, -1.931835937500011],
                        [12.590429687500006, -1.826855468750011],
                        [12.43212890625, -1.928906250000011],
                        [12.446386718750006, -2.329980468750009],
                        [12.064453125, -2.41259765625],
                        [11.60546875, -2.342578125],
                        [11.537792968750011, -2.83671875],
                        [11.760156250000023, -2.983105468750011],
                        [11.715429687500006, -3.176953125000011],
                        [11.934179687500006, -3.318554687500011],
                        [11.8798828125, -3.665917968750009],
                        [11.685742187500011, -3.68203125],
                        [11.504296875000023, -3.5203125],
                        [11.234472656250006, -3.690820312500009],
                        [11.130175781250017, -3.916308593750003],
                        [10.34765625, -3.013085937500009],
                        [9.722070312500023, -2.467578125],
                        [10.06201171875, -2.549902343750006],
                        [9.624609375, -2.367089843750009],
                        [9.298925781250006, -1.903027343750011],
                        [9.483203125000017, -1.894628906250006],
                        [9.265625, -1.825097656250009],
                        [9.036328125000011, -1.308886718750003],
                        [9.31884765625, -1.632031250000011],
                        [9.501074218750006, -1.55517578125],
                        [9.295800781250023, -1.515234375],
                        [9.3466796875, -1.325],
                        [9.203808593750011, -1.382421875],
                        [9.064648437500011, -1.29833984375],
                        [8.703125, -0.591015625000011],
                        [8.946386718750006, -0.688769531250003],
                        [9.296679687500017, -0.351269531250011],
                        [9.354882812500023, 0.343603515624991],
                        [9.468164062500023, 0.15976562499999],
                        [9.796777343750023, 0.044238281249989],
                        [10.00146484375, 0.194970703124994],
                        [9.546484375, 0.295947265624989],
                        [9.324804687500006, 0.552099609374991],
                        [9.495312500000011, 0.664843749999989],
                        [9.617968750000017, 0.576513671874991],
                        [9.5908203125, 1.031982421875],
                        [9.636132812500023, 1.046679687499989],
                        [9.676464843750011, 1.07470703125],
                        [9.70458984375, 1.079980468749994],
                        [9.760546875000017, 1.07470703125],
                        [9.788671875, 1.025683593749989],
                        [9.803906250000011, 0.998730468749997],
                        [9.90673828125, 0.960107421874994],
                        [11.335351562500023, 0.999707031249997],
                        [11.332324218750017, 1.528369140624989],
                        [11.328710937500006, 2.167431640624997],
                        [11.348437500000017, 2.299707031249994],
                        [11.558984375000023, 2.302197265624997],
                        [13.2203125, 2.256445312499991],
                        [13.293554687500006, 2.161572265624997],
                    ],
                ],
            },
            properties: {
                name: "加蓬",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-1.065576171874966, 50.69023437500002],
                            [-1.25146484375, 50.58881835937498],
                            [-1.563427734374955, 50.666113281250006],
                            [-1.31279296874996, 50.77348632812502],
                            [-1.065576171874966, 50.69023437500002],
                        ],
                    ],
                    [
                        [
                            [-4.196777343749972, 53.321435546874966],
                            [-4.04936523437496, 53.30576171874998],
                            [-4.373046875, 53.13417968750002],
                            [-4.56787109375, 53.386474609375],
                            [-4.315087890625023, 53.41723632812503],
                            [-4.196777343749972, 53.321435546874966],
                        ],
                    ],
                    [
                        [
                            [-6.218017578125, 54.08872070312506],
                            [-6.649804687499937, 54.05864257812496],
                            [-7.007714843749937, 54.40668945312501],
                            [-7.324511718750017, 54.13344726562502],
                            [-7.606542968750006, 54.14384765625002],
                            [-8.118261718749977, 54.41425781250004],
                            [-7.75439453125, 54.59492187499998],
                            [-7.910595703124955, 54.698339843750006],
                            [-7.55039062499992, 54.767968749999966],
                            [-7.218652343749937, 55.09199218749998],
                            [-6.475048828124955, 55.24101562499999],
                            [-6.035791015624994, 55.14453125000003],
                            [-5.71684570312496, 54.817480468750034],
                            [-5.878613281249955, 54.64130859375001],
                            [-5.582519531249943, 54.66342773437498],
                            [-5.470410156249926, 54.500195312499955],
                            [-5.671093749999955, 54.54975585937501],
                            [-5.60678710937492, 54.272558593750034],
                            [-6.019042968749972, 54.05126953124997],
                            [-6.218017578125, 54.08872070312506],
                        ],
                    ],
                    [
                        [
                            [-5.105419921875011, 55.448828125000034],
                            [-5.331494140624955, 55.481054687500034],
                            [-5.318115234375, 55.709179687499955],
                            [-5.105419921875011, 55.448828125000034],
                        ],
                    ],
                    [
                        [
                            [-6.128906249999972, 55.93056640625002],
                            [-6.055322265624994, 55.69531249999997],
                            [-6.305078124999966, 55.60693359375],
                            [-6.286425781249989, 55.77250976562499],
                            [-6.491357421874994, 55.697314453125045],
                            [-6.462841796874955, 55.808251953124994],
                            [-6.128906249999972, 55.93056640625002],
                        ],
                    ],
                    [
                        [
                            [-5.970068359374949, 55.814550781250034],
                            [-6.071972656250011, 55.893115234375045],
                            [-5.72514648437496, 56.118554687499966],
                            [-5.970068359374949, 55.814550781250034],
                        ],
                    ],
                    [
                        [
                            [-5.77788085937496, 56.344335937500034],
                            [-6.313427734374983, 56.29365234375001],
                            [-6.138867187499955, 56.490625],
                            [-6.286328124999983, 56.61186523437502],
                            [-6.102734374999955, 56.645654296874966],
                            [-5.760839843749949, 56.49067382812501],
                            [-5.77788085937496, 56.344335937500034],
                        ],
                    ],
                    [
                        [
                            [-7.249853515624977, 57.115332031250006],
                            [-7.410546874999937, 57.38110351562506],
                            [-7.26713867187496, 57.37177734375001],
                            [-7.249853515624977, 57.115332031250006],
                        ],
                    ],
                    [
                        [
                            [-6.144726562499983, 57.50498046874998],
                            [-6.135546874999989, 57.31425781250002],
                            [-5.672460937499977, 57.252685546875],
                            [-5.94907226562492, 57.045166015625],
                            [-6.034375, 57.20122070312499],
                            [-6.322705078124926, 57.20249023437498],
                            [-6.761132812499994, 57.4423828125],
                            [-6.305957031249989, 57.67197265624998],
                            [-6.144726562499983, 57.50498046874998],
                        ],
                    ],
                    [
                        [
                            [-7.205566406250028, 57.682958984375006],
                            [-7.182617187499972, 57.53330078125006],
                            [-7.514746093749949, 57.60195312500002],
                            [-7.205566406250028, 57.682958984375006],
                        ],
                    ],
                    [
                        [
                            [-6.198681640624983, 58.36328125000003],
                            [-6.554589843749994, 58.092871093750006],
                            [-6.425195312499937, 58.02128906249999],
                            [-6.983105468749983, 57.75],
                            [-7.083447265624926, 57.81376953124999],
                            [-6.856835937499937, 57.92353515624998],
                            [-7.085253906249932, 58.18217773437499],
                            [-6.726464843749937, 58.189404296874955],
                            [-6.776464843750006, 58.30151367187497],
                            [-6.237451171874966, 58.50283203125005],
                            [-6.198681640624983, 58.36328125000003],
                        ],
                    ],
                    [
                        [
                            [-3.109667968749932, 58.515478515625034],
                            [-3.212353515624983, 58.32124023437501],
                            [-3.99003906249996, 57.95903320312502],
                            [-4.035595703124926, 57.85200195312498],
                            [-3.857128906249983, 57.81855468750001],
                            [-4.134521484375, 57.57773437500006],
                            [-3.402783203124955, 57.708251953125],
                            [-2.074072265624977, 57.70239257812506],
                            [-1.780664062499994, 57.474023437499966],
                            [-2.592675781249937, 56.56157226562499],
                            [-3.309960937499966, 56.36347656250004],
                            [-2.885156249999937, 56.397509765625045],
                            [-2.674267578124955, 56.25341796875],
                            [-3.362255859374955, 56.02763671875002],
                            [-3.789062499999972, 56.09521484375],
                            [-3.048730468749937, 55.951953125000045],
                            [-2.599316406249955, 56.02729492187501],
                            [-2.14707031249992, 55.90297851562502],
                            [-1.655371093749949, 55.57036132812502],
                            [-1.232421874999943, 54.703710937500034],
                            [-0.084375, 54.118066406249994],
                            [-0.20556640625, 54.021728515625],
                            [0.115332031250006, 53.609277343749994],
                            [-0.270019531249972, 53.73676757812504],
                            [-0.659912109375, 53.72402343750002],
                            [-0.293701171875, 53.69233398437504],
                            [0.270996093750028, 53.33549804687499],
                            [0.355761718750045, 53.15996093750002],
                            [0.0458984375, 52.90561523437498],
                            [0.279785156250028, 52.80869140625006],
                            [0.55878906250004, 52.96694335937505],
                            [1.05556640625008, 52.95898437500003],
                            [1.656738281249972, 52.753710937500045],
                            [1.74658203125, 52.46899414062503],
                            [1.59140625, 52.11977539062502],
                            [1.232421875000057, 51.97124023437496],
                            [1.188476562500057, 51.803369140624966],
                            [0.752246093750017, 51.729589843750034],
                            [0.890917968750017, 51.571435546874966],
                            [0.42451171875004, 51.465625],
                            [1.414941406250023, 51.36328125],
                            [1.397558593750034, 51.18203125000002],
                            [0.960156250000011, 50.92587890624998],
                            [0.299707031249994, 50.775976562500006],
                            [-0.785253906249949, 50.76542968749999],
                            [-1.416455078124955, 50.896875],
                            [-1.334472656249943, 50.82080078124997],
                            [-1.516748046874937, 50.747460937499966],
                            [-2.031054687499932, 50.72539062499999],
                            [-2.035839843749926, 50.603076171875045],
                            [-2.999414062499937, 50.71660156249999],
                            [-3.40458984374996, 50.63242187499998],
                            [-3.679785156250006, 50.239941406249955],
                            [-4.194580078124972, 50.39331054687503],
                            [-4.727978515624926, 50.29047851562504],
                            [-5.11850585937492, 50.038330078125],
                            [-5.622119140624932, 50.05068359375002],
                            [-4.188183593749926, 51.18852539062502],
                            [-3.135986328124972, 51.20502929687501],
                            [-2.433056640624926, 51.74072265625],
                            [-3.293115234374994, 51.390429687500045],
                            [-3.890771484374994, 51.591650390625006],
                            [-4.234570312499955, 51.56909179687503],
                            [-4.091015624999926, 51.65991210937506],
                            [-4.38627929687496, 51.74106445312506],
                            [-4.902294921874926, 51.626269531250045],
                            [-5.168359374999937, 51.74072265625],
                            [-5.183349609374972, 51.94965820312501],
                            [-4.217724609374983, 52.277441406250006],
                            [-3.980322265624949, 52.54174804687503],
                            [-4.101464843750023, 52.915478515624955],
                            [-4.683056640624926, 52.80615234374997],
                            [-4.268554687499943, 53.14453125],
                            [-3.427734374999972, 53.34067382812498],
                            [-3.097558593749937, 53.260302734375045],
                            [-3.064746093749932, 53.426855468750034],
                            [-2.74951171875, 53.310205078124994],
                            [-3.064599609374994, 53.512841796874966],
                            [-2.84648437499996, 54.135302734375045],
                            [-3.165966796874955, 54.12792968750006],
                            [-3.56938476562496, 54.46757812499996],
                            [-3.464599609374943, 54.77309570312505],
                            [-3.036230468749977, 54.95307617187501],
                            [-3.550439453124937, 54.94741210937502],
                            [-3.957910156249994, 54.780957031249955],
                            [-4.818066406249983, 54.84614257812501],
                            [-4.911230468749949, 54.68945312500006],
                            [-5.032324218749949, 54.76137695312505],
                            [-5.172705078124949, 54.98588867187496],
                            [-4.676757812499972, 55.50131835937498],
                            [-4.871679687499977, 55.87392578125005],
                            [-4.58408203124992, 55.93867187500001],
                            [-4.844091796874949, 56.05117187499999],
                            [-4.80029296875, 56.158349609374994],
                            [-5.228222656249983, 55.886328125],
                            [-5.084326171874977, 56.197460937499955],
                            [-5.41044921874996, 55.995361328125],
                            [-5.55644531249996, 55.389599609374955],
                            [-5.730664062499926, 55.33413085937502],
                            [-5.504492187499949, 55.80239257812502],
                            [-5.609570312499955, 56.055273437500034],
                            [-5.188378906249937, 56.75805664062503],
                            [-5.652441406249977, 56.531982421875],
                            [-6.133691406249966, 56.706689453124966],
                            [-5.730615234374994, 56.853076171875045],
                            [-5.86142578124992, 56.902685546875006],
                            [-5.561914062499994, 57.23271484375002],
                            [-5.794921874999972, 57.37880859375002],
                            [-5.581787109374972, 57.546777343749966],
                            [-5.744921874999989, 57.668310546875034],
                            [-5.608349609374955, 57.88134765625],
                            [-5.157226562499972, 57.88134765625],
                            [-5.413183593750006, 58.06972656250002],
                            [-5.338281250000023, 58.23872070312498],
                            [-5.008300781250028, 58.262646484374955],
                            [-5.016748046874966, 58.566552734374966],
                            [-4.433251953124937, 58.51284179687505],
                            [-3.25913085937492, 58.65],
                            [-3.053076171874949, 58.63481445312502],
                            [-3.109667968749932, 58.515478515625034],
                        ],
                    ],
                    [
                        [
                            [-3.057421874999932, 59.02963867187498],
                            [-2.793017578124989, 58.906933593749955],
                            [-3.331640624999949, 58.97124023437499],
                            [-3.31035156249996, 59.13081054687498],
                            [-3.057421874999932, 59.02963867187498],
                        ],
                    ],
                    [
                        [
                            [-1.30810546875, 60.5375],
                            [-1.052441406249955, 60.44448242187502],
                            [-1.299462890624994, 59.87866210937503],
                            [-1.290917968749937, 60.153466796874966],
                            [-1.663769531249983, 60.282519531250074],
                            [-1.374609374999949, 60.33291015625002],
                            [-1.571777343749972, 60.494433593750074],
                            [-1.363964843750011, 60.60957031249998],
                            [-1.30810546875, 60.5375],
                        ],
                    ],
                ],
            },
            properties: {
                name: "英国",
                childNum: 14,
                cp: [-2.5830348, 54.4598409],
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [46.30546875000002, 41.507714843749994],
                        [46.61894531250002, 41.34375],
                        [46.67255859375001, 41.28681640625],
                        [46.66240234375002, 41.245507812499994],
                        [46.62636718750002, 41.15966796875],
                        [46.534375, 41.08857421875],
                        [46.43095703125002, 41.077050781249994],
                        [46.086523437500006, 41.183837890625],
                        [45.28095703125001, 41.449560546875],
                        [45.21718750000002, 41.423193359375],
                        [45.00136718750002, 41.290966796875],
                        [44.97587890625002, 41.277490234374994],
                        [44.81132812500002, 41.259375],
                        [44.077246093750006, 41.182519531249994],
                        [43.43339843750002, 41.155517578125],
                        [43.20546875000002, 41.199169921875],
                        [43.15283203125, 41.23642578125],
                        [43.14101562500002, 41.26484375],
                        [43.17128906250002, 41.287939453125],
                        [43.149023437500006, 41.30712890625],
                        [43.05712890625, 41.352832031249996],
                        [42.90673828125, 41.466845703124996],
                        [42.82167968750002, 41.4923828125],
                        [42.78789062500002, 41.563720703125],
                        [42.75410156250001, 41.57890625],
                        [42.68242187500002, 41.585742187499996],
                        [42.60683593750002, 41.57880859375],
                        [42.590429687500006, 41.57070312499999],
                        [42.5673828125, 41.55927734375],
                        [42.46640625, 41.43984375],
                        [41.92578125, 41.495654296874996],
                        [41.82353515625002, 41.432373046875],
                        [41.779394531250006, 41.44052734375],
                        [41.701757812500006, 41.471582031249994],
                        [41.57656250000002, 41.497314453125],
                        [41.51005859375002, 41.517480468749994],
                        [41.701757812500006, 41.705419921875],
                        [41.76298828125002, 41.970019531249996],
                        [41.48876953125, 42.659326171874994],
                        [40.83662109375001, 43.0634765625],
                        [40.46210937500001, 43.145703125],
                        [39.97832031250002, 43.419824218749994],
                        [40.02373046875002, 43.48486328125],
                        [40.084570312500006, 43.553125],
                        [40.648046875, 43.53388671875],
                        [40.941992187500006, 43.41806640625],
                        [41.083105468750006, 43.374462890625],
                        [41.35820312500002, 43.333398437499994],
                        [41.46074218750002, 43.276318359375],
                        [41.58056640625, 43.21923828125],
                        [42.76064453125002, 43.169580078124994],
                        [42.99160156250002, 43.09150390625],
                        [43.00019531250001, 43.049658203125],
                        [43.08916015625002, 42.9890625],
                        [43.55781250000001, 42.844482421875],
                        [43.623046875, 42.80771484375],
                        [43.78261718750002, 42.747021484375],
                        [43.79873046875002, 42.727783203125],
                        [43.79541015625, 42.702978515625],
                        [43.74990234375002, 42.657519531249996],
                        [43.738378906250006, 42.616992187499996],
                        [43.759863281250006, 42.59384765625],
                        [43.82597656250002, 42.571533203125],
                        [43.95742187500002, 42.566552734374994],
                        [44.00468750000002, 42.595605468749994],
                        [44.10273437500001, 42.616357421874994],
                        [44.32949218750002, 42.70351562499999],
                        [44.505859375, 42.7486328125],
                        [44.77109375, 42.616796875],
                        [44.85048828125002, 42.746826171875],
                        [44.87099609375002, 42.756396484374996],
                        [44.943359375, 42.730273437499996],
                        [45.07158203125002, 42.694140625],
                        [45.160253906250006, 42.675],
                        [45.34375, 42.52978515625],
                        [45.56289062500002, 42.5357421875],
                        [45.70527343750001, 42.498095703124996],
                        [45.7275390625, 42.475048828125],
                        [45.63427734375, 42.234716796875],
                        [45.63857421875002, 42.205078125],
                        [46.21269531250002, 41.989892578124994],
                        [46.42988281250001, 41.890966796875],
                        [46.18427734375001, 41.7021484375],
                        [46.30546875000002, 41.507714843749994],
                    ],
                ],
            },
            properties: {
                name: "格鲁吉亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-0.068603515625, 11.115625],
                        [0.009423828125023, 11.02099609375],
                        [-0.08632812499999, 10.673046875],
                        [0.380859375, 10.291845703124991],
                        [0.264550781250023, 9.644726562499997],
                        [0.342578125000017, 9.604150390624994],
                        [0.2333984375, 9.463525390624994],
                        [0.525683593750017, 9.398486328124989],
                        [0.48876953125, 8.851464843749994],
                        [0.37255859375, 8.75927734375],
                        [0.686328125000017, 8.354882812499994],
                        [0.5, 7.546875],
                        [0.634765625, 7.353662109374994],
                        [0.525585937500011, 6.850927734374991],
                        [0.736914062500006, 6.452587890624997],
                        [1.187207031250011, 6.089404296874989],
                        [0.94970703125, 5.810253906249997],
                        [0.259667968750023, 5.75732421875],
                        [-2.001855468749994, 4.762451171875],
                        [-3.114013671875, 5.088671874999989],
                        [-2.815673828125, 5.153027343749997],
                        [-2.754980468749977, 5.432519531249994],
                        [-2.793652343749983, 5.60009765625],
                        [-2.998291015625, 5.71132812499999],
                        [-3.227148437499977, 6.749121093749991],
                        [-2.959082031249977, 7.454541015624997],
                        [-2.789746093749983, 7.931933593749989],
                        [-2.668847656249994, 8.022216796875],
                        [-2.613378906249977, 8.046679687499989],
                        [-2.600976562499994, 8.082226562499997],
                        [-2.619970703124977, 8.12109375],
                        [-2.61171875, 8.147558593749991],
                        [-2.538281249999983, 8.171630859375],
                        [-2.505859375, 8.208740234375],
                        [-2.600390624999989, 8.800439453124994],
                        [-2.649218749999989, 8.956591796874989],
                        [-2.689892578124983, 9.025097656249997],
                        [-2.746923828124977, 9.045117187499997],
                        [-2.705761718749983, 9.351367187499989],
                        [-2.695849609374989, 9.481347656249994],
                        [-2.706201171874994, 9.533935546875],
                        [-2.765966796874977, 9.658056640624991],
                        [-2.780517578125, 9.745849609375],
                        [-2.791162109374994, 10.432421874999989],
                        [-2.914892578124977, 10.592333984374989],
                        [-2.829931640624977, 10.998388671874991],
                        [-1.04248046875, 11.010058593749989],
                        [-0.627148437499983, 10.927392578124994],
                        [-0.299462890624994, 11.166894531249994],
                        [-0.068603515625, 11.115625],
                    ],
                ],
            },
            properties: {
                name: "加纳",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-11.389404296875, 12.404394531249991],
                        [-11.502197265625, 12.198632812499994],
                        [-11.30517578125, 12.015429687499989],
                        [-10.933203124999977, 12.205175781249991],
                        [-10.709228515625, 11.898730468749989],
                        [-10.274853515624983, 12.212646484375],
                        [-9.754003906249977, 12.029931640624994],
                        [-9.358105468749983, 12.255419921874989],
                        [-9.395361328124977, 12.464648437499989],
                        [-9.043066406249977, 12.40234375],
                        [-8.818310546874983, 11.922509765624994],
                        [-8.822021484375, 11.673242187499994],
                        [-8.398535156249977, 11.366552734374991],
                        [-8.666699218749983, 11.009472656249997],
                        [-8.33740234375, 10.990625],
                        [-8.266650390624989, 10.485986328124994],
                        [-8.007275390624983, 10.321875],
                        [-7.990625, 10.1625],
                        [-8.155175781249994, 9.973193359374989],
                        [-8.136962890625, 9.49570312499999],
                        [-7.896191406249983, 9.415869140624991],
                        [-7.918066406249977, 9.188525390624989],
                        [-7.839404296874989, 9.151611328125],
                        [-7.7998046875, 9.115039062499989],
                        [-7.777978515624994, 9.080859374999989],
                        [-7.902099609375, 9.01708984375],
                        [-7.938183593749983, 8.979785156249989],
                        [-7.950976562499989, 8.786816406249997],
                        [-7.719580078124977, 8.643017578124997],
                        [-7.696093749999989, 8.375585937499991],
                        [-7.823583984374977, 8.467675781249994],
                        [-7.953125, 8.477734375],
                        [-8.236962890624994, 8.455664062499991],
                        [-8.244140625, 8.407910156249997],
                        [-8.256103515625, 8.253710937499989],
                        [-8.217138671874977, 8.219677734374997],
                        [-8.140625, 8.181445312499989],
                        [-8.048583984375, 8.169726562499989],
                        [-8.009863281249977, 8.07851562499999],
                        [-8.126855468749994, 7.867724609374989],
                        [-8.115429687499983, 7.7607421875],
                        [-8.205957031249994, 7.59023437499999],
                        [-8.231884765624983, 7.556738281249991],
                        [-8.429980468749989, 7.601855468749989],
                        [-8.486425781249977, 7.558496093749994],
                        [-8.659765624999977, 7.688378906249994],
                        [-8.8896484375, 7.2626953125],
                        [-9.11757812499999, 7.215917968749991],
                        [-9.463818359374983, 7.415869140624991],
                        [-9.369140625, 7.703808593749997],
                        [-9.518261718749983, 8.34609375],
                        [-9.781982421875, 8.537695312499991],
                        [-10.064355468749994, 8.429882812499997],
                        [-10.147412109374983, 8.519726562499997],
                        [-10.233056640624994, 8.488818359374989],
                        [-10.283203125, 8.485156249999989],
                        [-10.360058593749983, 8.495507812499994],
                        [-10.394433593749994, 8.48095703125],
                        [-10.496435546874977, 8.362109374999989],
                        [-10.557714843749977, 8.315673828125],
                        [-10.686962890624983, 8.321679687499994],
                        [-10.712109374999983, 8.335253906249989],
                        [-10.677343749999977, 8.400585937499997],
                        [-10.500537109374989, 8.687548828124989],
                        [-10.615966796875, 9.059179687499991],
                        [-10.726855468749989, 9.081689453124994],
                        [-10.747021484374983, 9.095263671874989],
                        [-10.749951171874983, 9.122363281249989],
                        [-10.687646484374994, 9.261132812499994],
                        [-10.682714843749977, 9.289355468749989],
                        [-10.758593749999989, 9.385351562499991],
                        [-11.047460937499977, 9.786328125],
                        [-11.180859374999983, 9.925341796874989],
                        [-11.205664062499977, 9.977734375],
                        [-11.273632812499983, 9.996533203124997],
                        [-11.911083984374983, 9.993017578124991],
                        [-12.142333984375, 9.87539062499999],
                        [-12.427978515625, 9.898144531249997],
                        [-12.557861328125, 9.704980468749994],
                        [-12.755859375, 9.373583984374989],
                        [-12.958789062499989, 9.263330078124994],
                        [-13.077294921874994, 9.069628906249989],
                        [-13.292675781249983, 9.04921875],
                        [-13.436279296875, 9.4203125],
                        [-13.691357421874983, 9.535791015624994],
                        [-13.689794921874977, 9.927783203124989],
                        [-13.820117187499989, 9.88720703125],
                        [-14.045019531249977, 10.141259765624994],
                        [-14.426904296874994, 10.248339843749989],
                        [-14.609570312499983, 10.549853515624989],
                        [-14.593505859375, 10.766699218749991],
                        [-14.677343749999977, 10.68896484375],
                        [-14.775927734374989, 10.931640625],
                        [-14.88671875, 10.968066406249989],
                        [-14.975, 10.803417968749997],
                        [-15.051220703124983, 10.834570312499991],
                        [-15.043017578124989, 10.940136718749997],
                        [-14.9990234375, 10.9921875],
                        [-14.944433593749977, 11.072167968749994],
                        [-14.779296875, 11.405517578125],
                        [-14.720263671874989, 11.48193359375],
                        [-14.682958984374977, 11.508496093749997],
                        [-14.604785156249989, 11.511621093749994],
                        [-14.452441406249989, 11.556201171874989],
                        [-14.327832031249983, 11.629785156249994],
                        [-14.265576171874983, 11.659912109375],
                        [-14.122314453125, 11.65195312499999],
                        [-13.953222656249977, 11.664599609374989],
                        [-13.732763671874977, 11.736035156249997],
                        [-13.730664062499983, 11.959863281249994],
                        [-13.737988281249983, 12.009667968749994],
                        [-13.816308593749994, 12.054492187499989],
                        [-13.948876953124994, 12.178173828124997],
                        [-13.8875, 12.246875],
                        [-13.759765625, 12.262353515624994],
                        [-13.673535156249983, 12.478515625],
                        [-13.732617187499983, 12.592822265624989],
                        [-13.729248046875, 12.673925781249991],
                        [-13.082910156249994, 12.633544921875],
                        [-13.061279296875, 12.489990234375],
                        [-12.930712890624989, 12.532275390624989],
                        [-12.399072265624994, 12.340087890625],
                        [-11.389404296875, 12.404394531249991],
                    ],
                ],
            },
            properties: {
                name: "几内亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-16.763330078124994, 13.064160156249997],
                        [-16.824804687499977, 13.341064453125],
                        [-16.669335937499994, 13.475],
                        [-16.41337890624999, 13.269726562499997],
                        [-15.427490234375, 13.46835937499999],
                        [-16.135449218749983, 13.4482421875],
                        [-16.351806640625, 13.34335937499999],
                        [-16.56230468749999, 13.587304687499994],
                        [-15.509667968749994, 13.586230468750003],
                        [-15.426855468749977, 13.727001953124997],
                        [-15.108349609374983, 13.81210937499999],
                        [-14.405468749999983, 13.503710937500003],
                        [-13.977392578124977, 13.54345703125],
                        [-13.826708984374989, 13.4078125],
                        [-14.246777343749983, 13.23583984375],
                        [-15.151123046875, 13.556494140624991],
                        [-15.286230468749977, 13.39599609375],
                        [-15.814404296874983, 13.325146484374997],
                        [-15.834277343749989, 13.156445312499997],
                        [-16.648779296874977, 13.154150390624991],
                        [-16.763330078124994, 13.064160156249997],
                    ],
                ],
            },
            properties: {
                name: "冈比亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-15.895898437499966, 11.082470703124969],
                            [-15.963964843749977, 11.05898437499998],
                            [-15.937695312499955, 11.192773437499966],
                            [-15.895898437499966, 11.082470703124969],
                        ],
                    ],
                    [
                        [
                            [-16.11450195312503, 11.059423828124977],
                            [-16.236425781249977, 11.113427734374966],
                            [-16.06733398437501, 11.197216796874983],
                            [-16.11450195312503, 11.059423828124977],
                        ],
                    ],
                    [
                        [
                            [-15.901806640624926, 11.4658203125],
                            [-16.02319335937497, 11.477148437499991],
                            [-15.964550781249926, 11.59829101562498],
                            [-15.901806640624926, 11.4658203125],
                        ],
                    ],
                    [
                        [
                            [-15.986425781249949, 11.882031249999969],
                            [-16.038330078124943, 11.759716796875011],
                            [-16.15244140624992, 11.876806640624963],
                            [-15.986425781249949, 11.882031249999969],
                        ],
                    ],
                    [
                        [
                            [-13.759765625, 12.262353515624994],
                            [-13.8875, 12.246875],
                            [-13.948876953124966, 12.178173828124997],
                            [-13.737988281250011, 12.009667968750037],
                            [-13.730664062499926, 11.959863281250009],
                            [-13.73276367187492, 11.736035156249983],
                            [-13.953222656249977, 11.664599609374989],
                            [-14.265576171874926, 11.659912109375014],
                            [-14.327832031250011, 11.629785156250009],
                            [-14.452441406249989, 11.556201171875017],
                            [-14.604785156249932, 11.511621093749994],
                            [-14.682958984374949, 11.508496093749983],
                            [-14.720263671875017, 11.481933593749986],
                            [-14.779296874999972, 11.405517578125057],
                            [-14.944433593749949, 11.072167968749994],
                            [-14.999023437499972, 10.992187500000043],
                            [-15.04301757812496, 10.940136718750011],
                            [-15.09375, 11.011035156249974],
                            [-15.054589843749994, 11.141943359375006],
                            [-15.222119140624926, 11.030908203125037],
                            [-15.216699218749994, 11.15625],
                            [-15.39311523437496, 11.217236328124983],
                            [-15.354687499999955, 11.396337890624963],
                            [-15.479492187499972, 11.410302734374966],
                            [-15.072656249999937, 11.597802734374966],
                            [-15.230371093750023, 11.686767578124972],
                            [-15.412988281249994, 11.615234374999972],
                            [-15.501904296875011, 11.723779296874966],
                            [-15.467187499999937, 11.842822265624974],
                            [-15.078271484374937, 11.968994140625014],
                            [-15.941748046875006, 11.786621093749986],
                            [-15.92021484374996, 11.93779296874996],
                            [-16.138427734375, 11.917285156250045],
                            [-16.32807617187501, 12.051611328124963],
                            [-16.244580078124955, 12.237109375],
                            [-16.43681640624996, 12.204150390625045],
                            [-16.711816406249937, 12.354833984375006],
                            [-16.656933593749955, 12.364355468749991],
                            [-16.52133789062495, 12.348632812499986],
                            [-16.41630859374996, 12.367675781250057],
                            [-16.24150390624996, 12.443310546875011],
                            [-16.144189453124937, 12.457421875000037],
                            [-15.839550781249955, 12.437890624999966],
                            [-15.57480468749992, 12.490380859375009],
                            [-15.19609375, 12.679931640624986],
                            [-14.3492187499999, 12.67641601562498],
                            [-14.064843749999966, 12.675292968750014],
                            [-13.729248046875, 12.673925781250006],
                            [-13.732617187499983, 12.592822265625003],
                            [-13.673535156249926, 12.478515624999986],
                            [-13.759765625, 12.262353515624994],
                        ],
                    ],
                ],
            },
            properties: {
                name: "几内亚比绍",
                childNum: 5,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [11.332324218750017, 1.528369140624989],
                            [11.335351562500023, 0.999707031250011],
                            [9.906738281250028, 0.960107421875037],
                            [9.80390625000004, 0.998730468749997],
                            [9.788671875000034, 1.025683593749974],
                            [9.760546874999989, 1.074707031250014],
                            [9.704589843750057, 1.079980468750023],
                            [9.676464843750011, 1.074707031250014],
                            [9.636132812500051, 1.046679687499989],
                            [9.590820312500057, 1.031982421875014],
                            [9.599414062500045, 1.054443359374972],
                            [9.509863281250006, 1.114794921875017],
                            [9.385937500000068, 1.13925781250002],
                            [9.807031250000051, 1.927490234375028],
                            [9.77968750000008, 2.068212890625006],
                            [9.800781250000028, 2.304443359375],
                            [9.826171875000057, 2.297802734374969],
                            [9.8369140625, 2.242382812500054],
                            [9.870117187500028, 2.21328125],
                            [9.979882812499994, 2.167773437500045],
                            [10.790917968750023, 2.167578125],
                            [11.096582031250051, 2.167480468749986],
                            [11.328710937500006, 2.167431640624969],
                            [11.332324218750017, 1.528369140624989],
                        ],
                    ],
                    [
                        [
                            [8.735742187500023, 3.758300781249972],
                            [8.910058593750023, 3.758203125000051],
                            [8.946093750000074, 3.627539062499977],
                            [8.704003906250051, 3.223632812500028],
                            [8.474902343749989, 3.264648437500043],
                            [8.464648437500045, 3.450585937499994],
                            [8.735742187500023, 3.758300781249972],
                        ],
                    ],
                ],
            },
            properties: {
                name: "赤道几内亚",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [23.852246093749983, 35.53544921874999],
                            [24.166015625000057, 35.59521484375],
                            [24.108984374999977, 35.49580078124998],
                            [24.35400390625, 35.359472656250034],
                            [25.73017578125004, 35.34858398437501],
                            [25.791308593750074, 35.122851562500045],
                            [26.32021484375008, 35.315136718749955],
                            [26.165625, 35.018603515625045],
                            [24.79980468750003, 34.93447265625002],
                            [24.70888671875008, 35.08906250000001],
                            [24.463671875000045, 35.160351562499955],
                            [23.59277343749997, 35.257226562499966],
                            [23.56982421875, 35.534765625000034],
                            [23.67265624999999, 35.51391601562506],
                            [23.736914062500034, 35.65551757812503],
                            [23.852246093749983, 35.53544921874999],
                        ],
                    ],
                    [
                        [
                            [27.17607421874999, 35.46528320312498],
                            [27.070703125000023, 35.59775390624998],
                            [27.22314453125, 35.820458984374966],
                            [27.17607421874999, 35.46528320312498],
                        ],
                    ],
                    [
                        [
                            [23.053808593750034, 36.18979492187498],
                            [22.91083984375004, 36.220996093750045],
                            [22.950488281250045, 36.38393554687502],
                            [23.053808593750034, 36.18979492187498],
                        ],
                    ],
                    [
                        [
                            [27.84277343750003, 35.929296875000034],
                            [27.71552734375004, 35.95732421874996],
                            [27.71630859375003, 36.17158203125001],
                            [28.23183593750005, 36.43364257812502],
                            [28.087792968750023, 36.06533203125002],
                            [27.84277343750003, 35.929296875000034],
                        ],
                    ],
                    [
                        [
                            [25.48242187500003, 36.39262695312502],
                            [25.37050781250005, 36.35893554687499],
                            [25.408984375000074, 36.473730468750006],
                            [25.48242187500003, 36.39262695312502],
                        ],
                    ],
                    [
                        [
                            [26.46064453125001, 36.58540039062501],
                            [26.270019531250057, 36.54692382812499],
                            [26.370019531250023, 36.63857421875002],
                            [26.46064453125001, 36.58540039062501],
                        ],
                    ],
                    [
                        [
                            [26.94960937500005, 36.72709960937502],
                            [27.214941406250006, 36.89863281249998],
                            [27.352148437499977, 36.86889648437506],
                            [26.94960937500005, 36.72709960937502],
                        ],
                    ],
                    [
                        [
                            [25.859375, 36.79042968750005],
                            [25.74316406250003, 36.78974609374998],
                            [26.06445312500003, 36.90273437500002],
                            [25.859375, 36.79042968750005],
                        ],
                    ],
                    [
                        [
                            [27.01972656250004, 36.95903320312502],
                            [26.91992187500003, 36.94521484375005],
                            [26.88867187499997, 37.087255859375034],
                            [27.01972656250004, 36.95903320312502],
                        ],
                    ],
                    [
                        [
                            [25.278906250000034, 37.06840820312502],
                            [25.105468750000057, 37.034960937500045],
                            [25.235058593750068, 37.148535156250006],
                            [25.278906250000034, 37.06840820312502],
                        ],
                    ],
                    [
                        [
                            [25.54589843749997, 36.96757812499999],
                            [25.45673828125001, 36.9296875],
                            [25.361914062500063, 37.07041015624998],
                            [25.52529296875005, 37.19638671875006],
                            [25.54589843749997, 36.96757812499999],
                        ],
                    ],
                    [
                        [
                            [24.523535156250063, 37.125097656250006],
                            [24.42480468750003, 37.131982421874994],
                            [24.48378906250005, 37.21020507812503],
                            [24.523535156250063, 37.125097656250006],
                        ],
                    ],
                    [
                        [
                            [25.402734375000023, 37.419140624999955],
                            [25.312695312500068, 37.48930664062496],
                            [25.462988281250063, 37.47109375],
                            [25.402734375000023, 37.419140624999955],
                        ],
                    ],
                    [
                        [
                            [26.029296875000057, 37.529394531250034],
                            [26.086328125000023, 37.63491210937505],
                            [26.351367187500017, 37.67431640625],
                            [26.029296875000057, 37.529394531250034],
                        ],
                    ],
                    [
                        [
                            [25.255859375000057, 37.59960937500006],
                            [25.156347656250034, 37.54506835937505],
                            [24.99648437500005, 37.676904296874994],
                            [25.255859375000057, 37.59960937500006],
                        ],
                    ],
                    [
                        [
                            [24.35595703125003, 37.57685546875004],
                            [24.28896484375005, 37.52827148437498],
                            [24.37910156250004, 37.682714843750006],
                            [24.35595703125003, 37.57685546875004],
                        ],
                    ],
                    [
                        [
                            [26.82441406250004, 37.81142578125005],
                            [27.05507812500005, 37.70927734375002],
                            [26.84492187500004, 37.64472656250001],
                            [26.58105468750003, 37.723730468750034],
                            [26.82441406250004, 37.81142578125005],
                        ],
                    ],
                    [
                        [
                            [20.888476562500074, 37.805371093749955],
                            [20.993945312500074, 37.70800781250003],
                            [20.81855468750004, 37.66474609375001],
                            [20.61953125000008, 37.855029296875045],
                            [20.691503906250006, 37.929541015625034],
                            [20.888476562500074, 37.805371093749955],
                        ],
                    ],
                    [
                        [
                            [24.991699218750057, 37.75961914062506],
                            [24.962207031250074, 37.69238281250003],
                            [24.7001953125, 37.961669921875],
                            [24.956347656250045, 37.90478515625006],
                            [24.991699218750057, 37.75961914062506],
                        ],
                    ],
                    [
                        [
                            [20.61230468750003, 38.38334960937502],
                            [20.761328125, 38.07055664062497],
                            [20.523535156250063, 38.106640624999955],
                            [20.4521484375, 38.23417968750002],
                            [20.35253906250003, 38.179882812499955],
                            [20.563183593750068, 38.474951171875034],
                            [20.61230468750003, 38.38334960937502],
                        ],
                    ],
                    [
                        [
                            [26.094042968750017, 38.21806640625002],
                            [25.891894531250045, 38.243310546874994],
                            [25.991406250000068, 38.353515625],
                            [25.846093750000023, 38.57402343749996],
                            [26.16035156250001, 38.54072265625001],
                            [26.094042968750017, 38.21806640625002],
                        ],
                    ],
                    [
                        [
                            [20.68671875000001, 38.60869140625002],
                            [20.5546875, 38.58256835937502],
                            [20.69414062499999, 38.84423828125003],
                            [20.68671875000001, 38.60869140625002],
                        ],
                    ],
                    [
                        [
                            [24.67470703125005, 38.80922851562502],
                            [24.54101562499997, 38.788671875],
                            [24.485644531250074, 38.980273437500045],
                            [24.67470703125005, 38.80922851562502],
                        ],
                    ],
                    [
                        [
                            [23.41542968750008, 38.958642578124994],
                            [23.525, 38.8134765625],
                            [24.127539062500034, 38.648486328125045],
                            [24.27578125000005, 38.22001953124996],
                            [24.58837890625003, 38.12397460937504],
                            [24.53652343750005, 37.97973632812506],
                            [24.212011718750006, 38.11752929687506],
                            [24.040136718750006, 38.389990234375034],
                            [23.65078125000008, 38.44306640625001],
                            [23.25214843750004, 38.80122070312498],
                            [22.870312500000068, 38.870507812499966],
                            [23.258203125000023, 39.03134765625006],
                            [23.41542968750008, 38.958642578124994],
                        ],
                    ],
                    [
                        [
                            [26.41015625000003, 39.329443359375034],
                            [26.59560546875005, 39.04882812499997],
                            [26.488671875000023, 39.074804687500034],
                            [26.46875, 38.97280273437502],
                            [26.10791015625, 39.08105468749997],
                            [26.273144531249983, 39.19755859374999],
                            [26.072363281250034, 39.095605468749994],
                            [25.84414062500008, 39.20004882812506],
                            [26.16542968750008, 39.37353515625006],
                            [26.41015625000003, 39.329443359375034],
                        ],
                    ],
                    [
                        [
                            [20.077929687500045, 39.432714843750034],
                            [19.883984375000068, 39.461523437500034],
                            [19.646484375, 39.76708984375003],
                            [19.926074218750017, 39.773730468750045],
                            [19.8466796875, 39.66811523437502],
                            [20.077929687500045, 39.432714843750034],
                        ],
                    ],
                    [
                        [
                            [25.43769531250004, 39.98330078125002],
                            [25.357031250000063, 39.80810546875003],
                            [25.24941406250005, 39.89414062500006],
                            [25.06220703125004, 39.852392578125006],
                            [25.05800781250005, 39.999658203124966],
                            [25.43769531250004, 39.98330078125002],
                        ],
                    ],
                    [
                        [
                            [24.774218750000074, 40.615185546874955],
                            [24.515527343750023, 40.64702148437496],
                            [24.623339843750045, 40.79291992187501],
                            [24.774218750000074, 40.615185546874955],
                        ],
                    ],
                    [
                        [
                            [26.03896484375008, 40.726757812499955],
                            [25.10449218750003, 40.994726562500006],
                            [24.792968750000057, 40.857519531250034],
                            [24.47705078125, 40.94775390625003],
                            [24.082324218750074, 40.72407226562504],
                            [23.762792968750063, 40.74780273437497],
                            [23.866796875000034, 40.41855468750006],
                            [24.21279296875008, 40.32778320312502],
                            [24.343359375000034, 40.14770507812503],
                            [23.913183593750063, 40.35878906250005],
                            [23.72792968750008, 40.329736328124994],
                            [23.96748046875001, 40.11455078125002],
                            [23.947070312500045, 39.96557617187506],
                            [23.66455078125003, 40.22382812499998],
                            [23.42626953125, 40.26396484374999],
                            [23.62734375, 39.92407226562503],
                            [22.896484375000057, 40.39990234374997],
                            [22.92226562500008, 40.59086914062499],
                            [22.629492187500034, 40.49555664062501],
                            [22.59218750000005, 40.03691406250002],
                            [23.327734374999977, 39.174902343750006],
                            [23.15468750000008, 39.10146484375005],
                            [23.16171875, 39.25776367187501],
                            [22.92138671874997, 39.30634765625004],
                            [22.886035156250074, 39.16997070312496],
                            [23.066699218750017, 39.03793945312498],
                            [22.569140625000074, 38.86748046874999],
                            [23.25292968750003, 38.66123046875006],
                            [23.68398437500008, 38.35244140625002],
                            [23.96699218750001, 38.275],
                            [24.024511718750006, 38.139794921874966],
                            [24.01972656250001, 37.67773437499997],
                            [23.50175781249999, 38.03486328124998],
                            [23.03632812500004, 37.87836914062501],
                            [23.48925781250003, 37.440185546875],
                            [23.16152343750005, 37.333837890625006],
                            [22.725390625000017, 37.542138671874966],
                            [23.16015625000003, 36.448095703125034],
                            [22.717187500000023, 36.79394531250006],
                            [22.42773437500003, 36.47578124999998],
                            [22.08046875000008, 37.028955078124966],
                            [21.95556640625003, 36.990087890625034],
                            [21.892382812500045, 36.73730468749997],
                            [21.58291015625005, 37.080957031249994],
                            [21.678906250000068, 37.38720703125003],
                            [21.124707031250068, 37.89160156250003],
                            [21.40371093750005, 38.19667968750002],
                            [21.658398437500068, 38.17509765624996],
                            [21.82470703125003, 38.328125],
                            [22.846386718750068, 37.96757812499996],
                            [23.18349609375008, 38.133691406249966],
                            [22.421679687500045, 38.43852539062499],
                            [22.319921875, 38.35683593750005],
                            [21.96533203124997, 38.412451171875006],
                            [21.47255859375005, 38.321386718750006],
                            [21.3310546875, 38.48730468749997],
                            [21.303320312500034, 38.373925781249966],
                            [21.113183593750023, 38.38466796875002],
                            [20.768554687500057, 38.874414062499966],
                            [21.111621093750045, 38.89628906249999],
                            [21.11835937500001, 39.029980468749955],
                            [20.71337890625, 39.03515625000003],
                            [20.300781250000057, 39.32709960937501],
                            [20.19140625, 39.545800781249966],
                            [20.099414062500074, 39.641259765624966],
                            [20.001269531250074, 39.70942382812501],
                            [20.022558593750063, 39.710693359375],
                            [20.059765624999983, 39.69912109375002],
                            [20.13105468750004, 39.66162109375003],
                            [20.206835937500017, 39.65351562499998],
                            [20.382421875, 39.802636718749994],
                            [20.381640625000017, 39.84179687500006],
                            [20.311328125000074, 39.95078125000006],
                            [20.311132812500034, 39.97944335937504],
                            [20.338476562500006, 39.991064453125006],
                            [20.38369140625008, 40.0171875],
                            [20.408007812500074, 40.049462890624994],
                            [20.4560546875, 40.065576171874994],
                            [20.657421875000068, 40.11738281249998],
                            [20.881640625000017, 40.467919921874994],
                            [21.030859375000034, 40.62246093750002],
                            [20.95576171875001, 40.775292968749994],
                            [20.96425781250005, 40.84990234374999],
                            [21.575781250000034, 40.86894531249996],
                            [21.627539062500006, 40.896337890625034],
                            [21.77949218750004, 40.95043945312506],
                            [21.99335937500001, 41.13095703125006],
                            [22.18447265625005, 41.15864257812501],
                            [22.49355468750005, 41.118505859375006],
                            [22.603613281249977, 41.14018554687499],
                            [22.724804687500068, 41.17851562499999],
                            [22.78388671875004, 41.33198242187498],
                            [23.155957031250068, 41.32207031249999],
                            [23.239843750000034, 41.38496093750001],
                            [23.372070312500057, 41.3896484375],
                            [23.433398437500017, 41.39873046874999],
                            [23.53583984375001, 41.38603515624999],
                            [23.63515625000008, 41.386767578125045],
                            [24.011328124999977, 41.460058593750034],
                            [24.03291015625004, 41.469091796875034],
                            [24.05605468750005, 41.527246093749966],
                            [24.38671875, 41.523535156250006],
                            [24.487890625, 41.55522460937499],
                            [24.518261718750068, 41.55253906249996],
                            [24.773730468750045, 41.356103515624994],
                            [24.99355468750008, 41.36499023437503],
                            [25.133398437500063, 41.31577148437506],
                            [25.251171875000068, 41.243554687499994],
                            [25.923339843750057, 41.311914062499966],
                            [26.066406250000057, 41.35068359375006],
                            [26.135351562499977, 41.3857421875],
                            [26.155175781250023, 41.43486328124999],
                            [26.143554687500057, 41.52153320312496],
                            [26.085546875000063, 41.704150390625045],
                            [26.10742187499997, 41.72568359374998],
                            [26.20058593750005, 41.74379882812502],
                            [26.320898437500034, 41.716552734375],
                            [26.581347656250074, 41.60126953125004],
                            [26.62490234375008, 41.401757812499994],
                            [26.330664062499977, 41.23876953125],
                            [26.331054687500057, 40.954492187499994],
                            [26.03896484375008, 40.726757812499955],
                        ],
                    ],
                ],
            },
            properties: {
                name: "希腊",
                childNum: 29,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-61.71552734375, 12.012646484374997],
                        [-61.714990234374994, 12.18515625],
                        [-61.60703125, 12.223291015624994],
                        [-61.71552734375, 12.012646484374997],
                    ],
                ],
            },
            properties: {
                name: "格林纳达",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-46.266699218750006, 60.781396484374994],
                            [-46.381542968749955, 60.66030273437502],
                            [-46.7880859375, 60.758398437500034],
                            [-46.205224609374994, 60.943505859374994],
                            [-46.266699218750006, 60.781396484374994],
                        ],
                    ],
                    [
                        [
                            [-37.03125, 65.53198242187497],
                            [-37.23842773437494, 65.60986328125003],
                            [-37.047509765624966, 65.722265625],
                            [-37.03125, 65.53198242187497],
                        ],
                    ],
                    [
                        [
                            [-51.01367187499994, 69.55249023437497],
                            [-51.202050781249966, 69.525],
                            [-51.33886718749994, 69.73203125000006],
                            [-51.094580078125006, 69.92416992187503],
                            [-50.67900390624999, 69.84853515625],
                            [-51.01367187499994, 69.55249023437497],
                        ],
                    ],
                    [
                        [
                            [-52.73115234375001, 69.94472656250005],
                            [-52.0453125, 69.8072265625],
                            [-51.90019531249999, 69.60478515625007],
                            [-53.57841796874996, 69.25664062500002],
                            [-54.18271484374995, 69.40351562500001],
                            [-53.65830078124998, 69.46513671875005],
                            [-53.825, 69.54033203124999],
                            [-54.91914062499998, 69.71362304687503],
                            [-54.78789062499996, 69.94985351562502],
                            [-54.322607421875034, 69.94189453125],
                            [-54.83076171875001, 70.13295898437502],
                            [-54.37163085937499, 70.31728515625],
                            [-53.296728515625034, 70.20537109375002],
                            [-52.73115234375001, 69.94472656250005],
                        ],
                    ],
                    [
                        [
                            [-51.67514648437498, 70.855224609375],
                            [-52.11938476562497, 70.87065429687502],
                            [-52.10673828124999, 70.96801757812497],
                            [-51.67514648437498, 70.855224609375],
                        ],
                    ],
                    [
                        [
                            [-25.43232421875001, 70.92133789062495],
                            [-25.402246093749994, 70.65268554687503],
                            [-26.217871093749977, 70.45405273437498],
                            [-26.604687499999926, 70.55336914062497],
                            [-28.03525390624995, 70.48681640625],
                            [-27.61723632812496, 70.91376953125001],
                            [-26.621777343749955, 70.87563476562497],
                            [-25.81889648437499, 71.04365234375001],
                            [-25.43232421875001, 70.92133789062495],
                        ],
                    ],
                    [
                        [
                            [-53.53520507812493, 71.04082031250005],
                            [-53.9578125, 71.12773437499999],
                            [-53.58447265625003, 71.29707031249995],
                            [-53.53520507812493, 71.04082031250005],
                        ],
                    ],
                    [
                        [
                            [-55.01689453124999, 72.79111328125003],
                            [-55.56660156249998, 72.56435546875002],
                            [-56.214794921874955, 72.71918945312495],
                            [-55.01689453124999, 72.79111328125003],
                        ],
                    ],
                    [
                        [
                            [-18.000537109374932, 75.40732421875003],
                            [-17.391992187499937, 75.03691406250007],
                            [-18.670800781249966, 75.00166015624998],
                            [-18.856054687499977, 75.31914062500002],
                            [-18.000537109374932, 75.40732421875003],
                        ],
                    ],
                    [
                        [
                            [-18.58261718749995, 76.042333984375],
                            [-19.085351562499966, 76.43037109375001],
                            [-18.882470703124937, 76.70380859375001],
                            [-18.58261718749995, 76.042333984375],
                        ],
                    ],
                    [
                        [
                            [-71.667333984375, 77.32529296874998],
                            [-72.48955078124999, 77.43164062499997],
                            [-71.43344726562495, 77.394384765625],
                            [-71.667333984375, 77.32529296874998],
                        ],
                    ],
                    [
                        [
                            [-17.6125, 79.82587890624995],
                            [-18.662011718749966, 79.72001953125005],
                            [-19.13828125, 79.85234375000002],
                            [-17.98291015625, 80.05517578125003],
                            [-17.471386718749955, 80.02871093749997],
                            [-17.6125, 79.82587890624995],
                        ],
                    ],
                    [
                        [
                            [-44.86455078124999, 82.08364257812502],
                            [-46.75190429687501, 82.34819335937502],
                            [-47.27226562499996, 82.65693359375001],
                            [-46.399169921875, 82.692138671875],
                            [-44.91748046875003, 82.48051757812505],
                            [-44.86455078124999, 82.08364257812502],
                        ],
                    ],
                    [
                        [
                            [-29.952880859375, 83.56484374999997],
                            [-25.795068359374994, 83.26098632812497],
                            [-31.99267578125, 83.0853515625],
                            [-32.03271484374997, 82.98344726562502],
                            [-25.12338867187495, 83.15961914062501],
                            [-24.47031249999995, 82.87739257812498],
                            [-21.582519531249943, 82.6341796875],
                            [-23.118066406249966, 82.32470703125003],
                            [-29.57939453124996, 82.16118164062502],
                            [-29.887402343749983, 82.05483398437502],
                            [-29.543847656249994, 81.93994140624997],
                            [-27.839501953124966, 82.04887695312505],
                            [-25.148828124999966, 82.001123046875],
                            [-24.293066406249977, 81.70097656250005],
                            [-23.103710937499983, 82.01181640625003],
                            [-21.337988281249977, 82.068701171875],
                            [-21.230517578125017, 81.60136718749999],
                            [-23.11772460937499, 80.77817382812498],
                            [-19.62993164062499, 81.63989257812503],
                            [-17.456054687499943, 81.397705078125],
                            [-16.12070312499995, 81.776611328125],
                            [-14.241992187500017, 81.81386718750005],
                            [-12.434423828125006, 81.68251953125002],
                            [-11.430664062499972, 81.45683593750005],
                            [-13.126220703124972, 81.08779296875],
                            [-14.452343749999955, 80.99311523437498],
                            [-14.503564453124994, 80.76328125000006],
                            [-16.76059570312492, 80.573388671875],
                            [-15.937255859374972, 80.42763671874997],
                            [-16.48876953124997, 80.25195312499997],
                            [-18.070947265624994, 80.17207031249995],
                            [-19.429199218749943, 80.25771484375],
                            [-20.150146484375, 80.01123046874997],
                            [-18.99199218749996, 79.17836914062502],
                            [-21.133740234374926, 78.65864257812501],
                            [-21.729589843749977, 77.70854492187499],
                            [-20.862597656249932, 77.91186523437503],
                            [-19.490429687499983, 77.71889648437497],
                            [-19.46752929687503, 77.56582031250005],
                            [-20.162060546874926, 77.68984375],
                            [-20.680810546875023, 77.61899414062503],
                            [-20.23193359374997, 77.36840820312497],
                            [-19.30029296874997, 77.22236328124995],
                            [-18.442626953124943, 77.259375],
                            [-18.51030273437496, 76.77817382812498],
                            [-20.48671875, 76.92080078125],
                            [-21.614697265624926, 76.68789062499997],
                            [-22.18525390625001, 76.79409179687502],
                            [-22.609326171874983, 76.70429687500004],
                            [-21.877343749999966, 76.57348632812503],
                            [-21.488232421874926, 76.271875],
                            [-20.10361328124992, 76.21909179687503],
                            [-19.508984374999926, 75.75751953124995],
                            [-19.52636718750003, 75.18022460937505],
                            [-20.484960937500006, 75.31425781249999],
                            [-21.649316406249966, 75.02343749999997],
                            [-22.232861328124926, 75.11972656249998],
                            [-21.69511718749999, 74.96445312500003],
                            [-20.985791015624983, 75.07436523437497],
                            [-20.86157226562497, 74.63593750000001],
                            [-20.41708984374995, 74.9751953125],
                            [-19.98491210937499, 74.9751953125],
                            [-19.287011718750023, 74.54638671875006],
                            [-19.36914062499997, 74.28403320312498],
                            [-20.256445312499977, 74.2828125],
                            [-20.653125, 74.13735351562502],
                            [-21.954931640624977, 74.24428710937497],
                            [-21.942919921874932, 74.56572265624999],
                            [-22.32158203124999, 74.30253906250002],
                            [-22.134814453124932, 73.99047851562503],
                            [-20.36728515624992, 73.8482421875],
                            [-20.509667968749966, 73.49287109375001],
                            [-22.346875, 73.26923828125001],
                            [-23.23320312499999, 73.39770507812497],
                            [-24.157714843749943, 73.76445312499999],
                            [-24.67724609375, 73.602197265625],
                            [-25.521289062500017, 73.85161132812499],
                            [-24.79125976562497, 73.51127929687502],
                            [-26.062304687500017, 73.25302734375],
                            [-27.270410156250023, 73.43627929687503],
                            [-26.541845703125006, 73.24897460937495],
                            [-27.561621093750006, 73.13847656250002],
                            [-27.348046875000023, 73.06782226562501],
                            [-25.057031250000023, 73.396484375],
                            [-24.132666015625006, 73.409375],
                            [-22.036328124999955, 72.91845703125006],
                            [-22.29321289062497, 72.11953125],
                            [-24.06904296875001, 72.49873046874998],
                            [-24.629980468749977, 73.03764648437499],
                            [-26.657617187499966, 72.71582031249997],
                            [-24.81333007812492, 72.90151367187497],
                            [-24.65, 72.58251953125],
                            [-25.117871093749983, 72.34697265625005],
                            [-24.66684570312492, 72.437353515625],
                            [-21.959667968749955, 71.74467773437502],
                            [-22.479638671874937, 71.38344726562497],
                            [-22.417578125, 71.24868164062505],
                            [-22.29902343750001, 71.43232421874998],
                            [-21.75224609374999, 71.47832031250002],
                            [-21.522656249999926, 70.52622070312503],
                            [-22.38413085937492, 70.46240234375],
                            [-22.437011718749943, 70.860009765625],
                            [-22.690673828124943, 70.43730468750002],
                            [-23.327832031249983, 70.45097656250007],
                            [-23.97138671875001, 70.64946289062499],
                            [-24.562207031249926, 71.22353515624997],
                            [-25.885156249999966, 71.571923828125],
                            [-27.08720703124999, 71.6265625],
                            [-27.107031250000034, 71.53266601562498],
                            [-25.842724609374955, 71.48017578124995],
                            [-25.74223632812499, 71.18359375],
                            [-26.717919921874994, 70.95048828125005],
                            [-28.39843749999997, 70.99291992187497],
                            [-27.99218749999997, 70.89521484374998],
                            [-28.06987304687499, 70.69902343750005],
                            [-29.07207031249999, 70.444970703125],
                            [-26.621777343749955, 70.46337890625],
                            [-26.576806640625023, 70.35708007812502],
                            [-27.560839843749932, 70.12446289062498],
                            [-27.384179687500023, 69.9916015625],
                            [-27.027734374999966, 70.20122070312499],
                            [-25.529882812499977, 70.35317382812502],
                            [-23.66733398437495, 70.139306640625],
                            [-22.28447265624996, 70.12583007812498],
                            [-22.287060546874955, 70.03339843749998],
                            [-23.03364257812501, 69.90083007812498],
                            [-23.04956054687497, 69.79272460937497],
                            [-23.86572265624997, 69.73671875000002],
                            [-23.739404296874994, 69.58862304687497],
                            [-24.296679687500017, 69.58554687500006],
                            [-24.295556640624966, 69.439306640625],
                            [-25.188574218750006, 69.26054687500002],
                            [-25.092431640624937, 69.16518554687502],
                            [-25.697998046874943, 68.889892578125],
                            [-26.48291015624997, 68.67592773437502],
                            [-29.24951171874997, 68.29877929687501],
                            [-29.86850585937495, 68.31157226562505],
                            [-30.318115234375, 68.19331054687501],
                            [-30.72001953124999, 68.25117187499998],
                            [-30.610742187499994, 68.11791992187503],
                            [-30.97856445312499, 68.06132812500005],
                            [-32.32744140624999, 68.43730468749999],
                            [-32.16455078125, 67.99111328125002],
                            [-33.15698242187497, 67.62670898437506],
                            [-34.1982421875, 66.65507812499999],
                            [-35.18857421874995, 66.25029296875002],
                            [-35.86723632812502, 66.44140624999997],
                            [-35.630078124999926, 66.13994140625002],
                            [-36.37919921874996, 65.830810546875],
                            [-36.52724609375002, 66.00771484375],
                            [-36.665185546874966, 65.79008789062507],
                            [-37.06279296874996, 65.87143554687503],
                            [-37.410058593749994, 65.65634765625],
                            [-37.954785156249955, 65.63359375000007],
                            [-37.278710937499994, 66.30439453124995],
                            [-38.156640624999966, 66.38559570312498],
                            [-37.75234375000002, 66.26152343750002],
                            [-38.13994140625002, 65.90351562499998],
                            [-38.52036132812498, 66.00966796875002],
                            [-38.20336914062497, 65.71171874999999],
                            [-40.17353515624998, 65.55615234375],
                            [-39.57792968749996, 65.34077148437501],
                            [-39.937255859375, 65.14160156250003],
                            [-40.253125, 65.04887695312505],
                            [-41.08442382812501, 65.10083007812497],
                            [-40.966015624999955, 64.86884765624995],
                            [-40.655468749999926, 64.91533203125002],
                            [-40.18222656249998, 64.47993164062495],
                            [-40.78173828125, 64.22177734375003],
                            [-41.581005859374926, 64.29833984375],
                            [-41.03056640624996, 64.12104492187504],
                            [-40.61777343749998, 64.13173828125],
                            [-40.550390625000034, 63.72524414062505],
                            [-40.77519531249999, 63.53364257812501],
                            [-41.04873046875002, 63.51381835937505],
                            [-41.387890624999926, 63.06186523437498],
                            [-41.84448242187497, 63.07026367187501],
                            [-42.174511718749955, 63.20878906249999],
                            [-41.63447265624998, 62.972460937500074],
                            [-41.90898437499996, 62.73710937499999],
                            [-42.94165039062503, 62.72021484375003],
                            [-42.15297851562502, 62.568457031250006],
                            [-42.32148437499998, 62.15273437500005],
                            [-42.110205078125006, 61.857226562500074],
                            [-42.58530273437498, 61.71748046875001],
                            [-42.34736328125001, 61.61743164062497],
                            [-42.717041015625, 60.767480468749994],
                            [-43.04409179687502, 60.523681640625],
                            [-43.92270507812495, 60.59536132812502],
                            [-43.21298828124998, 60.390673828125074],
                            [-43.122900390625006, 60.06123046875001],
                            [-43.32011718749993, 59.928125],
                            [-43.95502929687498, 60.025488281250006],
                            [-43.65791015625001, 59.85864257812503],
                            [-43.90654296874996, 59.815478515625045],
                            [-44.11699218750002, 59.83193359375002],
                            [-44.06547851562499, 59.92480468750003],
                            [-44.412939453125006, 59.922607421875],
                            [-44.22436523437494, 60.273535156250006],
                            [-44.61328124999997, 60.01665039062499],
                            [-45.37924804687495, 60.20292968750002],
                            [-45.367773437500006, 60.37294921875002],
                            [-44.97470703124995, 60.457226562499955],
                            [-44.756738281249966, 60.66459960937502],
                            [-45.38051757812494, 60.444921875],
                            [-46.04663085937503, 60.61572265625],
                            [-46.141943359375006, 60.776513671874994],
                            [-45.87021484374998, 61.21831054687502],
                            [-46.87446289062501, 60.81640625000003],
                            [-48.180810546874966, 60.76923828125001],
                            [-47.77031249999999, 60.99775390625001],
                            [-48.386425781249926, 61.004736328125034],
                            [-48.42817382812501, 61.18740234375002],
                            [-48.92207031249998, 61.27744140624998],
                            [-49.28906249999997, 61.58994140625006],
                            [-49.380273437499994, 61.89018554687502],
                            [-48.82871093749998, 62.0796875],
                            [-49.62377929687494, 61.99858398437499],
                            [-49.553466796875, 62.23271484374999],
                            [-50.319238281249966, 62.473193359375045],
                            [-50.298730468749966, 62.72197265625002],
                            [-49.793115234374994, 63.04462890625004],
                            [-50.39008789062501, 62.82202148437497],
                            [-51.46884765624995, 63.64228515625001],
                            [-51.547509765624994, 64.00610351562497],
                            [-50.260693359374955, 64.21425781250002],
                            [-50.48662109374996, 64.20888671875],
                            [-50.43706054687499, 64.31284179687503],
                            [-51.58491210937498, 64.10317382812502],
                            [-51.70786132812498, 64.205078125],
                            [-51.403759765624926, 64.46318359375002],
                            [-50.49208984375002, 64.69316406250005],
                            [-50.00898437500001, 64.44726562499997],
                            [-50.12163085937493, 64.703759765625],
                            [-50.51699218750002, 64.76650390625],
                            [-50.96064453124998, 65.20112304687498],
                            [-50.721582031249966, 64.79760742187503],
                            [-51.22060546875002, 64.62846679687502],
                            [-51.25537109375, 64.75810546875005],
                            [-51.92260742187503, 64.21875],
                            [-52.259033203125, 65.154931640625],
                            [-52.537695312500034, 65.32880859374998],
                            [-51.61914062500003, 65.71318359375002],
                            [-51.091894531250006, 65.77578125],
                            [-51.7234375, 65.723486328125],
                            [-52.55126953125003, 65.46137695312498],
                            [-52.760937499999926, 65.59082031249997],
                            [-53.198974609375, 65.59404296875002],
                            [-53.106347656249966, 65.97714843749998],
                            [-53.39204101562498, 66.04833984375],
                            [-51.225, 66.88154296875001],
                            [-53.035791015624966, 66.20141601562503],
                            [-53.538769531249955, 66.13935546874998],
                            [-53.41875, 66.64853515624998],
                            [-53.038281249999955, 66.82680664062497],
                            [-52.38686523437502, 66.88115234375005],
                            [-53.44360351562503, 66.924658203125],
                            [-53.88442382812502, 67.13554687499999],
                            [-53.79858398437494, 67.41816406250001],
                            [-52.666455078124955, 67.74970703124995],
                            [-50.613476562499955, 67.5279296875],
                            [-51.171044921874966, 67.693603515625],
                            [-50.96884765624998, 67.80664062500003],
                            [-51.765234375000034, 67.73784179687505],
                            [-52.34482421874998, 67.83691406249997],
                            [-53.735205078125006, 67.54902343750004],
                            [-53.151562499999926, 68.20776367187503],
                            [-51.779980468749926, 68.05673828124998],
                            [-51.456494140624926, 68.116064453125],
                            [-51.21015625000001, 68.419921875],
                            [-52.19853515624993, 68.22080078125],
                            [-53.38315429687495, 68.29736328124997],
                            [-53.03945312500002, 68.61088867187499],
                            [-52.60458984374998, 68.70874023437503],
                            [-51.62314453124995, 68.53481445312505],
                            [-50.945703124999966, 68.68266601562505],
                            [-50.807714843750006, 68.81699218749998],
                            [-51.24941406250002, 68.73994140625001],
                            [-51.084863281249994, 69.12827148437498],
                            [-50.29736328124994, 69.17060546874998],
                            [-51.07695312499996, 69.20947265625],
                            [-50.291699218749955, 70.01445312500005],
                            [-52.254638671875, 70.05893554687503],
                            [-53.02304687499995, 70.30190429687497],
                            [-54.01445312499996, 70.42167968750005],
                            [-54.53076171875, 70.69926757812502],
                            [-54.16582031249999, 70.82011718750005],
                            [-52.801953124999955, 70.7505859375],
                            [-50.87236328124993, 70.36489257812502],
                            [-50.66328124999998, 70.417578125],
                            [-51.32285156249998, 70.58876953124997],
                            [-51.25659179687497, 70.85268554687502],
                            [-51.77431640625002, 71.01044921875001],
                            [-51.018945312499966, 71.001318359375],
                            [-51.37666015625001, 71.11904296875],
                            [-53.007568359375, 71.17998046874999],
                            [-52.89184570312497, 71.457666015625],
                            [-51.76992187500002, 71.67172851562498],
                            [-53.44008789062502, 71.57900390625002],
                            [-53.14453125000003, 71.80742187500002],
                            [-53.65214843749996, 72.36264648437506],
                            [-53.92773437499997, 72.31879882812501],
                            [-53.47758789062502, 71.84995117187506],
                            [-54.01992187500002, 71.657861328125],
                            [-53.96298828124995, 71.45898437499997],
                            [-54.6890625, 71.36723632812505],
                            [-55.59404296874999, 71.55351562500005],
                            [-55.315576171874994, 72.11069335937498],
                            [-54.84013671874996, 72.35610351562497],
                            [-55.581445312499994, 72.178857421875],
                            [-55.63583984374998, 72.300439453125],
                            [-55.29570312499996, 72.35439453124997],
                            [-55.60170898437494, 72.453466796875],
                            [-54.924951171874994, 72.57197265624998],
                            [-54.737939453124994, 72.87250976562501],
                            [-55.07309570312498, 73.01513671875003],
                            [-55.28891601562498, 72.93320312500003],
                            [-55.66855468749998, 73.00791015624998],
                            [-55.288281249999955, 73.32709960937498],
                            [-56.10405273437496, 73.55815429687499],
                            [-55.83828125, 73.75971679687501],
                            [-56.22539062499999, 74.12910156249995],
                            [-57.23056640624995, 74.12529296875007],
                            [-56.70634765625002, 74.21918945312501],
                            [-56.717675781249994, 74.42924804687499],
                            [-56.25546874999998, 74.52680664062498],
                            [-58.56552734374998, 75.35273437500001],
                            [-58.249658203124994, 75.50668945312503],
                            [-58.51621093749995, 75.68906250000006],
                            [-61.18823242187494, 76.157861328125],
                            [-63.29130859374996, 76.35205078125003],
                            [-63.84306640624999, 76.21713867187498],
                            [-64.307275390625, 76.31650390624998],
                            [-65.36992187499993, 76.13056640625004],
                            [-65.87573242187494, 76.23833007812505],
                            [-66.46577148437498, 76.13916015625],
                            [-66.99257812500002, 76.21293945312502],
                            [-66.67480468750003, 75.977392578125],
                            [-68.14873046875002, 76.06704101562497],
                            [-69.48408203125001, 76.39916992187503],
                            [-68.1142578125, 76.65063476562503],
                            [-69.67382812499994, 76.73588867187507],
                            [-69.69423828125002, 76.98945312500004],
                            [-70.613134765625, 76.82182617187499],
                            [-71.14145507812498, 77.02866210937503],
                            [-70.86284179687496, 77.175439453125],
                            [-68.97832031250002, 77.19531250000006],
                            [-68.13554687499999, 77.37958984375001],
                            [-66.38945312499999, 77.28027343750003],
                            [-66.69121093749999, 77.68120117187502],
                            [-67.68808593749995, 77.523779296875],
                            [-68.62153320312498, 77.60185546875002],
                            [-69.35136718749999, 77.467138671875],
                            [-70.53540039062497, 77.699560546875],
                            [-70.11445312500001, 77.84135742187505],
                            [-71.27163085937494, 77.81313476562497],
                            [-72.81806640624995, 78.1943359375],
                            [-72.47250976562498, 78.48203125],
                            [-71.65131835937493, 78.62314453124998],
                            [-68.99345703124999, 78.857421875],
                            [-68.37705078124998, 79.037841796875],
                            [-65.82553710937503, 79.17373046874997],
                            [-64.79228515624993, 80.00063476562502],
                            [-64.17915039062498, 80.09926757812497],
                            [-66.84365234374997, 80.07622070312507],
                            [-67.05063476562503, 80.384521484375],
                            [-64.51552734374997, 81],
                            [-63.72197265624993, 81.05732421875001],
                            [-63.028662109375006, 80.88955078125002],
                            [-62.90336914062496, 81.21835937500003],
                            [-61.43598632812498, 81.13359375000002],
                            [-60.842871093750034, 81.85537109374997],
                            [-59.28193359374998, 81.88403320312503],
                            [-56.615136718749994, 81.362890625],
                            [-59.26181640624998, 82.00664062500005],
                            [-54.54887695312496, 82.35063476562505],
                            [-53.671337890624955, 82.16406249999997],
                            [-53.55566406250003, 81.65327148437501],
                            [-53.022558593750034, 82.32172851562504],
                            [-50.894433593749994, 81.89521484375001],
                            [-49.54106445312496, 81.91806640625003],
                            [-50.93554687500003, 82.38281250000003],
                            [-50.03710937499994, 82.472412109375],
                            [-44.7294921875, 81.77983398437505],
                            [-44.23886718749998, 82.3681640625],
                            [-45.55654296875002, 82.74702148437498],
                            [-41.87646484375, 82.680322265625],
                            [-41.36962890625003, 82.75],
                            [-46.136816406250006, 82.85883789062504],
                            [-46.169042968750006, 83.06386718749997],
                            [-45.41459960937496, 83.01767578124998],
                            [-43.00927734375003, 83.26459960937501],
                            [-41.300146484375006, 83.10078125000004],
                            [-40.35683593750002, 83.332177734375],
                            [-38.15625, 82.9986328125],
                            [-38.74956054687496, 83.37084960937497],
                            [-37.72333984374998, 83.49775390624998],
                            [-29.952880859375, 83.56484374999997],
                        ],
                    ],
                ],
            },
            properties: {
                name: "格陵兰",
                childNum: 14,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-89.2328125, 15.888671875],
                        [-88.89404296875, 15.890625],
                        [-88.60336914062499, 15.76416015625],
                        [-88.5939453125, 15.950292968749991],
                        [-88.22832031249999, 15.72900390625],
                        [-88.271435546875, 15.694873046875003],
                        [-88.36455078124999, 15.616015625],
                        [-88.68447265625, 15.360498046874994],
                        [-88.96098632812499, 15.152441406249991],
                        [-89.142578125, 15.072314453125003],
                        [-89.22236328125, 14.866064453124991],
                        [-89.16220703124999, 14.669238281250003],
                        [-89.17177734375, 14.606884765624997],
                        [-89.28671875, 14.529980468749997],
                        [-89.36259765624999, 14.416015625],
                        [-89.5736328125, 14.390087890624997],
                        [-89.54716796874999, 14.241259765625003],
                        [-90.04814453124999, 13.904052734375],
                        [-90.09521484375, 13.736523437499997],
                        [-90.60693359375, 13.929003906250003],
                        [-91.37734375, 13.990185546874997],
                        [-92.23515624999999, 14.54541015625],
                        [-92.15854492187499, 14.963574218749997],
                        [-92.14423828125, 15.001953125],
                        [-92.09873046874999, 15.026757812499994],
                        [-92.07480468749999, 15.07421875],
                        [-92.187158203125, 15.320898437499991],
                        [-92.08212890624999, 15.495556640624997],
                        [-91.9572265625, 15.703222656249991],
                        [-91.736572265625, 16.07016601562499],
                        [-91.433984375, 16.070458984374994],
                        [-90.97958984374999, 16.07080078125],
                        [-90.70322265624999, 16.071044921875],
                        [-90.52197265625, 16.071191406249994],
                        [-90.44716796875, 16.072705078124997],
                        [-90.45986328125, 16.162353515625],
                        [-90.450146484375, 16.261376953124994],
                        [-90.4169921875, 16.351318359375],
                        [-90.4169921875, 16.39101562499999],
                        [-90.47109375, 16.43955078124999],
                        [-90.57578125, 16.467822265625003],
                        [-90.63408203124999, 16.5107421875],
                        [-90.634375, 16.565136718749997],
                        [-90.65996093749999, 16.630908203125003],
                        [-90.710693359375, 16.70810546874999],
                        [-90.975830078125, 16.867822265624994],
                        [-91.409619140625, 17.255859375],
                        [-91.1955078125, 17.254101562499997],
                        [-90.99296874999999, 17.25244140625],
                        [-90.98916015625, 17.81640625],
                        [-89.16147460937499, 17.81484375],
                        [-89.2328125, 15.888671875],
                    ],
                ],
            },
            properties: {
                name: "危地马拉",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [144.74179687500003, 13.25927734375],
                        [144.64931640625002, 13.4287109375],
                        [144.87539062500002, 13.614648437499994],
                        [144.94082031250002, 13.5703125],
                        [144.74179687500003, 13.25927734375],
                    ],
                ],
            },
            properties: {
                name: "关岛",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-57.194775390625, 5.5484375],
                        [-57.3185546875, 5.335351562499994],
                        [-57.20981445312499, 5.195410156249991],
                        [-57.331005859375, 5.020166015624994],
                        [-57.711083984374994, 4.991064453124991],
                        [-57.91704101562499, 4.820410156249991],
                        [-57.84599609374999, 4.668164062499997],
                        [-58.05429687499999, 4.101660156249991],
                        [-57.646728515625, 3.39453125],
                        [-57.303662109375, 3.377099609374994],
                        [-57.19736328124999, 2.853271484375],
                        [-56.704345703125, 2.036474609374991],
                        [-56.4828125, 1.942138671875],
                        [-56.96953124999999, 1.91640625],
                        [-57.03759765625, 1.936474609374997],
                        [-57.092675781249994, 2.005810546874997],
                        [-57.118896484375, 2.013964843749989],
                        [-57.31748046874999, 1.963476562499991],
                        [-57.41269531249999, 1.908935546875],
                        [-57.500439453125, 1.77382812499999],
                        [-57.54575195312499, 1.72607421875],
                        [-57.59443359375, 1.7041015625],
                        [-57.795654296875, 1.7],
                        [-57.8734375, 1.667285156249989],
                        [-57.9828125, 1.6484375],
                        [-58.03466796875, 1.520263671875],
                        [-58.34067382812499, 1.587548828124994],
                        [-58.38037109375, 1.530224609374997],
                        [-58.39580078124999, 1.481738281249989],
                        [-58.5060546875, 1.438671875],
                        [-58.511865234374994, 1.28466796875],
                        [-58.68461914062499, 1.281054687499989],
                        [-58.73032226562499, 1.247509765624997],
                        [-58.78720703124999, 1.20849609375],
                        [-58.82177734375, 1.201220703124989],
                        [-59.231201171875, 1.376025390624989],
                        [-59.53569335937499, 1.7],
                        [-59.66660156249999, 1.746289062499997],
                        [-59.66850585937499, 1.842333984374989],
                        [-59.74072265625, 1.874169921874994],
                        [-59.75620117187499, 1.900634765625],
                        [-59.75522460937499, 2.274121093749997],
                        [-59.8896484375, 2.362939453124994],
                        [-59.9943359375, 2.689990234374989],
                        [-59.854394531249994, 3.5875],
                        [-59.55112304687499, 3.933544921874997],
                        [-59.557763671874994, 3.960009765624989],
                        [-59.62021484374999, 4.023144531249997],
                        [-59.73857421874999, 4.226757812499997],
                        [-59.69970703125, 4.353515625],
                        [-60.1486328125, 4.533251953124989],
                        [-59.990673828125, 5.082861328124991],
                        [-60.142041015625, 5.238818359374989],
                        [-60.241650390625, 5.257958984374994],
                        [-60.335205078125, 5.199316406249991],
                        [-60.45952148437499, 5.188085937499991],
                        [-60.6513671875, 5.221142578124997],
                        [-60.742138671875, 5.202050781249994],
                        [-61.37680664062499, 5.906982421875],
                        [-61.3908203125, 5.938769531249989],
                        [-61.303125, 6.049511718749997],
                        [-61.22495117187499, 6.129199218749989],
                        [-61.15947265624999, 6.174414062499991],
                        [-61.12871093749999, 6.214306640624997],
                        [-61.152294921875, 6.385107421874991],
                        [-61.151025390624994, 6.446533203125],
                        [-61.181591796875, 6.513378906249997],
                        [-61.20361328125, 6.58837890625],
                        [-61.14560546874999, 6.69453125],
                        [-60.717919921874994, 6.768310546875],
                        [-60.35209960937499, 7.002880859374997],
                        [-60.32207031249999, 7.092041015625],
                        [-60.32548828124999, 7.133984375],
                        [-60.34506835937499, 7.15],
                        [-60.46494140624999, 7.166552734374989],
                        [-60.523193359375, 7.143701171874994],
                        [-60.583203125, 7.156201171874997],
                        [-60.63330078125, 7.211083984374994],
                        [-60.718652343749994, 7.535937499999989],
                        [-60.513623046875, 7.813183593749997],
                        [-60.032421875, 8.053564453124991],
                        [-59.99072265625, 8.162011718749994],
                        [-59.96484375, 8.191601562499997],
                        [-59.849072265625, 8.248681640624994],
                        [-59.83164062499999, 8.305957031249989],
                        [-60.017529296875, 8.54931640625],
                        [-59.20024414062499, 8.07460937499999],
                        [-58.51108398437499, 7.39804687499999],
                        [-58.48056640624999, 7.038134765624989],
                        [-58.67294921874999, 6.390771484374994],
                        [-58.414990234375, 6.85117187499999],
                        [-57.982568359374994, 6.785888671875],
                        [-57.54013671874999, 6.33154296875],
                        [-57.2275390625, 6.178417968749997],
                        [-57.194775390625, 5.5484375],
                    ],
                ],
            },
            properties: {
                name: "圭亚那",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [73.70742187500002, -53.13710937499999],
                        [73.46513671875002, -53.184179687500006],
                        [73.25117187500001, -52.97578125000001],
                        [73.83779296875002, -53.11279296875],
                        [73.70742187500002, -53.13710937499999],
                    ],
                ],
            },
            properties: {
                name: "赫德和麦克唐纳群岛",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-83.635498046875, 14.876416015624997],
                        [-84.53764648437496, 14.633398437499963],
                        [-84.64594726562498, 14.661083984375011],
                        [-84.86044921874998, 14.809765625000011],
                        [-84.98515624999999, 14.752441406249972],
                        [-85.059375, 14.582958984374997],
                        [-85.20834960937498, 14.311816406250003],
                        [-85.73393554687496, 13.85869140625006],
                        [-85.75341796875, 13.852050781250028],
                        [-85.78671874999995, 13.844433593749997],
                        [-85.98378906249997, 13.965673828125006],
                        [-86.04038085937503, 14.050146484374977],
                        [-86.33173828124995, 13.770068359375031],
                        [-86.37695312500003, 13.755664062500031],
                        [-86.61025390624997, 13.774853515625026],
                        [-86.73364257812494, 13.763476562500017],
                        [-86.75898437499995, 13.746142578125045],
                        [-86.77060546875003, 13.698730468749972],
                        [-86.763525390625, 13.635253906250014],
                        [-86.72958984375, 13.4072265625],
                        [-86.710693359375, 13.31337890624998],
                        [-86.72929687499996, 13.284375],
                        [-86.79213867187497, 13.279785156249972],
                        [-86.87353515624994, 13.266503906250023],
                        [-86.918212890625, 13.223583984374983],
                        [-87.00932617187499, 13.007812499999986],
                        [-87.0591796875, 12.991455078125028],
                        [-87.337255859375, 12.979248046875028],
                        [-87.48911132812503, 13.352929687500051],
                        [-87.814208984375, 13.399169921875057],
                        [-87.781884765625, 13.521386718749994],
                        [-87.71533203125003, 13.812695312500011],
                        [-87.73144531250003, 13.841064453125014],
                        [-87.80224609374997, 13.889990234375034],
                        [-87.89199218749997, 13.894970703124983],
                        [-87.99101562499996, 13.879638671874972],
                        [-88.15102539062497, 13.987353515624974],
                        [-88.44912109374994, 13.850976562499994],
                        [-88.48266601562503, 13.854248046875043],
                        [-88.49765624999998, 13.904541015624986],
                        [-88.50434570312501, 13.964208984374963],
                        [-88.51254882812498, 13.97895507812504],
                        [-89.12050781249994, 14.370214843749991],
                        [-89.36259765624996, 14.416015625],
                        [-89.17177734375, 14.606884765624983],
                        [-89.16220703125, 14.669238281249989],
                        [-89.22236328125001, 14.86606445312502],
                        [-89.142578125, 15.072314453125031],
                        [-88.96098632812496, 15.15244140625002],
                        [-88.68447265625002, 15.360498046875037],
                        [-88.36455078124996, 15.616015625000045],
                        [-88.27143554687498, 15.694873046875045],
                        [-88.22832031249999, 15.729003906249972],
                        [-88.131103515625, 15.701025390625034],
                        [-87.87495117187495, 15.879345703124955],
                        [-86.35664062499998, 15.783203125],
                        [-85.93627929687497, 15.953417968750045],
                        [-85.98564453124999, 16.02416992187497],
                        [-85.48369140624996, 15.899511718749977],
                        [-84.97373046874998, 15.989892578124994],
                        [-84.55966796875, 15.802001953125],
                        [-84.26142578124998, 15.822607421875034],
                        [-83.765283203125, 15.405468750000054],
                        [-83.972802734375, 15.519628906250034],
                        [-84.11132812499997, 15.492431640625],
                        [-84.09506835937503, 15.400927734375017],
                        [-83.92744140624998, 15.394042968750028],
                        [-83.76044921874998, 15.220361328124994],
                        [-83.49794921874997, 15.222119140624997],
                        [-83.64638671875, 15.368408203125043],
                        [-83.36918945312493, 15.239990234375],
                        [-83.29086914062498, 15.078906250000045],
                        [-83.2255859375, 15.042285156250045],
                        [-83.15751953124999, 14.993066406249966],
                        [-83.41503906249994, 15.008056640625],
                        [-83.5365234375, 14.977001953124983],
                        [-83.635498046875, 14.876416015624997],
                    ],
                ],
            },
            properties: {
                name: "洪都拉斯",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [17.60781250000005, 42.76904296875],
                            [17.744238281250063, 42.70034179687505],
                            [17.34414062500008, 42.790380859375006],
                            [17.60781250000005, 42.76904296875],
                        ],
                    ],
                    [
                        [
                            [16.650683593750017, 42.99658203125],
                            [17.188281250000045, 42.917041015625045],
                            [16.850683593750006, 42.8955078125],
                            [16.650683593750017, 42.99658203125],
                        ],
                    ],
                    [
                        [
                            [17.667578125000063, 42.897119140624994],
                            [18.436328125000017, 42.559716796874994],
                            [18.517480468750023, 42.43291015624999],
                            [17.823828125, 42.79741210937502],
                            [17.045410156250057, 43.014892578125],
                            [17.667578125000063, 42.897119140624994],
                        ],
                    ],
                    [
                        [
                            [16.785253906250006, 43.270654296874966],
                            [16.490332031250034, 43.28618164062502],
                            [16.44892578125004, 43.38706054687506],
                            [16.89130859375001, 43.314648437499955],
                            [16.785253906250006, 43.270654296874966],
                        ],
                    ],
                    [
                        [
                            [15.371386718750074, 43.973828124999955],
                            [15.437207031250068, 43.899511718750006],
                            [15.270019531250028, 44.01074218750003],
                            [15.371386718750074, 43.973828124999955],
                        ],
                    ],
                    [
                        [
                            [14.488085937500074, 44.66005859375005],
                            [14.31240234375008, 44.90039062499997],
                            [14.33125, 45.16499023437498],
                            [14.488085937500074, 44.66005859375005],
                        ],
                    ],
                    [
                        [
                            [14.810253906250068, 44.97705078124997],
                            [14.45039062500004, 45.079199218750006],
                            [14.571093750000017, 45.224755859374994],
                            [14.810253906250068, 44.97705078124997],
                        ],
                    ],
                    [
                        [
                            [18.905371093750006, 45.931738281250034],
                            [18.839062499999983, 45.83574218750002],
                            [19.064257812500045, 45.51499023437506],
                            [19.004687500000074, 45.39951171875006],
                            [19.4, 45.2125],
                            [19.062890625000023, 45.13720703125],
                            [19.007128906250045, 44.86918945312502],
                            [18.83642578125, 44.883251953124955],
                            [18.66259765625, 45.07744140624999],
                            [17.812792968750074, 45.078125],
                            [16.918652343749983, 45.27656249999998],
                            [16.53066406250008, 45.21669921875002],
                            [16.29335937500005, 45.00883789062496],
                            [16.028320312500057, 45.18959960937502],
                            [15.788085937500057, 45.17895507812497],
                            [15.736621093750045, 44.76582031250001],
                            [16.10341796875008, 44.52099609375006],
                            [16.300097656250017, 44.12451171875],
                            [17.27382812500005, 43.44575195312501],
                            [17.650488281250063, 43.006591796875],
                            [17.585156250000068, 42.93837890625005],
                            [16.903125, 43.392431640625006],
                            [16.393945312500023, 43.54335937500002],
                            [15.985546875000068, 43.519775390625],
                            [15.185839843750017, 44.17211914062503],
                            [15.122949218749994, 44.256787109374955],
                            [15.470996093750045, 44.27197265625003],
                            [14.981347656250023, 44.60292968750005],
                            [14.854589843750034, 45.08100585937501],
                            [14.550488281249983, 45.297705078125006],
                            [14.31269531250004, 45.33779296875002],
                            [13.86074218750008, 44.83740234375003],
                            [13.517187500000063, 45.481787109375034],
                            [13.878710937500017, 45.428369140624994],
                            [14.369921875000074, 45.48144531250006],
                            [14.427343750000034, 45.50576171875002],
                            [14.56884765625, 45.65722656249997],
                            [14.591796875000057, 45.65126953125002],
                            [14.649511718750006, 45.57148437500001],
                            [14.793066406250034, 45.47822265625001],
                            [14.95458984375, 45.499902343749994],
                            [15.110449218750034, 45.450781250000034],
                            [15.242089843750023, 45.44140624999997],
                            [15.339453125000063, 45.46704101562506],
                            [15.326660156250028, 45.502294921875034],
                            [15.291210937500011, 45.541552734375045],
                            [15.283593750000051, 45.5796875],
                            [15.35371093750004, 45.659912109375],
                            [15.27705078125004, 45.73261718749998],
                            [15.652148437500074, 45.86215820312498],
                            [15.675585937500045, 45.98369140624996],
                            [15.666210937500011, 46.04848632812502],
                            [15.596875, 46.10922851562506],
                            [15.592578125000017, 46.139990234375006],
                            [15.608984374999977, 46.171923828125045],
                            [16.1064453125, 46.382226562499994],
                            [16.32119140625005, 46.53461914062504],
                            [16.42763671875005, 46.5244140625],
                            [16.516210937499977, 46.499902343749966],
                            [16.569921875, 46.48500976562505],
                            [16.748046875000057, 46.41640625000002],
                            [16.87148437500008, 46.33930664062504],
                            [17.310644531250006, 45.99614257812502],
                            [17.80712890625, 45.79042968750002],
                            [18.358300781250023, 45.75302734375006],
                            [18.533593750000023, 45.79614257812503],
                            [18.56464843750004, 45.81328124999999],
                            [18.666015625, 45.90747070312497],
                            [18.905371093750006, 45.931738281250034],
                        ],
                    ],
                ],
            },
            properties: {
                name: "克罗地亚",
                childNum: 8,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-72.80458984374997, 18.777685546875063],
                            [-72.82221679687501, 18.707128906249977],
                            [-73.07797851562498, 18.790917968749994],
                            [-73.27641601562499, 18.95405273437501],
                            [-72.80458984374997, 18.777685546875063],
                        ],
                    ],
                    [
                        [
                            [-71.647216796875, 19.195947265624994],
                            [-71.80712890624997, 18.987011718749983],
                            [-71.733642578125, 18.85639648437501],
                            [-71.72705078125, 18.80322265625003],
                            [-71.74321289062502, 18.73291015625],
                            [-71.86650390624999, 18.61416015625005],
                            [-71.98686523437499, 18.61035156249997],
                            [-72.000390625, 18.59790039062503],
                            [-71.94038085937493, 18.51259765625005],
                            [-71.87255859374997, 18.416210937499955],
                            [-71.76191406249998, 18.34130859374997],
                            [-71.73725585937495, 18.27080078124999],
                            [-71.76831054687497, 18.039160156250063],
                            [-71.85292968749997, 18.119140625],
                            [-71.94609375, 18.186083984375045],
                            [-72.05986328124993, 18.228564453125017],
                            [-72.87666015624998, 18.151757812499994],
                            [-73.38515625000002, 18.251171874999983],
                            [-73.747314453125, 18.190234375000017],
                            [-73.88496093749998, 18.041894531249994],
                            [-74.478125, 18.45],
                            [-74.3875, 18.624707031249983],
                            [-74.22773437499998, 18.662695312499977],
                            [-72.78935546874996, 18.434814453125],
                            [-72.37607421874998, 18.57446289062503],
                            [-72.34765624999994, 18.674951171874994],
                            [-72.81108398437496, 19.071582031250074],
                            [-72.70322265625, 19.441064453125023],
                            [-73.43837890624994, 19.722119140624983],
                            [-73.21777343750003, 19.88369140625005],
                            [-72.63701171875002, 19.90087890625],
                            [-72.21982421875003, 19.744628906250057],
                            [-71.834716796875, 19.696728515624983],
                            [-71.77924804687498, 19.718164062499994],
                            [-71.75742187499998, 19.68818359375001],
                            [-71.71147460937493, 19.486572265625057],
                            [-71.74648437499997, 19.28583984375001],
                            [-71.647216796875, 19.195947265624994],
                        ],
                    ],
                ],
            },
            properties: {
                name: "海地",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [22.1318359375, 48.405322265624996],
                        [22.253710937500017, 48.407373046874994],
                        [22.582421875000023, 48.134033203125],
                        [22.769140625, 48.109619140625],
                        [22.87666015625001, 47.947265625],
                        [21.99970703125001, 47.505029296874994],
                        [21.121679687500006, 46.282421875],
                        [20.76025390625, 46.246240234374994],
                        [20.613671875000023, 46.13349609375],
                        [20.508105468750017, 46.166943359375],
                        [20.28095703125001, 46.1330078125],
                        [20.241796875, 46.10859375],
                        [20.21015625000001, 46.126025390624996],
                        [20.161425781250017, 46.141894531249996],
                        [19.93408203125, 46.161474609375],
                        [19.84443359375001, 46.145898437499994],
                        [19.61347656250001, 46.169189453125],
                        [19.421289062500023, 46.064453125],
                        [18.666015625, 45.907470703125],
                        [18.56464843750001, 45.81328125],
                        [18.533593750000023, 45.796142578125],
                        [18.358300781250023, 45.75302734375],
                        [17.80712890625, 45.790429687499994],
                        [17.310644531250006, 45.996142578124996],
                        [16.871484375000023, 46.339306640625],
                        [16.748046875, 46.41640625],
                        [16.569921875, 46.485009765624994],
                        [16.516210937500006, 46.499902343749994],
                        [16.283593750000023, 46.857275390625],
                        [16.093066406250017, 46.86328125],
                        [16.453417968750017, 47.006787109375],
                        [16.44287109375, 47.39951171875],
                        [16.676562500000017, 47.536035156249994],
                        [16.421289062500023, 47.674462890624994],
                        [17.06660156250001, 47.707568359374996],
                        [17.147363281250023, 48.00595703125],
                        [17.76191406250001, 47.770166015624994],
                        [18.72421875, 47.787158203124996],
                        [18.791894531250023, 48.000292968749996],
                        [19.625390625000023, 48.223095703125],
                        [19.95039062500001, 48.146630859374994],
                        [20.333789062500017, 48.295556640624994],
                        [20.490039062500017, 48.526904296874996],
                        [21.45136718750001, 48.55224609375],
                        [21.766992187500023, 48.3380859375],
                        [22.1318359375, 48.405322265624996],
                    ],
                ],
            },
            properties: {
                name: "匈牙利",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [122.9489257812501, -10.90927734375002],
                            [122.82617187500003, -10.899121093749983],
                            [122.84570312500003, -10.761816406249991],
                            [123.37109375000003, -10.474902343749989],
                            [123.41816406250004, -10.651269531250037],
                            [122.9489257812501, -10.90927734375002],
                        ],
                    ],
                    [
                        [
                            [121.8830078125001, -10.590332031249957],
                            [121.70468750000006, -10.5556640625],
                            [121.99833984375002, -10.446972656249983],
                            [121.8830078125001, -10.590332031249957],
                        ],
                    ],
                    [
                        [
                            [123.41621093750004, -10.302636718749966],
                            [123.3255859375, -10.264160156249943],
                            [123.45878906250002, -10.13994140624996],
                            [123.41621093750004, -10.302636718749966],
                        ],
                    ],
                    [
                        [
                            [120.0125, -9.374707031250026],
                            [120.78447265625002, -9.95703125],
                            [120.83261718750006, -10.0375],
                            [120.69804687500002, -10.206640624999949],
                            [120.4391601562501, -10.294042968749991],
                            [120.14482421875002, -10.200097656249952],
                            [119.60107421874997, -9.773535156250006],
                            [119.08544921875003, -9.706933593750023],
                            [118.95878906250002, -9.519335937500003],
                            [119.29589843749997, -9.3671875],
                            [119.9420898437501, -9.301464843750026],
                            [120.0125, -9.374707031250026],
                        ],
                    ],
                    [
                        [
                            [125.06816406250002, -9.511914062499997],
                            [124.42753906250002, -10.14863281250004],
                            [123.7472656250001, -10.347167968749986],
                            [123.60478515625002, -10.270117187500006],
                            [123.71640625000012, -10.078613281249986],
                            [123.5892578125, -9.966796875000028],
                            [123.709375, -9.61484375],
                            [124.0363281250001, -9.341601562500031],
                            [124.28232421875012, -9.427929687500026],
                            [124.44443359375012, -9.190332031250023],
                            [124.92226562500005, -8.942480468749977],
                            [124.93681640625007, -9.053417968750026],
                            [125.14902343750012, -9.042578125000034],
                            [125.10048828125, -9.189843750000023],
                            [124.96015625000004, -9.213769531250009],
                            [125.06816406250002, -9.511914062499997],
                        ],
                    ],
                    [
                        [
                            [115.60996093750012, -8.769824218749974],
                            [115.48046875000003, -8.715429687500006],
                            [115.56142578125, -8.669921874999972],
                            [115.60996093750012, -8.769824218749974],
                        ],
                    ],
                    [
                        [
                            [122.97734375000002, -8.54521484374996],
                            [122.88779296875006, -8.587304687500009],
                            [123.01054687500002, -8.448339843750034],
                            [123.153125, -8.475781250000026],
                            [122.97734375000002, -8.54521484374996],
                        ],
                    ],
                    [
                        [
                            [119.46406250000004, -8.741015624999974],
                            [119.38554687500002, -8.736035156250026],
                            [119.4464843750001, -8.429199218749957],
                            [119.55722656250012, -8.518847656250003],
                            [119.46406250000004, -8.741015624999974],
                        ],
                    ],
                    [
                        [
                            [123.31748046875012, -8.354785156249974],
                            [123.02500000000012, -8.395507812500014],
                            [123.21708984375002, -8.235449218750006],
                            [123.33603515625006, -8.269042968750014],
                            [123.31748046875012, -8.354785156249974],
                        ],
                    ],
                    [
                        [
                            [116.64082031250004, -8.613867187500006],
                            [116.51425781250012, -8.820996093750011],
                            [116.58652343750012, -8.886132812499966],
                            [116.23935546875006, -8.912109375000014],
                            [115.85732421875005, -8.787890625000017],
                            [116.07646484375002, -8.744921874999974],
                            [116.06113281250006, -8.437402343750023],
                            [116.4015625000001, -8.204199218750034],
                            [116.7189453125001, -8.336035156249977],
                            [116.64082031250004, -8.613867187500006],
                        ],
                    ],
                    [
                        [
                            [124.28662109375003, -8.32949218749998],
                            [124.14667968750004, -8.531445312499997],
                            [123.92773437500003, -8.448925781249969],
                            [124.23955078125002, -8.20341796874996],
                            [124.28662109375003, -8.32949218749998],
                        ],
                    ],
                    [
                        [
                            [123.92480468750003, -8.2724609375],
                            [123.55302734375007, -8.566796875],
                            [123.23007812500006, -8.530664062500023],
                            [123.47587890625007, -8.322265625000014],
                            [123.39121093750012, -8.280468750000026],
                            [123.77597656250006, -8.190429687499986],
                            [123.92480468750003, -8.2724609375],
                        ],
                    ],
                    [
                        [
                            [138.89511718750006, -8.388671874999957],
                            [138.56337890625, -8.30908203125],
                            [138.79619140625007, -8.173632812500017],
                            [138.89511718750006, -8.388671874999957],
                        ],
                    ],
                    [
                        [
                            [117.55634765625004, -8.367285156249949],
                            [117.49052734375007, -8.183398437499974],
                            [117.66503906249997, -8.148242187500003],
                            [117.55634765625004, -8.367285156249949],
                        ],
                    ],
                    [
                        [
                            [124.5755859375, -8.140820312499997],
                            [125.05029296874997, -8.179589843749994],
                            [125.13173828125, -8.326464843749989],
                            [124.38066406250002, -8.41513671875002],
                            [124.43066406249997, -8.18320312500002],
                            [124.5755859375, -8.140820312499997],
                        ],
                    ],
                    [
                        [
                            [127.8234375000001, -8.098828124999969],
                            [128.11923828125012, -8.17070312499996],
                            [128.02353515625006, -8.255371093749972],
                            [127.82089843750012, -8.190234375000031],
                            [127.8234375000001, -8.098828124999969],
                        ],
                    ],
                    [
                        [
                            [122.7829101562501, -8.61171875],
                            [121.65136718749997, -8.898730468749946],
                            [121.41464843750006, -8.81484375],
                            [121.32832031250004, -8.916894531250009],
                            [121.03525390625012, -8.935449218749966],
                            [120.55048828125004, -8.80185546875002],
                            [119.909375, -8.857617187500011],
                            [119.80791015625002, -8.697656250000023],
                            [119.87480468750007, -8.419824218749994],
                            [120.61025390625005, -8.24042968750004],
                            [121.44453125000004, -8.57783203125004],
                            [121.96650390625004, -8.455175781250006],
                            [122.32324218749997, -8.628320312500023],
                            [122.85048828125, -8.304394531250011],
                            [122.91914062500004, -8.221875],
                            [122.75859375000002, -8.185937499999952],
                            [122.91699218749997, -8.105566406250006],
                            [123.00595703125006, -8.329101562499986],
                            [122.7829101562501, -8.61171875],
                        ],
                    ],
                    [
                        [
                            [130.86220703125, -8.31875],
                            [130.77519531250002, -8.34990234374996],
                            [131.02011718750012, -8.091308593749943],
                            [131.17636718750006, -8.130761718749994],
                            [130.86220703125, -8.31875],
                        ],
                    ],
                    [
                        [
                            [118.24238281250004, -8.317773437499994],
                            [118.61191406250006, -8.28066406249998],
                            [118.71386718749997, -8.41494140624998],
                            [118.926171875, -8.297656249999974],
                            [119.12968750000002, -8.668164062499969],
                            [118.74589843750002, -8.735449218749991],
                            [118.83261718750012, -8.833398437499966],
                            [118.47861328125012, -8.856445312499957],
                            [118.37890625000003, -8.674609375000031],
                            [118.18994140624997, -8.840527343749997],
                            [117.06132812500002, -9.099023437499994],
                            [116.78847656250005, -9.006347656250028],
                            [116.83505859375012, -8.532421875000026],
                            [117.16484375000007, -8.367187500000014],
                            [117.56708984375004, -8.426367187499991],
                            [117.80605468750005, -8.711132812500011],
                            [117.96953125000002, -8.728027343749986],
                            [118.23486328124997, -8.591894531249963],
                            [117.81484375000005, -8.342089843749974],
                            [117.7552734375, -8.149511718749991],
                            [118.11748046875007, -8.12226562500004],
                            [118.24238281250004, -8.317773437499994],
                        ],
                    ],
                    [
                        [
                            [115.44785156250012, -8.155175781249994],
                            [115.70429687500004, -8.40712890624998],
                            [115.14492187500005, -8.849023437500037],
                            [115.05507812500005, -8.573046874999946],
                            [114.61318359375, -8.37832031249998],
                            [114.46757812500007, -8.166308593749946],
                            [114.93847656249997, -8.18710937500002],
                            [115.15400390625004, -8.065722656249974],
                            [115.44785156250012, -8.155175781249994],
                        ],
                    ],
                    [
                        [
                            [129.83886718749997, -7.954589843749986],
                            [129.71347656250012, -8.04072265625004],
                            [129.60898437500006, -7.803417968750011],
                            [129.81298828124997, -7.819726562499952],
                            [129.83886718749997, -7.954589843749986],
                        ],
                    ],
                    [
                        [
                            [126.80097656250004, -7.667871093750009],
                            [126.4720703125, -7.950390625000011],
                            [126.04003906250003, -7.885839843750006],
                            [125.79824218750005, -7.984570312499969],
                            [125.97529296875004, -7.663378906249989],
                            [126.21367187500002, -7.706738281250026],
                            [126.60957031250004, -7.571777343749972],
                            [126.80097656250004, -7.667871093750009],
                        ],
                    ],
                    [
                        [
                            [127.41943359375003, -7.623046875000028],
                            [127.37070312500012, -7.512792968749949],
                            [127.47519531250012, -7.531054687500031],
                            [127.41943359375003, -7.623046875000028],
                        ],
                    ],
                    [
                        [
                            [138.53535156250004, -8.273632812499969],
                            [138.2962890625, -8.405175781250037],
                            [137.65039062499997, -8.386132812499966],
                            [138.08183593750002, -7.566210937500003],
                            [138.29550781250012, -7.4384765625],
                            [138.76982421875002, -7.390429687499974],
                            [138.98906250000002, -7.696093749999989],
                            [138.53535156250004, -8.273632812499969],
                        ],
                    ],
                    [
                        [
                            [131.3255859375, -7.999511718749986],
                            [131.11376953125003, -7.997363281249989],
                            [131.13779296875012, -7.684863281250017],
                            [131.64345703125, -7.11279296875],
                            [131.73613281250007, -7.197070312500017],
                            [131.64384765625002, -7.266894531249946],
                            [131.62441406250005, -7.626171874999955],
                            [131.3255859375, -7.999511718749986],
                        ],
                    ],
                    [
                        [
                            [131.98203125000006, -7.202050781249966],
                            [131.75078125000002, -7.116796875],
                            [131.92226562500005, -7.104492187499986],
                            [131.98203125000006, -7.202050781249966],
                        ],
                    ],
                    [
                        [
                            [128.6701171875001, -7.183300781249969],
                            [128.52978515625003, -7.134570312499989],
                            [128.62773437500007, -7.06875],
                            [128.6701171875001, -7.183300781249969],
                        ],
                    ],
                    [
                        [
                            [120.77441406250003, -7.118945312500003],
                            [120.64082031250004, -7.115820312499991],
                            [120.63339843750006, -7.018261718750011],
                            [120.77441406250003, -7.118945312500003],
                        ],
                    ],
                    [
                        [
                            [113.84453125000007, -7.105371093749994],
                            [113.12695312499997, -7.224121093750028],
                            [112.72587890625007, -7.072753906250014],
                            [112.86806640625, -6.899902343749972],
                            [113.06738281250003, -6.879980468749991],
                            [113.97470703125012, -6.873046875],
                            [114.0736328125, -6.960156249999983],
                            [113.84453125000007, -7.105371093749994],
                        ],
                    ],
                    [
                        [
                            [115.37705078125006, -6.97080078125002],
                            [115.22031250000012, -6.952539062500037],
                            [115.24052734375007, -6.861230468749994],
                            [115.54609375000004, -6.938671874999955],
                            [115.37705078125006, -6.97080078125002],
                        ],
                    ],
                    [
                        [
                            [105.25283203125005, -6.640429687499946],
                            [105.12138671875007, -6.614941406249997],
                            [105.26054687500002, -6.523925781250014],
                            [105.25283203125005, -6.640429687499946],
                        ],
                    ],
                    [
                        [
                            [134.53681640625004, -6.442285156249994],
                            [134.32275390624997, -6.84873046875002],
                            [134.09082031249997, -6.833789062500003],
                            [134.10703125000006, -6.471582031250009],
                            [134.19462890625007, -6.459765625],
                            [134.11464843750005, -6.190820312500009],
                            [134.53681640625004, -6.442285156249994],
                        ],
                    ],
                    [
                        [
                            [107.37392578125005, -6.007617187499989],
                            [107.66679687500002, -6.215820312499957],
                            [108.33017578125012, -6.286035156249966],
                            [108.67783203125006, -6.790527343749972],
                            [110.42626953124997, -6.947265625000028],
                            [110.83476562500002, -6.424218749999952],
                            [110.97226562500012, -6.435644531249977],
                            [111.18154296875005, -6.686718749999969],
                            [111.54033203125002, -6.648242187500031],
                            [112.0873046875, -6.89335937499996],
                            [112.53925781250004, -6.926464843749955],
                            [112.64873046875007, -7.221289062499977],
                            [112.7943359375, -7.304492187499974],
                            [112.79453125000012, -7.55244140625004],
                            [113.01357421875005, -7.657714843749986],
                            [113.49765625000006, -7.723828124999955],
                            [114.07070312500005, -7.633007812500011],
                            [114.40927734375012, -7.79248046875],
                            [114.38691406250004, -8.405175781250037],
                            [114.58378906250002, -8.769628906250034],
                            [113.25332031250005, -8.286718749999963],
                            [112.67880859375006, -8.409179687499957],
                            [111.50996093750004, -8.30507812499998],
                            [110.60722656250002, -8.149414062499972],
                            [109.28164062500005, -7.704882812500003],
                            [108.74121093749997, -7.667089843750034],
                            [108.45175781250006, -7.79697265625002],
                            [107.91748046875003, -7.724121093750014],
                            [107.28496093750007, -7.471679687500014],
                            [106.45527343750004, -7.368652343749986],
                            [106.51972656250004, -7.053710937499943],
                            [106.19824218749997, -6.927832031249977],
                            [105.25546875000012, -6.835253906250031],
                            [105.37089843750002, -6.664355468750031],
                            [105.48369140625007, -6.781542968750017],
                            [105.65507812500002, -6.469531249999946],
                            [105.78691406250002, -6.456933593749966],
                            [105.86826171875006, -6.11640625000004],
                            [106.075, -5.914160156249963],
                            [106.82519531249997, -6.098242187499977],
                            [107.0462890625, -5.90419921874998],
                            [107.37392578125005, -6.007617187499989],
                        ],
                    ],
                    [
                        [
                            [120.52832031249997, -6.2984375],
                            [120.48730468749997, -6.464843749999972],
                            [120.47734375000007, -5.775292968750009],
                            [120.52832031249997, -6.2984375],
                        ],
                    ],
                    [
                        [
                            [112.7194335937501, -5.81103515625],
                            [112.58603515625006, -5.803613281249994],
                            [112.69003906250006, -5.726171875000034],
                            [112.7194335937501, -5.81103515625],
                        ],
                    ],
                    [
                        [
                            [132.80712890625003, -5.850781250000011],
                            [132.68144531250002, -5.91259765625],
                            [132.63017578125002, -5.60703125],
                            [132.80712890625003, -5.850781250000011],
                        ],
                    ],
                    [
                        [
                            [134.74697265625, -5.707031249999957],
                            [134.71416015625007, -6.29511718750004],
                            [134.44111328125004, -6.334863281249966],
                            [134.15488281250006, -6.06289062499998],
                            [134.3019531250001, -6.009765624999986],
                            [134.34306640625002, -5.833007812499943],
                            [134.20537109375002, -5.707226562499997],
                            [134.34130859375003, -5.712890624999986],
                            [134.57080078124997, -5.42734375],
                            [134.74697265625, -5.707031249999957],
                        ],
                    ],
                    [
                        [
                            [132.92626953124997, -5.902050781249983],
                            [132.84501953125002, -5.987988281249997],
                            [133.13847656250002, -5.317871093749986],
                            [133.11962890625003, -5.575976562499989],
                            [132.92626953124997, -5.902050781249983],
                        ],
                    ],
                    [
                        [
                            [102.36718750000003, -5.478710937499983],
                            [102.1107421875, -5.32255859374996],
                            [102.3717773437501, -5.366406250000011],
                            [102.36718750000003, -5.478710937499983],
                        ],
                    ],
                    [
                        [
                            [123.62675781250007, -5.271582031249963],
                            [123.58261718750006, -5.36738281250004],
                            [123.54277343750002, -5.271093749999963],
                            [123.62675781250007, -5.271582031249963],
                        ],
                    ],
                    [
                        [
                            [122.04296874999997, -5.437988281250028],
                            [121.80849609375, -5.256152343750017],
                            [121.91367187500012, -5.072265624999957],
                            [122.04101562500003, -5.158789062499991],
                            [122.04296874999997, -5.437988281250028],
                        ],
                    ],
                    [
                        [
                            [122.64511718750012, -5.26943359374998],
                            [122.5638671875, -5.3875],
                            [122.28310546875, -5.319531249999969],
                            [122.39628906250002, -5.069824218749986],
                            [122.36894531250007, -4.767187499999977],
                            [122.70195312500002, -4.61865234375],
                            [122.75986328125012, -4.933886718750003],
                            [122.61406250000007, -5.138671874999986],
                            [122.64511718750012, -5.26943359374998],
                        ],
                    ],
                    [
                        [
                            [123.17978515625006, -4.551171875000023],
                            [123.195703125, -4.82265625],
                            [123.05517578124997, -4.748242187500026],
                            [122.97167968750003, -5.138476562500031],
                            [123.18730468750007, -5.333007812499957],
                            [122.96875, -5.405761718749943],
                            [122.81210937500012, -5.671289062499952],
                            [122.64501953124997, -5.663378906250031],
                            [122.58642578124997, -5.488867187500006],
                            [122.76650390625005, -5.210156249999983],
                            [122.85332031250007, -4.618359375000026],
                            [123.074609375, -4.38691406250004],
                            [123.17978515625006, -4.551171875000023],
                        ],
                    ],
                    [
                        [
                            [133.57080078124997, -4.245898437500003],
                            [133.621875, -4.299316406249957],
                            [133.32089843750006, -4.111035156249969],
                            [133.57080078124997, -4.245898437500003],
                        ],
                    ],
                    [
                        [
                            [123.2423828125001, -4.112988281250011],
                            [123.07617187499997, -4.227148437499991],
                            [122.96904296875002, -4.029980468749969],
                            [123.21191406250003, -3.997558593750028],
                            [123.2423828125001, -4.112988281250011],
                        ],
                    ],
                    [
                        [
                            [128.56259765625012, -3.58544921875],
                            [128.39160156250003, -3.637890625000026],
                            [128.45156250000005, -3.514746093749991],
                            [128.56259765625012, -3.58544921875],
                        ],
                    ],
                    [
                        [
                            [128.2755859375001, -3.67460937499996],
                            [127.97802734374997, -3.770996093749972],
                            [127.925, -3.69931640625002],
                            [128.32910156249997, -3.51591796874996],
                            [128.2755859375001, -3.67460937499996],
                        ],
                    ],
                    [
                        [
                            [116.42412109375007, -3.464453124999963],
                            [116.38779296875012, -3.636718749999972],
                            [116.3265625, -3.539062499999972],
                            [116.42412109375007, -3.464453124999963],
                        ],
                    ],
                    [
                        [
                            [116.30332031250006, -3.868164062499957],
                            [116.05878906250004, -4.006933593749991],
                            [116.06357421875006, -3.457910156249952],
                            [116.26972656250004, -3.251074218750006],
                            [116.30332031250006, -3.868164062499957],
                        ],
                    ],
                    [
                        [
                            [126.86113281250007, -3.087890624999986],
                            [127.22734375000007, -3.391015625],
                            [127.22958984375006, -3.633007812500011],
                            [126.68632812500007, -3.823632812500037],
                            [126.21455078125004, -3.605175781250026],
                            [126.05654296875, -3.420996093749991],
                            [126.08828125, -3.105468750000014],
                            [126.86113281250007, -3.087890624999986],
                        ],
                    ],
                    [
                        [
                            [106.88642578125004, -3.005273437500023],
                            [106.7428710937501, -2.932812500000011],
                            [106.91064453124997, -2.93398437499998],
                            [106.88642578125004, -3.005273437500023],
                        ],
                    ],
                    [
                        [
                            [129.75468750000007, -2.865820312500034],
                            [130.3791015625001, -2.989355468749977],
                            [130.56992187500006, -3.130859375000028],
                            [130.85996093750006, -3.570312500000028],
                            [130.805078125, -3.85771484374996],
                            [129.844140625, -3.327148437499957],
                            [129.51171875000003, -3.32851562499998],
                            [129.46767578125005, -3.453222656249977],
                            [128.8625, -3.234960937500006],
                            [128.51660156249997, -3.449121093750037],
                            [128.13203125000004, -3.157421875000026],
                            [127.90234374999997, -3.496289062499955],
                            [127.87792968749997, -3.222070312499966],
                            [128.19853515625002, -2.865917968749969],
                            [128.99111328125, -2.82851562499998],
                            [129.17441406250006, -2.933496093749966],
                            [129.48417968750002, -2.785742187499977],
                            [129.75468750000007, -2.865820312500034],
                        ],
                    ],
                    [
                        [
                            [100.42509765625007, -3.182910156249974],
                            [100.46513671875007, -3.32851562499998],
                            [100.20429687500004, -2.98681640625],
                            [100.19853515625002, -2.785546875000023],
                            [100.45458984375003, -3.001953124999972],
                            [100.42509765625007, -3.182910156249974],
                        ],
                    ],
                    [
                        [
                            [108.2072265625001, -2.997656249999977],
                            [108.05527343750006, -3.22685546874996],
                            [107.85820312500002, -3.086328125000023],
                            [107.61445312500004, -3.209375],
                            [107.56347656250003, -2.920117187499997],
                            [107.66630859375002, -2.566308593750037],
                            [107.83779296875005, -2.530273437499972],
                            [108.21513671875002, -2.696972656250011],
                            [108.29062500000012, -2.829980468750023],
                            [108.2072265625001, -2.997656249999977],
                        ],
                    ],
                    [
                        [
                            [100.20410156249997, -2.741015625000017],
                            [100.01494140625007, -2.819726562499966],
                            [99.98789062500006, -2.525390624999957],
                            [100.20410156249997, -2.741015625000017],
                        ],
                    ],
                    [
                        [
                            [99.84306640625007, -2.343066406250031],
                            [99.60703125000012, -2.257519531250011],
                            [99.57216796875005, -2.025781249999966],
                            [99.84306640625007, -2.343066406250031],
                        ],
                    ],
                    [
                        [
                            [126.055078125, -2.451269531249963],
                            [125.86289062500006, -2.077148437499943],
                            [125.92275390625, -1.974804687499969],
                            [126.055078125, -2.451269531249963],
                        ],
                    ],
                    [
                        [
                            [126.02421875000007, -1.789746093750011],
                            [126.33173828125004, -1.822851562500006],
                            [125.47919921875004, -1.940039062499991],
                            [125.38720703124997, -1.843066406249946],
                            [126.02421875000007, -1.789746093750011],
                        ],
                    ],
                    [
                        [
                            [130.35332031250007, -1.690527343749963],
                            [130.41884765625, -1.971289062499963],
                            [130.24804687500003, -2.047753906249994],
                            [129.7376953125, -1.866894531250011],
                            [130.35332031250007, -1.690527343749963],
                        ],
                    ],
                    [
                        [
                            [124.96953125000007, -1.70546875],
                            [125.18789062500005, -1.712890624999986],
                            [125.31406250000006, -1.877148437499969],
                            [124.41777343750002, -2.005175781250031],
                            [124.32968750000012, -1.858886718749972],
                            [124.41757812500006, -1.659277343749991],
                            [124.96953125000007, -1.70546875],
                        ],
                    ],
                    [
                        [
                            [135.47421875000006, -1.591796875000014],
                            [136.89257812500003, -1.799707031249994],
                            [136.22812500000012, -1.893652343749949],
                            [135.47421875000006, -1.591796875000014],
                        ],
                    ],
                    [
                        [
                            [108.953125, -1.61962890625],
                            [108.83789062499997, -1.661621093750028],
                            [108.80371093750003, -1.567773437499994],
                            [108.953125, -1.61962890625],
                        ],
                    ],
                    [
                        [
                            [106.04570312500002, -1.669433593750014],
                            [106.36591796875004, -2.464843749999972],
                            [106.81845703125006, -2.573339843749963],
                            [106.6120117187501, -2.895507812499957],
                            [106.66718750000004, -3.071777343749986],
                            [105.99873046875004, -2.824902343749955],
                            [105.7858398437501, -2.18134765625004],
                            [105.13339843750012, -2.042578125],
                            [105.45957031250006, -1.574707031249986],
                            [105.58544921875003, -1.526757812499994],
                            [105.7008789062501, -1.731054687499963],
                            [105.7204101562501, -1.533886718750026],
                            [105.91005859375, -1.504980468749991],
                            [106.04570312500002, -1.669433593750014],
                        ],
                    ],
                    [
                        [
                            [123.59755859375, -1.704296875000011],
                            [123.48251953125006, -1.681445312499974],
                            [123.52851562500004, -1.502832031250009],
                            [123.59755859375, -1.704296875000011],
                        ],
                    ],
                    [
                        [
                            [128.1530273437501, -1.66054687499998],
                            [127.56162109375012, -1.728515624999972],
                            [127.39501953125003, -1.589843749999972],
                            [127.64667968750004, -1.332421875],
                            [128.1530273437501, -1.66054687499998],
                        ],
                    ],
                    [
                        [
                            [123.2123046875, -1.171289062499966],
                            [123.23779296874997, -1.389355468749983],
                            [123.43476562500004, -1.236816406249986],
                            [123.54726562500005, -1.337402343749957],
                            [123.51191406250004, -1.447363281249977],
                            [123.27490234374997, -1.437207031249955],
                            [123.17294921875006, -1.616015624999974],
                            [123.15039062500003, -1.304492187500003],
                            [122.89042968750007, -1.58720703124996],
                            [122.81083984375002, -1.432128906249986],
                            [122.90800781250002, -1.182226562499963],
                            [123.2123046875, -1.171289062499966],
                        ],
                    ],
                    [
                        [
                            [109.71025390625007, -1.1806640625],
                            [109.46367187500002, -1.277539062500026],
                            [109.4759765625, -0.9853515625],
                            [109.74335937500004, -1.039355468749989],
                            [109.71025390625007, -1.1806640625],
                        ],
                    ],
                    [
                        [
                            [134.96533203124997, -1.116015624999974],
                            [134.86171875, -1.114160156249952],
                            [134.82792968750002, -0.978808593750003],
                            [134.99628906250004, -1.03408203124998],
                            [134.96533203124997, -1.116015624999974],
                        ],
                    ],
                    [
                        [
                            [99.16386718750007, -1.777929687500006],
                            [98.82773437500006, -1.609960937499977],
                            [98.60175781250004, -1.197851562499949],
                            [98.67607421875007, -0.970507812500003],
                            [98.93261718750003, -0.954003906250009],
                            [99.2672851562501, -1.62773437499996],
                            [99.16386718750007, -1.777929687500006],
                        ],
                    ],
                    [
                        [
                            [131.00185546875005, -1.315527343750034],
                            [130.78232421875006, -1.255468749999963],
                            [130.67294921875006, -0.959765625000031],
                            [131.03300781250007, -0.917578124999963],
                            [131.00185546875005, -1.315527343750034],
                        ],
                    ],
                    [
                        [
                            [135.38300781250004, -0.6513671875],
                            [135.89355468749997, -0.725781249999969],
                            [136.37529296875007, -1.094042968750031],
                            [136.1647460937501, -1.214746093750023],
                            [135.91503906250003, -1.178417968749997],
                            [135.74707031249997, -0.823046874999974],
                            [135.64570312500004, -0.881933593749991],
                            [135.38300781250004, -0.6513671875],
                        ],
                    ],
                    [
                        [
                            [127.30039062500012, -0.780957031250026],
                            [127.1564453125001, -0.760937500000026],
                            [127.20908203125006, -0.619335937499955],
                            [127.30039062500012, -0.780957031250026],
                        ],
                    ],
                    [
                        [
                            [130.6266601562501, -0.528710937499966],
                            [130.46542968750006, -0.486523437499983],
                            [130.6159179687501, -0.417285156250003],
                            [130.6266601562501, -0.528710937499966],
                        ],
                    ],
                    [
                        [
                            [121.86435546875012, -0.406835937500006],
                            [121.88125, -0.502636718749983],
                            [121.65527343749997, -0.526171874999989],
                            [121.86435546875012, -0.406835937500006],
                        ],
                    ],
                    [
                        [
                            [140.97343750000007, -2.609765625],
                            [140.97353515625, -2.803417968750026],
                            [140.975, -6.346093750000023],
                            [140.86230468749997, -6.740039062499989],
                            [140.97519531250006, -6.90537109375002],
                            [140.97617187500012, -9.11875],
                            [140.00292968749997, -8.19550781250004],
                            [140.11699218750002, -7.923730468750009],
                            [139.93476562500004, -8.101171875],
                            [139.38564453125, -8.189062499999963],
                            [139.24882812500002, -7.982421874999972],
                            [138.890625, -8.237792968749943],
                            [139.08798828125012, -7.587207031250017],
                            [138.74794921875, -7.25146484375],
                            [139.17685546875006, -7.1904296875],
                            [138.84570312500003, -7.13632812499999],
                            [138.60136718750007, -6.936523437499972],
                            [138.86455078125007, -6.858398437499943],
                            [138.43867187500004, -6.343359375],
                            [138.2962890625, -5.94902343749996],
                            [138.37460937500006, -5.84365234374998],
                            [138.19960937500005, -5.80703125],
                            [138.33964843750007, -5.675683593749966],
                            [138.08710937500004, -5.70917968750004],
                            [138.06083984375002, -5.46523437499998],
                            [137.27978515624997, -4.945410156249949],
                            [136.61884765625004, -4.81875],
                            [135.97968750000004, -4.530859374999963],
                            [135.19560546875007, -4.450683593749972],
                            [134.67968749999997, -4.079101562499943],
                            [134.70654296875003, -3.954785156250026],
                            [134.88652343750007, -3.938476562499986],
                            [134.26621093750012, -3.945800781249972],
                            [134.14707031250006, -3.79677734374998],
                            [133.97382812500004, -3.817968750000034],
                            [133.67832031250006, -3.4794921875],
                            [133.8415039062501, -3.054785156249991],
                            [133.70039062500004, -3.0875],
                            [133.653125, -3.364355468749991],
                            [133.51816406250012, -3.411914062500003],
                            [133.40087890625003, -3.899023437500034],
                            [133.24873046875004, -4.062304687499989],
                            [132.91445312500005, -4.05693359374996],
                            [132.75390625000003, -3.703613281250014],
                            [132.86972656250006, -3.550976562499997],
                            [132.75136718750005, -3.294628906249997],
                            [131.97119140624997, -2.788574218750014],
                            [132.2306640625001, -2.680371093749997],
                            [132.725, -2.789062500000028],
                            [133.19101562500006, -2.43779296874996],
                            [133.70009765625005, -2.624609375],
                            [133.75332031250005, -2.450683593750014],
                            [133.90488281250012, -2.390917968750003],
                            [133.79101562500003, -2.293652343749997],
                            [133.92158203125004, -2.102050781249957],
                            [132.96279296875005, -2.272558593749963],
                            [132.30761718749997, -2.24228515625002],
                            [132.02343749999997, -1.99033203125002],
                            [131.93037109375004, -1.559667968750034],
                            [131.29375, -1.393457031250009],
                            [130.99589843750007, -1.42470703124998],
                            [131.1908203125, -1.165820312500003],
                            [131.2572265625, -0.855468750000014],
                            [131.80429687500006, -0.703808593750026],
                            [132.39375, -0.355468750000028],
                            [132.85644531250003, -0.417382812500023],
                            [133.47265624999997, -0.726171874999963],
                            [133.97451171875, -0.744335937500026],
                            [134.11152343750004, -0.84677734375002],
                            [134.07197265625004, -1.001855468749994],
                            [134.25957031250007, -1.362988281250026],
                            [134.105859375, -1.720996093749946],
                            [134.19482421875003, -2.309082031249943],
                            [134.45996093749997, -2.83232421874996],
                            [134.48330078125, -2.583007812499972],
                            [134.62744140624997, -2.536718749999963],
                            [134.70214843749997, -2.933593749999986],
                            [134.84335937500006, -2.909179687499986],
                            [134.88681640625006, -3.209863281249966],
                            [135.25156250000012, -3.368554687499966],
                            [135.48662109375002, -3.34511718749998],
                            [135.85917968750002, -2.99531250000004],
                            [136.38994140625002, -2.273339843750037],
                            [137.07207031250002, -2.105078124999949],
                            [137.1710937500001, -2.025488281249991],
                            [137.1234375, -1.840917968749963],
                            [137.80625000000012, -1.483203125],
                            [139.78955078125003, -2.34824218750002],
                            [140.62255859374997, -2.44580078125],
                            [140.74746093750005, -2.607128906249997],
                            [140.97343750000007, -2.609765625],
                        ],
                    ],
                    [
                        [
                            [104.47421875000012, -0.334667968749955],
                            [104.59013671875002, -0.466601562500017],
                            [104.36318359375, -0.658593749999966],
                            [104.25712890625002, -0.463281249999966],
                            [104.47421875000012, -0.334667968749955],
                        ],
                    ],
                    [
                        [
                            [127.56699218750006, -0.318945312499949],
                            [127.68242187500002, -0.46835937500002],
                            [127.60498046874997, -0.610156249999946],
                            [127.88017578125002, -0.808691406249991],
                            [127.7611328125, -0.883691406249994],
                            [127.62382812500002, -0.76601562499999],
                            [127.46269531250002, -0.80595703124996],
                            [127.46865234375, -0.64296875],
                            [127.3, -0.500292968749946],
                            [127.32509765625, -0.335839843750023],
                            [127.45517578125012, -0.406347656249991],
                            [127.56699218750006, -0.318945312499949],
                        ],
                    ],
                    [
                        [
                            [127.24990234375005, -0.4953125],
                            [127.11914062500003, -0.520507812499986],
                            [127.12646484375003, -0.278613281250003],
                            [127.29003906250003, -0.284375],
                            [127.24990234375005, -0.4953125],
                        ],
                    ],
                    [
                        [
                            [103.73652343750004, -0.347949218750003],
                            [103.461328125, -0.357617187500011],
                            [103.54892578125006, -0.227539062499986],
                            [103.73652343750004, -0.347949218750003],
                        ],
                    ],
                    [
                        [
                            [130.81328125000007, -0.004101562500026],
                            [131.27685546875003, -0.149804687499952],
                            [131.33974609375005, -0.290332031249989],
                            [131.00537109374997, -0.360742187500037],
                            [130.62216796875006, -0.0859375],
                            [130.89921875000002, -0.344433593749997],
                            [130.7501953125001, -0.44384765625],
                            [130.6886718750001, -0.296582031250011],
                            [130.55078124999997, -0.366406250000026],
                            [130.23662109375002, -0.209667968749983],
                            [130.3625, -0.072851562500006],
                            [130.81328125000007, -0.004101562500026],
                        ],
                    ],
                    [
                        [
                            [98.45927734375007, -0.530468749999969],
                            [98.30966796875012, -0.531835937499977],
                            [98.4271484375, -0.226464843750037],
                            [98.3229492187501, -0.000781249999974],
                            [98.54414062500004, -0.257617187499989],
                            [98.45927734375007, -0.530468749999969],
                        ],
                    ],
                    [
                        [
                            [104.77861328125007, -0.175976562499955],
                            [105.00537109374997, -0.282812499999963],
                            [104.44707031250002, -0.189160156249983],
                            [104.54267578125004, 0.01772460937498],
                            [104.77861328125007, -0.175976562499955],
                        ],
                    ],
                    [
                        [
                            [103.28447265625002, 0.541943359375011],
                            [103.13955078125, 0.549072265625043],
                            [103.18740234375, 0.699755859375017],
                            [103.28447265625002, 0.541943359375011],
                        ],
                    ],
                    [
                        [
                            [103.0275390625001, 0.746630859374974],
                            [102.4904296875001, 0.856640625],
                            [102.50664062500002, 1.088769531250037],
                            [103.00244140624997, 0.859277343750009],
                            [103.0275390625001, 0.746630859374974],
                        ],
                    ],
                    [
                        [
                            [103.42392578125012, 1.048339843749972],
                            [103.31542968750003, 1.071289062500028],
                            [103.37998046875006, 1.133642578125034],
                            [103.42392578125012, 1.048339843749972],
                        ],
                    ],
                    [
                        [
                            [103.16640625000005, 0.870166015625003],
                            [102.7018554687501, 1.0537109375],
                            [102.72558593749997, 1.158837890625023],
                            [102.99941406250005, 1.067773437500023],
                            [103.16640625000005, 0.870166015625003],
                        ],
                    ],
                    [
                        [
                            [104.02480468750005, 1.180566406250009],
                            [104.13984375000004, 1.165576171874974],
                            [104.06611328125004, 0.989550781249989],
                            [103.93222656250012, 1.071386718749963],
                            [104.02480468750005, 1.180566406250009],
                        ],
                    ],
                    [
                        [
                            [104.58535156250005, 1.21611328124996],
                            [104.66289062500002, 1.04951171875004],
                            [104.57519531250003, 0.831933593750037],
                            [104.43925781250002, 1.050439453125051],
                            [104.25195312499997, 1.014892578125],
                            [104.36181640624997, 1.18149414062502],
                            [104.58535156250005, 1.21611328124996],
                        ],
                    ],
                    [
                        [
                            [102.4271484375, 0.990136718750023],
                            [102.27958984375002, 1.075683593750043],
                            [102.25634765625003, 1.397070312499963],
                            [102.44287109374997, 1.234228515625006],
                            [102.4271484375, 0.990136718750023],
                        ],
                    ],
                    [
                        [
                            [97.48154296875006, 1.465087890624972],
                            [97.93193359375002, 0.973925781250003],
                            [97.82041015625012, 0.564453124999986],
                            [97.683984375, 0.596093750000037],
                            [97.60390625000005, 0.83388671874998],
                            [97.40537109375012, 0.946972656250026],
                            [97.07919921875006, 1.425488281249983],
                            [97.35595703124997, 1.539746093749997],
                            [97.48154296875006, 1.465087890624972],
                        ],
                    ],
                    [
                        [
                            [102.49189453125004, 1.459179687500011],
                            [102.49941406250005, 1.330908203124991],
                            [102.02402343750012, 1.607958984375031],
                            [102.49189453125004, 1.459179687500011],
                        ],
                    ],
                    [
                        [
                            [124.88886718750004, 0.995312500000011],
                            [124.42753906250002, 0.470605468750051],
                            [123.75380859375, 0.305517578124991],
                            [123.26542968750007, 0.326611328125026],
                            [122.996875, 0.493505859375006],
                            [121.01298828125002, 0.441699218750017],
                            [120.57900390625, 0.5283203125],
                            [120.19228515625, 0.268505859374997],
                            [120.01328125000012, -0.196191406249994],
                            [120.062890625, -0.555566406250023],
                            [120.240625, -0.868261718749949],
                            [120.51757812499997, -1.039453125],
                            [120.66738281250005, -1.370117187499972],
                            [121.14853515625012, -1.33945312500002],
                            [121.5755859375, -0.828515625000023],
                            [121.96962890625005, -0.933300781249969],
                            [122.27998046875004, -0.757031250000026],
                            [122.88876953125006, -0.755175781250003],
                            [122.8294921875, -0.658886718750026],
                            [123.17148437500012, -0.57070312499999],
                            [123.37968750000002, -0.648535156249949],
                            [123.43417968750006, -0.778222656249994],
                            [123.37792968749997, -1.004101562500011],
                            [122.90283203125003, -0.900976562499963],
                            [122.25068359375004, -1.555273437500034],
                            [121.8585937500001, -1.69326171874998],
                            [121.65097656250006, -1.895410156249952],
                            [121.35546874999997, -1.878222656250003],
                            [122.29169921875004, -2.907617187500023],
                            [122.39902343750006, -3.200878906249997],
                            [122.25292968749997, -3.620410156250017],
                            [122.68964843750004, -4.084472656249972],
                            [122.84794921875002, -4.064550781250006],
                            [122.8722656250001, -4.391992187500009],
                            [122.71972656250003, -4.340722656249952],
                            [122.11425781250003, -4.540234375000011],
                            [122.03808593749997, -4.832421875000023],
                            [121.58867187500007, -4.759570312500017],
                            [121.48652343750004, -4.581054687499972],
                            [121.61806640625, -4.092675781249952],
                            [120.89179687500004, -3.520605468750034],
                            [121.05429687500012, -3.167089843749949],
                            [121.0521484375, -2.751660156249955],
                            [120.87939453124997, -2.64560546875002],
                            [120.65361328125002, -2.667578124999977],
                            [120.26103515625007, -2.949316406249991],
                            [120.43662109375012, -3.70732421874996],
                            [120.42011718750004, -4.617382812500011],
                            [120.27929687499997, -5.146093749999977],
                            [120.4303710937501, -5.591015625000026],
                            [119.9515625, -5.577636718749972],
                            [119.71728515625003, -5.693359375000014],
                            [119.55742187500007, -5.611035156250026],
                            [119.36035156249997, -5.314160156250026],
                            [119.59404296875007, -4.523144531249997],
                            [119.62363281250006, -4.034375],
                            [119.46748046875004, -3.512988281249989],
                            [118.99462890624997, -3.537597656250028],
                            [118.86767578124997, -3.39804687500002],
                            [118.78330078125006, -2.720800781249977],
                            [119.09218750000005, -2.482910156250014],
                            [119.32187500000012, -1.929687500000014],
                            [119.308984375, -1.408203125],
                            [119.508203125, -0.906738281249972],
                            [119.71132812500005, -0.680761718750034],
                            [119.84433593750006, -0.861914062499991],
                            [119.721875, -0.088476562499991],
                            [119.865625, 0.040087890625003],
                            [119.80927734375004, 0.238671875000051],
                            [119.9132812500001, 0.445068359375],
                            [120.26953125000003, 0.970800781249991],
                            [120.60253906249997, 0.854394531249994],
                            [120.86796875000007, 1.25283203124998],
                            [121.0817382812501, 1.327636718750028],
                            [121.40410156250002, 1.243603515624969],
                            [121.59179687499997, 1.067968749999977],
                            [122.43662109375006, 1.018066406250028],
                            [122.83828125, 0.845703125],
                            [123.06650390625006, 0.941796875000037],
                            [123.93076171875006, 0.850439453124977],
                            [124.53369140624997, 1.230468750000043],
                            [124.94707031250002, 1.672167968749974],
                            [125.11093750000012, 1.685693359374966],
                            [125.2337890625, 1.502294921875006],
                            [124.88886718750004, 0.995312500000011],
                        ],
                    ],
                    [
                        [
                            [101.70810546875006, 2.078417968750045],
                            [101.71943359375004, 1.789160156250006],
                            [101.50078125000002, 1.733203124999974],
                            [101.40966796875003, 2.021679687500026],
                            [101.70810546875006, 2.078417968750045],
                        ],
                    ],
                    [
                        [
                            [127.73271484375007, 0.848144531250043],
                            [127.8810546875001, 0.832128906249977],
                            [127.96728515624997, 1.042578125000048],
                            [128.16074218750006, 1.1578125],
                            [128.22246093750002, 1.400634765624986],
                            [128.68837890625, 1.572558593750017],
                            [128.70263671874997, 1.106396484374997],
                            [128.29882812500003, 0.87680664062502],
                            [128.26064453125, 0.733789062500023],
                            [128.61123046875, 0.549951171875051],
                            [128.89960937500004, 0.216259765625011],
                            [127.9831054687501, 0.471875],
                            [127.88740234375004, 0.298339843750043],
                            [127.97783203125002, -0.24833984374996],
                            [128.4254882812501, -0.892675781249949],
                            [128.04638671875003, -0.706054687499943],
                            [127.69160156250004, -0.241894531249983],
                            [127.70869140625004, 0.288085937499986],
                            [127.53710937500003, 0.610888671875031],
                            [127.60800781250006, 0.848242187499977],
                            [127.42851562500002, 1.139990234374991],
                            [127.63173828125, 1.843701171875011],
                            [128.03642578125002, 2.199023437500017],
                            [127.88681640625012, 1.83295898437504],
                            [128.0109375000001, 1.701220703125031],
                            [128.01171874999997, 1.331738281249983],
                            [127.65283203124997, 1.013867187499969],
                            [127.73271484375007, 0.848144531250043],
                        ],
                    ],
                    [
                        [
                            [97.3341796875001, 2.075634765625011],
                            [97.10830078125, 2.216894531250006],
                            [97.29140625, 2.200830078125023],
                            [97.3341796875001, 2.075634765625011],
                        ],
                    ],
                    [
                        [
                            [128.45390625000002, 2.051757812500028],
                            [128.29589843749997, 2.034716796875017],
                            [128.2179687500001, 2.297460937499991],
                            [128.60214843750012, 2.59760742187504],
                            [128.68847656250003, 2.473681640625017],
                            [128.62324218750004, 2.224414062500031],
                            [128.45390625000002, 2.051757812500028],
                        ],
                    ],
                    [
                        [
                            [96.46367187500002, 2.360009765625037],
                            [95.80859374999997, 2.655615234375034],
                            [95.7171875, 2.825976562500017],
                            [95.89580078125007, 2.8890625],
                            [96.41728515625007, 2.515185546875031],
                            [96.46367187500002, 2.360009765625037],
                        ],
                    ],
                    [
                        [
                            [108.8875, 2.905419921875037],
                            [108.7865234375, 2.885644531250009],
                            [108.88574218750003, 2.998974609374997],
                            [108.8875, 2.905419921875037],
                        ],
                    ],
                    [
                        [
                            [105.76035156250006, 2.863037109375014],
                            [105.69218750000002, 3.0625],
                            [105.83671875000007, 2.97651367187504],
                            [105.76035156250006, 2.863037109375014],
                        ],
                    ],
                    [
                        [
                            [106.28525390625006, 3.15712890624998],
                            [106.28369140624997, 3.088232421874977],
                            [106.20097656250002, 3.204882812500031],
                            [106.28525390625006, 3.15712890624998],
                        ],
                    ],
                    [
                        [
                            [117.65839843750004, 3.280517578124986],
                            [117.54785156250003, 3.43198242187502],
                            [117.68085937500004, 3.407519531250017],
                            [117.65839843750004, 3.280517578124986],
                        ],
                    ],
                    [
                        [
                            [125.65810546875, 3.436035156250043],
                            [125.51152343750007, 3.461132812500011],
                            [125.46884765625006, 3.73325195312502],
                            [125.65810546875, 3.436035156250043],
                        ],
                    ],
                    [
                        [
                            [117.88476562499997, 4.186132812500006],
                            [117.92285156250003, 4.054296874999977],
                            [117.73681640624997, 4.004003906250034],
                            [117.64902343750012, 4.168994140624974],
                            [117.88476562499997, 4.186132812500006],
                        ],
                    ],
                    [
                        [
                            [108.31601562500006, 3.689648437500026],
                            [108.10039062500002, 3.70454101562504],
                            [108.24326171875006, 3.810351562500017],
                            [108.00234375, 3.982861328124983],
                            [108.24833984375002, 4.21713867187502],
                            [108.39287109375007, 3.986181640625034],
                            [108.31601562500006, 3.689648437500026],
                        ],
                    ],
                    [
                        [
                            [117.5744140625001, 4.17060546875004],
                            [117.46533203124997, 4.076074218749966],
                            [117.77724609375005, 3.689257812500031],
                            [117.05595703125007, 3.622656249999963],
                            [117.34628906250006, 3.426611328124991],
                            [117.35244140625, 3.19375],
                            [117.61064453125002, 3.064355468749994],
                            [117.56914062500002, 2.92929687500002],
                            [117.69765625, 2.887304687499991],
                            [117.6388671875001, 2.825292968749963],
                            [118.0666015625001, 2.317822265624969],
                            [117.7892578125001, 2.026855468750014],
                            [118.98496093750006, 0.982128906249983],
                            [118.53476562500006, 0.813525390625017],
                            [118.19609375000002, 0.874365234374977],
                            [117.91162109374997, 1.098681640625017],
                            [117.96425781250005, 0.889550781250051],
                            [117.74511718749997, 0.72963867187498],
                            [117.52216796875004, 0.235888671875017],
                            [117.46289062500003, -0.323730468749957],
                            [117.5625, -0.770898437500009],
                            [116.91396484375, -1.223632812499972],
                            [116.73984375000006, -1.044238281250017],
                            [116.75341796874997, -1.327343749999955],
                            [116.27548828125006, -1.784863281249997],
                            [116.42431640625003, -1.784863281249997],
                            [116.45195312500002, -1.923144531250017],
                            [116.31396484374997, -2.139843750000011],
                            [116.56542968749997, -2.299707031249994],
                            [116.52929687499997, -2.51054687499996],
                            [116.31679687500005, -2.55185546875002],
                            [116.33066406250012, -2.902148437499974],
                            [116.16630859375002, -2.934570312500014],
                            [116.2572265625, -3.126367187500009],
                            [115.95615234375012, -3.595019531250003],
                            [114.6935546875001, -4.169726562500017],
                            [114.5255859375001, -3.376660156250011],
                            [114.44599609375004, -3.481835937500037],
                            [114.34433593750012, -3.444433593749963],
                            [114.34433593750012, -3.23515625],
                            [114.23632812500003, -3.36113281249996],
                            [114.0822265625001, -3.27890625],
                            [113.70507812499997, -3.45527343750004],
                            [113.6100585937501, -3.195703125],
                            [113.34316406250005, -3.246484374999966],
                            [113.03398437500002, -2.933496093749966],
                            [112.97148437500002, -3.187109375000034],
                            [112.75800781250004, -3.322167968750009],
                            [112.60029296875004, -3.400488281249977],
                            [112.28496093750002, -3.32099609375004],
                            [111.85810546875004, -3.551855468750006],
                            [111.82304687500007, -3.057226562499949],
                            [111.69472656250005, -2.88945312499996],
                            [110.93007812500005, -3.071093750000017],
                            [110.82968750000012, -2.9951171875],
                            [110.89931640625, -2.908593749999952],
                            [110.703125, -3.020898437500009],
                            [110.57402343750007, -2.89140625],
                            [110.25605468750004, -2.966113281249946],
                            [110.09658203125, -2.001367187499966],
                            [109.95986328125, -1.862792968749972],
                            [109.98330078125, -1.274804687499994],
                            [109.78740234375007, -1.011328124999963],
                            [109.25878906250003, -0.807421874999989],
                            [109.37275390625004, -0.638183593749972],
                            [109.12109375000003, -0.39091796874996],
                            [109.2575195312501, 0.031152343750051],
                            [108.94453125000004, 0.355664062499997],
                            [108.91679687500007, 0.912646484375045],
                            [108.95859375000006, 1.134619140624963],
                            [109.1315429687501, 1.253857421875011],
                            [109.01025390624997, 1.239648437500051],
                            [109.07587890625004, 1.495898437500031],
                            [109.37851562500006, 1.922705078125034],
                            [109.62890625000003, 2.027539062499983],
                            [109.53896484375, 1.89619140625004],
                            [109.65400390625004, 1.614892578125023],
                            [110.50576171875005, 0.861962890625023],
                            [111.10136718750002, 1.050537109374986],
                            [111.80898437500005, 1.011669921874969],
                            [112.078515625, 1.143359374999974],
                            [112.1857421875001, 1.4390625],
                            [112.47617187500006, 1.559082031250028],
                            [112.94296875000006, 1.566992187500034],
                            [113.00654296875004, 1.433886718750003],
                            [113.6222656250001, 1.2359375],
                            [113.90234375000003, 1.434277343749997],
                            [114.5125, 1.452001953124963],
                            [114.83056640625003, 1.980029296874989],
                            [114.78642578125002, 2.250488281250014],
                            [115.1791015625, 2.523193359374972],
                            [115.08076171875004, 2.63422851562504],
                            [115.117578125, 2.89487304687502],
                            [115.24697265625005, 3.025927734374989],
                            [115.45439453125002, 3.034326171875009],
                            [115.67880859375006, 4.193017578124994],
                            [115.86074218750005, 4.348046875000037],
                            [116.51474609375006, 4.370800781249969],
                            [117.10058593750003, 4.337060546875023],
                            [117.5744140625001, 4.17060546875004],
                        ],
                    ],
                    [
                        [
                            [126.81660156250004, 4.033496093750003],
                            [126.70449218750005, 4.070996093749997],
                            [126.81357421875006, 4.258496093750011],
                            [126.72207031250005, 4.344189453124969],
                            [126.75732421874997, 4.547900390624989],
                            [126.9210937500001, 4.291015624999972],
                            [126.81660156250004, 4.033496093750003],
                        ],
                    ],
                    [
                        [
                            [96.49257812500005, 5.229345703124991],
                            [97.54716796875002, 5.205859375],
                            [98.2484375, 4.41455078125],
                            [98.3073242187501, 4.09287109375002],
                            [99.73232421875005, 3.183056640625026],
                            [100.523828125, 2.18916015625004],
                            [100.88789062500004, 1.948242187499986],
                            [100.82822265625012, 2.242578125],
                            [101.04619140625002, 2.257470703125023],
                            [101.47666015625006, 1.693066406250054],
                            [102.019921875, 1.442138671875],
                            [102.38994140625007, 0.84199218750004],
                            [103.03183593750006, 0.57890625],
                            [103.0075195312501, 0.415332031249974],
                            [102.55, 0.216455078124966],
                            [103.33896484375012, 0.513720703125045],
                            [103.67265625000007, 0.288916015624977],
                            [103.78671875000012, 0.046972656249991],
                            [103.42851562500007, -0.19179687499998],
                            [103.40517578125005, -0.36220703124998],
                            [103.5091796875, -0.465527343749969],
                            [103.43857421875006, -0.575585937500009],
                            [103.72109375, -0.886718749999986],
                            [104.36054687500004, -1.038378906249974],
                            [104.51591796875002, -1.81943359375002],
                            [104.84521484375003, -2.092968749999969],
                            [104.65078125000005, -2.595214843749972],
                            [104.97080078125012, -2.370898437500017],
                            [105.39697265624997, -2.380175781249946],
                            [106.0443359375, -3.10625],
                            [105.84375, -3.61367187499998],
                            [105.93046875000007, -3.833007812499986],
                            [105.83144531250005, -4.16289062499996],
                            [105.88720703124997, -5.009570312499974],
                            [105.74833984375007, -5.818261718749966],
                            [105.34941406250007, -5.549511718750011],
                            [105.08134765625002, -5.74550781249998],
                            [104.63955078125005, -5.520410156250037],
                            [104.68398437500005, -5.89267578125002],
                            [104.60156249999997, -5.90458984374996],
                            [103.8314453125, -5.079589843750028],
                            [102.53769531250006, -4.152148437499989],
                            [102.12753906250006, -3.599218749999963],
                            [101.57861328124997, -3.166992187500014],
                            [100.88955078125, -2.248535156249957],
                            [100.85527343750002, -1.934179687499949],
                            [100.30820312500006, -0.82666015625],
                            [99.66982421875005, 0.045068359375037],
                            [99.15917968749997, 0.351757812499997],
                            [98.59531250000006, 1.864599609375006],
                            [97.70078125000006, 2.358544921875009],
                            [97.59082031249997, 2.846582031250037],
                            [97.3913085937501, 2.975292968749969],
                            [96.9689453125001, 3.575146484374969],
                            [96.44472656250005, 3.81630859374998],
                            [95.57861328125003, 4.661962890625048],
                            [95.20664062500006, 5.284033203125034],
                            [95.22783203125002, 5.564794921875034],
                            [95.62890625000003, 5.609082031249997],
                            [96.13330078125003, 5.294287109374991],
                            [96.49257812500005, 5.229345703124991],
                        ],
                    ],
                ],
            },
            properties: {
                name: "印度尼西亚",
                childNum: 107,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-4.412060546874983, 54.185351562499996],
                        [-4.785351562499983, 54.073046875],
                        [-4.424707031249994, 54.407177734375],
                        [-4.412060546874983, 54.185351562499996],
                    ],
                ],
            },
            properties: {
                name: "马恩岛",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [75.32221348233018, 32.28516356678968],
                            [75.62496871116024, 32.28516356678968],
                            [75.73585997688717, 32.78417426256088],
                            [76.32728006076415, 32.87658365066666],
                            [76.62299010270264, 33.32014871357439],
                            [77.06655516561037, 33.301666835953235],
                            [77.71342088235082, 32.6917648744551],
                            [78.10154031239509, 32.87658365066666],
                            [78.49194250885338, 32.53122786149202],
                            [78.38964843749997, 32.51987304687498],
                            [78.41748046874997, 32.466699218749994],
                            [78.4552734375001, 32.30034179687502],
                            [78.49589843750002, 32.21577148437504],
                            [78.72558593750009, 31.983789062500023],
                            [78.71972656250009, 31.887646484374983],
                            [78.69345703125006, 31.740380859374994],
                            [78.7550781250001, 31.55029296875],
                            [78.74355468750005, 31.323779296875017],
                            [79.10712890625004, 31.402636718750102],
                            [79.38847656250013, 31.064208984375085],
                            [79.66425781250004, 30.96523437499999],
                            [79.92451171875004, 30.888769531250034],
                            [80.20712890625006, 30.683740234375023],
                            [80.19121093750002, 30.56840820312496],
                            [80.87353515625003, 30.290576171875045],
                            [80.98544921875006, 30.23710937499999],
                            [81.01025390625014, 30.164501953125097],
                            [80.96611328125002, 30.180029296875063],
                            [80.90761718750005, 30.171923828125017],
                            [80.84814453125009, 30.139746093750034],
                            [80.81992187500012, 30.119335937499955],
                            [80.68408203125014, 29.994335937500068],
                            [80.54902343750015, 29.899804687499994],
                            [80.40185546875003, 29.730273437500102],
                            [80.31689453125014, 29.572070312500017],
                            [80.25488281250009, 29.423339843750114],
                            [80.25595703125006, 29.318017578125136],
                            [80.23300781250006, 29.194628906250045],
                            [80.16953125000012, 29.124316406250102],
                            [80.13046875000006, 29.100390625000045],
                            [80.08457031249995, 28.994189453125074],
                            [80.05166015625, 28.870312500000068],
                            [80.07070312500005, 28.830175781250063],
                            [80.22656250000003, 28.723339843750125],
                            [80.32480468750012, 28.66640625000008],
                            [80.41855468749995, 28.61201171875001],
                            [80.47910156250012, 28.604882812499994],
                            [80.49580078125015, 28.635791015625074],
                            [80.51787109375002, 28.665185546875023],
                            [80.58701171875006, 28.64960937500004],
                            [81.16894531250014, 28.335009765625074],
                            [81.85263671875018, 27.867089843750136],
                            [81.89687500000011, 27.87446289062504],
                            [81.94521484375005, 27.89926757812495],
                            [81.98769531250016, 27.91376953125004],
                            [82.03701171875, 27.90058593750004],
                            [82.11191406250006, 27.86494140625004],
                            [82.28769531250018, 27.756542968749983],
                            [82.45136718750004, 27.671826171874955],
                            [82.62988281249997, 27.687060546875045],
                            [82.67734375000006, 27.67343749999995],
                            [82.71083984375005, 27.596679687500114],
                            [82.73339843750003, 27.518994140625097],
                            [83.28974609375004, 27.370996093750136],
                            [83.36943359375002, 27.410253906249977],
                            [83.38398437500004, 27.444824218750085],
                            [83.44716796875011, 27.46533203125],
                            [83.55166015625011, 27.456347656249932],
                            [83.74697265625011, 27.395947265625068],
                            [83.8288085937501, 27.377832031250108],
                            [84.09101562499993, 27.491357421875136],
                            [84.22978515625007, 27.427832031250006],
                            [84.48085937500005, 27.348193359375102],
                            [84.61015625000002, 27.298681640624977],
                            [84.64072265625012, 27.249853515624977],
                            [84.65478515625014, 27.20366210937499],
                            [84.65380859375009, 27.09169921875008],
                            [84.68535156250013, 27.041015625000057],
                            [85.19179687500011, 26.766552734375097],
                            [85.29296875000009, 26.741015625000045],
                            [85.56845703125012, 26.839843750000114],
                            [85.64843749999997, 26.829003906250023],
                            [85.69990234375004, 26.781640624999966],
                            [85.73730468750003, 26.639746093750034],
                            [85.79453125000006, 26.60415039062505],
                            [86.00732421875009, 26.64936523437504],
                            [86.70136718750015, 26.435058593750057],
                            [87.01640625000002, 26.555419921875085],
                            [87.2874023437499, 26.360302734375125],
                            [87.41357421875014, 26.42294921875009],
                            [87.84921875000006, 26.43691406250008],
                            [87.99511718750014, 26.38237304687499],
                            [88.02695312500023, 26.395019531250085],
                            [88.05488281250004, 26.43002929687492],
                            [88.11152343750004, 26.58642578125],
                            [88.1615234375, 26.724804687500125],
                            [88.15722656250009, 26.807324218750068],
                            [88.1110351562501, 26.928466796875057],
                            [87.99316406250009, 27.086083984374994],
                            [87.984375, 27.133935546874994],
                            [88.14697265625014, 27.749218750000097],
                            [88.15029296875011, 27.843310546875074],
                            [88.10976562500005, 27.87060546874997],
                            [88.10898437499995, 27.93300781250005],
                            [88.14111328125003, 27.948925781250097],
                            [88.27519531250013, 27.96884765625009],
                            [88.42597656250015, 28.011669921875097],
                            [88.57792968750002, 28.093359375000034],
                            [88.80371093750003, 28.006933593750034],
                            [88.74902343749997, 27.521875000000136],
                            [88.7648437500001, 27.429882812500068],
                            [88.83251953125003, 27.362841796875074],
                            [88.89140625000002, 27.316064453125136],
                            [88.88164062500007, 27.29746093750009],
                            [88.76035156250006, 27.21811523437509],
                            [88.73876953125009, 27.175585937499932],
                            [88.85761718750015, 26.961474609375017],
                            [89.14824218750002, 26.816162109375085],
                            [89.33212890625018, 26.848632812500114],
                            [89.58613281250004, 26.778955078125136],
                            [89.60996093750012, 26.719433593750097],
                            [89.71093750000009, 26.713916015625045],
                            [89.76386718750004, 26.7015625],
                            [89.94316406250013, 26.723925781249932],
                            [90.12294921875011, 26.754589843749983],
                            [90.20605468749997, 26.847509765625063],
                            [90.34589843750004, 26.890332031250097],
                            [90.73964843750005, 26.771679687500068],
                            [91.2865234375, 26.78994140625008],
                            [91.42675781249997, 26.867089843749966],
                            [91.45585937500013, 26.866894531250125],
                            [91.51757812500009, 26.807324218750068],
                            [91.67158203124993, 26.80200195312503],
                            [91.84208984375013, 26.852978515625125],
                            [91.94375, 26.860839843750114],
                            [91.99833984375013, 26.85498046875],
                            [92.04970703125016, 26.87485351562495],
                            [92.73155507489682, 26.833697862861648],
                            [93.30975376159499, 26.784950522650554],
                            [93.61047043679247, 27.32239435188504],
                            [94.06979001484449, 27.589407158584788],
                            [95.10800937321915, 27.749636881153737],
                            [95.74000740838363, 28.116850432722256],
                            [96.19577594042592, 28.04291597700983],
                            [96.96279296875, 27.698291015625017],
                            [96.88359375000013, 27.514843750000125],
                            [96.90195312500012, 27.43959960937508],
                            [97.10371093749993, 27.163330078125114],
                            [97.10205078125003, 27.115429687500125],
                            [96.95341796875013, 27.13330078125003],
                            [96.79785156249997, 27.29619140624999],
                            [96.19082031250005, 27.26127929687499],
                            [95.20146484375007, 26.641406250000017],
                            [95.05976562500015, 26.473974609375006],
                            [95.06894531250006, 26.191113281250097],
                            [95.10839843750014, 26.091406250000034],
                            [95.12929687500011, 26.070410156250034],
                            [95.13242187500006, 26.041259765624943],
                            [94.99199218750002, 25.77045898437504],
                            [94.66777343750007, 25.458886718749966],
                            [94.55302734375013, 25.215722656249994],
                            [94.70371093750012, 25.097851562499955],
                            [94.49316406250003, 24.637646484374983],
                            [94.37724609375002, 24.473730468750006],
                            [94.29306640625012, 24.321875],
                            [94.07480468750006, 23.8720703125],
                            [93.68339843750007, 24.00654296875004],
                            [93.45214843750003, 23.987402343750034],
                            [93.32626953125006, 24.064208984375057],
                            [93.36601562500007, 23.132519531249955],
                            [93.34941406250007, 23.08496093750003],
                            [93.20390625000002, 23.03701171875005],
                            [93.07871093750018, 22.718212890625097],
                            [93.16201171875, 22.360205078125006],
                            [93.07060546875002, 22.20942382812501],
                            [92.96455078125015, 22.003759765625034],
                            [92.90947265625013, 21.988916015625023],
                            [92.85429687500002, 22.010156250000108],
                            [92.77138671875, 22.104785156250017],
                            [92.68896484375009, 22.130957031250006],
                            [92.63037109375014, 22.011328124999977],
                            [92.57490234374993, 21.97807617187496],
                            [92.5612304687501, 22.04804687500001],
                            [92.49140625000004, 22.685400390625006],
                            [92.46445312500006, 22.734423828125045],
                            [92.36162109375002, 22.929003906250074],
                            [92.33378906250002, 23.242382812499955],
                            [92.24609375000003, 23.68359374999997],
                            [92.04404296875006, 23.677783203125017],
                            [91.97851562500003, 23.691992187500063],
                            [91.92958984375011, 23.685986328125097],
                            [91.92949218750019, 23.598242187499977],
                            [91.93789062500011, 23.504687500000102],
                            [91.75419921875013, 23.28730468750004],
                            [91.75097656250003, 23.053515625000017],
                            [91.55351562500013, 22.991552734375006],
                            [91.43623046875004, 23.19990234375001],
                            [91.359375, 23.06835937500003],
                            [91.16044921875019, 23.660644531250085],
                            [91.35019531250012, 24.06049804687501],
                            [91.72656250000003, 24.20507812499997],
                            [91.84619140624997, 24.175292968749943],
                            [92.06416015625004, 24.374365234375006],
                            [92.11748046875002, 24.493945312500017],
                            [92.22666015625012, 24.77099609374997],
                            [92.22832031250002, 24.881347656250085],
                            [92.2512695312499, 24.895068359375045],
                            [92.38496093750004, 24.848779296875023],
                            [92.46835937500018, 24.944140625000074],
                            [92.04970703125016, 25.16948242187499],
                            [90.61308593750002, 25.16772460937497],
                            [90.11962890625003, 25.21997070312497],
                            [89.86630859375012, 25.293164062499955],
                            [89.81406250000006, 25.305371093749955],
                            [89.80087890625012, 25.33613281250001],
                            [89.82490234375004, 25.56015625],
                            [89.82294921875015, 25.94140625000003],
                            [89.67089843750009, 26.213818359375125],
                            [89.57275390625003, 26.13232421875003],
                            [89.54990234375006, 26.00527343750008],
                            [89.28925781250015, 26.037597656250085],
                            [89.01865234375012, 26.410253906249977],
                            [88.95195312500002, 26.412109375],
                            [88.97041015625004, 26.250878906250023],
                            [88.94072265625002, 26.24536132812497],
                            [88.68281250000004, 26.291699218749983],
                            [88.51826171875004, 26.51777343750004],
                            [88.36992187500002, 26.56411132812508],
                            [88.35146484375005, 26.482568359374966],
                            [88.38623046875003, 26.471533203125034],
                            [88.44042968749997, 26.369482421875034],
                            [88.33398437499997, 26.257519531249955],
                            [88.15078125000005, 26.087158203125057],
                            [88.1066406250001, 25.841113281250045],
                            [88.14746093749997, 25.811425781250023],
                            [88.50244140625009, 25.53701171875008],
                            [88.76914062500006, 25.490478515625],
                            [88.85478515625002, 25.333544921875017],
                            [88.94414062500002, 25.290771484375],
                            [88.92978515625012, 25.222998046875063],
                            [88.57382812500006, 25.18789062499999],
                            [88.45625, 25.188427734375125],
                            [88.37294921875016, 24.961523437500063],
                            [88.31337890625011, 24.8818359375],
                            [88.27949218750015, 24.881933593750034],
                            [88.18886718750016, 24.920605468750097],
                            [88.14980468750011, 24.91464843749995],
                            [88.04511718750015, 24.71303710937508],
                            [88.03027343750009, 24.664453125000136],
                            [88.02343750000003, 24.627832031250136],
                            [88.07910156250009, 24.549902343750063],
                            [88.14550781250003, 24.485791015624955],
                            [88.225, 24.460644531249983],
                            [88.3375, 24.45385742187503],
                            [88.49853515625003, 24.34663085937504],
                            [88.64228515625015, 24.325976562500102],
                            [88.72353515625011, 24.27490234375],
                            [88.7335937500001, 24.230908203125097],
                            [88.72656250000009, 24.18623046875004],
                            [88.71376953125016, 24.069628906250102],
                            [88.69980468750006, 24.00253906249992],
                            [88.56738281250009, 23.674414062500034],
                            [88.63574218749997, 23.55],
                            [88.69765625, 23.493017578125034],
                            [88.72441406250002, 23.254980468750034],
                            [88.89707031250018, 23.21040039062501],
                            [88.92812500000011, 23.186621093749977],
                            [88.89970703125002, 22.843505859375057],
                            [88.92070312500002, 22.632031249999955],
                            [89.05, 22.274609374999983],
                            [89.02792968750023, 21.937207031249983],
                            [88.94931640625018, 21.937939453125125],
                            [89.05166015625, 21.654101562500045],
                            [88.85751953125012, 21.744677734375017],
                            [88.74501953125011, 21.584375],
                            [88.74023437500003, 22.005419921875017],
                            [88.64160156250003, 22.121972656250136],
                            [88.58466796875015, 21.659716796874932],
                            [88.44599609375004, 21.614257812500085],
                            [88.28750000000016, 21.758203125000108],
                            [88.25371093750002, 21.622314453124943],
                            [88.0568359375001, 21.694140625000017],
                            [88.19628906249997, 22.139550781249994],
                            [87.94140625000003, 22.374316406250045],
                            [88.15927734375018, 22.12172851562508],
                            [87.82373046875003, 21.727343750000045],
                            [87.20068359375009, 21.544873046874983],
                            [86.95410156250014, 21.365332031250006],
                            [86.84228515625009, 21.106347656249994],
                            [86.97548828125005, 20.70014648437501],
                            [86.75039062500011, 20.313232421875057],
                            [86.37656250000006, 20.006738281249966],
                            [86.24521484375012, 20.05302734374999],
                            [86.27949218750021, 19.919433593749943],
                            [85.575, 19.69291992187499],
                            [85.496875, 19.696923828125108],
                            [85.50410156250004, 19.887695312500057],
                            [85.24863281250006, 19.757666015625034],
                            [85.18076171875018, 19.59487304687508],
                            [85.44160156249993, 19.626562499999977],
                            [84.77099609375009, 19.125390625000023],
                            [84.10410156250018, 18.29267578125001],
                            [82.35957031250004, 17.09619140624997],
                            [82.25878906250014, 16.55986328124996],
                            [81.76191406250015, 16.32949218750008],
                            [81.28613281249997, 16.337060546875023],
                            [80.97871093750004, 15.758349609375074],
                            [80.64658203125006, 15.895019531250028],
                            [80.29345703125014, 15.710742187499989],
                            [80.0534179687501, 15.074023437499932],
                            [80.17871093750003, 14.478320312500074],
                            [80.11171875000005, 14.212207031250045],
                            [80.30654296875016, 13.485058593750054],
                            [80.15625, 13.713769531250108],
                            [80.06210937500006, 13.60625],
                            [80.34238281250006, 13.361328125000071],
                            [80.22910156250018, 12.690332031249966],
                            [79.85849609375018, 11.988769531250043],
                            [79.69316406250007, 11.312548828124946],
                            [79.79902343750004, 11.338671874999932],
                            [79.84863281250009, 11.196875],
                            [79.83818359375002, 10.322558593750045],
                            [79.31455078125018, 10.256689453124949],
                            [78.93994140625009, 9.565771484375063],
                            [79.01992187500005, 9.333349609374963],
                            [79.41142578125002, 9.192382812500014],
                            [78.97958984375018, 9.268554687500085],
                            [78.42148437500006, 9.105029296874989],
                            [78.19248046874995, 8.890869140625057],
                            [78.06015625000006, 8.384570312499932],
                            [77.51757812500003, 8.078320312500068],
                            [77.06591796875003, 8.315917968749986],
                            [76.5534179687501, 8.902783203124997],
                            [76.32460937500016, 9.452099609374997],
                            [76.24238281250004, 9.927099609374949],
                            [76.37558593750006, 9.539892578124935],
                            [76.45878906250013, 9.536230468750077],
                            [76.34648437500002, 9.922119140625],
                            [76.19560546875002, 10.086132812500026],
                            [75.72382812500015, 11.361767578125026],
                            [74.94550781250004, 12.56455078124992],
                            [74.38222656250005, 14.494726562500048],
                            [73.94921875000014, 15.074755859375088],
                            [73.80078125000009, 15.39697265625],
                            [73.93193359375013, 15.39697265625],
                            [73.77177734375013, 15.573046874999989],
                            [73.83281250000013, 15.659375],
                            [73.67988281250015, 15.708886718750136],
                            [73.47607421875003, 16.05424804687496],
                            [72.87548828124997, 18.642822265625114],
                            [72.97207031250011, 19.15332031250003],
                            [72.8346679687501, 18.975585937500057],
                            [72.80302734375013, 19.07929687500004],
                            [72.81162109375, 19.298925781250006],
                            [72.98720703125, 19.27744140625009],
                            [72.78789062500013, 19.362988281250097],
                            [72.66777343750019, 19.83095703125005],
                            [72.89375, 20.672753906250136],
                            [72.81386718750011, 21.117187500000085],
                            [72.62382812500002, 21.371972656250108],
                            [72.73476562500016, 21.470800781250006],
                            [72.61328125000009, 21.461816406250108],
                            [73.1125, 21.750439453125125],
                            [72.54306640625, 21.69658203124999],
                            [72.70019531250003, 21.971923828124943],
                            [72.52226562500013, 21.976220703125108],
                            [72.55302734375007, 22.159960937500074],
                            [72.80917968749995, 22.23330078125008],
                            [72.18281250000015, 22.26972656250004],
                            [72.30644531250002, 22.18920898437497],
                            [72.27441406250009, 22.089746093749966],
                            [72.03720703125006, 21.82304687499999],
                            [72.2103515625, 21.72822265625004],
                            [72.25400390625006, 21.531005859375],
                            [72.01523437500012, 21.155712890625097],
                            [71.0246093750001, 20.73886718750009],
                            [70.71933593750006, 20.740429687500068],
                            [70.12734375, 21.094677734375097],
                            [68.96992187500021, 22.29028320312497],
                            [69.05166015625016, 22.437304687500074],
                            [69.27656250000004, 22.285498046875063],
                            [70.17724609375014, 22.57275390624997],
                            [70.48925781250009, 23.08950195312508],
                            [70.33945312500012, 22.939746093749932],
                            [69.66464843750006, 22.759082031250074],
                            [69.23593749999995, 22.848535156250023],
                            [68.64072265625006, 23.189941406250114],
                            [68.41748046875009, 23.57148437500004],
                            [68.7767578125, 23.852099609375017],
                            [68.23496093749995, 23.596972656250074],
                            [68.16503906250009, 23.857324218749994],
                            [68.28251953125013, 23.927978515625],
                            [68.38125000000016, 23.950878906250068],
                            [68.48867187500011, 23.96723632812501],
                            [68.5866210937501, 23.966601562500074],
                            [68.72412109375003, 23.964697265625034],
                            [68.72812500000012, 24.265625],
                            [68.73964843750016, 24.291992187500085],
                            [68.75898437499993, 24.307226562500006],
                            [68.78115234375011, 24.313720703125085],
                            [68.8, 24.30908203125003],
                            [68.82832031250004, 24.26401367187509],
                            [68.86347656250015, 24.26650390625005],
                            [68.90078125000011, 24.29243164062501],
                            [68.98457031250015, 24.273095703124966],
                            [69.05156250000013, 24.28632812500001],
                            [69.11953125000011, 24.26865234374995],
                            [69.23505859374993, 24.268261718750068],
                            [69.44345703124995, 24.275390625000085],
                            [69.55917968750006, 24.273095703124966],
                            [69.80517578125009, 24.16523437500004],
                            [70.0982421875, 24.2875],
                            [70.28906250000009, 24.356298828125063],
                            [70.54677734375, 24.418310546875063],
                            [70.56503906250006, 24.385791015625017],
                            [70.55585937500015, 24.331103515625074],
                            [70.57929687500015, 24.279052734374943],
                            [70.65947265625013, 24.24609374999997],
                            [70.71630859375009, 24.237988281250097],
                            [70.7672851562501, 24.245410156250017],
                            [70.80507812500011, 24.26196289062503],
                            [70.88623046875014, 24.34375],
                            [70.92812500000016, 24.362353515625045],
                            [70.98281250000011, 24.361035156250125],
                            [71.04404296875006, 24.400097656250097],
                            [71.04531250000005, 24.42998046874996],
                            [70.96982421875012, 24.571875],
                            [70.97636718750013, 24.61875],
                            [71.00234375000016, 24.6539062499999],
                            [71.04785156250003, 24.687744140625085],
                            [71.02070312500021, 24.75766601562492],
                            [70.95087890625015, 24.89160156250003],
                            [70.87773437500019, 25.06298828124997],
                            [70.65205078125004, 25.422900390625102],
                            [70.64843750000003, 25.666943359375068],
                            [70.5695312500001, 25.705957031250023],
                            [70.50585937500009, 25.685302734375085],
                            [70.44853515625013, 25.681347656249983],
                            [70.26464843750009, 25.70654296874997],
                            [70.10019531250006, 25.91005859375005],
                            [70.14921875000002, 26.347558593749994],
                            [70.11464843750016, 26.548046874999983],
                            [69.47001953125002, 26.804443359375],
                            [69.56796875, 27.174609375000102],
                            [69.89628906250007, 27.473632812500085],
                            [70.04980468750009, 27.694726562500023],
                            [70.14453125000003, 27.849023437499994],
                            [70.19394531250006, 27.89487304687492],
                            [70.24433593750004, 27.934130859375102],
                            [70.4037109375, 28.025048828124994],
                            [70.48857421875013, 28.023144531250125],
                            [70.62910156250015, 27.937451171875068],
                            [70.6916015625001, 27.76899414062504],
                            [70.79794921875012, 27.709619140625023],
                            [70.87490234375016, 27.71445312499995],
                            [71.18476562500004, 27.831640625],
                            [71.54296875000003, 27.869873046875],
                            [71.8703125000001, 27.9625],
                            [71.88886718750004, 28.04746093749992],
                            [71.94804687500002, 28.177294921875102],
                            [72.12851562500012, 28.34633789062508],
                            [72.29199218750003, 28.69726562499997],
                            [72.34189453125006, 28.751904296875097],
                            [72.90332031250003, 29.02875976562501],
                            [73.38164062500013, 29.934375],
                            [73.8091796875, 30.093359375],
                            [73.88652343750013, 30.162011718750136],
                            [73.93339843750002, 30.222070312500108],
                            [73.92460937500007, 30.28164062499999],
                            [73.88271484375, 30.352148437499977],
                            [73.89931640625, 30.435351562500045],
                            [74.00898437500004, 30.519677734374994],
                            [74.33935546875003, 30.893554687499943],
                            [74.38037109375003, 30.89340820312509],
                            [74.50976562500009, 30.959667968750097],
                            [74.63281250000014, 31.034667968750114],
                            [74.62578125000002, 31.068750000000108],
                            [74.61035156250009, 31.112841796875045],
                            [74.51767578125012, 31.185595703124932],
                            [74.53496093750007, 31.261376953125108],
                            [74.59394531249993, 31.465380859375102],
                            [74.58183593750013, 31.523925781250114],
                            [74.50996093750015, 31.712939453125074],
                            [74.52597656249995, 31.765136718750057],
                            [74.55556640625011, 31.818554687500097],
                            [74.63574218750003, 31.889746093750034],
                            [74.73945312500015, 31.948828125],
                            [75.07148437500015, 32.08935546875003],
                            [75.13876953125, 32.10478515624999],
                            [75.25410156250004, 32.140332031250125],
                            [75.33349609374997, 32.279199218749994],
                            [75.32221348233018, 32.28516356678968],
                        ],
                    ],
                ],
            },
            properties: {
                name: "印度",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [72.49199218750002, -7.37744140625],
                        [72.42910156250002, -7.435351562500003],
                        [72.34970703125, -7.263378906250011],
                        [72.447265625, -7.395703125000011],
                        [72.44560546875002, -7.220410156250011],
                        [72.49199218750002, -7.37744140625],
                    ],
                ],
            },
            properties: {
                name: "英属印度洋领地",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-9.948193359374926, 53.91313476562499],
                            [-10.265722656249949, 53.977685546874994],
                            [-9.99638671874996, 54.00361328125004],
                            [-9.948193359374926, 53.91313476562499],
                        ],
                    ],
                    [
                        [
                            [-6.218017578125, 54.08872070312506],
                            [-6.347607421874926, 53.94130859375005],
                            [-6.027392578124989, 52.927099609375006],
                            [-6.463183593749932, 52.345361328124994],
                            [-6.325, 52.246679687500034],
                            [-6.890234375, 52.15922851562499],
                            [-6.965771484374926, 52.24951171875],
                            [-8.057812499999926, 51.82558593750005],
                            [-8.4091796875, 51.888769531250034],
                            [-8.349121093749943, 51.73930664062496],
                            [-8.813427734374926, 51.584912109374955],
                            [-9.737304687499943, 51.473730468750034],
                            [-9.524902343750028, 51.68110351562501],
                            [-10.120751953124994, 51.60068359375006],
                            [-9.598828124999983, 51.87441406250005],
                            [-10.341064453124943, 51.798925781250034],
                            [-9.909667968749972, 52.122949218749966],
                            [-10.39023437499992, 52.134912109374994],
                            [-10.356689453125, 52.20693359375002],
                            [-9.772119140624937, 52.250097656250034],
                            [-9.90605468749996, 52.403710937499966],
                            [-9.632226562499937, 52.54692382812502],
                            [-8.783447265624943, 52.679638671874955],
                            [-8.990283203124989, 52.755419921875045],
                            [-9.175390624999949, 52.634912109374994],
                            [-9.916601562499977, 52.56972656250005],
                            [-9.46489257812496, 52.82319335937498],
                            [-9.299218749999966, 53.09755859375002],
                            [-8.930126953124983, 53.207080078125045],
                            [-9.51420898437496, 53.23823242187498],
                            [-10.091259765624926, 53.41284179687503],
                            [-10.116992187499932, 53.548535156249955],
                            [-9.720654296874926, 53.6044921875],
                            [-9.901611328124943, 53.72719726562502],
                            [-9.578222656249949, 53.80541992187497],
                            [-9.578857421875, 53.879833984374955],
                            [-9.9140625, 53.863720703124955],
                            [-9.856445312499972, 54.095361328124994],
                            [-10.092675781249966, 54.15576171875003],
                            [-10.056396484374943, 54.25781250000006],
                            [-8.545556640624994, 54.24121093750003],
                            [-8.623144531249977, 54.346875],
                            [-8.133447265624966, 54.64082031250001],
                            [-8.763916015624972, 54.68120117187496],
                            [-8.377294921874977, 54.88945312500002],
                            [-8.274609374999955, 55.146289062500045],
                            [-7.667089843749977, 55.25649414062502],
                            [-7.65874023437496, 54.97094726562503],
                            [-7.308789062500011, 55.365820312500006],
                            [-6.961669921874972, 55.23789062500006],
                            [-7.218652343749937, 55.09199218749998],
                            [-7.55039062499992, 54.767968749999966],
                            [-7.910595703124955, 54.698339843750006],
                            [-7.75439453125, 54.59492187499998],
                            [-8.118261718749977, 54.41425781250004],
                            [-7.606542968750006, 54.14384765625002],
                            [-7.324511718750017, 54.13344726562502],
                            [-7.007714843749937, 54.40668945312501],
                            [-6.649804687499937, 54.05864257812496],
                            [-6.218017578125, 54.08872070312506],
                        ],
                    ],
                ],
            },
            properties: {
                name: "爱尔兰",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [56.18798828125003, 26.92114257812497],
                            [55.95429687500004, 26.70112304687501],
                            [55.31152343749997, 26.592626953125006],
                            [55.76259765625005, 26.81196289062504],
                            [55.75761718750002, 26.94765625000005],
                            [56.279394531250006, 26.952099609374983],
                            [56.18798828125003, 26.92114257812497],
                        ],
                    ],
                    [
                        [
                            [46.1144531250001, 38.877783203125034],
                            [46.490625, 38.90668945312498],
                            [47.995898437500074, 39.683935546875034],
                            [48.322167968749994, 39.39907226562502],
                            [48.10439453125005, 39.241113281249994],
                            [48.292089843750006, 39.01884765624999],
                            [47.99648437499999, 38.85375976562503],
                            [48.59267578125005, 38.41108398437498],
                            [48.86875, 38.43549804687498],
                            [48.95996093750003, 37.89013671875],
                            [49.171191406250074, 37.60058593749997],
                            [50.13046875, 37.407128906249994],
                            [50.53320312499997, 37.01367187500006],
                            [51.11855468750005, 36.742578124999966],
                            [52.19013671875004, 36.62172851562505],
                            [53.76767578125006, 36.93032226562502],
                            [53.91542968750005, 36.93032226562502],
                            [53.67949218750002, 36.853125],
                            [53.970117187499994, 36.818310546874955],
                            [53.91416015625006, 37.34355468750002],
                            [54.6994140625001, 37.47016601562498],
                            [54.90009765625004, 37.77792968750006],
                            [55.38085937500003, 38.051123046875034],
                            [56.272070312500006, 38.080419921875034],
                            [56.440625, 38.249414062499994],
                            [57.1935546875001, 38.216406250000034],
                            [57.35371093750004, 37.97333984374998],
                            [58.261621093749994, 37.665820312500045],
                            [58.81542968750003, 37.683496093749994],
                            [59.30175781249997, 37.51064453125005],
                            [59.454980468749994, 37.25283203125002],
                            [60.06279296875002, 36.962890625],
                            [60.34130859375003, 36.63764648437501],
                            [61.11962890625003, 36.64257812500003],
                            [61.212011718750006, 36.190527343750034],
                            [61.15292968750006, 35.97675781250001],
                            [61.25214843750004, 35.86762695312498],
                            [61.26201171875002, 35.61958007812498],
                            [61.28183593750006, 35.55341796875001],
                            [61.2785156250001, 35.513769531250006],
                            [61.245507812499994, 35.47407226562501],
                            [61.18925781250002, 35.31201171875003],
                            [61.1, 35.272314453125034],
                            [61.08007812499997, 34.85561523437505],
                            [60.95117187499997, 34.65385742187499],
                            [60.91474609375004, 34.63398437500001],
                            [60.80234375000006, 34.55463867187501],
                            [60.73945312500004, 34.544726562500045],
                            [60.7262695312501, 34.51826171874998],
                            [60.736132812500074, 34.491796875],
                            [60.76259765625005, 34.475244140624994],
                            [60.88945312500002, 34.31943359375006],
                            [60.642675781250006, 34.30717773437496],
                            [60.48574218750005, 34.09477539062502],
                            [60.4859375, 33.7119140625],
                            [60.57382812500006, 33.58833007812498],
                            [60.91699218749997, 33.505224609375006],
                            [60.56054687499997, 33.13784179687502],
                            [60.5765625, 32.99487304687503],
                            [60.71044921874997, 32.6],
                            [60.82929687500004, 32.24941406250005],
                            [60.82724609375006, 32.16796874999997],
                            [60.789941406249994, 31.98710937499999],
                            [60.7875, 31.87719726562497],
                            [60.791601562500006, 31.660595703124983],
                            [60.82070312499999, 31.495166015625045],
                            [60.854101562500006, 31.483251953125006],
                            [61.110742187499994, 31.45112304687504],
                            [61.346484375000074, 31.42163085937497],
                            [61.66015625000003, 31.382421874999977],
                            [61.7550781250001, 31.285302734374994],
                            [61.814257812500074, 31.072558593750017],
                            [61.810839843750074, 30.913281249999983],
                            [61.78417968749997, 30.831933593750023],
                            [61.55947265625005, 30.59936523437497],
                            [61.33164062500006, 30.36372070312501],
                            [60.84335937500006, 29.85869140624999],
                            [61.03417968750003, 29.663427734374977],
                            [61.15214843750002, 29.542724609375],
                            [61.8898437500001, 28.546533203124994],
                            [62.7625, 28.202050781249994],
                            [62.782324218750006, 27.800537109375],
                            [62.75273437500002, 27.265625],
                            [63.16679687500002, 27.25249023437499],
                            [63.19609375000002, 27.243945312500017],
                            [63.25625, 27.20791015625005],
                            [63.30156250000002, 27.151464843750006],
                            [63.30517578124997, 27.124560546875017],
                            [63.242089843749994, 27.07768554687499],
                            [63.25039062499999, 26.879248046875063],
                            [63.24160156250005, 26.86474609375003],
                            [63.18613281250006, 26.83759765625001],
                            [63.168066406250006, 26.66557617187496],
                            [62.31230468750002, 26.490869140624994],
                            [62.23935546875006, 26.357031249999977],
                            [62.12597656249997, 26.368994140625034],
                            [61.842382812500006, 26.225927734375006],
                            [61.809960937499994, 26.165283203125],
                            [61.78076171874997, 25.99584960937503],
                            [61.75439453125003, 25.843359375000063],
                            [61.737695312499994, 25.821093750000045],
                            [61.66865234375004, 25.76899414062501],
                            [61.6618164062501, 25.751269531250017],
                            [61.67138671874997, 25.69238281250003],
                            [61.64013671875003, 25.584619140624994],
                            [61.61542968750004, 25.28613281250003],
                            [61.58789062499997, 25.20234375000001],
                            [61.533105468749994, 25.195507812499955],
                            [61.41220703125006, 25.102099609375017],
                            [60.66386718750002, 25.28222656250003],
                            [60.51054687500002, 25.437060546875045],
                            [60.40019531250002, 25.311572265625074],
                            [59.45605468749997, 25.481494140625045],
                            [59.0460937500001, 25.417285156250017],
                            [58.79785156249997, 25.554589843750023],
                            [57.334570312500006, 25.791552734375074],
                            [57.03603515625005, 26.80068359375005],
                            [56.728125, 27.127685546875057],
                            [56.118066406249994, 27.14311523437499],
                            [54.75927734375003, 26.50507812500004],
                            [54.24707031250003, 26.696630859374977],
                            [53.70576171875004, 26.72558593750003],
                            [52.69160156250004, 27.323388671875023],
                            [52.475878906250074, 27.61650390624999],
                            [52.03076171874997, 27.824414062499955],
                            [51.58906250000004, 27.864208984374983],
                            [51.27890625, 28.13134765624997],
                            [51.06201171874997, 28.72612304687499],
                            [50.86699218750002, 28.870166015625017],
                            [50.87578125000002, 29.062695312499983],
                            [50.67519531250005, 29.146582031250034],
                            [50.64960937500004, 29.420068359374966],
                            [50.16894531250003, 29.921240234375034],
                            [50.071582031250074, 30.198535156250017],
                            [49.55488281250004, 30.028955078125023],
                            [49.028125, 30.333447265624983],
                            [49.224511718749994, 30.472314453125023],
                            [49.00195312500003, 30.506542968749983],
                            [48.91914062500004, 30.120898437500017],
                            [48.54648437500006, 29.962353515624955],
                            [48.47851562499997, 30.003808593749966],
                            [48.43457031249997, 30.03759765625],
                            [48.33105468749997, 30.28544921874996],
                            [48.01494140625002, 30.465625],
                            [48.01064453125005, 30.989794921875017],
                            [47.679492187500074, 31.00239257812501],
                            [47.679492187500074, 31.400585937499955],
                            [47.75390624999997, 31.601367187500017],
                            [47.829980468749994, 31.79443359375],
                            [47.71455078125004, 31.936425781249966],
                            [47.5915039062501, 32.087988281250034],
                            [47.51191406250004, 32.15083007812504],
                            [47.3297851562501, 32.45551757812501],
                            [47.28515625000003, 32.474023437499966],
                            [47.121386718750074, 32.46660156249996],
                            [46.569921875, 32.83393554687501],
                            [46.37705078125006, 32.92924804687499],
                            [46.29824218750005, 32.95024414062502],
                            [46.11279296875003, 32.957666015624994],
                            [46.09306640625002, 32.97587890624999],
                            [46.08046875, 33.028222656249994],
                            [46.0807617187501, 33.08652343750006],
                            [46.14111328125003, 33.174414062500034],
                            [46.145898437499994, 33.229638671874994],
                            [46.01992187500005, 33.41572265624998],
                            [45.39707031250006, 33.970849609374994],
                            [45.542773437500074, 34.21552734375004],
                            [45.459375, 34.470361328124994],
                            [45.50078125000002, 34.58159179687499],
                            [45.6375, 34.573828125],
                            [45.678125, 34.798437500000034],
                            [45.92089843750003, 35.02851562500001],
                            [46.04179687500002, 35.08017578125006],
                            [46.13378906249997, 35.127636718749955],
                            [46.15468750000005, 35.19672851562498],
                            [46.112109375000074, 35.32167968750005],
                            [45.97109375000005, 35.524169921875],
                            [46.03740234375002, 35.67314453124999],
                            [46.180957031250074, 35.71137695312504],
                            [46.2625, 35.74414062500006],
                            [46.27343749999997, 35.77324218750002],
                            [46.16748046874997, 35.820556640625],
                            [45.77636718749997, 35.82182617187499],
                            [45.36162109375002, 36.015332031249955],
                            [45.241113281249994, 36.35595703125],
                            [45.20654296874997, 36.397167968749955],
                            [45.15527343749997, 36.407373046874994],
                            [45.11240234375006, 36.409277343750034],
                            [45.053125, 36.47163085937501],
                            [44.76513671875003, 37.142431640625006],
                            [44.79414062500004, 37.290380859375034],
                            [44.574023437500074, 37.435400390625006],
                            [44.589941406250006, 37.710351562499966],
                            [44.21132812499999, 37.908056640625006],
                            [44.4499023437501, 38.33422851562506],
                            [44.2985351562501, 38.38627929687499],
                            [44.27167968750004, 38.83603515625006],
                            [44.02324218750002, 39.37744140625006],
                            [44.3893554687501, 39.422119140625],
                            [44.58710937500004, 39.76855468750006],
                            [44.81718750000002, 39.65043945312496],
                            [45.4796875000001, 39.00625],
                            [46.1144531250001, 38.877783203125034],
                        ],
                    ],
                ],
            },
            properties: {
                name: "伊朗",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [45.6375, 34.573828125],
                        [45.50078125000002, 34.581591796874996],
                        [45.459375, 34.470361328124994],
                        [45.54277343750002, 34.21552734375],
                        [45.397070312500006, 33.970849609374994],
                        [46.01992187500002, 33.41572265625],
                        [46.14589843750002, 33.229638671874994],
                        [46.14111328125, 33.1744140625],
                        [46.08076171875001, 33.0865234375],
                        [46.08046875000002, 33.028222656249994],
                        [46.09306640625002, 32.975878906249996],
                        [46.11279296875, 32.957666015624994],
                        [46.377050781250006, 32.929248046874996],
                        [46.569921875, 32.833935546875],
                        [47.12138671875002, 32.466601562499996],
                        [47.28515625, 32.474023437499994],
                        [47.32978515625001, 32.455517578125],
                        [47.51191406250001, 32.150830078125],
                        [47.59150390625001, 32.08798828125],
                        [47.71455078125001, 31.936425781249994],
                        [47.82998046875002, 31.79443359375],
                        [47.75390625, 31.601367187499996],
                        [47.67949218750002, 31.400585937499997],
                        [47.67949218750002, 31.002392578124997],
                        [48.01064453125002, 30.989794921874996],
                        [48.01494140625002, 30.465625],
                        [48.3310546875, 30.285449218749996],
                        [48.546484375, 29.962353515624997],
                        [48.454199218750006, 29.9384765625],
                        [48.354589843750006, 29.956738281249997],
                        [48.141699218750006, 30.040917968749994],
                        [47.982519531250006, 30.011328125],
                        [47.97871093750001, 29.9828125],
                        [47.64375, 30.097314453124994],
                        [47.14824218750002, 30.0009765625],
                        [46.905859375, 29.5375],
                        [46.76933593750002, 29.347460937499996],
                        [46.69375, 29.259667968749994],
                        [46.53144531250001, 29.096240234374996],
                        [46.3564453125, 29.063671875],
                        [44.71650390625001, 29.193603515625],
                        [43.77373046875002, 29.84921875],
                        [42.07441406250001, 31.080371093749996],
                        [40.47890625000002, 31.893359375],
                        [40.36933593750001, 31.93896484375],
                        [40.02783203125, 31.995019531249994],
                        [39.7041015625, 32.042529296874996],
                        [39.14541015625002, 32.12451171875],
                        [39.29277343750002, 32.24384765625],
                        [39.24746093750002, 32.350976562499994],
                        [39.04140625000002, 32.3056640625],
                        [38.773535156250006, 33.372216796874994],
                        [40.98701171875001, 34.429052734375],
                        [41.19472656250002, 34.768994140625],
                        [41.354101562500006, 35.640429687499996],
                        [41.295996093750006, 36.383349609374996],
                        [41.41679687500002, 36.5146484375],
                        [41.78857421875, 36.59716796875],
                        [42.358984375, 37.10859375],
                        [42.45585937500002, 37.128710937499996],
                        [42.63544921875001, 37.249267578125],
                        [42.74111328125002, 37.3619140625],
                        [42.77460937500001, 37.371875],
                        [42.869140625, 37.334912109375],
                        [42.936621093750006, 37.324755859374996],
                        [43.09248046875001, 37.3673828125],
                        [43.67578125, 37.22724609375],
                        [43.83642578125, 37.223535156249994],
                        [44.01318359375, 37.313525390624996],
                        [44.11445312500001, 37.30185546875],
                        [44.15625, 37.282958984375],
                        [44.19179687500002, 37.249853515625],
                        [44.20839843750002, 37.20263671875],
                        [44.20166015625, 37.051806640624996],
                        [44.281835937500006, 36.97802734375],
                        [44.32558593750002, 37.0107421875],
                        [44.401953125, 37.058496093749994],
                        [44.60595703125, 37.176025390625],
                        [44.66933593750002, 37.173583984375],
                        [44.73095703125, 37.165283203125],
                        [44.76513671875, 37.142431640625],
                        [45.053125, 36.471630859375],
                        [45.112402343750006, 36.40927734375],
                        [45.1552734375, 36.407373046874994],
                        [45.20654296875, 36.39716796875],
                        [45.24111328125002, 36.35595703125],
                        [45.36162109375002, 36.01533203125],
                        [45.7763671875, 35.821826171874996],
                        [46.16748046875, 35.820556640625],
                        [46.2734375, 35.773242187499996],
                        [46.2625, 35.744140625],
                        [46.18095703125002, 35.711376953125],
                        [46.03740234375002, 35.673144531249996],
                        [45.97109375000002, 35.524169921875],
                        [46.11210937500002, 35.321679687499994],
                        [46.15468750000002, 35.196728515625],
                        [46.1337890625, 35.12763671875],
                        [46.04179687500002, 35.08017578125],
                        [45.9208984375, 35.028515625],
                        [45.678125, 34.7984375],
                        [45.6375, 34.573828125],
                    ],
                ],
            },
            properties: {
                name: "伊拉克",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-15.543115234374994, 66.228515625],
                        [-14.595849609374994, 66.38154296875],
                        [-15.117382812499983, 66.125634765625],
                        [-14.698193359374983, 66.02021484375],
                        [-14.827099609374983, 65.7642578125],
                        [-14.391845703125, 65.78740234375],
                        [-14.473388671875, 65.575341796875],
                        [-14.166943359374983, 65.64228515625],
                        [-13.617871093749983, 65.5193359375],
                        [-13.804785156249977, 65.35478515625],
                        [-13.599316406249983, 65.0359375],
                        [-14.04443359375, 64.74189453125],
                        [-14.385107421874977, 64.74521484375],
                        [-14.475390624999989, 64.493994140625],
                        [-14.927392578124994, 64.319677734375],
                        [-15.832910156249994, 64.17666015625],
                        [-16.640332031249983, 63.865478515625],
                        [-17.81572265624999, 63.71298828125],
                        [-17.946923828124994, 63.5357421875],
                        [-18.65361328124999, 63.406689453125],
                        [-20.198144531249994, 63.555810546874994],
                        [-20.494042968749994, 63.687353515625],
                        [-20.413964843749994, 63.80517578125],
                        [-20.65092773437499, 63.73740234375],
                        [-21.15239257812499, 63.94453125],
                        [-22.652197265624977, 63.827734375],
                        [-22.701171875, 64.083203125],
                        [-22.51005859374999, 63.991455078125],
                        [-22.187597656249977, 64.039208984375],
                        [-21.463330078124983, 64.379150390625],
                        [-22.053369140624994, 64.313916015625],
                        [-21.950341796874994, 64.514990234375],
                        [-21.590625, 64.6263671875],
                        [-22.10600585937499, 64.533056640625],
                        [-22.467041015625, 64.794970703125],
                        [-23.818994140624994, 64.73916015625],
                        [-24.02617187499999, 64.863427734375],
                        [-22.7880859375, 65.046484375],
                        [-21.89213867187499, 65.048779296875],
                        [-21.779980468749983, 65.1876953125],
                        [-22.50908203124999, 65.19677734375],
                        [-21.844384765624994, 65.44736328125],
                        [-22.902490234374994, 65.58046875],
                        [-23.89990234375, 65.407568359375],
                        [-24.475683593749977, 65.5251953125],
                        [-24.248925781249994, 65.614990234375],
                        [-23.85673828124999, 65.53837890625],
                        [-24.092626953124977, 65.77646484375],
                        [-23.615917968749983, 65.67958984375],
                        [-23.285351562499983, 65.75],
                        [-23.832617187499977, 65.84921875],
                        [-23.52495117187499, 65.880029296875],
                        [-23.77734375, 66.017578125],
                        [-23.434472656249994, 66.02421875],
                        [-23.452539062499994, 66.181005859375],
                        [-23.018994140624983, 65.98212890625],
                        [-22.659863281249983, 66.025927734375],
                        [-22.61601562499999, 65.86748046875],
                        [-22.44169921874999, 65.90830078125],
                        [-22.4453125, 66.07001953125],
                        [-22.947900390624994, 66.212744140625],
                        [-22.48442382812499, 66.26630859375],
                        [-23.116943359375, 66.338720703125],
                        [-22.9443359375, 66.429443359375],
                        [-22.426123046874977, 66.430126953125],
                        [-21.406884765624994, 66.0255859375],
                        [-21.374902343749994, 65.74189453125],
                        [-21.658447265625, 65.723583984375],
                        [-21.12968749999999, 65.2666015625],
                        [-20.804345703124994, 65.63642578125],
                        [-20.454833984375, 65.571044921875],
                        [-20.20751953125, 66.10009765625],
                        [-19.489697265624983, 65.76806640625],
                        [-19.382958984374994, 66.07568359375],
                        [-18.845898437499983, 66.183935546875],
                        [-18.141943359374977, 65.73408203125],
                        [-18.29716796874999, 66.157421875],
                        [-17.906982421875, 66.143310546875],
                        [-17.550439453124994, 65.964404296875],
                        [-17.153027343749983, 66.20283203125],
                        [-16.838037109374994, 66.125244140625],
                        [-16.485009765624994, 66.195947265625],
                        [-16.540673828124994, 66.446728515625],
                        [-16.24931640624999, 66.522900390625],
                        [-15.985400390624989, 66.5146484375],
                        [-15.543115234374994, 66.228515625],
                    ],
                ],
            },
            properties: {
                name: "冰岛",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [35.78730468750001, 32.734912109374996],
                        [35.572851562500006, 32.640869140625],
                        [35.56904296875001, 32.619873046875],
                        [35.55146484375001, 32.3955078125],
                        [35.484375, 32.401660156249996],
                        [35.40263671875002, 32.450634765625],
                        [35.38671875, 32.493017578125],
                        [35.303808593750006, 32.512939453125],
                        [35.19326171875002, 32.534423828125],
                        [35.065039062500006, 32.46044921875],
                        [35.01054687500002, 32.338183593749996],
                        [34.95595703125002, 32.1609375],
                        [34.98974609375, 31.91328125],
                        [34.97832031250002, 31.86640625],
                        [34.95380859375001, 31.841259765624997],
                        [34.96113281250001, 31.82333984375],
                        [34.983007812500006, 31.81679687499999],
                        [35.05322265625, 31.837939453124996],
                        [35.12714843750001, 31.816748046875],
                        [35.203710937500006, 31.75],
                        [34.95097656250002, 31.602294921875],
                        [34.88046875, 31.3681640625],
                        [35.45058593750002, 31.479296875],
                        [34.97343750000002, 29.555029296875],
                        [34.904296875, 29.47734375],
                        [34.24531250000001, 31.208300781249996],
                        [34.34833984375001, 31.292919921874997],
                        [34.350195312500006, 31.362744140624997],
                        [34.52558593750001, 31.525634765625],
                        [34.47734375000002, 31.584863281249994],
                        [34.483984375, 31.59228515625],
                        [34.67841796875001, 31.895703125],
                        [35.10859375000001, 33.08369140625],
                        [35.411230468750006, 33.07568359375],
                        [35.869140625, 33.43173828125],
                        [35.91347656250002, 32.94960937499999],
                        [35.78730468750001, 32.734912109374996],
                    ],
                ],
            },
            properties: {
                name: "以色列",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [15.576562500000051, 38.220312500000034],
                            [15.099511718750023, 37.45859375],
                            [15.295703125000017, 37.05517578124997],
                            [15.112597656250017, 36.687841796875006],
                            [14.501855468750023, 36.798681640625034],
                            [14.142968750000023, 37.103662109374994],
                            [13.90546875000004, 37.10063476562502],
                            [13.169921875000028, 37.47929687499996],
                            [12.640234375000034, 37.594335937500034],
                            [12.435546874999972, 37.819775390624955],
                            [12.734375, 38.18305664062498],
                            [12.902734375000023, 38.03486328124998],
                            [13.291113281250034, 38.19145507812502],
                            [13.788867187499989, 37.981201171875],
                            [15.11875, 38.15273437500002],
                            [15.498730468750011, 38.290869140625006],
                            [15.576562500000051, 38.220312500000034],
                        ],
                    ],
                    [
                        [
                            [8.478906250000023, 39.067529296874966],
                            [8.421484375000034, 38.968652343749994],
                            [8.366796875, 39.115917968749955],
                            [8.478906250000023, 39.067529296874966],
                        ],
                    ],
                    [
                        [
                            [8.28603515625008, 41.03984375],
                            [8.205664062500034, 40.99746093750005],
                            [8.320214843750023, 41.121875],
                            [8.28603515625008, 41.03984375],
                        ],
                    ],
                    [
                        [
                            [9.632031250000011, 40.88203124999998],
                            [9.805273437500063, 40.499560546875045],
                            [9.642968750000023, 40.268408203125006],
                            [9.5625, 39.16601562500006],
                            [9.056347656250068, 39.23916015625002],
                            [8.966601562500074, 38.963720703125034],
                            [8.648535156250034, 38.92656250000002],
                            [8.418164062500068, 39.205712890624966],
                            [8.547753906250023, 39.83920898437506],
                            [8.4078125, 39.91723632812497],
                            [8.471289062500063, 40.29267578124998],
                            [8.189941406250028, 40.651611328125],
                            [8.22421875, 40.91333007812503],
                            [8.571875, 40.85019531250006],
                            [9.228417968750023, 41.257080078125],
                            [9.615332031249977, 41.01728515624998],
                            [9.632031250000011, 40.88203124999998],
                        ],
                    ],
                    [
                        [
                            [10.395117187500034, 42.85815429687503],
                            [10.419335937499994, 42.71318359374999],
                            [10.13125, 42.742041015625006],
                            [10.395117187500034, 42.85815429687503],
                        ],
                    ],
                    [
                        [
                            [13.420996093750006, 46.212304687499994],
                            [13.63251953125004, 46.17705078125002],
                            [13.634960937499983, 46.15776367187499],
                            [13.61660156250008, 46.133105468750045],
                            [13.54804687500004, 46.08911132812503],
                            [13.486425781250034, 46.03955078124997],
                            [13.480273437500017, 46.00922851562501],
                            [13.487695312500023, 45.987109375000045],
                            [13.509179687500051, 45.973779296874994],
                            [13.6005859375, 45.97978515624996],
                            [13.663476562500023, 45.7919921875],
                            [13.831152343750006, 45.680419921875],
                            [13.719824218750063, 45.58759765625001],
                            [13.628320312500051, 45.77094726562498],
                            [13.206347656250074, 45.771386718749966],
                            [12.27431640625008, 45.44604492187503],
                            [12.225683593750034, 45.24150390625002],
                            [12.523437500000028, 44.96796874999998],
                            [12.248339843750045, 44.72250976562498],
                            [12.396289062500074, 44.223876953125],
                            [13.56416015625004, 43.57128906250003],
                            [14.010449218750011, 42.68955078125006],
                            [14.54072265625004, 42.24428710937502],
                            [15.16875, 41.93403320312498],
                            [16.164648437500034, 41.89619140624998],
                            [15.900488281250034, 41.51206054687498],
                            [17.954980468749994, 40.65517578125002],
                            [18.460644531249983, 40.221044921875034],
                            [18.34375, 39.82138671874998],
                            [18.077929687500017, 39.93696289062498],
                            [17.865039062500074, 40.28017578125002],
                            [17.395800781250045, 40.34023437499999],
                            [17.179980468750045, 40.50278320312498],
                            [16.92822265625, 40.45805664062502],
                            [16.521875, 39.74755859375003],
                            [17.114550781250017, 39.38061523437497],
                            [17.174609375000017, 38.998095703125045],
                            [16.61669921875003, 38.800146484375034],
                            [16.54560546875001, 38.40908203125002],
                            [16.05683593750001, 37.941845703124955],
                            [15.72451171875008, 37.93911132812502],
                            [15.645800781250017, 38.034228515625045],
                            [15.87890625, 38.61391601562502],
                            [16.19677734375, 38.759228515624955],
                            [16.20996093750003, 38.94111328124998],
                            [15.692773437499994, 39.99018554687501],
                            [14.95087890625004, 40.23901367187497],
                            [14.94765625000008, 40.469335937500006],
                            [14.765722656250063, 40.66840820312498],
                            [14.339941406250006, 40.59882812500001],
                            [14.460546875000063, 40.72871093750001],
                            [14.04433593750008, 40.81225585937506],
                            [13.733398437500057, 41.23564453124999],
                            [13.088671875000074, 41.243847656249955],
                            [12.630859374999972, 41.469677734374955],
                            [11.637304687500063, 42.287548828124955],
                            [11.141210937499977, 42.38989257812503],
                            [11.167773437500074, 42.53515625000006],
                            [10.708398437500023, 42.93632812499999],
                            [10.514843750000011, 42.96752929687503],
                            [10.188085937500063, 43.947509765625],
                            [8.76582031250004, 44.42231445312501],
                            [8.004980468750006, 43.87675781249999],
                            [7.4931640625, 43.767138671875045],
                            [7.637207031250057, 44.16484375],
                            [7.318554687500068, 44.13798828125002],
                            [6.900195312499989, 44.33574218749996],
                            [6.99267578125, 44.82729492187502],
                            [6.634765625000028, 45.06816406249996],
                            [7.07832031250004, 45.23994140624998],
                            [7.146386718750051, 45.381738281249994],
                            [6.790917968750023, 45.740869140624966],
                            [7.021093750000034, 45.92578124999997],
                            [7.055761718749977, 45.90380859375003],
                            [7.129003906249977, 45.88041992187499],
                            [7.327929687500017, 45.912353515625],
                            [7.9931640625, 46.01591796874996],
                            [8.081542968750057, 46.25600585937502],
                            [8.231933593750057, 46.341210937499966],
                            [8.29853515625004, 46.403417968750034],
                            [8.370703125, 46.44511718750002],
                            [8.458398437500023, 46.24589843750002],
                            [8.818554687500011, 46.0771484375],
                            [8.826757812500006, 46.06103515625],
                            [8.77802734375004, 45.996191406250034],
                            [8.953710937500034, 45.83002929687501],
                            [9.023730468750074, 45.845703125],
                            [9.203417968750017, 46.21923828125],
                            [9.304394531250068, 46.49555664062498],
                            [9.399316406250023, 46.480664062499955],
                            [9.427636718750023, 46.48232421875002],
                            [9.528710937500023, 46.306201171875045],
                            [9.57958984375, 46.29609375000001],
                            [9.639453125000017, 46.29589843749997],
                            [9.78779296875004, 46.34604492187498],
                            [9.884472656250011, 46.36777343750006],
                            [9.939257812500074, 46.36181640625],
                            [10.041015625000028, 46.23808593750002],
                            [10.08056640625, 46.22797851562501],
                            [10.128320312500051, 46.238232421874955],
                            [10.109667968750074, 46.36284179687502],
                            [10.081933593750023, 46.420751953125006],
                            [10.045605468750068, 46.44790039062505],
                            [10.038281250000011, 46.483203125000045],
                            [10.061230468750068, 46.54677734375002],
                            [10.087011718750063, 46.59990234375002],
                            [10.1375, 46.614355468750034],
                            [10.195507812500068, 46.62109374999997],
                            [10.4306640625, 46.55004882812497],
                            [10.409352678571473, 46.6092047991071],
                            [10.39794921875, 46.66503906250006],
                            [10.406054687500045, 46.73486328124997],
                            [10.452832031249983, 46.86494140625001],
                            [10.47939453125008, 46.85512695312505],
                            [10.579785156250011, 46.85371093750001],
                            [10.689257812500017, 46.846386718749955],
                            [10.759765625, 46.79331054687498],
                            [10.828906250000045, 46.775244140625034],
                            [10.927343750000034, 46.76948242187501],
                            [10.993261718750034, 46.77700195312502],
                            [11.02509765625004, 46.796972656250006],
                            [11.063476562500057, 46.85913085937497],
                            [11.133886718750006, 46.93618164062505],
                            [11.244433593750045, 46.975683593750006],
                            [11.433203125000063, 46.983056640624994],
                            [11.527539062500011, 46.99741210937498],
                            [11.775683593750017, 46.986083984375],
                            [12.169433593750028, 47.082128906250006],
                            [12.19716796875008, 47.075],
                            [12.201269531250034, 47.060888671875034],
                            [12.165527343750028, 47.028173828125034],
                            [12.130761718750051, 46.98476562499999],
                            [12.154101562500017, 46.93525390625004],
                            [12.267968750000023, 46.83588867187504],
                            [12.330078125, 46.75981445312499],
                            [12.388281250000034, 46.70263671874997],
                            [12.479199218749983, 46.672509765624966],
                            [13.16875, 46.572656249999966],
                            [13.3515625, 46.55791015624999],
                            [13.490039062500045, 46.55556640625002],
                            [13.7, 46.52026367187503],
                            [13.679687500000057, 46.46289062499997],
                            [13.63710937500008, 46.44853515624999],
                            [13.563281250000045, 46.41508789062502],
                            [13.399511718749977, 46.31752929687502],
                            [13.420996093750006, 46.212304687499994],
                        ],
                    ],
                ],
            },
            properties: {
                name: "意大利",
                childNum: 6,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-77.261474609375, 18.45742187499999],
                        [-76.349853515625, 18.15185546875],
                        [-76.21079101562499, 17.913525390624997],
                        [-76.524609375, 17.8662109375],
                        [-76.85322265625, 17.97373046874999],
                        [-76.94414062499999, 17.848779296874994],
                        [-77.11948242187499, 17.880078125],
                        [-77.20498046875, 17.71494140624999],
                        [-77.36142578124999, 17.833691406249997],
                        [-77.76816406249999, 17.877392578124997],
                        [-78.04448242187499, 18.173828125],
                        [-78.339501953125, 18.28720703124999],
                        [-78.21669921875, 18.44809570312499],
                        [-77.8734375, 18.522216796875],
                        [-77.261474609375, 18.45742187499999],
                    ],
                ],
            },
            properties: {
                name: "牙买加",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-2.018652343749977, 49.23125],
                        [-2.23583984375, 49.1763671875],
                        [-2.220507812499989, 49.266357421875],
                        [-2.018652343749977, 49.23125],
                    ],
                ],
            },
            properties: {
                name: "泽西岛",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [39.14541015625002, 32.12451171875],
                        [38.9970703125, 32.007470703124994],
                        [38.96230468750002, 31.994921875],
                        [38.37548828125, 31.847460937499996],
                        [38.111425781250006, 31.781152343749994],
                        [37.49335937500001, 31.625878906249994],
                        [37.215625, 31.556103515624997],
                        [36.95859375, 31.491503906249996],
                        [37.980078125, 30.5],
                        [37.862890625, 30.442626953125],
                        [37.66972656250002, 30.34814453125],
                        [37.64990234375, 30.330957031249994],
                        [37.63359375000002, 30.31328125],
                        [37.55361328125002, 30.144580078124996],
                        [37.49072265625, 30.01171875],
                        [37.46923828125, 29.995068359374997],
                        [36.75527343750002, 29.866015625],
                        [36.70390625000002, 29.831640625],
                        [36.591796875, 29.66611328125],
                        [36.47607421875, 29.4951171875],
                        [36.2828125, 29.355371093749994],
                        [36.068457031250006, 29.200537109375],
                        [34.95078125, 29.353515625],
                        [34.97343750000002, 29.555029296875],
                        [35.45058593750002, 31.479296875],
                        [35.57207031250002, 32.237890625],
                        [35.55146484375001, 32.3955078125],
                        [35.56904296875001, 32.619873046875],
                        [35.572851562500006, 32.640869140625],
                        [35.78730468750001, 32.734912109374996],
                        [36.3720703125, 32.3869140625],
                        [36.818359375, 32.317285156249994],
                        [38.773535156250006, 33.372216796874994],
                        [39.04140625000002, 32.3056640625],
                        [39.24746093750002, 32.350976562499994],
                        [39.29277343750002, 32.24384765625],
                        [39.14541015625002, 32.12451171875],
                    ],
                ],
            },
            properties: {
                name: "约旦",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [123.88867187499997, 24.280126953124977],
                            [123.67978515625012, 24.317773437500023],
                            [123.77148437499997, 24.41445312499999],
                            [123.93486328125002, 24.362011718749983],
                            [123.88867187499997, 24.280126953124977],
                        ],
                    ],
                    [
                        [
                            [124.29316406250004, 24.515917968750074],
                            [124.13574218750003, 24.347607421874983],
                            [124.08476562500002, 24.435839843750017],
                            [124.30195312500004, 24.58710937500001],
                            [124.29316406250004, 24.515917968750074],
                        ],
                    ],
                    [
                        [
                            [125.44414062500002, 24.7431640625],
                            [125.26894531250005, 24.732519531250063],
                            [125.28359375, 24.871923828125034],
                            [125.44414062500002, 24.7431640625],
                        ],
                    ],
                    [
                        [
                            [128.25878906249997, 26.65278320312501],
                            [127.86708984375, 26.442480468749977],
                            [127.80361328125005, 26.152539062499983],
                            [127.653125, 26.0947265625],
                            [127.90722656250003, 26.69360351562497],
                            [128.09765624999997, 26.66777343749996],
                            [128.25488281249997, 26.88188476562496],
                            [128.25878906249997, 26.65278320312501],
                        ],
                    ],
                    [
                        [
                            [128.99814453125012, 27.720800781250006],
                            [128.90000000000012, 27.727783203125],
                            [128.9076171875, 27.897998046875045],
                            [128.99814453125012, 27.720800781250006],
                        ],
                    ],
                    [
                        [
                            [129.45253906250005, 28.20898437499997],
                            [129.3664062500001, 28.127734375000045],
                            [129.16464843750012, 28.24975585937503],
                            [129.68955078125012, 28.517480468750023],
                            [129.45253906250005, 28.20898437499997],
                        ],
                    ],
                    [
                        [
                            [130.6227539062501, 30.262988281250017],
                            [130.44560546875002, 30.264697265625017],
                            [130.38808593750005, 30.38818359375003],
                            [130.49716796875006, 30.465527343749983],
                            [130.64355468749997, 30.388964843750017],
                            [130.6227539062501, 30.262988281250017],
                        ],
                    ],
                    [
                        [
                            [130.95976562500007, 30.39692382812504],
                            [130.87031250000004, 30.444238281249994],
                            [131.06035156250007, 30.828466796875006],
                            [130.95976562500007, 30.39692382812504],
                        ],
                    ],
                    [
                        [
                            [130.38105468750004, 32.42373046875002],
                            [130.24169921874997, 32.462792968749994],
                            [130.46142578124997, 32.515722656250034],
                            [130.38105468750004, 32.42373046875002],
                        ],
                    ],
                    [
                        [
                            [130.08251953124997, 32.22968750000001],
                            [129.9601562500001, 32.24375],
                            [130.00976562499997, 32.521630859374994],
                            [130.16777343750002, 32.54121093749998],
                            [130.19951171875002, 32.34057617187506],
                            [130.08251953124997, 32.22968750000001],
                        ],
                    ],
                    [
                        [
                            [128.66533203125002, 32.783886718749955],
                            [128.89453124999997, 32.65214843750002],
                            [128.69296875000012, 32.60473632812506],
                            [128.66533203125002, 32.783886718749955],
                        ],
                    ],
                    [
                        [
                            [129.07695312500002, 32.84028320312498],
                            [128.99726562500004, 32.95185546874998],
                            [129.10976562500005, 33.13256835937503],
                            [129.18193359375002, 32.99311523437504],
                            [129.07695312500002, 32.84028320312498],
                        ],
                    ],
                    [
                        [
                            [129.49179687500006, 33.22304687499999],
                            [129.37041015625002, 33.176025390625],
                            [129.56992187500006, 33.36103515625004],
                            [129.49179687500006, 33.22304687499999],
                        ],
                    ],
                    [
                        [
                            [129.79570312500007, 33.74882812499999],
                            [129.67480468749997, 33.73969726562498],
                            [129.71728515624997, 33.8583984375],
                            [129.79570312500007, 33.74882812499999],
                        ],
                    ],
                    [
                        [
                            [131.17460937500007, 33.602587890625045],
                            [131.69628906250003, 33.60283203124999],
                            [131.53740234375007, 33.274072265624994],
                            [131.89658203125006, 33.25458984375001],
                            [131.8478515625001, 33.118066406249994],
                            [132.0021484375001, 32.882373046875045],
                            [131.6603515625001, 32.465625],
                            [131.33720703125007, 31.4046875],
                            [131.07080078124997, 31.436865234374977],
                            [131.09843750000002, 31.256152343750017],
                            [130.68574218750004, 31.01513671875003],
                            [130.77626953125, 31.70629882812497],
                            [130.65507812500002, 31.71840820312505],
                            [130.5560546875, 31.563085937500034],
                            [130.58876953125, 31.178515625000017],
                            [130.20068359374997, 31.291894531250023],
                            [130.14726562500002, 31.40849609374996],
                            [130.2941406250001, 31.45068359375003],
                            [130.3219726562501, 31.601464843750023],
                            [130.18789062500005, 31.768847656250017],
                            [130.19443359375012, 32.090771484374955],
                            [130.64052734375005, 32.61923828124998],
                            [130.49785156250002, 32.65693359375001],
                            [130.547265625, 32.83159179687499],
                            [130.2375, 33.177636718749966],
                            [130.12685546875005, 33.10483398437506],
                            [130.175, 32.851318359375],
                            [130.32646484375002, 32.852636718750006],
                            [130.34042968750012, 32.70185546875004],
                            [130.05410156250005, 32.770800781250045],
                            [129.76855468749997, 32.57099609375001],
                            [129.82675781250006, 32.72534179687503],
                            [129.67910156250005, 33.059960937499966],
                            [129.99169921875003, 32.85156249999997],
                            [129.58007812500003, 33.23627929687501],
                            [129.61015625000002, 33.34365234375005],
                            [129.844140625, 33.32177734375003],
                            [129.82568359374997, 33.43701171875006],
                            [130.36503906250007, 33.634472656249955],
                            [130.4837890625, 33.834619140624966],
                            [130.715625, 33.92778320312502],
                            [130.953125, 33.87202148437504],
                            [131.17460937500007, 33.602587890625045],
                        ],
                    ],
                    [
                        [
                            [132.266015625, 33.945166015625006],
                            [132.44492187500006, 33.91318359374998],
                            [132.20878906250007, 33.87285156250002],
                            [132.266015625, 33.945166015625006],
                        ],
                    ],
                    [
                        [
                            [129.27949218750004, 34.123388671875006],
                            [129.18642578125, 34.14501953125006],
                            [129.21484374999997, 34.320654296875034],
                            [129.3371093750001, 34.284765625],
                            [129.27949218750004, 34.123388671875006],
                        ],
                    ],
                    [
                        [
                            [134.35742187500003, 34.25634765625],
                            [134.6375, 34.22661132812499],
                            [134.73886718750012, 33.82050781250001],
                            [134.37705078125012, 33.60839843749997],
                            [134.18164062500003, 33.24721679687502],
                            [133.95869140625004, 33.44833984375006],
                            [133.63203125000004, 33.51098632812503],
                            [133.28593750000007, 33.35996093749998],
                            [132.97724609375004, 32.84199218749998],
                            [132.80429687500006, 32.75200195312502],
                            [132.6417968750001, 32.76245117187503],
                            [132.70898437500003, 32.90249023437505],
                            [132.49511718749997, 32.91660156249998],
                            [132.41279296875004, 33.43046875],
                            [132.0326171875, 33.339990234374994],
                            [132.64306640624997, 33.68994140624997],
                            [132.93515625000006, 34.09531250000006],
                            [133.19306640625004, 33.93320312499998],
                            [133.58203124999997, 34.01713867187502],
                            [133.60263671875006, 34.24384765625001],
                            [133.94833984375006, 34.34804687500002],
                            [134.35742187500003, 34.25634765625],
                        ],
                    ],
                    [
                        [
                            [134.35185546875002, 34.48364257812503],
                            [134.25185546875, 34.42304687500004],
                            [134.18212890625003, 34.51923828124998],
                            [134.35185546875002, 34.48364257812503],
                        ],
                    ],
                    [
                        [
                            [134.9328125000001, 34.28813476562499],
                            [134.82441406250004, 34.202929687500045],
                            [134.66787109375005, 34.294140624999955],
                            [135.00468750000002, 34.54404296874998],
                            [134.9328125000001, 34.28813476562499],
                        ],
                    ],
                    [
                        [
                            [129.38564453125, 34.35366210937502],
                            [129.26669921875012, 34.37045898437506],
                            [129.45107421875005, 34.68657226562499],
                            [129.38564453125, 34.35366210937502],
                        ],
                    ],
                    [
                        [
                            [133.37050781250005, 36.203857421875],
                            [133.23925781249997, 36.178759765625045],
                            [133.20615234375006, 36.293408203124955],
                            [133.29570312500002, 36.34013671874996],
                            [133.37050781250005, 36.203857421875],
                        ],
                    ],
                    [
                        [
                            [138.34404296875007, 37.822119140625006],
                            [138.22519531250006, 37.82939453124996],
                            [138.25, 38.078466796875006],
                            [138.50361328125004, 38.31591796875006],
                            [138.45361328124997, 38.07568359375006],
                            [138.57519531249997, 38.065527343750034],
                            [138.34404296875007, 37.822119140625006],
                        ],
                    ],
                    [
                        [
                            [141.22929687500007, 41.37265625],
                            [141.45546875000005, 41.404736328124955],
                            [141.43046875000002, 40.72333984374998],
                            [141.7970703125001, 40.29116210937502],
                            [141.97695312500005, 39.428808593750034],
                            [141.90078125, 39.111328125],
                            [141.5462890625, 38.762841796874966],
                            [141.4674804687501, 38.404150390625006],
                            [141.10839843750003, 38.33793945312502],
                            [140.9621093750001, 38.148876953124955],
                            [141.00166015625004, 37.11464843750002],
                            [140.57353515625007, 36.23134765625002],
                            [140.87402343749997, 35.72495117187506],
                            [140.457421875, 35.51025390625],
                            [140.35468750000004, 35.18144531249999],
                            [139.8439453125001, 34.914892578125034],
                            [139.82646484375002, 35.29667968750002],
                            [140.096875, 35.58515624999998],
                            [139.83476562500002, 35.658056640625006],
                            [139.65000000000012, 35.40913085937501],
                            [139.675, 35.149267578125006],
                            [139.47441406250002, 35.298535156249955],
                            [139.24941406250005, 35.27802734375004],
                            [139.08603515625006, 34.83916015624999],
                            [138.8375, 34.619238281250034],
                            [138.80273437499997, 34.97480468749998],
                            [138.90361328125002, 35.02524414062506],
                            [138.71962890625, 35.12407226562502],
                            [138.18906250000012, 34.596337890624994],
                            [137.543359375, 34.66420898437505],
                            [137.06171875000004, 34.58281249999999],
                            [137.27519531250002, 34.77250976562499],
                            [136.96328125000005, 34.83491210937501],
                            [136.87128906250004, 34.733105468749955],
                            [136.89707031250006, 35.03554687500002],
                            [136.80419921874997, 35.05029296875],
                            [136.53300781250007, 34.678369140624994],
                            [136.8802734375, 34.43359375000006],
                            [136.8537109375001, 34.324072265625034],
                            [136.32988281250007, 34.17685546875006],
                            [135.91621093750004, 33.561718749999955],
                            [135.69531250000003, 33.48696289062502],
                            [135.4528320312501, 33.55336914062505],
                            [135.12792968749997, 34.006982421874994],
                            [135.10009765624997, 34.288378906250045],
                            [135.41591796875, 34.61748046875002],
                            [134.74003906250007, 34.765234375],
                            [134.246875, 34.71386718750003],
                            [133.96826171874997, 34.52729492187504],
                            [133.14238281250002, 34.30244140624998],
                            [132.65654296875007, 34.24609375000003],
                            [132.31259765625006, 34.32495117187503],
                            [132.14648437499997, 33.83876953125002],
                            [131.74052734375007, 34.05205078125002],
                            [130.91884765625, 33.97573242187502],
                            [130.88925781250012, 34.261816406250034],
                            [131.00419921875007, 34.39257812500003],
                            [131.35439453125, 34.41318359375006],
                            [132.92294921875006, 35.511279296875045],
                            [133.98125, 35.50722656250002],
                            [135.17431640625003, 35.74707031250003],
                            [135.32695312500002, 35.52553710937502],
                            [135.68027343750006, 35.503125],
                            [135.903125, 35.60688476562498],
                            [136.09531250000006, 35.767626953125045],
                            [136.06748046875006, 36.11684570312505],
                            [136.69814453125005, 36.742041015625034],
                            [136.84345703125004, 37.38212890624999],
                            [137.32265625, 37.52207031249998],
                            [136.89990234375003, 37.11767578125],
                            [137.01669921875006, 36.83720703124999],
                            [137.24628906250004, 36.753173828125],
                            [137.5140625, 36.95156250000002],
                            [138.31992187500012, 37.21840820312502],
                            [138.88505859375007, 37.84394531250001],
                            [139.36386718750006, 38.09902343750002],
                            [139.80195312500004, 38.881591796875],
                            [140.06474609375002, 39.624414062499994],
                            [139.99472656250006, 39.855078125],
                            [139.74150390625002, 39.92084960937498],
                            [140.01113281250005, 40.26035156250006],
                            [139.92285156250003, 40.59843750000002],
                            [140.28125, 40.84609375000002],
                            [140.3444335937501, 41.203320312499955],
                            [140.62763671875004, 41.195410156250034],
                            [140.74863281250012, 40.830322265625],
                            [140.93603515625003, 40.940771484375034],
                            [141.1185546875, 40.88227539062501],
                            [141.24423828125006, 41.20561523437499],
                            [140.80058593750002, 41.138818359374966],
                            [140.80185546875012, 41.253662109375],
                            [140.9369140625, 41.50556640624998],
                            [141.22929687500007, 41.37265625],
                        ],
                    ],
                    [
                        [
                            [139.48125, 42.08100585937498],
                            [139.43134765625004, 42.19956054687498],
                            [139.55839843750002, 42.235205078125034],
                            [139.48125, 42.08100585937498],
                        ],
                    ],
                    [
                        [
                            [141.29541015625003, 45.11933593750001],
                            [141.14531250000002, 45.153906250000034],
                            [141.19375, 45.24785156249999],
                            [141.29541015625003, 45.11933593750001],
                        ],
                    ],
                    [
                        [
                            [141.07275390624997, 45.33286132812498],
                            [141.03398437500007, 45.26933593750002],
                            [140.97167968749997, 45.465478515624994],
                            [141.07275390624997, 45.33286132812498],
                        ],
                    ],
                    [
                        [
                            [143.82431640625012, 44.11699218749999],
                            [144.71523437500005, 43.92797851562503],
                            [145.36953125000005, 44.32739257812506],
                            [145.13964843750003, 43.6625],
                            [145.34082031249997, 43.30253906249999],
                            [145.83300781249997, 43.38593750000001],
                            [144.92138671874997, 43.00092773437498],
                            [143.96933593750006, 42.88139648437499],
                            [143.42949218750002, 42.41889648437498],
                            [143.2365234375001, 42.000195312499955],
                            [141.85136718750007, 42.57905273437501],
                            [141.40664062500005, 42.54692382812496],
                            [140.98613281250002, 42.34213867187498],
                            [140.70976562500002, 42.555615234374955],
                            [140.48046875000003, 42.559375],
                            [140.32666015625003, 42.29335937499999],
                            [141.15097656250012, 41.80507812499999],
                            [140.99951171874997, 41.73740234375006],
                            [140.65986328125004, 41.815576171874994],
                            [140.3849609375001, 41.51928710937503],
                            [140.08515625000004, 41.43408203125],
                            [139.99531250000004, 41.57641601562503],
                            [140.10839843749997, 41.912939453125034],
                            [139.83544921874997, 42.278076171875],
                            [139.86015625000002, 42.58173828125004],
                            [140.43222656250012, 42.95410156250006],
                            [140.39238281250002, 43.303125],
                            [141.13818359374997, 43.17993164062506],
                            [141.37412109375006, 43.279638671875006],
                            [141.7609375000001, 44.482519531250034],
                            [141.58300781250003, 45.15595703125001],
                            [141.66796874999997, 45.401269531249966],
                            [141.93769531250004, 45.509521484375],
                            [142.88476562499997, 44.670117187499955],
                            [143.82431640625012, 44.11699218749999],
                        ],
                    ],
                ],
            },
            properties: {
                name: "日本",
                childNum: 28,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [77.04863281249999, 35.109912109374996],
                        [76.927734375, 35.346630859375],
                        [76.88222656250002, 35.4357421875],
                        [76.81279296874999, 35.571826171874996],
                        [76.76689453124999, 35.66171875],
                        [76.87890625, 35.61328125],
                        [77.09003906250001, 35.552050781249996],
                        [77.29482421875002, 35.508154296875],
                        [77.44648437500001, 35.4755859375],
                        [77.57255859374999, 35.471826171874994],
                        [77.72402343750002, 35.48056640625],
                        [77.79941406250003, 35.495898437499996],
                        [77.42343750000003, 35.302587890625],
                        [77.16855468750003, 35.171533203124994],
                        [77.04863281249999, 35.109912109374996],
                    ],
                ],
            },
            properties: {
                name: "Siachen Glacier",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [50.184472656249994, 44.854638671874994],
                            [49.99511718750003, 44.93696289062498],
                            [50.10986328124997, 45.08193359375002],
                            [50.038867187500074, 44.949121093749966],
                            [50.184472656249994, 44.854638671874994],
                        ],
                    ],
                    [
                        [
                            [87.32285156250012, 49.085791015625006],
                            [86.8083007812501, 49.04970703125002],
                            [86.54941406250012, 48.52861328125002],
                            [85.7494140625, 48.38505859374999],
                            [85.52597656250006, 47.915625],
                            [85.65664062500005, 47.254638671875],
                            [85.484765625, 47.06352539062496],
                            [84.78613281249997, 46.83071289062505],
                            [84.66660156250006, 46.97236328125004],
                            [84.016015625, 46.97050781250002],
                            [83.02949218750004, 47.18593750000002],
                            [82.31523437500002, 45.59492187499998],
                            [82.61162109375007, 45.424267578124955],
                            [82.52148437500003, 45.12548828125],
                            [82.26660156249997, 45.21909179687498],
                            [81.94492187500006, 45.16083984375001],
                            [81.69199218750012, 45.34936523437497],
                            [80.05917968750012, 45.006445312500006],
                            [79.871875, 44.88378906249997],
                            [80.48154296875006, 44.71464843749999],
                            [80.35527343750002, 44.09726562500006],
                            [80.78574218750006, 43.16157226562504],
                            [80.39023437500006, 43.043115234374966],
                            [80.53896484375005, 42.873486328124955],
                            [80.20224609375012, 42.73447265624998],
                            [80.209375, 42.190039062500006],
                            [80.07128906249997, 42.302978515625],
                            [79.92109375000004, 42.41313476562496],
                            [79.49013671875, 42.45756835937496],
                            [79.42822265624997, 42.483496093750006],
                            [79.20302734375005, 42.66601562499997],
                            [79.16484375000007, 42.759033203125],
                            [79.1266601562501, 42.775732421875034],
                            [76.98808593750007, 42.97358398437501],
                            [76.64648437500003, 42.928808593750034],
                            [76.50917968750005, 42.91889648437498],
                            [75.9322265625, 42.92851562499999],
                            [75.84033203125003, 42.9375],
                            [75.78955078124997, 42.93291015624999],
                            [75.68173828125, 42.83046875],
                            [75.04765625000007, 42.904394531250034],
                            [74.20908203125006, 43.24038085937502],
                            [73.88603515625002, 43.132568359375],
                            [73.55625, 43.002783203125006],
                            [73.45019531249997, 42.703027343749966],
                            [73.421875, 42.59350585937503],
                            [73.49296875000007, 42.409033203125034],
                            [73.41162109375003, 42.41977539062498],
                            [73.316015625, 42.46699218750001],
                            [73.2829101562501, 42.50410156250004],
                            [72.85507812500006, 42.561132812500006],
                            [72.75292968750003, 42.63789062500001],
                            [72.54316406250004, 42.67773437500006],
                            [72.27578125, 42.757666015625006],
                            [71.76054687500002, 42.82148437500004],
                            [71.5142578125, 42.766943359375006],
                            [71.42207031250004, 42.78315429687504],
                            [71.25664062500002, 42.733544921874966],
                            [70.89287109375007, 42.339990234374994],
                            [70.94677734374997, 42.24868164062505],
                            [69.15361328125002, 41.42524414062498],
                            [68.58408203125, 40.876269531250045],
                            [68.57265625, 40.62265624999998],
                            [68.29189453125, 40.656103515625034],
                            [68.04765625000007, 40.80927734374998],
                            [68.11308593750007, 41.02861328124999],
                            [67.9357421875001, 41.19658203125002],
                            [66.70966796875004, 41.17915039062501],
                            [66.49863281250006, 41.99487304687503],
                            [66.00957031250007, 42.00488281250003],
                            [66.1002929687501, 42.99082031249998],
                            [65.80302734375002, 42.87695312500006],
                            [65.49619140625, 43.310546875],
                            [64.9054687500001, 43.714697265625006],
                            [64.44316406250007, 43.55117187499999],
                            [63.20703125000003, 43.62797851562502],
                            [61.99023437500003, 43.492138671874955],
                            [61.007910156250006, 44.39379882812497],
                            [58.555273437500006, 45.55537109375001],
                            [55.97568359375006, 44.99492187499996],
                            [55.97744140625005, 41.32221679687504],
                            [55.434375, 41.296289062499994],
                            [54.85380859375002, 41.965185546875006],
                            [54.120996093749994, 42.335205078125],
                            [53.0558593750001, 42.14775390624999],
                            [52.4938476562501, 41.780371093750034],
                            [52.59658203125005, 42.760156249999966],
                            [51.898242187500074, 42.86962890624997],
                            [51.61601562500002, 43.15844726562503],
                            [51.29541015624997, 43.17412109375002],
                            [51.30175781249997, 43.48237304687501],
                            [50.8307617187501, 44.192773437499966],
                            [50.331152343750006, 44.32548828125002],
                            [50.25292968749997, 44.461523437500006],
                            [50.409472656250074, 44.6240234375],
                            [51.543554687500006, 44.53100585937506],
                            [51.009375, 44.92182617187501],
                            [51.4157226562501, 45.35786132812501],
                            [53.20039062500004, 45.33198242187498],
                            [52.77382812499999, 45.57275390625],
                            [53.13525390625003, 46.19165039062497],
                            [53.069433593750006, 46.85605468750006],
                            [52.48320312500002, 46.99067382812504],
                            [52.13828125, 46.82861328124997],
                            [51.178027343750074, 47.110156250000045],
                            [49.886328125, 46.59565429687504],
                            [49.347460937500074, 46.51914062499998],
                            [49.232226562500074, 46.33715820312503],
                            [48.54121093750004, 46.60561523437502],
                            [48.558398437500074, 46.75712890624999],
                            [48.959375, 46.77460937499998],
                            [48.16699218750003, 47.70878906249996],
                            [47.48193359374997, 47.80390624999998],
                            [47.292382812499994, 47.74091796875004],
                            [47.06464843750004, 48.23247070312499],
                            [46.660937500000074, 48.41225585937502],
                            [46.70263671875003, 48.80556640625002],
                            [47.031347656250006, 49.150292968749994],
                            [46.80205078125002, 49.36708984375002],
                            [46.889550781249994, 49.69697265625001],
                            [47.42919921874997, 50.35795898437502],
                            [47.7057617187501, 50.37797851562502],
                            [48.33496093750003, 49.858251953125006],
                            [48.7589843750001, 49.92832031250006],
                            [48.625097656250006, 50.61269531250005],
                            [49.32343750000004, 50.851708984374966],
                            [49.49804687500003, 51.08359375000006],
                            [50.246875, 51.28950195312498],
                            [50.79394531249997, 51.729199218749955],
                            [51.16347656250005, 51.6474609375],
                            [51.344531250000074, 51.47534179687503],
                            [52.21914062499999, 51.709375],
                            [52.57119140625005, 51.481640624999955],
                            [53.33808593750004, 51.48237304687504],
                            [54.139746093750006, 51.04077148437503],
                            [54.555273437500006, 50.535791015624994],
                            [54.64160156250003, 51.011572265625034],
                            [55.68623046875004, 50.582861328125006],
                            [56.49140625000004, 51.01953124999997],
                            [57.01171874999997, 51.06518554687503],
                            [57.44218750000002, 50.88886718749998],
                            [57.83886718750003, 51.091650390625006],
                            [58.359179687500074, 51.063818359375034],
                            [58.88369140625005, 50.694433593750006],
                            [59.4523437500001, 50.62041015625002],
                            [59.523046875, 50.492871093749955],
                            [59.812402343749994, 50.58203125],
                            [60.05859374999997, 50.850292968749955],
                            [60.42480468749997, 50.67915039062498],
                            [60.94228515625005, 50.69550781250004],
                            [61.38945312500002, 50.86103515625001],
                            [61.55468750000003, 51.32460937500005],
                            [60.464746093749994, 51.651171875000045],
                            [60.03027343749997, 51.93325195312505],
                            [60.99453125000005, 52.33686523437504],
                            [60.77441406249997, 52.67578124999997],
                            [61.047460937500006, 52.97246093750002],
                            [62.08271484375004, 53.00541992187499],
                            [61.65986328125004, 53.22846679687504],
                            [61.19921874999997, 53.28715820312502],
                            [61.22890625, 53.445898437500006],
                            [61.53496093750002, 53.52329101562506],
                            [60.97949218749997, 53.62172851562505],
                            [61.231054687500006, 54.01948242187498],
                            [61.92871093750003, 53.94648437500004],
                            [64.46123046875002, 54.38417968750002],
                            [65.08837890624997, 54.340185546875034],
                            [65.476953125, 54.62329101562497],
                            [68.15585937500006, 54.97670898437505],
                            [68.20625, 55.16093750000002],
                            [68.9772460937501, 55.389599609374955],
                            [70.18242187500002, 55.162451171875034],
                            [70.73808593750007, 55.30517578125],
                            [71.18554687500003, 54.59931640624998],
                            [71.09316406250005, 54.21220703124999],
                            [72.00449218750006, 54.20566406249998],
                            [72.18603515625003, 54.32563476562501],
                            [72.44677734375003, 53.94184570312498],
                            [72.62226562500004, 54.13432617187502],
                            [73.22988281250005, 53.957812500000045],
                            [73.71240234375003, 54.04238281250002],
                            [73.30566406250003, 53.707226562499955],
                            [73.40693359375004, 53.44755859374999],
                            [73.85898437500006, 53.61972656249998],
                            [74.35156250000003, 53.487646484375006],
                            [74.45195312500007, 53.64726562500002],
                            [75.22021484374997, 53.89379882812506],
                            [75.43720703125004, 54.08964843749999],
                            [76.8373046875, 54.4423828125],
                            [76.65458984375007, 54.14526367187503],
                            [76.42167968750007, 54.151513671874966],
                            [76.48476562500005, 54.02255859374998],
                            [77.85996093750006, 53.269189453124994],
                            [79.98623046875, 50.774560546874966],
                            [80.42363281250002, 50.94628906249997],
                            [80.44804687500002, 51.18334960937503],
                            [80.73525390625, 51.29340820312498],
                            [81.12724609375002, 51.19106445312502],
                            [81.0714843750001, 50.96875],
                            [81.38828125000006, 50.95649414062501],
                            [81.46591796875006, 50.73984375],
                            [82.49394531250007, 50.72758789062499],
                            [82.76083984375012, 50.89335937500002],
                            [83.35732421875005, 50.99458007812504],
                            [83.94511718750007, 50.774658203125],
                            [84.32324218749997, 50.239160156249966],
                            [84.9894531250001, 50.061425781249994],
                            [85.2326171875001, 49.61582031249998],
                            [86.1808593750001, 49.49931640624996],
                            [86.67548828125004, 49.77729492187501],
                            [86.62646484374997, 49.56269531250001],
                            [87.32285156250012, 49.085791015625006],
                        ],
                    ],
                ],
            },
            properties: {
                name: "哈萨克斯坦",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [35.325292968750006, 5.364892578124994],
                        [35.745019531249994, 5.343994140625],
                        [35.80029296874997, 5.156933593749983],
                        [35.77929687499997, 5.105566406250006],
                        [35.756152343750074, 4.950488281250031],
                        [35.76308593750005, 4.808007812500051],
                        [36.02197265625003, 4.468115234374991],
                        [36.90556640625002, 4.411474609374991],
                        [37.15458984375002, 4.254541015624994],
                        [37.944921875, 3.746728515625023],
                        [38.0861328125001, 3.648828124999966],
                        [38.22529296875004, 3.61899414062502],
                        [38.45156250000005, 3.604833984374977],
                        [38.608007812500006, 3.600097656249986],
                        [39.49443359375002, 3.45610351562496],
                        [39.65751953125002, 3.577832031249983],
                        [39.79033203125002, 3.754248046875034],
                        [39.8421875, 3.851464843750037],
                        [40.765234375, 4.273046875000034],
                        [41.02080078125002, 4.057470703124991],
                        [41.22089843750004, 3.943554687499969],
                        [41.372460937499994, 3.94619140624998],
                        [41.48193359375003, 3.96328125],
                        [41.737695312499994, 3.979052734375003],
                        [41.88398437500004, 3.977734375000011],
                        [41.6134765625001, 3.59047851562498],
                        [41.34179687499997, 3.20166015625],
                        [40.964453125, 2.814648437500026],
                        [40.9787109375001, -0.870312500000011],
                        [41.249804687500074, -1.220507812499946],
                        [41.4269531250001, -1.449511718749974],
                        [41.521875, -1.572265625000028],
                        [41.53271484374997, -1.695312499999957],
                        [41.26748046875005, -1.945019531250026],
                        [40.889746093750006, -2.023535156250034],
                        [40.89824218750002, -2.269921874999966],
                        [40.64414062500006, -2.53945312499998],
                        [40.22246093750002, -2.688378906250037],
                        [40.1154296875001, -3.250585937499991],
                        [39.8609375, -3.576757812500006],
                        [39.49091796875004, -4.478417968750023],
                        [39.221777343750006, -4.692382812500014],
                        [37.608203125000074, -3.497070312500028],
                        [37.643847656250074, -3.045410156250028],
                        [33.90322265625005, -1.002050781250034],
                        [33.94316406250002, 0.173779296874969],
                        [34.160937500000074, 0.605175781250026],
                        [34.4108398437501, 0.867285156250034],
                        [34.48173828125002, 1.042138671875051],
                        [34.79863281250002, 1.24453125],
                        [34.976464843749994, 1.719628906250051],
                        [34.97753906249997, 1.861914062499991],
                        [34.9640625000001, 2.06240234374998],
                        [34.8830078125001, 2.417919921875026],
                        [34.90576171875003, 2.4796875],
                        [34.44785156250006, 3.163476562500037],
                        [34.40722656249997, 3.357519531250034],
                        [34.39941406249997, 3.412695312500006],
                        [34.44179687499999, 3.60625],
                        [34.43769531250004, 3.650585937499969],
                        [34.392871093750074, 3.691503906250048],
                        [34.26708984375003, 3.733154296875],
                        [34.16503906250003, 3.812988281250014],
                        [34.18574218750004, 3.869775390625037],
                        [34.13203125000004, 3.889160156249986],
                        [33.97607421874997, 4.220214843750028],
                        [34.176855468750006, 4.419091796875037],
                        [34.38017578125002, 4.620654296874974],
                        [34.6398437500001, 4.875488281250028],
                        [34.878320312499994, 5.109570312500026],
                        [35.08447265624997, 5.31186523437502],
                        [35.268359375000074, 5.492285156250006],
                        [35.325292968750006, 5.364892578124994],
                    ],
                ],
            },
            properties: {
                name: "肯尼亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [72.63994140625002, 39.385986328125],
                        [72.22998046875, 39.20751953125],
                        [72.14736328125002, 39.2607421875],
                        [72.08417968750001, 39.31064453125],
                        [72.04277343750002, 39.3521484375],
                        [71.77861328125002, 39.277978515624994],
                        [71.73222656250002, 39.422998046874994],
                        [71.50332031250002, 39.478808593749996],
                        [71.51738281250002, 39.553857421874994],
                        [71.50302734375, 39.582177734374994],
                        [71.4703125, 39.603662109374994],
                        [70.79931640625, 39.3947265625],
                        [70.50117187500001, 39.587353515625],
                        [69.29765625000002, 39.524804687499994],
                        [69.2447265625, 39.827099609375],
                        [69.27880859375, 39.917773437499996],
                        [69.3072265625, 39.968554687499996],
                        [69.36542968750001, 39.947070312499996],
                        [69.43193359375002, 39.909765625],
                        [69.47626953125001, 39.919726562499996],
                        [69.47099609375002, 39.990625],
                        [69.46875, 40.020751953125],
                        [69.966796875, 40.20224609375],
                        [70.59921875, 39.974511718749994],
                        [70.990625, 40.2548828125],
                        [71.3046875, 40.286914062499996],
                        [71.69248046875, 40.15234375],
                        [72.13125, 40.438623046874994],
                        [72.3892578125, 40.427392578124994],
                        [72.40205078125001, 40.578076171875],
                        [72.6041015625, 40.525439453124996],
                        [73.13212890625002, 40.82851562499999],
                        [72.65830078125, 40.869921875],
                        [72.36406250000002, 41.04345703125],
                        [72.294921875, 41.039941406249994],
                        [72.21308593750001, 41.0142578125],
                        [72.18730468750002, 41.025927734374996],
                        [72.18095703125002, 41.118457031249996],
                        [72.16425781250001, 41.173730468749994],
                        [72.11542968750001, 41.186572265624996],
                        [72.05244140625001, 41.16474609375],
                        [71.95849609375, 41.187060546874996],
                        [71.87861328125001, 41.19501953125],
                        [71.8580078125, 41.311376953125],
                        [71.79248046875, 41.413134765624996],
                        [71.75771484375002, 41.428027343749996],
                        [71.70068359375, 41.454003906249994],
                        [71.66494140625002, 41.5412109375],
                        [71.6375, 41.5341796875],
                        [71.60224609375001, 41.503271484375],
                        [71.60625, 41.367431640625],
                        [71.54560546875001, 41.308056640625],
                        [71.5, 41.307470703125],
                        [71.4208984375, 41.34189453125],
                        [71.40839843750001, 41.136035156249996],
                        [71.39306640625, 41.123388671875],
                        [71.11074218750002, 41.152636718749996],
                        [70.86044921875, 41.224902343749996],
                        [70.734375, 41.400537109374994],
                        [70.18095703125002, 41.571435546874994],
                        [70.85664062500001, 42.030810546874996],
                        [71.0322265625, 42.077783203124994],
                        [71.228515625, 42.162890625],
                        [71.23232421875002, 42.186279296875],
                        [71.21269531250002, 42.206445312499994],
                        [71.12998046875, 42.25],
                        [71.03603515625002, 42.28466796875],
                        [70.97900390625, 42.266552734375],
                        [70.94677734375, 42.248681640624994],
                        [70.89287109375002, 42.339990234374994],
                        [71.25664062500002, 42.733544921874994],
                        [71.42207031250001, 42.783154296875],
                        [71.5142578125, 42.766943359375],
                        [71.76054687500002, 42.821484375],
                        [72.16181640625001, 42.760693359375],
                        [72.27578125000002, 42.757666015625],
                        [72.54316406250001, 42.677734375],
                        [72.7529296875, 42.637890625],
                        [72.855078125, 42.5611328125],
                        [73.28291015625001, 42.5041015625],
                        [73.316015625, 42.4669921875],
                        [73.41162109375, 42.419775390625],
                        [73.49296875000002, 42.409033203125],
                        [73.421875, 42.593505859375],
                        [73.4501953125, 42.703027343749994],
                        [73.55625, 43.002783203125],
                        [73.88603515625002, 43.132568359375],
                        [74.20908203125, 43.240380859374994],
                        [75.04765625000002, 42.90439453125],
                        [75.68173828125, 42.83046875],
                        [75.78955078125, 42.932910156249996],
                        [75.84033203125, 42.9375],
                        [75.9322265625, 42.928515625],
                        [76.50917968750002, 42.918896484375],
                        [76.646484375, 42.92880859375],
                        [76.98808593749999, 42.973583984375],
                        [79.12666015625001, 42.775732421875],
                        [79.20302734375002, 42.666015625],
                        [79.29550781250003, 42.604833984375],
                        [79.36777343750003, 42.547216796875],
                        [79.42822265625, 42.48349609375],
                        [79.92109375000001, 42.413134765624996],
                        [80.0712890625, 42.302978515625],
                        [80.209375, 42.1900390625],
                        [80.24619140625003, 42.059814453125],
                        [80.23515624999999, 42.04345703125],
                        [80.21621093750002, 42.032421875],
                        [79.90966796875, 42.014990234375],
                        [79.84042968750003, 41.995751953124994],
                        [79.76611328125, 41.898876953125],
                        [78.74257812500002, 41.56005859375],
                        [78.54316406250001, 41.4595703125],
                        [78.44287109375, 41.417529296874996],
                        [78.36240234375003, 41.371630859374996],
                        [78.34628906250003, 41.2814453125],
                        [78.12343750000002, 41.075634765625],
                        [77.95644531250002, 41.050683593749994],
                        [77.81523437499999, 41.055615234375],
                        [77.71933593750003, 41.024316406249994],
                        [77.58173828125001, 40.9927734375],
                        [76.98662109374999, 41.03916015625],
                        [76.90771484375, 41.024169921875],
                        [76.82402343749999, 40.982324218749994],
                        [76.70839843750002, 40.818115234375],
                        [76.6611328125, 40.779638671875],
                        [76.63984375000001, 40.742236328124996],
                        [76.62216796875003, 40.662353515625],
                        [76.57792968749999, 40.577880859375],
                        [76.48017578125001, 40.449511718749996],
                        [76.39638671875002, 40.389794921874994],
                        [76.31855468750001, 40.35224609375],
                        [76.25830078125, 40.43076171875],
                        [75.87197265625002, 40.30322265625],
                        [75.67714843750002, 40.305810546874994],
                        [75.55556640625002, 40.6251953125],
                        [75.52080078125002, 40.6275390625],
                        [75.24101562500002, 40.480273437499996],
                        [75.111328125, 40.4541015625],
                        [75.0044921875, 40.449511718749996],
                        [74.865625, 40.493505859375],
                        [74.80126953125, 40.428515625],
                        [74.83046875000002, 40.32851562499999],
                        [74.41191406250002, 40.13720703125],
                        [74.24267578125, 40.092041015625],
                        [74.08515625000001, 40.07431640625],
                        [73.99160156250002, 40.043115234374994],
                        [73.93876953125002, 39.978808593749996],
                        [73.88457031250002, 39.8779296875],
                        [73.85625, 39.828662109374996],
                        [73.83535156250002, 39.800146484375],
                        [73.83974609375002, 39.762841796874994],
                        [73.88251953125001, 39.71455078125],
                        [73.9146484375, 39.606494140624996],
                        [73.90712890625002, 39.57851562499999],
                        [73.87275390625001, 39.53330078125],
                        [73.82294921875001, 39.48896484375],
                        [73.71572265625002, 39.462255859375],
                        [73.63164062500002, 39.448876953124994],
                        [73.47041015625001, 39.460595703124994],
                        [73.38740234375001, 39.442724609375],
                        [73.33613281250001, 39.412353515625],
                        [73.2349609375, 39.374560546874996],
                        [73.10927734375002, 39.3619140625],
                        [72.63994140625002, 39.385986328125],
                    ],
                    [
                        [70.66416015625, 39.85546875],
                        [70.56708984375001, 39.866601562499994],
                        [70.49775390625001, 39.882421875],
                        [70.48281250000002, 39.882714843749994],
                        [70.4892578125, 39.863037109375],
                        [70.5595703125, 39.790917968749994],
                        [70.61210937500002, 39.786767578124994],
                        [70.70166015625, 39.82529296875],
                        [70.66416015625, 39.85546875],
                    ],
                    [
                        [71.20615234375, 39.892578125],
                        [71.22871093750001, 40.048144531249996],
                        [71.08037109375002, 40.079882812499996],
                        [71.02412109375001, 40.149169921875],
                        [71.00546875, 40.152294921875],
                        [70.96064453125001, 40.08798828125],
                        [71.04482421875002, 39.992529296875],
                        [71.04365234375001, 39.976318359375],
                        [71.01171875, 39.8951171875],
                        [71.06425781250002, 39.884912109374994],
                        [71.15625, 39.883447265624994],
                        [71.20615234375, 39.892578125],
                    ],
                ],
            },
            properties: {
                name: "吉尔吉斯斯坦",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [104.42636718750006, 10.411230468749991],
                        [103.87050781250005, 10.655126953125034],
                        [103.58710937500004, 10.552197265625026],
                        [103.54042968750005, 10.668701171875043],
                        [103.721875, 10.890136718750043],
                        [103.5324218750001, 11.146679687499997],
                        [103.35361328125006, 10.921582031250054],
                        [103.15283203124997, 10.913720703125051],
                        [103.12548828124997, 11.460644531250011],
                        [102.9486328125, 11.773486328124974],
                        [102.93388671875002, 11.706689453125037],
                        [102.73662109375007, 12.089794921875011],
                        [102.75566406250002, 12.42626953125],
                        [102.49960937500012, 12.669970703125003],
                        [102.33632812500005, 13.560302734375014],
                        [102.546875, 13.585693359375043],
                        [102.90927734375006, 14.136718750000028],
                        [103.19941406250004, 14.332617187499977],
                        [104.77900390625004, 14.427832031250006],
                        [105.07412109375005, 14.227441406250037],
                        [105.12597656250003, 14.280957031250011],
                        [105.16914062500004, 14.336083984374966],
                        [105.1833007812501, 14.346240234374989],
                        [105.18554687500003, 14.319091796874972],
                        [105.20703125000003, 14.259375],
                        [105.24570312500006, 14.200537109374977],
                        [105.35019531250006, 14.109570312500011],
                        [105.53154296875007, 14.156152343749994],
                        [105.73974609375003, 14.084960937500057],
                        [105.83144531250005, 13.976611328125003],
                        [105.9044921875001, 13.924511718750054],
                        [106.06679687500005, 13.921191406250003],
                        [106.12470703125004, 14.049121093750031],
                        [106.09667968749997, 14.127099609375023],
                        [106.00410156250004, 14.262890624999983],
                        [105.97890625, 14.343017578125043],
                        [106.00839843750012, 14.357177734375],
                        [106.1652343750001, 14.372363281249989],
                        [106.19072265625007, 14.388134765624997],
                        [106.22539062500002, 14.476220703125009],
                        [106.26796875, 14.466210937500009],
                        [106.35498046875003, 14.454785156249997],
                        [106.44697265625004, 14.515039062500009],
                        [106.50146484375003, 14.578222656250006],
                        [106.53115234375005, 14.549414062499991],
                        [106.5636718750001, 14.505078125000026],
                        [106.59921875000006, 14.479394531250037],
                        [106.66542968750005, 14.441308593749994],
                        [106.73818359375005, 14.387744140625017],
                        [106.78349609375002, 14.335107421875037],
                        [106.81992187500006, 14.314697265625057],
                        [106.91318359375006, 14.329394531250031],
                        [106.93808593750006, 14.327343750000054],
                        [106.99218750000003, 14.391015624999966],
                        [107.03017578125, 14.425683593750009],
                        [107.06240234375, 14.415771484375043],
                        [107.109375, 14.416699218750054],
                        [107.29267578125004, 14.592382812500048],
                        [107.37988281250003, 14.555322265625051],
                        [107.41474609375004, 14.56289062499999],
                        [107.51943359375005, 14.705078125],
                        [107.3314453125, 14.126611328125009],
                        [107.60546874999997, 13.437792968750017],
                        [107.47539062500002, 13.030371093749963],
                        [107.50644531250006, 12.364550781250031],
                        [107.39335937500002, 12.260498046874972],
                        [107.21210937500004, 12.30400390624996],
                        [106.70009765625, 11.979296874999974],
                        [106.41386718750002, 11.9484375],
                        [106.39921875000007, 11.687011718750028],
                        [106.0060546875001, 11.758007812500011],
                        [105.85146484375005, 11.635009765625],
                        [105.85605468750006, 11.294287109375048],
                        [106.16093750000002, 11.037109375000057],
                        [106.16396484375005, 10.794921875],
                        [105.85332031250007, 10.86357421874996],
                        [105.75507812500004, 10.989990234375043],
                        [105.40576171875003, 10.95161132812504],
                        [105.3146484375001, 10.845166015625026],
                        [105.04570312500002, 10.911376953125014],
                        [105.04638671874997, 10.701660156250014],
                        [104.85058593749997, 10.534472656249974],
                        [104.42636718750006, 10.411230468749991],
                    ],
                ],
            },
            properties: {
                name: "柬埔寨",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-157.34213867187503, 1.855566406250034],
                            [-157.17578125, 1.73984375],
                            [-157.57895507812498, 1.902050781249997],
                            [-157.43583984374993, 1.84726562500002],
                            [-157.365185546875, 1.94609375],
                            [-157.44189453125003, 2.025048828125009],
                            [-157.321875, 1.968554687500045],
                            [-157.34213867187503, 1.855566406250034],
                        ],
                    ],
                    [
                        [
                            [-159.3390625, 3.923535156249983],
                            [-159.27475585937503, 3.796582031250054],
                            [-159.40903320312503, 3.87324218750004],
                            [-159.3390625, 3.923535156249983],
                        ],
                    ],
                ],
            },
            properties: {
                name: "基里巴斯",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [126.32695312500002, 33.2236328125],
                            [126.16562500000012, 33.31201171875],
                            [126.33769531250002, 33.46040039062501],
                            [126.90117187500002, 33.51513671874997],
                            [126.87285156250002, 33.34116210937498],
                            [126.32695312500002, 33.2236328125],
                        ],
                    ],
                    [
                        [
                            [126.23369140625002, 34.370507812499994],
                            [126.12285156250002, 34.443945312500034],
                            [126.34384765625012, 34.544921875],
                            [126.23369140625002, 34.370507812499994],
                        ],
                    ],
                    [
                        [
                            [126.17197265625006, 34.73115234375001],
                            [126.00751953125004, 34.86748046874999],
                            [126.07841796875002, 34.914843750000045],
                            [126.17197265625006, 34.73115234375001],
                        ],
                    ],
                    [
                        [
                            [128.0658203125, 34.80585937500004],
                            [128.05468750000003, 34.70805664062502],
                            [127.87343750000005, 34.73496093749998],
                            [127.8322265625001, 34.87451171875],
                            [128.0658203125, 34.80585937500004],
                        ],
                    ],
                    [
                        [
                            [128.74101562500007, 34.798535156249955],
                            [128.64667968750004, 34.73686523437502],
                            [128.48925781250003, 34.86528320312496],
                            [128.66796875000003, 35.0087890625],
                            [128.74101562500007, 34.798535156249955],
                        ],
                    ],
                    [
                        [
                            [126.52070312500004, 37.73681640625003],
                            [126.516015625, 37.60468750000001],
                            [126.42333984375003, 37.62363281250006],
                            [126.41162109374997, 37.82265625000002],
                            [126.52070312500004, 37.73681640625003],
                        ],
                    ],
                    [
                        [
                            [128.37460937500012, 38.6234375],
                            [129.41826171875002, 37.059033203124955],
                            [129.40351562500004, 36.052148437499994],
                            [129.57285156250006, 36.05053710937503],
                            [129.4191406250001, 35.49785156249996],
                            [129.07675781250006, 35.12270507812502],
                            [128.5109375000001, 35.10097656250002],
                            [128.44394531250012, 34.87036132812503],
                            [128.03623046875006, 35.02197265625],
                            [127.71484374999997, 34.95468749999998],
                            [127.71542968750012, 34.72104492187498],
                            [127.40429687499997, 34.823095703125006],
                            [127.47910156250012, 34.625244140625],
                            [127.324609375, 34.463281249999966],
                            [127.17343750000006, 34.54614257812497],
                            [127.24707031249997, 34.755126953125],
                            [126.89746093749997, 34.438867187499966],
                            [126.75478515625005, 34.511865234374994],
                            [126.53144531250004, 34.31425781249999],
                            [126.26445312500002, 34.67324218750002],
                            [126.52451171875006, 34.697900390624966],
                            [126.59335937500012, 34.824365234374994],
                            [126.42070312500002, 34.823388671874966],
                            [126.29111328125012, 35.154150390625034],
                            [126.61406250000007, 35.57099609375004],
                            [126.4884765625001, 35.647070312500006],
                            [126.75302734375006, 35.871972656249994],
                            [126.5404296875, 36.166162109374966],
                            [126.4876953125, 36.69379882812498],
                            [126.18085937500004, 36.69160156249998],
                            [126.16054687500005, 36.77192382812501],
                            [126.48701171875004, 37.00747070312502],
                            [126.78447265625007, 36.94843749999998],
                            [126.87207031249997, 36.82446289062506],
                            [126.97685546875002, 36.93940429687501],
                            [126.74638671875002, 37.19355468750001],
                            [126.63388671875012, 37.78183593750006],
                            [127.09033203125003, 38.28388671875001],
                            [128.03896484375, 38.30854492187498],
                            [128.37460937500012, 38.6234375],
                        ],
                    ],
                ],
            },
            properties: {
                name: "韩国",
                childNum: 7,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [48.27539062499997, 29.624316406250017],
                            [48.17968750000003, 29.611425781250063],
                            [48.081445312499994, 29.798925781250063],
                            [48.1847656250001, 29.978857421875034],
                            [48.348242187500006, 29.78266601562504],
                            [48.27539062499997, 29.624316406250017],
                        ],
                    ],
                    [
                        [
                            [48.442480468750006, 28.542919921874983],
                            [47.671289062499994, 28.53315429687504],
                            [47.433203125, 28.989550781250017],
                            [46.53144531250004, 29.09624023437499],
                            [46.69375, 29.259667968749966],
                            [46.76933593750002, 29.347460937500017],
                            [46.90585937500006, 29.5375],
                            [47.14824218750002, 30.0009765625],
                            [47.64375, 30.097314453125023],
                            [47.75390624999997, 30.076611328124955],
                            [47.97871093750004, 29.98281250000005],
                            [48.00566406250002, 29.835791015625034],
                            [48.143457031249994, 29.57246093750001],
                            [47.96962890625005, 29.61669921874997],
                            [47.72265624999997, 29.393017578124955],
                            [48.0514648437501, 29.355371093750023],
                            [48.442480468750006, 28.542919921874983],
                        ],
                    ],
                ],
            },
            properties: {
                name: "科威特",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [102.12744140625011, 22.37919921874999],
                        [102.58251953125006, 21.904296875000057],
                        [102.66201171875008, 21.676025390625057],
                        [102.73857421875005, 21.677929687500125],
                        [102.77109375000015, 21.70966796875001],
                        [102.79824218750014, 21.797949218750034],
                        [102.81591796875, 21.807373046875],
                        [102.94960937500008, 21.681347656250068],
                        [102.85117187500009, 21.26591796874999],
                        [102.8837890625, 21.202587890625068],
                        [103.1044921875, 20.89165039062499],
                        [103.21074218749999, 20.840625],
                        [103.46357421874995, 20.779833984375102],
                        [103.6350585937501, 20.697070312500102],
                        [104.10136718750005, 20.945507812500125],
                        [104.1953125, 20.91396484375008],
                        [104.349609375, 20.82109374999999],
                        [104.58320312500001, 20.646679687499955],
                        [104.53271484375, 20.554882812500125],
                        [104.47861328124998, 20.529589843750102],
                        [104.40781250000015, 20.485742187500023],
                        [104.36777343750015, 20.441406250000057],
                        [104.39218750000015, 20.424755859375068],
                        [104.49619140625003, 20.41367187499992],
                        [104.61884765624995, 20.374511718750114],
                        [104.65644531250001, 20.328515624999966],
                        [104.66191406250005, 20.289013671875125],
                        [104.67695312500007, 20.224707031249977],
                        [104.69873046875006, 20.205322265625114],
                        [104.84785156250007, 20.202441406250045],
                        [104.88867187500006, 20.169091796875023],
                        [104.92919921874994, 20.082812500000045],
                        [104.92792968750007, 20.01811523437499],
                        [104.81513671875001, 19.90400390625001],
                        [104.80175781250011, 19.836132812500068],
                        [104.74316406250006, 19.754736328124977],
                        [104.58789062500006, 19.61875],
                        [104.54628906250014, 19.610546875000068],
                        [104.25986328125003, 19.685498046875068],
                        [104.06279296875005, 19.678417968750068],
                        [104.03203124999999, 19.67514648437492],
                        [104.0134765625001, 19.646484374999943],
                        [104.05156250000005, 19.564160156250068],
                        [104.06289062500002, 19.482568359375136],
                        [104.02753906250013, 19.420458984375102],
                        [103.93203125000002, 19.366064453125034],
                        [103.89638671875002, 19.339990234375023],
                        [103.89160156249994, 19.30498046874999],
                        [105.146484375, 18.650976562499977],
                        [105.14541015625014, 18.616796874999977],
                        [105.08701171875015, 18.49624023437508],
                        [105.11455078125005, 18.405273437500057],
                        [105.45820312500007, 18.154296875000057],
                        [105.51855468750011, 18.077441406250045],
                        [105.58847656250015, 17.983691406249932],
                        [105.69140625, 17.737841796874932],
                        [106.00625, 17.415283203124943],
                        [106.26953125, 17.216796875000057],
                        [106.33339843750002, 17.14370117187508],
                        [106.42597656250007, 17.00253906250009],
                        [106.50224609374999, 16.9541015625],
                        [106.52597656250003, 16.876611328125023],
                        [106.53369140625, 16.821044921875057],
                        [106.54619140625005, 16.650732421874977],
                        [106.65644531250013, 16.492626953125125],
                        [106.73955078124999, 16.452539062500136],
                        [106.79160156250015, 16.490332031249977],
                        [106.83242187500008, 16.526269531250023],
                        [106.85107421875, 16.515625],
                        [106.89277343750013, 16.396533203125102],
                        [106.93066406250006, 16.353125],
                        [107.39638671875008, 16.04301757812499],
                        [107.39199218750008, 15.951660156250057],
                        [107.36064453125005, 15.921728515624977],
                        [107.18886718750008, 15.838623046875114],
                        [107.16591796875002, 15.802490234375],
                        [107.27939453125003, 15.618701171875045],
                        [107.33876953125002, 15.560498046875125],
                        [107.56425781249999, 15.3916015625],
                        [107.62167968750015, 15.309863281250045],
                        [107.653125, 15.255224609375091],
                        [107.63369140625008, 15.18984375000008],
                        [107.58964843749999, 15.118457031250102],
                        [107.55527343750009, 15.057031250000023],
                        [107.48037109375014, 14.979882812500136],
                        [107.5046875000001, 14.91591796875008],
                        [107.52451171875003, 14.871826171874943],
                        [107.51376953124998, 14.817382812500057],
                        [107.51943359375008, 14.705078125000114],
                        [107.46513671875005, 14.664990234375125],
                        [107.41474609375007, 14.56289062500008],
                        [107.37988281250006, 14.555322265625136],
                        [107.29267578125007, 14.592382812500034],
                        [107.109375, 14.416699218749955],
                        [107.06240234375008, 14.415771484374943],
                        [107.03017578125008, 14.425683593750023],
                        [106.99218749999994, 14.39101562500008],
                        [106.93808593750015, 14.327343750000068],
                        [106.91318359375003, 14.329394531249932],
                        [106.81992187500003, 14.314697265624943],
                        [106.7834960937501, 14.335107421875023],
                        [106.73818359375008, 14.387744140625102],
                        [106.66542968750002, 14.441308593750023],
                        [106.59921875000003, 14.479394531250136],
                        [106.56367187500007, 14.505078125000011],
                        [106.53115234375002, 14.549414062499977],
                        [106.50146484375, 14.578222656250034],
                        [106.22539062500005, 14.476220703125023],
                        [106.1907226562501, 14.388134765625011],
                        [106.16523437500007, 14.372363281249989],
                        [106.00839843750009, 14.357177734375114],
                        [105.97890625000014, 14.343017578125057],
                        [106.00410156250013, 14.262890625000068],
                        [106.09667968750011, 14.127099609375136],
                        [106.12470703124995, 14.049121093750045],
                        [106.06679687500008, 13.921191406250102],
                        [105.90449218750007, 13.924511718750068],
                        [105.83144531250008, 13.976611328124989],
                        [105.73974609375006, 14.084960937500057],
                        [105.5315429687501, 14.156152343750023],
                        [105.35019531250009, 14.109570312500125],
                        [105.24570312500015, 14.200537109374977],
                        [105.20703125000006, 14.259375],
                        [105.18554687499994, 14.319091796875],
                        [105.18330078125001, 14.346240234374989],
                        [105.24365234375006, 14.367871093749955],
                        [105.34218750000008, 14.416699218749955],
                        [105.42265624999993, 14.471630859374955],
                        [105.47558593750006, 14.530126953124977],
                        [105.49736328125005, 14.590673828125034],
                        [105.52304687500015, 14.843310546874989],
                        [105.54667968749999, 14.932470703125034],
                        [105.53339843750013, 15.041601562500091],
                        [105.49042968750007, 15.127587890625023],
                        [105.49042968750007, 15.256591796875],
                        [105.615625, 15.488281249999943],
                        [105.63886718750013, 15.585937499999943],
                        [105.64101562500002, 15.656542968749932],
                        [105.62207031250006, 15.699951171875114],
                        [105.39892578125011, 15.829882812500102],
                        [105.40625, 15.987451171875023],
                        [105.33066406250003, 16.037890625000045],
                        [105.1487304687501, 16.09355468749999],
                        [105.04716796874999, 16.16025390625009],
                        [104.81933593749994, 16.466064453125057],
                        [104.75058593750015, 16.647558593750034],
                        [104.74355468750014, 16.884375],
                        [104.75898437500013, 17.0771484375],
                        [104.81601562499998, 17.30029296875],
                        [104.73964843750008, 17.461669921875],
                        [104.428125, 17.698974609375057],
                        [104.32265625000002, 17.815820312500023],
                        [104.19619140625002, 17.988378906250034],
                        [104.04873046875002, 18.216699218749966],
                        [103.94960937500008, 18.318994140625023],
                        [103.89882812500002, 18.295312500000023],
                        [103.79228515624999, 18.31650390625009],
                        [103.62968750000005, 18.382568359375057],
                        [103.48798828124995, 18.41816406250001],
                        [103.36699218750005, 18.42333984375],
                        [103.28828124999995, 18.408398437499955],
                        [103.25175781249999, 18.373486328125125],
                        [103.24892578125014, 18.338964843750034],
                        [103.27958984374999, 18.304980468750045],
                        [103.26318359375, 18.278466796875136],
                        [103.19970703125006, 18.25947265625001],
                        [103.14853515625009, 18.221728515624932],
                        [103.09121093750014, 18.13823242187499],
                        [103.05136718750003, 18.02851562500001],
                        [102.80742187500005, 17.945556640625],
                        [102.71757812500005, 17.892236328125136],
                        [102.67519531250014, 17.851757812500068],
                        [102.68007812500008, 17.824121093750136],
                        [102.66064453125, 17.8179687499999],
                        [102.61679687500015, 17.833349609375034],
                        [102.59824218750009, 17.926757812500057],
                        [102.55253906249999, 17.965087890625057],
                        [102.4587890625001, 17.984619140624943],
                        [102.35185546874999, 18.045947265625045],
                        [102.14824218750005, 18.203857421875057],
                        [102.10146484375014, 18.21064453125001],
                        [102.03457031250002, 18.169824218750023],
                        [101.94746093750001, 18.081494140624955],
                        [101.87548828125011, 18.046435546874932],
                        [101.81865234375005, 18.064648437500125],
                        [101.77480468750002, 18.033398437500125],
                        [101.6875, 17.889404296875114],
                        [101.56367187500001, 17.820507812500125],
                        [101.55507812500002, 17.812353515625034],
                        [101.41367187500015, 17.71875],
                        [101.16748046875011, 17.4990234375],
                        [101.10517578125001, 17.479541015625102],
                        [100.9084960937501, 17.583886718750023],
                        [101.14394531250008, 18.14262695312499],
                        [101.1375, 18.286865234375057],
                        [101.0505859375001, 18.407031250000045],
                        [101.04697265625003, 18.441992187500034],
                        [101.28632812499995, 18.977148437500034],
                        [101.19755859374999, 19.327929687500045],
                        [101.22080078125015, 19.486621093750045],
                        [101.21191406250011, 19.548339843750057],
                        [100.51357421875008, 19.553466796875],
                        [100.39765625000013, 19.756103515625057],
                        [100.51953125000006, 20.177929687500068],
                        [100.31796875000003, 20.385888671875136],
                        [100.2180664062501, 20.339599609375114],
                        [100.13974609375015, 20.245410156250102],
                        [100.11494140625007, 20.25766601562492],
                        [100.12246093750002, 20.316650390625057],
                        [100.12968750000005, 20.372216796875023],
                        [100.1838867187501, 20.589111328124943],
                        [100.2493164062501, 20.730273437499932],
                        [100.32607421875008, 20.795703124999932],
                        [100.40742187499995, 20.823242187500057],
                        [100.56513671875013, 20.82509765625008],
                        [100.62294921875002, 20.85957031250001],
                        [100.61767578125, 20.87924804687509],
                        [100.54931640625011, 20.884228515625068],
                        [100.5222656250001, 20.921923828125102],
                        [100.53613281250006, 20.992382812500068],
                        [100.703125, 21.25136718750008],
                        [101.0803710937501, 21.46865234375008],
                        [101.13886718750013, 21.567480468749977],
                        [101.19667968750002, 21.522070312499977],
                        [101.17539062500009, 21.407519531250102],
                        [101.21992187500013, 21.342431640625136],
                        [101.21181640625008, 21.278222656250023],
                        [101.22441406249999, 21.22373046874992],
                        [101.24785156249993, 21.197314453125045],
                        [101.28144531250007, 21.184130859375045],
                        [101.44355468750001, 21.230810546874977],
                        [101.54238281250008, 21.234277343750136],
                        [101.70478515625013, 21.150146484375057],
                        [101.728125, 21.15639648437508],
                        [101.78349609374999, 21.204150390625045],
                        [101.8005859375001, 21.212597656249955],
                        [101.7229492187501, 21.314941406250057],
                        [101.74726562500007, 21.60576171874999],
                        [101.7439453125001, 21.77797851562508],
                        [101.73652343750001, 21.826513671874977],
                        [101.52451171874998, 22.253662109375],
                        [101.56787109375011, 22.2763671875],
                        [101.6199218750001, 22.327441406250102],
                        [101.67148437500009, 22.462304687500023],
                        [101.70751953125, 22.486572265625],
                        [101.73876953125011, 22.495263671874966],
                        [101.75996093750001, 22.490332031250034],
                        [101.841796875, 22.388476562500102],
                        [102.02441406250006, 22.439208984375114],
                        [102.09150390625007, 22.412255859375136],
                        [102.12744140625011, 22.37919921874999],
                    ],
                ],
            },
            properties: {
                name: "老挝",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [35.869140625, 33.43173828125],
                        [35.411230468750006, 33.07568359375],
                        [35.10859375000001, 33.08369140625],
                        [35.64785156250002, 34.2482421875],
                        [35.97626953125001, 34.629199218749996],
                        [36.383886718750006, 34.65791015625],
                        [36.32988281250002, 34.499609375],
                        [36.50439453125, 34.432373046875],
                        [36.5849609375, 34.221240234374996],
                        [36.27783203125, 33.92529296875],
                        [36.36503906250002, 33.83935546875],
                        [35.98613281250002, 33.75263671875],
                        [36.03447265625002, 33.58505859375],
                        [35.869140625, 33.43173828125],
                    ],
                ],
            },
            properties: {
                name: "黎巴嫩",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-8.486425781249977, 7.558496093749994],
                        [-8.408740234374989, 7.411816406249997],
                        [-8.324511718749989, 6.920019531249991],
                        [-8.587890625, 6.490527343749989],
                        [-8.287109375, 6.319042968749997],
                        [-7.981591796874994, 6.2861328125],
                        [-7.888623046874983, 6.23486328125],
                        [-7.800927734374994, 6.038916015624991],
                        [-7.730371093749994, 5.919042968749991],
                        [-7.636132812499994, 5.90771484375],
                        [-7.454394531249989, 5.84130859375],
                        [-7.39990234375, 5.550585937499989],
                        [-7.585058593749977, 4.916748046875],
                        [-7.574658203124983, 4.572314453124989],
                        [-7.544970703124989, 4.351318359375],
                        [-8.259033203125, 4.589990234374994],
                        [-9.132177734374977, 5.054638671874997],
                        [-10.2763671875, 6.07763671875],
                        [-11.291601562499977, 6.688232421875],
                        [-11.507519531249983, 6.906542968749989],
                        [-11.267675781249977, 7.232617187499997],
                        [-10.878076171874994, 7.538232421874994],
                        [-10.6474609375, 7.759375],
                        [-10.570849609374989, 8.071142578124991],
                        [-10.516748046874994, 8.125292968749989],
                        [-10.359814453124983, 8.187939453124997],
                        [-10.283203125, 8.485156249999989],
                        [-10.233056640624994, 8.488818359374989],
                        [-10.147412109374983, 8.519726562499997],
                        [-10.064355468749994, 8.429882812499997],
                        [-9.781982421875, 8.537695312499991],
                        [-9.518261718749983, 8.34609375],
                        [-9.369140625, 7.703808593749997],
                        [-9.463818359374983, 7.415869140624991],
                        [-9.11757812499999, 7.215917968749991],
                        [-8.8896484375, 7.2626953125],
                        [-8.659765624999977, 7.688378906249994],
                        [-8.486425781249977, 7.558496093749994],
                    ],
                ],
            },
            properties: {
                name: "利比里亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [25.150488281250006, 31.654980468749997],
                        [24.85273437500001, 31.334814453125],
                        [24.96142578125, 30.678515625],
                        [24.703222656250006, 30.201074218749994],
                        [24.980273437500017, 29.181884765625],
                        [24.980273437500017, 25.5888671875],
                        [24.980273437500017, 21.995849609375],
                        [24.9794921875, 20.002587890624994],
                        [23.980273437500017, 19.99594726562499],
                        [23.980273437500017, 19.496630859375003],
                        [20.14765625000001, 21.38925781249999],
                        [15.984082031250011, 23.445214843749994],
                        [14.97900390625, 22.99619140624999],
                        [14.215527343750011, 22.619677734375003],
                        [13.48125, 23.18017578125],
                        [11.967871093750006, 23.517871093750003],
                        [11.507617187500017, 24.314355468749994],
                        [10.686132812500006, 24.55136718749999],
                        [10.395898437500023, 24.485595703125],
                        [10.255859375, 24.591015625],
                        [10.000683593750011, 25.332080078125003],
                        [9.4482421875, 26.067138671875],
                        [9.491406250000011, 26.333740234375],
                        [9.883203125000023, 26.630810546874997],
                        [9.74755859375, 27.330859375],
                        [9.916015625, 27.785693359374996],
                        [9.805273437500006, 29.176953125],
                        [9.310253906250011, 30.115234375],
                        [9.51875, 30.229394531249994],
                        [9.89501953125, 30.3873046875],
                        [9.932519531250023, 30.425341796874996],
                        [10.059765625000011, 30.580078125],
                        [10.21640625, 30.783203125],
                        [10.114941406250011, 31.463769531249994],
                        [10.274609375000011, 31.684960937499994],
                        [10.475781250000011, 31.736035156249997],
                        [10.60888671875, 31.929541015625],
                        [10.826367187500011, 32.0806640625],
                        [11.005175781250017, 32.172705078125],
                        [11.168261718750017, 32.256738281249994],
                        [11.358007812500006, 32.34521484375],
                        [11.504980468750006, 32.413671875],
                        [11.535937500000017, 32.47333984375],
                        [11.533789062500006, 32.524951171874996],
                        [11.453906250000017, 32.642578125],
                        [11.453906250000017, 32.781689453125],
                        [11.459179687500011, 32.897363281249994],
                        [11.467187500000023, 32.965722656249994],
                        [11.504589843750011, 33.181933593749996],
                        [11.657128906250023, 33.118896484375],
                        [11.8134765625, 33.093701171875],
                        [12.279882812500006, 32.858544921874994],
                        [12.753515625, 32.801074218749996],
                        [13.283496093750017, 32.9146484375],
                        [15.176562500000017, 32.391162109374996],
                        [15.705957031250023, 31.426416015624994],
                        [17.830468750000023, 30.927587890625],
                        [18.669824218750023, 30.415673828124994],
                        [19.12373046875001, 30.26611328125],
                        [19.713281250000023, 30.48837890625],
                        [20.11152343750001, 30.963720703125],
                        [19.926367187500006, 31.817529296874994],
                        [20.121484375000023, 32.21875],
                        [20.62109375, 32.58017578125],
                        [21.63593750000001, 32.937304687499996],
                        [22.187402343750023, 32.918261718749996],
                        [23.090625, 32.61875],
                        [23.10625, 32.331445312499994],
                        [23.28632812500001, 32.213818359375],
                        [24.129687500000017, 32.009228515625],
                        [24.878515625, 31.984277343749994],
                        [25.150488281250006, 31.654980468749997],
                    ],
                ],
            },
            properties: {
                name: "利比亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-60.89521484375, 13.821972656249997],
                        [-60.951416015625, 13.717578125],
                        [-61.073144531249994, 13.865576171874991],
                        [-60.908105468749994, 14.09335937499999],
                        [-60.89521484375, 13.821972656249997],
                    ],
                ],
            },
            properties: {
                name: "圣卢西亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [79.87480468750002, 9.050732421875026],
                            [79.90371093750005, 8.975],
                            [79.74765625000006, 9.104589843749991],
                            [79.87480468750002, 9.050732421875026],
                        ],
                    ],
                    [
                        [
                            [79.98232421875, 9.812695312500011],
                            [80.25283203125005, 9.796337890625054],
                            [80.71113281250004, 9.366357421875023],
                            [81.226953125, 8.50551757812498],
                            [81.37285156250002, 8.431445312499989],
                            [81.42216796875007, 8.147851562500023],
                            [81.87412109375012, 7.288330078124986],
                            [81.86142578125012, 6.901269531249994],
                            [81.63740234375004, 6.425146484374991],
                            [80.72412109375003, 5.97905273437496],
                            [80.26738281250007, 6.009765625],
                            [80.09531250000012, 6.153173828125006],
                            [79.859375, 6.829296874999983],
                            [79.71298828125012, 8.18232421875004],
                            [79.74980468750007, 8.294238281250003],
                            [79.78349609375007, 8.018457031250051],
                            [79.92890625000004, 8.899218749999974],
                            [80.09960937499997, 9.209960937500043],
                            [80.08632812500005, 9.577832031250026],
                            [80.42832031250006, 9.480957031250014],
                            [80.04580078125005, 9.649902343749972],
                            [79.98232421875, 9.812695312500011],
                        ],
                    ],
                ],
            },
            properties: {
                name: "斯里兰卡",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [28.646875, -30.1265625],
                        [28.39208984375, -30.147558593750006],
                        [28.128710937500017, -30.52509765625001],
                        [28.05683593750001, -30.63105468750001],
                        [27.753125, -30.6],
                        [27.364062500000017, -30.27919921875001],
                        [27.19355468750001, -29.94130859375001],
                        [27.056933593750017, -29.625585937500006],
                        [27.29453125, -29.519335937500003],
                        [27.73554687500001, -28.940039062500006],
                        [27.959863281250023, -28.873339843750003],
                        [28.084375, -28.77998046875001],
                        [28.23261718750001, -28.701269531250006],
                        [28.471875, -28.615820312500006],
                        [28.583398437500023, -28.594140625],
                        [28.625781250000017, -28.58173828125001],
                        [29.301367187500006, -29.08984375],
                        [29.38671875, -29.31972656250001],
                        [29.34882812500001, -29.441992187500006],
                        [29.293554687500006, -29.56689453125],
                        [29.1421875, -29.700976562500003],
                        [29.098046875000023, -29.919042968750006],
                        [28.646875, -30.1265625],
                    ],
                ],
            },
            properties: {
                name: "莱索托",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [20.957812500000074, 55.27890625000006],
                            [20.89980468750008, 55.286669921875045],
                            [21.11484375, 55.61650390624999],
                            [20.957812500000074, 55.27890625000006],
                        ],
                    ],
                    [
                        [
                            [25.573046875000017, 54.139892578125],
                            [25.497363281250045, 54.17524414062501],
                            [25.52734375000003, 54.21513671874996],
                            [25.505664062500045, 54.26494140624999],
                            [25.46113281250004, 54.29277343749996],
                            [25.179492187500017, 54.214257812499966],
                            [25.111425781250006, 54.15493164062505],
                            [25.04609375000004, 54.13305664062503],
                            [24.869531250000023, 54.14516601562502],
                            [24.82568359374997, 54.118994140625006],
                            [24.78925781250001, 53.99824218750001],
                            [24.768164062499977, 53.97465820312499],
                            [24.31796875, 53.892968749999966],
                            [24.236621093750045, 53.91997070312496],
                            [24.19130859375005, 53.95043945312503],
                            [23.559082031250057, 53.91982421875002],
                            [23.484667968750074, 53.939794921875006],
                            [23.453613281250057, 54.14345703125002],
                            [23.3701171875, 54.20048828124999],
                            [23.282324218750063, 54.240332031250034],
                            [23.17031250000008, 54.28144531249998],
                            [23.0875, 54.299462890624994],
                            [23.042187500000068, 54.30419921875],
                            [23.01552734375005, 54.34833984375001],
                            [22.976757812500068, 54.36635742187505],
                            [22.89394531250008, 54.390527343749994],
                            [22.82373046874997, 54.39580078124999],
                            [22.766210937499977, 54.356787109375034],
                            [22.679882812500068, 54.493017578125006],
                            [22.684472656250023, 54.56293945312504],
                            [22.82470703125, 54.87128906249998],
                            [22.56728515625005, 55.05913085937496],
                            [22.072363281250034, 55.06367187499998],
                            [21.235742187500023, 55.26411132812498],
                            [21.237890625000034, 55.455029296874955],
                            [21.06191406250005, 55.81342773437498],
                            [21.053808593750006, 56.02294921875003],
                            [21.04609375000004, 56.07006835937503],
                            [21.31464843750004, 56.18813476562502],
                            [21.65351562500004, 56.314550781250006],
                            [22.084570312500034, 56.40673828125006],
                            [22.875585937500063, 56.39643554687501],
                            [22.96826171875003, 56.38041992187502],
                            [23.042968750000057, 56.324072265625006],
                            [23.119824218749983, 56.330664062500006],
                            [23.195898437500034, 56.36713867187498],
                            [24.120703125000063, 56.26425781249998],
                            [24.90302734375001, 56.398193359375],
                            [25.069921875, 56.20039062500004],
                            [25.663183593750063, 56.104833984375006],
                            [26.593554687500074, 55.66752929687502],
                            [26.590820312500057, 55.62265625],
                            [26.56660156250001, 55.546484375000034],
                            [26.51923828125004, 55.448144531249994],
                            [26.469531250000045, 55.371923828125006],
                            [26.457617187500006, 55.342480468749955],
                            [26.49531250000004, 55.31801757812502],
                            [26.68125, 55.30644531249999],
                            [26.76015625000008, 55.29335937499999],
                            [26.775683593750045, 55.27309570312502],
                            [26.601171875000034, 55.130175781250045],
                            [26.291796875000074, 55.13959960937501],
                            [26.250781250000045, 55.12451171875006],
                            [26.175195312500023, 55.003271484375034],
                            [26.092968750000068, 54.96230468750005],
                            [25.964453124999977, 54.947167968749966],
                            [25.85927734375005, 54.91928710937498],
                            [25.722460937500074, 54.71787109374998],
                            [25.731640625000068, 54.59038085937502],
                            [25.72480468750001, 54.564257812500045],
                            [25.68515625, 54.53579101562502],
                            [25.62031250000004, 54.46040039062501],
                            [25.56757812500004, 54.377050781250006],
                            [25.54736328125, 54.33183593750002],
                            [25.55751953125005, 54.310693359374994],
                            [25.702539062499994, 54.29296875],
                            [25.765234374999977, 54.179785156250034],
                            [25.573046875000017, 54.139892578125],
                        ],
                    ],
                ],
            },
            properties: {
                name: "立陶宛",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [6.4873046875, 49.798486328124994],
                        [6.344335937500006, 49.452734375],
                        [6.181054687500023, 49.498925781249994],
                        [6.119921875000017, 49.485205078125],
                        [6.074121093750023, 49.454638671874996],
                        [6.011425781250011, 49.445458984374994],
                        [5.95947265625, 49.454638671874996],
                        [5.928906250000011, 49.4775390625],
                        [5.9013671875, 49.48974609375],
                        [5.823437500000011, 49.505078125],
                        [5.789746093750011, 49.53828125],
                        [5.776710379464286, 49.639953962053575],
                        [5.744042968750023, 49.91962890625],
                        [5.7880859375, 49.961230468749996],
                        [5.8173828125, 50.0126953125],
                        [5.866894531250011, 50.0828125],
                        [5.976269531250011, 50.1671875],
                        [6.089062500000011, 50.154589843749996],
                        [6.110058593750011, 50.123779296875],
                        [6.116503906250017, 50.120996093749994],
                        [6.109765625000023, 50.034375],
                        [6.13818359375, 49.97431640625],
                        [6.204882812500017, 49.91513671875],
                        [6.272327008928583, 49.887234933035714],
                        [6.4873046875, 49.798486328124994],
                    ],
                ],
            },
            properties: {
                name: "卢森堡",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [28.14794921875, 56.142919921875],
                        [27.576757812500006, 55.798779296875],
                        [27.052539062500017, 55.83056640625],
                        [26.593554687500017, 55.667529296874996],
                        [25.663183593750006, 56.104833984375],
                        [25.069921875, 56.200390625],
                        [24.90302734375001, 56.398193359375],
                        [24.120703125, 56.2642578125],
                        [23.81269531250001, 56.329248046875],
                        [23.195898437500006, 56.367138671875],
                        [23.11982421875001, 56.3306640625],
                        [23.04296875, 56.324072265625],
                        [22.875585937500006, 56.396435546875],
                        [22.084570312500006, 56.40673828125],
                        [21.730566406250006, 56.325976562499996],
                        [21.65351562500001, 56.31455078125],
                        [21.31464843750001, 56.188134765625],
                        [21.04609375000001, 56.070068359375],
                        [21.0712890625, 56.82373046875],
                        [21.72871093750001, 57.57099609375],
                        [22.554589843750023, 57.724267578125],
                        [23.28730468750001, 57.08974609375],
                        [23.647753906250017, 56.971044921875],
                        [24.382617187500017, 57.250048828124996],
                        [24.322558593750017, 57.87060546875],
                        [24.3625, 57.866162109375],
                        [24.458886718750023, 57.907861328125],
                        [25.11103515625001, 58.063427734375],
                        [25.27265625000001, 58.009375],
                        [25.66015625, 57.920166015625],
                        [26.29804687500001, 57.60107421875],
                        [26.532617187500023, 57.531005859375],
                        [26.96601562500001, 57.609130859375],
                        [27.187109375, 57.538330078125],
                        [27.326562500000023, 57.52548828125],
                        [27.4697265625, 57.5240234375],
                        [27.538671875, 57.42978515625],
                        [27.796875, 57.316943359374996],
                        [27.82861328125, 57.293310546875],
                        [27.838281250000023, 57.247705078125],
                        [27.83027343750001, 57.194482421875],
                        [27.639453125000017, 56.845654296875],
                        [27.806054687500023, 56.86708984375],
                        [27.8486328125, 56.85341796875],
                        [27.89208984375, 56.741064453125],
                        [28.00751953125001, 56.599853515625],
                        [28.103125, 56.545703125],
                        [28.11083984375, 56.510693359375],
                        [28.169238281250017, 56.386865234375],
                        [28.191699218750017, 56.315576171875],
                        [28.202050781250023, 56.260400390625],
                        [28.14794921875, 56.142919921875],
                    ],
                ],
            },
            properties: {
                name: "拉脱维亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [28.2125, 45.450439453125],
                        [28.07470703125, 45.598974609375],
                        [28.23945312500001, 46.6408203125],
                        [28.07177734375, 46.978417968749994],
                        [27.614062500000017, 47.34052734375],
                        [26.980761718750017, 48.155029296875],
                        [26.618945312500017, 48.25986328125],
                        [26.640429687500017, 48.294140625],
                        [26.847070312500023, 48.387158203125],
                        [26.90058593750001, 48.371923828125],
                        [27.228515625, 48.371435546875],
                        [27.549218750000023, 48.477734375],
                        [28.34052734375001, 48.144433593749994],
                        [28.42304687500001, 48.146875],
                        [29.125390625000023, 47.96455078125],
                        [29.134863281250006, 47.489697265625],
                        [29.455664062500006, 47.292626953124994],
                        [29.57197265625001, 46.964013671874994],
                        [29.7197265625, 46.88291015625],
                        [29.877832031250023, 46.82890625],
                        [29.942480468750006, 46.723779296874994],
                        [29.93476562500001, 46.625],
                        [29.92431640625, 46.538867187499996],
                        [30.13105468750001, 46.423095703125],
                        [30.07568359375, 46.377832031249994],
                        [29.878027343750006, 46.360205078125],
                        [29.837890625, 46.350537109375],
                        [29.458789062500017, 46.453759765624994],
                        [29.30488281250001, 46.466601562499996],
                        [29.22382812500001, 46.376953125],
                        [29.20458984375, 46.379345703125],
                        [29.20078125, 46.50498046875],
                        [29.18623046875001, 46.523974609374996],
                        [29.146289062500017, 46.526904296874996],
                        [28.958398437500023, 46.45849609375],
                        [28.92744140625001, 46.424121093749996],
                        [28.930566406250023, 46.362255859375],
                        [28.94375, 46.288427734375],
                        [29.00625, 46.17646484375],
                        [28.971875, 46.12763671875],
                        [28.94775390625, 46.049951171874994],
                        [28.849511718750023, 45.978662109374994],
                        [28.73876953125, 45.937158203124994],
                        [28.729296875000017, 45.852001953125],
                        [28.667578125, 45.793847656249994],
                        [28.562304687500017, 45.735791015625],
                        [28.491601562500023, 45.665771484375],
                        [28.4990234375, 45.517724609374994],
                        [28.310351562500017, 45.498583984374996],
                        [28.26484375000001, 45.48388671875],
                        [28.2125, 45.450439453125],
                    ],
                ],
            },
            properties: {
                name: "摩尔多瓦",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [49.936425781249994, -16.90292968750002],
                            [49.82402343750002, -17.08652343750002],
                            [50.02304687500006, -16.6953125],
                            [49.936425781249994, -16.90292968750002],
                        ],
                    ],
                    [
                        [
                            [48.3421875, -13.363867187500034],
                            [48.21191406250003, -13.385253906249957],
                            [48.191210937500074, -13.259960937500011],
                            [48.308886718750074, -13.198242187499957],
                            [48.3421875, -13.363867187500034],
                        ],
                    ],
                    [
                        [
                            [49.53828125000004, -12.432128906250014],
                            [49.9375, -13.072265624999957],
                            [50.23535156249997, -14.732031249999963],
                            [50.482714843750074, -15.385644531249994],
                            [50.20898437499997, -15.960449218750028],
                            [50.02041015625005, -15.801757812500028],
                            [49.89257812500003, -15.457714843750011],
                            [49.664355468750074, -15.521582031249977],
                            [49.83906250000004, -16.486523437499997],
                            [49.76718750000006, -16.815136718749983],
                            [49.44931640625006, -17.240625],
                            [49.477832031250074, -17.89853515624999],
                            [49.362890625, -18.336328125],
                            [47.934472656249994, -22.393945312500023],
                            [47.55800781250005, -23.874609374999963],
                            [47.17734375, -24.787207031249977],
                            [46.72851562499997, -25.14990234374997],
                            [46.15869140624997, -25.230371093750023],
                            [45.5080078125001, -25.56318359374997],
                            [45.2057617187501, -25.57050781250004],
                            [44.0353515625001, -24.995703125],
                            [43.670019531250006, -24.30029296875],
                            [43.722265625, -23.529687500000037],
                            [43.2648437500001, -22.38359375],
                            [43.29052734374997, -21.93251953124998],
                            [43.50185546875005, -21.356445312499957],
                            [43.800195312499994, -21.179199218749986],
                            [44.40468750000005, -19.922070312500026],
                            [44.44882812500006, -19.42871093749997],
                            [44.23876953124997, -19.075195312499986],
                            [44.23310546875004, -18.740625],
                            [44.04003906249997, -18.288476562500023],
                            [43.979394531249994, -17.3916015625],
                            [44.42138671874997, -16.70263671874997],
                            [44.476171875, -16.217285156249957],
                            [44.90917968749997, -16.174511718750026],
                            [45.2228515625001, -15.95048828124996],
                            [45.3421875, -16.03671875000002],
                            [45.598242187500006, -15.992578125],
                            [45.70019531249997, -15.813769531249989],
                            [46.157519531250074, -15.738281249999972],
                            [46.3996093750001, -15.924609375000017],
                            [46.331445312499994, -15.713671875000031],
                            [46.47509765625003, -15.513476562500003],
                            [46.942285156249994, -15.219042968749974],
                            [47.09921875, -15.43417968750002],
                            [47.092578125000074, -15.150097656249969],
                            [47.35195312500005, -14.766113281249986],
                            [47.46474609375005, -14.713281249999966],
                            [47.47832031250002, -15.009375],
                            [47.77402343750006, -14.63671875],
                            [47.964160156250074, -14.672558593750026],
                            [47.773339843749994, -14.369921875],
                            [47.995507812499994, -13.960449218749986],
                            [47.88359375000002, -13.807519531250009],
                            [47.94101562500006, -13.662402343750017],
                            [48.03984375000002, -13.596289062499963],
                            [48.25527343750005, -13.719335937499977],
                            [48.796484375, -13.267480468750023],
                            [48.91943359375003, -12.839062499999969],
                            [48.78632812500004, -12.470898437500011],
                            [48.931738281250006, -12.4390625],
                            [49.20703124999997, -12.079589843749957],
                            [49.53828125000004, -12.432128906250014],
                        ],
                    ],
                ],
            },
            properties: {
                name: "马达加斯加",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-91.68369140624998, 18.677343750000034],
                            [-91.81611328124995, 18.675878906250006],
                            [-91.53671874999998, 18.760009765625],
                            [-91.68369140624998, 18.677343750000034],
                        ],
                    ],
                    [
                        [
                            [-86.93964843750001, 20.303320312500006],
                            [-86.97797851562498, 20.489794921875074],
                            [-86.76328124999995, 20.579052734374955],
                            [-86.93964843750001, 20.303320312500006],
                        ],
                    ],
                    [
                        [
                            [-106.50224609374999, 21.61083984375003],
                            [-106.60703124999993, 21.561474609374983],
                            [-106.63935546874995, 21.697851562499977],
                            [-106.50224609374999, 21.61083984375003],
                        ],
                    ],
                    [
                        [
                            [-110.56738281249994, 25.003466796875017],
                            [-110.5388671875, 24.89155273437504],
                            [-110.69926757812499, 25.081445312499994],
                            [-110.56738281249994, 25.003466796875017],
                        ],
                    ],
                    [
                        [
                            [-112.05727539062498, 24.545703125000017],
                            [-112.29677734375002, 24.789648437500063],
                            [-112.15942382812501, 25.28564453125003],
                            [-112.19501953124998, 24.841064453125057],
                            [-112.05727539062498, 24.545703125000017],
                        ],
                    ],
                    [
                        [
                            [-111.10029296874998, 26.020605468750006],
                            [-111.224658203125, 25.83588867187504],
                            [-111.18291015625002, 26.040625],
                            [-111.10029296874998, 26.020605468750006],
                        ],
                    ],
                    [
                        [
                            [-115.17060546875001, 28.06938476562496],
                            [-115.35292968750002, 28.103955078124983],
                            [-115.23354492187495, 28.36835937500004],
                            [-115.17060546875001, 28.06938476562496],
                        ],
                    ],
                    [
                        [
                            [-112.20307617187503, 29.00532226562504],
                            [-112.27841796875, 28.769335937500017],
                            [-112.51406249999997, 28.847607421874955],
                            [-112.42353515625, 29.203662109375017],
                            [-112.28505859374994, 29.240429687499955],
                            [-112.20307617187503, 29.00532226562504],
                        ],
                    ],
                    [
                        [
                            [-113.15561523437502, 29.05224609375],
                            [-113.49633789062497, 29.30761718749997],
                            [-113.58720703125002, 29.57304687499996],
                            [-113.20214843749999, 29.301855468750034],
                            [-113.15561523437502, 29.05224609375],
                        ],
                    ],
                    [
                        [
                            [-97.14624023437494, 25.961474609375045],
                            [-97.66767578124995, 24.389990234374977],
                            [-97.84248046874995, 22.510302734375017],
                            [-97.76328124999998, 22.105859374999966],
                            [-97.31450195312496, 21.56420898437503],
                            [-97.40917968749997, 21.272558593750034],
                            [-97.38344726562497, 21.56669921874999],
                            [-97.75380859375002, 22.02666015624999],
                            [-97.18632812499996, 20.717041015625],
                            [-96.45605468749994, 19.869775390624966],
                            [-96.28955078124994, 19.34375],
                            [-95.778125, 18.805517578125034],
                            [-95.92036132812495, 18.81958007812497],
                            [-95.62680664062503, 18.690576171874994],
                            [-95.71982421874998, 18.768359375000017],
                            [-95.18183593749995, 18.700732421875017],
                            [-94.79814453124996, 18.51459960937501],
                            [-94.45976562499993, 18.166650390624994],
                            [-93.55234375, 18.430468750000017],
                            [-92.88476562499997, 18.468652343749966],
                            [-92.44101562499998, 18.67529296874997],
                            [-91.97377929687502, 18.715869140625074],
                            [-91.91357421875, 18.52851562500001],
                            [-91.53398437499993, 18.45654296875],
                            [-91.27524414062498, 18.62446289062501],
                            [-91.34306640624996, 18.900585937499955],
                            [-91.43666992187502, 18.889794921874966],
                            [-90.73925781249994, 19.352246093749955],
                            [-90.69316406249996, 19.729882812499966],
                            [-90.49169921874997, 19.94677734375003],
                            [-90.353125, 21.009423828124966],
                            [-89.81977539062495, 21.274609374999983],
                            [-88.46669921874997, 21.569384765625017],
                            [-88.0068359375, 21.604052734375045],
                            [-87.25087890625, 21.44697265625004],
                            [-87.18828124999993, 21.546435546875045],
                            [-87.36850585937498, 21.57373046875],
                            [-87.034765625, 21.592236328124955],
                            [-86.824072265625, 21.421679687500017],
                            [-86.77177734374999, 21.150537109375023],
                            [-86.92622070312493, 20.786474609375034],
                            [-87.42138671875, 20.23139648437501],
                            [-87.44174804687498, 19.861523437499983],
                            [-87.68769531249998, 19.63710937499999],
                            [-87.6453125, 19.55390625000001],
                            [-87.42475585937498, 19.583349609375063],
                            [-87.65869140625003, 19.352343750000074],
                            [-87.65576171874997, 19.25786132812499],
                            [-87.50107421874998, 19.287792968749983],
                            [-87.76181640624998, 18.446142578125006],
                            [-87.88198242187497, 18.27387695312501],
                            [-88.05644531249996, 18.524462890625074],
                            [-88.03173828125, 18.838916015625017],
                            [-88.29565429687494, 18.47241210937503],
                            [-88.52299804687499, 18.445898437500063],
                            [-88.80634765624998, 17.965527343749983],
                            [-89.13354492187503, 17.970800781249977],
                            [-89.16147460937503, 17.81484375],
                            [-90.98916015624997, 17.81640624999997],
                            [-90.99296874999993, 17.25244140625],
                            [-91.19550781249998, 17.254101562499983],
                            [-91.40961914062501, 17.255859375],
                            [-90.975830078125, 16.867822265624994],
                            [-90.710693359375, 16.708105468750034],
                            [-90.65996093749996, 16.630908203125045],
                            [-90.634375, 16.565136718749955],
                            [-90.63408203125002, 16.51074218749997],
                            [-90.57578124999995, 16.467822265625017],
                            [-90.47109374999994, 16.439550781250034],
                            [-90.41699218750003, 16.391015625000023],
                            [-90.41699218750003, 16.351318359375],
                            [-90.45014648437493, 16.261376953124994],
                            [-90.45986328124997, 16.16235351562497],
                            [-90.44716796874994, 16.07270507812501],
                            [-90.52197265625, 16.07119140625005],
                            [-90.70322265624998, 16.07104492187503],
                            [-90.97958984374998, 16.07080078124997],
                            [-91.433984375, 16.070458984374994],
                            [-91.736572265625, 16.070166015625006],
                            [-91.95722656250001, 15.703222656250034],
                            [-92.08212890624998, 15.495556640625011],
                            [-92.18715820312497, 15.320898437499963],
                            [-92.07480468749998, 15.074218749999972],
                            [-92.09873046874998, 15.026757812499994],
                            [-92.14423828125001, 15.001953125],
                            [-92.158544921875, 14.963574218749997],
                            [-92.23515625, 14.545410156249986],
                            [-93.91606445312493, 16.053564453125006],
                            [-94.374169921875, 16.284765625000034],
                            [-94.426416015625, 16.22626953125001],
                            [-94.00126953124996, 16.018945312499966],
                            [-94.66152343750002, 16.20190429687503],
                            [-94.58710937499995, 16.315820312499966],
                            [-94.79082031249999, 16.28715820312499],
                            [-94.85869140624996, 16.41972656249999],
                            [-95.02084960937503, 16.277636718750017],
                            [-94.79941406249995, 16.20966796875001],
                            [-95.134375, 16.17695312500001],
                            [-96.21357421874993, 15.693066406250011],
                            [-96.80795898437495, 15.726416015624977],
                            [-97.18466796874998, 15.909277343750006],
                            [-97.75478515624994, 15.966845703125017],
                            [-98.52031249999993, 16.30483398437505],
                            [-98.76220703125, 16.534765624999977],
                            [-99.69067382812499, 16.719628906249994],
                            [-100.847802734375, 17.20048828124999],
                            [-101.91870117187494, 17.959765625000045],
                            [-102.69956054687495, 18.062841796875006],
                            [-103.44160156249995, 18.32539062500001],
                            [-103.91245117187496, 18.828466796875006],
                            [-104.9384765625, 19.309375],
                            [-105.482080078125, 19.97607421875003],
                            [-105.66943359374997, 20.385595703124977],
                            [-105.26015625, 20.579052734374955],
                            [-105.32705078124994, 20.752978515625045],
                            [-105.51083984374999, 20.808740234375023],
                            [-105.23706054687499, 21.119189453125045],
                            [-105.20869140624998, 21.490820312499977],
                            [-105.43144531249997, 21.618261718750006],
                            [-105.64912109375001, 21.988085937500045],
                            [-105.64550781249999, 22.32690429687497],
                            [-105.79179687500003, 22.627490234375017],
                            [-106.93549804687497, 23.88125],
                            [-107.76494140625002, 24.47192382812497],
                            [-107.52724609375001, 24.36005859375001],
                            [-107.51191406249998, 24.489160156250023],
                            [-107.95117187499994, 24.614892578124966],
                            [-108.28076171874994, 25.08154296875],
                            [-108.05146484374995, 25.067041015624994],
                            [-108.69638671874998, 25.382910156250034],
                            [-108.78725585937502, 25.53803710937501],
                            [-109.02880859375003, 25.48046875000003],
                            [-108.886572265625, 25.733447265625045],
                            [-109.19648437499998, 25.59252929687503],
                            [-109.38496093750001, 25.727148437500006],
                            [-109.42563476562495, 26.032568359375063],
                            [-109.19970703125003, 26.30522460937499],
                            [-109.11669921874999, 26.25273437499996],
                            [-109.27626953125, 26.533886718749955],
                            [-109.48286132812498, 26.710351562500023],
                            [-109.75478515624995, 26.702929687500017],
                            [-109.94399414062495, 27.079345703125057],
                            [-110.37729492187495, 27.233300781249966],
                            [-110.59267578124995, 27.544335937500023],
                            [-110.52988281249995, 27.864208984374983],
                            [-111.12138671875002, 27.966992187499983],
                            [-112.16176757812495, 29.018896484375034],
                            [-113.05766601562496, 30.651025390625023],
                            [-113.04672851562495, 31.17924804687499],
                            [-113.62348632812494, 31.34589843750001],
                            [-113.75942382812501, 31.557763671874994],
                            [-113.94775390625001, 31.62934570312501],
                            [-114.14931640624995, 31.507373046875045],
                            [-114.93359374999994, 31.900732421874977],
                            [-114.78989257812498, 31.647119140624994],
                            [-114.88188476562499, 31.156396484375023],
                            [-114.55048828124997, 30.02226562499999],
                            [-113.75546875, 29.367480468750017],
                            [-113.49970703124995, 28.92670898437501],
                            [-113.20556640624997, 28.798779296874955],
                            [-113.09365234375001, 28.511767578125017],
                            [-112.870849609375, 28.42421875000005],
                            [-112.73403320312501, 27.825976562500017],
                            [-112.32919921874996, 27.52343750000003],
                            [-111.86264648437495, 26.678515625000017],
                            [-111.6994140625, 26.58095703125005],
                            [-111.79526367187499, 26.8796875],
                            [-111.56967773437495, 26.707617187500006],
                            [-111.29160156249996, 25.78979492187497],
                            [-110.68676757812501, 24.867675781250057],
                            [-110.65932617187502, 24.34145507812505],
                            [-110.36743164062497, 24.100488281249994],
                            [-110.30375976562497, 24.339453125],
                            [-110.02280273437502, 24.17460937499999],
                            [-109.6765625, 23.66157226562501],
                            [-109.42084960937495, 23.480126953124994],
                            [-109.49570312500002, 23.159814453125023],
                            [-110.00625, 22.894042968750057],
                            [-110.3626953125, 23.60493164062501],
                            [-111.68291015625002, 24.555810546875023],
                            [-111.80249023437494, 24.542529296875074],
                            [-112.07255859374999, 24.84003906250001],
                            [-112.06987304687497, 25.572851562500006],
                            [-112.37724609374997, 26.21391601562496],
                            [-113.02075195312499, 26.58325195312497],
                            [-113.15581054687496, 26.94624023437504],
                            [-113.27226562499997, 26.79096679687501],
                            [-113.59853515625001, 26.721289062500034],
                            [-113.84096679687502, 26.966503906249983],
                            [-114.44526367187503, 27.218164062499994],
                            [-114.53989257812495, 27.431103515624955],
                            [-114.99350585937499, 27.736035156249983],
                            [-115.03647460937495, 27.84184570312496],
                            [-114.57001953124995, 27.78393554687497],
                            [-114.30058593749995, 27.87299804687501],
                            [-114.30224609375003, 27.775732421875006],
                            [-114.0693359375, 27.67568359375005],
                            [-114.15839843750003, 27.919677734375],
                            [-114.26586914062499, 27.934472656249994],
                            [-114.04848632812502, 28.42617187499999],
                            [-114.93730468749999, 29.35161132812496],
                            [-115.67382812500003, 29.756396484375017],
                            [-116.06215820312501, 30.80415039062504],
                            [-116.29628906250001, 30.97050781249999],
                            [-116.33344726562494, 31.202783203124994],
                            [-116.66215820312495, 31.56489257812504],
                            [-116.72207031249998, 31.734570312499955],
                            [-116.62080078124995, 31.85107421874997],
                            [-116.84799804687496, 31.997363281250045],
                            [-117.12827148437495, 32.533349609374994],
                            [-114.72475585937495, 32.71533203125003],
                            [-114.83593749999994, 32.50830078125003],
                            [-111.0419921875, 31.32421875000003],
                            [-108.21445312499993, 31.329443359375034],
                            [-108.21181640625002, 31.779345703125017],
                            [-106.44541015624996, 31.768408203125006],
                            [-106.14804687499995, 31.450927734375],
                            [-104.97880859374996, 30.645947265624955],
                            [-104.50400390624995, 29.677685546874955],
                            [-104.110595703125, 29.386132812499994],
                            [-103.16831054687498, 28.998193359374994],
                            [-102.8919921875, 29.216406250000034],
                            [-102.61494140624994, 29.75234375],
                            [-102.26894531249998, 29.871191406250034],
                            [-101.44038085937503, 29.77685546875],
                            [-100.75458984375001, 29.182519531249994],
                            [-100.29604492187495, 28.32768554687499],
                            [-99.50532226562497, 27.54833984375003],
                            [-99.45654296874999, 27.05668945312496],
                            [-99.10776367187498, 26.446923828124994],
                            [-97.37563476562497, 25.871826171875],
                            [-97.14624023437494, 25.961474609375045],
                        ],
                    ],
                ],
            },
            properties: {
                name: "墨西哥",
                childNum: 10,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [22.344042968750017, 42.31396484375],
                        [22.836816406250023, 41.993603515625],
                        [23.00361328125001, 41.73984375],
                        [22.916015625, 41.336279296875],
                        [22.78388671875001, 41.331982421875],
                        [22.72480468750001, 41.178515625],
                        [22.603613281250006, 41.140185546874996],
                        [22.493554687500023, 41.118505859375],
                        [22.184472656250023, 41.158642578125],
                        [21.99335937500001, 41.13095703125],
                        [21.77949218750001, 40.950439453125],
                        [21.627539062500006, 40.896337890625],
                        [21.57578125, 40.868945312499996],
                        [20.964257812500023, 40.849902343749996],
                        [20.709277343750017, 40.928369140624994],
                        [20.48896484375001, 41.272607421874994],
                        [20.566210937500017, 41.873681640624994],
                        [20.725, 41.87353515625],
                        [20.778125, 42.071044921875],
                        [21.05976562500001, 42.171289062499994],
                        [21.28662109375, 42.100390625],
                        [21.389550781250023, 42.21982421875],
                        [21.560839843750017, 42.24765625],
                        [21.5625, 42.247509765625],
                        [21.81464843750001, 42.303125],
                        [22.344042968750017, 42.31396484375],
                    ],
                ],
            },
            properties: {
                name: "马其顿",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [4.227636718750006, 19.142773437499997],
                        [4.234667968750017, 16.996386718750003],
                        [4.121289062500011, 16.357714843750003],
                        [3.842968750000011, 15.701708984375003],
                        [3.5205078125, 15.483105468749997],
                        [3.504296875000023, 15.356347656249994],
                        [3.06015625, 15.427197265624997],
                        [3.001074218750006, 15.340966796874994],
                        [1.300195312500023, 15.272265625],
                        [0.947460937500011, 14.982128906249997],
                        [0.217480468750011, 14.911474609374991],
                        [-0.235888671874989, 15.059423828124991],
                        [-0.760449218749983, 15.047753906249994],
                        [-1.049560546875, 14.81953125],
                        [-1.97304687499999, 14.45654296875],
                        [-2.113232421874983, 14.16845703125],
                        [-2.586718749999989, 14.227587890625003],
                        [-2.873925781249994, 13.950732421875003],
                        [-2.950830078124994, 13.6484375],
                        [-3.248632812499977, 13.658349609374994],
                        [-3.3017578125, 13.28076171875],
                        [-3.527636718749989, 13.182714843749991],
                        [-3.947314453124989, 13.402197265624991],
                        [-4.151025390624994, 13.306201171875003],
                        [-4.328710937499977, 13.119042968749994],
                        [-4.227099609374989, 12.793701171875],
                        [-4.480615234374994, 12.672216796874991],
                        [-4.4287109375, 12.337597656249997],
                        [-4.699316406249977, 12.076171875],
                        [-5.288134765624989, 11.827929687499989],
                        [-5.250244140625, 11.375781249999989],
                        [-5.490478515625, 11.042382812499994],
                        [-5.523535156249977, 10.426025390625],
                        [-5.556591796874983, 10.43994140625],
                        [-5.694287109374983, 10.43320312499999],
                        [-5.843847656249977, 10.389550781249994],
                        [-5.896191406249983, 10.354736328125],
                        [-5.907568359374977, 10.307226562499991],
                        [-6.034570312499994, 10.19482421875],
                        [-6.1171875, 10.201904296875],
                        [-6.238378906249977, 10.261621093749994],
                        [-6.241308593749977, 10.279199218749994],
                        [-6.192626953125, 10.369433593749989],
                        [-6.190673828125, 10.400292968749994],
                        [-6.250244140625, 10.717919921874994],
                        [-6.482617187499983, 10.561230468749997],
                        [-6.564599609374994, 10.58642578125],
                        [-6.654150390624977, 10.656445312499997],
                        [-6.676367187499977, 10.6337890625],
                        [-6.686132812499977, 10.578027343749994],
                        [-6.691992187499977, 10.512011718749989],
                        [-6.669335937499994, 10.3921875],
                        [-6.693261718749994, 10.349462890624991],
                        [-6.950341796874994, 10.342333984374989],
                        [-7.01708984375, 10.143261718749997],
                        [-7.385058593749989, 10.340136718749989],
                        [-7.6611328125, 10.427441406249997],
                        [-7.990625, 10.1625],
                        [-8.007275390624983, 10.321875],
                        [-8.266650390624989, 10.485986328124994],
                        [-8.33740234375, 10.990625],
                        [-8.666699218749983, 11.009472656249997],
                        [-8.398535156249977, 11.366552734374991],
                        [-8.822021484375, 11.673242187499994],
                        [-8.818310546874983, 11.922509765624994],
                        [-9.043066406249977, 12.40234375],
                        [-9.395361328124977, 12.464648437499989],
                        [-9.358105468749983, 12.255419921874989],
                        [-9.754003906249977, 12.029931640624994],
                        [-10.274853515624983, 12.212646484375],
                        [-10.709228515625, 11.898730468749989],
                        [-10.933203124999977, 12.205175781249991],
                        [-11.30517578125, 12.015429687499989],
                        [-11.502197265625, 12.198632812499994],
                        [-11.389404296875, 12.404394531249991],
                        [-11.390380859375, 12.941992187499991],
                        [-11.634960937499983, 13.369873046875],
                        [-11.831689453124994, 13.315820312499994],
                        [-12.05419921875, 13.633056640625],
                        [-11.960888671874983, 13.875292968750003],
                        [-12.019189453124994, 14.206494140624997],
                        [-12.228417968749994, 14.45859375],
                        [-12.280615234374977, 14.809033203124997],
                        [-12.104687499999983, 14.745361328125],
                        [-12.08154296875, 14.766357421875],
                        [-12.021582031249977, 14.804931640625],
                        [-11.76015625, 15.425537109375],
                        [-11.675878906249977, 15.512060546874991],
                        [-11.502685546875, 15.636816406249991],
                        [-11.455224609374994, 15.62539062499999],
                        [-10.9482421875, 15.151123046875],
                        [-10.696582031249989, 15.42265625],
                        [-9.94140625, 15.373779296875],
                        [-9.446923828124994, 15.458203125],
                        [-9.447705078124983, 15.574853515624994],
                        [-9.426562499999989, 15.623046875],
                        [-9.3505859375, 15.677392578124994],
                        [-9.33544921875, 15.525683593750003],
                        [-9.293701171875, 15.502832031249994],
                        [-5.5125, 15.496289062499997],
                        [-5.359912109374989, 16.282861328124994],
                        [-5.509619140624977, 16.442041015624994],
                        [-5.628662109375, 16.568652343750003],
                        [-5.65625, 16.8095703125],
                        [-5.684765624999983, 17.058251953124994],
                        [-5.713183593749989, 17.306884765625],
                        [-5.74169921875, 17.555566406249994],
                        [-5.827099609374983, 18.3015625],
                        [-6.026416015624989, 20.0421875],
                        [-6.396582031249977, 23.274804687499994],
                        [-6.482031249999977, 24.020800781250003],
                        [-6.538964843749994, 24.51816406249999],
                        [-6.5673828125, 24.766796875],
                        [-6.594091796874977, 24.99462890625],
                        [-6.287207031249977, 24.994824218749997],
                        [-5.959814453124977, 24.99497070312499],
                        [-5.640771484374994, 24.995166015625003],
                        [-4.822607421874977, 24.99560546875],
                        [-1.947900390624994, 23.124804687500003],
                        [1.1455078125, 21.102246093749997],
                        [1.165722656250011, 20.817431640625003],
                        [1.610644531250017, 20.555566406249994],
                        [1.685449218750023, 20.378369140624997],
                        [3.130273437500023, 19.85019531249999],
                        [3.255859375, 19.4109375],
                        [3.119726562500006, 19.103173828124994],
                        [3.3564453125, 18.986621093750003],
                        [4.227636718750006, 19.142773437499997],
                    ],
                ],
            },
            properties: {
                name: "马里",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [14.566210937499989, 35.85273437499998],
                        [14.436425781250023, 35.82167968750005],
                        [14.351269531250011, 35.978417968749994],
                        [14.566210937499989, 35.85273437499998],
                    ],
                ],
            },
            properties: {
                name: "马耳他",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [98.18261718749997, 9.933447265625006],
                            [98.11806640625, 9.877880859375054],
                            [98.2916992187501, 10.051318359375031],
                            [98.18261718749997, 9.933447265625006],
                        ],
                    ],
                    [
                        [
                            [98.20976562500002, 10.952734375],
                            [98.27148437499997, 10.73989257812498],
                            [98.08046875000005, 10.886621093750037],
                            [98.20976562500002, 10.952734375],
                        ],
                    ],
                    [
                        [
                            [98.55380859375012, 11.744873046875],
                            [98.52841796875012, 11.538671875],
                            [98.43476562500004, 11.567089843750026],
                            [98.37646484374997, 11.79150390625],
                            [98.55380859375012, 11.744873046875],
                        ],
                    ],
                    [
                        [
                            [98.516015625, 11.905029296875028],
                            [98.46621093750005, 12.08427734374996],
                            [98.60957031250004, 11.956640624999977],
                            [98.516015625, 11.905029296875028],
                        ],
                    ],
                    [
                        [
                            [98.06611328125004, 12.389794921875023],
                            [98.00234375000005, 12.279003906250011],
                            [97.93867187500004, 12.34609375],
                            [98.06611328125004, 12.389794921875023],
                        ],
                    ],
                    [
                        [
                            [98.41396484375005, 12.597949218749974],
                            [98.45947265625003, 12.473730468749991],
                            [98.3138671875, 12.335986328124989],
                            [98.31210937500006, 12.678173828124983],
                            [98.41396484375005, 12.597949218749974],
                        ],
                    ],
                    [
                        [
                            [98.31542968749997, 13.099072265625026],
                            [98.30917968750012, 12.934716796875023],
                            [98.26533203125004, 13.202246093749991],
                            [98.31542968749997, 13.099072265625026],
                        ],
                    ],
                    [
                        [
                            [94.80488281250004, 15.8193359375],
                            [94.73349609375006, 15.823046875000045],
                            [94.82802734375005, 15.933007812499966],
                            [94.80488281250004, 15.8193359375],
                        ],
                    ],
                    [
                        [
                            [94.47675781250004, 15.945947265625023],
                            [94.41191406250007, 15.848388671875057],
                            [94.3878906250001, 15.994140624999972],
                            [94.60126953125004, 16.205517578124983],
                            [94.47675781250004, 15.945947265625023],
                        ],
                    ],
                    [
                        [
                            [97.575, 16.253222656250017],
                            [97.48037109375, 16.305712890625045],
                            [97.54199218749997, 16.505078124999983],
                            [97.575, 16.253222656250017],
                        ],
                    ],
                    [
                        [
                            [93.6908203125, 18.68427734375004],
                            [93.4875, 18.867529296875063],
                            [93.74472656250006, 18.865527343750017],
                            [93.6908203125, 18.68427734375004],
                        ],
                    ],
                    [
                        [
                            [93.71484374999997, 19.558251953124994],
                            [93.94570312500005, 19.428613281249966],
                            [93.90195312500012, 19.33203125],
                            [93.75585937500003, 19.325683593750057],
                            [93.64404296874997, 19.49506835937501],
                            [93.71484374999997, 19.558251953124994],
                        ],
                    ],
                    [
                        [
                            [93.49179687500012, 19.892578125],
                            [93.51328125000006, 19.754785156249994],
                            [93.41289062500002, 19.950341796875023],
                            [93.49179687500012, 19.892578125],
                        ],
                    ],
                    [
                        [
                            [93.01015625000005, 19.923925781249977],
                            [93.02324218750007, 19.82885742187497],
                            [92.91464843750006, 20.086474609375045],
                            [93.01015625000005, 19.923925781249977],
                        ],
                    ],
                    [
                        [
                            [101.1388671875001, 21.567480468749977],
                            [101.08037109375007, 21.468652343749994],
                            [100.703125, 21.251367187499966],
                            [100.613671875, 21.059326171875],
                            [100.56660156250004, 21.038183593750063],
                            [100.53613281250003, 20.992382812499955],
                            [100.52226562500007, 20.92192382812499],
                            [100.54931640624997, 20.884228515624955],
                            [100.61767578125003, 20.879248046875006],
                            [100.62294921875005, 20.859570312499983],
                            [100.5651367187501, 20.825097656249994],
                            [100.4074218750001, 20.823242187500057],
                            [100.32607421875005, 20.795703125000045],
                            [100.24931640625002, 20.730273437500045],
                            [100.18388671875002, 20.589111328125057],
                            [100.12968750000002, 20.372216796874994],
                            [100.12246093750005, 20.316650390625057],
                            [100.0036132812501, 20.37958984375001],
                            [99.9542968750001, 20.415429687500023],
                            [99.8903320312501, 20.424414062499977],
                            [99.72011718750005, 20.32543945312497],
                            [99.45888671875005, 20.363037109375],
                            [99.48593750000006, 20.14985351562501],
                            [99.07421875000003, 20.09936523437503],
                            [98.9166992187501, 19.77290039062504],
                            [98.37128906250004, 19.68916015625004],
                            [98.01503906250005, 19.74951171874997],
                            [97.816796875, 19.459960937500057],
                            [97.74589843750002, 18.58818359374999],
                            [97.37392578125, 18.51796875],
                            [97.63222656250005, 18.290332031250074],
                            [97.7064453125, 17.79711914062503],
                            [98.4388671875, 16.975683593750034],
                            [98.66074218750006, 16.330419921875006],
                            [98.83544921875003, 16.417578125],
                            [98.88828125000006, 16.351904296875034],
                            [98.81796875000012, 16.180810546874994],
                            [98.59238281250006, 16.05068359375005],
                            [98.55693359375007, 15.367675781249986],
                            [98.19101562500012, 15.204101562499972],
                            [98.20214843749997, 14.97592773437502],
                            [98.57001953125004, 14.359912109375031],
                            [99.13681640625006, 13.716699218749994],
                            [99.12392578125, 13.030761718750043],
                            [99.40507812500002, 12.547900390625003],
                            [99.61474609374997, 11.781201171875026],
                            [99.1901367187501, 11.105273437499989],
                            [98.7572265625, 10.660937499999974],
                            [98.70253906250005, 10.19038085937504],
                            [98.56259765625006, 10.034960937499989],
                            [98.46494140625006, 10.675830078124989],
                            [98.67558593750007, 10.986914062500034],
                            [98.74140625000004, 11.591699218749966],
                            [98.87597656250003, 11.719726562500028],
                            [98.63632812500006, 11.738378906250006],
                            [98.69628906250003, 12.225244140624994],
                            [98.6002929687501, 12.2453125],
                            [98.67871093749997, 12.348486328124963],
                            [98.57597656250002, 13.161914062500031],
                            [98.20039062500004, 13.980175781250026],
                            [98.14951171875012, 13.647607421875037],
                            [98.11064453125007, 13.712890625000014],
                            [98.10019531250006, 14.161523437500023],
                            [97.90976562500012, 14.652685546874991],
                            [98.01875, 14.652587890625057],
                            [97.81230468750007, 14.858935546874989],
                            [97.7103515625, 15.875537109375074],
                            [97.58427734375007, 16.019580078125017],
                            [97.72597656250005, 16.56855468750004],
                            [97.37587890625005, 16.52294921874997],
                            [97.20019531249997, 17.095410156249983],
                            [96.85146484375005, 17.401025390624994],
                            [96.90859375000005, 17.03095703125001],
                            [96.76542968750002, 16.710351562499966],
                            [96.43115234374997, 16.504931640625045],
                            [96.18906250000012, 16.768310546875057],
                            [96.32431640625006, 16.444433593750063],
                            [95.76328125000006, 16.169042968750006],
                            [95.38955078125005, 15.722753906250034],
                            [95.30146484375004, 15.756152343749989],
                            [95.34677734375012, 16.09760742187501],
                            [95.17695312500004, 15.825683593750028],
                            [94.9425781250001, 15.818261718750023],
                            [94.89316406250006, 16.182812499999955],
                            [94.66152343750005, 15.904394531250006],
                            [94.70332031250004, 16.511914062499955],
                            [94.4416015625001, 16.094384765624966],
                            [94.22382812500004, 16.016455078125006],
                            [94.58896484375006, 17.5693359375],
                            [94.17070312500007, 18.73242187499997],
                            [94.24570312500006, 18.741162109374983],
                            [94.07001953125004, 18.893408203125006],
                            [94.04492187500003, 19.287402343750074],
                            [93.92919921874997, 18.89965820312503],
                            [93.70546875000005, 19.026904296875017],
                            [93.49306640625005, 19.369482421875006],
                            [93.82490234375004, 19.238476562499955],
                            [93.99814453125006, 19.440869140624983],
                            [93.61171875000005, 19.776074218749983],
                            [93.70703125000003, 19.912158203125074],
                            [93.25, 20.070117187500017],
                            [93.12949218750012, 19.858007812500063],
                            [93.00195312499997, 20.074853515624994],
                            [93.06679687500005, 20.377636718749955],
                            [92.82832031250004, 20.177587890625063],
                            [92.89111328124997, 20.34033203125],
                            [92.73564453125007, 20.56269531250001],
                            [92.72285156250004, 20.29560546875004],
                            [92.32412109375, 20.791845703125063],
                            [92.17958984375005, 21.293115234375023],
                            [92.33056640624997, 21.439794921874977],
                            [92.63164062500002, 21.306201171875045],
                            [92.5934570312501, 21.46733398437499],
                            [92.58281250000002, 21.940332031249994],
                            [92.57490234375004, 21.978076171875045],
                            [92.68896484374997, 22.130957031250006],
                            [92.72099609375002, 22.132421875000063],
                            [92.77138671875, 22.104785156250017],
                            [92.9645507812501, 22.003759765625034],
                            [93.07060546875002, 22.20942382812501],
                            [93.16201171875, 22.360205078125006],
                            [93.07871093750006, 22.71821289062501],
                            [93.20390625000002, 23.03701171875005],
                            [93.34941406250007, 23.08496093750003],
                            [93.36601562500007, 23.132519531249955],
                            [93.32626953125006, 24.064208984375057],
                            [93.45214843750003, 23.987402343750034],
                            [93.68339843750007, 24.00654296875004],
                            [94.07480468750006, 23.8720703125],
                            [94.29306640625012, 24.321875],
                            [94.37724609375002, 24.473730468750006],
                            [94.49316406250003, 24.637646484374983],
                            [94.70371093750012, 25.097851562499955],
                            [94.55302734375007, 25.215722656249994],
                            [94.66777343750007, 25.458886718749966],
                            [94.99199218750002, 25.77045898437504],
                            [95.01523437500006, 25.912939453125006],
                            [95.0929687500001, 25.98730468749997],
                            [95.13242187500006, 26.041259765625057],
                            [95.12929687500005, 26.070410156250034],
                            [95.10839843749997, 26.091406250000034],
                            [95.06894531250006, 26.19111328125001],
                            [95.0597656250001, 26.473974609375006],
                            [95.20146484375007, 26.641406250000017],
                            [96.19082031250005, 27.26127929687499],
                            [96.79785156249997, 27.29619140624999],
                            [96.95341796875002, 27.13330078125003],
                            [97.10205078125003, 27.11542968750004],
                            [97.10371093750004, 27.16333007812503],
                            [96.90195312500012, 27.439599609374994],
                            [96.88359375000002, 27.514843749999955],
                            [96.96279296875, 27.698291015625017],
                            [97.04970703125005, 27.760009765625],
                            [97.34355468750002, 27.982324218749994],
                            [97.30273437499997, 28.08598632812496],
                            [97.3224609375001, 28.21796875000004],
                            [97.35644531249997, 28.254492187500006],
                            [97.43144531250002, 28.353906250000023],
                            [97.53789062500002, 28.510205078124983],
                            [97.59921875000006, 28.51704101562504],
                            [98.06162109375012, 28.185888671874977],
                            [98.29882812499997, 27.550097656250045],
                            [98.4525390625, 27.6572265625],
                            [98.65117187500007, 27.572460937499983],
                            [98.7384765625001, 26.785742187500006],
                            [98.68554687499997, 26.189355468750023],
                            [98.56406250000006, 26.072412109374994],
                            [98.65625, 25.86357421874999],
                            [98.33378906250007, 25.586767578125006],
                            [98.14287109375007, 25.571093750000017],
                            [98.01074218749997, 25.292529296875017],
                            [97.8195312500001, 25.251855468749994],
                            [97.73789062500006, 24.869873046875057],
                            [97.58330078125002, 24.77480468750005],
                            [97.53144531250004, 24.49169921875003],
                            [97.7082031250001, 24.228759765625],
                            [97.56455078125012, 23.911035156250023],
                            [98.2125, 24.110644531250017],
                            [98.83505859375006, 24.121191406250034],
                            [98.67675781250003, 23.905078125000045],
                            [98.8322265625001, 23.624365234374977],
                            [98.86376953125003, 23.191259765625034],
                            [99.41806640625006, 23.069238281250023],
                            [99.50712890625002, 22.959130859374994],
                            [99.19296875000006, 22.12597656249997],
                            [99.9176757812501, 22.02802734375001],
                            [99.94072265625007, 21.75874023437504],
                            [100.14765625000004, 21.480517578125017],
                            [100.60458984375012, 21.471777343750006],
                            [101.07978515625004, 21.75585937499997],
                            [101.1388671875001, 21.567480468749977],
                        ],
                    ],
                ],
            },
            properties: {
                name: "缅甸",
                childNum: 15,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [19.21875, 43.449951171875],
                        [19.670996093750006, 43.163964843749994],
                        [20.344335937500006, 42.827929687499996],
                        [20.054296875, 42.760058593749996],
                        [20.06396484375, 42.54726562499999],
                        [19.78828125000001, 42.476171875],
                        [19.65449218750001, 42.628564453124994],
                        [19.280664062500023, 42.17255859375],
                        [19.342382812500006, 41.869091796875],
                        [18.436328125000017, 42.559716796874994],
                        [18.5458984375, 42.6416015625],
                        [18.46601562500001, 42.777246093749994],
                        [18.44384765625, 42.96845703125],
                        [18.46015625000001, 42.997900390625],
                        [18.48847656250001, 43.012158203125],
                        [18.623632812500006, 43.027685546875],
                        [18.621875, 43.124609375],
                        [18.674218750000023, 43.230810546875],
                        [18.74921875000001, 43.283544921875],
                        [18.85107421875, 43.346337890624994],
                        [18.934667968750006, 43.339453125],
                        [18.97871093750001, 43.285400390625],
                        [19.026660156250017, 43.292431640625],
                        [19.03671875, 43.357324218749994],
                        [18.940234375000017, 43.496728515624994],
                        [18.95068359375, 43.526660156249996],
                        [18.97421875, 43.542333984375],
                        [19.0283203125, 43.532519531249996],
                        [19.080078125, 43.517724609374994],
                        [19.11279296875, 43.52773437499999],
                        [19.164355468750017, 43.535449218749996],
                        [19.1943359375, 43.53330078125],
                        [19.21875, 43.449951171875],
                    ],
                ],
            },
            properties: {
                name: "黑山",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [111.878125, 43.68017578125],
                        [111.00722656250002, 43.34140625],
                        [110.400390625, 42.773681640625],
                        [109.44316406249999, 42.455957031249994],
                        [109.33984375, 42.438378906249994],
                        [108.68730468749999, 42.41611328125],
                        [108.17119140624999, 42.447314453124996],
                        [106.77001953125, 42.288720703124994],
                        [105.86757812500002, 41.993994140625],
                        [105.31435546875002, 41.770898437499994],
                        [105.19707031249999, 41.738037109375],
                        [105.11542968750001, 41.66328125],
                        [105.05058593749999, 41.61591796875],
                        [104.98203125000003, 41.595507812499996],
                        [104.49824218750001, 41.65869140625],
                        [104.49824218750001, 41.877001953124996],
                        [104.30517578125, 41.846142578125],
                        [103.99726562500001, 41.79697265625],
                        [103.71113281250001, 41.751318359375],
                        [103.07285156250003, 42.00595703125],
                        [102.5751953125, 42.092089843749996],
                        [102.15664062500002, 42.158105468749994],
                        [101.97294921874999, 42.215869140624996],
                        [101.65996093749999, 42.500048828124996],
                        [101.5791015625, 42.52353515625],
                        [101.49531250000001, 42.53876953125],
                        [101.09199218750001, 42.551318359374996],
                        [100.51904296875, 42.616796875],
                        [100.08632812500002, 42.670751953125],
                        [99.98378906250002, 42.67734375],
                        [99.46787109375003, 42.568212890625],
                        [97.20566406250003, 42.789794921875],
                        [96.38544921875001, 42.720361328124994],
                        [95.85957031250001, 43.2759765625],
                        [95.52558593750001, 43.953955078125],
                        [95.32558593750002, 44.039355468749996],
                        [95.35029296875001, 44.278076171875],
                        [94.71201171875003, 44.350830078125],
                        [93.51621093750003, 44.944482421874994],
                        [92.78789062499999, 45.0357421875],
                        [92.57890624999999, 45.010986328125],
                        [92.423828125, 45.008935546874994],
                        [92.17265624999999, 45.03525390625],
                        [92.02978515625, 45.068505859374994],
                        [91.584375, 45.076513671875],
                        [91.05, 45.217431640624994],
                        [90.87724609374999, 45.19609375],
                        [90.66181640625001, 45.525244140625],
                        [91.00175781249999, 46.035791015624994],
                        [90.99677734375001, 46.10498046875],
                        [90.94755859374999, 46.177294921874996],
                        [90.91152343750002, 46.270654296874994],
                        [90.98574218750002, 46.7490234375],
                        [90.91054687500002, 46.883251953125],
                        [90.86992187499999, 46.954492187499994],
                        [90.79902343750001, 46.98515625],
                        [90.71552734375001, 47.003857421875],
                        [90.49619140625003, 47.28515625],
                        [90.42519531250002, 47.5041015625],
                        [90.34746093749999, 47.596972656249996],
                        [90.33066406250003, 47.655175781249994],
                        [90.31328124999999, 47.67617187499999],
                        [90.19101562500003, 47.702099609375],
                        [90.10322265625001, 47.745410156249996],
                        [90.02792968750003, 47.877685546875],
                        [89.95869140625001, 47.886328125],
                        [89.91044921874999, 47.8443359375],
                        [89.83134765624999, 47.823291015624996],
                        [89.778125, 47.827001953125],
                        [89.56093750000002, 48.003955078124996],
                        [89.47919921875001, 48.029052734375],
                        [89.04765624999999, 48.0025390625],
                        [88.97109375000002, 48.049951171874994],
                        [88.91777343749999, 48.089013671874994],
                        [88.83828125000002, 48.101708984374994],
                        [88.68183593750001, 48.170556640624994],
                        [88.57597656249999, 48.220166015625],
                        [88.56679687500002, 48.317431640624996],
                        [88.51708984375, 48.38447265625],
                        [88.41396484375002, 48.40341796875],
                        [88.30996093750002, 48.472070312499994],
                        [87.97968750000001, 48.555126953125],
                        [88.06005859375, 48.707177734374994],
                        [87.83183593749999, 48.791650390624994],
                        [87.7431640625, 48.881640625],
                        [87.87216796875003, 49.000146484374994],
                        [87.81630859375002, 49.0802734375],
                        [87.8251953125, 49.11630859375],
                        [87.81425781249999, 49.1623046875],
                        [87.93476562500001, 49.16455078125],
                        [87.98808593749999, 49.186914062499994],
                        [88.02851562500001, 49.219775390624996],
                        [88.11572265625, 49.256298828125],
                        [88.19257812500001, 49.451708984374996],
                        [88.63320312500002, 49.486132812499996],
                        [88.83164062500003, 49.4484375],
                        [88.86386718750003, 49.527636718749996],
                        [88.90019531249999, 49.539697265624994],
                        [88.94541015625003, 49.507666015625],
                        [88.97060546875002, 49.483740234375],
                        [89.00839843750003, 49.472802734374994],
                        [89.10947265625003, 49.501367187499994],
                        [89.17998046874999, 49.5322265625],
                        [89.20292968749999, 49.595703125],
                        [89.24394531249999, 49.62705078125],
                        [89.39560546875003, 49.6115234375],
                        [89.475, 49.66054687499999],
                        [89.57919921875003, 49.69970703125],
                        [89.65410156249999, 49.71748046875],
                        [89.64384765624999, 49.90302734375],
                        [90.0537109375, 50.09375],
                        [90.65507812499999, 50.22236328125],
                        [90.71435546875, 50.259423828124994],
                        [90.7607421875, 50.305957031249996],
                        [91.02158203125003, 50.415478515625],
                        [91.23378906250002, 50.452392578125],
                        [91.30058593749999, 50.46337890625],
                        [91.3408203125, 50.470068359375],
                        [91.4150390625, 50.468017578125],
                        [91.44648437500001, 50.52216796875],
                        [91.80429687500003, 50.693603515625],
                        [92.10400390625, 50.6919921875],
                        [92.1923828125, 50.700585937499994],
                        [92.35478515624999, 50.864160156249994],
                        [92.42636718750003, 50.803076171875],
                        [92.62666015625001, 50.68828125],
                        [92.68134765625001, 50.683203125],
                        [92.73867187500002, 50.7109375],
                        [92.779296875, 50.778662109375],
                        [92.8564453125, 50.789111328124996],
                        [92.94130859375002, 50.778222656249994],
                        [93.103125, 50.60390625],
                        [94.25107421875003, 50.556396484375],
                        [94.35468750000001, 50.221826171874994],
                        [94.61474609375, 50.023730468749996],
                        [94.67548828125001, 50.028076171875],
                        [94.71806640624999, 50.043261718749996],
                        [94.93027343750003, 50.04375],
                        [95.11142578125003, 49.935449218749994],
                        [95.52265625000001, 49.91123046875],
                        [96.06552734375003, 49.99873046875],
                        [96.31503906250003, 49.901123046875],
                        [96.98574218750002, 49.8828125],
                        [97.20859375000003, 49.730810546875],
                        [97.35976562500002, 49.741455078125],
                        [97.58935546875, 49.911474609375],
                        [98.00390625, 50.0142578125],
                        [98.25029296874999, 50.30244140625],
                        [98.27949218750001, 50.533251953124996],
                        [98.14501953125, 50.5685546875],
                        [98.07890624999999, 50.603808593749996],
                        [98.02978515625, 50.64462890625],
                        [97.82529296875003, 50.985253906249994],
                        [98.103125, 51.483544921874994],
                        [98.64052734375002, 51.801171875],
                        [98.89316406250003, 52.11728515625],
                        [99.92167968749999, 51.755517578125],
                        [100.03457031250002, 51.737109375],
                        [100.23037109375002, 51.729833984375],
                        [100.46894531250001, 51.72607421875],
                        [100.53623046875003, 51.7134765625],
                        [101.38125, 51.45263671875],
                        [101.57089843750003, 51.4671875],
                        [101.82119140625002, 51.421044921874994],
                        [102.11152343750001, 51.353466796875],
                        [102.15566406250002, 51.313769531249996],
                        [102.16005859375002, 51.26083984375],
                        [102.14238281249999, 51.216064453125],
                        [102.15195312500003, 51.10751953125],
                        [102.19453125000001, 51.050683593749994],
                        [102.21503906250001, 50.829443359375],
                        [102.31660156250001, 50.71845703125],
                        [102.28837890624999, 50.585107421874994],
                        [103.30439453125001, 50.20029296875],
                        [103.63291015625003, 50.138574218749994],
                        [103.72324218750003, 50.153857421874996],
                        [103.80263671875002, 50.176074218749996],
                        [104.07871093750003, 50.154248046875],
                        [105.38359374999999, 50.47373046875],
                        [106.21787109375003, 50.304589843749994],
                        [106.36845703124999, 50.317578125],
                        [106.57441406250001, 50.32880859375],
                        [106.71113281250001, 50.31259765625],
                        [106.94130859375002, 50.196679687499994],
                        [107.04023437500001, 50.086474609374996],
                        [107.14306640625, 50.033007812499996],
                        [107.23330078125002, 49.989404296874994],
                        [107.34707031250002, 49.986669921875],
                        [107.63095703125003, 49.98310546875],
                        [107.91660156250003, 49.947802734374996],
                        [107.96542968750003, 49.653515625],
                        [108.40693359375001, 49.396386718749994],
                        [108.5224609375, 49.34150390625],
                        [108.61367187500002, 49.322802734374996],
                        [109.23671875000002, 49.334912109375],
                        [109.45371093750003, 49.296337890625],
                        [109.52871093750002, 49.269873046875],
                        [110.19990234375001, 49.17041015625],
                        [110.42783203125003, 49.219970703125],
                        [110.70976562499999, 49.14296875],
                        [110.82792968749999, 49.166162109374994],
                        [111.20419921875003, 49.304296875],
                        [111.33662109375001, 49.35585937499999],
                        [111.42929687500003, 49.342626953125],
                        [112.07968750000003, 49.42421875],
                        [112.49492187499999, 49.53232421875],
                        [112.69736328125003, 49.507275390625],
                        [112.80644531249999, 49.523583984374994],
                        [112.91484374999999, 49.569238281249994],
                        [113.05556640625002, 49.616259765624996],
                        [113.09208984374999, 49.692529296874994],
                        [113.16416015625003, 49.797167968749996],
                        [113.31904296875001, 49.874316406249996],
                        [113.44550781250001, 49.9416015625],
                        [113.57421875, 50.00703125],
                        [114.29707031250001, 50.2744140625],
                        [114.7431640625, 50.233691406249996],
                        [115.00332031250002, 50.138574218749994],
                        [115.27451171875003, 49.948876953124994],
                        [115.36503906249999, 49.911767578124994],
                        [115.42919921875, 49.896484375],
                        [115.58798828125003, 49.886035156249996],
                        [115.7177734375, 49.880615234375],
                        [115.79521484374999, 49.905908203124994],
                        [115.92597656250001, 49.9521484375],
                        [116.13457031249999, 50.010791015624996],
                        [116.216796875, 50.00927734375],
                        [116.35117187500003, 49.978076171874996],
                        [116.55117187500002, 49.9203125],
                        [116.68330078125001, 49.823779296874996],
                        [115.82050781250001, 48.57724609375],
                        [115.79169921875001, 48.455712890624994],
                        [115.79658203125001, 48.346337890624994],
                        [115.78554687500002, 48.2482421875],
                        [115.63945312499999, 48.18623046875],
                        [115.52509765625001, 48.130859375],
                        [115.61640625000001, 47.874804687499996],
                        [115.89824218749999, 47.686914062499994],
                        [115.99384765625001, 47.71132812499999],
                        [116.07480468750003, 47.78955078125],
                        [116.23115234375001, 47.858203125],
                        [116.31718749999999, 47.85986328125],
                        [116.37822265624999, 47.844042968749996],
                        [116.51347656249999, 47.83955078125],
                        [116.65195312500003, 47.864501953125],
                        [116.76054687499999, 47.869775390624994],
                        [116.90117187499999, 47.853076171874996],
                        [116.95166015625, 47.836572265624994],
                        [117.06972656250002, 47.806396484375],
                        [117.28593749999999, 47.666357421875],
                        [117.35078125000001, 47.652197265625],
                        [117.76835937499999, 47.987890625],
                        [118.49843750000002, 47.983984375],
                        [118.56777343750002, 47.943261718749994],
                        [118.69052734375003, 47.822265625],
                        [118.75996093750001, 47.757617187499996],
                        [118.88027343750002, 47.72509765625],
                        [119.017578125, 47.685351562499996],
                        [119.08193359375002, 47.654150390625],
                        [119.71113281250001, 47.15],
                        [119.89785156250002, 46.8578125],
                        [119.8671875, 46.672167968749996],
                        [119.74746093750002, 46.627197265625],
                        [119.70664062500003, 46.606005859374996],
                        [119.62021484375003, 46.603955078125],
                        [119.47402343750002, 46.62666015625],
                        [119.33183593749999, 46.613818359374996],
                        [119.162109375, 46.638671875],
                        [118.95712890625003, 46.73486328125],
                        [118.84394531250001, 46.760205078125],
                        [118.79033203124999, 46.7470703125],
                        [118.72294921874999, 46.69189453125],
                        [118.64873046874999, 46.70166015625],
                        [118.58046875000002, 46.69189453125],
                        [118.40439453125003, 46.703173828124996],
                        [118.30869140625003, 46.717041015625],
                        [118.15683593750003, 46.678564453125],
                        [118.0712890625, 46.6666015625],
                        [117.7412109375, 46.5181640625],
                        [117.546875, 46.58828125],
                        [117.43808593750003, 46.586230468749996],
                        [117.40556640624999, 46.5708984375],
                        [117.39218750000003, 46.53759765625],
                        [117.35634765625002, 46.436669921874994],
                        [117.35693359375, 46.39130859375],
                        [117.33339843750002, 46.36201171875],
                        [116.85908203125001, 46.387939453125],
                        [116.56259765625003, 46.289794921875],
                        [116.21298828125003, 45.8869140625],
                        [116.22910156250003, 45.845751953124996],
                        [116.240625, 45.79599609375],
                        [116.19765625000002, 45.73935546875],
                        [115.68105468750002, 45.458251953125],
                        [115.16259765625, 45.390234375],
                        [114.91923828124999, 45.378271484375],
                        [114.73876953125, 45.41962890625],
                        [114.56015625000003, 45.389990234375],
                        [114.41914062500001, 45.202587890625],
                        [114.16738281250002, 45.049853515624996],
                        [114.08027343750001, 44.971142578125],
                        [113.87705078125003, 44.89619140625],
                        [113.65263671874999, 44.763476562499996],
                        [113.58701171875003, 44.745703125],
                        [113.04941406250003, 44.810351562499996],
                        [112.70673828125001, 44.883447265624994],
                        [112.59677734375003, 44.91767578125],
                        [112.49931640624999, 45.0109375],
                        [112.41132812500001, 45.058203125],
                        [112.11289062500003, 45.062939453125],
                        [112.03261718750002, 45.081640625],
                        [111.89804687500003, 45.0640625],
                        [111.40224609375002, 44.36728515625],
                        [111.93173828125003, 43.81494140625],
                        [111.878125, 43.68017578125],
                    ],
                ],
            },
            properties: {
                name: "蒙古",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [145.75195312499997, 15.133154296874991],
                        [145.71318359375007, 15.215283203125026],
                        [145.821875, 15.265380859375014],
                        [145.75195312499997, 15.133154296874991],
                    ],
                ],
            },
            properties: {
                name: "北马里亚纳群岛",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [32.112890625, -26.839453125],
                        [32.10595703125, -26.52001953125],
                        [32.04140625000002, -26.28125],
                        [32.060546875, -26.018359375],
                        [31.9482421875, -25.957617187500006],
                        [31.98583984375, -24.46064453125001],
                        [31.799609375000017, -23.8921875],
                        [31.54560546875001, -23.48232421875001],
                        [31.287890625000017, -22.40205078125001],
                        [31.429492187500017, -22.298828125],
                        [32.429785156250006, -21.29707031250001],
                        [32.353613281250006, -21.136523437500003],
                        [32.49238281250001, -20.659765625],
                        [32.992773437500006, -19.98486328125],
                        [32.77763671875002, -19.388769531250006],
                        [32.84980468750001, -19.10439453125001],
                        [32.69970703125, -18.94091796875],
                        [32.99306640625002, -18.35957031250001],
                        [32.87626953125002, -16.88359375],
                        [32.94804687500002, -16.71230468750001],
                        [31.939843750000023, -16.428808593750006],
                        [31.236230468750023, -16.02363281250001],
                        [30.437792968750017, -15.995312500000011],
                        [30.39609375, -15.64306640625],
                        [30.231835937500023, -14.990332031250006],
                        [33.201757812500006, -14.013378906250011],
                        [33.63642578125001, -14.568164062500003],
                        [34.375, -14.4248046875],
                        [34.50527343750002, -14.59814453125],
                        [34.54082031250002, -15.297265625],
                        [34.24609375, -15.829394531250003],
                        [34.528125, -16.319140625],
                        [34.93339843750002, -16.760351562500006],
                        [35.11210937500002, -16.898535156250006],
                        [35.06464843750001, -17.07861328125],
                        [35.124609375, -17.127246093750003],
                        [35.20136718750001, -17.13105468750001],
                        [35.272558593750006, -17.118457031250003],
                        [35.29042968750002, -17.096972656250003],
                        [35.28115234375002, -16.80781250000001],
                        [35.22978515625002, -16.639257812500006],
                        [35.178320312500006, -16.573339843750006],
                        [35.16718750000001, -16.56025390625001],
                        [35.242773437500006, -16.375390625],
                        [35.358496093750006, -16.160546875],
                        [35.59931640625001, -16.12587890625001],
                        [35.70888671875002, -16.095800781250006],
                        [35.75527343750002, -16.05830078125001],
                        [35.79121093750001, -15.958691406250011],
                        [35.89277343750001, -14.891796875000011],
                        [35.86669921875, -14.86376953125],
                        [35.84716796875, -14.6708984375],
                        [35.6904296875, -14.465527343750011],
                        [35.48847656250001, -14.201074218750009],
                        [35.37578125000002, -14.058691406250006],
                        [35.24746093750002, -13.896875],
                        [35.01386718750001, -13.643457031250009],
                        [34.61152343750001, -13.437890625],
                        [34.54570312500002, -13.21630859375],
                        [34.542578125, -13.108691406250003],
                        [34.35781250000002, -12.164746093750011],
                        [34.60625, -11.690039062500006],
                        [34.65957031250002, -11.588671875],
                        [34.82656250000002, -11.57568359375],
                        [34.95947265625, -11.578125],
                        [35.1826171875, -11.574804687500006],
                        [35.41826171875002, -11.583203125000011],
                        [35.50439453125, -11.604785156250003],
                        [35.56435546875002, -11.60234375],
                        [35.630957031250006, -11.58203125],
                        [35.78544921875002, -11.452929687500003],
                        [35.91132812500001, -11.4546875],
                        [36.08222656250001, -11.537304687500011],
                        [36.17548828125001, -11.609277343750009],
                        [36.19130859375002, -11.670703125],
                        [36.3056640625, -11.706347656250003],
                        [36.97890625000002, -11.566992187500006],
                        [37.37285156250002, -11.71044921875],
                        [37.54169921875001, -11.675097656250003],
                        [37.72480468750001, -11.580664062500006],
                        [37.92021484375002, -11.294726562500003],
                        [38.491796875, -11.413281250000011],
                        [38.9875, -11.167285156250003],
                        [39.81708984375001, -10.912402343750003],
                        [39.98867187500002, -10.82080078125],
                        [40.46357421875001, -10.46435546875],
                        [40.61171875000002, -10.661523437500009],
                        [40.48662109375002, -10.76513671875],
                        [40.59716796875, -10.830664062500006],
                        [40.40283203125, -11.33203125],
                        [40.53154296875002, -12.004589843750011],
                        [40.48710937500002, -12.4921875],
                        [40.58085937500002, -12.635546875],
                        [40.43681640625002, -12.983105468750011],
                        [40.56875, -12.984667968750003],
                        [40.595703125, -14.122851562500003],
                        [40.715625, -14.214453125],
                        [40.64609375, -14.538671875],
                        [40.775, -14.421289062500009],
                        [40.84453125000002, -14.718652343750009],
                        [40.617773437500006, -15.115527343750003],
                        [40.650976562500006, -15.260937500000011],
                        [39.98359375000001, -16.22548828125001],
                        [39.79091796875002, -16.29453125],
                        [39.84462890625002, -16.435644531250006],
                        [39.084375, -16.97285156250001],
                        [38.14492187500002, -17.242773437500006],
                        [37.24453125000002, -17.73994140625001],
                        [36.93935546875002, -17.993457031250003],
                        [36.40371093750002, -18.76972656250001],
                        [36.26289062500001, -18.71962890625001],
                        [36.23564453125002, -18.861328125],
                        [35.85371093750001, -18.99335937500001],
                        [34.947851562500006, -19.81269531250001],
                        [34.6494140625, -19.70136718750001],
                        [34.75576171875002, -19.82197265625001],
                        [34.705078125, -20.473046875],
                        [34.98232421875002, -20.80625],
                        [35.267675781250006, -21.650976562500006],
                        [35.31572265625002, -22.396875],
                        [35.38300781250001, -22.45458984375],
                        [35.45634765625002, -22.11591796875001],
                        [35.53007812500002, -22.248144531250006],
                        [35.57539062500001, -22.96308593750001],
                        [35.37041015625002, -23.79824218750001],
                        [35.5419921875, -23.82441406250001],
                        [35.48964843750002, -24.065527343750006],
                        [34.99208984375002, -24.65058593750001],
                        [32.96113281250001, -25.49042968750001],
                        [32.590429687500006, -26.00410156250001],
                        [32.84882812500001, -26.26806640625],
                        [32.95488281250002, -26.08359375],
                        [32.93359375, -26.25234375],
                        [32.88916015625, -26.83046875],
                        [32.88613281250002, -26.84931640625001],
                        [32.353515625, -26.861621093750003],
                        [32.19960937500002, -26.83349609375],
                        [32.112890625, -26.839453125],
                    ],
                ],
            },
            properties: {
                name: "莫桑比克",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-16.37333984374999, 19.706445312499994],
                            [-16.437548828124932, 19.609277343749994],
                            [-16.477001953124983, 19.710351562499994],
                            [-16.343652343749994, 19.86621093750003],
                            [-16.37333984374999, 19.706445312499994],
                        ],
                    ],
                    [
                        [
                            [-5.359912109374989, 16.282861328124994],
                            [-5.5125, 15.496289062499983],
                            [-9.293701171875, 15.502832031249994],
                            [-9.350585937499943, 15.677392578125023],
                            [-9.38535156249992, 15.667626953124994],
                            [-9.4265625, 15.623046875000057],
                            [-9.447705078124926, 15.574853515624994],
                            [-9.446923828124937, 15.458203124999955],
                            [-9.941406249999972, 15.373779296874986],
                            [-10.696582031249989, 15.42265625],
                            [-10.9482421875, 15.151123046875014],
                            [-11.455224609374994, 15.62539062499999],
                            [-11.760156249999937, 15.425537109375057],
                            [-11.828759765624966, 15.244873046875014],
                            [-11.872851562499989, 14.995166015625031],
                            [-12.02158203124992, 14.804931640625],
                            [-12.081542968749972, 14.766357421875057],
                            [-12.104687499999955, 14.745361328125043],
                            [-12.40869140625, 14.889013671874991],
                            [-12.735253906249994, 15.13125],
                            [-13.105273437499989, 15.57177734375],
                            [-13.40966796875, 16.059179687500006],
                            [-13.756640624999989, 16.172509765624994],
                            [-13.868457031249932, 16.14814453125001],
                            [-14.300097656249932, 16.58027343750001],
                            [-14.990625, 16.676904296874994],
                            [-15.768212890624994, 16.485107421875],
                            [-16.23901367187497, 16.53129882812499],
                            [-16.44101562499992, 16.20454101562504],
                            [-16.480078124999977, 16.097216796875017],
                            [-16.50205078124992, 15.917333984375063],
                            [-16.53525390624995, 15.838378906250057],
                            [-16.53574218749995, 16.28681640625001],
                            [-16.463623046875, 16.60151367187501],
                            [-16.030322265625017, 17.88793945312497],
                            [-16.213085937499926, 19.003320312500023],
                            [-16.51445312499996, 19.361962890624994],
                            [-16.305273437499977, 19.51264648437504],
                            [-16.44487304687499, 19.47314453124997],
                            [-16.21044921875003, 20.227929687500023],
                            [-16.42978515624995, 20.652343750000057],
                            [-16.622509765624955, 20.634179687499994],
                            [-16.87607421874992, 21.086132812499955],
                            [-16.998242187499926, 21.039697265625023],
                            [-17.048046874999955, 20.80615234375003],
                            [-17.06396484375, 20.89882812499999],
                            [-16.96455078125001, 21.329248046875023],
                            [-15.231201171875, 21.331298828125],
                            [-14.084667968749926, 21.33271484375001],
                            [-13.626025390624932, 21.33325195312503],
                            [-13.396728515624943, 21.333544921875017],
                            [-13.167431640624926, 21.333789062500074],
                            [-13.016210937499949, 21.33393554687501],
                            [-13.025097656249983, 21.46679687499997],
                            [-13.032226562500028, 21.572070312500017],
                            [-13.041748046875, 21.71381835937504],
                            [-13.051220703124983, 21.854785156250074],
                            [-13.094335937499977, 22.49599609375005],
                            [-13.153271484374983, 22.820507812499983],
                            [-13.031494140624943, 23.000244140625],
                            [-12.895996093749972, 23.08955078125001],
                            [-12.739599609375006, 23.192724609375063],
                            [-12.62041015624996, 23.271337890625006],
                            [-12.559375, 23.290820312500045],
                            [-12.372900390624977, 23.318017578124994],
                            [-12.023437499999943, 23.467578125000017],
                            [-12.016308593749983, 23.97021484375],
                            [-12.016308593749983, 24.378662109375],
                            [-12.016308593749983, 24.923242187499994],
                            [-12.016308593749983, 25.059375],
                            [-12.016308593749983, 25.331689453124994],
                            [-12.016308593749983, 25.740136718749994],
                            [-12.016308593749983, 25.995410156250017],
                            [-10.376123046874966, 25.995458984375034],
                            [-9.444531249999983, 25.99550781250005],
                            [-9.071923828124937, 25.99550781250005],
                            [-8.885644531249994, 25.99550781250005],
                            [-8.682226562499949, 25.99550781250005],
                            [-8.68212890625, 26.109472656250006],
                            [-8.68212890625, 26.273193359375057],
                            [-8.682324218749955, 26.49770507812505],
                            [-8.682617187500028, 26.723144531250057],
                            [-8.682861328124972, 26.92133789062501],
                            [-8.683349609375, 27.285937500000045],
                            [-4.822607421874949, 24.99560546875],
                            [-5.640771484374994, 24.99516601562499],
                            [-5.959814453124977, 24.994970703125063],
                            [-6.287207031249977, 24.99482421875001],
                            [-6.594091796874977, 24.99462890624997],
                            [-6.396582031249977, 23.274804687499994],
                            [-6.02641601562496, 20.04218750000001],
                            [-5.827099609374955, 18.301562500000045],
                            [-5.741699218749943, 17.555566406250023],
                            [-5.713183593750017, 17.306884765625],
                            [-5.684765624999983, 17.058251953124966],
                            [-5.628662109375028, 16.568652343750045],
                            [-5.50961914062492, 16.442041015625023],
                            [-5.359912109374989, 16.282861328124994],
                        ],
                    ],
                ],
            },
            properties: {
                name: "毛里塔尼亚",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-62.1484375, 16.74033203124999],
                        [-62.221630859375, 16.699511718750003],
                        [-62.191357421875, 16.804394531249997],
                        [-62.1484375, 16.74033203124999],
                    ],
                ],
            },
            properties: {
                name: "蒙特塞拉特",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [57.65126953125002, -20.48486328125],
                        [57.31767578125002, -20.42763671875001],
                        [57.416015625, -20.18378906250001],
                        [57.65654296875002, -19.98994140625001],
                        [57.7919921875, -20.21259765625001],
                        [57.65126953125002, -20.48486328125],
                    ],
                ],
            },
            properties: {
                name: "毛里求斯",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [34.95947265625003, -11.578125],
                        [34.82656250000005, -11.575683593749972],
                        [34.65957031250005, -11.58867187499996],
                        [34.61855468750005, -11.620214843749991],
                        [34.60625, -11.690039062500006],
                        [34.3578125, -12.164746093749997],
                        [34.542578125, -13.108691406250003],
                        [34.54570312500002, -13.21630859375],
                        [34.6115234375001, -13.437890625000023],
                        [35.0138671875001, -13.64345703124998],
                        [35.247460937499994, -13.896875],
                        [35.37578125000002, -14.05869140625002],
                        [35.48847656250004, -14.20107421874998],
                        [35.69042968749997, -14.465527343750026],
                        [35.84716796875003, -14.670898437500043],
                        [35.8927734375001, -14.891796875000011],
                        [35.7912109375001, -15.958691406250026],
                        [35.75527343750005, -16.058300781249983],
                        [35.708886718749994, -16.095800781249977],
                        [35.5993164062501, -16.12587890624998],
                        [35.35849609375006, -16.160546875000023],
                        [35.242773437500006, -16.375390625],
                        [35.16718750000004, -16.56025390625001],
                        [35.178320312500006, -16.57333984375002],
                        [35.22978515625002, -16.639257812500034],
                        [35.281152343749994, -16.8078125],
                        [35.29042968750005, -17.096972656250017],
                        [35.27255859375006, -17.11845703124996],
                        [35.2013671875001, -17.13105468750004],
                        [35.124609375, -17.127246093749974],
                        [35.06464843750004, -17.078613281250014],
                        [35.11210937500002, -16.898535156250006],
                        [34.93339843750002, -16.760351562500006],
                        [34.528125, -16.319140625],
                        [34.24609374999997, -15.829394531249974],
                        [34.54082031250002, -15.297265625],
                        [34.50527343750005, -14.598144531249957],
                        [34.375, -14.4248046875],
                        [33.63642578125004, -14.568164062499974],
                        [33.148046875, -13.94091796875],
                        [32.98125, -14.009375],
                        [32.797460937500006, -13.6884765625],
                        [32.67041015624997, -13.590429687500006],
                        [32.96757812500002, -13.225],
                        [32.97519531250006, -12.701367187499983],
                        [33.51230468750006, -12.347753906249977],
                        [33.340136718750074, -12.308300781250011],
                        [33.25234375000005, -12.112597656250031],
                        [33.3039062500001, -11.69082031249998],
                        [33.23271484375002, -11.417675781250026],
                        [33.26835937500002, -11.403906249999977],
                        [33.379785156249994, -11.15791015625004],
                        [33.29277343750002, -10.85234375],
                        [33.661523437499994, -10.553125],
                        [33.55371093749997, -10.391308593750011],
                        [33.53759765624997, -10.351562499999986],
                        [33.52890625, -10.234667968749974],
                        [33.31152343750003, -10.037988281249966],
                        [33.3371093750001, -9.954003906249994],
                        [33.350976562499994, -9.862207031250037],
                        [33.25, -9.759570312500003],
                        [33.148046875, -9.603515625],
                        [32.99599609375005, -9.622851562499946],
                        [32.91992187500003, -9.407421875000026],
                        [33.88886718750004, -9.670117187499983],
                        [33.99560546875003, -9.495410156250003],
                        [34.32089843750006, -9.731542968749977],
                        [34.56992187500006, -10.241113281249966],
                        [34.66708984375006, -10.792480468750028],
                        [34.60791015624997, -11.08046875],
                        [34.77382812500005, -11.341699218750009],
                        [34.890625, -11.3935546875],
                        [34.93701171874997, -11.463476562500034],
                        [34.95947265625003, -11.578125],
                    ],
                ],
            },
            properties: {
                name: "马拉维",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [111.38925781250006, 2.415332031250031],
                            [111.31152343749997, 2.437597656250034],
                            [111.33349609374997, 2.768310546875],
                            [111.38925781250006, 2.415332031250031],
                        ],
                    ],
                    [
                        [
                            [104.22158203125, 2.731738281250003],
                            [104.1291015625001, 2.767236328125037],
                            [104.18476562500004, 2.871728515625009],
                            [104.22158203125, 2.731738281250003],
                        ],
                    ],
                    [
                        [
                            [117.88476562499997, 4.186132812500006],
                            [117.64902343750012, 4.168994140624974],
                            [117.70800781249997, 4.262402343749997],
                            [117.88476562499997, 4.186132812500006],
                        ],
                    ],
                    [
                        [
                            [100.28896484375005, 5.294726562499989],
                            [100.19101562500006, 5.28286132812498],
                            [100.2455078125, 5.467773437499986],
                            [100.33886718749997, 5.410058593750037],
                            [100.28896484375005, 5.294726562499989],
                        ],
                    ],
                    [
                        [
                            [99.848046875, 6.465722656249994],
                            [99.9186523437501, 6.358593750000011],
                            [99.74375, 6.263281249999963],
                            [99.64628906250002, 6.418359375000023],
                            [99.848046875, 6.465722656249994],
                        ],
                    ],
                    [
                        [
                            [102.10107421874997, 6.242236328125031],
                            [102.34013671875002, 6.172021484375023],
                            [102.534375, 5.862548828125028],
                            [103.09707031250005, 5.408447265624986],
                            [103.41582031250007, 4.85029296875004],
                            [103.43945312499997, 2.93310546875],
                            [103.8122070312501, 2.58046875],
                            [104.21855468750002, 1.722851562499997],
                            [104.25009765625012, 1.388574218750009],
                            [104.11494140625004, 1.412255859375037],
                            [103.98144531250003, 1.623632812500034],
                            [103.99150390625002, 1.454785156249997],
                            [103.6945312500001, 1.449658203125026],
                            [103.48027343750007, 1.329492187499966],
                            [103.35683593750005, 1.546142578125057],
                            [102.72714843750012, 1.855566406250034],
                            [101.29550781250012, 2.885205078125011],
                            [101.29990234375012, 3.253271484375034],
                            [100.71542968750006, 3.966210937499966],
                            [100.79550781250012, 4.023388671874983],
                            [100.61455078125002, 4.3734375],
                            [100.34326171874997, 5.984179687500031],
                            [100.11914062499997, 6.441992187500048],
                            [100.26142578125004, 6.682714843749963],
                            [100.3454101562501, 6.549902343750006],
                            [100.75449218750012, 6.460058593749991],
                            [100.87392578125, 6.24541015624996],
                            [101.05351562500002, 6.242578125],
                            [100.98164062500004, 5.771044921875045],
                            [101.1139648437501, 5.636767578125045],
                            [101.5560546875, 5.907763671875003],
                            [101.67841796875004, 5.778808593750028],
                            [101.87363281250012, 5.825292968749991],
                            [102.10107421874997, 6.242236328125031],
                        ],
                    ],
                    [
                        [
                            [117.5744140625001, 4.17060546875004],
                            [117.10058593750003, 4.337060546875023],
                            [116.51474609375006, 4.370800781249969],
                            [115.86074218750005, 4.348046875000037],
                            [115.67880859375006, 4.193017578124994],
                            [115.45439453125002, 3.034326171875009],
                            [115.24697265625005, 3.025927734374989],
                            [115.117578125, 2.89487304687502],
                            [115.08076171875004, 2.63422851562504],
                            [115.1791015625, 2.523193359374972],
                            [114.78642578125002, 2.250488281250014],
                            [114.83056640625003, 1.980029296874989],
                            [114.5125, 1.452001953124963],
                            [113.90234375000003, 1.434277343749997],
                            [113.6222656250001, 1.2359375],
                            [113.00654296875004, 1.433886718750003],
                            [112.94296875000006, 1.566992187500034],
                            [112.47617187500006, 1.559082031250028],
                            [112.1857421875001, 1.4390625],
                            [112.078515625, 1.143359374999974],
                            [111.80898437500005, 1.011669921874969],
                            [111.10136718750002, 1.050537109374986],
                            [110.50576171875005, 0.861962890625023],
                            [109.65400390625004, 1.614892578125023],
                            [109.53896484375, 1.89619140625004],
                            [109.62890625000003, 2.027539062499983],
                            [109.86484375000012, 1.764453125000031],
                            [110.34921875000012, 1.719726562499972],
                            [111.22324218750012, 1.395849609374991],
                            [111.0287109375, 1.557812500000026],
                            [111.26816406250012, 2.13974609375002],
                            [111.20859375000012, 2.379638671875043],
                            [111.44384765625003, 2.381542968749983],
                            [111.5125, 2.743017578124991],
                            [112.98789062500006, 3.161914062499974],
                            [113.92392578125006, 4.243212890625003],
                            [114.0638671875, 4.592675781249966],
                            [114.65410156250007, 4.037646484375045],
                            [114.84023437500005, 4.393212890625009],
                            [114.74667968750006, 4.718066406250017],
                            [115.02675781250005, 4.899707031249989],
                            [115.10703125000006, 4.390429687499974],
                            [115.290625, 4.352587890624989],
                            [115.1400390625, 4.899755859374991],
                            [115.37490234375, 4.932763671874966],
                            [115.55449218750007, 5.093554687500045],
                            [115.41904296875012, 5.413183593749963],
                            [115.60390625, 5.603417968749994],
                            [115.74082031250012, 5.533007812500045],
                            [115.8771484375001, 5.613525390625014],
                            [116.74980468750007, 6.977099609374989],
                            [116.8498046875001, 6.826708984374989],
                            [116.78808593749997, 6.606103515624994],
                            [117.12851562500012, 6.968896484375009],
                            [117.2298828125, 6.939990234374974],
                            [117.29404296875006, 6.676904296875023],
                            [117.60966796875002, 6.512646484375054],
                            [117.69375, 6.35],
                            [117.64453124999997, 6.001855468749994],
                            [117.5011718750001, 5.884667968750009],
                            [118.00380859375, 6.053320312499991],
                            [118.11582031250006, 5.8625],
                            [117.93476562500004, 5.7875],
                            [117.97363281249997, 5.70625],
                            [118.35312500000012, 5.80605468749998],
                            [118.59482421875006, 5.592089843750003],
                            [119.22343750000007, 5.412646484375031],
                            [119.2663085937501, 5.308105468750057],
                            [119.21962890625, 5.159814453125037],
                            [118.9125, 5.02290039062504],
                            [118.26054687500007, 4.988867187500034],
                            [118.18535156250002, 4.828515625000051],
                            [118.5625, 4.502148437499997],
                            [118.54833984375003, 4.379248046875006],
                            [118.008203125, 4.250244140625014],
                            [117.6964843750001, 4.342822265625045],
                            [117.5744140625001, 4.17060546875004],
                        ],
                    ],
                    [
                        [
                            [117.14160156250003, 7.168212890625028],
                            [117.08066406250006, 7.115283203124989],
                            [117.06425781250007, 7.26069335937504],
                            [117.2640625, 7.351660156250006],
                            [117.26679687500004, 7.220800781249991],
                            [117.14160156250003, 7.168212890625028],
                        ],
                    ],
                ],
            },
            properties: {
                name: "马来西亚",
                childNum: 8,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [23.380664062500017, -17.640625],
                        [24.27490234375, -17.481054687500006],
                        [24.73291015625, -17.51777343750001],
                        [25.001757812500017, -17.56855468750001],
                        [25.2587890625, -17.793554687500006],
                        [24.909082031250023, -17.821386718750006],
                        [24.530566406250017, -18.052734375],
                        [24.243945312500017, -18.0234375],
                        [23.599707031250006, -18.4599609375],
                        [23.219335937500006, -17.99970703125001],
                        [20.97412109375, -18.31884765625],
                        [20.9794921875, -21.9619140625],
                        [19.977343750000017, -22.00019531250001],
                        [19.98046875, -24.77675781250001],
                        [19.98046875, -28.310351562500003],
                        [19.98046875, -28.451269531250006],
                        [19.539843750000017, -28.574609375],
                        [19.31269531250001, -28.73330078125001],
                        [19.24580078125001, -28.901660156250003],
                        [19.16171875, -28.938769531250003],
                        [18.310839843750017, -28.88623046875],
                        [17.44794921875001, -28.69814453125001],
                        [17.34785156250001, -28.50117187500001],
                        [17.358691406250017, -28.26943359375001],
                        [17.1884765625, -28.13251953125001],
                        [17.05625, -28.031054687500003],
                        [16.93330078125001, -28.069628906250003],
                        [16.875292968750017, -28.1279296875],
                        [16.841210937500023, -28.21894531250001],
                        [16.81015625, -28.26455078125001],
                        [16.7875, -28.39472656250001],
                        [16.755761718750023, -28.4521484375],
                        [16.62617187500001, -28.487890625],
                        [16.487109375000017, -28.572851562500006],
                        [16.447558593750017, -28.617578125],
                        [15.719042968750017, -27.9658203125],
                        [15.341503906250011, -27.386523437500003],
                        [15.139062500000023, -26.50800781250001],
                        [14.9677734375, -26.31806640625001],
                        [14.837109375000011, -25.033203125],
                        [14.5015625, -24.201953125],
                        [14.462792968750023, -22.44912109375001],
                        [13.450585937500023, -20.91669921875001],
                        [13.168359375000023, -20.184667968750006],
                        [12.458203125000011, -18.9267578125],
                        [11.77587890625, -18.001757812500003],
                        [11.733496093750006, -17.7509765625],
                        [11.743066406250023, -17.24921875000001],
                        [11.902539062500011, -17.2265625],
                        [12.013964843750017, -17.168554687500006],
                        [12.21337890625, -17.2099609375],
                        [12.318457031250006, -17.21337890625],
                        [12.359277343750023, -17.205859375],
                        [12.548144531250017, -17.212695312500003],
                        [13.179492187500017, -16.9716796875],
                        [13.475976562500023, -17.0400390625],
                        [14.017480468750023, -17.40888671875001],
                        [16.1484375, -17.390234375],
                        [18.396386718750023, -17.3994140625],
                        [18.95527343750001, -17.803515625],
                        [20.1943359375, -17.863671875],
                        [20.745507812500023, -18.01972656250001],
                        [22.32421875, -17.8375],
                        [23.380664062500017, -17.640625],
                    ],
                ],
            },
            properties: {
                name: "纳米比亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [167.54443359375003, -22.62324218750001],
                            [167.44375, -22.63916015624997],
                            [167.44345703125006, -22.541406250000037],
                            [167.54443359375003, -22.62324218750001],
                        ],
                    ],
                    [
                        [
                            [168.01093750000004, -21.429980468750017],
                            [168.1390625, -21.44521484375001],
                            [168.12070312500012, -21.615820312500034],
                            [167.96679687500003, -21.641601562499957],
                            [167.81542968749997, -21.392675781249963],
                            [167.9884765625001, -21.337890624999986],
                            [168.01093750000004, -21.429980468750017],
                        ],
                    ],
                    [
                        [
                            [167.40087890625003, -21.16064453125003],
                            [167.07265625, -20.99726562499997],
                            [167.03271484374997, -20.922558593750026],
                            [167.18945312500003, -20.803515625000017],
                            [167.05576171875012, -20.720214843750014],
                            [167.29794921875006, -20.732519531250034],
                            [167.40087890625003, -21.16064453125003],
                        ],
                    ],
                    [
                        [
                            [164.20234375000004, -20.246093749999957],
                            [164.4359375, -20.282226562499957],
                            [165.191796875, -20.768847656249974],
                            [165.66279296875004, -21.267187499999977],
                            [166.94238281250003, -22.09013671875003],
                            [166.97031250000012, -22.32285156250002],
                            [166.77412109375004, -22.37617187500004],
                            [166.4679687500001, -22.256054687499997],
                            [164.92744140625004, -21.289843749999974],
                            [164.16972656250007, -20.48017578125004],
                            [164.05966796875012, -20.141503906249966],
                            [164.20234375000004, -20.246093749999957],
                        ],
                    ],
                ],
            },
            properties: {
                name: "新喀里多尼亚",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [14.97900390625, 22.99619140624999],
                        [15.181835937500011, 21.523388671874997],
                        [15.607324218750023, 20.954394531250003],
                        [15.587109375000011, 20.733300781249994],
                        [15.963183593750017, 20.34619140625],
                        [15.735058593750011, 19.904052734375],
                        [15.474316406250011, 16.908398437499997],
                        [14.367968750000017, 15.750146484374994],
                        [13.4482421875, 14.380664062500003],
                        [13.505761718750023, 14.134423828124994],
                        [13.606347656250023, 13.70458984375],
                        [13.426953125000011, 13.701757812499991],
                        [13.323828125, 13.670849609374997],
                        [12.871679687500006, 13.449023437500003],
                        [12.65478515625, 13.3265625],
                        [12.463183593750017, 13.09375],
                        [10.958886718750023, 13.371533203124997],
                        [10.475878906250017, 13.330224609374994],
                        [10.229589843750006, 13.281005859375],
                        [10.184667968750006, 13.270117187499991],
                        [9.615917968750011, 12.810644531249991],
                        [9.201562500000023, 12.821484375],
                        [8.750585937500006, 12.908154296874997],
                        [8.4560546875, 13.059667968749991],
                        [8.095019531250017, 13.291162109374994],
                        [7.955761718750011, 13.32275390625],
                        [7.788671875, 13.337890625],
                        [7.056738281250006, 13.000195312499997],
                        [6.804296875, 13.107666015625],
                        [6.2998046875, 13.658789062499991],
                        [6.184277343750011, 13.66367187499999],
                        [5.838183593750017, 13.765380859375],
                        [5.491992187500017, 13.872851562500003],
                        [5.415820312500017, 13.859179687500003],
                        [5.361621093750017, 13.836865234374997],
                        [5.241894531250011, 13.757226562499994],
                        [4.664843750000017, 13.733203125],
                        [4.147558593750006, 13.457714843749997],
                        [3.947851562500006, 12.775048828124994],
                        [3.646679687500011, 12.529980468749997],
                        [3.595410156250011, 11.6962890625],
                        [2.805273437500006, 12.383837890624989],
                        [2.366015625000017, 12.221923828125],
                        [2.38916015625, 11.897070312499991],
                        [2.072949218750011, 12.309375],
                        [2.226269531250011, 12.466064453125],
                        [2.104589843750006, 12.701269531249991],
                        [1.56494140625, 12.635400390624994],
                        [0.9873046875, 13.041894531249994],
                        [0.988476562500011, 13.36484375],
                        [1.201171875, 13.357519531249991],
                        [0.6181640625, 13.703417968750003],
                        [0.42919921875, 13.972119140624997],
                        [0.382519531250011, 14.245800781249997],
                        [0.163867187500017, 14.497216796874994],
                        [0.217480468750011, 14.911474609374991],
                        [0.947460937500011, 14.982128906249997],
                        [1.300195312500023, 15.272265625],
                        [3.001074218750006, 15.340966796874994],
                        [3.06015625, 15.427197265624997],
                        [3.504296875000023, 15.356347656249994],
                        [3.5205078125, 15.483105468749997],
                        [3.842968750000011, 15.701708984375003],
                        [4.121289062500011, 16.357714843750003],
                        [4.234667968750017, 16.996386718750003],
                        [4.227636718750006, 19.142773437499997],
                        [5.836621093750011, 19.479150390624994],
                        [7.481738281250017, 20.873095703125003],
                        [11.967871093750006, 23.517871093750003],
                        [13.48125, 23.18017578125],
                        [14.215527343750011, 22.619677734375003],
                        [14.97900390625, 22.99619140624999],
                    ],
                ],
            },
            properties: {
                name: "尼日尔",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [7.30078125, 4.418164062500026],
                            [7.140429687500017, 4.395117187500034],
                            [7.227343750000045, 4.527343749999972],
                            [7.30078125, 4.418164062500026],
                        ],
                    ],
                    [
                        [
                            [6.804296875, 13.107666015625],
                            [7.056738281250006, 13.00019531250004],
                            [7.788671875, 13.337890625],
                            [7.955761718750011, 13.322753906250028],
                            [8.095019531250045, 13.29116210937498],
                            [8.750585937500034, 12.908154296875026],
                            [9.20156250000008, 12.82148437500004],
                            [9.615917968750011, 12.810644531249963],
                            [10.184667968750063, 13.270117187499963],
                            [10.229589843749977, 13.281005859375043],
                            [10.475878906250074, 13.330224609375037],
                            [10.958886718750051, 13.371533203125011],
                            [12.463183593750017, 13.09375],
                            [12.654785156250057, 13.3265625],
                            [13.426953125000068, 13.701757812499963],
                            [13.606347656250023, 13.704589843750014],
                            [13.932324218750011, 13.258496093749997],
                            [14.06396484375, 13.078515625],
                            [14.160058593750023, 12.612792968749986],
                            [14.184863281250017, 12.447216796874997],
                            [14.272851562500023, 12.356494140624989],
                            [14.518945312500051, 12.298242187500023],
                            [14.619726562500063, 12.150976562500048],
                            [14.559765625000011, 11.492285156249963],
                            [14.20234375000004, 11.268164062499963],
                            [14.143261718750068, 11.248535156250043],
                            [14.056738281250034, 11.245019531250037],
                            [13.981445312500057, 11.21186523437504],
                            [13.892089843750057, 11.140087890624983],
                            [13.699902343749983, 10.873144531250048],
                            [13.53535156250004, 10.605078124999963],
                            [13.414550781250028, 10.171435546874989],
                            [13.269921875000051, 10.036181640624974],
                            [13.198730468750028, 9.563769531250003],
                            [12.929492187500074, 9.426269531249972],
                            [12.87568359375004, 9.303515625000017],
                            [12.80654296875008, 8.886621093749994],
                            [12.7822265625, 8.817871093750014],
                            [12.651562500000011, 8.667773437499989],
                            [12.40351562500004, 8.59555664062502],
                            [12.311328125000074, 8.419726562499989],
                            [12.2333984375, 8.282324218749977],
                            [12.016015625000051, 7.589746093750009],
                            [11.809179687500006, 7.345068359374991],
                            [11.767382812500017, 7.272265624999989],
                            [11.861425781249977, 7.11640625000004],
                            [11.657519531250017, 6.951562500000023],
                            [11.580078125000057, 6.88886718750004],
                            [11.551660156250023, 6.697265625],
                            [11.153320312500057, 6.437939453125011],
                            [11.1064453125, 6.457714843750054],
                            [11.032519531250045, 6.697900390625037],
                            [10.954199218750006, 6.7765625],
                            [10.60625, 7.063085937500006],
                            [10.413183593750006, 6.877734375],
                            [10.293066406250034, 6.876757812499974],
                            [10.205468750000051, 6.891601562499986],
                            [10.185546874999972, 6.91279296875004],
                            [10.167773437500017, 6.959179687499983],
                            [10.143554687500057, 6.99643554687502],
                            [10.038867187500045, 6.921386718750014],
                            [9.874218750000068, 6.803271484375017],
                            [9.82070312500008, 6.783935546874986],
                            [9.779882812500034, 6.760156250000023],
                            [9.725585937499972, 6.65],
                            [9.659960937500017, 6.531982421874986],
                            [9.490234375, 6.418652343749997],
                            [8.997167968750006, 5.917724609375],
                            [8.715625, 5.046875],
                            [8.514843750000068, 4.724707031250034],
                            [8.23378906250008, 4.907470703124972],
                            [8.293066406250006, 4.557617187500014],
                            [7.644238281250068, 4.525341796875011],
                            [7.530761718750028, 4.655175781249994],
                            [7.284375, 4.547656250000031],
                            [7.076562500000051, 4.716162109374991],
                            [7.15468750000008, 4.514404296875],
                            [6.92324218750008, 4.390673828125017],
                            [6.767675781250006, 4.724707031250034],
                            [6.860351562500057, 4.373339843750045],
                            [6.633007812500011, 4.340234375000051],
                            [6.579980468750051, 4.475976562499994],
                            [6.554589843750023, 4.34140625000002],
                            [6.263671875, 4.309423828124991],
                            [6.270996093749972, 4.432128906250028],
                            [6.173339843749972, 4.277392578125031],
                            [5.970703125, 4.338574218749983],
                            [5.587792968750051, 4.647216796874972],
                            [5.448144531250023, 4.945849609374974],
                            [5.383300781250057, 5.129003906249977],
                            [5.475976562500023, 5.153857421874989],
                            [5.370019531250023, 5.195019531250026],
                            [5.367968750000045, 5.337744140624963],
                            [5.549707031250023, 5.474218749999963],
                            [5.385839843750034, 5.401757812500037],
                            [5.199218750000028, 5.533544921874977],
                            [5.456640624999977, 5.61171875],
                            [5.327343750000011, 5.707519531249986],
                            [5.112402343750034, 5.64155273437504],
                            [4.861035156250068, 6.026318359374997],
                            [4.431347656250011, 6.348583984375026],
                            [3.450781249999977, 6.427050781250017],
                            [3.71699218750004, 6.597949218750017],
                            [3.430175781250057, 6.525],
                            [3.335546875000063, 6.396923828125011],
                            [2.706445312500051, 6.369238281249963],
                            [2.735644531250045, 6.595703125],
                            [2.753710937499989, 6.661767578124966],
                            [2.774609374999983, 6.711718750000017],
                            [2.752929687500028, 6.771630859374966],
                            [2.731738281250045, 6.852832031249989],
                            [2.721386718750068, 6.980273437500017],
                            [2.75673828125008, 7.067919921875017],
                            [2.750488281250057, 7.39506835937496],
                            [2.765820312500068, 7.422509765625051],
                            [2.783984375000045, 7.443408203125045],
                            [2.78515625, 7.476855468750017],
                            [2.703125, 8.371826171875],
                            [2.774804687500023, 9.048535156250026],
                            [3.044921875, 9.08383789062502],
                            [3.325195312499972, 9.778466796875051],
                            [3.60205078125, 10.004541015625009],
                            [3.646582031250006, 10.408984374999989],
                            [3.771777343750017, 10.417626953124966],
                            [3.83447265625, 10.607421875],
                            [3.7568359375, 10.76875],
                            [3.71640625, 11.07958984375],
                            [3.695312499999972, 11.12031250000004],
                            [3.63886718750004, 11.176855468750006],
                            [3.487792968749972, 11.395410156250037],
                            [3.490527343750017, 11.499218750000054],
                            [3.55390625000004, 11.631884765624989],
                            [3.595410156250068, 11.696289062500057],
                            [3.664746093750068, 11.762451171875028],
                            [3.646679687500011, 12.529980468749983],
                            [3.947851562500006, 12.775048828124994],
                            [4.147558593750006, 13.457714843749983],
                            [4.664843750000045, 13.733203124999974],
                            [5.241894531250011, 13.757226562499994],
                            [5.361621093750074, 13.836865234375054],
                            [5.415820312500017, 13.859179687499974],
                            [5.491992187500074, 13.872851562500003],
                            [6.2998046875, 13.658789062500006],
                            [6.804296875, 13.107666015625],
                        ],
                    ],
                ],
            },
            properties: {
                name: "尼日利亚",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-83.6419921875, 10.917236328125],
                        [-83.71293945312499, 10.785888671875],
                        [-83.91928710937499, 10.7353515625],
                        [-84.6341796875, 11.045605468749997],
                        [-84.9091796875, 10.9453125],
                        [-85.5841796875, 11.189453125],
                        [-85.7443359375, 11.06210937499999],
                        [-87.670166015625, 12.965673828124991],
                        [-87.58505859374999, 13.043310546874991],
                        [-87.42436523437499, 12.921142578125],
                        [-87.33725585937499, 12.979248046875],
                        [-87.05917968749999, 12.991455078125],
                        [-87.00932617187499, 13.0078125],
                        [-86.918212890625, 13.223583984374997],
                        [-86.87353515625, 13.266503906249994],
                        [-86.792138671875, 13.27978515625],
                        [-86.72929687499999, 13.284375],
                        [-86.710693359375, 13.313378906249994],
                        [-86.76352539062499, 13.63525390625],
                        [-86.77060546874999, 13.69873046875],
                        [-86.758984375, 13.746142578125003],
                        [-86.733642578125, 13.763476562500003],
                        [-86.61025390625, 13.774853515624997],
                        [-86.376953125, 13.755664062500003],
                        [-86.33173828125, 13.770068359375003],
                        [-86.238232421875, 13.899462890625003],
                        [-86.15122070312499, 13.994580078124997],
                        [-86.0892578125, 14.037207031249991],
                        [-86.04038085937499, 14.050146484374991],
                        [-85.9837890625, 13.965673828124991],
                        [-85.78671875, 13.844433593749997],
                        [-85.75341796875, 13.85205078125],
                        [-85.73393554687499, 13.858691406250003],
                        [-85.727734375, 13.876074218749991],
                        [-85.731201171875, 13.931835937499997],
                        [-85.68193359374999, 13.982568359374994],
                        [-85.20834960937499, 14.311816406250003],
                        [-85.059375, 14.582958984374997],
                        [-84.86044921874999, 14.809765625],
                        [-84.645947265625, 14.661083984374997],
                        [-84.53764648437499, 14.633398437499991],
                        [-83.635498046875, 14.876416015624997],
                        [-83.5365234375, 14.977001953124997],
                        [-83.4150390625, 15.008056640625],
                        [-83.15751953124999, 14.993066406249994],
                        [-83.18535156249999, 14.956396484374991],
                        [-83.21591796874999, 14.932373046875],
                        [-83.27988281249999, 14.812792968750003],
                        [-83.344384765625, 14.902099609375],
                        [-83.413720703125, 14.825341796874994],
                        [-83.29921875, 14.7490234375],
                        [-83.187744140625, 14.340087890625],
                        [-83.4123046875, 13.99648437499999],
                        [-83.567333984375, 13.3203125],
                        [-83.5109375, 12.411816406249997],
                        [-83.627197265625, 12.459326171874991],
                        [-83.59335937499999, 12.713085937499997],
                        [-83.75424804687499, 12.501953125],
                        [-83.680419921875, 12.024316406249994],
                        [-83.7671875, 12.059277343749997],
                        [-83.82890624999999, 11.861035156249997],
                        [-83.70458984375, 11.824560546874991],
                        [-83.6517578125, 11.642041015624997],
                        [-83.86787109375, 11.300048828125],
                        [-83.6419921875, 10.917236328125],
                    ],
                ],
            },
            properties: {
                name: "尼加拉瓜",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-169.80341796875, -19.0830078125],
                        [-169.94833984375, -19.072851562500006],
                        [-169.834033203125, -18.96601562500001],
                        [-169.80341796875, -19.0830078125],
                    ],
                ],
            },
            properties: {
                name: "纽埃",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-68.205810546875, 12.144580078124989],
                            [-68.25434570312495, 12.032080078124977],
                            [-68.36923828125, 12.301953124999983],
                            [-68.205810546875, 12.144580078124989],
                        ],
                    ],
                    [
                        [
                            [4.226171875000034, 51.38647460937503],
                            [3.902050781250011, 51.20766601562502],
                            [3.43251953125008, 51.24575195312505],
                            [3.35009765625, 51.37768554687503],
                            [4.226171875000034, 51.38647460937503],
                        ],
                    ],
                    [
                        [
                            [3.94912109375008, 51.73945312500001],
                            [4.07509765625008, 51.648779296875006],
                            [3.699023437500017, 51.70991210937501],
                            [3.94912109375008, 51.73945312500001],
                        ],
                    ],
                    [
                        [
                            [4.886132812500023, 53.07070312500005],
                            [4.70917968750004, 53.036035156249994],
                            [4.886425781249983, 53.18330078124998],
                            [4.886132812500023, 53.07070312500005],
                        ],
                    ],
                    [
                        [
                            [4.226171875000034, 51.38647460937503],
                            [3.448925781250068, 51.54077148437503],
                            [3.743945312500017, 51.596044921875006],
                            [4.27412109375004, 51.47163085937498],
                            [4.004785156250051, 51.595849609374966],
                            [4.182617187500057, 51.61030273437498],
                            [3.946875, 51.810546875],
                            [4.482812500000023, 52.30917968749998],
                            [4.76875, 52.941308593749966],
                            [5.061230468750068, 52.96064453125001],
                            [5.532031250000074, 53.268701171874966],
                            [6.062207031250068, 53.407080078125006],
                            [6.816210937500045, 53.44116210937503],
                            [7.197265625000028, 53.28227539062499],
                            [7.033007812500045, 52.65136718749997],
                            [6.710742187500045, 52.61787109374998],
                            [6.748828125000074, 52.464013671874994],
                            [7.035156250000057, 52.38022460937498],
                            [6.724511718749994, 52.080224609374966],
                            [6.800390625, 51.96738281249998],
                            [5.948730468750057, 51.80268554687501],
                            [6.198828125000034, 51.45],
                            [6.129980468750034, 51.14741210937501],
                            [5.857519531250034, 51.030126953125006],
                            [6.048437500000034, 50.90488281250006],
                            [5.993945312500017, 50.75043945312504],
                            [5.693554687500011, 50.774755859375006],
                            [5.796484375000034, 51.153076171875],
                            [5.214160156250045, 51.278955078124966],
                            [5.03095703125004, 51.46909179687498],
                            [4.226171875000034, 51.38647460937503],
                        ],
                    ],
                    [
                        [
                            [5.325781250000063, 53.38574218750003],
                            [5.190234375000074, 53.39179687500001],
                            [5.582617187500063, 53.438085937500034],
                            [5.325781250000063, 53.38574218750003],
                        ],
                    ],
                ],
            },
            properties: {
                name: "荷兰",
                childNum: 6,
                cp: [5.0752777, 52.358465],
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [5.085839843750023, 60.30756835937501],
                            [5.089062500000068, 60.188769531250045],
                            [4.95722656250004, 60.44726562500006],
                            [5.085839843750023, 60.30756835937501],
                        ],
                    ],
                    [
                        [
                            [4.958691406250068, 61.084570312500034],
                            [4.79902343750004, 61.08271484375001],
                            [4.861621093749989, 61.19384765625],
                            [4.958691406250068, 61.084570312500034],
                        ],
                    ],
                    [
                        [
                            [8.10273437500004, 63.33759765625004],
                            [7.804003906250017, 63.413916015625034],
                            [8.073535156250045, 63.47080078124998],
                            [8.10273437500004, 63.33759765625004],
                        ],
                    ],
                    [
                        [
                            [8.470800781250063, 63.66713867187502],
                            [8.287109375000028, 63.68715820312502],
                            [8.764648437500057, 63.804638671874955],
                            [8.78652343750008, 63.703466796875034],
                            [8.470800781250063, 63.66713867187502],
                        ],
                    ],
                    [
                        [
                            [11.2314453125, 64.865869140625],
                            [10.739843750000034, 64.87031250000001],
                            [11.02099609375, 64.97871093749995],
                            [11.2314453125, 64.865869140625],
                        ],
                    ],
                    [
                        [
                            [12.971777343750063, 67.87412109375],
                            [12.824023437500074, 67.82124023437498],
                            [13.068066406250068, 68.07133789062505],
                            [12.971777343750063, 67.87412109375],
                        ],
                    ],
                    [
                        [
                            [13.872851562500045, 68.26533203125004],
                            [14.096777343750034, 68.218603515625],
                            [13.229394531250051, 67.995361328125],
                            [13.300195312499994, 68.16044921875007],
                            [13.872851562500045, 68.26533203125004],
                        ],
                    ],
                    [
                        [
                            [15.207128906250006, 68.943115234375],
                            [15.222070312500023, 68.61630859375003],
                            [14.404687500000051, 68.663232421875],
                            [15.037792968750068, 69.00053710937507],
                            [15.207128906250006, 68.943115234375],
                        ],
                    ],
                    [
                        [
                            [15.760351562500006, 68.56123046875001],
                            [16.328906250000017, 68.87631835937498],
                            [16.519238281250068, 68.63300781249998],
                            [15.975292968750011, 68.402490234375],
                            [14.257519531249983, 68.19077148437503],
                            [15.412597656250028, 68.61582031250003],
                            [15.483007812500006, 69.04345703125003],
                            [16.04804687500001, 69.30205078125002],
                            [15.760351562500006, 68.56123046875001],
                        ],
                    ],
                    [
                        [
                            [17.503027343750034, 69.59624023437502],
                            [18.004101562500068, 69.50498046874998],
                            [17.95068359375003, 69.19814453125],
                            [17.487890625000063, 69.19682617187499],
                            [17.08251953124997, 69.013671875],
                            [16.81044921875008, 69.07070312499997],
                            [17.001757812500045, 69.36191406250006],
                            [17.36083984375003, 69.38149414062497],
                            [17.503027343750034, 69.59624023437502],
                        ],
                    ],
                    [
                        [
                            [29.956152343750006, 69.79677734375002],
                            [29.766210937500006, 69.76752929687501],
                            [29.835839843749994, 69.90556640625005],
                            [29.956152343750006, 69.79677734375002],
                        ],
                    ],
                    [
                        [
                            [20.779199218750023, 70.08974609375002],
                            [20.46425781250005, 70.0765625],
                            [20.492773437500006, 70.20332031249995],
                            [20.78603515625008, 70.21953124999999],
                            [20.779199218750023, 70.08974609375002],
                        ],
                    ],
                    [
                        [
                            [19.25507812500001, 70.06640625000006],
                            [19.607812500000023, 70.019140625],
                            [19.334765625000074, 69.82026367187501],
                            [18.784765625000034, 69.57900390624997],
                            [18.12988281250003, 69.557861328125],
                            [18.34931640625004, 69.76787109374999],
                            [18.67402343750004, 69.78164062500002],
                            [19.13271484375005, 70.24414062500003],
                            [19.25507812500001, 70.06640625000006],
                        ],
                    ],
                    [
                        [
                            [19.76748046875005, 70.21669921875002],
                            [20.005957031250034, 70.07622070312502],
                            [19.599023437499994, 70.26616210937507],
                            [19.76748046875005, 70.21669921875002],
                        ],
                    ],
                    [
                        [
                            [23.615332031250034, 70.54931640625003],
                            [23.15917968750003, 70.28261718750005],
                            [22.941015625000063, 70.444580078125],
                            [23.546679687500017, 70.61708984374997],
                            [23.615332031250034, 70.54931640625003],
                        ],
                    ],
                    [
                        [
                            [24.01757812500003, 70.56738281249997],
                            [23.716601562500074, 70.561865234375],
                            [23.778417968750063, 70.74736328125005],
                            [24.01757812500003, 70.56738281249997],
                        ],
                    ],
                    [
                        [
                            [23.440527343750063, 70.81577148437503],
                            [22.8291015625, 70.54155273437505],
                            [22.358691406250017, 70.514794921875],
                            [21.99453125000008, 70.65712890624997],
                            [23.440527343750063, 70.81577148437503],
                        ],
                    ],
                    [
                        [
                            [30.869726562500006, 69.78344726562506],
                            [30.860742187499994, 69.53842773437503],
                            [30.18017578124997, 69.63583984375],
                            [30.08730468750005, 69.43286132812503],
                            [29.38828125, 69.29814453125005],
                            [28.96582031250003, 69.02197265625],
                            [28.846289062500006, 69.17690429687502],
                            [29.33339843750005, 69.47299804687503],
                            [29.14160156250003, 69.67143554687505],
                            [27.747851562500045, 70.06484375],
                            [27.127539062500063, 69.90649414062497],
                            [26.525390625000057, 69.91503906250003],
                            [26.07246093750004, 69.69155273437497],
                            [25.748339843750017, 68.99013671875],
                            [24.94140625000003, 68.59326171875006],
                            [23.85400390625, 68.80590820312503],
                            [23.324023437500017, 68.64897460937502],
                            [22.410937500000074, 68.719873046875],
                            [21.59375, 69.273583984375],
                            [21.06611328125001, 69.21411132812497],
                            [21.065722656250017, 69.04174804687503],
                            [20.622167968750006, 69.036865234375],
                            [20.116699218750057, 69.02089843750005],
                            [20.348046875000023, 68.84873046875003],
                            [19.969824218750063, 68.35639648437501],
                            [18.303027343750045, 68.55541992187497],
                            [17.91669921875001, 67.96489257812502],
                            [17.324609375000023, 68.10380859374999],
                            [16.783593750000023, 67.89501953125],
                            [16.12744140625, 67.42583007812507],
                            [16.40351562500004, 67.05498046875002],
                            [15.422949218750006, 66.48984374999998],
                            [15.483789062500051, 66.30595703124999],
                            [14.543261718750045, 66.12934570312498],
                            [14.47968750000004, 65.30146484374998],
                            [13.650292968750023, 64.58154296874997],
                            [14.077636718750028, 64.464013671875],
                            [14.141210937500006, 64.17353515624998],
                            [13.960546875000063, 64.01401367187498],
                            [13.203515625000023, 64.07509765625],
                            [12.792773437500017, 64],
                            [12.175195312500051, 63.595947265625],
                            [11.999902343750051, 63.29169921875001],
                            [12.303515625000074, 62.28559570312501],
                            [12.155371093750006, 61.720751953125045],
                            [12.88076171875008, 61.35229492187506],
                            [12.706054687500028, 61.059863281250074],
                            [12.29414062500004, 61.00268554687506],
                            [12.588671874999989, 60.450732421875045],
                            [12.486132812500074, 60.10678710937506],
                            [11.680761718750034, 59.59228515625003],
                            [11.798144531250074, 59.28989257812498],
                            [11.64277343750004, 58.92607421875002],
                            [11.470703125000057, 58.909521484375034],
                            [11.388281250000063, 59.036523437499966],
                            [10.834472656250028, 59.18393554687498],
                            [10.595312500000063, 59.764550781249966],
                            [10.179394531250068, 59.00927734375003],
                            [9.842578125000017, 58.95849609374997],
                            [9.557226562500063, 59.11269531250002],
                            [9.65693359375004, 58.97119140624997],
                            [8.166113281250063, 58.145312500000045],
                            [7.0048828125, 58.024218750000074],
                            [6.877050781250006, 58.15073242187498],
                            [6.590527343750068, 58.09731445312502],
                            [6.659863281250068, 58.26274414062499],
                            [5.706835937500074, 58.52363281250001],
                            [5.55556640625008, 58.975195312500006],
                            [6.099023437500023, 58.87026367187502],
                            [6.363281250000028, 59.00092773437501],
                            [6.099414062500017, 58.951953125000074],
                            [5.88916015625, 59.097949218750045],
                            [5.951855468750068, 59.299072265625],
                            [6.415332031250074, 59.547119140625],
                            [5.17324218750008, 59.16254882812498],
                            [5.2421875, 59.564306640625034],
                            [5.472460937500017, 59.713085937499955],
                            [5.77216796875004, 59.66093749999999],
                            [6.216601562499989, 59.818359375],
                            [5.73046875, 59.863085937500045],
                            [6.348730468750006, 60.35297851562504],
                            [6.57363281250008, 60.36059570312497],
                            [6.526855468750057, 60.152929687500034],
                            [6.995703125, 60.511962890625],
                            [6.1533203125, 60.34624023437499],
                            [5.145800781250074, 59.63881835937502],
                            [5.205664062500006, 60.087939453125045],
                            [5.688574218749977, 60.12319335937502],
                            [5.285839843750011, 60.20571289062505],
                            [5.13710937500008, 60.445605468750074],
                            [5.648339843750051, 60.68798828124997],
                            [5.244042968750023, 60.569580078125],
                            [5.115820312500006, 60.63598632812503],
                            [5.008593750000017, 61.038183593750006],
                            [6.777832031250028, 61.142431640625006],
                            [7.038671875000063, 60.952929687500045],
                            [7.040136718750006, 61.091162109375034],
                            [7.604492187500057, 61.210546875000034],
                            [7.34658203125008, 61.30058593749999],
                            [7.442578125000011, 61.43461914062502],
                            [7.173535156250011, 61.16596679687501],
                            [6.599902343750017, 61.28964843749998],
                            [6.383496093750068, 61.133886718750034],
                            [5.451269531250034, 61.10234375000002],
                            [5.106738281250017, 61.187548828125045],
                            [5.002734375000074, 61.43359375],
                            [5.338671875000017, 61.485498046874994],
                            [4.927832031249977, 61.71069335937506],
                            [4.93007812499999, 61.878320312499994],
                            [6.01582031250004, 61.7875],
                            [6.730761718750045, 61.86977539062505],
                            [5.266894531250045, 61.935595703125045],
                            [5.143164062500063, 62.159912109375],
                            [5.908300781249977, 62.41601562500003],
                            [6.083496093750057, 62.349609375],
                            [6.580078125000057, 62.407275390625045],
                            [6.692382812500028, 62.46806640624999],
                            [6.136132812500051, 62.40747070312497],
                            [6.352929687500051, 62.61113281249999],
                            [7.653125, 62.56401367187499],
                            [7.538378906250074, 62.67207031249998],
                            [8.045507812500006, 62.77124023437503],
                            [6.734960937500006, 62.72070312500003],
                            [6.940429687500028, 62.930468750000045],
                            [7.571875, 63.09951171875002],
                            [8.100585937500028, 63.090966796874966],
                            [8.623144531250006, 62.84624023437502],
                            [8.158007812500017, 63.16152343750005],
                            [8.635546875000045, 63.34233398437502],
                            [8.360742187500023, 63.498876953125034],
                            [8.576171875000028, 63.60117187499998],
                            [9.135839843750006, 63.593652343749966],
                            [9.156054687500045, 63.459326171875034],
                            [9.696875, 63.624560546875045],
                            [10.020996093750028, 63.39082031250004],
                            [10.76015625000008, 63.461279296875006],
                            [10.725292968750068, 63.625],
                            [11.370703125000034, 63.804833984374994],
                            [11.175585937500074, 63.89887695312498],
                            [11.457617187500063, 64.00297851562505],
                            [11.306640625000028, 64.04887695312499],
                            [10.91425781250004, 63.92109374999998],
                            [10.934863281250045, 63.770214843749955],
                            [10.055078125000051, 63.5126953125],
                            [9.567285156250051, 63.70615234374998],
                            [10.565625, 64.418310546875],
                            [11.523828125000051, 64.744384765625],
                            [11.632910156250063, 64.81391601562495],
                            [11.296777343750051, 64.75478515625],
                            [11.489355468750034, 64.975830078125],
                            [12.15966796875, 65.178955078125],
                            [12.508398437499977, 65.09941406250005],
                            [12.915527343750057, 65.33925781249997],
                            [12.417578125000063, 65.18408203124997],
                            [12.133886718749977, 65.27915039062498],
                            [12.68886718750008, 65.90219726562498],
                            [13.033105468750051, 65.95625],
                            [12.783789062500063, 66.10043945312506],
                            [14.034179687500057, 66.29755859374998],
                            [13.118847656250011, 66.23066406250004],
                            [13.211425781250028, 66.64082031250001],
                            [13.959472656250028, 66.79433593750002],
                            [13.651562500000011, 66.90708007812498],
                            [14.10878906250008, 67.11923828125003],
                            [15.41572265625004, 67.20244140625002],
                            [14.441699218750045, 67.27138671875005],
                            [14.961914062500057, 67.57426757812502],
                            [15.59443359375004, 67.34853515625005],
                            [15.691503906250006, 67.52138671875],
                            [15.24873046875004, 67.6021484375],
                            [15.303906250000011, 67.76528320312502],
                            [14.854687500000068, 67.66333007812506],
                            [14.798925781250063, 67.80932617187503],
                            [15.13427734375, 67.97270507812502],
                            [15.621386718750017, 67.94829101562502],
                            [15.316015624999977, 68.06875],
                            [16.007910156250006, 68.22871093750004],
                            [16.312304687500017, 67.88144531249998],
                            [16.20380859375001, 68.31674804687503],
                            [17.552832031250063, 68.42626953125006],
                            [16.51435546875004, 68.53256835937503],
                            [18.101464843749994, 69.15629882812499],
                            [18.259765625, 69.47060546875],
                            [18.915917968750023, 69.33559570312502],
                            [18.614453125000068, 69.49057617187498],
                            [19.197265625000057, 69.74785156249999],
                            [19.722460937500017, 69.78164062500002],
                            [19.64150390625005, 69.42402343750001],
                            [20.324218750000057, 69.94531249999997],
                            [20.054492187500074, 69.33266601562497],
                            [20.486718750000023, 69.54208984375],
                            [20.739453124999983, 69.52050781250003],
                            [20.622070312500057, 69.91391601562498],
                            [21.163085937500057, 69.88950195312498],
                            [21.432910156250045, 70.01318359375006],
                            [21.974707031250034, 69.83457031249998],
                            [21.355761718750045, 70.23339843749997],
                            [22.321972656250068, 70.264501953125],
                            [22.684570312500057, 70.374755859375],
                            [23.35390625000008, 69.98339843750003],
                            [23.3291015625, 70.20722656249995],
                            [24.420019531250034, 70.70200195312503],
                            [24.263476562500017, 70.82631835937497],
                            [24.658007812500017, 71.00102539062505],
                            [25.264648437500057, 70.843505859375],
                            [25.768164062500063, 70.85317382812502],
                            [25.043847656250023, 70.10903320312502],
                            [26.66132812500004, 70.93974609374999],
                            [26.585058593750034, 70.41000976562498],
                            [26.989355468750063, 70.51137695312502],
                            [27.183691406250034, 70.74404296875],
                            [27.546484375000063, 70.80400390625005],
                            [27.23525390625008, 70.94721679687498],
                            [27.59707031250005, 71.09130859375003],
                            [28.392285156250068, 70.97529296875004],
                            [27.898046875, 70.67792968750001],
                            [28.271777343750017, 70.66796875000003],
                            [28.192968750000034, 70.24858398437505],
                            [28.83154296875003, 70.86396484375001],
                            [29.7375, 70.646826171875],
                            [30.065136718750097, 70.70297851562498],
                            [30.944140625000017, 70.27441406249997],
                            [30.262988281250074, 70.12470703125004],
                            [28.804296875000063, 70.09252929687506],
                            [29.601367187500017, 69.97675781249998],
                            [29.792089843750063, 69.727880859375],
                            [30.08828125, 69.71757812500005],
                            [30.237597656250017, 69.86220703125002],
                            [30.428320312500006, 69.722265625],
                            [30.869726562500006, 69.78344726562506],
                        ],
                    ],
                    [
                        [
                            [25.58632812500005, 71.14208984375],
                            [26.13378906250003, 70.99580078125004],
                            [25.582031250000057, 70.960791015625],
                            [25.31494140625, 71.03413085937504],
                            [25.58632812500005, 71.14208984375],
                        ],
                    ],
                    [
                        [
                            [-8.953564453124983, 70.83916015625002],
                            [-8.001367187499966, 71.17768554687495],
                            [-8.002099609374937, 71.04125976562497],
                            [-8.953564453124983, 70.83916015625002],
                        ],
                    ],
                    [
                        [
                            [19.219335937500006, 74.39101562500002],
                            [18.86123046875008, 74.51416015624997],
                            [19.182910156250045, 74.51791992187503],
                            [19.219335937500006, 74.39101562500002],
                        ],
                    ],
                    [
                        [
                            [21.60810546875004, 78.59570312499997],
                            [22.04316406250004, 78.57695312500007],
                            [22.29951171875004, 78.22817382812497],
                            [23.451953125000074, 78.14946289062502],
                            [23.11669921874997, 77.99150390624999],
                            [24.901855468750057, 77.756591796875],
                            [22.55371093750003, 77.26665039062502],
                            [22.685351562500045, 77.55351562500002],
                            [20.928125, 77.45966796874998],
                            [21.653125, 77.92353515624998],
                            [20.22792968750005, 78.47783203125005],
                            [21.60810546875004, 78.59570312499997],
                        ],
                    ],
                    [
                        [
                            [11.250292968750017, 78.610693359375],
                            [12.116406250000068, 78.232568359375],
                            [11.121289062500011, 78.46328125],
                            [10.558203125000063, 78.90292968750003],
                            [11.250292968750017, 78.610693359375],
                        ],
                    ],
                    [
                        [
                            [29.047070312500068, 78.91206054687504],
                            [29.69667968750005, 78.90473632812495],
                            [27.88906250000005, 78.8521484375],
                            [28.511132812500023, 78.96733398437502],
                            [29.047070312500068, 78.91206054687504],
                        ],
                    ],
                    [
                        [
                            [16.786718750000034, 79.90673828125],
                            [17.834570312499977, 79.80004882812503],
                            [17.66875, 79.38593750000004],
                            [18.39736328125008, 79.60517578125001],
                            [18.677832031250006, 79.26171875000003],
                            [19.893554687500057, 79.05620117187499],
                            [20.61103515625004, 79.10664062499998],
                            [21.388769531250034, 78.74042968749998],
                            [19.67675781250003, 78.60957031249995],
                            [16.700488281250045, 76.57929687499995],
                            [14.365820312500034, 77.23447265625003],
                            [13.995703125000034, 77.50820312500002],
                            [14.69501953125004, 77.525048828125],
                            [14.920800781250023, 77.68881835937506],
                            [17.033300781250006, 77.79770507812503],
                            [16.91406250000003, 77.89799804687505],
                            [14.089941406250063, 77.77138671875],
                            [13.680566406250051, 78.028125],
                            [14.307226562500006, 78.00507812500001],
                            [15.783886718750011, 78.32705078125005],
                            [17.00292968750003, 78.36938476562497],
                            [16.44863281250008, 78.50356445312502],
                            [16.78261718750008, 78.66362304687505],
                            [15.417382812500023, 78.47324218749998],
                            [15.384179687500023, 78.77119140625001],
                            [15.01630859375004, 78.63012695312497],
                            [14.689257812500017, 78.720947265625],
                            [14.638281250000034, 78.41459960937502],
                            [14.110449218750063, 78.27089843749997],
                            [13.150195312499989, 78.2375],
                            [11.365429687500011, 78.95039062500004],
                            [12.323437500000068, 78.91425781249995],
                            [12.083984375000028, 79.26752929687498],
                            [11.579785156250068, 79.28349609375005],
                            [11.208105468750034, 79.12963867187503],
                            [10.737597656250017, 79.52016601562502],
                            [10.804003906250045, 79.79877929687504],
                            [11.150390625, 79.71699218749998],
                            [11.702343750000011, 79.82060546875005],
                            [12.287792968750068, 79.713134765625],
                            [12.279980468749983, 79.81596679687507],
                            [13.692871093749972, 79.860986328125],
                            [13.777539062500011, 79.71528320312498],
                            [12.555371093750068, 79.56948242187502],
                            [13.333789062500017, 79.57480468750006],
                            [14.029589843750017, 79.34414062500005],
                            [14.59365234375008, 79.79873046875002],
                            [16.34375, 78.97612304687502],
                            [15.816113281250011, 79.68183593750001],
                            [16.245703125000034, 80.04946289062502],
                            [16.786718750000034, 79.90673828125],
                        ],
                    ],
                    [
                        [
                            [32.52597656250006, 80.119140625],
                            [31.48193359374997, 80.10791015625003],
                            [33.62929687499999, 80.21743164062497],
                            [32.52597656250006, 80.119140625],
                        ],
                    ],
                    [
                        [
                            [20.897851562500023, 80.24995117187501],
                            [22.289746093749983, 80.04921874999997],
                            [22.450781250000034, 80.40224609375005],
                            [23.00800781250004, 80.473974609375],
                            [23.114550781250074, 80.18696289062498],
                            [24.29755859375004, 80.36040039062505],
                            [26.86083984375, 80.16000976562498],
                            [27.19863281250008, 79.90659179687506],
                            [25.641210937500034, 79.40302734374995],
                            [23.94775390625, 79.19428710937498],
                            [22.903710937500023, 79.23066406250001],
                            [22.865527343750045, 79.41186523437497],
                            [20.861132812500017, 79.39785156249997],
                            [20.128222656250074, 79.489599609375],
                            [19.674609375000045, 79.591162109375],
                            [20.784082031250023, 79.74858398437502],
                            [18.725, 79.7607421875],
                            [18.25537109375, 79.92919921875003],
                            [18.855957031250057, 80.03662109375],
                            [17.91689453125005, 80.14311523437502],
                            [19.343359375000063, 80.11640624999998],
                            [19.733300781249994, 80.47783203124999],
                            [20.897851562500023, 80.24995117187501],
                        ],
                    ],
                ],
            },
            properties: {
                name: "挪威",
                childNum: 27,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [87.984375, 27.133935546874994],
                        [87.9931640625, 27.086083984374994],
                        [88.11103515625001, 26.928466796875],
                        [88.1572265625, 26.807324218749997],
                        [88.16152343750002, 26.724804687499997],
                        [88.11152343750001, 26.58642578125],
                        [88.05488281250001, 26.430029296875],
                        [88.02695312500003, 26.39501953125],
                        [87.9951171875, 26.382373046874996],
                        [87.28740234374999, 26.360302734374997],
                        [87.01640624999999, 26.555419921875],
                        [86.70136718750001, 26.43505859375],
                        [86.00732421875, 26.649365234374997],
                        [85.79453125000003, 26.604150390624994],
                        [85.7373046875, 26.63974609375],
                        [85.6484375, 26.829003906249994],
                        [85.56845703125003, 26.83984375],
                        [85.29296875, 26.741015625],
                        [85.19179687500002, 26.766552734374997],
                        [84.68535156249999, 27.041015625],
                        [84.65380859375, 27.091699218749994],
                        [84.65478515625, 27.203662109374996],
                        [84.64072265625003, 27.249853515625],
                        [84.61015624999999, 27.298681640625],
                        [84.48085937500002, 27.348193359374996],
                        [84.22978515624999, 27.42783203125],
                        [84.09101562500001, 27.491357421874994],
                        [83.82880859375001, 27.377832031249994],
                        [83.74697265625002, 27.395947265624997],
                        [83.55166015625002, 27.456347656249996],
                        [83.44716796875002, 27.46533203125],
                        [83.38398437500001, 27.44482421875],
                        [83.36943359374999, 27.41025390625],
                        [83.28974609375001, 27.370996093749994],
                        [82.7333984375, 27.518994140624997],
                        [82.71083984375002, 27.5966796875],
                        [82.67734375000003, 27.6734375],
                        [82.6298828125, 27.687060546874996],
                        [82.45136718750001, 27.671826171874997],
                        [82.28769531250003, 27.756542968749997],
                        [82.11191406250003, 27.864941406249997],
                        [82.03701171875002, 27.900585937499997],
                        [81.98769531250002, 27.913769531249997],
                        [81.94521484375002, 27.899267578125],
                        [81.896875, 27.874462890624997],
                        [81.85263671875003, 27.867089843749994],
                        [81.1689453125, 28.335009765624996],
                        [80.58701171875003, 28.649609375],
                        [80.51787109374999, 28.665185546874994],
                        [80.49580078125001, 28.635791015624996],
                        [80.47910156250003, 28.604882812499994],
                        [80.41855468750003, 28.612011718749997],
                        [80.32480468750003, 28.66640625],
                        [80.2265625, 28.723339843749997],
                        [80.07070312500002, 28.83017578125],
                        [80.05166015625002, 28.8703125],
                        [80.08457031250003, 28.994189453124996],
                        [80.13046875000003, 29.100390625],
                        [80.16953125000003, 29.124316406249996],
                        [80.23300781250003, 29.194628906249996],
                        [80.25595703125003, 29.318017578124994],
                        [80.2548828125, 29.42333984375],
                        [80.31689453125, 29.572070312499996],
                        [80.40185546875, 29.730273437499996],
                        [80.54902343750001, 29.899804687499994],
                        [80.81992187500003, 30.119335937499997],
                        [80.84814453125, 30.13974609375],
                        [80.90761718750002, 30.171923828124996],
                        [80.96611328124999, 30.180029296875],
                        [81.17714843750002, 30.039892578125],
                        [81.25507812500001, 30.093310546874996],
                        [81.41718750000001, 30.337597656249997],
                        [81.64189453124999, 30.3875],
                        [81.85488281250002, 30.36240234375],
                        [82.04335937500002, 30.3267578125],
                        [82.220703125, 30.063867187499994],
                        [83.15546875000001, 29.612646484375],
                        [83.58349609375, 29.18359375],
                        [83.93593750000002, 29.279492187499997],
                        [84.02197265625, 29.253857421874997],
                        [84.10136718749999, 29.219970703125],
                        [84.12783203125002, 29.156298828124996],
                        [84.17558593749999, 29.036376953125],
                        [84.22871093750001, 28.911767578124994],
                        [84.796875, 28.560205078124994],
                        [84.85507812500003, 28.553613281249994],
                        [85.06914062499999, 28.609667968749996],
                        [85.12636718750002, 28.60263671875],
                        [85.15908203125002, 28.592236328124997],
                        [85.16015625, 28.571875],
                        [85.12148437500002, 28.484277343749994],
                        [85.08857421875001, 28.372265625],
                        [85.12246093750002, 28.315966796874996],
                        [85.21210937500001, 28.292626953124994],
                        [85.41064453125, 28.276025390624994],
                        [85.67832031250003, 28.27744140625],
                        [85.75947265625001, 28.220654296874997],
                        [85.84023437500002, 28.1353515625],
                        [85.92167968749999, 27.989697265624997],
                        [85.9541015625, 27.92822265625],
                        [85.99453125000002, 27.910400390625],
                        [86.06416015625001, 27.934716796874994],
                        [86.07871093750003, 28.08359375],
                        [86.13701171874999, 28.11435546875],
                        [86.21796875000001, 28.0220703125],
                        [86.32861328125, 27.959521484374996],
                        [86.40869140625, 27.928662109374997],
                        [86.51689453124999, 27.963525390624994],
                        [86.55449218749999, 28.085205078125],
                        [86.61445312500001, 28.10302734375],
                        [86.69052734375003, 28.094921875],
                        [86.71962890625002, 28.070654296875],
                        [86.75039062500002, 28.0220703125],
                        [86.93378906250001, 27.968457031249997],
                        [87.02011718750003, 27.928662109374997],
                        [87.14140624999999, 27.838330078124997],
                        [87.29072265625001, 27.821923828124994],
                        [87.62255859375, 27.815185546875],
                        [87.86074218750002, 27.886083984375],
                        [88.10976562500002, 27.87060546875],
                        [87.984375, 27.133935546874994],
                    ],
                ],
            },
            properties: {
                name: "尼泊尔",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [169.17822265624997, -52.497265625],
                            [169.12753906250006, -52.570312499999964],
                            [169.02177734375002, -52.49541015624998],
                            [169.17822265624997, -52.497265625],
                        ],
                    ],
                    [
                        [
                            [166.22109375, -50.76152343749997],
                            [166.2428710937501, -50.84570312499998],
                            [165.88916015624997, -50.80771484374996],
                            [166.10136718750002, -50.538964843750016],
                            [166.26748046875005, -50.558593750000014],
                            [166.22109375, -50.76152343749997],
                        ],
                    ],
                    [
                        [
                            [168.14492187500005, -46.862207031249966],
                            [168.04316406250004, -46.9326171875],
                            [168.2409179687501, -47.070019531250026],
                            [167.52197265624997, -47.258691406249994],
                            [167.80078125000003, -46.90654296875002],
                            [167.78398437500007, -46.699804687500006],
                            [167.9557617187501, -46.69443359374998],
                            [168.14492187500005, -46.862207031249966],
                        ],
                    ],
                    [
                        [
                            [166.97949218749997, -45.17968750000003],
                            [167.02265625000004, -45.299804687499986],
                            [166.89267578125012, -45.24052734374999],
                            [166.97949218749997, -45.17968750000003],
                        ],
                    ],
                    [
                        [
                            [-176.17763671874997, -43.74033203124998],
                            [-176.38173828124997, -43.86679687499998],
                            [-176.40737304687497, -43.7609375],
                            [-176.516552734375, -43.78476562499996],
                            [-176.33359375000003, -44.02529296875004],
                            [-176.51552734374997, -44.11660156249998],
                            [-176.62934570312495, -44.036132812500014],
                            [-176.55512695312504, -43.85195312499998],
                            [-176.84765625000003, -43.82392578125004],
                            [-176.56611328124995, -43.717578125000045],
                            [-176.17763671874997, -43.74033203124998],
                        ],
                    ],
                    [
                        [
                            [173.91464843750018, -40.86367187500004],
                            [173.78085937500012, -40.921777343749966],
                            [173.964453125, -40.71298828124998],
                            [173.91464843750018, -40.86367187500004],
                        ],
                    ],
                    [
                        [
                            [173.11533203125006, -41.27929687499997],
                            [173.94716796875005, -40.92412109375],
                            [173.79785156250003, -41.271972656249986],
                            [173.99941406250005, -40.99326171874996],
                            [174.30253906249996, -41.019531249999986],
                            [174.03857421875003, -41.24189453125],
                            [174.37011718750009, -41.1037109375],
                            [174.06933593750009, -41.42949218750002],
                            [174.08369140625015, -41.67080078124998],
                            [174.2831054687501, -41.740625],
                            [173.22119140624997, -42.976562499999986],
                            [172.62402343749997, -43.27246093749996],
                            [172.73476562500005, -43.35478515625003],
                            [172.52666015625002, -43.464746093749966],
                            [172.69345703125006, -43.444335937499986],
                            [172.80703125000005, -43.620996093749994],
                            [173.07324218750003, -43.676171874999966],
                            [173.065625, -43.87460937499998],
                            [172.50273437500002, -43.84365234374998],
                            [172.48037109375, -43.726660156250034],
                            [172.29658203125004, -43.867871093750026],
                            [172.035546875, -43.70175781250002],
                            [172.17978515625006, -43.895996093749986],
                            [171.24072265624997, -44.26416015625003],
                            [171.14628906250002, -44.9123046875],
                            [170.99902343750003, -44.91142578124999],
                            [171.11328125000003, -45.03925781250001],
                            [170.7005859375, -45.68427734374997],
                            [170.77626953125005, -45.870898437499974],
                            [170.4191406250001, -45.94101562499996],
                            [169.68662109375006, -46.55166015625002],
                            [169.34228515625003, -46.62050781250001],
                            [168.38212890625007, -46.60537109374995],
                            [168.1891601562501, -46.362207031249966],
                            [167.8419921875001, -46.366210937499986],
                            [167.539453125, -46.14853515624996],
                            [167.36894531250007, -46.24150390624999],
                            [166.73154296875006, -46.19785156249998],
                            [166.91669921875004, -45.95722656249998],
                            [166.64990234374997, -46.04169921875004],
                            [166.71796875000004, -45.88935546875001],
                            [166.49316406249997, -45.9638671875],
                            [166.48828124999997, -45.83183593750002],
                            [167.0033203125, -45.71210937500004],
                            [166.79765625000002, -45.64560546874999],
                            [166.99082031250012, -45.531738281249986],
                            [166.73398437500012, -45.54355468749999],
                            [166.74306640625, -45.46845703124997],
                            [166.91992187499997, -45.40791015624998],
                            [166.86923828125006, -45.31123046875],
                            [167.15566406250005, -45.410937499999974],
                            [167.23007812500012, -45.29033203125],
                            [167.02587890624997, -45.12363281249998],
                            [167.25947265625004, -45.08222656249997],
                            [167.19453125000004, -44.963476562500034],
                            [167.41074218750006, -44.82792968750003],
                            [167.4662109375, -44.958300781250045],
                            [167.48496093750006, -44.77138671874998],
                            [167.78701171875, -44.59501953125002],
                            [167.90898437500002, -44.66474609375001],
                            [167.85654296875012, -44.50068359374998],
                            [168.45742187500005, -44.030566406250045],
                            [169.17890625000004, -43.9130859375],
                            [169.16953125000006, -43.77705078125],
                            [169.83388671875, -43.53701171875004],
                            [170.24023437499997, -43.163867187500045],
                            [170.39609375000012, -43.18222656249996],
                            [170.30283203125012, -43.10761718750004],
                            [170.61181640625003, -43.091796875000014],
                            [170.5236328125001, -43.00898437500001],
                            [170.6654296875, -42.961230468749974],
                            [170.73525390625005, -43.029785156249986],
                            [170.96992187500004, -42.71835937499996],
                            [171.01171875000003, -42.88505859374999],
                            [171.027734375, -42.696093750000045],
                            [171.31337890625005, -42.460156250000026],
                            [171.48623046875, -41.7947265625],
                            [171.94804687500002, -41.53867187499996],
                            [172.13945312500002, -40.947265625000014],
                            [172.640625, -40.51826171875001],
                            [172.94365234375007, -40.51875],
                            [172.73261718750004, -40.54375],
                            [172.70439453125002, -40.6677734375],
                            [172.988671875, -40.84824218749999],
                            [173.11533203125006, -41.27929687499997],
                        ],
                    ],
                    [
                        [
                            [175.54316406250015, -36.279296874999986],
                            [175.34619140624997, -36.217773437499986],
                            [175.3895507812501, -36.07773437499996],
                            [175.54316406250015, -36.279296874999986],
                        ],
                    ],
                    [
                        [
                            [173.26943359375, -34.93476562499998],
                            [173.44785156250012, -34.844335937500034],
                            [173.47265625000003, -34.94697265624998],
                            [174.10400390625003, -35.14287109375002],
                            [174.1431640625, -35.3],
                            [174.32031250000003, -35.246679687500034],
                            [174.58066406250018, -35.78554687500004],
                            [174.39580078124996, -35.79736328124996],
                            [174.8021484375, -36.30947265625001],
                            [174.72246093750007, -36.84121093749998],
                            [175.29951171875004, -36.99326171874996],
                            [175.38535156250012, -37.206933593749966],
                            [175.54248046874997, -37.2013671875],
                            [175.46083984375005, -36.475683593750034],
                            [175.77216796875004, -36.73515625],
                            [176.10839843749997, -37.64511718749998],
                            [177.27402343750012, -37.993457031249974],
                            [178.0091796875, -37.55488281249998],
                            [178.53623046875006, -37.69208984375004],
                            [178.26767578125006, -38.551171875],
                            [177.976171875, -38.72226562500005],
                            [177.90878906250012, -39.23955078125],
                            [177.52294921875003, -39.07382812499999],
                            [177.07675781250012, -39.22177734375002],
                            [176.93925781249996, -39.55527343750002],
                            [177.10986328125009, -39.673144531250045],
                            [176.8421875000001, -40.15781250000002],
                            [175.98291015625003, -41.21328125000002],
                            [175.30976562499998, -41.610644531249974],
                            [175.16562500000012, -41.41738281249995],
                            [174.88134765624997, -41.42402343749997],
                            [174.8656250000001, -41.223046874999966],
                            [174.63535156250012, -41.28945312499999],
                            [175.1625, -40.62158203125],
                            [175.25410156250004, -40.28935546875],
                            [175.1559570312501, -40.11494140625],
                            [175.00927734375009, -39.95214843749996],
                            [173.93437500000013, -39.50908203125002],
                            [173.76367187499997, -39.31875],
                            [173.84433593750006, -39.13935546875001],
                            [174.39843749999997, -38.96259765624998],
                            [174.59736328124998, -38.78505859374995],
                            [174.80166015625005, -37.895507812500014],
                            [174.92802734375002, -37.80449218750003],
                            [174.58583984374994, -37.09775390625002],
                            [174.73427734375, -37.21523437499998],
                            [174.92890625000004, -37.084765625000045],
                            [174.78203125000013, -36.94375],
                            [174.47558593750009, -36.94189453124997],
                            [174.1888671875001, -36.492285156250034],
                            [174.4015625000001, -36.60195312499999],
                            [174.39277343750004, -36.24003906249999],
                            [174.26787109375002, -36.16308593750003],
                            [174.25371093749996, -36.24912109374998],
                            [174.03642578125013, -36.12246093750001],
                            [173.91445312499994, -35.908691406249986],
                            [173.91728515625002, -36.01816406249999],
                            [174.16640624999994, -36.327636718749986],
                            [174.05468749999991, -36.35976562500004],
                            [173.41220703125012, -35.542578125],
                            [173.62617187500004, -35.31914062499996],
                            [173.3763671875001, -35.50009765624996],
                            [173.31396484375003, -35.44335937499996],
                            [173.11669921874997, -35.205273437500026],
                            [173.190625, -35.01621093749998],
                            [172.70595703125005, -34.45517578124998],
                            [173.04394531249997, -34.429101562499994],
                            [172.96376953125, -34.53515625000003],
                            [173.26943359375, -34.93476562499998],
                        ],
                    ],
                ],
            },
            properties: {
                name: "新西兰",
                childNum: 9,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [58.722070312499994, 20.21875],
                            [58.640917968750074, 20.210693359375057],
                            [58.64121093750006, 20.33735351562501],
                            [58.884375, 20.680566406250023],
                            [58.95078125000006, 20.516162109375017],
                            [58.722070312499994, 20.21875],
                        ],
                    ],
                    [
                        [
                            [56.38798828125002, 24.97919921875004],
                            [56.640625, 24.4703125],
                            [57.12304687500003, 23.980712890625],
                            [58.773046875, 23.517187499999977],
                            [59.42939453125004, 22.660839843749955],
                            [59.82324218749997, 22.50898437500004],
                            [59.8, 22.21992187500001],
                            [59.37148437500005, 21.498828125000017],
                            [58.89570312500004, 21.11279296874997],
                            [58.47421875000006, 20.406884765624966],
                            [58.20898437500003, 20.423974609374994],
                            [58.245019531249994, 20.599218749999977],
                            [58.16943359375003, 20.58950195312505],
                            [57.86181640624997, 20.24414062500003],
                            [57.71416015625002, 19.678417968749983],
                            [57.81162109375006, 19.01708984374997],
                            [56.825976562500074, 18.753515625],
                            [56.3834960937501, 17.98798828125001],
                            [55.479101562500006, 17.84326171875003],
                            [55.25537109375003, 17.58564453125004],
                            [55.275195312500074, 17.320898437500006],
                            [55.06416015625004, 17.038916015625034],
                            [54.06816406250002, 17.005517578124966],
                            [53.60986328124997, 16.75996093750004],
                            [53.08564453125004, 16.648388671874955],
                            [51.977636718750006, 18.996142578125074],
                            [54.97734375000002, 19.995947265625006],
                            [55.64101562499999, 22.001855468749994],
                            [55.185839843750074, 22.7041015625],
                            [55.1999023437501, 23.034765625000034],
                            [55.53164062499999, 23.81904296875001],
                            [55.4684570312501, 23.94111328125001],
                            [55.98515625000002, 24.063378906249966],
                            [55.92861328125005, 24.215136718750074],
                            [55.76083984375006, 24.24267578125],
                            [55.795703125000074, 24.868115234374955],
                            [56.00058593750006, 24.953222656249977],
                            [56.06386718750005, 24.73876953125],
                            [56.38798828125002, 24.97919921875004],
                        ],
                    ],
                    [
                        [
                            [56.29785156250003, 25.650683593750045],
                            [56.144628906250006, 25.690527343750006],
                            [56.16748046875003, 26.047460937499977],
                            [56.08046875, 26.06264648437505],
                            [56.41308593749997, 26.351171875000034],
                            [56.29785156250003, 25.650683593750045],
                        ],
                    ],
                ],
            },
            properties: {
                name: "阿曼",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [73.08961802927895, 36.86435907947333],
                        [73.08203125000107, 36.43949943991182],
                        [72.31128647748268, 35.77290936638241],
                        [73.13410859949555, 34.82510160558277],
                        [73.19895048106557, 33.88770931468204],
                        [74.00809389139292, 33.25375789331485],
                        [73.98984375, 33.22119140625],
                        [74.30361328125002, 32.991796875],
                        [74.30546875000002, 32.810449218749994],
                        [74.35458984375, 32.768701171874994],
                        [74.58828125000002, 32.753222656249996],
                        [74.632421875, 32.770898437499994],
                        [74.66328125000001, 32.757666015625],
                        [74.64335937500002, 32.607714843749996],
                        [74.68574218750001, 32.493798828124994],
                        [74.78886718750002, 32.4578125],
                        [74.9873046875, 32.462207031249996],
                        [75.33349609375, 32.279199218749994],
                        [75.25410156250001, 32.14033203125],
                        [75.13876953125, 32.104785156249996],
                        [75.07148437500001, 32.08935546875],
                        [74.73945312500001, 31.948828125],
                        [74.6357421875, 31.88974609375],
                        [74.55556640625002, 31.818554687499997],
                        [74.5259765625, 31.76513671875],
                        [74.50996093750001, 31.712939453124996],
                        [74.58183593750002, 31.52392578125],
                        [74.59394531250001, 31.465380859374996],
                        [74.53496093750002, 31.261376953124994],
                        [74.51767578125, 31.185595703124996],
                        [74.6103515625, 31.112841796874996],
                        [74.62578125000002, 31.06875],
                        [74.6328125, 31.03466796875],
                        [74.509765625, 30.959667968749997],
                        [74.38037109375, 30.893408203125],
                        [74.33935546875, 30.8935546875],
                        [74.00898437500001, 30.519677734374994],
                        [73.89931640625002, 30.435351562499996],
                        [73.88271484375002, 30.3521484375],
                        [73.92460937500002, 30.28164062499999],
                        [73.93339843750002, 30.222070312499994],
                        [73.88652343750002, 30.162011718749994],
                        [73.8091796875, 30.093359375],
                        [73.38164062500002, 29.934375],
                        [72.9033203125, 29.028759765624997],
                        [72.34189453125, 28.751904296874997],
                        [72.2919921875, 28.697265625],
                        [72.128515625, 28.346337890624994],
                        [71.94804687500002, 28.177294921874996],
                        [71.88886718750001, 28.0474609375],
                        [71.87031250000001, 27.9625],
                        [71.54296875, 27.869873046875],
                        [71.18476562500001, 27.831640625],
                        [70.87490234375002, 27.714453125],
                        [70.79794921875, 27.709619140624994],
                        [70.69160156250001, 27.768994140624997],
                        [70.62910156250001, 27.937451171874997],
                        [70.40371093750002, 28.025048828124994],
                        [70.24433593750001, 27.934130859374996],
                        [70.1939453125, 27.894873046875],
                        [70.14453125, 27.849023437499994],
                        [70.0498046875, 27.694726562499994],
                        [69.89628906250002, 27.4736328125],
                        [69.56796875, 27.174609375],
                        [69.47001953125002, 26.804443359375],
                        [70.11464843750002, 26.548046875],
                        [70.14921875000002, 26.347558593749994],
                        [70.1001953125, 25.910058593749994],
                        [70.2646484375, 25.70654296875],
                        [70.3251953125, 25.685742187499997],
                        [70.44853515625002, 25.681347656249997],
                        [70.505859375, 25.685302734375],
                        [70.56953125000001, 25.705957031249994],
                        [70.6484375, 25.666943359374997],
                        [70.65205078125001, 25.422900390625003],
                        [70.87773437500002, 25.06298828125],
                        [70.95087890625001, 24.8916015625],
                        [71.02070312500001, 24.75766601562499],
                        [71.0478515625, 24.687744140625],
                        [71.00234375000002, 24.65390625],
                        [70.97636718750002, 24.61875],
                        [70.96982421875, 24.571875],
                        [71.04531250000002, 24.429980468750003],
                        [71.04404296875, 24.400097656249997],
                        [70.98281250000002, 24.361035156249997],
                        [70.928125, 24.362353515625003],
                        [70.88623046875, 24.34375],
                        [70.80507812500002, 24.261962890625],
                        [70.76728515625001, 24.245410156250003],
                        [70.71630859375, 24.237988281249997],
                        [70.65947265625002, 24.24609375],
                        [70.57929687500001, 24.279052734375],
                        [70.55585937500001, 24.331103515625003],
                        [70.5650390625, 24.385791015625003],
                        [70.54677734375002, 24.41831054687499],
                        [70.2890625, 24.35629882812499],
                        [70.0982421875, 24.2875],
                        [69.80517578125, 24.165234375],
                        [69.71621093750002, 24.172607421875],
                        [69.63417968750002, 24.22519531249999],
                        [69.5591796875, 24.273095703124994],
                        [69.44345703125, 24.275390625],
                        [69.23505859375001, 24.268261718749997],
                        [69.11953125000002, 24.26865234374999],
                        [69.05156250000002, 24.286328125],
                        [68.98457031250001, 24.273095703124994],
                        [68.90078125000002, 24.292431640624997],
                        [68.86347656250001, 24.266503906249994],
                        [68.82832031250001, 24.26401367187499],
                        [68.78115234375002, 24.313720703125],
                        [68.75898437500001, 24.30722656249999],
                        [68.73964843750002, 24.2919921875],
                        [68.728125, 24.265625],
                        [68.72412109375, 23.96469726562499],
                        [68.48867187500002, 23.967236328124997],
                        [68.38125, 23.950878906249997],
                        [68.28251953125002, 23.927978515625],
                        [68.1650390625, 23.857324218749994],
                        [68.11552734375002, 23.753369140624997],
                        [67.8599609375, 23.90268554687499],
                        [67.66845703125, 23.810986328124997],
                        [67.309375, 24.1748046875],
                        [67.171484375, 24.756103515625],
                        [66.70302734375002, 24.8609375],
                        [66.69863281250002, 25.226318359375],
                        [66.32421875, 25.601806640625],
                        [66.13115234375002, 25.49326171874999],
                        [66.46767578125002, 25.4453125],
                        [64.77666015625002, 25.307324218749997],
                        [64.65898437500002, 25.18408203125],
                        [64.059375, 25.40292968749999],
                        [63.556640625, 25.353173828124994],
                        [63.49140625000001, 25.210839843749994],
                        [61.56689453125, 25.186328125],
                        [61.587890625, 25.20234375],
                        [61.61542968750001, 25.2861328125],
                        [61.64013671875, 25.584619140624994],
                        [61.67138671875, 25.6923828125],
                        [61.66181640625001, 25.751269531250003],
                        [61.66865234375001, 25.768994140624997],
                        [61.73769531250002, 25.82109375],
                        [61.75439453125, 25.84335937499999],
                        [61.78076171875, 25.995849609375],
                        [61.80996093750002, 26.165283203125],
                        [61.842382812500006, 26.225927734375],
                        [62.1259765625, 26.368994140625],
                        [62.239355468750006, 26.35703125],
                        [62.31230468750002, 26.490869140624994],
                        [63.168066406250006, 26.665576171874996],
                        [63.186132812500006, 26.837597656249997],
                        [63.24160156250002, 26.86474609375],
                        [63.25039062500002, 26.879248046875],
                        [63.24208984375002, 27.077685546874996],
                        [63.30517578125, 27.124560546874996],
                        [63.30156250000002, 27.15146484375],
                        [63.25625, 27.207910156249994],
                        [63.19609375000002, 27.243945312499996],
                        [63.16679687500002, 27.252490234374996],
                        [62.75273437500002, 27.265625],
                        [62.782324218750006, 27.800537109375],
                        [62.7625, 28.202050781249994],
                        [61.88984375000001, 28.546533203124994],
                        [61.15214843750002, 29.542724609375],
                        [61.0341796875, 29.663427734375],
                        [60.843359375, 29.858691406249996],
                        [61.22441406250002, 29.749414062499994],
                        [62.0009765625, 29.530419921874994],
                        [62.4765625, 29.408349609374994],
                        [63.56757812500001, 29.497998046874997],
                        [64.09873046875, 29.391943359375],
                        [64.39375, 29.544335937499994],
                        [65.09550781250002, 29.559472656249994],
                        [66.23125, 29.86572265625],
                        [66.346875, 30.802783203124996],
                        [66.82929687500001, 31.263671875],
                        [67.45283203125001, 31.234619140625],
                        [67.737890625, 31.343945312499997],
                        [67.57822265625, 31.506494140624994],
                        [68.16103515625002, 31.802978515625],
                        [68.59765625, 31.802978515625],
                        [68.86894531250002, 31.634228515624997],
                        [69.279296875, 31.936816406249996],
                        [69.24140625000001, 32.433544921875],
                        [69.5015625, 33.020068359374996],
                        [70.26113281250002, 33.289013671875],
                        [69.8896484375, 34.007275390625],
                        [70.65400390625001, 33.952294921874994],
                        [71.05156250000002, 34.049707031249994],
                        [71.095703125, 34.369433593749996],
                        [70.965625, 34.53037109375],
                        [71.62050781250002, 35.183007812499994],
                        [71.57197265625001, 35.546826171875],
                        [71.18505859375, 36.04208984375],
                        [71.23291015625, 36.12177734375],
                        [72.24980468750002, 36.734716796875],
                        [73.08961802927895, 36.86435907947333],
                    ],
                ],
            },
            properties: {
                name: "巴基斯坦",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-81.60327148437497, 7.332812499999989],
                            [-81.85205078125003, 7.453320312500026],
                            [-81.812158203125, 7.59238281250002],
                            [-81.72875976562494, 7.62119140625002],
                            [-81.60327148437497, 7.332812499999989],
                        ],
                    ],
                    [
                        [
                            [-78.89833984375002, 8.27426757812502],
                            [-78.960595703125, 8.435839843749989],
                            [-78.88325195312495, 8.460253906249989],
                            [-78.89833984375002, 8.27426757812502],
                        ],
                    ],
                    [
                        [
                            [-77.37421874999993, 8.65830078125002],
                            [-77.47851562499994, 8.498437500000037],
                            [-77.19599609374995, 7.972460937500003],
                            [-77.53828124999995, 7.56625976562502],
                            [-77.76191406249995, 7.698828125000034],
                            [-77.90117187499999, 7.229345703125048],
                            [-78.42158203124995, 8.060986328125011],
                            [-78.28735351562497, 8.091796874999972],
                            [-78.14189453125002, 8.386083984374977],
                            [-77.76054687499993, 8.133251953124983],
                            [-78.09946289062498, 8.496972656250009],
                            [-78.22304687500002, 8.396630859374994],
                            [-78.39921874999993, 8.505664062500003],
                            [-78.40986328124998, 8.35532226562502],
                            [-78.51406249999997, 8.628173828125],
                            [-79.08637695312495, 8.997167968750034],
                            [-79.50708007812494, 8.97006835937502],
                            [-79.68745117187493, 8.850976562500009],
                            [-79.81591796875, 8.639208984375031],
                            [-79.75043945312498, 8.595507812500017],
                            [-80.458984375, 8.213867187499972],
                            [-80.45810546875, 8.077050781249994],
                            [-80.01123046875, 7.500048828125031],
                            [-80.66669921874995, 7.225683593750006],
                            [-80.90122070312503, 7.277148437500017],
                            [-81.06386718749994, 7.89975585937502],
                            [-81.26840820312495, 7.625488281250014],
                            [-81.50415039062503, 7.721191406249972],
                            [-81.72763671875, 8.137548828124977],
                            [-82.15986328124995, 8.19482421875],
                            [-82.23544921874998, 8.311035156250057],
                            [-82.67954101562503, 8.321972656249969],
                            [-82.86611328124994, 8.246337890625014],
                            [-82.87934570312498, 8.07065429687502],
                            [-83.02734375, 8.337744140624991],
                            [-82.86162109374999, 8.453515625000037],
                            [-82.84477539062493, 8.489355468749963],
                            [-82.85571289062494, 8.635302734375031],
                            [-82.91704101562502, 8.740332031250034],
                            [-82.88198242187497, 8.805322265625037],
                            [-82.72783203125002, 8.916064453125031],
                            [-82.78305664062498, 8.990283203124974],
                            [-82.88134765625003, 9.055859375000011],
                            [-82.94033203124997, 9.060107421874989],
                            [-82.93984374999994, 9.449169921875026],
                            [-82.92504882812494, 9.469042968749989],
                            [-82.88896484374999, 9.481005859375017],
                            [-82.86015625, 9.511474609375014],
                            [-82.84399414062497, 9.570800781250014],
                            [-82.801025390625, 9.591796875000028],
                            [-82.64409179687502, 9.505859375000028],
                            [-82.56357421875003, 9.576660156249972],
                            [-82.50034179687503, 9.523242187500017],
                            [-82.37080078124993, 9.428564453124991],
                            [-82.33974609375, 9.209179687499983],
                            [-82.18813476562502, 9.191748046874977],
                            [-82.24418945312499, 9.031494140625014],
                            [-82.07788085937503, 8.93486328124996],
                            [-81.78022460937495, 8.957226562499983],
                            [-81.89448242187495, 9.140429687500003],
                            [-81.35478515624996, 8.78056640624996],
                            [-80.83867187499999, 8.887207031250014],
                            [-80.12709960937497, 9.20991210937504],
                            [-79.57729492187497, 9.597851562500026],
                            [-78.08276367187494, 9.236279296874997],
                            [-77.37421874999993, 8.65830078125002],
                        ],
                    ],
                ],
            },
            properties: {
                name: "巴拿马",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-73.137353515625, -6.4658203125],
                        [-73.75810546874999, -6.90576171875],
                        [-73.79301757812499, -7.135058593750003],
                        [-73.758203125, -7.172753906250009],
                        [-73.72041015625, -7.309277343750011],
                        [-73.964306640625, -7.37890625],
                        [-73.95849609375, -7.506640625],
                        [-73.98173828124999, -7.535742187500006],
                        [-74.00205078124999, -7.556054687500009],
                        [-73.98173828124999, -7.585058593750006],
                        [-73.946875, -7.611230468750009],
                        [-73.89462890624999, -7.65478515625],
                        [-73.82207031249999, -7.738964843750011],
                        [-73.76689453124999, -7.753515625],
                        [-73.72041015625, -7.782519531250003],
                        [-73.73203125, -7.875390625],
                        [-73.54912109374999, -8.345800781250006],
                        [-73.39814453125, -8.458984375],
                        [-73.36040039062499, -8.479296875],
                        [-73.351708984375, -8.51416015625],
                        [-73.35673828124999, -8.566992187500006],
                        [-73.30244140625, -8.654003906250011],
                        [-73.203125, -8.719335937500006],
                        [-73.0705078125, -8.8828125],
                        [-72.9740234375, -8.9931640625],
                        [-72.970361328125, -9.1201171875],
                        [-73.08984375, -9.265722656250006],
                        [-73.209423828125, -9.411425781250003],
                        [-72.379052734375, -9.51015625],
                        [-72.181591796875, -10.003710937500003],
                        [-71.33940429687499, -9.988574218750003],
                        [-71.11528320312499, -9.852441406250009],
                        [-71.041748046875, -9.81875],
                        [-70.6369140625, -9.478222656250011],
                        [-70.60791015625, -9.463671875],
                        [-70.54111328124999, -9.4375],
                        [-70.57016601562499, -9.48984375],
                        [-70.592236328125, -9.54345703125],
                        [-70.59916992187499, -9.620507812500009],
                        [-70.642333984375, -11.01025390625],
                        [-70.59653320312499, -10.976855468750003],
                        [-70.53325195312499, -10.946875],
                        [-70.45087890625, -11.024804687500009],
                        [-70.39228515625, -11.05859375],
                        [-70.3419921875, -11.066699218750003],
                        [-70.29038085937499, -11.064257812500003],
                        [-70.22006835937499, -11.04765625],
                        [-70.06630859375, -10.982421875],
                        [-69.9603515625, -10.929882812500011],
                        [-69.839794921875, -10.933398437500003],
                        [-69.6740234375, -10.9541015625],
                        [-69.57861328125, -10.951757812500006],
                        [-68.68525390625, -12.501953125],
                        [-68.97861328124999, -12.880078125000011],
                        [-69.07412109375, -13.682812500000011],
                        [-68.87089843749999, -14.169726562500003],
                        [-69.35947265624999, -14.7953125],
                        [-69.37470703125, -14.962988281250006],
                        [-69.17246093749999, -15.236621093750003],
                        [-69.4208984375, -15.640625],
                        [-69.21757812499999, -16.14912109375001],
                        [-68.8427734375, -16.337890625],
                        [-69.03291015625, -16.47597656250001],
                        [-69.020703125, -16.6421875],
                        [-69.62485351562499, -17.2001953125],
                        [-69.645703125, -17.24853515625],
                        [-69.521923828125, -17.388964843750003],
                        [-69.510986328125, -17.46035156250001],
                        [-69.51108398437499, -17.5048828125],
                        [-69.5109375, -17.50605468750001],
                        [-69.58642578125, -17.5732421875],
                        [-69.684765625, -17.64980468750001],
                        [-69.85209960937499, -17.70380859375001],
                        [-69.80258789062499, -17.990234375],
                        [-69.92636718749999, -18.2060546875],
                        [-70.41826171874999, -18.34560546875001],
                        [-71.33696289062499, -17.68251953125001],
                        [-71.5322265625, -17.29433593750001],
                        [-72.46767578125, -16.708105468750006],
                        [-73.727685546875, -16.20166015625],
                        [-75.104248046875, -15.411914062500003],
                        [-75.533642578125, -14.89921875],
                        [-75.93388671874999, -14.63359375],
                        [-76.37646484375, -13.863085937500003],
                        [-76.259228515625, -13.802832031250006],
                        [-76.2236328125, -13.371191406250006],
                        [-76.83212890624999, -12.348730468750006],
                        [-77.152734375, -12.060351562500003],
                        [-77.2203125, -11.663378906250003],
                        [-77.633203125, -11.287792968750011],
                        [-77.736083984375, -10.83671875],
                        [-78.18559570312499, -10.089062500000011],
                        [-78.76225585937499, -8.616992187500003],
                        [-79.37724609374999, -7.835546875],
                        [-79.99497070312499, -6.768945312500009],
                        [-81.142041015625, -6.056738281250006],
                        [-81.164306640625, -5.875292968750003],
                        [-80.9306640625, -5.8408203125],
                        [-80.88193359374999, -5.635058593750003],
                        [-81.33662109375, -4.66953125],
                        [-81.283203125, -4.322265625],
                        [-80.503662109375, -3.49609375],
                        [-80.324658203125, -3.387890625000011],
                        [-80.24375, -3.576757812500006],
                        [-80.19414062499999, -3.905859375],
                        [-80.23051757812499, -3.924023437500011],
                        [-80.26689453124999, -3.948828125],
                        [-80.30327148437499, -4.005078125000011],
                        [-80.43720703125, -3.978613281250006],
                        [-80.49013671875, -4.010058593750003],
                        [-80.510009765625, -4.069531250000011],
                        [-80.49345703124999, -4.119140625],
                        [-80.4884765625, -4.16552734375],
                        [-80.453759765625, -4.205175781250006],
                        [-80.35288085937499, -4.20849609375],
                        [-80.44384765625, -4.335839843750009],
                        [-80.4884765625, -4.393652343750006],
                        [-80.47856445312499, -4.430078125],
                        [-80.42416992187499, -4.46142578125],
                        [-80.38349609375, -4.463671875],
                        [-80.293359375, -4.416796875],
                        [-80.1974609375, -4.31103515625],
                        [-80.13955078125, -4.296093750000011],
                        [-80.06352539062499, -4.327539062500009],
                        [-79.962890625, -4.390332031250011],
                        [-79.8451171875, -4.445898437500006],
                        [-79.797265625, -4.476367187500003],
                        [-79.71098632812499, -4.467578125],
                        [-79.63852539062499, -4.454882812500003],
                        [-79.57768554687499, -4.500585937500006],
                        [-79.51616210937499, -4.539160156250006],
                        [-79.501904296875, -4.670605468750011],
                        [-79.45576171875, -4.766210937500006],
                        [-79.3994140625, -4.840039062500011],
                        [-79.33095703125, -4.927832031250006],
                        [-79.26811523437499, -4.957617187500006],
                        [-79.186669921875, -4.958203125000011],
                        [-79.07626953124999, -4.990625],
                        [-79.03330078124999, -4.969140625],
                        [-78.995263671875, -4.908007812500003],
                        [-78.97539062499999, -4.873242187500011],
                        [-78.919189453125, -4.8583984375],
                        [-78.92578125, -4.770703125000011],
                        [-78.9076171875, -4.714453125],
                        [-78.8615234375, -4.6650390625],
                        [-78.68603515625, -4.562402343750009],
                        [-78.64799804687499, -4.248144531250006],
                        [-78.345361328125, -3.397363281250009],
                        [-78.240380859375, -3.472558593750009],
                        [-77.860595703125, -2.981640625000011],
                        [-76.6791015625, -2.562597656250006],
                        [-76.089794921875, -2.133105468750003],
                        [-75.570556640625, -1.53125],
                        [-75.42041015625, -0.962207031250003],
                        [-75.40805664062499, -0.92431640625],
                        [-75.24960937499999, -0.951855468750011],
                        [-75.259375, -0.590136718750003],
                        [-75.42470703125, -0.408886718750011],
                        [-75.49106445312499, -0.248339843750003],
                        [-75.56059570312499, -0.200097656250009],
                        [-75.63203125, -0.157617187500009],
                        [-75.62626953124999, -0.122851562500003],
                        [-75.340478515625, -0.1421875],
                        [-75.13837890625, -0.050488281250011],
                        [-74.8017578125, -0.200097656250009],
                        [-74.78046875, -0.24453125],
                        [-74.75537109375, -0.298632812500003],
                        [-74.691650390625, -0.335253906250003],
                        [-74.616357421875, -0.370019531250009],
                        [-74.555078125, -0.429882812500011],
                        [-74.5138671875, -0.470117187500009],
                        [-74.46518554687499, -0.517675781250006],
                        [-74.41787109375, -0.580664062500006],
                        [-74.334423828125, -0.850878906250003],
                        [-74.28388671875, -0.927832031250006],
                        [-74.24638671874999, -0.970605468750009],
                        [-74.05439453125, -1.028613281250003],
                        [-73.98681640625, -1.09814453125],
                        [-73.926953125, -1.125195312500011],
                        [-73.86318359375, -1.196679687500009],
                        [-73.664306640625, -1.248828125],
                        [-73.4962890625, -1.693066406250011],
                        [-73.19697265625, -1.830273437500011],
                        [-73.1544921875, -2.278222656250009],
                        [-72.9896484375, -2.339746093750009],
                        [-72.94111328125, -2.39404296875],
                        [-72.21845703125, -2.400488281250006],
                        [-71.98427734375, -2.3265625],
                        [-71.93247070312499, -2.288671875],
                        [-71.86728515624999, -2.227734375000011],
                        [-71.802734375, -2.166308593750003],
                        [-71.75253906249999, -2.152734375],
                        [-71.55947265625, -2.22421875],
                        [-71.39697265625, -2.334082031250006],
                        [-71.19638671874999, -2.313085937500006],
                        [-71.11337890624999, -2.245410156250003],
                        [-71.027294921875, -2.225781250000011],
                        [-70.96855468749999, -2.206835937500003],
                        [-70.70537109374999, -2.341992187500011],
                        [-70.64799804687499, -2.40576171875],
                        [-70.57587890625, -2.418261718750003],
                        [-70.29462890625, -2.552539062500003],
                        [-70.24443359374999, -2.606542968750006],
                        [-70.16474609375, -2.639843750000011],
                        [-70.095849609375, -2.658203125],
                        [-70.735107421875, -3.781542968750003],
                        [-70.5296875, -3.866406250000011],
                        [-70.48583984375, -3.869335937500011],
                        [-70.42109375, -3.849609375],
                        [-70.37919921874999, -3.81875],
                        [-70.339501953125, -3.814355468750009],
                        [-70.2984375, -3.84423828125],
                        [-70.24028320312499, -3.882714843750009],
                        [-70.16752929687499, -4.050195312500009],
                        [-70.0171875, -4.162011718750009],
                        [-69.96591796874999, -4.2359375],
                        [-69.97202148437499, -4.301171875],
                        [-70.00395507812499, -4.327246093750006],
                        [-70.05332031249999, -4.333105468750006],
                        [-70.12880859375, -4.28662109375],
                        [-70.23916015625, -4.301171875],
                        [-70.31689453125, -4.246972656250009],
                        [-70.34365234375, -4.193652343750003],
                        [-70.40463867187499, -4.150097656250011],
                        [-70.5306640625, -4.167578125],
                        [-70.72158203125, -4.158886718750011],
                        [-70.79951171875, -4.17333984375],
                        [-70.97368164062499, -4.350488281250009],
                        [-71.8447265625, -4.50439453125],
                        [-72.256787109375, -4.748925781250009],
                        [-72.35283203124999, -4.786035156250009],
                        [-72.468994140625, -4.901269531250009],
                        [-72.608349609375, -5.009570312500003],
                        [-72.69873046875, -5.0671875],
                        [-72.83193359375, -5.09375],
                        [-72.88706054687499, -5.122753906250011],
                        [-72.9798828125, -5.634863281250006],
                        [-73.16289062499999, -5.933398437500003],
                        [-73.209375, -6.028710937500009],
                        [-73.235546875, -6.0984375],
                        [-73.137353515625, -6.4658203125],
                    ],
                ],
            },
            properties: {
                name: "秘鲁",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [120.250390625, 5.256591796875043],
                            [119.82148437500004, 5.06953125000004],
                            [120.1652343750001, 5.332421875000037],
                            [120.250390625, 5.256591796875043],
                        ],
                    ],
                    [
                        [
                            [121.159375, 6.075634765625011],
                            [121.41103515625005, 5.939843749999966],
                            [121.29443359374997, 5.869970703125034],
                            [120.8763671875, 5.95263671875],
                            [121.159375, 6.075634765625011],
                        ],
                    ],
                    [
                        [
                            [122.09287109375012, 6.428320312500006],
                            [121.95917968750004, 6.415820312500045],
                            [121.83203125000003, 6.664062499999986],
                            [122.0583007812501, 6.740722656249972],
                            [122.32353515625002, 6.602246093750011],
                            [122.09287109375012, 6.428320312500006],
                        ],
                    ],
                    [
                        [
                            [122.93710937500006, 7.409130859374983],
                            [122.80468750000003, 7.315966796875017],
                            [122.82216796875, 7.428466796875014],
                            [122.93710937500006, 7.409130859374983],
                        ],
                    ],
                    [
                        [
                            [117.07988281250007, 7.883398437499977],
                            [117.02832031249997, 7.807519531249966],
                            [116.96953125000007, 7.894921875],
                            [116.9935546875, 8.050537109375014],
                            [117.07705078125, 8.069140624999974],
                            [117.07988281250007, 7.883398437499977],
                        ],
                    ],
                    [
                        [
                            [117.35527343750002, 8.21464843749996],
                            [117.28701171875, 8.191015625000034],
                            [117.28085937500006, 8.314990234374974],
                            [117.35527343750002, 8.21464843749996],
                        ],
                    ],
                    [
                        [
                            [124.80664062500003, 9.142626953125003],
                            [124.66582031250002, 9.132324218750043],
                            [124.65332031250003, 9.225830078125],
                            [124.80664062500003, 9.142626953125003],
                        ],
                    ],
                    [
                        [
                            [123.69765625000005, 9.237304687500028],
                            [123.61445312500004, 9.103320312499989],
                            [123.49345703125002, 9.192089843750054],
                            [123.69765625000005, 9.237304687500028],
                        ],
                    ],
                    [
                        [
                            [126.00595703125006, 9.320947265625009],
                            [126.19335937499997, 9.276708984374963],
                            [126.30458984375, 8.952050781249994],
                            [126.13955078125005, 8.59565429687504],
                            [126.36533203125012, 8.483886718750014],
                            [126.45869140625004, 8.20283203125004],
                            [126.43535156250002, 7.832812499999974],
                            [126.57011718750002, 7.677246093749986],
                            [126.58154296875003, 7.247753906249969],
                            [126.1920898437501, 6.852539062500014],
                            [126.18935546875, 6.309667968749991],
                            [125.82441406250004, 7.333300781249989],
                            [125.68925781250007, 7.263037109374977],
                            [125.38066406250007, 6.689941406250014],
                            [125.58847656250012, 6.465771484374997],
                            [125.66796874999997, 5.97866210937498],
                            [125.34648437500002, 5.598974609374977],
                            [125.23154296875006, 6.069531250000011],
                            [124.92734375000006, 5.875341796874977],
                            [124.21279296875, 6.233251953124977],
                            [124.078125, 6.404443359375037],
                            [123.98525390625, 6.993701171875003],
                            [124.20664062500006, 7.396435546874983],
                            [123.66582031250002, 7.817773437500023],
                            [123.49306640625, 7.80791015624996],
                            [123.39091796875007, 7.407519531250017],
                            [123.09667968749997, 7.700439453125],
                            [122.8429687500001, 7.529296875000043],
                            [122.79179687500002, 7.72246093749996],
                            [122.61621093749997, 7.763134765624983],
                            [122.14248046875, 6.949658203124997],
                            [121.96425781250005, 6.96821289062504],
                            [121.92460937500002, 7.199511718750003],
                            [122.24335937500004, 7.945117187500031],
                            [122.91113281250003, 8.156445312499997],
                            [123.05058593750002, 8.433935546875048],
                            [123.43457031249997, 8.70332031250004],
                            [123.84921875000006, 8.432714843749977],
                            [123.79941406250006, 8.049121093749989],
                            [124.19765625, 8.229541015624974],
                            [124.40488281250006, 8.599853515625014],
                            [124.7311523437501, 8.562988281250043],
                            [124.86894531250002, 8.972265625000034],
                            [125.141015625, 8.86875],
                            [125.20966796875004, 9.027148437500017],
                            [125.49873046875004, 9.014746093749977],
                            [125.47128906250006, 9.756787109374983],
                            [126.00595703125006, 9.320947265625009],
                        ],
                    ],
                    [
                        [
                            [126.059375, 9.766210937500034],
                            [125.99121093750003, 9.838525390625023],
                            [126.07382812500006, 10.059228515625051],
                            [126.1725585937501, 9.79995117187498],
                            [126.059375, 9.766210937500034],
                        ],
                    ],
                    [
                        [
                            [124.59384765625006, 9.787207031249963],
                            [124.1224609375, 9.599316406249969],
                            [123.93564453125012, 9.623974609375011],
                            [123.81718750000002, 9.817382812499986],
                            [124.17285156250003, 10.135205078124983],
                            [124.33574218750002, 10.159912109375043],
                            [124.57714843749997, 10.026708984374991],
                            [124.59384765625006, 9.787207031249963],
                        ],
                    ],
                    [
                        [
                            [125.69023437500007, 9.914453125000037],
                            [125.49482421875004, 10.118701171875003],
                            [125.66679687500002, 10.440136718750026],
                            [125.69023437500007, 9.914453125000037],
                        ],
                    ],
                    [
                        [
                            [119.91621093750004, 10.485986328125037],
                            [119.79316406250004, 10.455273437499997],
                            [119.85205078124997, 10.64013671875],
                            [120.00839843750012, 10.570117187500031],
                            [119.91621093750004, 10.485986328125037],
                        ],
                    ],
                    [
                        [
                            [122.64951171875012, 10.472705078125003],
                            [122.53837890625002, 10.424951171875037],
                            [122.5375, 10.607568359375023],
                            [122.70126953125006, 10.740625],
                            [122.64951171875012, 10.472705078125003],
                        ],
                    ],
                    [
                        [
                            [123.13085937500003, 9.064111328124994],
                            [122.99472656250006, 9.058837890624986],
                            [122.8666015625, 9.319824218750043],
                            [122.5625, 9.482812500000037],
                            [122.39951171875006, 9.823046874999989],
                            [122.47148437500007, 9.961523437500034],
                            [122.85556640625006, 10.0869140625],
                            [122.81699218750012, 10.503808593750023],
                            [122.98330078125, 10.886621093750037],
                            [123.25664062500007, 10.99394531249996],
                            [123.51064453125005, 10.923046875],
                            [123.5675781250001, 10.780761718750057],
                            [123.16201171875, 9.864257812500028],
                            [123.1498046875, 9.606152343750026],
                            [123.32050781250004, 9.27294921875],
                            [123.13085937500003, 9.064111328124994],
                        ],
                    ],
                    [
                        [
                            [123.37031250000004, 9.449609375000023],
                            [123.38623046874997, 9.967089843750017],
                            [124.03886718750002, 11.273535156249991],
                            [124.00498046875012, 10.40009765625004],
                            [123.70048828125007, 10.128320312500009],
                            [123.37031250000004, 9.449609375000023],
                        ],
                    ],
                    [
                        [
                            [123.75703125000004, 11.28330078125002],
                            [123.815625, 11.15073242187502],
                            [123.73671875, 11.151464843749991],
                            [123.75703125000004, 11.28330078125002],
                        ],
                    ],
                    [
                        [
                            [117.31113281250012, 8.439599609375051],
                            [117.21855468750007, 8.367285156249963],
                            [117.34990234375002, 8.713574218749997],
                            [119.22382812500004, 10.477294921875043],
                            [119.30566406250003, 10.9736328125],
                            [119.55332031250012, 11.31352539062496],
                            [119.52666015625002, 10.953173828125003],
                            [119.68691406250005, 10.500341796875034],
                            [119.36933593750004, 10.327294921875037],
                            [119.19150390625012, 10.061083984374989],
                            [118.78212890625005, 9.91611328125002],
                            [118.4349609375, 9.256005859375009],
                            [117.31113281250012, 8.439599609375051],
                        ],
                    ],
                    [
                        [
                            [119.86142578125006, 11.52534179687504],
                            [119.83066406250012, 11.375683593750011],
                            [119.72998046874997, 11.431933593750017],
                            [119.86142578125006, 11.52534179687504],
                        ],
                    ],
                    [
                        [
                            [124.574609375, 11.343066406250031],
                            [124.92998046875002, 11.372851562499974],
                            [125.02656250000004, 11.21171875],
                            [125.01318359374997, 10.785693359374989],
                            [125.26845703125005, 10.307714843750048],
                            [125.14257812499997, 10.189453125000028],
                            [124.9875, 10.36757812499998],
                            [125.02656250000004, 10.033105468749966],
                            [124.78076171874997, 10.16806640625002],
                            [124.78671875000012, 10.781396484375009],
                            [124.66269531250006, 10.961962890625017],
                            [124.44550781250004, 10.923583984375014],
                            [124.33066406250012, 11.535205078125003],
                            [124.574609375, 11.343066406250031],
                        ],
                    ],
                    [
                        [
                            [124.60839843750003, 11.492187500000043],
                            [124.48349609375006, 11.485839843749986],
                            [124.36035156250003, 11.665917968749994],
                            [124.5109375000001, 11.687109375000048],
                            [124.60839843750003, 11.492187500000043],
                        ],
                    ],
                    [
                        [
                            [122.49619140625006, 11.615087890625034],
                            [122.83808593750004, 11.595654296874983],
                            [122.89453125000003, 11.44130859374998],
                            [123.15830078125012, 11.53554687499999],
                            [123.11953125, 11.286816406250026],
                            [122.8029296875001, 10.99003906249996],
                            [122.76992187500005, 10.823828125000034],
                            [121.95400390625, 10.444384765625003],
                            [122.10351562499997, 11.64291992187502],
                            [121.91601562499997, 11.854345703125006],
                            [122.02919921875005, 11.895410156250023],
                            [122.49619140625006, 11.615087890625034],
                        ],
                    ],
                    [
                        [
                            [120.03876953125004, 11.703320312499969],
                            [119.94492187500006, 11.690722656249989],
                            [119.86093750000006, 11.953955078124963],
                            [120.03593750000002, 11.917236328125028],
                            [120.03876953125004, 11.703320312499969],
                        ],
                    ],
                    [
                        [
                            [120.1, 12.167675781249983],
                            [120.22822265625004, 12.219824218750034],
                            [120.31455078125012, 12.012402343749969],
                            [120.01054687500002, 12.008251953125011],
                            [119.88574218749997, 12.299853515625003],
                            [120.1, 12.167675781249983],
                        ],
                    ],
                    [
                        [
                            [122.65449218750004, 12.309033203125011],
                            [122.42294921875006, 12.455078125],
                            [122.60361328125006, 12.49160156249998],
                            [122.65449218750004, 12.309033203125011],
                        ],
                    ],
                    [
                        [
                            [125.23955078125002, 12.527880859375003],
                            [125.32021484375, 12.321826171875031],
                            [125.53564453125003, 12.191406250000028],
                            [125.49179687500006, 11.594335937499977],
                            [125.57353515625002, 11.238232421874997],
                            [125.73564453125002, 11.049609375000017],
                            [125.23339843749997, 11.145068359375017],
                            [125.03427734375012, 11.341259765625026],
                            [124.91699218750003, 11.558398437500031],
                            [124.99501953125, 11.764941406250003],
                            [124.445703125, 12.152783203124969],
                            [124.29472656250007, 12.569335937500014],
                            [125.23955078125002, 12.527880859375003],
                        ],
                    ],
                    [
                        [
                            [123.71660156250007, 12.287353515625028],
                            [124.04033203125002, 11.966796875],
                            [124.04550781250012, 11.752441406250028],
                            [123.47373046875006, 12.21665039062502],
                            [123.15781250000012, 11.925634765624963],
                            [123.23642578125012, 12.583496093750057],
                            [123.71660156250007, 12.287353515625028],
                        ],
                    ],
                    [
                        [
                            [122.09404296875002, 12.354882812500023],
                            [122.01396484375002, 12.105615234375037],
                            [121.9232421875, 12.331298828125014],
                            [122.00156250000006, 12.598535156250009],
                            [122.14501953124997, 12.652636718750017],
                            [122.09404296875002, 12.354882812500023],
                        ],
                    ],
                    [
                        [
                            [123.77539062499997, 12.453906250000031],
                            [123.77910156250002, 12.366259765625031],
                            [123.62148437500005, 12.67490234375002],
                            [123.77539062499997, 12.453906250000031],
                        ],
                    ],
                    [
                        [
                            [123.28183593750006, 12.85341796874998],
                            [123.36718750000003, 12.70083007812498],
                            [122.95751953124997, 13.107177734374986],
                            [123.28183593750006, 12.85341796874998],
                        ],
                    ],
                    [
                        [
                            [120.70439453125002, 13.479492187499986],
                            [121.20273437500006, 13.432324218749969],
                            [121.52275390625007, 13.131201171874991],
                            [121.540625, 12.63818359375],
                            [121.39433593750002, 12.300585937499974],
                            [121.23671875000005, 12.218798828125003],
                            [120.92216796875002, 12.51162109374998],
                            [120.65136718749997, 13.169140625],
                            [120.33847656250012, 13.412353515624986],
                            [120.40126953125, 13.517041015624997],
                            [120.70439453125002, 13.479492187499986],
                        ],
                    ],
                    [
                        [
                            [121.91484375000002, 13.540332031250031],
                            [122.11455078125002, 13.463183593750031],
                            [122.00488281249997, 13.204980468750009],
                            [121.82919921875006, 13.328613281249972],
                            [121.91484375000002, 13.540332031250031],
                        ],
                    ],
                    [
                        [
                            [124.35361328125006, 13.632226562500009],
                            [124.17539062500012, 13.531542968750017],
                            [124.03886718750002, 13.663134765625003],
                            [124.22490234375007, 14.077587890624969],
                            [124.41718750000004, 13.871044921874997],
                            [124.35361328125006, 13.632226562500009],
                        ],
                    ],
                    [
                        [
                            [122.03349609375002, 15.005029296875009],
                            [121.93300781250005, 14.656054687500045],
                            [121.83984374999997, 15.038134765625003],
                            [122.03349609375002, 15.005029296875009],
                        ],
                    ],
                    [
                        [
                            [121.10156249999997, 18.615283203125017],
                            [121.84560546875, 18.29541015625003],
                            [122.03847656250005, 18.32792968749999],
                            [122.14667968750004, 18.486572265625],
                            [122.26552734375005, 18.458837890625034],
                            [122.15234374999997, 17.664404296875006],
                            [122.51914062500012, 17.124853515625034],
                            [122.13515625000005, 16.18481445312503],
                            [121.59531250000012, 15.933251953125023],
                            [121.60703125000006, 15.669824218749994],
                            [121.39228515625004, 15.324414062499969],
                            [121.69541015625006, 14.7373046875],
                            [121.62792968749997, 14.581152343749977],
                            [121.76660156249997, 14.16806640625002],
                            [122.21171875000002, 13.930175781250057],
                            [122.2875, 13.996191406250006],
                            [122.19970703125003, 14.148046875000034],
                            [122.6271484375001, 14.317529296875009],
                            [122.93417968750012, 14.18808593750002],
                            [123.101953125, 13.750244140624986],
                            [123.29697265625012, 13.836425781250043],
                            [123.32031249999997, 14.061669921875023],
                            [123.81572265625002, 13.837109375000011],
                            [123.80625000000012, 13.721728515625045],
                            [123.54960937500007, 13.645751953125014],
                            [123.81923828125, 13.269482421875011],
                            [123.78515625000003, 13.110546875000054],
                            [124.14277343750004, 13.035791015625009],
                            [124.0597656250001, 12.567089843749997],
                            [123.87783203125005, 12.689697265625014],
                            [123.94853515625007, 12.916406250000023],
                            [123.31093750000005, 13.044091796875009],
                            [123.16328125000004, 13.44174804687502],
                            [122.59521484374997, 13.90761718749998],
                            [122.46796875000004, 13.886718749999986],
                            [122.66787109375, 13.395361328124991],
                            [122.59990234375002, 13.194140625000031],
                            [122.37656250000012, 13.520605468750006],
                            [121.77792968750006, 13.93764648437498],
                            [121.50107421875006, 13.8421875],
                            [121.344140625, 13.649121093749997],
                            [121.09550781250007, 13.679492187500045],
                            [120.84072265625, 13.884716796875026],
                            [120.637109375, 13.804492187500031],
                            [120.61679687500006, 14.188037109375003],
                            [120.9220703125001, 14.493115234374983],
                            [120.94130859375, 14.645068359375031],
                            [120.58369140625004, 14.88125],
                            [120.58867187500002, 14.483105468749983],
                            [120.43876953125002, 14.453369140624972],
                            [120.25078125000002, 14.793310546875034],
                            [120.08212890625012, 14.851074218749986],
                            [119.77255859375012, 16.25512695312503],
                            [119.83076171875004, 16.326562500000023],
                            [120.15976562500012, 16.047656250000045],
                            [120.36875, 16.109570312499955],
                            [120.35839843749997, 17.63818359375],
                            [120.59970703125012, 18.507861328125074],
                            [121.10156249999997, 18.615283203125017],
                        ],
                    ],
                    [
                        [
                            [121.92167968750007, 18.89472656250001],
                            [121.82519531250003, 18.842724609374983],
                            [121.94335937500003, 19.010449218749955],
                            [121.92167968750007, 18.89472656250001],
                        ],
                    ],
                    [
                        [
                            [121.52089843750005, 19.361962890624994],
                            [121.53125, 19.271337890625006],
                            [121.37460937500006, 19.356298828124977],
                            [121.52089843750005, 19.361962890624994],
                        ],
                    ],
                ],
            },
            properties: {
                name: "菲律宾",
                childNum: 37,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [134.5954101562501, 7.382031249999969],
                        [134.51572265625012, 7.525781250000037],
                        [134.65117187500002, 7.712109374999983],
                        [134.5954101562501, 7.382031249999969],
                    ],
                ],
            },
            properties: {
                name: "帕劳",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [153.53613281249997, -11.476171874999949],
                            [153.75986328125006, -11.586328125],
                            [153.55371093749997, -11.630566406249969],
                            [153.28681640625004, -11.516992187500009],
                            [153.20361328124997, -11.32412109374998],
                            [153.53613281249997, -11.476171874999949],
                        ],
                    ],
                    [
                        [
                            [154.28076171874997, -11.36142578125002],
                            [154.12119140625006, -11.425683593749966],
                            [154.02343750000003, -11.347949218750031],
                            [154.28076171874997, -11.36142578125002],
                        ],
                    ],
                    [
                        [
                            [150.89873046875002, -10.565332031250023],
                            [150.88466796875, -10.643457031250037],
                            [150.78574218750006, -10.603417968749966],
                            [150.89873046875002, -10.565332031250023],
                        ],
                    ],
                    [
                        [
                            [151.08095703125, -10.020117187499963],
                            [151.29648437500012, -9.956738281250026],
                            [151.230859375, -10.194726562500009],
                            [150.95917968750004, -10.092578124999989],
                            [150.77607421875004, -9.70908203125002],
                            [151.08095703125, -10.020117187499963],
                        ],
                    ],
                    [
                        [
                            [150.52841796875006, -9.34658203124998],
                            [150.78867187500006, -9.417968749999957],
                            [150.89404296875003, -9.667480468749986],
                            [150.43623046875004, -9.624609374999949],
                            [150.5084960937501, -9.536132812499957],
                            [150.43730468750007, -9.359960937500034],
                            [150.52841796875006, -9.34658203124998],
                        ],
                    ],
                    [
                        [
                            [150.3454101562501, -9.493847656249955],
                            [150.10976562500005, -9.361914062499991],
                            [150.20830078125002, -9.206347656250003],
                            [150.32011718750007, -9.264160156249972],
                            [150.3454101562501, -9.493847656249955],
                        ],
                    ],
                    [
                        [
                            [152.63095703125012, -8.959375],
                            [152.95292968750007, -9.07011718749996],
                            [152.96689453125006, -9.208984375000014],
                            [152.51513671874997, -9.009863281250034],
                            [152.63095703125012, -8.959375],
                        ],
                    ],
                    [
                        [
                            [151.10683593750005, -8.733496093749949],
                            [151.12412109375012, -8.804882812500011],
                            [151.00498046875006, -8.523828124999952],
                            [151.117578125, -8.41884765624998],
                            [151.10683593750005, -8.733496093749949],
                        ],
                    ],
                    [
                        [
                            [143.58681640625005, -8.481738281250003],
                            [143.321875, -8.367578125],
                            [143.5814453125, -8.390917968749974],
                            [143.58681640625005, -8.481738281250003],
                        ],
                    ],
                    [
                        [
                            [148.02578125, -5.826367187500011],
                            [147.78105468750007, -5.627246093749946],
                            [147.7946289062501, -5.492382812500011],
                            [148.05478515625006, -5.61152343750004],
                            [148.02578125, -5.826367187500011],
                        ],
                    ],
                    [
                        [
                            [155.95761718750006, -6.686816406249989],
                            [155.71933593750012, -6.862792968749957],
                            [155.34404296875007, -6.721679687499986],
                            [155.20214843750003, -6.3076171875],
                            [154.75927734375003, -5.931347656249997],
                            [154.72929687500002, -5.444433593750006],
                            [155.09384765625006, -5.620214843750034],
                            [155.46699218750004, -6.145117187500034],
                            [155.82255859375002, -6.38046875000002],
                            [155.95761718750006, -6.686816406249989],
                        ],
                    ],
                    [
                        [
                            [147.17626953124997, -5.431933593749946],
                            [147.00585937499997, -5.30703125],
                            [147.1310546875001, -5.190820312500037],
                            [147.17626953124997, -5.431933593749946],
                        ],
                    ],
                    [
                        [
                            [154.64726562500002, -5.43271484375002],
                            [154.54003906250003, -5.11083984375],
                            [154.63261718750007, -5.013867187499955],
                            [154.72714843750006, -5.218066406249989],
                            [154.64726562500002, -5.43271484375002],
                        ],
                    ],
                    [
                        [
                            [146.01933593750007, -4.726171874999963],
                            [145.88359375000007, -4.66748046875],
                            [145.9958007812501, -4.539257812499983],
                            [146.01933593750007, -4.726171874999963],
                        ],
                    ],
                    [
                        [
                            [151.915625, -4.296777343749966],
                            [152.11718749999997, -4.212207031249974],
                            [152.40566406250005, -4.340722656249952],
                            [152.35117187500006, -4.82216796874998],
                            [151.98369140625007, -5.07441406250004],
                            [152.14296875, -5.357031249999963],
                            [152.07705078125, -5.458300781249989],
                            [151.86542968750004, -5.564843750000023],
                            [151.51513671874997, -5.552343749999963],
                            [151.22929687500002, -5.919921874999986],
                            [150.47353515625, -6.263378906249969],
                            [149.65253906250004, -6.290429687499966],
                            [149.38232421874997, -6.078125],
                            [149.0990234375, -6.116992187499989],
                            [148.33720703125007, -5.669433593750014],
                            [148.43203125, -5.471777343749991],
                            [149.35888671875003, -5.583984375000014],
                            [149.8314453125, -5.524121093749997],
                            [149.96279296875, -5.447753906249972],
                            [150.0900390625001, -5.011816406249977],
                            [150.1703125, -5.070605468749974],
                            [150.0724609375001, -5.309570312499986],
                            [150.18310546874997, -5.523632812499983],
                            [150.90029296875005, -5.447167968750037],
                            [151.32656250000005, -4.96035156249998],
                            [151.67119140625007, -4.88330078125],
                            [151.59306640625007, -4.200781249999949],
                            [151.915625, -4.296777343749966],
                        ],
                    ],
                    [
                        [
                            [152.67060546875004, -3.13339843750002],
                            [152.64619140625004, -3.221191406249957],
                            [152.54326171875002, -3.095605468749952],
                            [152.63876953125012, -3.042773437500031],
                            [152.67060546875004, -3.13339843750002],
                        ],
                    ],
                    [
                        [
                            [140.97617187500012, -9.11875],
                            [140.97519531250006, -6.90537109375002],
                            [140.86230468749997, -6.740039062499989],
                            [140.975, -6.346093750000023],
                            [140.97353515625, -2.803417968750026],
                            [140.97343750000007, -2.609765625],
                            [142.90517578125, -3.32070312499998],
                            [143.50898437500004, -3.431152343750014],
                            [144.06640625000003, -3.80517578125],
                            [144.4777343750001, -3.82529296875002],
                            [145.08779296875, -4.349121093749972],
                            [145.33457031250012, -4.385253906249972],
                            [145.7669921875, -4.823046874999989],
                            [145.74521484375012, -5.402441406249977],
                            [147.56669921875002, -6.056933593750003],
                            [147.80205078125002, -6.31523437499996],
                            [147.84550781250007, -6.662402343749989],
                            [147.11914062499997, -6.721679687499986],
                            [146.95361328124997, -6.834082031249963],
                            [147.19003906250012, -7.378125],
                            [148.12675781250007, -8.103613281249963],
                            [148.246875, -8.554296875000034],
                            [148.45117187499997, -8.694531250000011],
                            [148.58310546875006, -9.051757812499957],
                            [149.19833984375006, -9.03125],
                            [149.26318359374997, -9.497851562499974],
                            [150.01103515625007, -9.688183593750026],
                            [149.76123046874997, -9.805859375000011],
                            [149.87441406250005, -10.012988281250031],
                            [150.84951171875, -10.236035156249997],
                            [150.44609375000007, -10.30732421875004],
                            [150.6471679687501, -10.517968749999966],
                            [150.31992187500012, -10.654882812499963],
                            [150.0167968750001, -10.577148437500028],
                            [149.75410156250004, -10.353027343750028],
                            [147.76865234375012, -10.070117187500031],
                            [147.01718750000006, -9.38789062500004],
                            [146.96376953125, -9.059570312499943],
                            [146.63085937499997, -8.951171874999972],
                            [146.03320312499997, -8.076367187500011],
                            [144.97382812500004, -7.802148437500009],
                            [144.86425781249997, -7.631542968749983],
                            [144.50986328125006, -7.567382812499972],
                            [144.14287109375007, -7.757226562500009],
                            [143.65488281250012, -7.460351562500009],
                            [143.94228515625005, -7.944238281250009],
                            [143.8333984375, -8.029101562499974],
                            [143.51816406250006, -8.000683593749955],
                            [143.61376953125003, -8.200390624999969],
                            [142.52412109375004, -8.32167968749998],
                            [142.34746093750002, -8.167480468750014],
                            [142.20683593750002, -8.195800781250014],
                            [142.47480468750004, -8.369433593750031],
                            [142.79794921875006, -8.345019531250031],
                            [143.11181640624997, -8.474511718750037],
                            [143.37724609375007, -8.762207031250028],
                            [143.36621093750003, -8.961035156250034],
                            [142.6471679687501, -9.327832031249969],
                            [142.22958984375012, -9.169921874999957],
                            [141.13320312500005, -9.221289062500034],
                            [140.97617187500012, -9.11875],
                        ],
                    ],
                    [
                        [
                            [152.96582031249997, -4.756347656249986],
                            [152.89169921875006, -4.832421875000023],
                            [152.73994140625004, -4.635839843750034],
                            [152.66816406250004, -4.131835937500028],
                            [152.27939453125006, -3.582421875],
                            [151.06679687500005, -2.829003906249994],
                            [150.74609374999997, -2.73886718750002],
                            [150.8253906250001, -2.572949218749969],
                            [152.03291015625004, -3.25136718749998],
                            [153.01679687500004, -4.105664062500026],
                            [153.1325195312501, -4.352441406250037],
                            [152.96582031249997, -4.756347656249986],
                        ],
                    ],
                    [
                        [
                            [150.43662109375012, -2.66181640625004],
                            [150.16572265625004, -2.660253906249991],
                            [149.96162109375004, -2.473828125000026],
                            [150.22714843750006, -2.384179687499966],
                            [150.42949218750007, -2.47041015625004],
                            [150.43662109375012, -2.66181640625004],
                        ],
                    ],
                    [
                        [
                            [147.06757812500004, -1.96015625],
                            [147.43808593750012, -2.05898437499998],
                            [147.20634765625007, -2.181933593749974],
                            [146.54648437500012, -2.20859375],
                            [146.65625, -1.97402343749998],
                            [147.06757812500004, -1.96015625],
                        ],
                    ],
                    [
                        [
                            [149.76542968750007, -1.553027343750017],
                            [149.54589843749997, -1.471679687499957],
                            [149.58095703125005, -1.353222656249983],
                            [149.76542968750007, -1.553027343750017],
                        ],
                    ],
                ],
            },
            properties: {
                name: "巴布亚新几内亚",
                childNum: 21,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [23.484667968750017, 53.939794921875],
                        [23.915429687500023, 52.770263671875],
                        [23.175097656250017, 52.28662109375],
                        [23.652441406250006, 52.040380859375],
                        [23.605273437500017, 51.517919921875],
                        [23.664453125000023, 51.31005859375],
                        [24.095800781250006, 50.87275390625],
                        [23.9970703125, 50.809375],
                        [24.089941406250006, 50.53046875],
                        [23.97265625, 50.410058593749994],
                        [23.711718750000017, 50.37734375],
                        [23.03632812500001, 49.899072265624994],
                        [22.706152343750006, 49.606201171875],
                        [22.6494140625, 49.539013671875],
                        [22.66064453125, 49.483691406249996],
                        [22.71992187500001, 49.353808593749996],
                        [22.732421875, 49.295166015625],
                        [22.705664062500006, 49.171191406249996],
                        [22.847070312500023, 49.08125],
                        [22.538671875, 49.072705078125],
                        [22.473046875000023, 49.081298828125],
                        [22.020117187500006, 49.209521484374996],
                        [21.6396484375, 49.411962890625],
                        [21.079394531250017, 49.418261718749996],
                        [20.868457031250017, 49.314697265625],
                        [20.36298828125001, 49.38525390625],
                        [20.0576171875, 49.181298828124994],
                        [19.756640625000017, 49.204394531249996],
                        [19.77392578125, 49.37216796875],
                        [19.44160156250001, 49.597705078124996],
                        [19.1494140625, 49.4],
                        [18.83222656250001, 49.510791015624996],
                        [18.562402343750023, 49.879345703125],
                        [18.0283203125, 50.03525390625],
                        [17.874804687500017, 49.972265625],
                        [17.627050781250006, 50.11640625],
                        [17.702246093750006, 50.307177734374996],
                        [17.41523437500001, 50.254785156249994],
                        [16.88007812500001, 50.427050781249996],
                        [16.989648437500023, 50.2369140625],
                        [16.63916015625, 50.1021484375],
                        [16.210351562500023, 50.423730468749994],
                        [16.419726562500017, 50.573632812499994],
                        [16.2822265625, 50.655615234375],
                        [16.007226562500023, 50.611621093749996],
                        [14.99375, 51.01435546875],
                        [14.98291015625, 50.886572265625],
                        [14.895800781250017, 50.861376953124996],
                        [14.809375, 50.858984375],
                        [14.814257812500017, 50.871630859374996],
                        [14.91748046875, 51.008740234375],
                        [14.9638671875, 51.095117187499994],
                        [14.935546875, 51.435351562499996],
                        [14.905957031250011, 51.463330078125],
                        [14.724707031250006, 51.523876953125],
                        [14.7109375, 51.544921875],
                        [14.738671875000023, 51.6271484375],
                        [14.601660156250006, 51.832373046875],
                        [14.752539062500006, 52.081835937499996],
                        [14.679882812500011, 52.25],
                        [14.615625, 52.277636718749996],
                        [14.573925781250011, 52.31416015625],
                        [14.554589843750023, 52.359667968749996],
                        [14.569726562500023, 52.431103515625],
                        [14.619433593750017, 52.528515625],
                        [14.514062500000023, 52.64560546875],
                        [14.253710937500017, 52.782519531249996],
                        [14.128613281250011, 52.878222656249996],
                        [14.138867187500011, 52.932861328125],
                        [14.293164062500011, 53.0267578125],
                        [14.368554687500023, 53.10556640625],
                        [14.410937500000017, 53.199023437499996],
                        [14.412304687500011, 53.216748046875],
                        [14.41455078125, 53.283496093749996],
                        [14.258886718750006, 53.729638671875],
                        [14.58349609375, 53.63935546875],
                        [14.558398437500017, 53.823193359375],
                        [14.21142578125, 53.950341796875],
                        [16.186328125000017, 54.290380859375],
                        [16.55976562500001, 54.55380859375],
                        [18.32343750000001, 54.838183593749996],
                        [18.75927734375, 54.6845703125],
                        [18.43623046875001, 54.7447265625],
                        [18.83642578125, 54.369580078125],
                        [19.604394531250023, 54.4591796875],
                        [20.20820312500001, 54.420751953125],
                        [22.16845703125, 54.35986328125],
                        [22.731835937500023, 54.35009765625],
                        [22.766210937500006, 54.356787109375],
                        [22.82373046875, 54.395800781249996],
                        [22.893945312500023, 54.39052734375],
                        [22.97675781250001, 54.366357421875],
                        [23.015527343750023, 54.34833984375],
                        [23.04218750000001, 54.30419921875],
                        [23.0875, 54.299462890625],
                        [23.170312500000023, 54.2814453125],
                        [23.282324218750006, 54.24033203125],
                        [23.3701171875, 54.200488281249996],
                        [23.45361328125, 54.14345703125],
                        [23.484667968750017, 53.939794921875],
                    ],
                ],
            },
            properties: {
                name: "波兰",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-65.42558593749999, 18.105615234374994],
                            [-65.57221679687493, 18.137304687499977],
                            [-65.29487304687501, 18.133349609375045],
                            [-65.42558593749999, 18.105615234374994],
                        ],
                    ],
                    [
                        [
                            [-66.12939453125003, 18.444921875000034],
                            [-65.62880859375, 18.381396484375045],
                            [-65.62084960937497, 18.242333984374966],
                            [-65.97080078124995, 17.974365234375],
                            [-67.196875, 17.994189453125045],
                            [-67.2640625, 18.364599609375006],
                            [-67.15864257812501, 18.499218749999983],
                            [-66.12939453125003, 18.444921875000034],
                        ],
                    ],
                ],
            },
            properties: {
                name: "波多黎各",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [130.52695312500012, 42.535400390625],
                        [130.68730468750007, 42.30253906249999],
                        [130.2357421875, 42.183203125000034],
                        [129.75634765624997, 41.712255859375006],
                        [129.70869140625004, 40.857324218749994],
                        [129.34111328125002, 40.72631835937506],
                        [128.51123046874997, 40.130224609375006],
                        [127.56816406250002, 39.78198242187503],
                        [127.39453125000003, 39.207910156249966],
                        [127.78613281250003, 39.084130859374966],
                        [128.37460937500012, 38.6234375],
                        [128.03896484375, 38.30854492187498],
                        [127.09033203125003, 38.28388671875001],
                        [126.63388671875012, 37.78183593750006],
                        [126.36992187500007, 37.87836914062501],
                        [126.11669921875003, 37.74291992187503],
                        [125.76914062500006, 37.98535156250003],
                        [125.35781250000005, 37.72480468749998],
                        [125.31074218750004, 37.843505859375],
                        [124.98876953124997, 37.93144531249999],
                        [125.2067382812501, 38.08154296875],
                        [124.69091796874997, 38.12919921875002],
                        [125.06738281250003, 38.556738281250006],
                        [125.55449218750002, 38.68623046875001],
                        [125.16884765625, 38.80551757812506],
                        [125.40966796875003, 39.28837890625002],
                        [125.36083984375003, 39.52661132812497],
                        [124.77529296875, 39.75805664062506],
                        [124.63828125000006, 39.61508789062506],
                        [124.36210937500002, 40.004052734374994],
                        [124.8893554687501, 40.459814453125006],
                        [125.98906250000002, 40.904638671875034],
                        [126.74306640625, 41.724853515625],
                        [126.95478515625004, 41.76948242187501],
                        [127.17968750000003, 41.531347656250006],
                        [128.14941406249997, 41.38774414062496],
                        [128.28925781250004, 41.60742187500006],
                        [128.04521484375007, 41.9875],
                        [128.92343750000006, 42.038232421874966],
                        [129.3136718750001, 42.41357421874997],
                        [129.69785156250012, 42.448144531249994],
                        [129.89824218750002, 42.998144531250034],
                        [130.24033203125006, 42.891796874999955],
                        [130.24667968750012, 42.744824218749955],
                        [130.52695312500012, 42.535400390625],
                    ],
                ],
            },
            properties: {
                name: "朝鲜",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-17.190869140624926, 32.86860351562498],
                            [-16.693261718749966, 32.75800781250001],
                            [-17.018261718749926, 32.66279296874998],
                            [-17.226025390624983, 32.76684570312503],
                            [-17.190869140624926, 32.86860351562498],
                        ],
                    ],
                    [
                        [
                            [-25.64897460937499, 37.840917968750006],
                            [-25.18193359374996, 37.837890625],
                            [-25.19072265624999, 37.764355468749955],
                            [-25.73447265624992, 37.76289062500001],
                            [-25.845898437499983, 37.89404296875],
                            [-25.64897460937499, 37.840917968750006],
                        ],
                    ],
                    [
                        [
                            [-28.14726562499996, 38.45268554687502],
                            [-28.064794921875034, 38.412744140624966],
                            [-28.454492187500023, 38.40864257812504],
                            [-28.54882812499997, 38.51855468750003],
                            [-28.14726562499996, 38.45268554687502],
                        ],
                    ],
                    [
                        [
                            [-28.641308593749983, 38.525],
                            [-28.842041015625, 38.5984375],
                            [-28.69775390625, 38.638476562500045],
                            [-28.641308593749983, 38.525],
                        ],
                    ],
                    [
                        [
                            [-27.07524414062496, 38.643457031249994],
                            [-27.38593750000001, 38.765820312499955],
                            [-27.127001953125017, 38.78984375],
                            [-27.07524414062496, 38.643457031249994],
                        ],
                    ],
                    [
                        [
                            [-31.137109374999937, 39.40693359375001],
                            [-31.282958984375, 39.39409179687496],
                            [-31.260839843750034, 39.49677734375001],
                            [-31.137109374999937, 39.40693359375001],
                        ],
                    ],
                    [
                        [
                            [-7.406152343749937, 37.17944335937497],
                            [-7.834130859374994, 37.005712890625034],
                            [-8.597656249999943, 37.12133789062506],
                            [-8.997802734375028, 37.03227539062502],
                            [-8.814160156249983, 37.43081054687502],
                            [-8.881103515624943, 38.44667968750005],
                            [-8.668310546874949, 38.42431640625003],
                            [-8.798876953124989, 38.518164062500034],
                            [-9.213281249999937, 38.44809570312498],
                            [-9.250390624999966, 38.65673828125003],
                            [-9.021484374999943, 38.746875],
                            [-8.79160156249992, 39.07817382812502],
                            [-9.13579101562496, 38.74277343749998],
                            [-9.35673828124996, 38.697900390624994],
                            [-9.479736328124972, 38.79877929687501],
                            [-9.374755859374972, 39.338281249999966],
                            [-8.837841796874926, 40.11567382812498],
                            [-8.684619140624989, 40.75253906250006],
                            [-8.755419921874932, 41.69838867187502],
                            [-8.887597656249937, 41.76459960937501],
                            [-8.777148437500017, 41.941064453124994],
                            [-8.266064453124983, 42.13740234375001],
                            [-8.152490234374937, 41.81196289062498],
                            [-7.40361328124996, 41.833691406249955],
                            [-7.147119140625023, 41.98115234374998],
                            [-6.61826171874992, 41.9423828125],
                            [-6.542187499999955, 41.672509765624994],
                            [-6.2125, 41.53203125],
                            [-6.928466796874972, 41.009130859375006],
                            [-6.8101562499999, 40.343115234375034],
                            [-7.032617187499966, 40.16791992187498],
                            [-6.896093749999949, 40.02182617187506],
                            [-6.975390624999932, 39.79838867187502],
                            [-7.117675781249972, 39.681689453125045],
                            [-7.53569335937496, 39.66157226562501],
                            [-6.997949218749994, 39.05644531250002],
                            [-7.343017578124943, 38.45742187500002],
                            [-7.106396484374983, 38.181005859375006],
                            [-6.957568359374932, 38.18789062499999],
                            [-7.44394531249992, 37.72827148437497],
                            [-7.406152343749937, 37.17944335937497],
                        ],
                    ],
                ],
            },
            properties: {
                name: "葡萄牙",
                childNum: 7,
                cp: [-8.7440694, 39.9251454],
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-58.15976562499999, -20.164648437500006],
                        [-58.13779296874999, -20.2373046875],
                        [-58.12460937499999, -20.29345703125],
                        [-58.09150390625, -20.33320312500001],
                        [-58.05844726562499, -20.38613281250001],
                        [-58.025390625, -20.415820312500003],
                        [-58.00224609374999, -20.465429687500006],
                        [-57.97905273437499, -20.657324218750006],
                        [-57.91513671874999, -20.69033203125001],
                        [-57.830224609374994, -20.99794921875001],
                        [-57.94267578124999, -21.79833984375],
                        [-57.95590820312499, -22.109179687500003],
                        [-56.77519531249999, -22.261328125],
                        [-56.44780273437499, -22.076171875],
                        [-56.39487304687499, -22.09267578125001],
                        [-56.35185546874999, -22.17861328125001],
                        [-56.246044921875, -22.2646484375],
                        [-56.18984375, -22.28115234375001],
                        [-55.99140625, -22.28115234375001],
                        [-55.84916992187499, -22.3076171875],
                        [-55.75327148437499, -22.41015625],
                        [-55.74663085937499, -22.5126953125],
                        [-55.61767578125, -22.671484375],
                        [-55.53828125, -23.58095703125001],
                        [-55.518457031249994, -23.627246093750003],
                        [-55.458886718749994, -23.68671875000001],
                        [-55.4423828125, -23.792578125],
                        [-55.4423828125, -23.865332031250006],
                        [-55.415917968749994, -23.95136718750001],
                        [-55.36630859374999, -23.991015625],
                        [-55.28691406249999, -24.004296875],
                        [-55.1943359375, -24.01748046875001],
                        [-55.08188476562499, -23.99765625],
                        [-54.982666015625, -23.97451171875001],
                        [-54.62548828125, -23.8125],
                        [-54.44023437499999, -23.90175781250001],
                        [-54.37080078125, -23.97119140625],
                        [-54.24179687499999, -24.047265625],
                        [-54.281005859375, -24.30605468750001],
                        [-54.43623046875, -25.12128906250001],
                        [-54.47314453125, -25.22021484375],
                        [-54.610546875, -25.432714843750006],
                        [-54.615869140624994, -25.57607421875001],
                        [-54.63193359374999, -26.00576171875001],
                        [-54.677734375, -26.30878906250001],
                        [-54.934472656249994, -26.70253906250001],
                        [-55.1359375, -26.93115234375],
                        [-55.426660156249994, -27.00927734375],
                        [-55.450634765625, -27.068359375],
                        [-55.496728515624994, -27.115332031250006],
                        [-55.564892578125, -27.15],
                        [-55.59726562499999, -27.207617187500006],
                        [-55.59379882812499, -27.2880859375],
                        [-55.63291015624999, -27.35712890625001],
                        [-55.71464843749999, -27.41484375],
                        [-55.789990234375, -27.41640625],
                        [-55.95146484374999, -27.32568359375],
                        [-56.1640625, -27.32148437500001],
                        [-56.437158203124994, -27.553808593750006],
                        [-58.16826171874999, -27.2734375],
                        [-58.60483398437499, -27.31435546875001],
                        [-58.641748046874994, -27.19609375],
                        [-58.618603515625, -27.132128906250003],
                        [-58.222070312499994, -26.65],
                        [-58.18149414062499, -26.307421875],
                        [-57.943115234375, -26.05292968750001],
                        [-57.563134765624994, -25.473730468750006],
                        [-57.821679687499994, -25.13642578125001],
                        [-59.187255859375, -24.562304687500003],
                        [-59.892480468749994, -24.093554687500003],
                        [-60.83984375, -23.85810546875001],
                        [-61.084716796875, -23.65644531250001],
                        [-61.79853515625, -23.18203125],
                        [-62.21416015624999, -22.612402343750006],
                        [-62.372509765625, -22.43916015625001],
                        [-62.54155273437499, -22.349609375],
                        [-62.6259765625, -22.29042968750001],
                        [-62.62568359375, -22.261523437500003],
                        [-62.65097656249999, -22.233691406250003],
                        [-62.27666015624999, -21.066015625],
                        [-62.276318359375, -20.5625],
                        [-61.7568359375, -19.6453125],
                        [-60.00737304687499, -19.29755859375001],
                        [-59.09052734375, -19.286230468750006],
                        [-58.18017578125, -19.81787109375],
                        [-58.15976562499999, -20.164648437500006],
                    ],
                ],
            },
            properties: {
                name: "巴拉圭",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [34.34833984375004, 31.292919921874955],
                            [34.2453125000001, 31.208300781250045],
                            [34.2125, 31.292285156250017],
                            [34.198144531249994, 31.322607421875063],
                            [34.47734375000002, 31.584863281250023],
                            [34.52412109375004, 31.541650390624994],
                            [34.5255859375001, 31.52563476562503],
                            [34.34833984375004, 31.292919921874955],
                        ],
                    ],
                    [
                        [
                            [34.88046875, 31.3681640625],
                            [34.950976562500074, 31.60229492187503],
                            [35.20371093750006, 31.75],
                            [35.1271484375001, 31.816748046875006],
                            [35.05322265625003, 31.83793945312496],
                            [34.983007812500006, 31.816796875000023],
                            [34.9611328125001, 31.823339843750006],
                            [34.95380859375004, 31.84125976562504],
                            [34.98974609374997, 31.913281249999955],
                            [34.955957031249994, 32.1609375],
                            [35.01054687500002, 32.33818359375002],
                            [35.06503906250006, 32.46044921875006],
                            [35.19326171875005, 32.53442382812503],
                            [35.303808593750006, 32.512939453125],
                            [35.38671875000003, 32.493017578125034],
                            [35.402636718750074, 32.45063476562501],
                            [35.484375, 32.40166015624999],
                            [35.5514648437501, 32.39550781250006],
                            [35.57207031250002, 32.237890625],
                            [35.450585937499994, 31.479296875000017],
                            [34.88046875, 31.3681640625],
                        ],
                    ],
                ],
            },
            properties: {
                name: "巴勒斯坦",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-149.321533203125, -17.690039062499963],
                            [-149.177685546875, -17.736621093750045],
                            [-149.18178710937497, -17.86230468749997],
                            [-149.34111328125, -17.732421874999986],
                            [-149.57890624999993, -17.734960937499963],
                            [-149.635009765625, -17.564257812500003],
                            [-149.37919921874993, -17.522363281249994],
                            [-149.321533203125, -17.690039062499963],
                        ],
                    ],
                    [
                        [
                            [-143.44057617187497, -16.619726562499963],
                            [-143.38618164062498, -16.668847656250023],
                            [-143.55068359375002, -16.62109374999997],
                            [-143.44057617187497, -16.619726562499963],
                        ],
                    ],
                    [
                        [
                            [-139.02431640624997, -9.695214843750037],
                            [-138.82734375, -9.74160156249998],
                            [-139.13408203124996, -9.829492187500037],
                            [-139.02431640624997, -9.695214843750037],
                        ],
                    ],
                    [
                        [
                            [-140.075634765625, -9.425976562499983],
                            [-140.14438476562498, -9.359375],
                            [-140.07094726562497, -9.328125],
                            [-140.075634765625, -9.425976562499983],
                        ],
                    ],
                    [
                        [
                            [-140.07260742187503, -8.910449218750031],
                            [-140.21743164062497, -8.929687499999957],
                            [-140.24003906249993, -8.79755859375004],
                            [-140.057666015625, -8.801464843750026],
                            [-140.07260742187503, -8.910449218750031],
                        ],
                    ],
                ],
            },
            properties: {
                name: "法属波利尼西亚",
                childNum: 5,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [51.26796875000002, 24.607226562500003],
                        [51.17802734375002, 24.58671875],
                        [51.093359375, 24.564648437499997],
                        [51.02275390625002, 24.565234375],
                        [50.96601562500001, 24.573925781249997],
                        [50.928320312500006, 24.595117187499994],
                        [50.85566406250001, 24.679638671874997],
                        [50.80439453125001, 24.789257812499997],
                        [50.8359375, 24.850390625],
                        [50.846777343750006, 24.888574218749994],
                        [50.75458984375001, 25.39926757812499],
                        [51.003125, 25.9814453125],
                        [51.262304687500006, 26.153271484374997],
                        [51.543066406250006, 25.902392578125003],
                        [51.4853515625, 25.524707031250003],
                        [51.60888671875, 25.052880859374994],
                        [51.42792968750001, 24.668261718750003],
                        [51.26796875000002, 24.607226562500003],
                    ],
                ],
            },
            properties: {
                name: "卡塔尔",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [28.2125, 45.450439453125],
                        [28.317675781250017, 45.347119140625],
                        [28.451269531250006, 45.2921875],
                        [28.78828125000001, 45.240966796875],
                        [28.78173828125, 45.309863281249996],
                        [28.894335937500017, 45.289941406249994],
                        [29.223535156250023, 45.4029296875],
                        [29.403710937500023, 45.419677734375],
                        [29.567675781250017, 45.37080078125],
                        [29.705859375000017, 45.259912109374994],
                        [29.557519531250023, 44.843408203124994],
                        [29.048242187500023, 44.757568359375],
                        [29.0953125, 44.975048828125],
                        [28.891503906250023, 44.91865234375],
                        [28.585351562500023, 43.742236328124996],
                        [28.221972656250017, 43.772851562499994],
                        [27.88427734375, 43.987353515624996],
                        [27.425390625, 44.0205078125],
                        [27.0869140625, 44.167382812499994],
                        [26.2158203125, 44.007275390625],
                        [25.4970703125, 43.670800781249994],
                        [22.919042968750006, 43.83447265625],
                        [22.868261718750006, 43.947900390624994],
                        [23.02851562500001, 44.077978515625],
                        [22.705078125, 44.23779296875],
                        [22.687890625000023, 44.248291015625],
                        [22.494531250000023, 44.435449218749994],
                        [22.554003906250017, 44.540332031249996],
                        [22.6201171875, 44.562353515625],
                        [22.70078125, 44.555517578125],
                        [22.734375, 44.569921875],
                        [22.72089843750001, 44.605517578124996],
                        [22.64208984375, 44.6509765625],
                        [22.49765625, 44.70625],
                        [22.350683593750006, 44.676123046875],
                        [22.200976562500017, 44.560693359374994],
                        [22.093066406250017, 44.541943359375],
                        [21.909277343750006, 44.66611328125],
                        [21.636132812500023, 44.71044921875],
                        [21.52314453125001, 44.790087890624996],
                        [21.36005859375001, 44.82666015625],
                        [21.35791015625, 44.86181640625],
                        [21.384375, 44.870068359375],
                        [21.442187500000017, 44.873388671875],
                        [21.519921875000023, 44.880810546875],
                        [21.532324218750006, 44.900683593749996],
                        [21.35703125, 44.990771484374996],
                        [21.465429687500006, 45.171875],
                        [21.431445312500017, 45.192529296874994],
                        [20.794042968750006, 45.46787109375],
                        [20.775, 45.749804687499996],
                        [20.760156250000023, 45.758105468749996],
                        [20.746875, 45.748974609375],
                        [20.727832031250017, 45.73740234375],
                        [20.709277343750017, 45.735253906249994],
                        [20.652734375000023, 45.77939453125],
                        [20.581152343750006, 45.869482421875],
                        [20.35859375000001, 45.975488281249994],
                        [20.241796875, 46.10859375],
                        [20.28095703125001, 46.1330078125],
                        [20.508105468750017, 46.166943359375],
                        [20.613671875000023, 46.13349609375],
                        [20.76025390625, 46.246240234374994],
                        [21.121679687500006, 46.282421875],
                        [21.99970703125001, 47.505029296874994],
                        [22.87666015625001, 47.947265625],
                        [23.054785156250006, 48.00654296875],
                        [23.139453125000017, 48.08740234375],
                        [23.20263671875, 48.084521484374996],
                        [23.408203125, 47.989990234375],
                        [23.628710937500017, 47.995849609375],
                        [24.578906250000017, 47.931054687499994],
                        [24.979101562500006, 47.72412109375],
                        [25.464257812500023, 47.910791015624994],
                        [25.689257812500017, 47.932470703125],
                        [25.90869140625, 47.967578125],
                        [26.162695312500006, 47.992529296875],
                        [26.236230468750023, 48.064355468749994],
                        [26.276953125, 48.113232421875],
                        [26.3056640625, 48.203759765624994],
                        [26.4423828125, 48.22998046875],
                        [26.618945312500017, 48.25986328125],
                        [26.980761718750017, 48.155029296875],
                        [27.614062500000017, 47.34052734375],
                        [28.07177734375, 46.978417968749994],
                        [28.23945312500001, 46.6408203125],
                        [28.07470703125, 45.598974609375],
                        [28.2125, 45.450439453125],
                    ],
                ],
            },
            properties: {
                name: "罗马尼亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [146.71396484375012, 43.743798828124994],
                            [146.62197265625, 43.81298828125006],
                            [146.88408203125002, 43.82915039062496],
                            [146.71396484375012, 43.743798828124994],
                        ],
                    ],
                    [
                        [
                            [146.20761718750006, 44.49765625],
                            [146.5677734375, 44.44042968749997],
                            [145.91406249999997, 44.10371093750004],
                            [145.58681640625, 43.84511718750002],
                            [145.5558593750001, 43.66459960937502],
                            [145.46171875000007, 43.870898437500045],
                            [146.20761718750006, 44.49765625],
                        ],
                    ],
                    [
                        [
                            [148.59951171875, 45.317626953125],
                            [147.91376953125004, 44.99038085937502],
                            [147.65781250000012, 44.97714843749998],
                            [146.89746093750003, 44.404296875],
                            [147.24658203124997, 44.856054687500006],
                            [147.88554687500007, 45.22563476562499],
                            [147.9240234375001, 45.38330078125006],
                            [148.05605468750005, 45.26210937500005],
                            [148.32421874999997, 45.28242187500001],
                            [148.8122070312501, 45.510009765625],
                            [148.83710937500004, 45.36269531250002],
                            [148.59951171875, 45.317626953125],
                        ],
                    ],
                    [
                        [
                            [149.68769531250004, 45.64204101562501],
                            [149.44707031250002, 45.593359375000034],
                            [149.9623046875, 46.02192382812504],
                            [150.553125, 46.208544921875045],
                            [149.68769531250004, 45.64204101562501],
                        ],
                    ],
                    [
                        [
                            [152.00205078125006, 46.89716796874998],
                            [151.72343750000007, 46.82880859375001],
                            [152.28886718750007, 47.1421875],
                            [152.00205078125006, 46.89716796874998],
                        ],
                    ],
                    [
                        [
                            [154.81044921875005, 49.31201171875],
                            [154.61093750000006, 49.29404296874998],
                            [154.82490234375004, 49.64692382812501],
                            [154.81044921875005, 49.31201171875],
                        ],
                    ],
                    [
                        [
                            [155.9210937500001, 50.30219726562501],
                            [155.39716796875004, 50.04125976562497],
                            [155.24306640625, 50.09462890625002],
                            [155.21835937500012, 50.29785156250003],
                            [155.68017578124997, 50.400732421875034],
                            [156.096875, 50.771875],
                            [155.9210937500001, 50.30219726562501],
                        ],
                    ],
                    [
                        [
                            [156.40507812500002, 50.65761718750005],
                            [156.16796874999997, 50.73188476562498],
                            [156.37646484374997, 50.86210937499996],
                            [156.4875, 50.84296874999998],
                            [156.40507812500002, 50.65761718750005],
                        ],
                    ],
                    [
                        [
                            [142.76103515625002, 54.393945312499966],
                            [143.32470703125003, 52.96308593749998],
                            [143.15556640625002, 52.08374023437497],
                            [143.29951171875004, 51.632373046875045],
                            [143.81601562500006, 50.28261718750002],
                            [144.71376953125, 48.64028320312502],
                            [144.04873046875, 49.249169921874994],
                            [143.73232421875, 49.31201171875],
                            [143.10498046875003, 49.198828125000034],
                            [142.57421874999997, 48.07216796875002],
                            [142.55693359375002, 47.737890625000034],
                            [143.21767578125005, 46.79487304687504],
                            [143.48564453125002, 46.752050781250006],
                            [143.58066406250012, 46.360693359375034],
                            [143.43164062500003, 46.02866210937498],
                            [143.28232421875006, 46.55898437500002],
                            [142.57802734375005, 46.700781250000034],
                            [142.07714843749997, 45.91704101562499],
                            [141.83037109375002, 46.451074218749966],
                            [142.03867187500012, 47.140283203124966],
                            [141.9640625000001, 47.58745117187502],
                            [142.18173828125012, 48.01337890625001],
                            [141.86630859375006, 48.750097656250006],
                            [142.1422851562501, 49.56914062499999],
                            [142.06601562500006, 50.630468750000034],
                            [142.20673828125004, 51.22255859375002],
                            [141.72236328125004, 51.73632812499997],
                            [141.66083984375004, 52.27294921874997],
                            [141.85556640625012, 52.79350585937499],
                            [141.82353515625007, 53.33950195312502],
                            [142.1419921875, 53.49560546875003],
                            [142.52617187500002, 53.44746093749998],
                            [142.70595703125, 53.89570312499998],
                            [142.33496093749997, 54.28071289062501],
                            [142.76103515625002, 54.393945312499966],
                        ],
                    ],
                    [
                        [
                            [137.17861328125005, 55.100439453125034],
                            [137.05527343750006, 54.9267578125],
                            [136.71464843750002, 54.956152343750034],
                            [137.17861328125005, 55.100439453125034],
                        ],
                    ],
                    [
                        [
                            [137.94052734375012, 55.092626953125034],
                            [138.20615234375012, 55.03354492187498],
                            [137.72148437500007, 54.66323242187505],
                            [137.46269531250002, 54.873388671875034],
                            [137.23291015624997, 54.79057617187496],
                            [137.5773437500001, 55.19702148437497],
                            [137.94052734375012, 55.092626953125034],
                        ],
                    ],
                    [
                        [
                            [21.235742187500023, 55.26411132812498],
                            [22.072363281250034, 55.06367187499998],
                            [22.56728515625005, 55.05913085937496],
                            [22.82470703125, 54.87128906249998],
                            [22.684472656250023, 54.56293945312504],
                            [22.679882812500068, 54.493017578125006],
                            [22.766210937499977, 54.356787109375034],
                            [22.168457031250057, 54.35986328125006],
                            [21.14052734375008, 54.39179687499998],
                            [19.604394531250023, 54.45917968750004],
                            [19.974511718750023, 54.92119140625002],
                            [20.520312500000017, 54.994873046875],
                            [20.89980468750008, 55.286669921875045],
                            [20.957812500000074, 55.27890625000006],
                            [20.594824218750006, 54.982373046874955],
                            [20.995898437500017, 54.90268554687506],
                            [21.18886718750008, 54.93520507812502],
                            [21.235742187500023, 55.26411132812498],
                        ],
                    ],
                    [
                        [
                            [166.65029296875005, 54.83906249999998],
                            [166.64511718750006, 54.69409179687503],
                            [165.75107421875006, 55.294531250000034],
                            [166.27578125000005, 55.311962890624955],
                            [166.24804687499997, 55.16542968750002],
                            [166.65029296875005, 54.83906249999998],
                        ],
                    ],
                    [
                        [
                            [150.58994140625006, 59.01875],
                            [150.47021484375003, 59.05405273437498],
                            [150.66621093750004, 59.16015625000003],
                            [150.58994140625006, 59.01875],
                        ],
                    ],
                    [
                        [
                            [163.63515625000005, 58.603369140625006],
                            [163.47138671875004, 58.509375],
                            [163.7609375000001, 59.01503906250002],
                            [164.57265625, 59.22114257812501],
                            [164.61572265624997, 58.885595703125034],
                            [163.63515625000005, 58.603369140625006],
                        ],
                    ],
                    [
                        [
                            [35.8161132812501, 65.18208007812501],
                            [35.77871093750005, 64.97666015625],
                            [35.52890625000006, 65.15107421875001],
                            [35.8161132812501, 65.18208007812501],
                        ],
                    ],
                    [
                        [
                            [70.02070312500004, 66.502197265625],
                            [69.65136718750003, 66.56533203125],
                            [69.50273437500002, 66.75107421875],
                            [70.07666015624997, 66.69589843750003],
                            [70.02070312500004, 66.502197265625],
                        ],
                    ],
                    [
                        [
                            [-179.79853515625, 68.9404296875],
                            [-178.873876953125, 68.75410156249995],
                            [-178.69262695312503, 68.54599609375],
                            [-178.09746093750002, 68.4248046875],
                            [-178.05581054687497, 68.26489257812503],
                            [-177.79677734374997, 68.33798828125],
                            [-178.37304687500003, 68.56567382812503],
                            [-177.52724609375002, 68.29438476562501],
                            [-177.58920898437503, 68.22421875],
                            [-175.34521484375, 67.67807617187503],
                            [-175.37470703124998, 67.35737304687498],
                            [-175.00268554687494, 67.4375],
                            [-174.849853515625, 67.34887695312503],
                            [-174.92490234375, 66.62314453125006],
                            [-174.503759765625, 66.537939453125],
                            [-174.39409179687496, 66.34423828124997],
                            [-174.084765625, 66.47309570312504],
                            [-174.06503906249998, 66.22958984374998],
                            [-173.77397460937502, 66.43466796875003],
                            [-174.23159179687497, 66.63188476562505],
                            [-174.08642578125, 66.94287109375],
                            [-174.55009765624993, 67.090625],
                            [-173.6796875, 67.144775390625],
                            [-173.15781249999998, 67.06909179687503],
                            [-173.32353515625, 66.95483398437503],
                            [-173.25893554687497, 66.84008789062503],
                            [-173.19301757812497, 66.99360351562504],
                            [-172.5201171875, 66.952490234375],
                            [-173.00751953125, 67.06489257812498],
                            [-171.79555664062502, 66.93173828125003],
                            [-170.50952148437503, 66.34365234375005],
                            [-170.604443359375, 66.24892578125002],
                            [-170.30122070312504, 66.29404296874998],
                            [-170.24394531250002, 66.16928710937503],
                            [-169.777880859375, 66.14311523437505],
                            [-169.83168945312497, 65.99892578124997],
                            [-170.54067382812497, 65.86542968749995],
                            [-170.66630859375, 65.62153320312501],
                            [-171.42153320312502, 65.81035156250002],
                            [-171.10585937500002, 65.51103515625005],
                            [-171.90712890625, 65.495947265625],
                            [-172.78330078124998, 65.68105468749997],
                            [-172.23281250000002, 65.45571289062497],
                            [-172.30927734375004, 65.27563476562497],
                            [-172.66191406249993, 65.24853515625006],
                            [-172.28603515625002, 65.20571289062502],
                            [-172.21318359375, 65.04814453124999],
                            [-173.08579101562498, 64.81733398437495],
                            [-172.80107421874996, 64.79052734375],
                            [-172.90087890624994, 64.62885742187501],
                            [-172.40146484374998, 64.413916015625],
                            [-172.73916015624997, 64.41225585937502],
                            [-172.90317382812498, 64.52607421875004],
                            [-172.96005859375003, 64.32768554687502],
                            [-173.27548828124998, 64.2896484375],
                            [-173.327490234375, 64.53955078125003],
                            [-173.72973632812497, 64.36450195312497],
                            [-174.57055664062503, 64.7177734375],
                            [-175.39511718749998, 64.80239257812502],
                            [-175.85385742187498, 65.01083984375003],
                            [-176.09326171875, 65.471044921875],
                            [-177.05625, 65.613623046875],
                            [-177.48876953125, 65.50371093749999],
                            [-178.4125, 65.49555664062501],
                            [-178.93906249999998, 66.03276367187505],
                            [-178.74672851562497, 66.01367187500006],
                            [-178.52656250000004, 66.40156250000004],
                            [-178.86811523437498, 66.18706054687502],
                            [-179.14340820312503, 66.37504882812505],
                            [-179.327197265625, 66.16259765625003],
                            [-179.68330078124998, 66.18413085937505],
                            [-179.78969726562497, 65.90087890625],
                            [-179.352099609375, 65.51674804687497],
                            [-180, 65.06723632812498],
                            [-180, 65.31196289062501],
                            [-180, 65.55678710937497],
                            [-180, 65.80156250000002],
                            [-180, 66.04628906250002],
                            [-180, 66.29106445312499],
                            [-180, 66.53583984375004],
                            [-180, 66.78056640625005],
                            [-180, 67.02534179687501],
                            [-180, 67.27011718750006],
                            [-180, 67.51484374999998],
                            [-180, 67.75961914062503],
                            [-180, 68.00439453124997],
                            [-180, 68.24912109375],
                            [-180, 68.49389648437497],
                            [-180, 68.738671875],
                            [-179.999951171875, 68.98344726562505],
                            [-179.79853515625, 68.9404296875],
                        ],
                    ],
                    [
                        [
                            [50.265234375, 69.18559570312502],
                            [49.62626953125002, 68.85971679687498],
                            [48.91035156250004, 68.74306640625002],
                            [48.4390625, 68.80488281249998],
                            [48.319921875, 69.26923828125001],
                            [48.8449218750001, 69.49472656250003],
                            [49.22519531250006, 69.51123046875],
                            [50.265234375, 69.18559570312502],
                        ],
                    ],
                    [
                        [
                            [161.46708984375002, 68.90097656250003],
                            [161.08281250000007, 69.4056640625],
                            [161.50517578125007, 69.63945312500002],
                            [161.46708984375002, 68.90097656250003],
                        ],
                    ],
                    [
                        [
                            [169.20078125000006, 69.58046875],
                            [168.34804687500005, 69.66435546875005],
                            [167.86474609375003, 69.90107421875004],
                            [168.35791015625003, 70.01567382812502],
                            [169.37480468750007, 69.88261718749999],
                            [169.20078125000006, 69.58046875],
                        ],
                    ],
                    [
                        [
                            [60.450488281250074, 69.93486328124999],
                            [60.44023437500002, 69.72592773437506],
                            [59.637011718750074, 69.72104492187503],
                            [59.50263671875004, 69.86621093750003],
                            [58.952734375, 69.89277343750004],
                            [58.51992187500005, 70.31831054687504],
                            [59.04804687500004, 70.46049804687505],
                            [60.450488281250074, 69.93486328124999],
                        ],
                    ],
                    [
                        [
                            [52.90332031250003, 71.36499023437503],
                            [53.19257812500004, 71.21528320312498],
                            [53.0226562500001, 70.96870117187501],
                            [52.24960937500006, 71.28491210937506],
                            [52.90332031250003, 71.36499023437503],
                        ],
                    ],
                    [
                        [
                            [178.8615234375001, 70.826416015625],
                            [178.68388671875013, 71.10566406250004],
                            [180, 71.53774414062505],
                            [180, 70.993017578125],
                            [178.8615234375001, 70.826416015625],
                        ],
                    ],
                    [
                        [
                            [137.95986328125005, 71.50766601562503],
                            [137.71181640625005, 71.4232421875],
                            [137.06406250000006, 71.52988281250003],
                            [137.816796875, 71.58789062500006],
                            [137.95986328125005, 71.50766601562503],
                        ],
                    ],
                    [
                        [
                            [-178.87646484375, 71.57705078124997],
                            [-178.13388671874998, 71.46547851562497],
                            [-177.523583984375, 71.16689453125],
                            [-179.415673828125, 70.91899414062502],
                            [-179.999951171875, 70.993017578125],
                            [-179.999951171875, 71.53774414062505],
                            [-178.87646484375, 71.57705078124997],
                        ],
                    ],
                    [
                        [
                            [77.6325195312501, 72.291259765625],
                            [76.87109374999997, 72.317041015625],
                            [77.74853515625003, 72.63120117187506],
                            [78.36513671875005, 72.48242187500003],
                            [77.6325195312501, 72.291259765625],
                        ],
                    ],
                    [
                        [
                            [79.50146484374997, 72.72192382812497],
                            [78.63320312500005, 72.85073242187502],
                            [79.16425781250004, 73.0943359375],
                            [79.50146484374997, 72.72192382812497],
                        ],
                    ],
                    [
                        [
                            [74.660546875, 72.87343750000002],
                            [74.18066406250003, 72.975341796875],
                            [74.19853515625002, 73.10908203124998],
                            [74.9615234375, 73.0625],
                            [74.660546875, 72.87343750000002],
                        ],
                    ],
                    [
                        [
                            [120.26132812500012, 73.08984374999997],
                            [119.79208984375006, 73.04541015624997],
                            [119.64042968750002, 73.12431640625007],
                            [120.26132812500012, 73.08984374999997],
                        ],
                    ],
                    [
                        [
                            [55.31982421875003, 73.30830078124998],
                            [56.42958984375005, 73.201171875],
                            [56.121679687500006, 72.80659179687498],
                            [55.40332031249997, 72.54907226562503],
                            [55.29785156249997, 71.93535156250005],
                            [56.45439453125002, 71.10737304687504],
                            [57.62539062500005, 70.72880859374999],
                            [57.14589843750005, 70.58911132812506],
                            [56.38574218749997, 70.73413085937503],
                            [56.49970703125004, 70.56640625000003],
                            [55.687304687500074, 70.69218749999999],
                            [54.60117187500006, 70.68007812500002],
                            [53.383593750000074, 70.87353515625],
                            [53.670507812500006, 71.08691406250003],
                            [54.155664062499994, 71.12548828125],
                            [53.40996093750002, 71.34013671875002],
                            [53.41162109375003, 71.530126953125],
                            [51.93789062500005, 71.47470703124998],
                            [51.511328125, 71.64809570312497],
                            [51.58251953124997, 72.07119140625],
                            [52.252050781250006, 72.12973632812503],
                            [52.66191406250002, 72.33686523437495],
                            [52.91660156250006, 72.66889648437501],
                            [52.5792968750001, 72.791357421875],
                            [53.3698242187501, 72.91674804687506],
                            [53.2511718750001, 73.182958984375],
                            [54.80390625000004, 73.38764648437498],
                            [55.31982421875003, 73.30830078124998],
                        ],
                    ],
                    [
                        [
                            [70.67392578125006, 73.09501953125005],
                            [70.04072265625004, 73.03715820312507],
                            [69.99589843750002, 73.359375],
                            [70.94023437500002, 73.51440429687503],
                            [71.6261718750001, 73.17397460937497],
                            [70.67392578125006, 73.09501953125005],
                        ],
                    ],
                    [
                        [
                            [142.18486328125007, 73.89589843750005],
                            [143.34375, 73.56875],
                            [143.45146484375007, 73.231298828125],
                            [141.59667968750003, 73.31083984375005],
                            [140.66279296875004, 73.45200195312503],
                            [139.785546875, 73.35522460937503],
                            [141.08476562500002, 73.86586914062497],
                            [142.18486328125007, 73.89589843750005],
                        ],
                    ],
                    [
                        [
                            [83.5490234375001, 74.07177734375],
                            [82.8177734375, 74.09160156250005],
                            [83.14980468750005, 74.151611328125],
                            [83.5490234375001, 74.07177734375],
                        ],
                    ],
                    [
                        [
                            [141.01025390625003, 73.99946289062501],
                            [140.40947265625002, 73.92167968750005],
                            [140.1935546875001, 74.23671875000002],
                            [141.03857421875003, 74.24272460937502],
                            [141.01025390625003, 73.99946289062501],
                        ],
                    ],
                    [
                        [
                            [113.38720703124997, 74.40043945312499],
                            [112.78242187500004, 74.09506835937503],
                            [111.50341796874997, 74.35307617187502],
                            [111.87978515625, 74.36381835937499],
                            [112.08447265624997, 74.54897460937505],
                            [113.38720703124997, 74.40043945312499],
                        ],
                    ],
                    [
                        [
                            [86.653125, 74.981298828125],
                            [87.05214843750005, 74.982568359375],
                            [86.92714843750005, 74.83076171874998],
                            [86.25859375000002, 74.89350585937498],
                            [86.653125, 74.981298828125],
                        ],
                    ],
                    [
                        [
                            [82.17236328125003, 75.41938476562501],
                            [81.97851562499997, 75.24711914062499],
                            [81.65478515625003, 75.28891601562498],
                            [81.71210937500004, 75.45141601562506],
                            [82.165625, 75.515625],
                            [82.17236328125003, 75.41938476562501],
                        ],
                    ],
                    [
                        [
                            [146.79521484375007, 75.37075195312505],
                            [148.43242187500002, 75.41352539062495],
                            [148.59013671875007, 75.23637695312502],
                            [150.82236328125006, 75.15654296875002],
                            [150.64628906250002, 74.944580078125],
                            [149.596875, 74.77260742187505],
                            [148.296875, 74.80043945312502],
                            [146.14853515625012, 75.19829101562499],
                            [146.5375, 75.58178710937506],
                            [146.79521484375007, 75.37075195312505],
                        ],
                    ],
                    [
                        [
                            [135.9486328125, 75.40957031250005],
                            [135.45195312500007, 75.38955078124997],
                            [135.6986328125, 75.84526367187499],
                            [136.16894531249997, 75.60556640625],
                            [135.9486328125, 75.40957031250005],
                        ],
                    ],
                    [
                        [
                            [140.04873046875, 75.82895507812503],
                            [140.81591796874997, 75.63071289062498],
                            [141.48544921875012, 76.13715820312495],
                            [142.66953125000012, 75.86342773437497],
                            [143.68583984375002, 75.86367187500002],
                            [145.35996093750006, 75.53046874999998],
                            [144.01972656250004, 75.04467773437506],
                            [143.1703125, 75.11689453125001],
                            [142.72949218749997, 75.33764648437506],
                            [142.941796875, 75.71328125000002],
                            [142.30791015625007, 75.69169921875005],
                            [142.19882812500006, 75.39267578124998],
                            [143.12792968749997, 74.9703125],
                            [142.47275390625006, 74.82041015625],
                            [141.98730468750003, 74.99125976562499],
                            [140.26787109375002, 74.846923828125],
                            [139.68125, 74.96406249999995],
                            [139.09912109374997, 74.65654296875002],
                            [138.09228515625003, 74.79746093750003],
                            [136.94765625000005, 75.32553710937498],
                            [137.28974609375004, 75.34863281249997],
                            [137.26884765625002, 75.7494140625],
                            [137.70654296875003, 75.75957031250002],
                            [137.56054687499997, 75.95522460937502],
                            [138.20761718750006, 76.11494140624995],
                            [138.91953125000006, 76.19672851562501],
                            [140.04873046875, 75.82895507812503],
                        ],
                    ],
                    [
                        [
                            [96.5324218750001, 76.278125],
                            [96.30058593750002, 76.121728515625],
                            [95.31113281250006, 76.21474609375002],
                            [95.37988281250003, 76.2890625],
                            [96.5324218750001, 76.278125],
                        ],
                    ],
                    [
                        [
                            [112.47802734375003, 76.62089843749999],
                            [112.531640625, 76.450048828125],
                            [111.96894531250004, 76.62617187500001],
                            [112.47802734375003, 76.62089843749999],
                        ],
                    ],
                    [
                        [
                            [149.15019531250002, 76.65991210937506],
                            [148.39863281250004, 76.64824218750007],
                            [149.4064453125001, 76.78208007812498],
                            [149.15019531250002, 76.65991210937506],
                        ],
                    ],
                    [
                        [
                            [67.7653320312501, 76.23759765624999],
                            [61.35595703124997, 75.31484375000002],
                            [60.27685546875003, 75.00756835937503],
                            [60.501367187499994, 74.90463867187503],
                            [59.67402343750004, 74.61015624999999],
                            [59.24013671875005, 74.69296874999998],
                            [59.040429687499994, 74.48554687500001],
                            [58.53466796875003, 74.49892578124997],
                            [58.6178710937501, 74.22739257812498],
                            [57.76738281250002, 74.013818359375],
                            [57.755957031250006, 73.769189453125],
                            [57.313085937500006, 73.838037109375],
                            [57.54257812500006, 73.65820312500003],
                            [56.96386718750003, 73.36655273437503],
                            [56.43037109375004, 73.29721679687503],
                            [55.00683593750003, 73.45385742187506],
                            [54.29990234375006, 73.35097656249997],
                            [53.7628906250001, 73.76616210937499],
                            [54.64267578125006, 73.95957031250006],
                            [55.34091796875006, 74.41962890624998],
                            [56.13710937500005, 74.49609375000003],
                            [55.5822265625001, 74.627685546875],
                            [56.4987304687501, 74.95708007812505],
                            [55.81005859374997, 75.12490234374997],
                            [56.03554687499999, 75.19423828124997],
                            [56.57031250000003, 75.09775390625003],
                            [56.8444335937501, 75.351416015625],
                            [57.606835937499994, 75.34125976562498],
                            [58.05830078125004, 75.6630859375],
                            [58.88125, 75.85478515625007],
                            [60.27929687499997, 76.09624023437505],
                            [60.94218750000002, 76.07128906250003],
                            [61.20166015624997, 76.28203125000007],
                            [62.97148437500002, 76.23666992187498],
                            [64.4634765625, 76.37817382812503],
                            [67.65185546874997, 77.011572265625],
                            [68.48574218750005, 76.93369140625003],
                            [68.94169921875002, 76.707666015625],
                            [67.7653320312501, 76.23759765624999],
                        ],
                    ],
                    [
                        [
                            [96.28544921875002, 77.02666015625007],
                            [95.27031250000007, 77.01884765624999],
                            [96.52841796875006, 77.20551757812501],
                            [96.28544921875002, 77.02666015625007],
                        ],
                    ],
                    [
                        [
                            [89.51425781250006, 77.18881835937498],
                            [89.14169921875012, 77.22680664062497],
                            [89.61621093749997, 77.31103515625],
                            [89.51425781250006, 77.18881835937498],
                        ],
                    ],
                    [
                        [
                            [130.68730468750007, 42.30253906249999],
                            [130.52695312500012, 42.535400390625],
                            [130.42480468749997, 42.72705078124997],
                            [131.06855468750004, 42.90224609375005],
                            [131.25732421875003, 43.378076171874994],
                            [131.2552734375, 44.07158203124999],
                            [130.9816406250001, 44.844335937500034],
                            [131.44687500000012, 44.984033203124966],
                            [131.85185546875002, 45.32685546875001],
                            [132.93603515624997, 45.029931640624994],
                            [133.1134765625001, 45.130712890625006],
                            [133.18603515625003, 45.49482421875004],
                            [133.43642578125, 45.60468750000004],
                            [133.86132812500003, 46.24775390625004],
                            [134.1676757812501, 47.30219726562501],
                            [134.75234375, 47.71542968749998],
                            [134.56601562500006, 48.02250976562502],
                            [134.66523437500004, 48.25390625],
                            [134.29335937500005, 48.37343750000002],
                            [133.46835937500006, 48.09716796875003],
                            [133.14404296875003, 48.10566406249998],
                            [132.7072265625001, 47.94726562500006],
                            [132.47626953125004, 47.714990234374994],
                            [130.96191406249997, 47.70932617187498],
                            [130.7326171875001, 48.01923828124998],
                            [130.80429687500012, 48.34150390624998],
                            [130.5521484375, 48.602490234374955],
                            [130.553125, 48.861181640625006],
                            [130.1959960937501, 48.89165039062499],
                            [129.49814453125012, 49.38881835937502],
                            [129.0651367187501, 49.374658203124966],
                            [128.70400390625, 49.60014648437499],
                            [127.99960937500006, 49.56860351562506],
                            [127.55078124999997, 49.801806640625045],
                            [127.590234375, 50.20898437500003],
                            [127.33720703125007, 50.35014648437502],
                            [127.30703125000005, 50.70795898437501],
                            [126.92480468749997, 51.10014648437496],
                            [126.34169921875, 52.36201171875001],
                            [125.64902343750012, 53.042285156250045],
                            [125.075, 53.20366210937496],
                            [124.81230468750002, 53.133837890625045],
                            [123.6078125, 53.546533203124994],
                            [120.98544921875012, 53.28457031250002],
                            [120.09453125000007, 52.787207031250034],
                            [120.0675781250001, 52.632910156250034],
                            [120.65615234375, 52.56665039062503],
                            [120.74980468750007, 52.096533203125006],
                            [120.06689453125003, 51.60068359375006],
                            [119.16367187500006, 50.40600585937503],
                            [119.34628906250012, 50.278955078124994],
                            [119.25986328125012, 50.06640625000003],
                            [118.4515625, 49.84448242187503],
                            [117.8734375, 49.51347656250002],
                            [116.6833007812501, 49.82377929687499],
                            [116.551171875, 49.92031250000002],
                            [116.35117187500012, 49.97807617187499],
                            [116.21679687500003, 50.00927734375003],
                            [116.13457031250002, 50.01079101562499],
                            [115.9259765625001, 49.95214843750003],
                            [115.79521484375002, 49.90590820312502],
                            [115.71777343750003, 49.88061523437503],
                            [115.58798828125006, 49.88603515624996],
                            [115.42919921874997, 49.89648437499997],
                            [115.36503906250002, 49.911767578124966],
                            [115.27451171875006, 49.948876953124994],
                            [115.00332031250005, 50.138574218749994],
                            [114.74316406249997, 50.23369140625002],
                            [114.29707031250004, 50.27441406250006],
                            [113.57421874999997, 50.00703125000001],
                            [113.44550781250004, 49.94160156250001],
                            [113.31904296875004, 49.87431640624999],
                            [113.16416015625012, 49.79716796874999],
                            [113.09208984375007, 49.692529296874994],
                            [113.05556640625, 49.61625976562499],
                            [112.91484375000002, 49.569238281249994],
                            [112.80644531250007, 49.52358398437502],
                            [112.69736328125012, 49.50727539062498],
                            [112.49492187500002, 49.532324218750034],
                            [112.07968750000006, 49.42421875000002],
                            [111.42929687500006, 49.342626953125034],
                            [111.3366210937501, 49.355859374999966],
                            [111.20419921875012, 49.304296875000034],
                            [110.82792968750002, 49.16616210937505],
                            [110.70976562500002, 49.14296875000002],
                            [110.42783203125006, 49.219970703125],
                            [110.32138671875012, 49.215869140625045],
                            [110.19990234375004, 49.17041015625003],
                            [109.5287109375, 49.269873046875034],
                            [109.45371093750012, 49.29633789062501],
                            [109.23671875000005, 49.334912109374955],
                            [108.61367187500005, 49.32280273437499],
                            [108.52246093750003, 49.34150390624998],
                            [108.4069335937501, 49.39638671875005],
                            [107.96542968750012, 49.65351562500004],
                            [107.91660156250012, 49.947802734375045],
                            [107.63095703125012, 49.98310546875004],
                            [107.3470703125, 49.986669921875034],
                            [107.23330078125, 49.989404296874994],
                            [107.14306640625003, 50.03300781249999],
                            [107.04023437500004, 50.086474609375045],
                            [106.94130859375005, 50.19667968750002],
                            [106.71113281250004, 50.312597656250006],
                            [106.57441406250004, 50.32880859375004],
                            [106.36845703125002, 50.317578124999955],
                            [106.21787109375006, 50.304589843749966],
                            [105.38359375000002, 50.47373046874998],
                            [104.07871093750012, 50.15424804687498],
                            [103.63291015625006, 50.138574218749994],
                            [103.49628906250004, 50.16494140625005],
                            [103.42119140625002, 50.18706054687502],
                            [103.3043945312501, 50.200292968750034],
                            [102.28837890625007, 50.58510742187502],
                            [102.31660156250004, 50.71845703125001],
                            [102.21503906250004, 50.82944335937506],
                            [102.19453125000004, 51.05068359375002],
                            [102.15195312500006, 51.107519531250034],
                            [102.14238281250007, 51.21606445312503],
                            [102.16005859375005, 51.260839843750006],
                            [102.1556640625, 51.31376953124996],
                            [102.1115234375001, 51.353466796874955],
                            [101.97919921875004, 51.382226562499966],
                            [101.82119140625, 51.421044921874966],
                            [101.57089843750006, 51.46718750000005],
                            [101.38125, 51.45263671875],
                            [100.53623046875006, 51.713476562500034],
                            [100.46894531250004, 51.72607421875003],
                            [100.23037109375, 51.729833984375006],
                            [100.0345703125, 51.73710937499996],
                            [99.92167968750002, 51.755517578124994],
                            [99.71923828124997, 51.87163085937502],
                            [98.89316406250006, 52.11728515625006],
                            [98.64052734375005, 51.80117187500005],
                            [98.103125, 51.483544921874994],
                            [97.82529296875012, 50.985253906249994],
                            [97.953125, 50.85517578124998],
                            [98.02978515625003, 50.64462890624998],
                            [98.07890625000002, 50.60380859375002],
                            [98.14501953124997, 50.56855468750001],
                            [98.22050781250007, 50.55717773437502],
                            [98.2794921875001, 50.53325195312502],
                            [98.25029296875002, 50.30244140624998],
                            [98.00390625000003, 50.01425781249998],
                            [97.35976562500005, 49.741455078125],
                            [97.20859375000006, 49.73081054687506],
                            [96.98574218750005, 49.88281250000003],
                            [96.31503906250012, 49.90112304687503],
                            [96.06552734375006, 49.99873046875001],
                            [95.52265625000004, 49.911230468750034],
                            [95.11142578125012, 49.935449218749994],
                            [94.93027343750006, 50.04375],
                            [94.8112304687501, 50.04819335937506],
                            [94.71806640625002, 50.04326171875002],
                            [94.67548828125004, 50.02807617187506],
                            [94.61474609375003, 50.02373046874996],
                            [94.56464843750004, 50.08793945312499],
                            [94.35468750000004, 50.221826171874994],
                            [94.25107421875006, 50.55639648437503],
                            [93.103125, 50.60390625000002],
                            [92.94130859375005, 50.77822265625002],
                            [92.85644531250003, 50.78911132812502],
                            [92.77929687500003, 50.778662109375006],
                            [92.738671875, 50.71093749999997],
                            [92.68134765625004, 50.683203125],
                            [92.6266601562501, 50.68828124999999],
                            [92.57890625000002, 50.725439453125006],
                            [92.42636718750006, 50.803076171875006],
                            [92.35478515625002, 50.86416015625002],
                            [92.29580078125, 50.84980468750004],
                            [92.19238281249997, 50.700585937499994],
                            [91.80429687500006, 50.693603515625],
                            [91.4464843750001, 50.52216796874998],
                            [91.41503906249997, 50.46801757812506],
                            [91.34082031249997, 50.470068359375034],
                            [91.30058593750002, 50.46337890625],
                            [91.2337890625, 50.45239257812497],
                            [91.02158203125012, 50.41547851562501],
                            [90.83808593750004, 50.32373046874997],
                            [90.76074218749997, 50.30595703124999],
                            [90.71435546874997, 50.25942382812502],
                            [90.65507812500007, 50.22236328125001],
                            [90.05371093750003, 50.09375],
                            [89.64384765625002, 49.90302734374998],
                            [89.65410156250007, 49.71748046875001],
                            [89.57919921875006, 49.69970703125003],
                            [89.475, 49.66054687500005],
                            [89.39560546875006, 49.61152343750001],
                            [89.24394531250007, 49.62705078125006],
                            [89.20292968750007, 49.59570312499997],
                            [89.17998046875002, 49.5322265625],
                            [89.10947265625012, 49.50136718750002],
                            [89.00839843750006, 49.472802734374994],
                            [88.97060546875, 49.483740234375006],
                            [88.94541015625012, 49.50766601562498],
                            [88.90019531250002, 49.53969726562502],
                            [88.86386718750006, 49.52763671874996],
                            [88.83164062500012, 49.44843749999998],
                            [88.633203125, 49.486132812500045],
                            [88.19257812500004, 49.451708984375045],
                            [88.13554687500002, 49.38149414062502],
                            [88.11572265624997, 49.25629882812501],
                            [88.0285156250001, 49.219775390625045],
                            [87.98808593750002, 49.186914062499994],
                            [87.9347656250001, 49.16455078124997],
                            [87.81425781250002, 49.162304687499955],
                            [87.7625, 49.16582031249996],
                            [87.5158203125001, 49.122412109375006],
                            [87.41669921875004, 49.07661132812501],
                            [87.32285156250012, 49.085791015625006],
                            [86.62646484374997, 49.56269531250001],
                            [86.67548828125004, 49.77729492187501],
                            [86.1808593750001, 49.49931640624996],
                            [85.2326171875001, 49.61582031249998],
                            [84.9894531250001, 50.061425781249994],
                            [84.32324218749997, 50.239160156249966],
                            [83.94511718750007, 50.774658203125],
                            [83.35732421875005, 50.99458007812504],
                            [82.76083984375012, 50.89335937500002],
                            [82.49394531250007, 50.72758789062499],
                            [81.46591796875006, 50.73984375],
                            [81.38828125000006, 50.95649414062501],
                            [81.0714843750001, 50.96875],
                            [81.12724609375002, 51.19106445312502],
                            [80.73525390625, 51.29340820312498],
                            [80.44804687500002, 51.18334960937503],
                            [80.42363281250002, 50.94628906249997],
                            [79.98623046875, 50.774560546874966],
                            [77.85996093750006, 53.269189453124994],
                            [76.48476562500005, 54.02255859374998],
                            [76.42167968750007, 54.151513671874966],
                            [76.65458984375007, 54.14526367187503],
                            [76.8373046875, 54.4423828125],
                            [75.43720703125004, 54.08964843749999],
                            [75.22021484374997, 53.89379882812506],
                            [74.45195312500007, 53.64726562500002],
                            [74.35156250000003, 53.487646484375006],
                            [73.85898437500006, 53.61972656249998],
                            [73.40693359375004, 53.44755859374999],
                            [73.30566406250003, 53.707226562499955],
                            [73.71240234375003, 54.04238281250002],
                            [73.22988281250005, 53.957812500000045],
                            [72.62226562500004, 54.13432617187502],
                            [72.44677734375003, 53.94184570312498],
                            [72.18603515625003, 54.32563476562501],
                            [72.00449218750006, 54.20566406249998],
                            [71.09316406250005, 54.21220703124999],
                            [71.18554687500003, 54.59931640624998],
                            [70.73808593750007, 55.30517578125],
                            [70.18242187500002, 55.162451171875034],
                            [68.9772460937501, 55.389599609374955],
                            [68.20625, 55.16093750000002],
                            [68.15585937500006, 54.97670898437505],
                            [65.476953125, 54.62329101562497],
                            [65.08837890624997, 54.340185546875034],
                            [64.46123046875002, 54.38417968750002],
                            [61.92871093750003, 53.94648437500004],
                            [61.231054687500006, 54.01948242187498],
                            [60.97949218749997, 53.62172851562505],
                            [61.53496093750002, 53.52329101562506],
                            [61.22890625, 53.445898437500006],
                            [61.19921874999997, 53.28715820312502],
                            [61.65986328125004, 53.22846679687504],
                            [62.08271484375004, 53.00541992187499],
                            [61.047460937500006, 52.97246093750002],
                            [60.77441406249997, 52.67578124999997],
                            [60.99453125000005, 52.33686523437504],
                            [60.03027343749997, 51.93325195312505],
                            [60.464746093749994, 51.651171875000045],
                            [61.55468750000003, 51.32460937500005],
                            [61.38945312500002, 50.86103515625001],
                            [60.94228515625005, 50.69550781250004],
                            [60.42480468749997, 50.67915039062498],
                            [60.05859374999997, 50.850292968749955],
                            [59.812402343749994, 50.58203125],
                            [59.523046875, 50.492871093749955],
                            [59.4523437500001, 50.62041015625002],
                            [58.88369140625005, 50.694433593750006],
                            [58.359179687500074, 51.063818359375034],
                            [57.83886718750003, 51.091650390625006],
                            [57.44218750000002, 50.88886718749998],
                            [57.01171874999997, 51.06518554687503],
                            [56.49140625000004, 51.01953124999997],
                            [55.68623046875004, 50.582861328125006],
                            [54.64160156250003, 51.011572265625034],
                            [54.555273437500006, 50.535791015624994],
                            [54.139746093750006, 51.04077148437503],
                            [53.33808593750004, 51.48237304687504],
                            [52.57119140625005, 51.481640624999955],
                            [52.21914062499999, 51.709375],
                            [51.344531250000074, 51.47534179687503],
                            [51.16347656250005, 51.6474609375],
                            [50.79394531249997, 51.729199218749955],
                            [50.246875, 51.28950195312498],
                            [49.49804687500003, 51.08359375000006],
                            [49.32343750000004, 50.851708984374966],
                            [48.625097656250006, 50.61269531250005],
                            [48.7589843750001, 49.92832031250006],
                            [48.33496093750003, 49.858251953125006],
                            [47.7057617187501, 50.37797851562502],
                            [47.42919921874997, 50.35795898437502],
                            [46.889550781249994, 49.69697265625001],
                            [46.80205078125002, 49.36708984375002],
                            [47.031347656250006, 49.150292968749994],
                            [46.70263671875003, 48.80556640625002],
                            [46.660937500000074, 48.41225585937502],
                            [47.06464843750004, 48.23247070312499],
                            [47.292382812499994, 47.74091796875004],
                            [47.48193359374997, 47.80390624999998],
                            [48.16699218750003, 47.70878906249996],
                            [48.959375, 46.77460937499998],
                            [48.558398437500074, 46.75712890624999],
                            [48.54121093750004, 46.60561523437502],
                            [49.232226562500074, 46.33715820312503],
                            [48.683691406250006, 46.08618164062497],
                            [48.72958984375006, 45.896826171875034],
                            [48.4870117187501, 45.93486328124996],
                            [47.63330078124997, 45.58403320312499],
                            [47.46328125, 45.67968750000003],
                            [47.5294921875001, 45.530224609374955],
                            [47.3512695312501, 45.21772460937498],
                            [46.7072265625001, 44.503320312499994],
                            [47.30703125000005, 44.103125],
                            [47.462792968749994, 43.55502929687498],
                            [47.64648437500003, 43.88461914062498],
                            [47.463183593750074, 43.03505859375002],
                            [48.572851562500006, 41.84448242187503],
                            [47.79101562499997, 41.19926757812502],
                            [47.31767578125002, 41.28242187500001],
                            [46.74931640625002, 41.812597656250006],
                            [46.42988281250004, 41.890966796875006],
                            [46.21269531250002, 41.989892578124994],
                            [45.63857421875005, 42.20507812500003],
                            [45.63427734374997, 42.234716796875034],
                            [45.72753906249997, 42.47504882812498],
                            [45.70527343750004, 42.49809570312496],
                            [45.56289062499999, 42.53574218749998],
                            [45.34375, 42.52978515625003],
                            [45.16025390625006, 42.675],
                            [45.07158203125002, 42.69414062500002],
                            [44.94335937499997, 42.73027343750002],
                            [44.870996093749994, 42.75639648437499],
                            [44.850488281249994, 42.746826171875],
                            [44.77109375000006, 42.61679687499998],
                            [44.69179687499999, 42.709619140624966],
                            [44.64433593750002, 42.734716796875034],
                            [44.50585937500003, 42.748632812500006],
                            [44.329492187499994, 42.703515624999966],
                            [44.10273437500004, 42.616357421874994],
                            [44.004687500000074, 42.59560546875002],
                            [43.95742187500005, 42.56655273437505],
                            [43.825976562500074, 42.571533203125],
                            [43.759863281250006, 42.593847656250006],
                            [43.738378906250006, 42.61699218750002],
                            [43.74990234375005, 42.65751953125002],
                            [43.79541015624997, 42.702978515625034],
                            [43.78261718750005, 42.747021484374955],
                            [43.62304687500003, 42.80771484374998],
                            [43.5578125000001, 42.844482421875],
                            [43.089160156250074, 42.9890625],
                            [43.00019531250004, 43.04965820312506],
                            [42.991601562499994, 43.09150390624998],
                            [42.76064453125005, 43.169580078124966],
                            [41.58056640624997, 43.21923828124997],
                            [41.460742187500074, 43.276318359374955],
                            [41.35820312500002, 43.33339843750005],
                            [41.08310546875006, 43.37446289062498],
                            [40.94199218750006, 43.41806640624998],
                            [40.801660156249994, 43.479931640624955],
                            [40.64804687500006, 43.53388671875004],
                            [40.084570312500006, 43.553125],
                            [40.02373046875002, 43.48486328125],
                            [39.873632812500006, 43.47280273437502],
                            [38.71728515624997, 44.28808593750003],
                            [38.18125, 44.41967773437503],
                            [37.851464843749994, 44.698828125000034],
                            [37.49511718750003, 44.69526367187504],
                            [37.20478515625004, 44.97197265624999],
                            [36.62763671875004, 45.15131835937504],
                            [36.941210937500074, 45.289697265624994],
                            [36.72041015625004, 45.371875],
                            [36.8659179687501, 45.42705078124999],
                            [37.21357421875004, 45.272314453125006],
                            [37.6471679687501, 45.37719726562506],
                            [37.61240234375006, 45.56469726562506],
                            [37.93310546875003, 46.001708984375],
                            [38.014257812500006, 46.047753906249966],
                            [38.07958984375003, 45.93481445312506],
                            [38.18359374999997, 46.09482421875006],
                            [38.49228515625006, 46.09052734374998],
                            [37.913867187500074, 46.40649414062503],
                            [37.766503906249994, 46.63613281250002],
                            [38.50097656249997, 46.663671875000034],
                            [38.43867187500004, 46.813085937500006],
                            [39.29345703125003, 47.105761718750045],
                            [39.19570312499999, 47.268847656250045],
                            [39.023730468750074, 47.27221679687503],
                            [38.928320312500006, 47.175683593749994],
                            [38.55244140625004, 47.15034179687498],
                            [38.7619140625001, 47.261621093749994],
                            [38.21435546875003, 47.091455078124966],
                            [38.36884765625004, 47.609960937500006],
                            [38.90029296875005, 47.85512695312502],
                            [39.77871093750005, 47.88754882812506],
                            [39.95791015625005, 48.268896484375034],
                            [39.8356445312501, 48.54277343749996],
                            [39.6447265625001, 48.591210937499966],
                            [39.792871093749994, 48.807714843750034],
                            [40.00361328125004, 48.82207031250002],
                            [39.68652343749997, 49.007910156250034],
                            [40.10878906250005, 49.251562500000034],
                            [40.080664062500006, 49.576855468749955],
                            [39.780566406250074, 49.57202148437503],
                            [39.17480468750003, 49.85595703124997],
                            [38.91835937499999, 49.82470703125],
                            [38.258593750000074, 50.05234375],
                            [38.046875, 49.92001953125006],
                            [37.42285156249997, 50.411474609375006],
                            [36.619433593750074, 50.209228515625],
                            [36.1164062500001, 50.408544921875006],
                            [35.59111328125002, 50.36875],
                            [35.31191406250005, 51.043896484374955],
                            [35.0640625, 51.203417968750045],
                            [34.21386718750003, 51.25537109375006],
                            [34.12109375000003, 51.67915039062498],
                            [34.397851562499994, 51.780419921874994],
                            [33.735253906249994, 52.344775390625045],
                            [32.435449218749994, 52.307226562500034],
                            [32.12226562500004, 52.05058593749996],
                            [31.763378906250097, 52.10107421875003],
                            [31.758593750000017, 52.125830078125034],
                            [31.690625, 52.22065429687498],
                            [31.64990234374997, 52.26220703125],
                            [31.60156250000003, 52.284814453124994],
                            [31.57734375000004, 52.31230468749999],
                            [31.585546875, 52.532470703125],
                            [31.56484375, 52.75922851562501],
                            [31.53515624999997, 52.798242187499966],
                            [31.442773437499994, 52.86181640625003],
                            [31.35302734374997, 52.93344726562498],
                            [31.295117187500097, 52.98979492187499],
                            [31.25878906249997, 53.01669921875006],
                            [31.364550781250017, 53.13896484375002],
                            [31.388378906250097, 53.18481445312503],
                            [31.41787109375005, 53.196044921875],
                            [31.849707031250006, 53.106201171875],
                            [32.14199218750005, 53.091162109375034],
                            [32.46933593750006, 53.270312500000045],
                            [32.578027343749994, 53.312402343749994],
                            [32.644433593749994, 53.32890624999999],
                            [32.70429687500004, 53.33632812499999],
                            [32.45097656250002, 53.6533203125],
                            [32.20039062500004, 53.78125],
                            [31.99218750000003, 53.796875],
                            [31.82080078124997, 53.79194335937498],
                            [31.754199218750017, 53.81044921875002],
                            [31.825292968750006, 53.93500976562501],
                            [31.837792968749994, 54.00078124999999],
                            [31.825976562500074, 54.030712890624955],
                            [31.79199218749997, 54.05590820312503],
                            [31.62841796874997, 54.111181640625006],
                            [31.403613281250017, 54.195947265624966],
                            [31.299121093750017, 54.29169921875001],
                            [31.184765625000097, 54.452978515625006],
                            [31.074804687500063, 54.491796875],
                            [31.154882812500063, 54.610937500000034],
                            [31.152148437500017, 54.625341796875034],
                            [31.12128906250004, 54.64848632812496],
                            [30.984179687500074, 54.695898437500034],
                            [30.79882812499997, 54.78325195312499],
                            [30.79101562499997, 54.806005859375006],
                            [30.804492187500074, 54.8609375],
                            [30.829882812500017, 54.91499023437498],
                            [30.977734375000097, 55.05048828124998],
                            [30.977734375000097, 55.08779296875002],
                            [30.958886718749994, 55.13759765625005],
                            [30.87744140625003, 55.223437500000045],
                            [30.81445312499997, 55.27871093750002],
                            [30.81054687499997, 55.306982421875006],
                            [30.82099609375004, 55.330273437499955],
                            [30.900585937500097, 55.397412109374955],
                            [30.906835937500063, 55.57001953125004],
                            [30.625585937500006, 55.666259765625],
                            [30.23359375000004, 55.84521484375006],
                            [30.04267578125004, 55.83642578125003],
                            [29.93701171874997, 55.84526367187499],
                            [29.881640625000074, 55.83232421875002],
                            [29.82392578125004, 55.79511718749998],
                            [29.74414062499997, 55.770410156249994],
                            [29.630078125000097, 55.75117187499998],
                            [29.482226562500074, 55.6845703125],
                            [29.412988281249994, 55.72485351562506],
                            [29.35341796875005, 55.784375],
                            [29.375, 55.938720703125],
                            [28.284277343750006, 56.055908203125],
                            [28.14794921875003, 56.142919921875034],
                            [28.202050781250023, 56.260400390624994],
                            [28.191699218750045, 56.31557617187505],
                            [28.169238281250017, 56.386865234374994],
                            [28.11083984375, 56.51069335937501],
                            [28.103125, 56.545703125000045],
                            [27.89208984375003, 56.741064453125034],
                            [27.88154296875001, 56.82416992187501],
                            [27.848632812500057, 56.85341796875002],
                            [27.806054687499994, 56.86708984375005],
                            [27.639453125000074, 56.84565429687504],
                            [27.83027343750004, 57.19448242187505],
                            [27.83828125000008, 57.247705078124966],
                            [27.82861328124997, 57.293310546875006],
                            [27.796875, 57.316943359375045],
                            [27.538671875000063, 57.429785156250034],
                            [27.51113281250005, 57.508154296875006],
                            [27.469726562500057, 57.524023437500034],
                            [27.35195312500005, 57.528125],
                            [27.4, 57.66679687499999],
                            [27.542089843750063, 57.799414062500006],
                            [27.778515625000068, 57.87070312500006],
                            [27.502441406250057, 58.221337890624994],
                            [27.434179687500006, 58.787255859374994],
                            [28.15107421875004, 59.374414062499966],
                            [28.0125, 59.484277343749966],
                            [28.05800781250008, 59.781542968750045],
                            [28.334570312500034, 59.69252929687502],
                            [28.518164062500034, 59.849560546874955],
                            [28.947265625000057, 59.828759765624994],
                            [29.147265625000045, 59.999755859375],
                            [30.12255859374997, 59.873583984375074],
                            [30.172656250000017, 59.957128906250034],
                            [29.72119140624997, 60.19531249999997],
                            [29.069140625000017, 60.19145507812499],
                            [28.643164062500006, 60.375292968750045],
                            [28.512792968750006, 60.67729492187502],
                            [27.797656250000074, 60.53613281250003],
                            [29.69013671875004, 61.54609375000001],
                            [31.18671875000004, 62.48139648437504],
                            [31.533984375000017, 62.885400390624994],
                            [31.180859375000097, 63.208300781250074],
                            [29.991503906250074, 63.73515625000002],
                            [30.50390625000003, 64.02060546875],
                            [30.513769531250006, 64.2],
                            [30.04189453125005, 64.44335937499997],
                            [30.072851562500063, 64.76503906250005],
                            [29.60419921875004, 64.968408203125],
                            [29.826953125000017, 65.14506835937502],
                            [29.608007812500006, 65.248681640625],
                            [29.715917968750063, 65.62456054687502],
                            [30.102734375000097, 65.72626953125004],
                            [29.066210937500045, 66.89174804687497],
                            [29.988085937500017, 67.66826171874999],
                            [29.343847656250006, 68.06186523437506],
                            [28.685156250000034, 68.189794921875],
                            [28.470703125000057, 68.48837890625],
                            [28.77285156250005, 68.84003906249995],
                            [28.414062500000057, 68.90415039062506],
                            [28.96582031250003, 69.02197265625],
                            [29.38828125, 69.29814453125005],
                            [30.08730468750005, 69.43286132812503],
                            [30.18017578124997, 69.63583984375],
                            [30.860742187499994, 69.53842773437503],
                            [30.869726562500006, 69.78344726562506],
                            [31.546972656250063, 69.696923828125],
                            [31.997949218749994, 69.80991210937503],
                            [31.98457031250004, 69.95366210937499],
                            [33.00781249999997, 69.72211914062498],
                            [32.91503906249997, 69.60170898437497],
                            [32.17675781250003, 69.67402343749995],
                            [32.37773437500002, 69.47910156250003],
                            [32.99980468750002, 69.4701171875],
                            [32.97890625000005, 69.367333984375],
                            [33.45429687500004, 69.42817382812495],
                            [33.14121093750006, 69.068701171875],
                            [33.684375, 69.31025390625001],
                            [35.85791015625003, 69.19174804687503],
                            [37.73056640625006, 68.69213867187503],
                            [38.43017578125003, 68.35561523437505],
                            [39.568945312500006, 68.07172851562501],
                            [39.82333984375006, 68.05859375],
                            [39.80927734375004, 68.15083007812498],
                            [40.38066406250002, 67.831884765625],
                            [40.96640625000006, 67.71347656250003],
                            [41.358789062499994, 67.20966796874998],
                            [41.18896484375003, 66.82617187500003],
                            [40.10332031250002, 66.29995117187502],
                            [38.65390625000006, 66.06904296874995],
                            [35.51347656250002, 66.39580078125002],
                            [34.82460937499999, 66.61113281249999],
                            [34.48261718750004, 66.55034179687505],
                            [34.4515625, 66.651220703125],
                            [33.15019531250002, 66.84394531250001],
                            [32.93046875000002, 67.08681640625002],
                            [31.895312500000074, 67.16142578125002],
                            [33.65595703125004, 66.44262695312506],
                            [33.36054687500004, 66.32954101562501],
                            [34.112695312499994, 66.225244140625],
                            [34.69179687500005, 65.95185546874998],
                            [34.77695312500006, 65.76826171874998],
                            [34.40644531250004, 65.39575195312503],
                            [35.03535156250004, 64.44023437500005],
                            [35.802050781250074, 64.3353515625],
                            [36.3649414062501, 64.00283203125002],
                            [37.44218750000002, 63.813378906249966],
                            [37.9679687500001, 63.949121093749994],
                            [38.0622070312501, 64.09101562499995],
                            [37.953710937500006, 64.32011718749999],
                            [37.183691406250006, 64.40849609375007],
                            [36.6242187500001, 64.75053710937502],
                            [36.534570312499994, 64.93862304687497],
                            [36.88281249999997, 65.17236328124997],
                            [39.7580078125001, 64.57705078125002],
                            [40.05781250000004, 64.77075195312497],
                            [40.44492187500006, 64.7787109375],
                            [39.7980468750001, 65.349853515625],
                            [39.816503906250006, 65.59794921874999],
                            [41.4757812500001, 66.12343750000002],
                            [42.21054687500006, 66.51967773437502],
                            [43.23320312500002, 66.41552734375003],
                            [43.653125, 66.2509765625],
                            [43.54189453125005, 66.12338867187503],
                            [43.84375, 66.14238281249999],
                            [44.10439453125005, 66.00859374999999],
                            [44.42929687500006, 66.93774414062503],
                            [43.7824218750001, 67.25449218749998],
                            [44.20468750000006, 68.25375976562498],
                            [43.33320312500004, 68.67338867187502],
                            [44.04804687500004, 68.54882812499997],
                            [45.891992187499994, 68.47968750000001],
                            [46.69042968750003, 67.84882812500001],
                            [45.52871093750005, 67.75756835937497],
                            [44.90214843750002, 67.41313476562505],
                            [45.56220703125004, 67.18559570312507],
                            [45.88535156250006, 66.89106445312501],
                            [46.4923828125001, 66.80019531249997],
                            [47.65585937500006, 66.97592773437498],
                            [47.87470703125004, 67.58417968749998],
                            [48.83320312500004, 67.681494140625],
                            [48.75429687500005, 67.89594726562501],
                            [49.15527343750003, 67.87041015625005],
                            [51.994726562500006, 68.53876953124995],
                            [52.3966796875001, 68.35170898437505],
                            [52.72265624999997, 68.484033203125],
                            [52.34404296875002, 68.60815429687497],
                            [53.80195312500004, 68.99589843750002],
                            [54.49121093750003, 68.992333984375],
                            [53.797656250000074, 68.90747070312503],
                            [53.9308593750001, 68.43554687499997],
                            [53.260546875000074, 68.26748046875002],
                            [54.476171875, 68.29414062499995],
                            [54.86132812500003, 68.20185546874998],
                            [55.418066406250006, 68.56782226562501],
                            [56.04365234375004, 68.64887695312501],
                            [57.126855468749994, 68.55400390625005],
                            [58.17304687500004, 68.88974609375006],
                            [59.0573242187501, 69.00605468750004],
                            [59.37050781250005, 68.73837890625003],
                            [59.09902343750005, 68.4443359375],
                            [59.725683593750006, 68.35161132812502],
                            [59.89599609374997, 68.70634765624999],
                            [60.489160156249994, 68.72895507812498],
                            [60.93359374999997, 68.98676757812501],
                            [60.17060546875004, 69.59091796875],
                            [60.90908203125005, 69.84711914062495],
                            [64.19042968750003, 69.53466796875],
                            [64.89628906250002, 69.247802734375],
                            [67.00244140625003, 68.87358398437505],
                            [68.37119140625006, 68.31425781250005],
                            [69.14052734375005, 68.95063476562501],
                            [68.54277343750002, 68.96708984374999],
                            [68.00585937499997, 69.48002929687505],
                            [67.62412109375, 69.58442382812501],
                            [67.06445312500003, 69.69370117187498],
                            [66.89667968750004, 69.55380859374998],
                            [67.28476562500006, 70.73872070312498],
                            [67.14335937500002, 70.83754882812502],
                            [66.70224609375006, 70.81850585937497],
                            [66.63964843749997, 71.08139648437498],
                            [68.2692382812501, 71.68281250000001],
                            [69.61181640625003, 72.98193359375],
                            [69.73828124999997, 72.88496093749998],
                            [71.5001953125001, 72.91367187500003],
                            [72.812109375, 72.69140624999997],
                            [72.57412109375, 72.01254882812506],
                            [71.86728515625, 71.457373046875],
                            [72.70449218750005, 70.96323242187498],
                            [72.5767578125, 68.96870117187498],
                            [73.59169921875005, 68.48188476562501],
                            [73.13945312500002, 68.18134765624998],
                            [73.06679687500005, 67.766943359375],
                            [71.84746093750002, 67.00761718750005],
                            [71.36523437500003, 66.96152343749998],
                            [71.53955078125003, 66.68310546875],
                            [70.72490234375007, 66.51943359374997],
                            [70.38281249999997, 66.60249023437501],
                            [70.69072265625002, 66.74531249999998],
                            [70.2833984375001, 66.68579101562503],
                            [69.8771484375001, 66.84545898437506],
                            [69.21777343749997, 66.82861328125],
                            [69.01347656250002, 66.78833007812503],
                            [69.19433593749997, 66.57866210937505],
                            [70.33945312500006, 66.34238281250006],
                            [71.35800781250006, 66.35942382812505],
                            [71.91699218749997, 66.24672851562502],
                            [72.32158203125002, 66.33212890625],
                            [72.4173828125, 66.56079101562506],
                            [73.79208984375, 66.99531250000001],
                            [74.07451171875007, 67.41411132812499],
                            [74.76953124999997, 67.76635742187497],
                            [74.39140625000007, 68.42060546874995],
                            [74.57958984375003, 68.751220703125],
                            [76.10751953125006, 68.975732421875],
                            [76.45917968750004, 68.97827148437497],
                            [77.2384765625001, 68.46958007812498],
                            [77.17441406250012, 67.77851562499998],
                            [77.77158203125006, 67.57026367187501],
                            [78.92246093750006, 67.58911132812503],
                            [77.58828125000005, 67.75190429687498],
                            [77.66484375000002, 68.19038085937495],
                            [77.99511718749997, 68.25947265624998],
                            [77.65068359375007, 68.90302734375001],
                            [76.00097656249997, 69.23505859374998],
                            [75.42001953125, 69.23862304687498],
                            [74.81484375, 69.09057617187503],
                            [73.83603515625006, 69.143212890625],
                            [73.578125, 69.80297851562503],
                            [74.34335937500006, 70.57871093749998],
                            [73.08623046875007, 71.44492187500006],
                            [73.67177734375, 71.84506835937503],
                            [74.99218749999997, 72.14482421874999],
                            [74.78681640625004, 72.811865234375],
                            [75.15244140625, 72.85273437499998],
                            [75.74140625000004, 72.29624023437503],
                            [75.273828125, 71.95893554687495],
                            [75.33203125000003, 71.34174804687498],
                            [76.92900390625002, 71.12788085937504],
                            [77.58964843750007, 71.16791992187501],
                            [78.32060546875002, 70.93041992187503],
                            [78.94218750000002, 70.93378906250001],
                            [79.08388671875, 71.00200195312505],
                            [78.58769531250007, 70.993896484375],
                            [78.21259765625004, 71.26630859374998],
                            [76.43339843750002, 71.55249023437503],
                            [76.03242187500004, 71.91040039062503],
                            [76.87138671875002, 72.03300781250005],
                            [77.77753906250004, 71.83642578125006],
                            [78.23242187500003, 71.95229492187502],
                            [78.01640625000007, 72.092041015625],
                            [77.49287109375004, 72.07172851562504],
                            [77.47158203125, 72.19213867187506],
                            [78.22539062500007, 72.37744140625006],
                            [79.4220703125001, 72.38076171875002],
                            [80.7625, 72.08916015625002],
                            [81.66162109374997, 71.71596679687502],
                            [82.75781250000003, 71.76411132812498],
                            [83.23359375000004, 71.66816406249995],
                            [82.32285156250006, 71.26000976562503],
                            [82.16318359375012, 70.59814453125003],
                            [82.22119140625003, 70.39570312499998],
                            [82.86914062499997, 70.95483398437503],
                            [83.03017578125, 70.58051757812498],
                            [82.6823242187501, 70.21772460937498],
                            [83.0807617187501, 70.09301757812497],
                            [83.07382812500012, 70.276708984375],
                            [83.73593750000006, 70.54648437499998],
                            [83.15126953125005, 71.10361328124998],
                            [83.534375, 71.68393554687498],
                            [83.20029296875012, 71.87470703125004],
                            [82.64541015625005, 71.92524414062504],
                            [82.09365234375, 72.26542968750005],
                            [80.82705078125005, 72.48828124999997],
                            [80.84160156250007, 72.94916992187498],
                            [80.4245117187501, 73.23115234374998],
                            [80.5832031250001, 73.56845703125003],
                            [85.20058593750005, 73.72153320312506],
                            [86.89296875, 73.88710937500002],
                            [85.79257812500012, 73.438330078125],
                            [86.67705078125002, 73.10678710937503],
                            [85.93896484374997, 73.45649414062495],
                            [87.12011718750003, 73.61503906250002],
                            [87.57119140625, 73.81074218750001],
                            [86.57109375000007, 74.24375],
                            [86.0013671875, 74.316015625],
                            [86.39580078125007, 74.45009765624997],
                            [86.89794921874997, 74.32534179687497],
                            [87.22968750000004, 74.3638671875],
                            [85.79101562499997, 74.6451171875],
                            [86.20126953125006, 74.81621093750005],
                            [86.65146484375012, 74.68242187500005],
                            [87.04179687500007, 74.77885742187499],
                            [87.46757812500002, 75.01323242187505],
                            [86.93906250000006, 75.06811523437503],
                            [87.00595703125012, 75.16982421874997],
                            [87.67138671874997, 75.12958984375004],
                            [90.18496093750005, 75.59106445312497],
                            [94.07519531249997, 75.91289062499999],
                            [92.89042968750002, 75.90996093750002],
                            [93.25927734375003, 76.09877929687502],
                            [95.57871093750012, 76.13730468749998],
                            [96.07548828125007, 76.08198242187498],
                            [95.65332031250003, 75.89218750000003],
                            [96.50859375000002, 76.00556640624995],
                            [96.49707031249997, 75.89121093750003],
                            [98.66201171875005, 76.24267578125003],
                            [99.77041015625, 76.02875976562498],
                            [99.5407226562501, 75.79858398437497],
                            [99.85136718750007, 75.93027343749998],
                            [99.8253906250001, 76.13593749999995],
                            [98.80566406250003, 76.48066406250004],
                            [100.84375, 76.52519531250005],
                            [101.59775390625006, 76.43920898437503],
                            [100.92802734375002, 76.55673828124998],
                            [100.98994140625004, 76.99047851562497],
                            [102.61015625000007, 77.508544921875],
                            [104.01455078125, 77.73041992187501],
                            [106.05957031249997, 77.39052734375002],
                            [104.20244140625002, 77.101806640625],
                            [106.9416015625001, 77.034375],
                            [107.42978515625006, 76.92656250000002],
                            [106.41357421874997, 76.51225585937499],
                            [107.72216796875003, 76.52231445312498],
                            [108.18164062500003, 76.73784179687502],
                            [111.39248046875, 76.686669921875],
                            [112.09394531250004, 76.48032226562506],
                            [111.94267578125002, 76.38046875000003],
                            [112.61953125, 76.38354492187506],
                            [112.65625, 76.05356445312498],
                            [113.2726562500001, 76.25166015625001],
                            [113.5638671875, 75.89165039062502],
                            [113.85722656250007, 75.92128906250002],
                            [113.56757812500004, 75.56840820312499],
                            [112.45302734375, 75.83017578125003],
                            [112.95566406250006, 75.571923828125],
                            [113.24296875000007, 75.61142578125003],
                            [113.72617187500012, 75.45063476562498],
                            [112.92490234375012, 75.01503906249997],
                            [109.84033203124997, 74.32197265624998],
                            [109.8102539062501, 74.16918945312503],
                            [108.19951171875002, 73.69409179687497],
                            [107.27109375000006, 73.62104492187501],
                            [106.67939453125004, 73.3306640625],
                            [106.1886718750001, 73.3080078125],
                            [105.14394531250005, 72.77705078125001],
                            [105.7082031250001, 72.836669921875],
                            [106.47792968750005, 73.13940429687503],
                            [107.750390625, 73.17314453125007],
                            [109.33105468749997, 73.48745117187497],
                            [109.85527343750002, 73.47246093750002],
                            [110.86816406249997, 73.73071289062497],
                            [109.70673828125004, 73.74375],
                            [110.2614257812501, 74.01743164062503],
                            [111.05625, 73.93935546875002],
                            [111.13085937500003, 74.05283203125003],
                            [111.55058593750007, 74.02851562499998],
                            [111.22812500000012, 73.96855468750002],
                            [111.40039062500003, 73.827734375],
                            [112.14726562500007, 73.70893554687498],
                            [112.79541015625003, 73.74609375],
                            [112.83593750000003, 73.96206054687502],
                            [113.03281250000006, 73.91386718750007],
                            [113.4162109375001, 73.647607421875],
                            [113.15693359375004, 73.45957031249998],
                            [113.49091796875004, 73.34609375000002],
                            [113.12783203125, 72.8306640625],
                            [113.66455078124997, 72.63452148437503],
                            [113.2155273437501, 72.80585937500001],
                            [113.88623046875003, 73.34580078124998],
                            [113.51035156250012, 73.50498046874998],
                            [115.33769531250007, 73.70258789062501],
                            [118.87089843750007, 73.53789062500002],
                            [118.45703124999997, 73.46440429687507],
                            [118.43027343750012, 73.24653320312501],
                            [119.750390625, 72.97910156250006],
                            [122.26015625, 72.88056640624995],
                            [122.75195312500003, 72.906494140625],
                            [122.61523437499997, 73.02792968750006],
                            [123.1603515625001, 72.95488281250002],
                            [123.62226562500004, 73.19326171875],
                            [123.49111328125005, 73.666357421875],
                            [124.54121093750004, 73.75126953125007],
                            [125.59853515625005, 73.447412109375],
                            [126.25449218750012, 73.548193359375],
                            [126.55253906250007, 73.33491210937498],
                            [127.03134765625006, 73.54746093750003],
                            [127.74033203125012, 73.48154296875],
                            [129.10058593750003, 73.11235351562502],
                            [128.5990234375, 72.895166015625],
                            [129.01728515625004, 72.8724609375],
                            [129.250390625, 72.70517578125003],
                            [128.41826171875002, 72.53515625000003],
                            [129.28134765625006, 72.43769531249998],
                            [129.41064453124997, 72.16630859375002],
                            [128.93496093750005, 72.07949218750002],
                            [127.8034179687501, 72.43403320312504],
                            [127.84140625000012, 72.308251953125],
                            [128.91142578125002, 71.75532226562495],
                            [129.21025390625007, 71.91694335937501],
                            [129.46083984375, 71.73930664062499],
                            [128.84326171875003, 71.6634765625],
                            [129.76191406250004, 71.11953125000002],
                            [130.53710937500003, 70.89252929687495],
                            [130.75712890625002, 70.96235351562498],
                            [131.02158203125006, 70.74609374999997],
                            [132.0353515625001, 71.24404296875],
                            [132.65390625000006, 71.92597656250001],
                            [133.6888671875, 71.434228515625],
                            [134.70273437500012, 71.38681640625003],
                            [135.55917968750006, 71.6103515625],
                            [136.09033203125003, 71.61958007812501],
                            [137.9396484375001, 71.1333984375],
                            [137.84404296875007, 71.22680664062503],
                            [138.31406250000006, 71.32553710937498],
                            [137.918359375, 71.38408203124999],
                            [138.23417968750007, 71.596337890625],
                            [138.78017578125, 71.62900390624998],
                            [139.209375, 71.44477539062501],
                            [139.98417968750007, 71.49150390625005],
                            [139.72294921875002, 71.88496093749998],
                            [139.35927734375005, 71.95136718750001],
                            [140.18769531250004, 72.19130859374997],
                            [139.17636718750006, 72.16347656249997],
                            [139.14082031250004, 72.32973632812502],
                            [139.60117187500012, 72.49609374999997],
                            [141.07929687500004, 72.5869140625],
                            [140.80820312500006, 72.89096679687503],
                            [142.06142578125005, 72.72080078125],
                            [146.25292968749997, 72.442236328125],
                            [146.234765625, 72.34970703125],
                            [144.77636718749997, 72.38227539062495],
                            [144.16923828125002, 72.25878906250003],
                            [144.29492187499997, 72.19262695312497],
                            [146.83183593750007, 72.29541015625003],
                            [146.11328125000003, 71.94497070312497],
                            [146.23027343750007, 72.1375],
                            [145.75859375000007, 72.22587890624999],
                            [145.75673828125005, 71.94130859375002],
                            [145.06396484374997, 71.92607421875002],
                            [145.18857421875012, 71.69580078125],
                            [146.07324218749997, 71.80834960937503],
                            [147.26181640625006, 72.327880859375],
                            [149.50156250000012, 72.16430664062497],
                            [150.01689453125002, 71.89565429687505],
                            [149.04873046875005, 71.79575195312503],
                            [148.9681640625, 71.69047851562499],
                            [150.59980468750004, 71.5201171875],
                            [150.09765624999997, 71.22656249999997],
                            [150.96777343749997, 71.38046874999998],
                            [151.58242187500005, 71.28696289062503],
                            [152.09277343749997, 71.02329101562503],
                            [151.76201171875002, 70.98247070312499],
                            [152.50878906250003, 70.83447265625003],
                            [156.68457031250003, 71.09375],
                            [158.03701171875005, 71.03925781250001],
                            [159.35068359375006, 70.79072265625001],
                            [160.00644531250006, 70.30966796875006],
                            [159.72939453125005, 69.87021484375006],
                            [160.91074218750012, 69.60634765625002],
                            [161.03554687500005, 69.09819335937507],
                            [161.30986328125007, 68.98227539062498],
                            [160.85605468750006, 68.53833007812506],
                            [161.565625, 68.90517578125],
                            [161.53691406250002, 69.379541015625],
                            [162.16601562499997, 69.61157226562503],
                            [163.20136718750004, 69.71474609375],
                            [166.82031250000003, 69.49956054687505],
                            [167.8568359375, 69.72822265624998],
                            [168.30302734375002, 69.27148437500003],
                            [169.31064453125006, 69.07954101562498],
                            [169.60986328124997, 68.78603515624997],
                            [170.53759765624997, 68.82539062500001],
                            [170.99541015625002, 69.04531250000005],
                            [170.58222656250004, 69.58334960937506],
                            [170.16093750000007, 69.62656249999998],
                            [170.48681640625003, 70.107568359375],
                            [173.27744140625006, 69.823828125],
                            [173.43867187500004, 69.94682617187502],
                            [175.92148437500012, 69.89531250000002],
                            [179.27265624999998, 69.25966796875002],
                            [180, 68.98344726562505],
                            [180, 65.06723632812498],
                            [178.51953125000003, 64.60297851562498],
                            [177.7486328125, 64.71704101562503],
                            [176.88085937499997, 65.08193359375002],
                            [176.34101562500015, 65.04731445312501],
                            [177.03730468750004, 64.99965820312497],
                            [177.22285156250004, 64.861669921875],
                            [177.06875, 64.78666992187502],
                            [176.06113281250012, 64.96088867187498],
                            [174.54882812500009, 64.68388671875005],
                            [176.0565429687501, 64.90473632812498],
                            [176.35097656250005, 64.70512695312502],
                            [176.14091796875007, 64.58583984375005],
                            [177.42744140625015, 64.76337890624998],
                            [177.43291015625002, 64.44448242187502],
                            [177.6875, 64.30473632812507],
                            [178.04472656250013, 64.21958007812503],
                            [178.22949218749991, 64.36440429687497],
                            [178.38144531250018, 64.26088867187502],
                            [178.73144531250003, 63.667089843750006],
                            [178.44042968750009, 63.605566406250006],
                            [178.74404296874994, 63.39477539062503],
                            [178.79296874999997, 63.54033203125002],
                            [179.38857421875, 63.14721679687497],
                            [179.25957031250002, 63.00830078125],
                            [179.5705078125001, 62.6875],
                            [179.12070312500012, 62.32036132812499],
                            [177.292578125, 62.59902343750002],
                            [177.33896484375006, 62.781347656250034],
                            [177.02353515625012, 62.777246093749994],
                            [177.15947265625007, 62.56098632812498],
                            [174.51435546875015, 61.823632812499966],
                            [173.6234375, 61.716064453125],
                            [173.13183593749997, 61.40664062500002],
                            [172.85654296875006, 61.469189453124955],
                            [172.90800781250002, 61.311621093750006],
                            [172.39609375000006, 61.16738281250002],
                            [172.39277343750004, 61.061767578125],
                            [170.60820312500007, 60.434912109375034],
                            [170.3509765625, 59.965527343749955],
                            [169.9826171875001, 60.067089843749955],
                            [169.2267578125001, 60.59594726562497],
                            [168.1375, 60.57392578125001],
                            [167.22675781250004, 60.406298828125045],
                            [166.27304687500012, 59.85625],
                            [166.13603515625007, 59.979345703125034],
                            [166.35214843750006, 60.48481445312498],
                            [165.08457031250006, 60.09858398437498],
                            [164.95371093750006, 59.843603515625006],
                            [164.52529296875, 60.06127929687503],
                            [164.11328125000003, 59.89755859374998],
                            [164.13505859375002, 59.984375],
                            [163.74384765625004, 60.02802734374998],
                            [163.36484375000012, 59.78144531250004],
                            [163.27285156250005, 59.302587890625006],
                            [162.14160156249997, 58.44741210937502],
                            [161.96005859375012, 58.07690429687506],
                            [162.39140625000002, 57.717236328124955],
                            [162.65429687499997, 57.94824218750003],
                            [163.22578125000004, 57.790380859375034],
                            [162.77929687500003, 57.35761718749998],
                            [162.79111328125012, 56.875390624999966],
                            [162.92207031250004, 56.72265625000003],
                            [163.2565429687501, 56.68803710937499],
                            [163.33554687500012, 56.232519531250006],
                            [163.04736328125003, 56.044677734375],
                            [162.84033203125003, 56.065625],
                            [162.628125, 56.232275390625034],
                            [163.03837890625002, 56.521875],
                            [162.67148437500006, 56.49008789062498],
                            [162.52822265625005, 56.260693359374955],
                            [162.08496093749997, 56.08964843750002],
                            [161.72392578125002, 55.49614257812499],
                            [162.10556640625006, 54.75214843750004],
                            [161.62480468750002, 54.51625976562502],
                            [160.77265625000004, 54.54135742187498],
                            [160.0744140625001, 54.18916015625001],
                            [159.84375, 53.78364257812498],
                            [160.02509765625004, 53.129589843749955],
                            [159.58593750000003, 53.237695312499966],
                            [158.74541015625002, 52.90893554687506],
                            [158.47207031250005, 53.032373046874966],
                            [158.6087890625, 52.873632812500034],
                            [158.49316406249997, 52.383154296875034],
                            [158.10351562500003, 51.80961914062499],
                            [156.84746093750002, 51.006591796875],
                            [156.74775390625004, 50.969287109375045],
                            [156.52119140625004, 51.38027343750002],
                            [156.36474609374997, 52.509375],
                            [156.11035156250003, 52.86616210937504],
                            [155.62031250000004, 54.86455078125002],
                            [155.5548828125001, 55.348486328125034],
                            [155.98251953125012, 56.69521484375002],
                            [156.8488281250001, 57.290185546874994],
                            [156.97675781250004, 57.46630859375],
                            [156.82988281250007, 57.77963867187498],
                            [157.4503906250001, 57.79926757812498],
                            [157.66640625000005, 58.01977539062506],
                            [158.27519531250007, 58.00898437499998],
                            [159.21064453125004, 58.519433593749966],
                            [159.8473632812501, 59.127148437499955],
                            [161.75351562500012, 60.15229492187501],
                            [162.06816406250002, 60.466406250000034],
                            [163.70996093749997, 60.916796875000045],
                            [163.55351562500002, 61.02563476562503],
                            [164.00546875000006, 61.34379882812499],
                            [163.80439453125004, 61.46137695312498],
                            [164.20722656250004, 62.29223632812506],
                            [164.59833984375004, 62.470556640625034],
                            [165.20810546875012, 62.37397460937501],
                            [165.41738281250005, 62.447070312500045],
                            [164.418359375, 62.704638671875045],
                            [163.33173828125004, 62.550927734374994],
                            [163.01767578125006, 61.89106445312504],
                            [163.25781249999997, 61.69946289062497],
                            [163.08525390625002, 61.570556640625],
                            [162.85595703125003, 61.705029296874955],
                            [162.39257812500003, 61.662109375],
                            [160.76660156249997, 60.753320312499966],
                            [160.17363281250002, 60.638427734375],
                            [160.37890625000003, 61.02548828124998],
                            [159.79042968750005, 60.956640625],
                            [160.309375, 61.894384765625006],
                            [159.55234375000012, 61.71948242187497],
                            [159.18925781250007, 61.92939453125001],
                            [158.07011718750002, 61.75361328125001],
                            [157.46933593750012, 61.798925781250006],
                            [157.0841796875001, 61.67568359375002],
                            [155.71611328125002, 60.682373046875],
                            [154.97080078125012, 60.376660156249955],
                            [154.29306640625006, 59.833349609375034],
                            [154.1498046875, 59.52851562500001],
                            [154.97128906250006, 59.44960937500002],
                            [155.16044921875002, 59.19013671875001],
                            [154.45800781250003, 59.21655273437497],
                            [154.01093750000004, 59.075537109375006],
                            [153.69521484375005, 59.22475585937505],
                            [153.36113281250002, 59.214794921874955],
                            [152.81787109375003, 58.92626953124997],
                            [152.31962890625002, 59.03076171875003],
                            [152.08789062499997, 58.910449218750045],
                            [151.32675781250006, 58.875097656250034],
                            [151.12109375000003, 59.08251953125003],
                            [152.26064453125, 59.22358398437498],
                            [151.34824218750012, 59.561132812500006],
                            [150.4835937500001, 59.494384765625],
                            [150.66728515625002, 59.55634765625001],
                            [149.64257812499997, 59.770410156249994],
                            [149.06523437500002, 59.63051757812502],
                            [149.20498046875, 59.488183593749966],
                            [148.79707031250004, 59.532324218750006],
                            [148.74414062499997, 59.37353515624997],
                            [148.96464843750007, 59.36914062499997],
                            [148.72666015625006, 59.257910156250034],
                            [148.25742187500006, 59.414208984374994],
                            [147.51445312500002, 59.2685546875],
                            [146.53720703125006, 59.45698242187501],
                            [146.0495117187501, 59.17055664062502],
                            [145.55458984375, 59.413525390624955],
                            [143.19218750000002, 59.3701171875],
                            [142.58027343750004, 59.240136718749966],
                            [140.79023437500004, 58.30346679687503],
                            [140.446875, 57.81367187499998],
                            [138.66210937500003, 56.96552734375004],
                            [137.69150390625006, 56.13935546875004],
                            [135.2625, 54.94331054687498],
                            [135.25771484375005, 54.73149414062499],
                            [135.85156249999997, 54.583935546874955],
                            [136.797265625, 54.62099609375005],
                            [136.71884765625006, 53.804101562499994],
                            [137.15537109375012, 53.82167968750002],
                            [137.14160156249997, 54.182226562500006],
                            [137.66601562500003, 54.283300781250006],
                            [137.3392578125, 54.10053710937498],
                            [137.83476562500002, 53.94672851562498],
                            [137.25371093750007, 53.546142578125],
                            [137.95048828125007, 53.60356445312499],
                            [138.52792968750012, 53.959863281249994],
                            [138.56914062500002, 53.818798828124955],
                            [138.24970703125004, 53.524023437500034],
                            [138.45068359375003, 53.53701171875002],
                            [138.69941406250004, 53.869726562500034],
                            [138.65722656249997, 54.29833984375003],
                            [139.31972656250005, 54.19296874999998],
                            [139.707421875, 54.27714843749999],
                            [140.68759765625012, 53.59643554687503],
                            [141.3737304687501, 53.29277343749999],
                            [141.18125, 53.01528320312505],
                            [140.83964843750002, 53.087890625],
                            [141.25585937499997, 52.84013671874996],
                            [141.13242187500006, 52.435693359374994],
                            [141.48525390625, 52.17851562500002],
                            [141.36689453125004, 51.92065429687506],
                            [140.93261718750003, 51.61992187499999],
                            [140.5208984375, 50.80019531250005],
                            [140.62451171874997, 50.08242187500002],
                            [140.46269531250002, 49.911474609375006],
                            [140.51718750000012, 49.59614257812498],
                            [140.17060546875004, 48.52368164062497],
                            [138.58681640625005, 47.057226562500006],
                            [138.33691406250003, 46.543408203124955],
                            [137.68544921875, 45.81835937500003],
                            [136.14228515625004, 44.489111328125034],
                            [135.87460937500012, 44.37353515625003],
                            [135.1310546875001, 43.52573242187506],
                            [134.01044921875004, 42.94746093750001],
                            [133.15996093750007, 42.69697265624998],
                            [132.70898437500003, 42.875830078125006],
                            [132.30380859375006, 42.88330078125],
                            [132.30957031249997, 43.31352539062499],
                            [131.8666015625, 43.09516601562501],
                            [131.93896484374997, 43.30195312500004],
                            [131.15830078125012, 42.62602539062499],
                            [130.709375, 42.656396484374966],
                            [130.8341796875001, 42.52294921875006],
                            [130.68730468750007, 42.30253906249999],
                        ],
                    ],
                    [
                        [
                            [107.69550781250004, 78.13090820312505],
                            [107.48164062500004, 78.057763671875],
                            [106.41552734375003, 78.13984375000001],
                            [107.69550781250004, 78.13090820312505],
                        ],
                    ],
                    [
                        [
                            [102.88476562499997, 79.25395507812505],
                            [102.4123046875001, 78.83544921874997],
                            [103.80078124999997, 79.14926757812503],
                            [104.45205078125005, 78.880029296875],
                            [105.14599609375003, 78.81884765625006],
                            [105.31259765625012, 78.49990234375],
                            [104.74179687500012, 78.33974609374997],
                            [102.79667968750007, 78.18789062500002],
                            [101.20410156249997, 78.19194335937505],
                            [99.50029296875002, 77.97607421875003],
                            [101.590625, 79.350439453125],
                            [102.25126953125002, 79.25605468749995],
                            [102.40488281250006, 79.43320312499998],
                            [102.88476562499997, 79.25395507812505],
                        ],
                    ],
                    [
                        [
                            [76.24892578125005, 79.65107421874995],
                            [77.58896484375012, 79.50190429687504],
                            [76.64951171875012, 79.493408203125],
                            [76.24892578125005, 79.65107421874995],
                        ],
                    ],
                    [
                        [
                            [92.68349609375005, 79.685205078125],
                            [91.37626953125007, 79.83549804687505],
                            [91.22929687500007, 80.03071289062504],
                            [93.803125, 79.904541015625],
                            [92.68349609375005, 79.685205078125],
                        ],
                    ],
                    [
                        [
                            [51.409277343750006, 79.94423828125],
                            [50.09140625, 79.98056640625003],
                            [50.93632812500002, 80.09423828125],
                            [51.409277343750006, 79.94423828125],
                        ],
                    ],
                    [
                        [
                            [59.68886718750005, 79.95581054687506],
                            [58.91923828125002, 79.98461914062506],
                            [59.54453125000006, 80.11884765624995],
                            [59.68886718750005, 79.95581054687506],
                        ],
                    ],
                    [
                        [
                            [97.67451171875004, 80.15825195312499],
                            [97.65166015625002, 79.76064453125],
                            [98.59648437500002, 80.05219726562495],
                            [100.0612304687501, 79.77709960937506],
                            [99.68066406250003, 79.32333984374998],
                            [99.04179687500007, 79.29301757812502],
                            [99.92929687500012, 78.96142578124997],
                            [98.41113281250003, 78.78779296875004],
                            [95.53105468750007, 79.09809570312501],
                            [95.02041015625005, 79.05268554687498],
                            [94.21875, 79.40234375],
                            [93.07080078124997, 79.49531250000001],
                            [94.98730468749997, 80.096826171875],
                            [95.28134765625012, 80.030517578125],
                            [97.67451171875004, 80.15825195312499],
                        ],
                    ],
                    [
                        [
                            [50.05175781250003, 80.07431640625003],
                            [49.55605468750005, 80.15893554687503],
                            [49.883691406249994, 80.230224609375],
                            [50.05175781250003, 80.07431640625003],
                        ],
                    ],
                    [
                        [
                            [57.07871093750006, 80.35092773437498],
                            [56.986914062500006, 80.07148437499998],
                            [55.811621093750006, 80.08715820312497],
                            [56.02441406250003, 80.34130859374997],
                            [57.07871093750006, 80.35092773437498],
                        ],
                    ],
                    [
                        [
                            [53.521386718749994, 80.18520507812497],
                            [52.34355468750002, 80.213232421875],
                            [52.85390625, 80.40239257812499],
                            [53.85166015625006, 80.26835937500005],
                            [53.521386718749994, 80.18520507812497],
                        ],
                    ],
                    [
                        [
                            [57.95625, 80.12324218749995],
                            [57.33232421875002, 80.15810546875005],
                            [57.075, 80.49394531249999],
                            [59.25546875000006, 80.34321289062501],
                            [58.39794921874997, 80.31875],
                            [57.95625, 80.12324218749995],
                        ],
                    ],
                    [
                        [
                            [54.41533203125002, 80.47280273437502],
                            [53.811914062499994, 80.47622070312502],
                            [53.87724609375002, 80.60527343750002],
                            [54.41533203125002, 80.47280273437502],
                        ],
                    ],
                    [
                        [
                            [47.441992187500006, 80.853662109375],
                            [48.44570312500005, 80.80600585937506],
                            [48.68359375000003, 80.63325195312504],
                            [47.7052734375001, 80.76518554687499],
                            [46.141406250000074, 80.44672851562495],
                            [45.969042968750074, 80.56948242187502],
                            [44.9049804687501, 80.61127929687501],
                            [47.441992187500006, 80.853662109375],
                        ],
                    ],
                    [
                        [
                            [62.167773437500074, 80.83476562500005],
                            [62.07578125000006, 80.616943359375],
                            [61.05126953124997, 80.418603515625],
                            [60.27832031249997, 80.49443359374999],
                            [59.649804687499994, 80.43125],
                            [59.59228515625003, 80.81650390624998],
                            [62.167773437500074, 80.83476562500005],
                        ],
                    ],
                    [
                        [
                            [50.278125, 80.92724609374997],
                            [51.70361328125003, 80.68764648437502],
                            [48.81103515625003, 80.35371093750001],
                            [48.97753906250003, 80.16259765624997],
                            [47.73730468749997, 80.08168945312502],
                            [47.89296875000005, 80.23925781249997],
                            [46.991015625000074, 80.182763671875],
                            [46.644433593749994, 80.30034179687507],
                            [47.89580078125002, 80.52905273437503],
                            [49.087792968749994, 80.515771484375],
                            [49.24433593750004, 80.82138671875],
                            [50.278125, 80.92724609374997],
                        ],
                    ],
                    [
                        [
                            [80.02666015625007, 80.84814453125003],
                            [79.09853515625005, 80.81206054687505],
                            [79.21738281250012, 80.96035156249997],
                            [80.27958984375007, 80.94980468750003],
                            [80.02666015625007, 80.84814453125003],
                        ],
                    ],
                    [
                        [
                            [61.1408203125001, 80.95034179687497],
                            [60.0783203125001, 80.99916992187497],
                            [61.45742187499999, 81.10395507812501],
                            [61.1408203125001, 80.95034179687497],
                        ],
                    ],
                    [
                        [
                            [54.71894531250004, 81.11596679687497],
                            [56.47226562500006, 80.99824218749995],
                            [57.58037109375002, 80.75546874999998],
                            [55.88339843750006, 80.62841796875003],
                            [54.66816406250004, 80.73867187500002],
                            [54.04541015624997, 80.87197265625],
                            [54.71894531250004, 81.11596679687497],
                        ],
                    ],
                    [
                        [
                            [58.62236328125002, 81.04165039062502],
                            [58.930566406249994, 80.83168945312497],
                            [58.28564453124997, 80.76489257812503],
                            [57.21093749999997, 81.01708984374997],
                            [58.04951171875004, 81.11845703125002],
                            [58.62236328125002, 81.04165039062502],
                        ],
                    ],
                    [
                        [
                            [63.37382812500002, 80.70009765624997],
                            [62.59257812500002, 80.85302734375006],
                            [64.80205078125002, 81.197265625],
                            [65.43740234375005, 80.93071289062507],
                            [63.37382812500002, 80.70009765624997],
                        ],
                    ],
                    [
                        [
                            [91.56718750000007, 81.14121093750003],
                            [91.2228515625001, 81.063818359375],
                            [89.90117187500002, 81.17070312500002],
                            [91.56718750000007, 81.14121093750003],
                        ],
                    ],
                    [
                        [
                            [96.52656250000004, 81.0755859375],
                            [97.86992187500007, 80.76328125000006],
                            [97.02539062499997, 80.53554687500002],
                            [97.29843750000006, 80.27275390625005],
                            [93.6546875, 80.009619140625],
                            [91.52382812500005, 80.35854492187502],
                            [93.2625, 80.79125976562497],
                            [92.59257812500007, 80.780859375],
                            [92.7103515625, 80.87216796875003],
                            [95.1595703125, 81.27099609375003],
                            [95.80068359375005, 81.28046874999998],
                            [96.52656250000004, 81.0755859375],
                        ],
                    ],
                    [
                        [
                            [57.81025390625004, 81.54604492187502],
                            [58.563867187499994, 81.41840820312504],
                            [57.858691406250074, 81.36806640625],
                            [57.76972656250004, 81.16972656249999],
                            [55.71669921875005, 81.1884765625],
                            [55.46601562500004, 81.31118164062502],
                            [57.81025390625004, 81.54604492187502],
                        ],
                    ],
                    [
                        [
                            [63.65097656250006, 81.60932617187501],
                            [62.10644531249997, 81.679345703125],
                            [63.709570312500006, 81.68730468750002],
                            [63.65097656250006, 81.60932617187501],
                        ],
                    ],
                    [
                        [
                            [58.29541015625003, 81.715185546875],
                            [58.13457031250002, 81.82797851562498],
                            [59.261816406250006, 81.85419921874998],
                            [59.35644531250003, 81.75898437499995],
                            [58.29541015625003, 81.715185546875],
                        ],
                    ],
                ],
            },
            properties: {
                name: "俄罗斯",
                childNum: 73,
                cp: [80.35, 60.45],
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [30.50996093750001, -1.067285156250009],
                        [30.47705078125, -1.0830078125],
                        [30.47021484375, -1.131152343750003],
                        [30.508105468750017, -1.208203125000011],
                        [30.631933593750006, -1.367480468750003],
                        [30.710742187500017, -1.396777343750003],
                        [30.76220703125, -1.458691406250011],
                        [30.812597656250006, -1.563085937500006],
                        [30.8765625, -2.143359375],
                        [30.85498046875, -2.265429687500003],
                        [30.828710937500006, -2.338476562500006],
                        [30.7625, -2.371679687500006],
                        [30.71484375, -2.363476562500011],
                        [30.656640625000023, -2.373828125],
                        [30.593359375, -2.396777343750003],
                        [30.553613281250023, -2.400097656250011],
                        [30.408496093750017, -2.31298828125],
                        [30.117285156250006, -2.416601562500006],
                        [29.93017578125, -2.339550781250011],
                        [29.8681640625, -2.71640625],
                        [29.698046875000017, -2.794726562500003],
                        [29.390234375, -2.80859375],
                        [29.10205078125, -2.595703125],
                        [29.01435546875001, -2.72021484375],
                        [28.893945312500023, -2.635058593750003],
                        [28.876367187500023, -2.400292968750009],
                        [29.13154296875001, -2.195117187500003],
                        [29.196582031250017, -1.719921875000011],
                        [29.576953125000017, -1.387890625000011],
                        [29.82539062500001, -1.335546875],
                        [29.930078125000023, -1.469921875000011],
                        [30.360253906250023, -1.074609375],
                        [30.41230468750001, -1.063085937500006],
                        [30.46992187500001, -1.066015625],
                        [30.50996093750001, -1.067285156250009],
                    ],
                ],
            },
            properties: {
                name: "卢旺达",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [41.98769531250005, 16.715625],
                            [42.059960937499994, 16.803515625000017],
                            [42.15781250000006, 16.570703125000023],
                            [41.80156250000002, 16.778759765624955],
                            [41.86044921875006, 17.002539062499977],
                            [41.98769531250005, 16.715625],
                        ],
                    ],
                    [
                        [
                            [46.53144531250004, 29.09624023437499],
                            [47.433203125, 28.989550781250017],
                            [47.671289062499994, 28.53315429687504],
                            [48.442480468750006, 28.542919921874983],
                            [48.80898437499999, 27.895898437499966],
                            [48.797167968750074, 27.72431640625001],
                            [49.2375, 27.49272460937499],
                            [49.17509765625002, 27.43764648437505],
                            [49.40527343749997, 27.18095703124996],
                            [50.149804687499994, 26.66264648437499],
                            [50.00810546875002, 26.678515625000017],
                            [50.21386718750003, 26.30849609375005],
                            [50.15546875000004, 26.100537109374955],
                            [50.03164062499999, 26.11098632812505],
                            [50.55791015625002, 25.086669921875],
                            [50.66689453125005, 24.96381835937501],
                            [50.72558593749997, 24.869384765625057],
                            [50.80439453125004, 24.789257812499983],
                            [50.928320312500006, 24.595117187500023],
                            [50.96601562500004, 24.573925781249983],
                            [51.022753906250074, 24.56523437499999],
                            [51.09335937500006, 24.564648437499955],
                            [51.178027343750074, 24.586718750000017],
                            [51.26796875, 24.607226562500017],
                            [51.33847656250006, 24.564355468749994],
                            [51.41123046875006, 24.570800781250057],
                            [51.30986328125002, 24.340380859375017],
                            [51.56835937500003, 24.286181640625074],
                            [51.592578125000074, 24.07885742187503],
                            [52.55507812500005, 22.932812499999955],
                            [55.104296875000074, 22.621484375000023],
                            [55.185839843750074, 22.7041015625],
                            [55.64101562499999, 22.001855468749994],
                            [54.97734375000002, 19.995947265625006],
                            [51.977636718750006, 18.996142578125074],
                            [49.04199218750003, 18.58178710937503],
                            [48.17216796875002, 18.156933593749983],
                            [47.57958984374997, 17.448339843750034],
                            [47.44179687499999, 17.111865234375045],
                            [47.14355468749997, 16.946679687499966],
                            [46.97568359375006, 16.953466796875034],
                            [46.72763671875006, 17.26557617187501],
                            [45.5353515625001, 17.30205078124999],
                            [45.14804687500006, 17.427441406249955],
                            [43.91699218749997, 17.32470703124997],
                            [43.41796875000003, 17.516259765625023],
                            [43.19091796875003, 17.359375],
                            [43.16503906249997, 16.689404296874955],
                            [42.79931640624997, 16.37177734375001],
                            [42.29394531249997, 17.434960937499966],
                            [41.75, 17.88574218749997],
                            [41.22949218750003, 18.678417968749983],
                            [40.75917968750005, 19.755468750000034],
                            [40.080664062500006, 20.265917968750017],
                            [39.728320312500074, 20.390332031249955],
                            [39.27607421875004, 20.973974609375034],
                            [39.093554687500074, 21.31035156249999],
                            [39.14707031250006, 21.518994140624955],
                            [38.98789062500006, 21.88173828125005],
                            [39.06201171874997, 22.592187500000023],
                            [38.46416015625002, 23.71186523437504],
                            [37.91972656250002, 24.185400390625063],
                            [37.54306640625006, 24.291650390625023],
                            [37.18085937500004, 24.82001953125001],
                            [37.26630859375004, 24.960058593750034],
                            [37.14882812499999, 25.291113281249977],
                            [35.18046875000002, 28.03486328125004],
                            [34.722070312499994, 28.130664062500017],
                            [34.625, 28.064501953125017],
                            [34.95078125, 29.353515625],
                            [36.068457031250006, 29.200537109375006],
                            [36.28281250000006, 29.355371093750023],
                            [36.47607421874997, 29.49511718749997],
                            [36.59179687500003, 29.666113281250006],
                            [36.703906250000074, 29.831640624999977],
                            [36.75527343750005, 29.86601562499996],
                            [37.46923828125003, 29.995068359374955],
                            [37.49072265625003, 30.01171874999997],
                            [37.55361328125005, 30.14458007812496],
                            [37.63359375000002, 30.313281250000045],
                            [37.64990234374997, 30.330957031249994],
                            [37.669726562500074, 30.34814453125003],
                            [37.862890625, 30.44262695312503],
                            [37.98007812500006, 30.5],
                            [37.47900390624997, 31.007763671874955],
                            [37.10527343750002, 31.35517578125004],
                            [36.95859375000006, 31.491503906250017],
                            [37.215625, 31.55610351562501],
                            [37.49335937500004, 31.625878906250023],
                            [38.111425781250006, 31.78115234375005],
                            [38.37548828124997, 31.84746093749996],
                            [38.962304687499994, 31.99492187499999],
                            [38.99707031249997, 32.00747070312505],
                            [39.145410156249994, 32.12451171875],
                            [39.36865234374997, 32.09174804687498],
                            [39.70410156250003, 32.04252929687499],
                            [40.02783203124997, 31.995019531249994],
                            [40.3693359375001, 31.93896484375003],
                            [40.47890625000005, 31.89335937499999],
                            [42.07441406250004, 31.08037109374999],
                            [43.77373046875002, 29.84921875],
                            [44.71650390625004, 29.19360351562503],
                            [46.35644531250003, 29.06367187500001],
                            [46.53144531250004, 29.09624023437499],
                        ],
                    ],
                ],
            },
            properties: {
                name: "沙特阿拉伯",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [36.87138671875002, 21.996728515624994],
                        [36.92695312500001, 21.58652343749999],
                        [37.25859375000002, 21.108544921874994],
                        [37.25722656250002, 21.03940429687499],
                        [37.15058593750001, 21.103759765625],
                        [37.14111328125, 20.98178710937499],
                        [37.19316406250002, 20.12070312499999],
                        [37.471289062500006, 18.820117187500003],
                        [38.609472656250006, 18.005078125],
                        [38.422460937500006, 17.823925781249997],
                        [38.39716796875001, 17.778369140625003],
                        [38.38554687500002, 17.751269531250003],
                        [38.37373046875001, 17.717333984375003],
                        [38.34736328125001, 17.68359375],
                        [38.28984375000002, 17.637011718750003],
                        [38.26728515625001, 17.61669921875],
                        [38.253515625, 17.584765625],
                        [37.78242187500001, 17.4580078125],
                        [37.547460937500006, 17.324121093749994],
                        [37.51015625000002, 17.288134765625003],
                        [37.45292968750002, 17.108691406250003],
                        [37.41103515625002, 17.06171875],
                        [37.24882812500002, 17.056884765625],
                        [37.16953125, 17.04140625],
                        [37.0615234375, 17.061279296875],
                        [37.00898437500001, 17.058886718750003],
                        [36.995214843750006, 17.020556640625003],
                        [36.97578125000001, 16.86655273437499],
                        [36.97871093750001, 16.800585937500003],
                        [36.887792968750006, 16.624658203124994],
                        [36.91376953125001, 16.296191406250003],
                        [36.566015625, 15.362109375],
                        [36.4267578125, 15.132080078125],
                        [36.44814453125002, 14.940087890624994],
                        [36.470800781250006, 14.736474609374994],
                        [36.52431640625002, 14.2568359375],
                        [36.12519531250001, 12.75703125],
                        [35.67021484375002, 12.623730468749997],
                        [35.1123046875, 11.816552734374994],
                        [34.93144531250002, 10.864794921874989],
                        [34.77128906250002, 10.746191406249991],
                        [34.571875, 10.880175781249989],
                        [34.34394531250001, 10.658642578124997],
                        [34.31123046875001, 10.190869140624997],
                        [34.078125, 9.461523437499991],
                        [33.87148437500002, 9.506152343749989],
                        [33.96328125000002, 9.861767578124997],
                        [33.90703125000002, 10.181445312499989],
                        [33.13007812500001, 10.745947265624991],
                        [33.073339843750006, 11.606103515624994],
                        [33.199316406250006, 12.21728515625],
                        [32.721875, 12.223095703124997],
                        [32.73671875000002, 12.009667968749994],
                        [32.072265625, 12.006738281249994],
                        [32.338476562500006, 11.710107421874994],
                        [32.42080078125002, 11.089111328125],
                        [31.224902343750017, 9.799267578124997],
                        [30.75537109375, 9.731201171875],
                        [30.003027343750006, 10.277392578124989],
                        [29.60546875, 10.065087890624994],
                        [29.47314453125, 9.768603515624989],
                        [28.979589843750006, 9.594189453124997],
                        [28.844531250000017, 9.326074218749994],
                        [28.048925781250006, 9.32861328125],
                        [27.880859375, 9.601611328124989],
                        [27.07421875, 9.613818359374989],
                        [26.65869140625, 9.484130859375],
                        [25.91914062500001, 10.169335937499994],
                        [25.858203125000017, 10.406494140625],
                        [25.211718750000017, 10.329931640624991],
                        [25.066992187500006, 10.293798828124991],
                        [24.785253906250006, 9.774658203125],
                        [24.53193359375001, 8.886914062499997],
                        [24.147363281250023, 8.665625],
                        [23.53730468750001, 8.815820312499994],
                        [23.46826171875, 9.11474609375],
                        [23.62265625, 9.340625],
                        [23.646289062500017, 9.822900390624994],
                        [22.86005859375001, 10.919677734375],
                        [22.922656250000017, 11.344873046874994],
                        [22.591113281250017, 11.579882812499989],
                        [22.580957031250023, 11.990136718749994],
                        [22.472460937500017, 12.067773437499994],
                        [22.352343750000017, 12.660449218749989],
                        [21.928125, 12.678125],
                        [21.825292968750006, 12.79052734375],
                        [22.228125, 13.32958984375],
                        [22.1064453125, 13.7998046875],
                        [22.53857421875, 14.161865234375],
                        [22.38154296875001, 14.550488281249997],
                        [22.6708984375, 14.722460937500003],
                        [22.93232421875001, 15.162109375],
                        [22.933886718750017, 15.533105468749994],
                        [23.10517578125001, 15.702539062499994],
                        [23.970800781250006, 15.721533203124991],
                        [23.980273437500017, 19.496630859375003],
                        [23.980273437500017, 19.99594726562499],
                        [24.9794921875, 20.002587890624994],
                        [24.980273437500017, 21.995849609375],
                        [28.036425781250017, 21.995361328125],
                        [31.092675781250023, 21.994873046875],
                        [31.260644531250023, 22.00229492187499],
                        [31.400292968750023, 22.202441406250003],
                        [31.486132812500017, 22.14780273437499],
                        [31.434472656250023, 21.995849609375],
                        [36.87138671875002, 21.996728515624994],
                    ],
                ],
            },
            properties: {
                name: "苏丹",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [34.078125, 9.461523437499991],
                        [34.07275390625, 8.545263671874991],
                        [33.95332031250001, 8.443505859374994],
                        [33.28105468750002, 8.437255859375],
                        [32.99892578125002, 7.899511718749991],
                        [33.902441406250006, 7.509521484375],
                        [34.06425781250002, 7.225732421874994],
                        [34.71064453125001, 6.660302734374994],
                        [34.98359375000001, 5.858300781249994],
                        [35.26835937500002, 5.492285156249991],
                        [35.08447265625, 5.311865234374991],
                        [34.87832031250002, 5.109570312499997],
                        [34.63984375000001, 4.87548828125],
                        [34.38017578125002, 4.620654296874989],
                        [34.176855468750006, 4.419091796874994],
                        [33.97607421875, 4.22021484375],
                        [33.74160156250002, 3.985253906249994],
                        [33.568457031250006, 3.81171875],
                        [33.489355468750006, 3.755078125],
                        [32.99726562500001, 3.880175781249989],
                        [32.33574218750002, 3.706201171874994],
                        [32.13593750000001, 3.519726562499997],
                        [31.79804687500001, 3.802636718749994],
                        [31.547167968750017, 3.677587890624991],
                        [31.15234375, 3.785595703124997],
                        [30.83857421875001, 3.49072265625],
                        [30.757226562500023, 3.62421875],
                        [30.586718750000017, 3.62421875],
                        [30.50830078125, 3.835693359375],
                        [30.194921875, 3.98193359375],
                        [29.676855468750006, 4.5869140625],
                        [29.469628906250023, 4.61181640625],
                        [29.224902343750017, 4.391894531249989],
                        [28.72705078125, 4.504980468749991],
                        [28.427539062500017, 4.324169921874997],
                        [28.19208984375001, 4.350244140624994],
                        [27.7880859375, 4.644677734374994],
                        [27.4033203125, 5.109179687499989],
                        [27.143945312500023, 5.722949218749989],
                        [26.514257812500006, 6.069238281249994],
                        [26.30859375, 6.455322265625],
                        [26.36181640625, 6.635302734374989],
                        [25.27890625, 7.427490234375],
                        [25.18134765625001, 7.557226562499991],
                        [25.20039062500001, 7.807910156249989],
                        [24.853320312500017, 8.137548828124991],
                        [24.291406250000023, 8.29140625],
                        [24.147363281250023, 8.665625],
                        [24.53193359375001, 8.886914062499997],
                        [24.785253906250006, 9.774658203125],
                        [25.066992187500006, 10.293798828124991],
                        [25.211718750000017, 10.329931640624991],
                        [25.858203125000017, 10.406494140625],
                        [25.91914062500001, 10.169335937499994],
                        [26.65869140625, 9.484130859375],
                        [27.07421875, 9.613818359374989],
                        [27.880859375, 9.601611328124989],
                        [28.048925781250006, 9.32861328125],
                        [28.844531250000017, 9.326074218749994],
                        [28.979589843750006, 9.594189453124997],
                        [29.47314453125, 9.768603515624989],
                        [29.60546875, 10.065087890624994],
                        [30.003027343750006, 10.277392578124989],
                        [30.75537109375, 9.731201171875],
                        [31.224902343750017, 9.799267578124997],
                        [32.42080078125002, 11.089111328125],
                        [32.338476562500006, 11.710107421874994],
                        [32.072265625, 12.006738281249994],
                        [32.73671875000002, 12.009667968749994],
                        [32.721875, 12.223095703124997],
                        [33.199316406250006, 12.21728515625],
                        [33.073339843750006, 11.606103515624994],
                        [33.13007812500001, 10.745947265624991],
                        [33.90703125000002, 10.181445312499989],
                        [33.96328125000002, 9.861767578124997],
                        [33.87148437500002, 9.506152343749989],
                        [34.078125, 9.461523437499991],
                    ],
                ],
            },
            properties: {
                name: "达尔富尔",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-12.280615234374977, 14.809033203124997],
                        [-12.228417968749994, 14.45859375],
                        [-12.019189453124994, 14.206494140624997],
                        [-11.960888671874983, 13.875292968750003],
                        [-12.05419921875, 13.633056640625],
                        [-11.831689453124994, 13.315820312499994],
                        [-11.634960937499983, 13.369873046875],
                        [-11.390380859375, 12.941992187499991],
                        [-11.389404296875, 12.404394531249991],
                        [-12.399072265624994, 12.340087890625],
                        [-12.930712890624989, 12.532275390624989],
                        [-13.061279296875, 12.489990234375],
                        [-13.082910156249994, 12.633544921875],
                        [-13.729248046875, 12.673925781249991],
                        [-14.06484375, 12.67529296875],
                        [-14.349218749999977, 12.676416015624994],
                        [-15.196093749999989, 12.679931640625],
                        [-15.3779296875, 12.588964843749991],
                        [-15.574804687499977, 12.490380859374994],
                        [-15.839550781249983, 12.43789062499999],
                        [-16.144189453124994, 12.45742187499999],
                        [-16.24150390624999, 12.443310546874997],
                        [-16.41630859374999, 12.36767578125],
                        [-16.521337890624977, 12.3486328125],
                        [-16.656933593749983, 12.364355468749991],
                        [-16.711816406249994, 12.354833984374991],
                        [-16.76030273437499, 12.52578125],
                        [-16.44287109375, 12.609472656249991],
                        [-16.59765625, 12.715283203124997],
                        [-16.743896484375, 12.58544921875],
                        [-16.763330078124994, 13.064160156249997],
                        [-16.648779296874977, 13.154150390624991],
                        [-15.834277343749989, 13.156445312499997],
                        [-15.814404296874983, 13.325146484374997],
                        [-15.286230468749977, 13.39599609375],
                        [-15.151123046875, 13.556494140624991],
                        [-14.246777343749983, 13.23583984375],
                        [-13.826708984374989, 13.4078125],
                        [-13.977392578124977, 13.54345703125],
                        [-14.405468749999983, 13.503710937500003],
                        [-15.108349609374983, 13.81210937499999],
                        [-15.426855468749977, 13.727001953124997],
                        [-15.509667968749994, 13.586230468750003],
                        [-16.56230468749999, 13.587304687499994],
                        [-16.766943359374977, 13.904931640624994],
                        [-16.618115234374983, 14.04052734375],
                        [-16.791748046875, 14.004150390625],
                        [-17.168066406249977, 14.640625],
                        [-17.345800781249977, 14.729296875],
                        [-17.445019531249983, 14.651611328125],
                        [-17.53564453125, 14.755126953125],
                        [-17.147167968749983, 14.922021484374994],
                        [-16.843408203124994, 15.293994140625003],
                        [-16.570751953124983, 15.734423828125003],
                        [-16.535253906249977, 15.83837890625],
                        [-16.502050781249977, 15.917333984374991],
                        [-16.480078124999977, 16.097216796875003],
                        [-16.441015624999977, 16.204541015624997],
                        [-16.239013671875, 16.531298828125003],
                        [-15.768212890624994, 16.485107421875],
                        [-14.990625, 16.676904296874994],
                        [-14.300097656249989, 16.580273437499997],
                        [-13.868457031249989, 16.148144531249997],
                        [-13.756640624999989, 16.172509765624994],
                        [-13.40966796875, 16.05917968749999],
                        [-13.105273437499989, 15.57177734375],
                        [-12.735253906249994, 15.13125],
                        [-12.40869140625, 14.889013671874991],
                        [-12.280615234374977, 14.809033203124997],
                    ],
                ],
            },
            properties: {
                name: "塞内加尔",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [103.9697265625, 1.331445312499994],
                        [103.65019531249999, 1.325537109374991],
                        [103.81796875000003, 1.447070312499989],
                        [103.9697265625, 1.331445312499994],
                    ],
                ],
            },
            properties: {
                name: "新加坡",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-37.10332031249996, -54.065625],
                        [-36.70380859375001, -54.10810546874999],
                        [-36.64741210937498, -54.26230468749996],
                        [-36.32646484374996, -54.251171875],
                        [-35.79858398437497, -54.76347656250002],
                        [-36.08549804687499, -54.86679687500001],
                        [-36.885986328125, -54.33945312499996],
                        [-37.63090820312496, -54.16748046875001],
                        [-37.61884765625001, -54.04208984375004],
                        [-38.017431640625034, -54.008007812500026],
                        [-37.10332031249996, -54.065625],
                    ],
                ],
            },
            properties: {
                name: "南乔治亚岛和南桑威奇群岛",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-5.692138671874972, -15.997753906249997],
                        [-5.782519531250017, -16.00400390625002],
                        [-5.707861328124977, -15.90615234374998],
                        [-5.692138671874972, -15.997753906249997],
                    ],
                ],
            },
            properties: {
                name: "圣赫勒拿",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [160.57626953125006, -11.797851562500028],
                            [160.44306640625004, -11.814941406249957],
                            [159.98632812499997, -11.494726562500006],
                            [160.57626953125006, -11.797851562500028],
                        ],
                    ],
                    [
                        [
                            [166.13320312500005, -10.757812499999972],
                            [165.90400390625004, -10.851464843749966],
                            [165.79101562500003, -10.784765624999963],
                            [166.02382812500005, -10.6611328125],
                            [166.13320312500005, -10.757812499999972],
                        ],
                    ],
                    [
                        [
                            [161.71533203124997, -10.387304687499991],
                            [162.10537109375005, -10.45380859375004],
                            [162.37333984375002, -10.823242187499986],
                            [161.78681640625004, -10.716894531249991],
                            [161.53789062500007, -10.566406249999972],
                            [161.4870117187501, -10.361425781249963],
                            [161.29394531250003, -10.326464843750031],
                            [161.30478515625012, -10.204394531250031],
                            [161.71533203124997, -10.387304687499991],
                        ],
                    ],
                    [
                        [
                            [161.54785156249997, -9.625683593749997],
                            [161.55380859375012, -9.769726562500026],
                            [161.40976562500006, -9.681640625000028],
                            [161.36416015625, -9.353417968750037],
                            [161.54785156249997, -9.625683593749997],
                        ],
                    ],
                    [
                        [
                            [159.75039062500005, -9.272656250000011],
                            [159.97060546875, -9.433300781249969],
                            [160.35458984375006, -9.421582031249983],
                            [160.81894531250006, -9.862792968749986],
                            [160.64921875000002, -9.92861328124998],
                            [159.80273437499997, -9.763476562500003],
                            [159.61230468749997, -9.470703124999943],
                            [159.62558593750012, -9.311230468749969],
                            [159.75039062500005, -9.272656250000011],
                        ],
                    ],
                    [
                        [
                            [160.1681640625001, -8.995507812500037],
                            [160.40751953125007, -9.140332031249969],
                            [160.10537109375, -9.080761718749997],
                            [160.1681640625001, -8.995507812500037],
                        ],
                    ],
                    [
                        [
                            [159.18857421875006, -9.123535156250014],
                            [159.03632812500004, -9.075],
                            [159.12978515625, -8.99306640624998],
                            [159.22841796875005, -9.029980468749955],
                            [159.18857421875006, -9.123535156250014],
                        ],
                    ],
                    [
                        [
                            [158.10791015625003, -8.684179687500034],
                            [157.93759765625006, -8.73642578125002],
                            [157.90927734375006, -8.565625],
                            [158.10546874999997, -8.536816406250026],
                            [158.10791015625003, -8.684179687500034],
                        ],
                    ],
                    [
                        [
                            [157.38896484375002, -8.713476562499963],
                            [157.2123046875, -8.565039062500006],
                            [157.37949218750012, -8.420898437499943],
                            [157.38896484375002, -8.713476562499963],
                        ],
                    ],
                    [
                        [
                            [160.7494140625, -8.313964843750014],
                            [160.99765625000006, -8.612011718749983],
                            [160.94433593750003, -8.799023437499983],
                            [161.15869140624997, -8.961816406250009],
                            [161.36738281250004, -9.61123046874998],
                            [160.77207031250012, -8.963867187499986],
                            [160.7140625000001, -8.539257812499997],
                            [160.59042968750006, -8.372753906249997],
                            [160.7494140625, -8.313964843750014],
                        ],
                    ],
                    [
                        [
                            [157.76347656250002, -8.242187499999957],
                            [157.89843749999997, -8.506347656249943],
                            [157.81933593750003, -8.612011718749983],
                            [157.58789062500003, -8.445410156249963],
                            [157.5580078125, -8.269921875],
                            [157.30244140625004, -8.33330078124996],
                            [157.21757812500002, -8.262792968749977],
                            [157.490625, -7.965722656250037],
                            [157.76347656250002, -8.242187499999957],
                        ],
                    ],
                    [
                        [
                            [157.171875, -8.108105468749997],
                            [156.95830078125002, -8.014355468749997],
                            [157.02412109375004, -7.867871093749997],
                            [157.18613281250006, -7.941210937500017],
                            [157.171875, -8.108105468749997],
                        ],
                    ],
                    [
                        [
                            [156.687890625, -7.92304687500004],
                            [156.5109375000001, -7.707812499999974],
                            [156.5609375, -7.574023437499989],
                            [156.80908203124997, -7.722851562500026],
                            [156.687890625, -7.92304687500004],
                        ],
                    ],
                    [
                        [
                            [159.8791015625001, -8.534277343749949],
                            [158.9440429687501, -8.04072265625004],
                            [158.457421875, -7.544726562499974],
                            [158.734375, -7.604296875000031],
                            [159.43144531250002, -8.029003906249955],
                            [159.84306640625002, -8.326953124999989],
                            [159.8791015625001, -8.534277343749949],
                        ],
                    ],
                    [
                        [
                            [155.83984374999997, -7.097167968750014],
                            [155.67753906250002, -7.08896484375002],
                            [155.73896484375004, -6.972949218750017],
                            [155.83984374999997, -7.097167968750014],
                        ],
                    ],
                    [
                        [
                            [157.48671875000005, -7.330371093750003],
                            [157.44130859375, -7.425683593749966],
                            [157.10156249999997, -7.323632812499966],
                            [156.4525390625, -6.638281249999963],
                            [157.03027343750003, -6.891992187499952],
                            [157.19335937499997, -7.160351562499997],
                            [157.48671875000005, -7.330371093750003],
                        ],
                    ],
                ],
            },
            properties: {
                name: "所罗门群岛",
                childNum: 16,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-12.526074218749926, 7.436328125000017],
                            [-12.951611328124926, 7.570849609374989],
                            [-12.615234374999972, 7.63720703125],
                            [-12.5125, 7.582421875000037],
                            [-12.526074218749926, 7.436328125000017],
                        ],
                    ],
                    [
                        [
                            [-10.758593749999989, 9.385351562499991],
                            [-10.682714843750006, 9.289355468749974],
                            [-10.687646484374937, 9.261132812499994],
                            [-10.749951171874926, 9.12236328124996],
                            [-10.747021484374955, 9.095263671875045],
                            [-10.726855468749932, 9.081689453125023],
                            [-10.615966796875, 9.059179687499977],
                            [-10.500537109375017, 8.687548828125017],
                            [-10.677343749999977, 8.400585937499997],
                            [-10.712109374999955, 8.335253906250017],
                            [-10.686962890624983, 8.321679687500009],
                            [-10.652636718749989, 8.330273437499983],
                            [-10.604003906249943, 8.319482421874994],
                            [-10.55771484374992, 8.315673828125028],
                            [-10.496435546874977, 8.362109374999974],
                            [-10.394433593749966, 8.480957031250028],
                            [-10.360058593749983, 8.49550781249998],
                            [-10.283203124999972, 8.48515625],
                            [-10.285742187499949, 8.454101562499986],
                            [-10.314648437499983, 8.310839843750017],
                            [-10.359814453124926, 8.187939453125026],
                            [-10.570849609374932, 8.071142578125034],
                            [-10.6474609375, 7.759375],
                            [-10.878076171874994, 7.538232421874994],
                            [-11.267675781249977, 7.232617187499997],
                            [-11.507519531249983, 6.906542968750003],
                            [-12.48564453124996, 7.386279296875045],
                            [-12.480273437499932, 7.75327148437502],
                            [-12.697607421874977, 7.715869140625045],
                            [-12.850878906249932, 7.818701171875034],
                            [-12.956933593749966, 8.145312500000045],
                            [-13.148974609374989, 8.214599609375043],
                            [-13.272753906249989, 8.429736328124989],
                            [-13.085009765624932, 8.42475585937504],
                            [-12.894091796874932, 8.62978515624998],
                            [-13.181835937499955, 8.576904296875043],
                            [-13.206933593749994, 8.843115234375006],
                            [-13.059472656249966, 8.881152343750031],
                            [-13.292675781249955, 9.04921875],
                            [-13.077294921874966, 9.069628906249974],
                            [-12.958789062499989, 9.263330078124994],
                            [-12.755859374999943, 9.373583984374989],
                            [-12.557861328125, 9.704980468749994],
                            [-12.427978515625028, 9.898144531250011],
                            [-12.142333984375, 9.87539062499999],
                            [-11.911083984374955, 9.993017578124977],
                            [-11.273632812499955, 9.996533203124983],
                            [-11.205664062499949, 9.977734374999969],
                            [-11.180859374999955, 9.925341796875045],
                            [-11.047460937499977, 9.786328125000054],
                            [-10.758593749999989, 9.385351562499991],
                        ],
                    ],
                ],
            },
            properties: {
                name: "塞拉利昂",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-89.36259765624999, 14.416015625],
                        [-89.1205078125, 14.370214843749991],
                        [-88.51254882812499, 13.978955078124997],
                        [-88.504345703125, 13.964208984374991],
                        [-88.49765625, 13.904541015625],
                        [-88.482666015625, 13.854248046875],
                        [-88.44912109375, 13.850976562499994],
                        [-88.40849609374999, 13.87539062499999],
                        [-88.27622070312499, 13.942675781250003],
                        [-88.151025390625, 13.987353515625003],
                        [-87.99101562499999, 13.879638671875],
                        [-87.8919921875, 13.894970703124997],
                        [-87.80224609375, 13.889990234374991],
                        [-87.7314453125, 13.841064453125],
                        [-87.71533203125, 13.812695312499997],
                        [-87.781884765625, 13.521386718749994],
                        [-87.930859375, 13.1806640625],
                        [-88.68564453124999, 13.281494140625],
                        [-88.51201171874999, 13.183935546874991],
                        [-89.80419921875, 13.560107421875003],
                        [-90.09521484375, 13.736523437499997],
                        [-90.04814453124999, 13.904052734375],
                        [-89.54716796874999, 14.241259765625003],
                        [-89.5736328125, 14.390087890624997],
                        [-89.36259765624999, 14.416015625],
                    ],
                ],
            },
            properties: {
                name: "萨尔瓦多",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-56.26708984374997, 46.838476562500034],
                        [-56.38476562499994, 46.81943359375006],
                        [-56.36464843749994, 47.09897460937498],
                        [-56.26708984374997, 46.838476562500034],
                    ],
                ],
            },
            properties: {
                name: "圣皮埃尔和米克隆群岛",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [6.659960937499989, 0.120654296874989],
                            [6.51972656250004, 0.066308593750023],
                            [6.468164062499994, 0.22734375],
                            [6.68691406250008, 0.404394531249977],
                            [6.75, 0.24345703124996],
                            [6.659960937499989, 0.120654296874989],
                        ],
                    ],
                    [
                        [
                            [7.423828125, 1.567724609375006],
                            [7.330664062500034, 1.603369140624991],
                            [7.414453125000051, 1.699121093750037],
                            [7.423828125, 1.567724609375006],
                        ],
                    ],
                ],
            },
            properties: {
                name: "圣多美和普林西比",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-54.03422851562499, 3.62939453125],
                        [-54.00957031249999, 3.448535156249989],
                        [-54.06318359375, 3.353320312499989],
                        [-54.18803710937499, 3.178759765624989],
                        [-54.203125, 3.13818359375],
                        [-54.17070312499999, 2.993603515624997],
                        [-54.18808593749999, 2.874853515624991],
                        [-54.1955078125, 2.81787109375],
                        [-54.256738281249994, 2.713720703124991],
                        [-54.402001953124994, 2.461523437499991],
                        [-54.53593749999999, 2.343310546874989],
                        [-54.56840820312499, 2.342578124999989],
                        [-54.604736328125, 2.335791015624991],
                        [-54.61625976562499, 2.326757812499991],
                        [-54.661865234375, 2.327539062499994],
                        [-54.697412109374994, 2.359814453124997],
                        [-54.72221679687499, 2.441650390625],
                        [-54.87607421874999, 2.450390625],
                        [-54.92656249999999, 2.497363281249989],
                        [-54.968408203124994, 2.54833984375],
                        [-54.978662109374994, 2.59765625],
                        [-55.005810546875, 2.59296875],
                        [-55.0703125, 2.54833984375],
                        [-55.11411132812499, 2.539208984374994],
                        [-55.1876953125, 2.547509765624994],
                        [-55.286035156249994, 2.499658203124994],
                        [-55.343994140625, 2.48876953125],
                        [-55.38535156249999, 2.440625],
                        [-55.73056640624999, 2.406152343749994],
                        [-55.957470703125, 2.520458984374997],
                        [-55.99350585937499, 2.497509765624997],
                        [-56.02036132812499, 2.392773437499997],
                        [-56.0451171875, 2.364404296874994],
                        [-56.087792968749994, 2.34130859375],
                        [-56.12939453125, 2.299511718749997],
                        [-56.1376953125, 2.259033203125],
                        [-56.073632812499994, 2.236767578124997],
                        [-56.02006835937499, 2.158154296874997],
                        [-55.96196289062499, 2.095117187499994],
                        [-55.91533203124999, 2.03955078125],
                        [-55.921630859375, 1.976660156249991],
                        [-55.929638671875, 1.8875],
                        [-56.01992187499999, 1.842236328124997],
                        [-56.4828125, 1.942138671875],
                        [-56.704345703125, 2.036474609374991],
                        [-57.19736328124999, 2.853271484375],
                        [-57.303662109375, 3.377099609374994],
                        [-57.646728515625, 3.39453125],
                        [-58.05429687499999, 4.101660156249991],
                        [-57.84599609374999, 4.668164062499997],
                        [-57.91704101562499, 4.820410156249991],
                        [-57.711083984374994, 4.991064453124991],
                        [-57.331005859375, 5.020166015624994],
                        [-57.20981445312499, 5.195410156249991],
                        [-57.3185546875, 5.335351562499994],
                        [-57.194775390625, 5.5484375],
                        [-56.96982421874999, 5.992871093749997],
                        [-56.235595703125, 5.885351562499991],
                        [-55.897607421874994, 5.699316406249991],
                        [-55.909912109375, 5.892626953124989],
                        [-55.648339843749994, 5.985888671874989],
                        [-54.83369140625, 5.988330078124989],
                        [-54.05419921875, 5.807910156249989],
                        [-54.08046875, 5.502246093749989],
                        [-54.4796875, 4.836523437499991],
                        [-54.350732421874994, 4.054101562499994],
                        [-54.03422851562499, 3.62939453125],
                    ],
                ],
            },
            properties: {
                name: "苏里南",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [22.538671875, 49.072705078125],
                        [22.52412109375001, 49.031396484374994],
                        [22.389453125000017, 48.873486328125],
                        [22.295214843750017, 48.685839843749996],
                        [22.142871093750017, 48.568505859374994],
                        [22.1318359375, 48.405322265624996],
                        [21.766992187500023, 48.3380859375],
                        [21.45136718750001, 48.55224609375],
                        [20.490039062500017, 48.526904296874996],
                        [20.333789062500017, 48.295556640624994],
                        [19.95039062500001, 48.146630859374994],
                        [19.625390625000023, 48.223095703125],
                        [18.791894531250023, 48.000292968749996],
                        [18.72421875, 47.787158203124996],
                        [17.76191406250001, 47.770166015624994],
                        [17.147363281250023, 48.00595703125],
                        [16.86542968750001, 48.3869140625],
                        [16.953125, 48.598828125],
                        [17.135644531250023, 48.841064453125],
                        [17.75849609375001, 48.888134765625],
                        [18.0859375, 49.06513671875],
                        [18.160937500000017, 49.257373046874996],
                        [18.83222656250001, 49.510791015624996],
                        [19.1494140625, 49.4],
                        [19.44160156250001, 49.597705078124996],
                        [19.77392578125, 49.37216796875],
                        [19.756640625000017, 49.204394531249996],
                        [20.0576171875, 49.181298828124994],
                        [20.36298828125001, 49.38525390625],
                        [20.868457031250017, 49.314697265625],
                        [21.079394531250017, 49.418261718749996],
                        [21.6396484375, 49.411962890625],
                        [22.020117187500006, 49.209521484374996],
                        [22.473046875000023, 49.081298828125],
                        [22.538671875, 49.072705078125],
                    ],
                ],
            },
            properties: {
                name: "斯洛伐克",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [16.516210937500006, 46.499902343749994],
                        [16.427636718750023, 46.5244140625],
                        [16.321191406250023, 46.534619140625],
                        [16.1064453125, 46.382226562499994],
                        [15.608984375, 46.171923828124996],
                        [15.592578125000017, 46.139990234375],
                        [15.596875, 46.109228515625],
                        [15.675585937500017, 45.983691406249996],
                        [15.652148437500017, 45.862158203125],
                        [15.277050781250011, 45.7326171875],
                        [15.353710937500011, 45.659912109375],
                        [15.283593750000023, 45.5796875],
                        [15.291210937500011, 45.541552734374996],
                        [15.32666015625, 45.502294921875],
                        [15.339453125, 45.467041015625],
                        [15.242089843750023, 45.44140625],
                        [15.110449218750006, 45.45078125],
                        [14.95458984375, 45.499902343749994],
                        [14.793066406250006, 45.47822265625],
                        [14.649511718750006, 45.571484375],
                        [14.591796875, 45.651269531249994],
                        [14.56884765625, 45.6572265625],
                        [14.548448660714302, 45.628388671875],
                        [14.507586495535731, 45.59039341517857],
                        [14.42734375, 45.505761718749994],
                        [14.369921875000017, 45.4814453125],
                        [13.878710937500017, 45.428369140624994],
                        [13.577929687500017, 45.516894531249996],
                        [13.8447265625, 45.59287109375],
                        [13.831152343750006, 45.680419921875],
                        [13.663476562500023, 45.7919921875],
                        [13.6005859375, 45.979785156249996],
                        [13.509179687500023, 45.973779296874994],
                        [13.487695312500023, 45.987109375],
                        [13.480273437500017, 46.009228515625],
                        [13.486425781250006, 46.03955078125],
                        [13.548046875000011, 46.089111328125],
                        [13.616601562500023, 46.133105468749996],
                        [13.634960937500011, 46.157763671874996],
                        [13.632519531250011, 46.177050781249996],
                        [13.420996093750006, 46.212304687499994],
                        [13.399511718750006, 46.317529296874994],
                        [13.563281250000017, 46.415087890624996],
                        [13.637109375000023, 46.448535156249996],
                        [13.6796875, 46.462890625],
                        [13.7, 46.520263671875],
                        [14.5498046875, 46.399707031249996],
                        [14.893261718750011, 46.605908203125],
                        [15.957617187500006, 46.677636718749994],
                        [16.093066406250017, 46.86328125],
                        [16.283593750000023, 46.857275390625],
                        [16.516210937500006, 46.499902343749994],
                    ],
                ],
            },
            properties: {
                name: "斯洛文尼亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [16.52851562500001, 56.29052734375],
                            [16.431640625, 56.24375],
                            [16.41230468750004, 56.568994140624994],
                            [17.02539062499997, 57.345068359375006],
                            [16.52851562500001, 56.29052734375],
                        ],
                    ],
                    [
                        [
                            [19.076464843750045, 57.8359375],
                            [18.813867187500023, 57.70620117187502],
                            [18.907910156250068, 57.39833984375002],
                            [18.146386718749994, 56.920507812500006],
                            [18.285351562500068, 57.08320312500001],
                            [18.136523437500045, 57.55664062500003],
                            [18.53740234374999, 57.83056640625006],
                            [18.90058593750001, 57.91547851562504],
                            [19.076464843750045, 57.8359375],
                        ],
                    ],
                    [
                        [
                            [19.156347656250063, 57.92260742187497],
                            [19.086523437500034, 57.86499023437506],
                            [19.134863281250034, 57.98134765625002],
                            [19.331445312500023, 57.962890625],
                            [19.156347656250063, 57.92260742187497],
                        ],
                    ],
                    [
                        [
                            [24.15546875000004, 65.80527343750006],
                            [23.102343750000074, 65.73535156250003],
                            [22.400976562500006, 65.86210937499999],
                            [22.254003906250006, 65.59755859375002],
                            [21.565527343750063, 65.40810546874997],
                            [21.609179687500074, 65.261376953125],
                            [21.410351562500068, 65.31743164062505],
                            [21.57392578125001, 65.12578124999999],
                            [21.138183593750057, 64.80869140625006],
                            [21.519628906250034, 64.46308593749998],
                            [20.76269531250003, 63.86782226562505],
                            [18.60644531250003, 63.17827148437499],
                            [18.31289062500008, 62.996386718750045],
                            [18.46308593750004, 62.895849609375006],
                            [18.170019531250034, 62.789355468750074],
                            [17.906640625000023, 62.88676757812502],
                            [18.037304687500068, 62.60053710937498],
                            [17.834472656250057, 62.50273437500002],
                            [17.410253906250063, 62.508398437500034],
                            [17.633691406249994, 62.23300781250006],
                            [17.374511718750057, 61.866308593750034],
                            [17.465429687500006, 61.68447265625005],
                            [17.196386718750006, 61.72456054687504],
                            [17.13076171875005, 61.57573242187499],
                            [17.25097656250003, 60.70078125],
                            [17.6611328125, 60.53515625000003],
                            [17.955761718750068, 60.589794921874955],
                            [18.85273437500001, 60.02587890625],
                            [18.970507812500045, 59.757226562499994],
                            [17.964257812500023, 59.359375],
                            [18.56025390625004, 59.39448242187498],
                            [18.285351562500068, 59.109375],
                            [16.978125, 58.65415039062506],
                            [16.214257812500023, 58.636669921874955],
                            [16.92382812499997, 58.49257812499999],
                            [16.651953125, 58.43432617187503],
                            [16.65224609375008, 57.50068359374998],
                            [16.348730468750063, 56.70927734374996],
                            [15.826660156250028, 56.12495117187501],
                            [14.782031250000017, 56.16191406250002],
                            [14.754785156250051, 56.03315429687498],
                            [14.401953125000034, 55.97675781250004],
                            [14.21503906250004, 55.83261718749998],
                            [14.341699218749994, 55.52773437500002],
                            [14.17373046875008, 55.396630859374966],
                            [12.885839843750063, 55.41137695312506],
                            [12.973925781250074, 55.748144531250006],
                            [12.471191406250057, 56.29052734375],
                            [12.801660156250051, 56.263916015625],
                            [12.65644531250004, 56.44057617187502],
                            [12.857421875000028, 56.45239257812503],
                            [12.883691406250051, 56.61772460937496],
                            [12.421484375000034, 56.906396484374966],
                            [11.449316406250063, 58.118359374999955],
                            [11.43154296875008, 58.339990234374994],
                            [11.24824218750004, 58.369140625],
                            [11.14716796875004, 58.98862304687498],
                            [11.19580078125, 59.07827148437505],
                            [11.388281250000063, 59.036523437499966],
                            [11.470703125000057, 58.909521484375034],
                            [11.64277343750004, 58.92607421875002],
                            [11.798144531250074, 59.28989257812498],
                            [11.680761718750034, 59.59228515625003],
                            [12.486132812500074, 60.10678710937506],
                            [12.588671874999989, 60.450732421875045],
                            [12.29414062500004, 61.00268554687506],
                            [12.706054687500028, 61.059863281250074],
                            [12.88076171875008, 61.35229492187506],
                            [12.155371093750006, 61.720751953125045],
                            [12.303515625000074, 62.28559570312501],
                            [11.999902343750051, 63.29169921875001],
                            [12.175195312500051, 63.595947265625],
                            [12.792773437500017, 64],
                            [13.203515625000023, 64.07509765625],
                            [13.960546875000063, 64.01401367187498],
                            [14.141210937500006, 64.17353515624998],
                            [14.077636718750028, 64.464013671875],
                            [13.650292968750023, 64.58154296874997],
                            [14.47968750000004, 65.30146484374998],
                            [14.543261718750045, 66.12934570312498],
                            [15.483789062500051, 66.30595703124999],
                            [15.422949218750006, 66.48984374999998],
                            [16.40351562500004, 67.05498046875002],
                            [16.12744140625, 67.42583007812507],
                            [16.783593750000023, 67.89501953125],
                            [17.324609375000023, 68.10380859374999],
                            [17.91669921875001, 67.96489257812502],
                            [18.303027343750045, 68.55541992187497],
                            [19.969824218750063, 68.35639648437501],
                            [20.348046875000023, 68.84873046875003],
                            [20.116699218750057, 69.02089843750005],
                            [20.622167968750006, 69.036865234375],
                            [21.99746093750005, 68.52060546874998],
                            [22.854101562500034, 68.36733398437502],
                            [23.63886718750004, 67.95439453125002],
                            [23.454882812500045, 67.46025390625007],
                            [23.733593750000068, 67.42290039062499],
                            [23.64150390625005, 67.12939453124997],
                            [23.988574218750045, 66.81054687500003],
                            [23.700292968750034, 66.25263671874998],
                            [24.15546875000004, 65.80527343750006],
                        ],
                    ],
                ],
            },
            properties: {
                name: "瑞典",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [31.9482421875, -25.957617187500006],
                        [32.060546875, -26.018359375],
                        [32.04140625000002, -26.28125],
                        [32.10595703125, -26.52001953125],
                        [32.112890625, -26.839453125],
                        [32.02480468750002, -26.811132812500006],
                        [31.994726562500006, -26.817480468750006],
                        [31.967187500000023, -26.96064453125001],
                        [31.946093750000017, -27.173632812500003],
                        [31.958398437500023, -27.30585937500001],
                        [31.742578125000023, -27.30996093750001],
                        [31.469531250000017, -27.295507812500006],
                        [31.274023437500006, -27.238378906250006],
                        [31.063378906250023, -27.1123046875],
                        [30.938085937500006, -26.915820312500003],
                        [30.88330078125, -26.79238281250001],
                        [30.806738281250006, -26.785253906250006],
                        [30.794335937500023, -26.764257812500006],
                        [30.803320312500006, -26.41347656250001],
                        [31.08808593750001, -25.98066406250001],
                        [31.207324218750017, -25.843359375],
                        [31.33515625000001, -25.75556640625001],
                        [31.382617187500017, -25.74296875],
                        [31.415136718750006, -25.74658203125],
                        [31.921679687500017, -25.96875],
                        [31.9482421875, -25.957617187500006],
                    ],
                ],
            },
            properties: {
                name: "斯威士兰",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [55.54033203125002, -4.693066406250011],
                        [55.54296875, -4.785546875],
                        [55.383398437500006, -4.609277343750009],
                        [55.45576171875001, -4.558789062500011],
                        [55.54033203125002, -4.693066406250011],
                    ],
                ],
            },
            properties: {
                name: "塞舌尔",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [42.358984375, 37.10859375],
                        [41.78857421875, 36.59716796875],
                        [41.41679687500002, 36.5146484375],
                        [41.295996093750006, 36.383349609374996],
                        [41.354101562500006, 35.640429687499996],
                        [41.19472656250002, 34.768994140625],
                        [40.98701171875001, 34.429052734375],
                        [38.773535156250006, 33.372216796874994],
                        [36.818359375, 32.317285156249994],
                        [36.3720703125, 32.3869140625],
                        [35.78730468750001, 32.734912109374996],
                        [35.91347656250002, 32.94960937499999],
                        [35.869140625, 33.43173828125],
                        [36.03447265625002, 33.58505859375],
                        [35.98613281250002, 33.75263671875],
                        [36.36503906250002, 33.83935546875],
                        [36.27783203125, 33.92529296875],
                        [36.5849609375, 34.221240234374996],
                        [36.50439453125, 34.432373046875],
                        [36.32988281250002, 34.499609375],
                        [36.383886718750006, 34.65791015625],
                        [35.97626953125001, 34.629199218749996],
                        [35.902441406250006, 35.420703125],
                        [35.76445312500002, 35.571582031249996],
                        [35.83964843750002, 35.84921875],
                        [35.892675781250006, 35.916552734374996],
                        [35.96757812500002, 35.910058593749994],
                        [36.12734375000002, 35.831445312499994],
                        [36.15361328125002, 35.833886718749994],
                        [36.34755859375002, 36.003515625],
                        [36.37539062500002, 36.171240234375],
                        [36.63671875, 36.233984375],
                        [36.64140625000002, 36.263525390625],
                        [36.5375, 36.45742187499999],
                        [36.54667968750002, 36.50634765625],
                        [36.596875, 36.7013671875],
                        [36.62841796875, 36.777685546875],
                        [36.65859375000002, 36.802539062499996],
                        [36.77656250000001, 36.79267578125],
                        [36.94179687500002, 36.7583984375],
                        [36.9853515625, 36.702392578125],
                        [37.06621093750002, 36.652636718749996],
                        [37.43632812500002, 36.643310546875],
                        [37.523535156250006, 36.6783203125],
                        [37.7203125, 36.743701171874996],
                        [37.90664062500002, 36.79462890625],
                        [38.19169921875002, 36.9015625],
                        [38.7666015625, 36.693115234375],
                        [38.90644531250001, 36.694677734375],
                        [39.1083984375, 36.680566406249994],
                        [39.35664062500001, 36.681591796875],
                        [39.50146484375, 36.70224609375],
                        [39.6865234375, 36.738623046875],
                        [40.01640625000002, 36.826074218749994],
                        [40.705664062500006, 37.097705078124996],
                        [41.886816406250006, 37.156396484374994],
                        [42.05986328125002, 37.2060546875],
                        [42.16787109375002, 37.288623046874996],
                        [42.202734375, 37.29726562499999],
                        [42.24755859375, 37.2822265625],
                        [42.2685546875, 37.2765625],
                        [42.31289062500002, 37.22958984375],
                        [42.358984375, 37.10859375],
                    ],
                ],
            },
            properties: {
                name: "叙利亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-72.3328125, 21.85136718749999],
                        [-72.14433593750002, 21.79272460937503],
                        [-72.33544921874994, 21.758007812499983],
                        [-72.3328125, 21.85136718749999],
                    ],
                ],
            },
            properties: {
                name: "特克斯和凯克特斯群岛",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [23.980273437500017, 19.496630859375003],
                        [23.970800781250006, 15.721533203124991],
                        [23.10517578125001, 15.702539062499994],
                        [22.933886718750017, 15.533105468749994],
                        [22.93232421875001, 15.162109375],
                        [22.6708984375, 14.722460937500003],
                        [22.38154296875001, 14.550488281249997],
                        [22.53857421875, 14.161865234375],
                        [22.1064453125, 13.7998046875],
                        [22.228125, 13.32958984375],
                        [21.825292968750006, 12.79052734375],
                        [21.928125, 12.678125],
                        [22.352343750000017, 12.660449218749989],
                        [22.472460937500017, 12.067773437499994],
                        [22.580957031250023, 11.990136718749994],
                        [22.591113281250017, 11.579882812499989],
                        [22.922656250000017, 11.344873046874994],
                        [22.86005859375001, 10.919677734375],
                        [22.49384765625001, 10.996240234374994],
                        [21.771484375, 10.642822265625],
                        [21.682714843750006, 10.289843749999989],
                        [20.773242187500017, 9.405664062499994],
                        [20.342089843750017, 9.127099609374994],
                        [18.95625, 8.938867187499994],
                        [18.886035156250017, 8.836035156249991],
                        [19.108691406250017, 8.656152343749994],
                        [18.56416015625001, 8.0458984375],
                        [17.6494140625, 7.98359375],
                        [16.784765625, 7.550976562499997],
                        [16.545312500000023, 7.865478515625],
                        [16.37890625, 7.683544921874997],
                        [15.957617187500006, 7.507568359375],
                        [15.480078125, 7.523779296874991],
                        [15.5498046875, 7.787890624999989],
                        [15.1162109375, 8.557324218749997],
                        [14.332324218750017, 9.20351562499999],
                        [13.977246093750011, 9.691552734374994],
                        [14.243261718750006, 9.979736328125],
                        [15.654882812500006, 10.0078125],
                        [15.276074218750011, 10.357373046874997],
                        [15.132226562500023, 10.648486328124989],
                        [15.029882812500006, 11.11367187499999],
                        [15.08125, 11.845507812499989],
                        [14.847070312500023, 12.502099609374994],
                        [14.461718750000017, 13.021777343749989],
                        [14.244824218750011, 13.07734375],
                        [14.06396484375, 13.07851562499999],
                        [13.932324218750011, 13.258496093749997],
                        [13.606347656250023, 13.70458984375],
                        [13.505761718750023, 14.134423828124994],
                        [13.4482421875, 14.380664062500003],
                        [14.367968750000017, 15.750146484374994],
                        [15.474316406250011, 16.908398437499997],
                        [15.735058593750011, 19.904052734375],
                        [15.963183593750017, 20.34619140625],
                        [15.587109375000011, 20.733300781249994],
                        [15.607324218750023, 20.954394531250003],
                        [15.181835937500011, 21.523388671874997],
                        [14.97900390625, 22.99619140624999],
                        [15.984082031250011, 23.445214843749994],
                        [20.14765625000001, 21.38925781249999],
                        [23.980273437500017, 19.496630859375003],
                    ],
                ],
            },
            properties: {
                name: "乍得",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [0.900488281250006, 10.993261718749991],
                        [0.763378906250011, 10.386669921874997],
                        [1.330078125, 9.996972656249994],
                        [1.3857421875, 9.361669921874991],
                        [1.600195312500006, 9.050048828125],
                        [1.624707031250011, 6.997314453125],
                        [1.530957031250011, 6.992431640625],
                        [1.777929687500006, 6.294628906249997],
                        [1.62265625, 6.216796875],
                        [1.187207031250011, 6.089404296874989],
                        [0.736914062500006, 6.452587890624997],
                        [0.525585937500011, 6.850927734374991],
                        [0.634765625, 7.353662109374994],
                        [0.5, 7.546875],
                        [0.686328125000017, 8.354882812499994],
                        [0.37255859375, 8.75927734375],
                        [0.48876953125, 8.851464843749994],
                        [0.525683593750017, 9.398486328124989],
                        [0.2333984375, 9.463525390624994],
                        [0.342578125000017, 9.604150390624994],
                        [0.264550781250023, 9.644726562499997],
                        [0.380859375, 10.291845703124991],
                        [-0.08632812499999, 10.673046875],
                        [0.009423828125023, 11.02099609375],
                        [-0.068603515625, 11.115625],
                        [0.49267578125, 10.954980468749994],
                        [0.900488281250006, 10.993261718749991],
                    ],
                ],
            },
            properties: {
                name: "多哥",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [98.40908203125005, 7.90205078125004],
                            [98.2962890625, 7.776074218750054],
                            [98.32207031250007, 8.166308593749974],
                            [98.4349609375, 8.085644531249969],
                            [98.40908203125005, 7.90205078125004],
                        ],
                    ],
                    [
                        [
                            [100.070703125, 9.58603515625002],
                            [99.96240234375003, 9.421630859375],
                            [99.93955078125006, 9.559960937500037],
                            [100.070703125, 9.58603515625002],
                        ],
                    ],
                    [
                        [
                            [102.42675781250003, 11.988720703125026],
                            [102.30195312500004, 11.98081054687502],
                            [102.27744140625006, 12.151855468750043],
                            [102.42675781250003, 11.988720703125026],
                        ],
                    ],
                    [
                        [
                            [100.12246093750005, 20.316650390625057],
                            [100.11494140625004, 20.257666015625034],
                            [100.13974609375012, 20.245410156250017],
                            [100.31796875000006, 20.38588867187505],
                            [100.51953125000003, 20.17792968750004],
                            [100.39765625000004, 19.756103515625],
                            [100.51357421875005, 19.553466796875],
                            [101.21191406249997, 19.54833984375003],
                            [101.22080078125006, 19.486621093750074],
                            [101.19755859375007, 19.327929687500074],
                            [101.2863281250001, 18.977148437500006],
                            [101.04697265625012, 18.441992187500063],
                            [101.05058593750002, 18.407031250000045],
                            [101.1375, 18.28686523437497],
                            [101.14394531250005, 18.14262695312499],
                            [100.90849609375002, 17.583886718750023],
                            [100.95585937500002, 17.541113281250006],
                            [101.10517578125004, 17.47954101562499],
                            [101.16748046874997, 17.49902343749997],
                            [101.41367187500012, 17.71875],
                            [101.55507812500005, 17.812353515625034],
                            [101.56367187500004, 17.82050781250001],
                            [101.6875, 17.889404296875],
                            [101.77480468750005, 18.03339843750004],
                            [101.81865234375002, 18.06464843750001],
                            [101.87548828124997, 18.046435546875017],
                            [101.94746093750004, 18.081494140624983],
                            [102.03457031250005, 18.169824218750023],
                            [102.10146484375, 18.210644531249983],
                            [102.14824218750002, 18.20385742187503],
                            [102.35185546875002, 18.045947265625017],
                            [102.45878906250002, 17.984619140625057],
                            [102.55253906250007, 17.96508789062497],
                            [102.61679687500006, 17.833349609375034],
                            [102.66064453124997, 17.817968750000034],
                            [102.680078125, 17.824121093750023],
                            [103.05136718750006, 18.02851562500001],
                            [103.0912109375, 18.13823242187499],
                            [103.14853515625006, 18.221728515625045],
                            [103.19970703124997, 18.259472656249983],
                            [103.26318359374997, 18.27846679687505],
                            [103.27958984375002, 18.304980468750017],
                            [103.24892578125, 18.338964843750034],
                            [103.25175781250002, 18.373486328124955],
                            [103.2882812500001, 18.408398437499955],
                            [103.36699218750007, 18.42333984374997],
                            [103.48798828125004, 18.418164062499983],
                            [103.62968750000002, 18.38256835937503],
                            [103.79228515625002, 18.316503906249977],
                            [103.89882812500005, 18.295312500000023],
                            [103.949609375, 18.31899414062505],
                            [104.04873046875005, 18.216699218749994],
                            [104.19619140625005, 17.988378906250006],
                            [104.32265625, 17.815820312500023],
                            [104.428125, 17.69897460937503],
                            [104.7396484375, 17.461669921875],
                            [104.81601562500012, 17.30029296874997],
                            [104.75898437500004, 17.0771484375],
                            [104.7435546875, 16.884375],
                            [104.75058593750012, 16.647558593750063],
                            [104.81933593750003, 16.46606445312503],
                            [105.04716796875007, 16.160253906249977],
                            [105.14873046875007, 16.09355468749999],
                            [105.33066406250006, 16.037890625000017],
                            [105.40625, 15.987451171875051],
                            [105.39892578124997, 15.829882812500017],
                            [105.62207031250003, 15.699951171875],
                            [105.641015625, 15.656542968750045],
                            [105.6388671875001, 15.585937500000057],
                            [105.615625, 15.488281250000057],
                            [105.49042968750004, 15.256591796875],
                            [105.49042968750004, 15.127587890625009],
                            [105.5333984375001, 15.041601562499991],
                            [105.54667968750002, 14.932470703124963],
                            [105.52304687500012, 14.843310546875003],
                            [105.49736328125002, 14.590673828124963],
                            [105.47558593750003, 14.530126953124977],
                            [105.42265625000007, 14.471630859375054],
                            [105.34218750000005, 14.416699218750054],
                            [105.24365234375003, 14.367871093750054],
                            [105.1833007812501, 14.346240234374989],
                            [105.16914062500004, 14.336083984374966],
                            [105.12597656250003, 14.280957031250011],
                            [105.07412109375005, 14.227441406250037],
                            [104.77900390625004, 14.427832031250006],
                            [103.19941406250004, 14.332617187499977],
                            [102.90927734375006, 14.136718750000028],
                            [102.546875, 13.585693359375043],
                            [102.33632812500005, 13.560302734375014],
                            [102.49960937500012, 12.669970703125003],
                            [102.75566406250002, 12.42626953125],
                            [102.73662109375007, 12.089794921875011],
                            [102.93388671875002, 11.706689453125037],
                            [102.594140625, 12.203027343749994],
                            [102.54023437500004, 12.109228515624977],
                            [101.83574218750002, 12.640380859375014],
                            [100.89775390625007, 12.653808593749986],
                            [100.96269531250007, 13.431982421874991],
                            [100.60292968750005, 13.568164062500017],
                            [100.23564453125002, 13.48447265625002],
                            [99.99052734375007, 13.243457031250031],
                            [100.08994140625006, 13.045654296874972],
                            [99.96396484375006, 12.690039062500006],
                            [99.98906250000007, 12.170800781249994],
                            [99.16503906250003, 10.319824218750028],
                            [99.25390625000003, 9.265234375000034],
                            [99.83554687500012, 9.288378906250031],
                            [99.98955078125007, 8.589208984374977],
                            [100.129296875, 8.428076171875006],
                            [100.16347656250005, 8.508398437500034],
                            [100.27939453125006, 8.268505859375011],
                            [100.54521484375002, 7.226904296874991],
                            [100.43935546875005, 7.280761718750043],
                            [100.38037109375003, 7.541503906250043],
                            [100.28378906250006, 7.551513671875043],
                            [100.25664062500002, 7.774902343749986],
                            [100.16074218750012, 7.599267578124994],
                            [100.4235351562501, 7.18784179687502],
                            [101.01787109375002, 6.860937500000034],
                            [101.49794921875005, 6.865283203125031],
                            [102.10107421874997, 6.242236328125031],
                            [101.87363281250012, 5.825292968749991],
                            [101.67841796875004, 5.778808593750028],
                            [101.5560546875, 5.907763671875003],
                            [101.1139648437501, 5.636767578125045],
                            [100.98164062500004, 5.771044921875045],
                            [101.05351562500002, 6.242578125],
                            [100.87392578125, 6.24541015624996],
                            [100.75449218750012, 6.460058593749991],
                            [100.3454101562501, 6.549902343750006],
                            [100.26142578125004, 6.682714843749963],
                            [100.11914062499997, 6.441992187500048],
                            [99.69599609375004, 6.87666015625004],
                            [99.72031250000012, 7.106201171875],
                            [99.55302734375002, 7.218798828125031],
                            [99.59697265625002, 7.355615234375009],
                            [99.35859375000004, 7.372216796875023],
                            [99.26367187499997, 7.619042968750037],
                            [99.07763671874997, 7.718066406250045],
                            [99.05107421875002, 7.887841796874994],
                            [98.78867187500012, 8.059814453125028],
                            [98.703515625, 8.256738281250009],
                            [98.57919921875006, 8.344287109374989],
                            [98.42099609375006, 8.17822265625],
                            [98.30546875000007, 8.226220703125009],
                            [98.24179687500006, 8.767871093750045],
                            [98.70253906250005, 10.19038085937504],
                            [98.7572265625, 10.660937499999974],
                            [99.1901367187501, 11.105273437499989],
                            [99.61474609374997, 11.781201171875026],
                            [99.40507812500002, 12.547900390625003],
                            [99.12392578125, 13.030761718750043],
                            [99.13681640625006, 13.716699218749994],
                            [98.57001953125004, 14.359912109375031],
                            [98.20214843749997, 14.97592773437502],
                            [98.19101562500012, 15.204101562499972],
                            [98.55693359375007, 15.367675781249986],
                            [98.59238281250006, 16.05068359375005],
                            [98.81796875000012, 16.180810546874994],
                            [98.88828125000006, 16.351904296875034],
                            [98.83544921875003, 16.417578125],
                            [98.66074218750006, 16.330419921875006],
                            [98.4388671875, 16.975683593750034],
                            [97.7064453125, 17.79711914062503],
                            [97.63222656250005, 18.290332031250074],
                            [97.37392578125, 18.51796875],
                            [97.74589843750002, 18.58818359374999],
                            [97.816796875, 19.459960937500057],
                            [98.01503906250005, 19.74951171874997],
                            [98.37128906250004, 19.68916015625004],
                            [98.9166992187501, 19.77290039062504],
                            [99.07421875000003, 20.09936523437503],
                            [99.48593750000006, 20.14985351562501],
                            [99.45888671875005, 20.363037109375],
                            [99.72011718750005, 20.32543945312497],
                            [99.8903320312501, 20.424414062499977],
                            [99.9542968750001, 20.415429687500023],
                            [100.0036132812501, 20.37958984375001],
                            [100.12246093750005, 20.316650390625057],
                        ],
                    ],
                ],
            },
            properties: {
                name: "泰国",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [70.66416015625, 39.85546875],
                            [70.55957031250003, 39.790917968749994],
                            [70.48925781250003, 39.86303710937503],
                            [70.48281250000005, 39.88271484375005],
                            [70.49775390625004, 39.88242187499998],
                            [70.56708984375004, 39.86660156250005],
                            [70.66416015625, 39.85546875],
                        ],
                    ],
                    [
                        [
                            [70.95800781250003, 40.238867187500034],
                            [70.59921875, 39.974511718749994],
                            [69.96679687499997, 40.202246093750034],
                            [69.46875, 40.020751953125],
                            [69.47099609375002, 39.990625],
                            [69.43193359375007, 39.909765625000034],
                            [69.36542968750004, 39.94707031250002],
                            [69.30722656250006, 39.968554687500045],
                            [69.27880859374997, 39.91777343749999],
                            [69.24472656250006, 39.82709960937498],
                            [69.29765625000007, 39.52480468750005],
                            [70.50117187500004, 39.58735351562501],
                            [70.79931640625003, 39.39472656250001],
                            [71.4703125, 39.60366210937502],
                            [71.50302734375006, 39.58217773437502],
                            [71.51738281250002, 39.55385742187502],
                            [71.50585937499997, 39.51708984374997],
                            [71.5033203125, 39.47880859374999],
                            [71.73222656250002, 39.422998046874994],
                            [71.77861328125007, 39.27797851562502],
                            [72.04277343750002, 39.352148437500034],
                            [72.08417968750004, 39.310644531250034],
                            [72.14736328125005, 39.26074218749997],
                            [72.22998046874997, 39.20751953124997],
                            [72.63994140625002, 39.385986328125],
                            [73.10927734375, 39.36191406249998],
                            [73.2349609375, 39.37456054687499],
                            [73.3361328125001, 39.41235351562506],
                            [73.38740234375004, 39.442724609375034],
                            [73.4704101562501, 39.46059570312502],
                            [73.63164062500007, 39.44887695312502],
                            [73.63632812500006, 39.396679687499955],
                            [73.60732421875, 39.229199218749955],
                            [73.8052734375, 38.968652343749994],
                            [73.69609375000007, 38.85429687499996],
                            [73.80166015625, 38.60688476562501],
                            [74.02558593750004, 38.53984375000002],
                            [74.27744140625, 38.659765625000034],
                            [74.81230468750002, 38.46030273437498],
                            [74.8942382812501, 37.60141601562498],
                            [75.11875, 37.38569335937498],
                            [74.89130859375004, 37.231640624999955],
                            [74.875390625, 37.24199218750002],
                            [74.83046875, 37.28593750000002],
                            [74.73056640625006, 37.35703125],
                            [74.659375, 37.39448242187501],
                            [74.34902343750005, 37.41875],
                            [74.25966796875005, 37.41542968750002],
                            [74.20351562500005, 37.37246093750005],
                            [74.16708984375, 37.32944335937498],
                            [73.74960937500006, 37.23178710937498],
                            [73.6535156250001, 37.239355468750034],
                            [73.62753906250006, 37.261572265625006],
                            [73.71728515625003, 37.32944335937498],
                            [73.7337890625, 37.37578125000002],
                            [73.72060546875, 37.41875],
                            [73.65712890625005, 37.43046875],
                            [73.6046875000001, 37.44604492187503],
                            [73.48134765625, 37.4716796875],
                            [73.38291015625006, 37.462255859375034],
                            [73.21113281250004, 37.40849609375002],
                            [72.89550781250003, 37.26752929687498],
                            [72.65742187500004, 37.029052734375],
                            [71.665625, 36.696923828124994],
                            [71.530859375, 36.845117187499994],
                            [71.43291015625007, 37.12753906249998],
                            [71.5822265625001, 37.91010742187498],
                            [71.55195312500004, 37.93315429687496],
                            [71.48779296874997, 37.93188476562497],
                            [71.38964843750003, 37.90629882812502],
                            [71.31992187500006, 37.90185546875],
                            [71.27851562500004, 37.91840820312498],
                            [71.33271484375004, 38.170263671875034],
                            [71.25585937499997, 38.306982421875006],
                            [70.7359375, 38.42255859375001],
                            [70.41777343750002, 38.075439453125],
                            [70.21464843750002, 37.92441406250006],
                            [70.19941406250004, 37.88603515624996],
                            [70.25498046875006, 37.76538085937497],
                            [70.25146484374997, 37.66416015625006],
                            [70.18867187500004, 37.58247070312501],
                            [70.11982421875004, 37.54350585937499],
                            [69.9849609375, 37.566162109375],
                            [69.8208984375, 37.60957031250004],
                            [69.62578125000002, 37.59404296874999],
                            [69.49208984375, 37.55307617187498],
                            [69.42011718750004, 37.486718749999966],
                            [69.39921875000007, 37.39931640625002],
                            [69.42968749999997, 37.290869140625034],
                            [69.414453125, 37.20776367187497],
                            [69.35380859375007, 37.15004882812502],
                            [69.3039062500001, 37.11694335937503],
                            [69.26484375000004, 37.1083984375],
                            [69.18017578125003, 37.158300781250034],
                            [68.96044921875003, 37.32504882812498],
                            [68.9118164062501, 37.33393554687501],
                            [68.88525390624997, 37.32807617187498],
                            [68.85537109375005, 37.31684570312501],
                            [68.83847656250006, 37.30283203124998],
                            [68.82373046874997, 37.27070312500001],
                            [68.78203125000002, 37.25800781250001],
                            [68.7232421875, 37.26801757812501],
                            [68.6691406250001, 37.258398437500006],
                            [68.3869140625001, 37.1375],
                            [68.29951171875004, 37.08842773437502],
                            [68.28476562500006, 37.036328124999955],
                            [68.2609375000001, 37.01308593750002],
                            [68.2121093750001, 37.02153320312496],
                            [68.0677734375, 36.949804687500006],
                            [67.95800781249997, 36.972021484375006],
                            [67.83447265624997, 37.06420898437506],
                            [67.75898437500004, 37.172216796875034],
                            [67.7980468750001, 37.244970703125006],
                            [67.81435546875005, 37.48701171875004],
                            [68.3502929687501, 38.211035156250006],
                            [68.08720703125002, 38.47353515625002],
                            [68.13251953125004, 38.927636718749966],
                            [67.69443359375006, 38.99462890625003],
                            [67.64833984375005, 39.13105468750004],
                            [67.3576171875001, 39.216699218749994],
                            [67.426171875, 39.46557617187497],
                            [67.71904296875007, 39.62138671875002],
                            [68.46328125, 39.53671874999998],
                            [68.63896484375007, 39.8388671875],
                            [68.86875, 39.90747070312503],
                            [68.80468750000003, 40.05034179687499],
                            [68.9720703125, 40.08994140624998],
                            [68.63066406250007, 40.16708984374998],
                            [69.27490234374997, 40.19809570312498],
                            [69.20625, 40.566552734374994],
                            [69.35722656250002, 40.76738281249996],
                            [69.71289062500003, 40.65698242187503],
                            [70.40195312500006, 41.03510742187498],
                            [70.75107421875006, 40.721777343750006],
                            [70.37158203125003, 40.38413085937506],
                            [70.653125, 40.201171875],
                            [70.95800781250003, 40.238867187500034],
                        ],
                    ],
                ],
            },
            properties: {
                name: "塔吉克斯坦",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [66.52226562500007, 37.34848632812506],
                        [66.471875, 37.3447265625],
                        [65.7650390625, 37.56914062499996],
                        [65.55498046875002, 37.25117187500004],
                        [65.30361328125005, 37.24677734375001],
                        [65.08964843750007, 37.237939453124994],
                        [64.9515625, 37.19355468750001],
                        [64.81630859375005, 37.13208007812503],
                        [64.7824218750001, 37.05927734375001],
                        [64.60253906250003, 36.554541015625034],
                        [64.5658203125, 36.427587890625034],
                        [64.51103515625002, 36.34067382812498],
                        [64.184375, 36.14892578125],
                        [63.8625, 36.012353515624994],
                        [63.12998046875006, 35.84619140624997],
                        [63.169726562500074, 35.678125],
                        [63.05664062500003, 35.44580078125003],
                        [62.98027343750002, 35.40917968750003],
                        [62.85800781250006, 35.34965820312499],
                        [62.688085937500006, 35.25532226562504],
                        [62.3078125000001, 35.17080078125005],
                        [62.08964843750002, 35.3796875],
                        [61.62099609375005, 35.43232421875004],
                        [61.34472656249997, 35.62949218750006],
                        [61.26201171875002, 35.61958007812498],
                        [61.25214843750004, 35.86762695312498],
                        [61.15292968750006, 35.97675781250001],
                        [61.212011718750006, 36.190527343750034],
                        [61.11962890625003, 36.64257812500003],
                        [60.34130859375003, 36.63764648437501],
                        [60.06279296875002, 36.962890625],
                        [59.454980468749994, 37.25283203125002],
                        [59.30175781249997, 37.51064453125005],
                        [58.81542968750003, 37.683496093749994],
                        [58.261621093749994, 37.665820312500045],
                        [57.35371093750004, 37.97333984374998],
                        [57.1935546875001, 38.216406250000034],
                        [56.440625, 38.249414062499994],
                        [56.272070312500006, 38.080419921875034],
                        [55.38085937500003, 38.051123046875034],
                        [54.90009765625004, 37.77792968750006],
                        [54.6994140625001, 37.47016601562498],
                        [53.91416015625006, 37.34355468750002],
                        [53.86865234375003, 38.949267578125045],
                        [53.70458984375003, 39.209570312500034],
                        [53.33632812500005, 39.34082031250006],
                        [53.15664062499999, 39.26499023437506],
                        [53.23564453125002, 39.608544921874966],
                        [53.603125, 39.546972656250034],
                        [53.472265625, 39.66879882812498],
                        [53.48730468749997, 39.909375],
                        [52.9875, 39.98759765625002],
                        [53.03554687500005, 39.7744140625],
                        [52.80468749999997, 40.054003906250045],
                        [52.73369140625002, 40.39873046875002],
                        [52.943457031250006, 41.03808593750006],
                        [53.1452148437501, 40.82495117187497],
                        [53.61523437500003, 40.818505859374994],
                        [53.87001953125005, 40.64868164062503],
                        [54.37734375, 40.693261718749966],
                        [54.319433593750006, 40.83457031249998],
                        [54.68505859375003, 40.873046875],
                        [54.70371093750006, 41.071142578125034],
                        [54.094824218750006, 41.51938476562506],
                        [53.80468749999997, 42.11762695312498],
                        [53.16416015625006, 42.09379882812502],
                        [52.97001953125002, 41.97622070312505],
                        [52.81484375, 41.711816406249994],
                        [52.850390625000074, 41.20029296875006],
                        [52.4938476562501, 41.780371093750034],
                        [53.0558593750001, 42.14775390624999],
                        [54.120996093749994, 42.335205078125],
                        [54.85380859375002, 41.965185546875006],
                        [55.434375, 41.296289062499994],
                        [55.97744140625005, 41.32221679687504],
                        [57.01796875, 41.26347656249996],
                        [57.11884765625004, 41.35029296874998],
                        [56.96406250000004, 41.856542968750006],
                        [57.290625, 42.123779296875],
                        [57.814257812500074, 42.18984375000005],
                        [58.02890625, 42.48764648437506],
                        [58.474414062500074, 42.29936523437496],
                        [58.15156250000004, 42.628076171874966],
                        [58.477148437500006, 42.66284179687503],
                        [58.5890625000001, 42.778466796874966],
                        [59.35429687500002, 42.32329101562496],
                        [59.98515625000002, 42.21171875],
                        [59.94179687499999, 41.97353515625002],
                        [60.20078125000006, 41.803125],
                        [60.07558593750005, 41.759667968749966],
                        [60.089648437500074, 41.39941406250003],
                        [60.454980468749994, 41.221630859374955],
                        [61.2423828125001, 41.18920898437503],
                        [61.496972656249994, 41.276074218749955],
                        [61.90283203124997, 41.09370117187501],
                        [62.48320312500002, 39.97563476562496],
                        [63.76367187500003, 39.16054687499999],
                        [64.3099609375, 38.97729492187497],
                        [65.612890625, 38.23857421875002],
                        [65.97119140624997, 38.244238281250006],
                        [66.60625, 37.98671875000005],
                        [66.52558593750004, 37.785742187500034],
                        [66.51132812500006, 37.59916992187496],
                        [66.51064453125, 37.45869140625004],
                        [66.52226562500007, 37.34848632812506],
                    ],
                ],
            },
            properties: {
                name: "土库曼斯坦",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [124.0363281250001, -9.341601562500031],
                            [124.44443359375012, -9.190332031250023],
                            [124.28232421875012, -9.427929687500026],
                            [124.0363281250001, -9.341601562500031],
                        ],
                    ],
                    [
                        [
                            [125.06816406250002, -9.511914062499997],
                            [124.96015625000004, -9.213769531250009],
                            [125.10048828125, -9.189843750000023],
                            [125.14902343750012, -9.042578125000034],
                            [124.93681640625007, -9.053417968750026],
                            [124.92226562500005, -8.942480468749977],
                            [125.17802734375002, -8.647851562499994],
                            [125.38183593749997, -8.575390624999983],
                            [126.61972656250006, -8.459472656249986],
                            [126.96640625000012, -8.315722656250017],
                            [127.29609375000004, -8.424511718749969],
                            [126.91523437500004, -8.715234374999966],
                            [125.40800781250002, -9.275781250000023],
                            [125.06816406250002, -9.511914062499997],
                        ],
                    ],
                    [
                        [
                            [125.64609375000006, -8.139941406250003],
                            [125.5794921875, -8.311816406250017],
                            [125.50712890625007, -8.275097656249997],
                            [125.64609375000006, -8.139941406250003],
                        ],
                    ],
                ],
            },
            properties: {
                name: "东帝汶",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-175.1619140625, -21.169335937500023],
                            [-175.07817382812496, -21.129003906249977],
                            [-175.15659179687495, -21.26367187499997],
                            [-175.36235351562496, -21.106835937499994],
                            [-175.1619140625, -21.169335937500023],
                        ],
                    ],
                    [
                        [
                            [-173.953515625, -18.63935546875001],
                            [-174.06914062500002, -18.640234375],
                            [-173.96806640624993, -18.565332031250023],
                            [-173.953515625, -18.63935546875001],
                        ],
                    ],
                ],
            },
            properties: {
                name: "汤加",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-61.012109374999966, 10.134326171874989],
                        [-61.906103515625006, 10.069140625000031],
                        [-61.49931640624999, 10.268554687499972],
                        [-61.47827148437497, 10.603369140624977],
                        [-61.65117187499993, 10.718066406249974],
                        [-60.917626953124966, 10.84023437499999],
                        [-61.03374023437502, 10.669873046875026],
                        [-61.012109374999966, 10.134326171874989],
                    ],
                ],
            },
            properties: {
                name: "特立尼达和多巴哥",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [10.957617187500063, 33.72207031250005],
                            [10.722070312500051, 33.738916015624994],
                            [10.745214843750063, 33.88867187500006],
                            [11.017871093749989, 33.82333984374998],
                            [10.957617187500063, 33.72207031250005],
                        ],
                    ],
                    [
                        [
                            [11.278027343750068, 34.753808593749994],
                            [11.123632812500063, 34.68168945312496],
                            [11.254882812500057, 34.82031250000006],
                            [11.278027343750068, 34.753808593749994],
                        ],
                    ],
                    [
                        [
                            [10.274609375000011, 31.684960937499994],
                            [10.114941406250068, 31.46376953125005],
                            [10.216406250000063, 30.78320312500003],
                            [10.05976562500004, 30.58007812500003],
                            [9.932519531250051, 30.42534179687496],
                            [9.895019531250028, 30.387304687500034],
                            [9.51875, 30.229394531249994],
                            [9.224023437500023, 31.373681640624994],
                            [9.160253906250006, 31.621337890625],
                            [9.044042968750034, 32.072363281250034],
                            [8.333398437500051, 32.54360351562502],
                            [8.1125, 33.055322265624994],
                            [7.877246093750017, 33.172119140625],
                            [7.534375, 33.717919921874994],
                            [7.513867187500068, 34.080517578124955],
                            [8.24560546875, 34.73408203124998],
                            [8.276855468750057, 34.97949218749997],
                            [8.312109375000063, 35.084619140624994],
                            [8.394238281250011, 35.20385742187503],
                            [8.318066406250011, 35.654931640624994],
                            [8.348730468750063, 36.367968750000045],
                            [8.207617187500006, 36.518945312499994],
                            [8.601269531250068, 36.83393554687504],
                            [8.576562500000023, 36.93720703125001],
                            [9.687988281250057, 37.34038085937499],
                            [9.838476562500063, 37.30898437499999],
                            [9.830273437499983, 37.13535156250006],
                            [9.875585937499977, 37.25415039062503],
                            [10.196386718750063, 37.205859375000045],
                            [10.293261718750074, 36.781494140625],
                            [10.412304687499983, 36.73183593750002],
                            [11.053906250000068, 37.07250976562506],
                            [11.12666015625004, 36.874072265625045],
                            [10.476562500000028, 36.175146484375006],
                            [10.590820312500028, 35.88725585937499],
                            [11.00429687500008, 35.63383789062496],
                            [11.120117187500057, 35.24028320312499],
                            [10.69091796875, 34.67846679687503],
                            [10.118359375000068, 34.280078125000045],
                            [10.049023437500068, 34.056298828124994],
                            [10.305273437500034, 33.72827148437497],
                            [10.713183593750017, 33.68901367187496],
                            [10.722753906250006, 33.514404296875],
                            [10.958007812500057, 33.62631835937498],
                            [11.257421875000034, 33.30883789062506],
                            [11.202636718749972, 33.24921874999998],
                            [11.50458984375004, 33.181933593750045],
                            [11.502441406250028, 33.15556640624999],
                            [11.467187500000051, 32.96572265625005],
                            [11.459179687500011, 32.897363281249966],
                            [11.453906250000017, 32.64257812500003],
                            [11.533789062500034, 32.52495117187496],
                            [11.535937500000017, 32.47333984375001],
                            [11.504980468750034, 32.413671875000034],
                            [11.358007812500006, 32.34521484375003],
                            [11.168261718750074, 32.25673828125002],
                            [11.005175781250074, 32.17270507812506],
                            [10.826367187500068, 32.080664062500034],
                            [10.771582031250006, 32.02119140625001],
                            [10.60888671875, 31.929541015624977],
                            [10.47578125000004, 31.736035156249983],
                            [10.274609375000011, 31.684960937499994],
                        ],
                    ],
                ],
            },
            properties: {
                name: "突尼斯",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [25.970019531250045, 40.136328125],
                            [25.6689453125, 40.13588867187502],
                            [25.918359375000023, 40.23798828125004],
                            [25.970019531250045, 40.136328125],
                        ],
                    ],
                    [
                        [
                            [43.43339843750002, 41.155517578125],
                            [43.43945312500003, 41.10712890625001],
                            [43.72265624999997, 40.71953124999999],
                            [43.56933593750003, 40.48237304687498],
                            [43.66621093750004, 40.12636718750002],
                            [44.28925781250004, 40.040380859375006],
                            [44.76826171875004, 39.70351562500005],
                            [44.81718750000002, 39.65043945312496],
                            [44.58710937500004, 39.76855468750006],
                            [44.3893554687501, 39.422119140625],
                            [44.02324218750002, 39.37744140625006],
                            [44.27167968750004, 38.83603515625006],
                            [44.2985351562501, 38.38627929687499],
                            [44.4499023437501, 38.33422851562506],
                            [44.21132812499999, 37.908056640625006],
                            [44.589941406250006, 37.710351562499966],
                            [44.574023437500074, 37.435400390625006],
                            [44.79414062500004, 37.290380859375034],
                            [44.76513671875003, 37.142431640625006],
                            [44.73095703124997, 37.16528320312503],
                            [44.66933593750005, 37.17358398437503],
                            [44.60595703124997, 37.176025390625],
                            [44.401953125, 37.05849609375002],
                            [44.325585937499994, 37.0107421875],
                            [44.28183593750006, 36.97802734374997],
                            [44.24570312500006, 36.983300781249994],
                            [44.20166015624997, 37.05180664062502],
                            [44.208398437499994, 37.20263671875],
                            [44.19179687499999, 37.249853515625034],
                            [44.15625, 37.28295898437503],
                            [44.11445312500004, 37.30185546875006],
                            [44.01318359375003, 37.313525390625045],
                            [43.83642578124997, 37.223535156249994],
                            [43.67578125000003, 37.227246093749955],
                            [43.09248046875004, 37.36738281249998],
                            [42.936621093750006, 37.32475585937502],
                            [42.77460937500004, 37.371875],
                            [42.74111328125005, 37.361914062500034],
                            [42.6354492187501, 37.249267578125],
                            [42.45585937500002, 37.128710937500045],
                            [42.358984375, 37.10859375000004],
                            [42.31289062499999, 37.22958984374998],
                            [42.26855468749997, 37.276562499999955],
                            [42.24755859375003, 37.28222656250006],
                            [42.20273437500006, 37.29726562499999],
                            [42.16787109375005, 37.28862304687502],
                            [42.059863281250074, 37.2060546875],
                            [41.886816406250006, 37.156396484374994],
                            [40.70566406250006, 37.09770507812502],
                            [40.4503906250001, 37.00888671875006],
                            [40.016406250000074, 36.82607421875002],
                            [39.68652343749997, 36.73862304687506],
                            [39.50146484374997, 36.702246093750034],
                            [39.35664062500004, 36.68159179687498],
                            [39.10839843749997, 36.68056640625005],
                            [38.90644531250004, 36.69467773437498],
                            [38.76660156249997, 36.69311523437503],
                            [38.19169921875002, 36.90156250000004],
                            [37.90664062500005, 36.79462890625001],
                            [37.7203125, 36.74370117187502],
                            [37.52353515625006, 36.678320312500034],
                            [37.436328125000074, 36.643310546875],
                            [37.327050781249994, 36.64658203125006],
                            [37.18740234375005, 36.655908203124994],
                            [37.066210937500074, 36.652636718750045],
                            [36.98535156250003, 36.70239257812506],
                            [36.94179687499999, 36.758398437500006],
                            [36.77656250000004, 36.79267578124998],
                            [36.65859375000005, 36.80253906250002],
                            [36.62841796875003, 36.777685546875034],
                            [36.596875, 36.70136718750001],
                            [36.546679687500074, 36.50634765625],
                            [36.5375, 36.457421874999966],
                            [36.63671874999997, 36.233984375],
                            [36.37539062499999, 36.171240234375034],
                            [36.347558593749994, 36.003515625000034],
                            [36.20195312500002, 35.93754882812502],
                            [36.15361328125002, 35.83388671875005],
                            [36.12734375, 35.831445312499994],
                            [35.967578125000074, 35.91005859375002],
                            [35.89267578125006, 35.91655273437502],
                            [35.81093750000005, 36.30986328125002],
                            [36.18847656250003, 36.65898437499999],
                            [36.048925781250006, 36.91059570312501],
                            [35.393164062500006, 36.57519531249997],
                            [34.70361328125003, 36.81679687499999],
                            [33.694726562499994, 36.18198242187498],
                            [32.794824218749994, 36.03588867187497],
                            [32.37773437500002, 36.18364257812496],
                            [32.02197265625003, 36.53530273437502],
                            [31.35253906249997, 36.80107421874999],
                            [30.64404296874997, 36.86567382812501],
                            [30.446093750000074, 36.269873046875034],
                            [29.6890625, 36.15668945312498],
                            [29.22363281249997, 36.32446289062497],
                            [28.96962890625008, 36.71533203125003],
                            [28.303710937500057, 36.81196289062498],
                            [28.01943359375005, 36.63447265624998],
                            [28.083984375000057, 36.75146484375],
                            [27.453906250000017, 36.712158203125],
                            [28.00537109375003, 36.83198242187498],
                            [28.242382812500068, 37.029052734375],
                            [27.262988281250045, 36.97656250000003],
                            [27.30019531250005, 37.12685546875002],
                            [27.53505859375005, 37.16386718750002],
                            [27.06796875, 37.65791015625004],
                            [27.224414062500074, 37.725439453125006],
                            [27.23242187500003, 37.978662109374994],
                            [26.29072265625001, 38.27719726562498],
                            [26.44130859375005, 38.64121093749998],
                            [26.67421875000008, 38.33574218750002],
                            [27.14423828125001, 38.45195312499996],
                            [26.906835937500034, 38.48173828124999],
                            [26.763671875, 38.709619140624966],
                            [27.013671875000057, 38.88686523437502],
                            [26.814941406250057, 38.96098632812502],
                            [26.853613281250034, 39.115625],
                            [26.68183593750004, 39.292236328125],
                            [26.89921874999999, 39.549658203125034],
                            [26.113085937500074, 39.46738281249998],
                            [26.101367187500074, 39.56894531249998],
                            [26.18134765625004, 39.99008789062498],
                            [26.738085937500045, 40.40024414062506],
                            [27.28457031250008, 40.45561523437496],
                            [27.4755859375, 40.319921875000034],
                            [27.72802734375, 40.32880859374998],
                            [27.84853515625005, 40.38173828125002],
                            [27.73183593750008, 40.48149414062499],
                            [27.87490234375008, 40.512939453125],
                            [27.989550781250074, 40.48945312500001],
                            [27.96259765625001, 40.369873046875],
                            [29.00712890624999, 40.389746093750034],
                            [28.787890625000017, 40.534033203125034],
                            [28.95800781250003, 40.63056640624998],
                            [29.849218750000063, 40.760107421875006],
                            [29.113867187499977, 40.93784179687506],
                            [29.14814453125004, 41.221044921875034],
                            [31.25488281249997, 41.10761718750001],
                            [31.45800781249997, 41.32001953125004],
                            [32.306445312500074, 41.72958984374998],
                            [33.38134765625003, 42.01757812500003],
                            [34.75048828124997, 41.95683593749999],
                            [35.006445312500006, 42.06328125000002],
                            [35.15488281250006, 42.02753906250001],
                            [35.12207031250003, 41.89111328125003],
                            [35.297753906249994, 41.72851562500003],
                            [35.558007812499994, 41.63403320312506],
                            [36.05175781249997, 41.68256835937498],
                            [36.40537109375006, 41.27460937500001],
                            [36.77773437499999, 41.36347656250001],
                            [37.066210937500074, 41.184423828125034],
                            [38.38105468750004, 40.92451171875001],
                            [39.426367187500006, 41.10644531250003],
                            [40.26523437500006, 40.96132812500005],
                            [41.08359375000006, 41.26118164062504],
                            [41.41435546875002, 41.42363281249999],
                            [41.510058593750074, 41.51748046875002],
                            [41.70175781250006, 41.471582031249994],
                            [41.77939453125006, 41.44052734374998],
                            [41.823535156250074, 41.432373046875],
                            [41.92578125000003, 41.49565429687502],
                            [42.46640625, 41.43984375000002],
                            [42.56738281249997, 41.55927734375001],
                            [42.590429687500006, 41.57070312500002],
                            [42.60683593750005, 41.57880859374998],
                            [42.682421875000074, 41.58574218749999],
                            [42.75410156250004, 41.57890625000002],
                            [42.787890625000074, 41.56372070312503],
                            [42.82167968750005, 41.49238281249998],
                            [42.90673828125003, 41.46684570312502],
                            [43.05712890625003, 41.35283203124996],
                            [43.149023437500006, 41.30712890624997],
                            [43.171289062499994, 41.28793945312498],
                            [43.14101562499999, 41.26484374999998],
                            [43.15283203124997, 41.23642578125006],
                            [43.20546875000005, 41.19916992187501],
                            [43.43339843750002, 41.155517578125],
                        ],
                    ],
                    [
                        [
                            [27.47480468750001, 41.946875],
                            [28.014453125000017, 41.96904296874999],
                            [28.197851562500063, 41.55449218750002],
                            [29.057226562500006, 41.22973632812503],
                            [28.95625, 41.00820312499999],
                            [28.172167968750074, 41.08071289062502],
                            [27.49941406250005, 40.97314453124997],
                            [27.258007812499983, 40.687353515625006],
                            [26.772070312500034, 40.498046875],
                            [26.202734375000034, 40.07539062500004],
                            [26.25380859375005, 40.31469726562503],
                            [26.792089843750034, 40.626611328124994],
                            [26.10546875000003, 40.61132812499997],
                            [26.03896484375008, 40.726757812499955],
                            [26.331054687500057, 40.954492187499994],
                            [26.330664062499977, 41.23876953125],
                            [26.62490234375008, 41.401757812499994],
                            [26.581347656250074, 41.60126953125004],
                            [26.320898437500034, 41.716552734375],
                            [26.3603515625, 41.80156249999999],
                            [26.51142578125004, 41.82636718749998],
                            [26.549707031250023, 41.896728515625],
                            [26.5796875, 41.947949218749955],
                            [26.615332031250063, 41.964892578125045],
                            [26.884863281250006, 41.99184570312502],
                            [26.96875, 42.02685546875006],
                            [27.01171875, 42.05864257812496],
                            [27.193359375000057, 42.07709960937498],
                            [27.24433593750004, 42.09326171875],
                            [27.294921875000057, 42.079541015624955],
                            [27.47480468750001, 41.946875],
                        ],
                    ],
                ],
            },
            properties: {
                name: "土耳其",
                childNum: 3,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.71132812499999, -7.977441406250023],
                            [39.602929687499994, -7.936132812499949],
                            [39.907128906249994, -7.649218750000031],
                            [39.71132812499999, -7.977441406250023],
                        ],
                    ],
                    [
                        [
                            [39.49648437499999, -6.174609375],
                            [39.573046875000074, -6.387402343750011],
                            [39.48095703124997, -6.45371093750002],
                            [39.18232421875004, -6.172558593750026],
                            [39.30898437499999, -5.721972656249974],
                            [39.49648437499999, -6.174609375],
                        ],
                    ],
                    [
                        [
                            [39.86503906250002, -4.906152343750037],
                            [39.74931640625002, -5.443847656249986],
                            [39.646777343750074, -5.368554687500009],
                            [39.6734375, -4.927050781250031],
                            [39.86503906250002, -4.906152343750037],
                        ],
                    ],
                    [
                        [
                            [33.90322265625005, -1.002050781250034],
                            [37.643847656250074, -3.045410156250028],
                            [37.608203125000074, -3.497070312500028],
                            [39.221777343750006, -4.692382812500014],
                            [38.80468750000003, -6.070117187500031],
                            [38.87402343750003, -6.33125],
                            [39.5460937500001, -7.024023437500034],
                            [39.288476562499994, -7.517871093750003],
                            [39.28701171875005, -7.787695312500006],
                            [39.4284179687501, -7.81279296874996],
                            [39.441015625, -8.011523437499946],
                            [39.304003906250074, -8.44384765625],
                            [39.451269531250006, -8.94296875],
                            [39.64130859375004, -9.19248046875002],
                            [39.72519531250006, -10.000488281249972],
                            [40.46357421875004, -10.464355468749972],
                            [39.98867187499999, -10.820800781250014],
                            [39.81708984375004, -10.912402343750031],
                            [38.9875, -11.167285156250003],
                            [38.49179687500006, -11.413281250000026],
                            [37.92021484375002, -11.294726562500031],
                            [37.72480468750004, -11.58066406250002],
                            [37.54169921875004, -11.675097656249974],
                            [37.37285156250002, -11.710449218749986],
                            [36.97890625000005, -11.566992187499977],
                            [36.30566406250003, -11.706347656249946],
                            [36.191308593749994, -11.670703124999974],
                            [36.17548828125004, -11.60927734374998],
                            [36.08222656250004, -11.537304687499969],
                            [35.91132812500004, -11.45468750000002],
                            [35.785449218750074, -11.452929687500017],
                            [35.63095703125006, -11.582031250000028],
                            [35.564355468749994, -11.602343749999989],
                            [35.418261718750074, -11.583203125],
                            [35.18261718750003, -11.574804687499977],
                            [34.95947265625003, -11.578125],
                            [34.93701171874997, -11.463476562500034],
                            [34.890625, -11.3935546875],
                            [34.77382812500005, -11.341699218750009],
                            [34.60791015624997, -11.08046875],
                            [34.66708984375006, -10.792480468750028],
                            [34.56992187500006, -10.241113281249966],
                            [34.32089843750006, -9.731542968749977],
                            [33.99560546875003, -9.495410156250003],
                            [33.88886718750004, -9.670117187499983],
                            [32.91992187500003, -9.407421875000026],
                            [32.75664062500002, -9.322265625],
                            [31.94257812500004, -9.05400390624996],
                            [31.91865234375004, -8.942187500000017],
                            [31.886132812499994, -8.921972656249977],
                            [31.81806640625004, -8.902246093749952],
                            [31.673632812500017, -8.908789062499963],
                            [31.55625, -8.80546875],
                            [31.44921874999997, -8.65390625],
                            [31.35058593750003, -8.607031250000034],
                            [31.07636718750004, -8.611914062499963],
                            [30.968359375000063, -8.550976562499983],
                            [30.89199218750005, -8.473730468749963],
                            [30.830664062500063, -8.385546875000031],
                            [30.720898437500097, -8.104394531250037],
                            [30.40673828125003, -7.460644531249983],
                            [30.313183593750097, -7.203710937499949],
                            [30.212695312500017, -7.037890625000017],
                            [30.10625, -6.915039062500028],
                            [29.961816406249994, -6.803125],
                            [29.798144531250017, -6.691894531249957],
                            [29.70966796875004, -6.61689453125004],
                            [29.590625, -6.394433593750023],
                            [29.540820312500017, -6.313867187500037],
                            [29.50625, -6.172070312500011],
                            [29.480078125, -6.025],
                            [29.490820312500063, -5.96542968750002],
                            [29.59638671875004, -5.775976562499963],
                            [29.60703125, -5.722656250000028],
                            [29.59414062500005, -5.650781250000037],
                            [29.542382812499994, -5.499804687500017],
                            [29.34277343749997, -4.983105468749997],
                            [29.32343750000004, -4.898828124999966],
                            [29.32568359374997, -4.835644531249969],
                            [29.404199218749994, -4.49667968750002],
                            [29.40322265625005, -4.449316406249963],
                            [29.71777343750003, -4.45585937499996],
                            [29.94726562499997, -4.307324218749983],
                            [30.4, -3.65390625],
                            [30.790234375000097, -3.274609375000011],
                            [30.811132812500006, -3.116406250000011],
                            [30.78027343750003, -2.984863281249957],
                            [30.70947265624997, -2.977246093749997],
                            [30.604296875000074, -2.935253906249969],
                            [30.515039062499994, -2.917578125],
                            [30.45556640625003, -2.893164062500006],
                            [30.433496093749994, -2.874511718750028],
                            [30.424023437500097, -2.82402343749996],
                            [30.473339843750097, -2.6943359375],
                            [30.42421875000005, -2.641601562500014],
                            [30.441992187500006, -2.613476562499969],
                            [30.53369140624997, -2.426269531250014],
                            [30.55361328125005, -2.400097656250011],
                            [30.593359375000063, -2.39677734374996],
                            [30.65664062500005, -2.373828124999989],
                            [30.71484375000003, -2.363476562500011],
                            [30.7625, -2.371679687499991],
                            [30.828710937500006, -2.338476562499977],
                            [30.85498046874997, -2.265429687500017],
                            [30.8765625, -2.143359375000017],
                            [30.864648437499994, -2.044042968749949],
                            [30.819140625000017, -1.967480468749983],
                            [30.812597656250006, -1.56308593750002],
                            [30.76220703124997, -1.458691406249983],
                            [30.710742187500074, -1.396777343749974],
                            [30.631933593750006, -1.36748046874996],
                            [30.508105468750074, -1.208203125000026],
                            [30.47021484374997, -1.13115234374996],
                            [30.47705078124997, -1.0830078125],
                            [30.509960937500097, -1.067285156249994],
                            [30.51992187499999, -1.0625],
                            [30.67275390625005, -1.051367187499949],
                            [30.741992187500017, -1.007519531249997],
                            [30.809179687500063, -0.994921875],
                            [30.82363281250005, -0.999023437499943],
                            [30.84472656250003, -1.002050781250034],
                            [32.371875, -1.002050781250034],
                            [33.90322265625005, -1.002050781250034],
                        ],
                    ],
                ],
            },
            properties: {
                name: "坦桑尼亚",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [30.50996093750001, -1.067285156250009],
                        [30.46992187500001, -1.066015625],
                        [30.41230468750001, -1.063085937500006],
                        [30.360253906250023, -1.074609375],
                        [29.930078125000023, -1.469921875000011],
                        [29.82539062500001, -1.335546875],
                        [29.576953125000017, -1.387890625000011],
                        [29.717675781250023, 0.098339843749997],
                        [29.934472656250023, 0.4990234375],
                        [29.94287109375, 0.819238281249994],
                        [31.252734375000017, 2.044580078124994],
                        [31.176367187500006, 2.270068359374989],
                        [30.728613281250006, 2.455371093749989],
                        [30.8466796875, 2.847021484374991],
                        [30.754003906250006, 3.041796874999989],
                        [30.90644531250001, 3.408935546875],
                        [30.83857421875001, 3.49072265625],
                        [31.15234375, 3.785595703124997],
                        [31.547167968750017, 3.677587890624991],
                        [31.79804687500001, 3.802636718749994],
                        [32.13593750000001, 3.519726562499997],
                        [32.33574218750002, 3.706201171874994],
                        [32.99726562500001, 3.880175781249989],
                        [33.489355468750006, 3.755078125],
                        [33.568457031250006, 3.81171875],
                        [33.74160156250002, 3.985253906249994],
                        [33.97607421875, 4.22021484375],
                        [34.13203125000001, 3.88916015625],
                        [34.18574218750001, 3.869775390624994],
                        [34.1650390625, 3.81298828125],
                        [34.26708984375, 3.733154296875],
                        [34.39287109375002, 3.691503906249991],
                        [34.43769531250001, 3.650585937499997],
                        [34.44179687500002, 3.60625],
                        [34.3994140625, 3.412695312499991],
                        [34.4072265625, 3.357519531249991],
                        [34.447851562500006, 3.163476562499994],
                        [34.90576171875, 2.4796875],
                        [34.88300781250001, 2.417919921874997],
                        [34.96406250000001, 2.062402343749994],
                        [34.9775390625, 1.861914062499991],
                        [34.97646484375002, 1.719628906249994],
                        [34.79863281250002, 1.24453125],
                        [34.48173828125002, 1.042138671874994],
                        [34.41083984375001, 0.867285156249991],
                        [34.16093750000002, 0.605175781249997],
                        [33.94316406250002, 0.173779296874997],
                        [33.90322265625002, -1.002050781250006],
                        [32.371875, -1.002050781250006],
                        [30.8447265625, -1.002050781250006],
                        [30.823632812500023, -0.9990234375],
                        [30.809179687500006, -0.994921875],
                        [30.741992187500017, -1.007519531250011],
                        [30.672753906250023, -1.051367187500006],
                        [30.598730468750006, -1.069726562500009],
                        [30.519921875000023, -1.0625],
                        [30.50996093750001, -1.067285156250009],
                    ],
                ],
            },
            properties: {
                name: "乌干达",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [32.01220703124997, 46.20390624999999],
                            [32.15009765625004, 46.1546875],
                            [31.56386718750005, 46.25776367187504],
                            [31.50878906250003, 46.373144531250006],
                            [32.01220703124997, 46.20390624999999],
                        ],
                    ],
                    [
                        [
                            [38.21435546875003, 47.091455078124966],
                            [37.54335937499999, 47.07456054687498],
                            [36.794824218749994, 46.71440429687499],
                            [36.55878906250004, 46.76269531250006],
                            [35.82714843749997, 46.62431640625002],
                            [35.01455078125005, 46.10600585937502],
                            [35.280175781249994, 46.27949218750001],
                            [35.23037109375005, 46.440625],
                            [34.84960937500003, 46.189892578124955],
                            [35.02285156250005, 45.70097656250002],
                            [35.45751953124997, 45.316308593749994],
                            [36.170507812500006, 45.453076171874955],
                            [36.575, 45.3935546875],
                            [36.39335937500002, 45.06538085937501],
                            [35.87011718750003, 45.005322265624955],
                            [35.472558593749994, 45.098486328125006],
                            [35.08769531250002, 44.802636718749966],
                            [34.46992187500004, 44.7216796875],
                            [33.909960937500074, 44.387597656249966],
                            [33.45068359374997, 44.553662109374955],
                            [33.55517578125003, 45.09765625000003],
                            [32.5080078125001, 45.40380859375006],
                            [33.664843750000074, 45.94707031249996],
                            [33.59414062500005, 46.09624023437499],
                            [33.42988281250004, 46.05761718750003],
                            [33.20224609375006, 46.17573242187501],
                            [32.47675781250004, 46.08369140625001],
                            [31.83125, 46.28168945312501],
                            [32.00849609375004, 46.42998046875002],
                            [31.554882812500097, 46.554296875000034],
                            [32.36132812499997, 46.474951171875034],
                            [32.578027343749994, 46.615625],
                            [32.04433593750005, 46.642480468749966],
                            [31.75917968750005, 47.21284179687501],
                            [31.872851562500017, 46.649755859375034],
                            [31.532128906249994, 46.66474609374998],
                            [31.56337890625005, 46.77729492187501],
                            [31.402929687500063, 46.62880859375002],
                            [30.796289062499994, 46.55200195312503],
                            [30.219042968750074, 45.866748046875045],
                            [29.62841796875003, 45.722460937500045],
                            [29.705859375000074, 45.25991210937505],
                            [29.567675781250074, 45.37080078124998],
                            [29.40371093750005, 45.419677734375],
                            [29.22353515625005, 45.402929687500034],
                            [28.894335937500017, 45.28994140625002],
                            [28.78173828125, 45.30986328125002],
                            [28.76660156250003, 45.28623046874998],
                            [28.78828125000001, 45.240966796875],
                            [28.451269531250006, 45.292187499999955],
                            [28.317675781250045, 45.347119140624955],
                            [28.2125, 45.45043945312506],
                            [28.26484375000004, 45.48388671875003],
                            [28.310351562500074, 45.49858398437499],
                            [28.499023437500057, 45.517724609374994],
                            [28.513769531250034, 45.57241210937502],
                            [28.49160156250005, 45.66577148437503],
                            [28.562304687500074, 45.73579101562501],
                            [28.667578125, 45.79384765625002],
                            [28.729296875000074, 45.852001953124955],
                            [28.73876953125003, 45.937158203124994],
                            [28.84951171875005, 45.97866210937502],
                            [28.94775390624997, 46.049951171874966],
                            [28.971875, 46.12763671874998],
                            [29.00625, 46.17646484374998],
                            [28.94375, 46.28842773437506],
                            [28.930566406250023, 46.36225585937501],
                            [28.92744140625001, 46.42412109374999],
                            [28.958398437500023, 46.45849609374997],
                            [29.146289062500017, 46.52690429687496],
                            [29.186230468750068, 46.52397460937499],
                            [29.20078125, 46.504980468750034],
                            [29.20458984374997, 46.37934570312501],
                            [29.223828125000097, 46.37695312499997],
                            [29.458789062500017, 46.453759765624994],
                            [29.83789062499997, 46.35053710937501],
                            [29.878027343750063, 46.360205078125034],
                            [30.07568359375003, 46.377832031249966],
                            [30.131054687500097, 46.42309570312506],
                            [29.92431640624997, 46.53886718750002],
                            [29.934765625000097, 46.625],
                            [29.942480468750063, 46.72377929687502],
                            [29.918066406250063, 46.78242187499998],
                            [29.877832031249994, 46.828906250000045],
                            [29.57197265625004, 46.96401367187502],
                            [29.455664062500006, 47.292626953124994],
                            [29.134863281250006, 47.48969726562501],
                            [29.125390625000023, 47.96455078125001],
                            [28.42304687500001, 48.146875],
                            [28.34052734375001, 48.144433593749994],
                            [27.54921875000008, 48.47773437500004],
                            [27.22851562500003, 48.37143554687506],
                            [26.90058593750001, 48.37192382812506],
                            [26.847070312500023, 48.387158203124955],
                            [26.640429687500045, 48.29414062500001],
                            [26.618945312500017, 48.25986328125006],
                            [26.4423828125, 48.22998046875],
                            [26.162695312500063, 47.992529296875034],
                            [25.90869140625, 47.96757812500002],
                            [25.689257812500045, 47.93247070312506],
                            [25.46425781250005, 47.910791015624994],
                            [24.979101562500063, 47.72412109374997],
                            [24.578906250000074, 47.93105468750005],
                            [23.628710937500017, 47.995849609375],
                            [23.40820312500003, 47.98999023437506],
                            [23.20263671875, 48.084521484375045],
                            [23.13945312499999, 48.08740234375],
                            [22.87666015625001, 47.94726562500006],
                            [22.769140625000063, 48.109619140625],
                            [22.582421875000023, 48.134033203125],
                            [22.253710937500017, 48.407373046874994],
                            [22.131835937500057, 48.40532226562502],
                            [22.142871093750017, 48.568505859374966],
                            [22.295214843750045, 48.68583984374999],
                            [22.389453125000045, 48.87348632812501],
                            [22.52412109375004, 49.03139648437502],
                            [22.538671875, 49.07270507812501],
                            [22.847070312500023, 49.08125],
                            [22.705664062500006, 49.17119140624999],
                            [22.6494140625, 49.53901367187498],
                            [22.706152343750006, 49.60620117187497],
                            [23.03632812500004, 49.899072265624966],
                            [23.711718750000045, 50.377343749999966],
                            [23.97265625, 50.410058593749966],
                            [24.089941406250006, 50.53046874999998],
                            [24.0947265625, 50.617041015625034],
                            [23.9970703125, 50.809375],
                            [24.095800781250063, 50.87275390625001],
                            [23.664453125000023, 51.31005859375],
                            [23.61376953125, 51.525390625],
                            [23.706835937500045, 51.64130859374998],
                            [23.79169921875001, 51.63710937500002],
                            [23.864257812500057, 51.62397460937501],
                            [23.951171875, 51.58505859374998],
                            [23.978320312500017, 51.59130859375003],
                            [24.12685546875008, 51.664648437500034],
                            [24.280078125000017, 51.77470703124999],
                            [24.361914062500006, 51.86752929687498],
                            [25.785742187500006, 51.923828125],
                            [26.77343750000003, 51.77070312499998],
                            [26.952832031249983, 51.754003906250034],
                            [27.074121093750023, 51.760839843750006],
                            [27.14199218750008, 51.75205078124998],
                            [27.29628906250008, 51.59741210937503],
                            [27.689746093750017, 51.572412109374994],
                            [27.7, 51.47797851562501],
                            [27.85859375000004, 51.59238281250006],
                            [28.532031250000017, 51.56245117187501],
                            [28.59902343750008, 51.54262695312505],
                            [28.647753906250074, 51.45654296875],
                            [28.690234375000017, 51.43886718750005],
                            [28.73125, 51.43339843749999],
                            [28.84951171875005, 51.540185546874994],
                            [28.927539062500045, 51.56215820312502],
                            [28.97773437500004, 51.57177734375003],
                            [29.01308593750005, 51.59892578124996],
                            [29.06074218750001, 51.625439453124955],
                            [29.102050781250057, 51.627539062500034],
                            [29.346484375000017, 51.38256835937503],
                            [30.160742187500006, 51.477880859375006],
                            [30.449511718750017, 51.274316406249994],
                            [30.63251953125004, 51.35541992187501],
                            [30.61171875000005, 51.406347656250006],
                            [30.602343750000017, 51.47124023437499],
                            [30.56074218750004, 51.531494140625],
                            [30.533007812500017, 51.596337890624966],
                            [30.583886718749994, 51.68896484375003],
                            [30.667285156250017, 51.81411132812502],
                            [30.755273437499994, 51.89516601562502],
                            [30.84570312500003, 51.95307617187501],
                            [30.980664062500097, 52.04619140624996],
                            [31.217968750000097, 52.05024414062498],
                            [31.345996093750074, 52.10537109375002],
                            [31.57373046875003, 52.108105468749955],
                            [31.763378906250097, 52.10107421875003],
                            [32.12226562500004, 52.05058593749996],
                            [32.435449218749994, 52.307226562500034],
                            [33.735253906249994, 52.344775390625045],
                            [34.397851562499994, 51.780419921874994],
                            [34.12109375000003, 51.67915039062498],
                            [34.21386718750003, 51.25537109375006],
                            [35.0640625, 51.203417968750045],
                            [35.31191406250005, 51.043896484374955],
                            [35.59111328125002, 50.36875],
                            [36.1164062500001, 50.408544921875006],
                            [36.619433593750074, 50.209228515625],
                            [37.42285156249997, 50.411474609375006],
                            [38.046875, 49.92001953125006],
                            [38.258593750000074, 50.05234375],
                            [38.91835937499999, 49.82470703125],
                            [39.17480468750003, 49.85595703124997],
                            [39.780566406250074, 49.57202148437503],
                            [40.080664062500006, 49.576855468749955],
                            [40.10878906250005, 49.251562500000034],
                            [39.68652343749997, 49.007910156250034],
                            [40.00361328125004, 48.82207031250002],
                            [39.792871093749994, 48.807714843750034],
                            [39.6447265625001, 48.591210937499966],
                            [39.8356445312501, 48.54277343749996],
                            [39.95791015625005, 48.268896484375034],
                            [39.77871093750005, 47.88754882812506],
                            [38.90029296875005, 47.85512695312502],
                            [38.36884765625004, 47.609960937500006],
                            [38.21435546875003, 47.091455078124966],
                        ],
                    ],
                ],
            },
            properties: {
                name: "乌克兰",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-57.81059570312499, -30.85859375000001],
                        [-57.872509765625, -30.59101562500001],
                        [-57.831201171874994, -30.495214843750006],
                        [-57.71269531249999, -30.38447265625001],
                        [-57.65087890625, -30.295019531250006],
                        [-57.645751953125, -30.226953125],
                        [-57.60888671875, -30.187792968750003],
                        [-57.55229492187499, -30.26123046875],
                        [-57.21445312499999, -30.28339843750001],
                        [-57.186914062499994, -30.26484375000001],
                        [-57.120507812499994, -30.14443359375001],
                        [-56.83271484375, -30.107226562500003],
                        [-56.4072265625, -30.44746093750001],
                        [-55.998974609375, -30.837207031250003],
                        [-56.018457031249994, -30.99189453125001],
                        [-56.00468749999999, -31.079199218750006],
                        [-55.873681640624994, -31.069628906250003],
                        [-55.6271484375, -30.85810546875001],
                        [-55.60302734375, -30.85078125000001],
                        [-55.55732421875, -30.8759765625],
                        [-55.17353515625, -31.279589843750003],
                        [-55.09116210937499, -31.31396484375],
                        [-55.036035156249994, -31.27900390625001],
                        [-54.587646484375, -31.48515625],
                        [-54.22055664062499, -31.85517578125001],
                        [-53.76171875, -32.05683593750001],
                        [-53.601708984374994, -32.40302734375001],
                        [-53.12558593749999, -32.73671875],
                        [-53.2140625, -32.82109375],
                        [-53.31010742187499, -32.92705078125],
                        [-53.39521484375, -33.010351562500006],
                        [-53.482861328125, -33.06855468750001],
                        [-53.511865234374994, -33.10869140625],
                        [-53.53134765624999, -33.1708984375],
                        [-53.53134765624999, -33.65546875000001],
                        [-53.37060546875, -33.7421875],
                        [-53.419580078124994, -33.77919921875001],
                        [-53.47246093749999, -33.84931640625001],
                        [-53.53452148437499, -34.01748046875001],
                        [-53.742919921875, -34.24951171875],
                        [-53.785302734374994, -34.38037109375],
                        [-54.16855468749999, -34.670703125],
                        [-54.902294921875, -34.93281250000001],
                        [-55.67314453124999, -34.77568359375],
                        [-56.249951171875, -34.90126953125001],
                        [-57.17070312499999, -34.45234375000001],
                        [-57.8291015625, -34.47734375],
                        [-58.40019531249999, -33.91240234375],
                        [-58.363525390625, -33.18232421875001],
                        [-58.08232421874999, -32.893652343750006],
                        [-58.12958984375, -32.75722656250001],
                        [-58.16220703124999, -32.566503906250006],
                        [-58.201171875, -32.4716796875],
                        [-58.123046875, -32.321875],
                        [-58.11972656249999, -32.24892578125001],
                        [-58.164794921875, -32.18486328125],
                        [-58.177001953125, -32.11904296875001],
                        [-58.15634765624999, -32.0515625],
                        [-58.160400390625, -31.98652343750001],
                        [-58.18901367187499, -31.92421875],
                        [-58.16748046875, -31.87265625],
                        [-58.04233398437499, -31.76923828125001],
                        [-58.006982421874994, -31.68496093750001],
                        [-58.053857421874994, -31.494921875],
                        [-58.0333984375, -31.416601562500006],
                        [-57.89335937499999, -31.1953125],
                        [-57.868408203125, -31.10439453125001],
                        [-57.88632812499999, -30.93740234375001],
                        [-57.81059570312499, -30.85859375000001],
                    ],
                ],
            },
            properties: {
                name: "乌拉圭",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-155.58134765624996, 19.012011718750017],
                            [-155.88129882812495, 19.07050781250001],
                            [-156.04868164062498, 19.749951171874983],
                            [-155.82031249999997, 20.01416015624997],
                            [-155.83164062499998, 20.27583007812501],
                            [-155.198779296875, 19.99438476562503],
                            [-154.80419921875, 19.524462890625045],
                            [-155.58134765624996, 19.012011718750017],
                        ],
                    ],
                    [
                        [
                            [-156.84960937499997, 20.772656249999955],
                            [-156.97338867187497, 20.757519531249983],
                            [-157.0505859375, 20.912451171875034],
                            [-156.88056640624995, 20.904833984375074],
                            [-156.84960937499997, 20.772656249999955],
                        ],
                    ],
                    [
                        [
                            [-156.48681640624994, 20.93256835937504],
                            [-156.27753906250004, 20.951269531250034],
                            [-155.98984374999998, 20.75712890624999],
                            [-156.40878906249998, 20.60517578125004],
                            [-156.480078125, 20.80122070312501],
                            [-156.69775390625003, 20.949072265625034],
                            [-156.58540039062495, 21.034326171874994],
                            [-156.48681640624994, 20.93256835937504],
                        ],
                    ],
                    [
                        [
                            [-157.21362304687497, 21.215380859375017],
                            [-156.71215820312506, 21.155078125000074],
                            [-156.85986328125, 21.05634765625004],
                            [-157.29033203124996, 21.112597656250017],
                            [-157.21362304687497, 21.215380859375017],
                        ],
                    ],
                    [
                        [
                            [-157.79936523437502, 21.456640625000034],
                            [-157.63540039062502, 21.30761718749997],
                            [-158.11035156249994, 21.318603515625],
                            [-158.27314453125, 21.585253906250045],
                            [-157.9625, 21.701367187499983],
                            [-157.79936523437502, 21.456640625000034],
                        ],
                    ],
                    [
                        [
                            [-159.37275390625, 21.93237304687497],
                            [-159.60883789062495, 21.909521484375034],
                            [-159.78916015625003, 22.041796875000074],
                            [-159.57919921874998, 22.22314453124997],
                            [-159.35205078124997, 22.219580078125034],
                            [-159.37275390625, 21.93237304687497],
                        ],
                    ],
                    [
                        [
                            [-81.04418945312503, 24.716796875000057],
                            [-81.137353515625, 24.710498046875017],
                            [-80.93046875, 24.75947265625004],
                            [-81.04418945312503, 24.716796875000057],
                        ],
                    ],
                    [
                        [
                            [-80.3818359375, 25.142285156249955],
                            [-80.58056640624997, 24.954248046875023],
                            [-80.25708007812497, 25.34760742187504],
                            [-80.3818359375, 25.142285156249955],
                        ],
                    ],
                    [
                        [
                            [-97.17070312499996, 26.159375],
                            [-97.40209960937494, 26.820507812499983],
                            [-97.38598632812494, 27.19648437500004],
                            [-97.17070312499996, 26.159375],
                        ],
                    ],
                    [
                        [
                            [-80.18676757812497, 27.278417968750034],
                            [-80.17050781250003, 27.20478515625004],
                            [-80.43691406249994, 27.850537109374955],
                            [-80.18676757812497, 27.278417968750034],
                        ],
                    ],
                    [
                        [
                            [-91.793701171875, 29.50073242187497],
                            [-92.00664062499996, 29.61030273437501],
                            [-91.875244140625, 29.640966796875034],
                            [-91.793701171875, 29.50073242187497],
                        ],
                    ],
                    [
                        [
                            [-84.90791015624998, 29.642626953125017],
                            [-85.11674804687499, 29.63281249999997],
                            [-84.737158203125, 29.732421875],
                            [-84.90791015624998, 29.642626953125017],
                        ],
                    ],
                    [
                        [
                            [-89.22397460937498, 30.084082031249977],
                            [-89.34199218749995, 30.062841796875006],
                            [-89.18466796874995, 30.168652343749983],
                            [-89.22397460937498, 30.084082031249977],
                        ],
                    ],
                    [
                        [
                            [-118.34794921875002, 33.3857421875],
                            [-118.29746093750003, 33.312109375],
                            [-118.44628906249997, 33.317089843749955],
                            [-118.56943359375002, 33.46416015624999],
                            [-118.34794921875002, 33.3857421875],
                        ],
                    ],
                    [
                        [
                            [-120.04355468749995, 33.918847656249994],
                            [-120.25190429687494, 34.01386718749998],
                            [-120.07182617187493, 34.026513671874966],
                            [-120.04355468749995, 33.918847656249994],
                        ],
                    ],
                    [
                        [
                            [-119.88237304687497, 34.07968749999998],
                            [-119.54926757812497, 34.02817382812506],
                            [-119.80957031249997, 33.9677734375],
                            [-119.88237304687497, 34.07968749999998],
                        ],
                    ],
                    [
                        [
                            [-75.54414062499995, 35.240087890625034],
                            [-75.69008789062502, 35.221582031249994],
                            [-75.53637695312497, 35.27861328124999],
                            [-75.50351562500003, 35.769140625],
                            [-75.46474609374994, 35.448632812499966],
                            [-75.54414062499995, 35.240087890625034],
                        ],
                    ],
                    [
                        [
                            [-74.13320312500002, 39.680761718750034],
                            [-74.25048828125, 39.529394531250006],
                            [-74.10673828124996, 39.74643554687498],
                            [-74.13320312500002, 39.680761718750034],
                        ],
                    ],
                    [
                        [
                            [-72.50976562500003, 40.98603515625001],
                            [-72.58085937499996, 40.92133789062498],
                            [-71.90322265625, 41.06069335937505],
                            [-73.19428710937495, 40.654199218749994],
                            [-74.01489257812497, 40.581201171874966],
                            [-73.87924804687498, 40.79165039062502],
                            [-73.573828125, 40.91962890624998],
                            [-72.62509765624998, 40.99184570312505],
                            [-72.27412109374998, 41.15302734375001],
                            [-72.50976562500003, 40.98603515625001],
                        ],
                    ],
                    [
                        [
                            [-69.9779296875, 41.26557617187504],
                            [-70.23305664062502, 41.28632812500001],
                            [-70.04121093750001, 41.3974609375],
                            [-69.9779296875, 41.26557617187504],
                        ],
                    ],
                    [
                        [
                            [-70.50991210937502, 41.376318359375034],
                            [-70.82919921874995, 41.35898437500006],
                            [-70.61601562499996, 41.45722656250001],
                            [-70.50991210937502, 41.376318359375034],
                        ],
                    ],
                    [
                        [
                            [-71.24140625000001, 41.49194335937497],
                            [-71.34624023437496, 41.469384765624994],
                            [-71.23203124999995, 41.654296875],
                            [-71.24140625000001, 41.49194335937497],
                        ],
                    ],
                    [
                        [
                            [-68.18725585937497, 44.33247070312501],
                            [-68.41171875000003, 44.294335937499966],
                            [-68.29941406249998, 44.456494140624955],
                            [-68.18725585937497, 44.33247070312501],
                        ],
                    ],
                    [
                        [
                            [-122.394140625, 47.39526367187503],
                            [-122.50991210937497, 47.358007812500006],
                            [-122.486474609375, 47.48876953125],
                            [-122.394140625, 47.39526367187503],
                        ],
                    ],
                    [
                        [
                            [-122.57275390624999, 48.15664062499999],
                            [-122.38315429687499, 47.923193359375034],
                            [-122.74150390624999, 48.22529296875004],
                            [-122.62861328125, 48.38422851562498],
                            [-122.54243164062503, 48.29399414062499],
                            [-122.69702148437499, 48.228662109374994],
                            [-122.57275390624999, 48.15664062499999],
                        ],
                    ],
                    [
                        [
                            [-94.80346679687497, 49.0029296875],
                            [-94.71279296874997, 48.863427734374994],
                            [-94.62089843749999, 48.74262695312501],
                            [-93.85161132812496, 48.607275390625034],
                            [-93.70771484374995, 48.52543945312499],
                            [-93.37788085937498, 48.61655273437498],
                            [-93.25795898437497, 48.62885742187501],
                            [-92.83671875, 48.567773437499994],
                            [-92.50058593749995, 48.43535156250002],
                            [-92.41459960937493, 48.276611328125],
                            [-92.3484375, 48.276611328125],
                            [-92.00517578125002, 48.301855468750006],
                            [-91.38720703124997, 48.05854492187498],
                            [-91.04345703125003, 48.19370117187498],
                            [-90.84033203125003, 48.20053710937506],
                            [-90.79731445312495, 48.13105468750001],
                            [-89.4556640625, 47.996240234374994],
                            [-88.37817382812497, 48.30307617187498],
                            [-87.74389648437497, 48.06054687500003],
                            [-87.20800781249997, 47.848486328125006],
                            [-86.67216796874996, 47.636425781249955],
                            [-85.65224609375, 47.21997070312503],
                            [-85.07006835937497, 46.97993164062498],
                            [-84.87597656249994, 46.89990234375003],
                            [-84.66577148437503, 46.54326171875002],
                            [-84.44047851562496, 46.49814453125006],
                            [-84.12319335937497, 46.50292968749997],
                            [-83.97778320312503, 46.08491210937498],
                            [-83.61596679687503, 46.116845703124994],
                            [-83.46948242187503, 45.99467773437499],
                            [-83.59267578125, 45.81713867187506],
                            [-82.91933593749994, 45.51796875000002],
                            [-82.55107421874996, 45.34736328125001],
                            [-82.48505859374993, 45.08374023437503],
                            [-82.137841796875, 43.570898437500034],
                            [-82.19038085937495, 43.47407226562501],
                            [-82.54531249999997, 42.62470703124998],
                            [-83.10952148437497, 42.25068359375001],
                            [-83.141943359375, 41.97587890624996],
                            [-82.69003906249995, 41.675195312499994],
                            [-82.43906249999998, 41.67485351562502],
                            [-81.97416992187496, 41.88872070312499],
                            [-81.50732421874997, 42.10346679687504],
                            [-81.02822265624997, 42.247167968750006],
                            [-80.24755859375, 42.36601562499996],
                            [-79.17373046875, 42.74853515625],
                            [-78.91508789062496, 42.90913085937504],
                            [-78.98076171874993, 42.98061523437502],
                            [-79.02617187499996, 43.01733398437506],
                            [-79.066064453125, 43.10610351562502],
                            [-79.171875, 43.466552734375],
                            [-79.00249023437502, 43.52714843749999],
                            [-78.845556640625, 43.58334960937498],
                            [-78.72041015625001, 43.62495117187501],
                            [-78.45825195312497, 43.63149414062502],
                            [-77.596533203125, 43.62861328124998],
                            [-76.819970703125, 43.62880859375002],
                            [-76.18579101562503, 44.24223632812502],
                            [-75.81933593749997, 44.468017578125],
                            [-75.40126953124997, 44.77226562499999],
                            [-74.99614257812496, 44.970117187499966],
                            [-74.76245117187494, 44.99907226562502],
                            [-74.663232421875, 45.00390625000003],
                            [-71.51752929687495, 45.00756835937497],
                            [-71.327294921875, 45.29008789062496],
                            [-70.86503906249999, 45.27070312500001],
                            [-70.296240234375, 45.90610351562506],
                            [-70.00771484375002, 46.70893554687501],
                            [-69.24287109374998, 47.46298828124998],
                            [-69.0501953125, 47.426611328125034],
                            [-68.93720703124998, 47.21123046875002],
                            [-68.23549804687502, 47.34594726562503],
                            [-67.806787109375, 47.08281249999999],
                            [-67.80224609374994, 45.7275390625],
                            [-67.43266601562496, 45.603125],
                            [-67.366943359375, 45.17377929687498],
                            [-67.12485351562498, 45.16943359375],
                            [-66.98701171874995, 44.82768554687502],
                            [-67.191259765625, 44.67558593750002],
                            [-67.83906249999998, 44.576269531250034],
                            [-68.056640625, 44.38432617187502],
                            [-68.15205078124998, 44.50200195312499],
                            [-68.45058593749997, 44.50761718749999],
                            [-68.53251953124996, 44.25864257812498],
                            [-68.81191406249994, 44.33935546875],
                            [-68.76269531249994, 44.57075195312498],
                            [-69.22607421875003, 43.98647460937505],
                            [-69.52075195312503, 43.89736328125002],
                            [-69.55668945312496, 43.982763671875006],
                            [-69.62392578125, 43.88061523437497],
                            [-69.65288085937493, 43.99389648437506],
                            [-69.808349609375, 43.772314453125034],
                            [-69.965234375, 43.855078125],
                            [-70.17880859374998, 43.76635742187506],
                            [-70.73310546875001, 43.07001953125004],
                            [-70.82905273437493, 42.82534179687502],
                            [-70.61293945312497, 42.623242187499955],
                            [-71.04619140624993, 42.331103515625045],
                            [-70.73828125, 42.228857421875006],
                            [-70.42666015625002, 41.75727539062501],
                            [-70.00141601562498, 41.82617187500003],
                            [-70.24106445312495, 42.09121093750002],
                            [-70.10893554687496, 42.07832031249998],
                            [-69.97788085937498, 41.961279296875006],
                            [-69.94863281249997, 41.67714843750005],
                            [-70.65712890625, 41.53422851562496],
                            [-70.70112304687498, 41.71484375],
                            [-71.1685546875, 41.489404296874994],
                            [-71.14873046874996, 41.74570312499998],
                            [-71.27109375, 41.68125],
                            [-71.39013671875003, 41.79531250000005],
                            [-71.52285156249997, 41.378955078125045],
                            [-72.92470703125002, 41.28515625000003],
                            [-73.98710937499999, 40.751367187499994],
                            [-73.87197265625, 41.05517578124997],
                            [-73.96992187499995, 41.24970703125001],
                            [-73.92719726562495, 40.914257812499955],
                            [-74.26420898437496, 40.52861328124999],
                            [-73.972265625, 40.40034179687498],
                            [-74.079931640625, 39.78813476562496],
                            [-74.06459960937497, 39.99311523437498],
                            [-74.79448242187499, 39.00190429687501],
                            [-74.95429687499995, 38.949951171875],
                            [-74.89702148437502, 39.14545898437504],
                            [-75.52421874999999, 39.49018554687501],
                            [-75.421875, 39.78969726562502],
                            [-75.07416992187495, 39.98349609375006],
                            [-75.40063476562503, 39.83159179687502],
                            [-75.58759765625001, 39.64077148437505],
                            [-75.3921875, 39.09277343750006],
                            [-75.08867187499999, 38.777539062499955],
                            [-75.18710937499995, 38.59111328124999],
                            [-75.03876953124993, 38.426367187500006],
                            [-75.934375, 37.15190429687496],
                            [-75.97504882812498, 37.3984375],
                            [-75.65927734374995, 37.953955078125034],
                            [-75.850830078125, 37.971582031249994],
                            [-75.85869140624999, 38.36206054687503],
                            [-76.05122070312495, 38.27954101562503],
                            [-76.2646484375, 38.436425781249994],
                            [-76.26416015625, 38.599951171875006],
                            [-76.016943359375, 38.62509765624998],
                            [-76.21298828124998, 38.75830078125003],
                            [-76.34116210937498, 38.70966796874998],
                            [-76.16816406249998, 38.85273437499998],
                            [-76.32958984375, 38.95278320312505],
                            [-76.13520507812493, 39.082128906250006],
                            [-76.23569335937498, 39.19160156250001],
                            [-76.153125, 39.315039062500034],
                            [-75.87597656249997, 39.3759765625],
                            [-76.003125, 39.41083984375001],
                            [-75.87294921874997, 39.510888671874966],
                            [-75.95893554687498, 39.58505859374998],
                            [-76.2763671875, 39.32275390625],
                            [-76.330810546875, 39.40390625],
                            [-76.42089843749997, 39.225],
                            [-76.57041015624995, 39.26933593749996],
                            [-76.42758789062498, 39.12602539062499],
                            [-76.55854492187493, 39.065234375000045],
                            [-76.39409179687502, 38.368994140625034],
                            [-76.67734374999998, 38.611962890624966],
                            [-76.66855468749998, 38.5375],
                            [-76.34116210937498, 38.08701171875006],
                            [-76.86811523437495, 38.39028320312502],
                            [-76.88974609375, 38.292089843750006],
                            [-77.00117187499995, 38.44526367187504],
                            [-77.23251953125, 38.40771484375003],
                            [-77.03037109374995, 38.88925781249998],
                            [-77.26040039062502, 38.6],
                            [-77.27324218749996, 38.35175781249998],
                            [-77.04677734375002, 38.356689453125],
                            [-76.26425781250003, 37.89355468749997],
                            [-76.34414062499997, 37.675683593749994],
                            [-76.49248046874999, 37.682226562500006],
                            [-77.11108398437497, 38.165673828124994],
                            [-76.54946289062494, 37.66914062500001],
                            [-76.30556640625, 37.57148437500001],
                            [-76.26347656249996, 37.35703125],
                            [-76.40097656249998, 37.386132812499994],
                            [-76.45390624999993, 37.27353515625006],
                            [-76.75771484375002, 37.50541992187496],
                            [-76.28330078125, 37.05268554687501],
                            [-76.40087890624997, 36.991308593750034],
                            [-76.63090820312493, 37.22172851562499],
                            [-77.25087890624994, 37.329199218750034],
                            [-76.671875, 37.172949218750006],
                            [-76.48784179687502, 36.89702148437499],
                            [-75.99941406249997, 36.91264648437499],
                            [-75.53417968749997, 35.81909179687506],
                            [-75.94648437499995, 36.65908203125002],
                            [-75.99277343749995, 36.47377929687502],
                            [-75.82006835937494, 36.11284179687502],
                            [-76.14785156250002, 36.279296875],
                            [-76.15, 36.14575195312497],
                            [-76.27060546874998, 36.18989257812501],
                            [-76.22739257812498, 36.11601562499996],
                            [-76.559375, 36.015332031249955],
                            [-76.733642578125, 36.229150390624994],
                            [-76.726220703125, 35.957617187500034],
                            [-76.06977539062501, 35.970312500000034],
                            [-76.08359374999998, 35.69052734375006],
                            [-75.85390625, 35.96015625000001],
                            [-75.75883789062499, 35.84326171875],
                            [-75.77392578124997, 35.64697265624997],
                            [-76.17382812499997, 35.354150390624994],
                            [-76.489501953125, 35.397021484375045],
                            [-76.57719726562502, 35.53232421874998],
                            [-76.74140624999998, 35.431494140625034],
                            [-77.03999023437495, 35.527392578125045],
                            [-76.51293945312497, 35.270410156249994],
                            [-76.77915039062503, 34.990332031250034],
                            [-77.07026367187501, 35.154638671875034],
                            [-76.97495117187503, 35.025195312500045],
                            [-76.74497070312498, 34.94096679687502],
                            [-76.45673828124998, 34.989355468750034],
                            [-76.36220703125, 34.9365234375],
                            [-76.43979492187498, 34.84291992187502],
                            [-77.29624023437503, 34.602929687499994],
                            [-77.41225585937497, 34.730810546875034],
                            [-77.37978515625, 34.526611328125],
                            [-77.750732421875, 34.28496093749996],
                            [-77.92783203125, 33.93974609374999],
                            [-77.95327148437494, 34.16899414062496],
                            [-78.01333007812502, 33.91181640624998],
                            [-78.40585937499995, 33.91757812499998],
                            [-78.84145507812497, 33.72407226562501],
                            [-79.19379882812498, 33.24414062500003],
                            [-79.22646484375, 33.40488281249998],
                            [-79.27602539062497, 33.135400390624966],
                            [-79.80498046874999, 32.78740234374996],
                            [-79.93310546874997, 32.81005859375006],
                            [-79.94072265625002, 32.667138671874966],
                            [-80.36284179687496, 32.500732421875],
                            [-80.6341796875, 32.51171875000003],
                            [-80.474267578125, 32.42275390625002],
                            [-80.579345703125, 32.28730468750004],
                            [-80.80253906249999, 32.44804687500002],
                            [-80.69423828124997, 32.21572265625002],
                            [-81.11328124999997, 31.87861328125001],
                            [-81.06611328124995, 31.787988281250023],
                            [-81.259375, 31.538916015624977],
                            [-81.17543945312494, 31.531298828125017],
                            [-81.38095703124998, 31.353271484375],
                            [-81.28847656249997, 31.263916015625],
                            [-81.441748046875, 31.19970703124997],
                            [-81.5162109375, 30.801806640625017],
                            [-81.24951171875003, 29.793798828125006],
                            [-80.52412109374995, 28.48608398437503],
                            [-80.5849609375, 28.271582031250034],
                            [-80.456884765625, 27.90068359374996],
                            [-80.61000976562494, 28.177587890624977],
                            [-80.60693359375003, 28.522900390624983],
                            [-80.693505859375, 28.34497070312497],
                            [-80.68847656250003, 28.578515625000023],
                            [-80.83818359374999, 28.757666015625034],
                            [-80.74863281250003, 28.381005859375023],
                            [-80.050048828125, 26.807714843750063],
                            [-80.1263671875, 25.83349609375],
                            [-80.48466796874999, 25.229833984375034],
                            [-81.11049804687494, 25.138037109374977],
                            [-81.13603515624999, 25.309667968750034],
                            [-80.94042968750003, 25.264208984375017],
                            [-81.11333007812499, 25.367236328125045],
                            [-81.36494140625001, 25.83105468750003],
                            [-81.715478515625, 25.98315429687503],
                            [-81.95893554687495, 26.489941406249983],
                            [-81.82866210937496, 26.68706054687499],
                            [-82.03959960937496, 26.552050781250017],
                            [-82.01328125, 26.96157226562505],
                            [-82.24287109374998, 26.848876953125],
                            [-82.44135742187501, 27.059667968750034],
                            [-82.71459960937497, 27.499609375000063],
                            [-82.40576171874994, 27.862890624999977],
                            [-82.67519531249994, 27.963769531250023],
                            [-82.61098632812502, 27.77724609375005],
                            [-82.74287109374995, 27.709375],
                            [-82.84350585937494, 27.845996093750017],
                            [-82.65146484375, 28.8875],
                            [-83.69438476562502, 29.92597656250001],
                            [-84.04423828124996, 30.10380859374999],
                            [-84.30966796874995, 30.064746093750045],
                            [-84.38281250000003, 29.90737304687505],
                            [-85.31894531249995, 29.680224609375045],
                            [-85.413818359375, 29.76757812499997],
                            [-85.413818359375, 29.842480468749955],
                            [-85.31489257812493, 29.758105468750017],
                            [-85.35361328125, 29.875732421875],
                            [-85.67578125, 30.121923828125063],
                            [-85.60351562500003, 30.286767578124966],
                            [-85.75581054687495, 30.1669921875],
                            [-86.454443359375, 30.39912109375004],
                            [-86.12382812499999, 30.40581054687499],
                            [-86.25737304687502, 30.493017578124977],
                            [-87.201171875, 30.339257812499994],
                            [-86.98579101562498, 30.43085937500001],
                            [-86.99755859375, 30.5703125],
                            [-87.17060546874998, 30.538769531249983],
                            [-87.28105468750002, 30.339257812499994],
                            [-87.47578124999998, 30.294287109375006],
                            [-87.44829101562499, 30.394140625],
                            [-87.62226562499998, 30.264746093750006],
                            [-88.00595703124998, 30.230908203124955],
                            [-87.79028320312503, 30.291796875000017],
                            [-88.011328125, 30.694189453125006],
                            [-88.13544921874998, 30.366601562499994],
                            [-88.90522460937495, 30.415136718750006],
                            [-89.32055664062503, 30.3453125],
                            [-89.58847656249998, 30.165966796874955],
                            [-90.12597656249997, 30.369091796874955],
                            [-90.33198242187493, 30.277587890625057],
                            [-90.41303710937501, 30.140332031249983],
                            [-90.17534179687499, 30.02910156249996],
                            [-89.73745117187497, 30.171972656250034],
                            [-89.66503906249994, 30.117041015625034],
                            [-89.81518554687497, 30.007275390624955],
                            [-89.631689453125, 29.90380859375003],
                            [-89.400732421875, 30.04604492187505],
                            [-89.35444335937501, 29.82021484375005],
                            [-89.72089843749995, 29.619287109374966],
                            [-89.01572265625, 29.202880859375057],
                            [-89.15551757812497, 29.01660156250003],
                            [-89.23608398437494, 29.081103515625017],
                            [-89.37612304687497, 28.981347656250023],
                            [-89.44316406249996, 29.194140625000045],
                            [-90.15908203124997, 29.537158203125017],
                            [-90.05278320312499, 29.336816406249966],
                            [-90.21279296875, 29.104931640624983],
                            [-90.37919921874996, 29.29511718750001],
                            [-90.75102539062496, 29.13085937500003],
                            [-91.29013671875, 29.288964843749994],
                            [-91.15078124999994, 29.317919921875045],
                            [-91.24882812499993, 29.56420898437503],
                            [-91.51420898437499, 29.55537109375001],
                            [-91.8931640625, 29.836035156249977],
                            [-92.135498046875, 29.699462890625057],
                            [-92.08403320312499, 29.59282226562499],
                            [-92.26083984374995, 29.55683593750004],
                            [-93.17568359375, 29.778955078124994],
                            [-93.82646484374999, 29.725146484375045],
                            [-93.84145507812502, 29.97973632812503],
                            [-93.89047851562495, 29.689355468750023],
                            [-94.759619140625, 29.384277343750057],
                            [-94.52626953125, 29.547949218750006],
                            [-94.77827148437498, 29.54785156249997],
                            [-94.74194335937497, 29.75],
                            [-95.0228515625, 29.70234375000001],
                            [-94.88828125000003, 29.37055664062501],
                            [-95.27348632812499, 28.96386718750003],
                            [-96.23452148437502, 28.488964843749983],
                            [-96.01103515624996, 28.631933593749977],
                            [-96.44873046874997, 28.594482421875],
                            [-96.64003906249994, 28.708789062500017],
                            [-96.42109374999993, 28.457324218750045],
                            [-96.67636718749998, 28.34130859375003],
                            [-96.77353515624998, 28.421630859375057],
                            [-96.839501953125, 28.194384765625017],
                            [-97.156494140625, 28.144335937500045],
                            [-97.141259765625, 28.060742187499983],
                            [-97.034326171875, 28.093847656250063],
                            [-97.07309570312498, 27.98608398437503],
                            [-97.43149414062498, 27.83720703124999],
                            [-97.28872070312494, 27.670605468749983],
                            [-97.43911132812502, 27.328271484374966],
                            [-97.76845703124997, 27.45751953125],
                            [-97.69238281250003, 27.287158203125017],
                            [-97.48510742187497, 27.237402343750006],
                            [-97.55468749999994, 26.96733398437496],
                            [-97.43505859375, 26.48583984375003],
                            [-97.14624023437494, 25.961474609375045],
                            [-97.37563476562497, 25.871826171875],
                            [-99.10776367187498, 26.446923828124994],
                            [-99.45654296874999, 27.05668945312496],
                            [-99.50532226562497, 27.54833984375003],
                            [-100.29604492187495, 28.32768554687499],
                            [-100.75458984375001, 29.182519531249994],
                            [-101.44038085937503, 29.77685546875],
                            [-102.26894531249998, 29.871191406250034],
                            [-102.61494140624994, 29.75234375],
                            [-102.8919921875, 29.216406250000034],
                            [-103.16831054687498, 28.998193359374994],
                            [-104.110595703125, 29.386132812499994],
                            [-104.50400390624995, 29.677685546874955],
                            [-104.97880859374996, 30.645947265624955],
                            [-106.14804687499995, 31.450927734375],
                            [-106.44541015624996, 31.768408203125006],
                            [-108.21181640625002, 31.779345703125017],
                            [-108.21445312499993, 31.329443359375034],
                            [-111.0419921875, 31.32421875000003],
                            [-114.83593749999994, 32.50830078125003],
                            [-114.72475585937495, 32.71533203125003],
                            [-117.12827148437495, 32.533349609374994],
                            [-117.46743164062495, 33.295507812500006],
                            [-118.08051757812497, 33.72216796874997],
                            [-118.41044921874996, 33.74394531249996],
                            [-118.506201171875, 34.01738281249999],
                            [-119.14375, 34.11201171874998],
                            [-119.60605468749999, 34.41801757812499],
                            [-120.48120117187503, 34.47163085937498],
                            [-120.64467773437502, 34.57998046875002],
                            [-120.65908203124994, 35.122412109375034],
                            [-120.85737304687501, 35.209667968749955],
                            [-120.899609375, 35.42509765624999],
                            [-121.28383789062494, 35.67631835937499],
                            [-121.87739257812498, 36.33105468749997],
                            [-121.80742187499995, 36.851220703124994],
                            [-122.394921875, 37.20751953125003],
                            [-122.49921875000001, 37.542626953124994],
                            [-122.44560546875002, 37.797998046874966],
                            [-122.07050781249998, 37.47827148437503],
                            [-122.38544921875001, 37.960595703124966],
                            [-122.31425781249999, 38.00732421874997],
                            [-121.52534179687503, 38.05590820312503],
                            [-122.39335937499995, 38.14482421875002],
                            [-122.52133789062499, 37.82641601562497],
                            [-122.93198242187498, 38.05546875000002],
                            [-122.998779296875, 37.98862304687498],
                            [-122.90815429687501, 38.19658203124999],
                            [-123.701123046875, 38.90727539062502],
                            [-123.83291015624994, 39.775488281250034],
                            [-124.35654296875003, 40.37109374999997],
                            [-124.07192382812497, 41.45952148437502],
                            [-124.53964843750003, 42.812890624999966],
                            [-124.14873046874997, 43.691748046875034],
                            [-123.92934570312495, 45.57695312499996],
                            [-123.989306640625, 46.21938476562502],
                            [-123.22060546874998, 46.153613281250045],
                            [-123.46484375, 46.27109374999998],
                            [-124.07275390624996, 46.279443359374994],
                            [-124.04433593750002, 46.605078125],
                            [-123.946142578125, 46.43256835937501],
                            [-123.88916015625003, 46.660009765625006],
                            [-124.11254882812497, 46.862695312499994],
                            [-123.84287109375002, 46.963183593750045],
                            [-124.11171875, 47.03520507812496],
                            [-124.1392578125, 46.95468749999998],
                            [-124.376025390625, 47.658642578124955],
                            [-124.66308593749996, 47.97412109375003],
                            [-124.7099609375, 48.38037109375],
                            [-123.97578125, 48.16845703125],
                            [-122.97387695312499, 48.07329101562496],
                            [-122.77861328125, 48.13759765625002],
                            [-122.65664062500002, 47.88115234374999],
                            [-122.77841796874996, 47.738427734374966],
                            [-122.82138671875, 47.79316406250001],
                            [-123.1390625, 47.386083984375034],
                            [-122.92216796874993, 47.40766601562498],
                            [-123.066796875, 47.39965820312506],
                            [-123.04863281249995, 47.479345703125034],
                            [-122.53281250000002, 47.919726562500045],
                            [-122.67548828124995, 47.612353515625045],
                            [-122.57788085937496, 47.29316406250001],
                            [-122.76777343750001, 47.21835937500006],
                            [-122.82846679687503, 47.336572265624994],
                            [-123.02758789062501, 47.13891601562503],
                            [-122.70195312500002, 47.11088867187502],
                            [-122.35380859374996, 47.37158203125],
                            [-122.40180664062497, 47.78427734374998],
                            [-122.24199218750002, 48.01074218750003],
                            [-122.5169921875, 48.15966796874997],
                            [-122.40854492187502, 48.29389648437498],
                            [-122.66899414062496, 48.465234374999966],
                            [-122.49677734374995, 48.50556640625001],
                            [-122.51274414062502, 48.66943359375],
                            [-122.56201171875001, 48.777978515624994],
                            [-122.68593749999995, 48.794287109375034],
                            [-122.72246093750002, 48.85302734375003],
                            [-122.78876953125003, 48.993017578125034],
                            [-121.40722656249994, 48.993017578125034],
                            [-119.70170898437495, 48.993017578125034],
                            [-119.27534179687494, 48.99306640625005],
                            [-118.84892578124993, 48.99306640625005],
                            [-117.99619140625002, 48.99306640625005],
                            [-116.71704101562501, 48.99306640625005],
                            [-110.74765625, 48.99306640625005],
                            [-104.77832031249997, 48.993115234374955],
                            [-98.80898437499995, 48.99316406249997],
                            [-97.52983398437493, 48.99316406249997],
                            [-96.67705078124993, 48.99316406249997],
                            [-96.25068359374993, 48.99316406249997],
                            [-95.39790039062493, 48.99316406249997],
                            [-95.16206054687493, 48.991748046875045],
                            [-95.15527343749997, 49.36967773437502],
                            [-94.85434570312495, 49.304589843749994],
                            [-94.86040039062493, 49.258593750000045],
                            [-94.80346679687497, 49.0029296875],
                        ],
                    ],
                    [
                        [
                            [-176.28671874999998, 51.79199218750006],
                            [-176.34965820312502, 51.733300781249994],
                            [-176.41372070312502, 51.840576171875],
                            [-176.28671874999998, 51.79199218750006],
                        ],
                    ],
                    [
                        [
                            [-177.87905273437502, 51.64970703125002],
                            [-178.05888671875, 51.67260742187497],
                            [-177.98637695312493, 51.76425781249998],
                            [-178.16826171874996, 51.90302734375001],
                            [-177.644482421875, 51.826269531250006],
                            [-177.87905273437502, 51.64970703125002],
                        ],
                    ],
                    [
                        [
                            [-177.14819335937497, 51.71674804687498],
                            [-177.67021484375002, 51.701074218749994],
                            [-177.11005859375, 51.92875976562502],
                            [-177.14819335937497, 51.71674804687498],
                        ],
                    ],
                    [
                        [
                            [-176.593310546875, 51.86669921875],
                            [-176.45234374999995, 51.735693359375034],
                            [-176.96162109374998, 51.60366210937505],
                            [-176.69833984374998, 51.986035156249955],
                            [-176.593310546875, 51.86669921875],
                        ],
                    ],
                    [
                        [
                            [179.72773437500015, 51.905419921874966],
                            [179.50390625000003, 51.97958984374998],
                            [179.6271484375001, 52.03041992187502],
                            [179.72773437500015, 51.905419921874966],
                        ],
                    ],
                    [
                        [
                            [177.4154296875, 51.88281249999997],
                            [177.25029296875013, 51.902929687500006],
                            [177.6696289062501, 52.10302734375],
                            [177.4154296875, 51.88281249999997],
                        ],
                    ],
                    [
                        [
                            [-173.5533203125, 52.13627929687502],
                            [-173.02290039062504, 52.07915039062502],
                            [-173.83579101562498, 52.048193359375006],
                            [-173.99248046874993, 52.12333984374996],
                            [-173.5533203125, 52.13627929687502],
                        ],
                    ],
                    [
                        [
                            [-172.464794921875, 52.27226562500002],
                            [-172.61982421874998, 52.27285156250005],
                            [-172.47041015625, 52.38803710937506],
                            [-172.31362304687497, 52.32958984375006],
                            [-172.464794921875, 52.27226562500002],
                        ],
                    ],
                    [
                        [
                            [-174.67739257812502, 52.035009765625006],
                            [-175.29555664062502, 52.022167968749955],
                            [-174.30615234375, 52.216162109375034],
                            [-174.43554687499997, 52.317236328125034],
                            [-174.168896484375, 52.42016601562503],
                            [-174.04560546875, 52.36723632812499],
                            [-174.12065429687493, 52.13520507812498],
                            [-174.67739257812502, 52.035009765625006],
                        ],
                    ],
                    [
                        [
                            [173.72275390625018, 52.35957031250004],
                            [173.40234375000009, 52.40478515625],
                            [173.77607421875004, 52.49511718750003],
                            [173.72275390625018, 52.35957031250004],
                        ],
                    ],
                    [
                        [
                            [172.81181640625002, 53.01298828125002],
                            [173.43603515625003, 52.85205078125],
                            [172.93515625000012, 52.752099609374966],
                            [172.49482421875004, 52.93789062499999],
                            [172.81181640625002, 53.01298828125002],
                        ],
                    ],
                    [
                        [
                            [-167.96435546875003, 53.345117187499994],
                            [-169.088916015625, 52.83203125],
                            [-168.68984375000002, 53.227246093749955],
                            [-168.38041992187496, 53.28344726562506],
                            [-168.28769531249998, 53.500146484374966],
                            [-167.82807617187495, 53.50795898437505],
                            [-167.96435546875003, 53.345117187499994],
                        ],
                    ],
                    [
                        [
                            [-166.61533203124998, 53.90092773437499],
                            [-166.37231445312494, 53.99897460937498],
                            [-166.230859375, 53.93261718750006],
                            [-166.54560546875, 53.726464843749966],
                            [-166.354541015625, 53.67353515625004],
                            [-166.85097656249997, 53.45288085937503],
                            [-167.78085937500003, 53.30024414062501],
                            [-167.13608398437503, 53.526464843750006],
                            [-167.01572265625003, 53.69838867187502],
                            [-166.80898437500002, 53.64614257812505],
                            [-166.741259765625, 53.71293945312496],
                            [-167.10561523437497, 53.813378906249994],
                            [-167.03808593749997, 53.9421875],
                            [-166.67329101562498, 54.00595703124998],
                            [-166.61533203124998, 53.90092773437499],
                        ],
                    ],
                    [
                        [
                            [-165.841552734375, 54.070654296875006],
                            [-166.05664062500003, 54.054345703124994],
                            [-166.08774414062498, 54.16914062500001],
                            [-165.89287109375, 54.20698242187498],
                            [-165.69287109375, 54.09990234375002],
                            [-165.841552734375, 54.070654296875006],
                        ],
                    ],
                    [
                        [
                            [-165.56113281249998, 54.13671874999997],
                            [-165.55063476562498, 54.28452148437498],
                            [-165.40786132812502, 54.19682617187496],
                            [-165.56113281249998, 54.13671874999997],
                        ],
                    ],
                    [
                        [
                            [-162.29814453124993, 54.847021484375006],
                            [-162.43388671875, 54.931542968749994],
                            [-162.26459960937504, 54.983496093750006],
                            [-162.29814453124993, 54.847021484375006],
                        ],
                    ],
                    [
                        [
                            [-163.476025390625, 54.98071289062497],
                            [-163.37895507812496, 54.81552734374998],
                            [-163.083251953125, 54.66899414062496],
                            [-163.35810546874995, 54.73569335937506],
                            [-164.82343749999998, 54.41909179687505],
                            [-164.887646484375, 54.60781250000002],
                            [-164.47861328124998, 54.906835937500006],
                            [-163.80712890624997, 55.04907226562503],
                            [-163.476025390625, 54.98071289062497],
                        ],
                    ],
                    [
                        [
                            [-159.51513671875, 55.15185546875003],
                            [-159.617724609375, 55.05732421875004],
                            [-159.54506835937497, 55.22597656250002],
                            [-159.51513671875, 55.15185546875003],
                        ],
                    ],
                    [
                        [
                            [-131.33974609375002, 55.079833984375],
                            [-131.32954101562498, 54.887744140625045],
                            [-131.592236328125, 55.02568359374999],
                            [-131.5654296875, 55.26411132812498],
                            [-131.33974609375002, 55.079833984375],
                        ],
                    ],
                    [
                        [
                            [-159.87299804687495, 55.128759765625034],
                            [-160.22705078124997, 54.92270507812506],
                            [-160.17207031249995, 55.123046875],
                            [-159.88735351562497, 55.27299804687502],
                            [-159.87299804687495, 55.128759765625034],
                        ],
                    ],
                    [
                        [
                            [-132.86225585937504, 54.894433593749966],
                            [-132.61723632812493, 54.892431640625006],
                            [-132.70581054687497, 54.684179687500034],
                            [-133.42905273437498, 55.30380859374998],
                            [-133.097412109375, 55.213720703125006],
                            [-132.86225585937504, 54.894433593749966],
                        ],
                    ],
                    [
                        [
                            [-160.329296875, 55.337695312500045],
                            [-160.34331054687493, 55.25878906250006],
                            [-160.51748046875, 55.33383789062506],
                            [-160.329296875, 55.337695312500045],
                        ],
                    ],
                    [
                        [
                            [-160.68491210937498, 55.314794921875006],
                            [-160.552783203125, 55.38076171875002],
                            [-160.48754882812503, 55.18486328124999],
                            [-160.79506835937497, 55.14521484375001],
                            [-160.72392578124993, 55.404638671875006],
                            [-160.68491210937498, 55.314794921875006],
                        ],
                    ],
                    [
                        [
                            [-133.30507812500002, 55.54375],
                            [-133.6501953125, 55.26928710937506],
                            [-133.73710937500002, 55.49692382812498],
                            [-133.30507812500002, 55.54375],
                        ],
                    ],
                    [
                        [
                            [-155.56601562500003, 55.82119140625005],
                            [-155.73735351562493, 55.82978515625001],
                            [-155.59394531250004, 55.92431640625],
                            [-155.56601562500003, 55.82119140625005],
                        ],
                    ],
                    [
                        [
                            [-130.97915039062502, 55.489160156249994],
                            [-131.187890625, 55.206298828125],
                            [-131.44755859374996, 55.40878906250006],
                            [-131.7625, 55.16582031250002],
                            [-131.84609374999997, 55.41625976562497],
                            [-131.62495117187504, 55.831689453124966],
                            [-131.26923828125004, 55.95537109375002],
                            [-130.997802734375, 55.727636718750006],
                            [-130.97915039062502, 55.489160156249994],
                        ],
                    ],
                    [
                        [
                            [-133.56611328125, 56.33920898437498],
                            [-133.202978515625, 56.31982421875003],
                            [-133.096630859375, 56.09003906250001],
                            [-132.59760742187504, 55.89501953125],
                            [-132.17270507812498, 55.48061523437502],
                            [-132.51127929687493, 55.59394531250001],
                            [-132.63129882812495, 55.47319335937502],
                            [-132.41787109375002, 55.48291015625006],
                            [-132.20668945312497, 55.22441406249996],
                            [-131.97641601562498, 55.208593750000034],
                            [-132.06474609375002, 54.713134765625],
                            [-133.11855468750002, 55.32763671875003],
                            [-132.95888671875002, 55.39555664062502],
                            [-133.0333984375, 55.589697265625034],
                            [-133.68017578124994, 55.78515625],
                            [-133.24150390624993, 55.920800781249994],
                            [-133.371240234375, 56.035888671875],
                            [-133.74252929687498, 55.96484375],
                            [-133.530859375, 56.145654296874966],
                            [-133.56611328125, 56.33920898437498],
                        ],
                    ],
                    [
                        [
                            [-132.77988281249998, 56.24726562499998],
                            [-133.03500976562498, 56.34091796875006],
                            [-132.90205078124998, 56.45375976562505],
                            [-132.62910156249995, 56.411914062500045],
                            [-132.77988281249998, 56.24726562499998],
                        ],
                    ],
                    [
                        [
                            [-132.11235351562493, 56.109375],
                            [-132.13295898437497, 55.94326171875005],
                            [-132.28730468749995, 55.92939453124998],
                            [-132.65991210937503, 56.07817382812499],
                            [-132.379833984375, 56.49877929687497],
                            [-132.06689453125, 56.24423828124998],
                            [-132.11235351562493, 56.109375],
                        ],
                    ],
                    [
                        [
                            [-154.208642578125, 56.51489257812497],
                            [-154.32221679687504, 56.570605468750045],
                            [-154.11040039062496, 56.602929687499966],
                            [-154.208642578125, 56.51489257812497],
                        ],
                    ],
                    [
                        [
                            [-169.755224609375, 56.63505859375002],
                            [-169.47431640624998, 56.59404296875002],
                            [-169.6326171875, 56.545703125000045],
                            [-169.755224609375, 56.63505859375002],
                        ],
                    ],
                    [
                        [
                            [-132.746875, 56.525683593750045],
                            [-132.94804687500002, 56.56723632812498],
                            [-132.842529296875, 56.79477539062506],
                            [-132.56796875000003, 56.57583007812505],
                            [-132.746875, 56.525683593750045],
                        ],
                    ],
                    [
                        [
                            [-133.98959960937503, 56.84497070312497],
                            [-133.73837890625, 56.65043945312496],
                            [-133.94970703125, 56.12773437499996],
                            [-134.18959960937502, 56.07695312500002],
                            [-134.084375, 56.456347656250045],
                            [-134.37368164062502, 56.838671875000045],
                            [-134.14326171874998, 56.93232421875001],
                            [-133.98959960937503, 56.84497070312497],
                        ],
                    ],
                    [
                        [
                            [-133.36621093750006, 57.003515625000034],
                            [-132.99624023437497, 56.93041992187497],
                            [-132.95917968749998, 56.67705078124996],
                            [-133.03491210937494, 56.62075195312505],
                            [-133.32895507812498, 56.83007812499997],
                            [-133.158154296875, 56.495166015625045],
                            [-133.4841796875, 56.45175781249998],
                            [-133.979443359375, 57.009570312500045],
                            [-133.36621093750006, 57.003515625000034],
                        ],
                    ],
                    [
                        [
                            [-153.007080078125, 57.12485351562498],
                            [-153.37460937499998, 57.05190429687505],
                            [-153.285205078125, 57.18505859375],
                            [-152.90839843750004, 57.152441406250006],
                            [-153.007080078125, 57.12485351562498],
                        ],
                    ],
                    [
                        [
                            [-134.96977539062496, 57.351416015625034],
                            [-134.62070312499998, 56.71831054687502],
                            [-134.68188476562503, 56.216162109375034],
                            [-134.98056640625003, 56.518945312499994],
                            [-134.88344726562497, 56.679052734375034],
                            [-135.33061523437505, 56.821875],
                            [-135.19960937499997, 57.02734375],
                            [-135.45493164062503, 57.24941406250005],
                            [-135.81230468750002, 57.00952148437503],
                            [-135.82275390625, 57.280419921874966],
                            [-135.448681640625, 57.534375],
                            [-134.96977539062496, 57.351416015625034],
                        ],
                    ],
                    [
                        [
                            [-152.89804687499998, 57.82392578125004],
                            [-152.42875976562493, 57.82568359375003],
                            [-152.48261718749998, 57.70332031249998],
                            [-152.21621093749997, 57.577001953125006],
                            [-152.41220703125003, 57.454785156249955],
                            [-152.94077148437498, 57.49809570312499],
                            [-152.67905273437503, 57.345117187499994],
                            [-153.274365234375, 57.22636718749996],
                            [-153.732568359375, 57.052343750000034],
                            [-153.643310546875, 56.960742187500045],
                            [-154.02734375, 56.77797851562502],
                            [-153.793212890625, 56.98950195312503],
                            [-154.24375, 57.143017578124955],
                            [-154.33896484374998, 56.9208984375],
                            [-154.67319335937498, 57.44609375],
                            [-154.11616210937498, 57.651220703125006],
                            [-153.6876953125, 57.30512695312504],
                            [-153.841552734375, 57.86284179687496],
                            [-153.48793945312497, 57.73095703125],
                            [-153.21748046875004, 57.79575195312506],
                            [-153.16044921875, 57.97197265624999],
                            [-152.85039062499993, 57.896777343750045],
                            [-152.89804687499998, 57.82392578125004],
                        ],
                    ],
                    [
                        [
                            [-135.73037109375002, 58.244238281250034],
                            [-135.61323242187507, 57.99184570312505],
                            [-135.346630859375, 58.12412109374998],
                            [-134.9546875, 58.01533203125004],
                            [-134.97065429687495, 57.817236328125006],
                            [-135.33847656250003, 57.768652343750034],
                            [-134.97885742187503, 57.724365234375],
                            [-134.93149414062498, 57.48115234375001],
                            [-135.564208984375, 57.66640625],
                            [-135.691943359375, 57.41992187500006],
                            [-135.91079101562502, 57.44658203124999],
                            [-136.568603515625, 57.97216796875003],
                            [-136.32197265625, 58.21889648437502],
                            [-136.14375, 58.098486328125006],
                            [-136.09438476562502, 58.198144531249966],
                            [-135.73037109375002, 58.244238281250034],
                        ],
                    ],
                    [
                        [
                            [-134.68027343749998, 58.16166992187499],
                            [-134.24008789062498, 58.143994140624955],
                            [-133.82275390624997, 57.62866210937503],
                            [-134.29233398437498, 58.044726562500074],
                            [-133.91113281250003, 57.3525390625],
                            [-134.51601562499997, 57.042578125],
                            [-134.48676757812495, 57.48203125],
                            [-134.92348632812497, 58.354638671874966],
                            [-134.68027343749998, 58.16166992187499],
                        ],
                    ],
                    [
                        [
                            [-152.416943359375, 58.360205078125034],
                            [-151.974365234375, 58.30986328124999],
                            [-152.068896484375, 58.17792968750001],
                            [-152.26835937499993, 58.25170898437506],
                            [-152.30922851562502, 58.133886718750034],
                            [-152.5982421875, 58.16259765625],
                            [-152.92841796875004, 57.99370117187499],
                            [-153.38134765625003, 58.08720703125002],
                            [-152.976123046875, 58.29702148437505],
                            [-152.771875, 58.278564453125],
                            [-152.84111328125002, 58.41640625000002],
                            [-152.416943359375, 58.360205078125034],
                        ],
                    ],
                    [
                        [
                            [-152.486083984375, 58.485009765624966],
                            [-152.63662109375002, 58.54169921874998],
                            [-152.3955078125, 58.619384765625],
                            [-152.486083984375, 58.485009765624966],
                        ],
                    ],
                    [
                        [
                            [-160.918994140625, 58.57709960937498],
                            [-161.13149414062502, 58.668212890625],
                            [-160.71513671875005, 58.79521484375002],
                            [-160.918994140625, 58.57709960937498],
                        ],
                    ],
                    [
                        [
                            [-148.02177734375, 60.06533203125005],
                            [-148.271875, 60.05327148437499],
                            [-148.07958984375003, 60.151660156250045],
                            [-148.02177734375, 60.06533203125005],
                        ],
                    ],
                    [
                        [
                            [-147.735888671875, 59.81323242187503],
                            [-147.76806640625, 59.94375],
                            [-147.180859375, 60.358251953125034],
                            [-147.01987304687498, 60.33222656249998],
                            [-147.735888671875, 59.81323242187503],
                        ],
                    ],
                    [
                        [
                            [-166.13544921875, 60.38354492187503],
                            [-165.72968750000004, 60.31420898437503],
                            [-165.591796875, 59.913134765625045],
                            [-166.14873046874996, 59.764111328124955],
                            [-167.13886718749998, 60.00854492187503],
                            [-167.43642578125002, 60.20664062500006],
                            [-166.836328125, 60.21699218750004],
                            [-166.47568359374998, 60.382763671874955],
                            [-166.13544921875, 60.38354492187503],
                        ],
                    ],
                    [
                        [
                            [-146.3939453125, 60.44965820312501],
                            [-146.10224609374998, 60.41118164062499],
                            [-146.61831054687497, 60.27368164062503],
                            [-146.70253906249997, 60.40854492187498],
                            [-146.3939453125, 60.44965820312501],
                        ],
                    ],
                    [
                        [
                            [-147.658251953125, 60.45048828124999],
                            [-147.787841796875, 60.17792968749998],
                            [-147.89145507812498, 60.299414062500034],
                            [-147.658251953125, 60.45048828124999],
                        ],
                    ],
                    [
                        [
                            [-172.74223632812496, 60.45737304687498],
                            [-172.23208007812494, 60.299121093750074],
                            [-172.63574218750003, 60.328857421875],
                            [-173.04765625000002, 60.56831054687501],
                            [-172.74223632812496, 60.45737304687498],
                        ],
                    ],
                    [
                        [
                            [-171.46303710937494, 63.640039062499994],
                            [-171.03486328125, 63.58549804687499],
                            [-170.29936523437502, 63.68061523437501],
                            [-169.55454101562498, 63.373486328124955],
                            [-168.71601562500004, 63.310595703125045],
                            [-168.76132812500003, 63.21376953125002],
                            [-169.364697265625, 63.17114257812506],
                            [-169.67636718750003, 62.95610351562502],
                            [-169.81860351562494, 63.122363281250045],
                            [-170.84838867187494, 63.44438476562502],
                            [-171.63183593749997, 63.351220703124966],
                            [-171.74638671874993, 63.703076171874955],
                            [-171.46303710937494, 63.640039062499994],
                        ],
                    ],
                    [
                        [
                            [-141.00214843750004, 68.77416992187506],
                            [-141.00214843750004, 67.89755859374998],
                            [-141.00214843750004, 66.43652343750006],
                            [-141.00214843750004, 65.55991210937498],
                            [-141.00214843750004, 64.09887695312506],
                            [-141.00214843750004, 63.22226562499998],
                            [-141.00214843750004, 61.761279296875045],
                            [-141.00214843750004, 60.884667968749994],
                            [-141.00214843750004, 60.30024414062504],
                            [-140.76274414062505, 60.259130859375006],
                            [-140.525439453125, 60.218359375000034],
                            [-140.45283203125004, 60.29970703125002],
                            [-139.97329101562497, 60.183154296875074],
                            [-139.67631835937505, 60.32832031249998],
                            [-139.23476562499997, 60.339746093749994],
                            [-139.07924804687497, 60.34370117187501],
                            [-139.07924804687497, 60.279443359374966],
                            [-139.136962890625, 60.17270507812498],
                            [-139.18515624999998, 60.083593750000034],
                            [-138.86875, 59.94575195312501],
                            [-138.317626953125, 59.611132812500074],
                            [-137.59331054687493, 59.22626953124998],
                            [-137.52089843750002, 58.91538085937498],
                            [-137.43857421875003, 58.903125],
                            [-137.2775390625, 58.988183593749994],
                            [-137.126220703125, 59.04096679687498],
                            [-136.81328125000002, 59.150048828124994],
                            [-136.57875976562502, 59.15224609375002],
                            [-136.46635742187493, 59.459082031250006],
                            [-136.27797851562502, 59.48032226562506],
                            [-136.321826171875, 59.604833984375034],
                            [-135.70258789062504, 59.72875976562506],
                            [-135.36787109374998, 59.743310546874994],
                            [-135.051025390625, 59.57866210937502],
                            [-134.94375, 59.28828125000001],
                            [-134.67724609374997, 59.19926757812499],
                            [-134.39306640625, 59.009179687499994],
                            [-134.32963867187505, 58.93969726562506],
                            [-134.21850585937503, 58.849902343750045],
                            [-133.54638671874997, 58.50346679687499],
                            [-133.27529296875, 58.22285156250004],
                            [-133.00141601562495, 57.948974609375],
                            [-132.55048828125, 57.499902343749994],
                            [-132.44248046874998, 57.40673828125003],
                            [-132.30166015624997, 57.27631835937501],
                            [-132.232177734375, 57.19853515624999],
                            [-132.27939453124998, 57.14536132812498],
                            [-132.33798828124998, 57.07944335937506],
                            [-132.15703125, 57.048193359375006],
                            [-132.03154296875, 57.02656250000004],
                            [-132.062890625, 56.95336914062503],
                            [-132.104296875, 56.856787109375006],
                            [-131.86616210937495, 56.792822265625006],
                            [-131.82426757812496, 56.589990234374994],
                            [-131.471875, 56.55673828125006],
                            [-130.649072265625, 56.26367187500003],
                            [-130.47709960937496, 56.230566406250034],
                            [-130.413134765625, 56.12250976562498],
                            [-130.09785156249995, 56.10927734375002],
                            [-130.01406249999997, 55.950537109375006],
                            [-130.2140625, 55.02587890625003],
                            [-130.57534179687497, 54.769677734374966],
                            [-130.849609375, 54.80761718750006],
                            [-131.04785156249997, 55.157666015624955],
                            [-130.74819335937502, 55.31801757812502],
                            [-131.127685546875, 55.96015625000001],
                            [-131.032763671875, 56.08808593749998],
                            [-131.78417968749997, 55.876562500000034],
                            [-131.98339843749994, 55.535009765625006],
                            [-132.15541992187502, 55.59956054687501],
                            [-132.20751953124997, 55.75341796875],
                            [-131.84384765625003, 56.16010742187498],
                            [-131.55136718749998, 56.206787109375],
                            [-131.88789062500004, 56.24165039062498],
                            [-132.18203125000002, 56.42065429687506],
                            [-132.82460937500002, 57.05581054687505],
                            [-133.465869140625, 57.17216796875002],
                            [-133.64873046874993, 57.64228515624998],
                            [-133.11704101562498, 57.56621093750002],
                            [-133.535205078125, 57.83295898437501],
                            [-133.1943359375, 57.87768554687506],
                            [-133.559375, 57.924462890624994],
                            [-133.72231445312502, 57.84423828125],
                            [-134.03110351562498, 58.072167968749966],
                            [-133.87675781249996, 58.51816406249998],
                            [-134.20883789062503, 58.232958984375045],
                            [-134.77612304687506, 58.45385742187503],
                            [-135.36367187500002, 59.41943359375],
                            [-135.50234375000002, 59.202294921874994],
                            [-135.090234375, 58.245849609375],
                            [-135.57177734374994, 58.41206054687504],
                            [-135.89755859374998, 58.40019531250002],
                            [-136.04311523437497, 58.82163085937498],
                            [-135.82636718750004, 58.89794921874997],
                            [-136.0166015625, 58.87397460937498],
                            [-136.150048828125, 59.04809570312503],
                            [-136.22583007812497, 58.765478515625006],
                            [-136.98901367187503, 59.03447265624999],
                            [-137.05903320312498, 58.87373046875001],
                            [-136.613916015625, 58.809277343749955],
                            [-136.48374023437503, 58.61767578125],
                            [-136.224609375, 58.602246093749955],
                            [-136.06147460937495, 58.45273437500006],
                            [-136.607421875, 58.24399414062498],
                            [-137.54399414062502, 58.58120117187502],
                            [-138.51489257812503, 59.16591796875005],
                            [-139.77329101562498, 59.52729492187504],
                            [-139.51303710937498, 59.698095703125006],
                            [-139.5123046875, 59.95356445312501],
                            [-139.28671874999998, 59.610937500000034],
                            [-139.22080078125003, 59.819873046875045],
                            [-138.9880859375, 59.83500976562502],
                            [-139.43144531249996, 60.012255859375074],
                            [-140.41982421874997, 59.71074218750002],
                            [-141.40830078125, 59.90278320312498],
                            [-141.408740234375, 60.11767578125006],
                            [-141.67016601562497, 59.969873046874966],
                            [-142.94565429687503, 60.09697265625002],
                            [-144.14721679687494, 60.01640625000002],
                            [-144.185498046875, 60.150732421875034],
                            [-144.901318359375, 60.335156249999955],
                            [-144.69111328125, 60.66909179687502],
                            [-145.248291015625, 60.38012695312506],
                            [-145.898876953125, 60.47817382812505],
                            [-145.67490234374998, 60.65112304687503],
                            [-146.57045898437497, 60.72915039062502],
                            [-146.39199218749997, 60.810839843750045],
                            [-146.63842773437497, 60.89731445312498],
                            [-146.59912109374994, 61.05351562500002],
                            [-146.284912109375, 61.11264648437498],
                            [-147.89111328125, 60.889892578125],
                            [-148.00512695312494, 60.96855468750002],
                            [-147.75185546874997, 61.218945312499955],
                            [-148.34189453125, 61.060400390625006],
                            [-148.34443359374998, 60.853564453125045],
                            [-148.55615234374994, 60.82700195312506],
                            [-148.25673828124997, 60.67529296874997],
                            [-148.64013671875, 60.48945312500004],
                            [-148.11918945312502, 60.57514648437498],
                            [-147.96411132812494, 60.48486328124997],
                            [-148.430712890625, 59.98911132812498],
                            [-149.2666015625, 59.99829101562497],
                            [-149.395263671875, 60.10576171875002],
                            [-149.59804687500002, 59.77045898437501],
                            [-149.7138671875, 59.91958007812502],
                            [-149.80126953124994, 59.737939453124966],
                            [-150.00532226562507, 59.78442382812503],
                            [-150.19804687499996, 59.56655273437505],
                            [-150.60737304687504, 59.56337890625002],
                            [-150.934521484375, 59.249121093750034],
                            [-151.18276367187502, 59.30078124999997],
                            [-151.73818359375002, 59.18852539062502],
                            [-151.94951171875, 59.26508789062498],
                            [-151.88461914062503, 59.386328125],
                            [-151.39960937499995, 59.51630859375001],
                            [-151.04648437499998, 59.771826171875034],
                            [-151.45009765624997, 59.65039062499997],
                            [-151.85322265625, 59.78208007812498],
                            [-151.39599609375006, 60.27446289062502],
                            [-151.35644531249997, 60.72294921874999],
                            [-150.44125976562503, 61.02358398437505],
                            [-149.07509765624997, 60.87641601562498],
                            [-150.05327148437496, 61.17109374999998],
                            [-149.433544921875, 61.50078125000002],
                            [-149.97568359374998, 61.27934570312502],
                            [-150.61225585937495, 61.301123046875006],
                            [-151.59350585937494, 60.979638671874966],
                            [-152.54091796874997, 60.265429687500045],
                            [-153.025, 60.29565429687497],
                            [-152.660107421875, 59.99721679687502],
                            [-153.21123046875002, 59.84272460937498],
                            [-153.09360351562503, 59.70913085937505],
                            [-153.65253906250004, 59.64702148437499],
                            [-154.17832031250003, 59.155566406250074],
                            [-153.41826171875, 58.9599609375],
                            [-153.43759765625003, 58.754833984374955],
                            [-154.289013671875, 58.30434570312502],
                            [-154.247021484375, 58.15942382812497],
                            [-155.006884765625, 58.01606445312501],
                            [-155.77797851562497, 57.56821289062498],
                            [-156.43588867187498, 57.359960937500006],
                            [-156.62900390624998, 57.00996093750001],
                            [-158.41440429687498, 56.435839843750045],
                            [-158.5521484375, 56.31269531249998],
                            [-158.27563476562497, 56.19624023437498],
                            [-158.5046875, 56.062109375],
                            [-158.59116210937503, 56.18452148437498],
                            [-158.78984375000002, 55.98691406250006],
                            [-159.52324218749993, 55.81000976562498],
                            [-159.65966796875003, 55.625927734374955],
                            [-159.77138671874997, 55.84111328125002],
                            [-160.49931640625002, 55.53730468750004],
                            [-161.38193359374998, 55.371289062499955],
                            [-161.44379882812495, 55.513281250000034],
                            [-161.202099609375, 55.54355468750006],
                            [-161.51694335937503, 55.61840820312503],
                            [-162.073974609375, 55.13930664062505],
                            [-162.38637695312497, 55.05234375],
                            [-162.63037109375003, 55.24667968749998],
                            [-162.67436523437505, 54.99658203125],
                            [-162.86503906249996, 54.954541015624955],
                            [-163.11962890624997, 55.06469726562503],
                            [-163.131103515625, 54.916552734375045],
                            [-163.33530273437503, 54.83916015624999],
                            [-163.27880859374997, 55.12182617187503],
                            [-162.906591796875, 55.19555664062503],
                            [-161.69731445312502, 55.9072265625],
                            [-161.215625, 56.02143554687498],
                            [-160.8986328125, 55.99365234375],
                            [-161.00537109375, 55.88715820312498],
                            [-160.80283203125003, 55.754443359375045],
                            [-160.70634765625002, 55.870458984375034],
                            [-160.29169921875, 55.80507812500005],
                            [-160.53906250000006, 56.00629882812501],
                            [-160.30205078125, 56.31411132812502],
                            [-158.91801757812502, 56.882177734375006],
                            [-158.675146484375, 56.79487304687498],
                            [-158.66079101562502, 57.03940429687498],
                            [-158.32094726562497, 57.29790039062499],
                            [-157.84575195312496, 57.52807617187497],
                            [-157.4619140625, 57.506201171875034],
                            [-157.697216796875, 57.679443359375],
                            [-157.610888671875, 58.050830078125074],
                            [-157.19370117187498, 58.19418945312506],
                            [-157.48837890624998, 58.25371093750002],
                            [-157.52363281249998, 58.421337890624955],
                            [-156.97465820312496, 58.736328125],
                            [-156.80888671875005, 59.13427734375],
                            [-157.14204101562504, 58.87763671875001],
                            [-158.19091796875003, 58.6142578125],
                            [-158.50317382812494, 58.85034179687497],
                            [-158.42563476562498, 58.99931640625002],
                            [-158.080517578125, 58.97744140625002],
                            [-158.422802734375, 59.08984375],
                            [-158.67827148437502, 58.92939453124998],
                            [-158.80947265625002, 58.973876953125],
                            [-158.78862304687493, 58.440966796875045],
                            [-158.95068359375, 58.404541015625],
                            [-159.67026367187498, 58.9111328125],
                            [-159.92021484375, 58.819873046875074],
                            [-160.36313476562498, 59.05117187500002],
                            [-161.246826171875, 58.799462890624994],
                            [-161.36132812499994, 58.66953124999998],
                            [-162.144921875, 58.64423828124998],
                            [-161.724365234375, 58.794287109375006],
                            [-161.64438476562498, 59.109667968750045],
                            [-161.9810546875, 59.14614257812502],
                            [-161.82871093749998, 59.588623046875],
                            [-162.421337890625, 60.28398437500002],
                            [-161.96201171875003, 60.695361328125045],
                            [-162.68496093749997, 60.268945312499966],
                            [-162.57075195312495, 59.98974609375],
                            [-163.68037109374998, 59.80151367187503],
                            [-164.14282226562497, 59.89677734374999],
                            [-165.02651367187497, 60.500634765624994],
                            [-165.35380859375002, 60.54121093750001],
                            [-164.80517578125, 60.89204101562498],
                            [-164.31850585937497, 60.77128906249999],
                            [-164.37236328125002, 60.59184570312502],
                            [-163.999560546875, 60.76606445312498],
                            [-163.72998046874997, 60.589990234374994],
                            [-163.420947265625, 60.757421875],
                            [-163.90654296874996, 60.85380859375002],
                            [-163.58691406249994, 60.902978515624994],
                            [-163.74902343750003, 60.9697265625],
                            [-163.99462890624997, 60.86469726562501],
                            [-165.11484375, 60.93281250000004],
                            [-164.86899414062503, 61.11176757812498],
                            [-165.27978515624994, 61.169628906249955],
                            [-165.27363281250004, 61.27485351562498],
                            [-165.56586914062498, 61.10234375000002],
                            [-165.86396484375004, 61.33569335937503],
                            [-165.84531249999998, 61.536230468750034],
                            [-166.152734375, 61.545947265625074],
                            [-166.16811523437502, 61.65083007812501],
                            [-165.80893554687503, 61.69609375000002],
                            [-166.07880859375, 61.803125],
                            [-165.61279296875003, 61.86928710937502],
                            [-165.707275390625, 62.10043945312506],
                            [-165.19453125, 62.47353515625002],
                            [-164.75786132812493, 62.496728515624966],
                            [-164.589453125, 62.709375],
                            [-164.79267578125, 62.623193359374966],
                            [-164.79965820312503, 62.918066406250006],
                            [-164.384228515625, 63.03046874999998],
                            [-164.40903320312503, 63.21503906250001],
                            [-163.94287109375, 63.247216796874994],
                            [-163.61630859374998, 63.125146484374994],
                            [-163.73784179687496, 63.016406250000045],
                            [-163.504345703125, 63.105859374999966],
                            [-163.28784179687494, 63.046435546875045],
                            [-162.621484375, 63.26582031249998],
                            [-162.28281250000003, 63.529199218749994],
                            [-161.97397460937498, 63.45292968749999],
                            [-161.09970703125003, 63.557910156250045],
                            [-160.778564453125, 63.818945312500034],
                            [-160.987548828125, 64.25126953125002],
                            [-161.49072265625003, 64.43378906249998],
                            [-160.93193359374996, 64.5791015625],
                            [-160.855908203125, 64.755615234375],
                            [-161.13017578125005, 64.92543945312505],
                            [-161.759375, 64.816259765625],
                            [-162.80703124999997, 64.37421875000001],
                            [-163.20390625, 64.65200195312502],
                            [-163.14433593750002, 64.423828125],
                            [-163.71308593749998, 64.588232421875],
                            [-164.978759765625, 64.45366210937502],
                            [-166.1427734375, 64.58276367187503],
                            [-166.48139648437498, 64.72807617187507],
                            [-166.415234375, 64.926513671875],
                            [-166.92841796875, 65.15708007812498],
                            [-166.15703125, 65.28583984375001],
                            [-167.40400390625, 65.42211914062497],
                            [-168.08837890624997, 65.65776367187502],
                            [-166.39873046875002, 66.14443359375005],
                            [-165.62993164062496, 66.131201171875],
                            [-165.77617187500002, 66.31904296875001],
                            [-164.46049804687502, 66.58842773437499],
                            [-163.63823242187502, 66.57465820312504],
                            [-163.89394531249997, 66.57587890625001],
                            [-164.03374023437493, 66.21552734374995],
                            [-163.69536132812502, 66.08383789062503],
                            [-161.93369140625003, 66.04287109374997],
                            [-161.45541992187503, 66.28139648437497],
                            [-161.03427734375003, 66.18881835937503],
                            [-161.12031249999995, 66.334326171875],
                            [-161.91689453124997, 66.41181640624998],
                            [-162.54365234375004, 66.80512695312501],
                            [-162.36162109375, 66.94731445312502],
                            [-161.591015625, 66.45952148437502],
                            [-160.23168945312503, 66.420263671875],
                            [-160.360888671875, 66.6125],
                            [-160.864013671875, 66.67084960937501],
                            [-161.39804687499998, 66.55185546875],
                            [-161.85668945312497, 66.70034179687497],
                            [-161.719921875, 67.02055664062502],
                            [-163.5318359375, 67.10258789062502],
                            [-164.1251953125, 67.60673828125007],
                            [-166.786279296875, 68.35961914062497],
                            [-166.38051757812502, 68.425146484375],
                            [-166.20908203125, 68.88535156250003],
                            [-165.04394531249994, 68.882470703125],
                            [-163.867919921875, 69.03666992187505],
                            [-161.88095703125003, 70.33173828125001],
                            [-162.073876953125, 70.16196289062498],
                            [-160.9962890625, 70.30458984375],
                            [-160.11713867187495, 70.59121093750002],
                            [-159.86567382812498, 70.27885742187499],
                            [-159.81499023437496, 70.49707031250003],
                            [-159.38676757812493, 70.52451171875003],
                            [-160.081591796875, 70.63486328125003],
                            [-159.680908203125, 70.786767578125],
                            [-159.31450195312496, 70.87851562500003],
                            [-159.251171875, 70.7484375],
                            [-157.909375, 70.860107421875],
                            [-156.47021484374994, 71.40766601562501],
                            [-156.469970703125, 71.29155273437507],
                            [-155.57944335937503, 71.12109374999997],
                            [-156.14658203125, 70.92783203125003],
                            [-155.97353515625002, 70.84199218749995],
                            [-155.16684570312498, 71.09921875000006],
                            [-154.19521484375002, 70.80112304687498],
                            [-153.23291015625, 70.93256835937504],
                            [-152.49121093749994, 70.88095703125],
                            [-152.23291015625, 70.81035156249999],
                            [-152.39921875, 70.62045898437503],
                            [-151.76904296875, 70.56015625],
                            [-151.94467773437498, 70.45209960937501],
                            [-149.26943359374997, 70.50078124999999],
                            [-147.70537109375, 70.21723632812495],
                            [-145.82314453124997, 70.16005859375002],
                            [-145.19736328125003, 70.00869140625002],
                            [-143.218310546875, 70.11625976562499],
                            [-142.70786132812498, 70.03378906249998],
                            [-141.40791015625, 69.65336914062502],
                            [-141.00214843750004, 69.65078125000002],
                            [-141.00214843750004, 68.77416992187506],
                        ],
                    ],
                ],
            },
            properties: {
                name: "美国",
                childNum: 76,
                cp: [-90.02, 39.91],
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [71.20615234375006, 39.892578125],
                            [71.15625, 39.88344726562502],
                            [71.06425781250002, 39.88491210937505],
                            [71.01171874999997, 39.895117187500006],
                            [71.04365234375004, 39.97631835937503],
                            [71.04482421875005, 39.992529296875034],
                            [70.96064453125004, 40.087988281250034],
                            [71.00546875, 40.15229492187498],
                            [71.0241210937501, 40.14916992187497],
                            [71.08037109375007, 40.07988281249999],
                            [71.2287109375001, 40.04814453124999],
                            [71.20615234375006, 39.892578125],
                        ],
                    ],
                    [
                        [
                            [70.94677734374997, 42.24868164062505],
                            [70.97900390625003, 42.26655273437504],
                            [71.03603515625, 42.28466796875],
                            [71.12998046875006, 42.25],
                            [71.21269531250002, 42.20644531250005],
                            [71.23232421875005, 42.18627929687503],
                            [71.22851562499997, 42.16289062499996],
                            [70.18095703125007, 41.571435546874994],
                            [70.734375, 41.400537109374994],
                            [70.86044921875006, 41.22490234375002],
                            [71.11074218750005, 41.152636718750045],
                            [71.29882812500003, 41.152490234374994],
                            [71.39306640625003, 41.123388671875034],
                            [71.40839843750004, 41.13603515625002],
                            [71.42089843750003, 41.341894531250034],
                            [71.60625, 41.367431640625],
                            [71.66494140625, 41.54121093749998],
                            [71.70068359374997, 41.454003906249966],
                            [71.75771484375005, 41.42802734375002],
                            [71.79248046875003, 41.41313476562499],
                            [71.85800781250006, 41.311376953125034],
                            [71.8786132812501, 41.195019531249955],
                            [71.95849609375003, 41.18706054687502],
                            [72.05244140625004, 41.16474609375001],
                            [72.1154296875001, 41.18657226562502],
                            [72.1642578125001, 41.173730468749966],
                            [72.18095703125002, 41.11845703124999],
                            [72.18730468750002, 41.02592773437499],
                            [72.2130859375001, 41.014257812500006],
                            [72.36406250000002, 41.04345703125],
                            [72.65830078125, 40.86992187499999],
                            [73.13212890625002, 40.82851562499999],
                            [72.6041015625, 40.52543945312499],
                            [72.40205078125004, 40.578076171874955],
                            [72.3892578125, 40.427392578124994],
                            [72.13125, 40.438623046874966],
                            [71.69248046875, 40.15234375],
                            [71.30468749999997, 40.28691406249996],
                            [70.990625, 40.2548828125],
                            [70.95800781250003, 40.238867187500034],
                            [70.653125, 40.201171875],
                            [70.37158203125003, 40.38413085937506],
                            [70.75107421875006, 40.721777343750006],
                            [70.40195312500006, 41.03510742187498],
                            [69.71289062500003, 40.65698242187503],
                            [69.35722656250002, 40.76738281249996],
                            [69.20625, 40.566552734374994],
                            [69.27490234374997, 40.19809570312498],
                            [68.63066406250007, 40.16708984374998],
                            [68.9720703125, 40.08994140624998],
                            [68.80468750000003, 40.05034179687499],
                            [68.86875, 39.90747070312503],
                            [68.63896484375007, 39.8388671875],
                            [68.46328125, 39.53671874999998],
                            [67.71904296875007, 39.62138671875002],
                            [67.426171875, 39.46557617187497],
                            [67.3576171875001, 39.216699218749994],
                            [67.64833984375005, 39.13105468750004],
                            [67.69443359375006, 38.99462890625003],
                            [68.13251953125004, 38.927636718749966],
                            [68.08720703125002, 38.47353515625002],
                            [68.3502929687501, 38.211035156250006],
                            [67.81435546875005, 37.48701171875004],
                            [67.7980468750001, 37.244970703125006],
                            [67.75898437500004, 37.172216796875034],
                            [67.75292968749997, 37.199804687500034],
                            [67.7, 37.227246093749955],
                            [67.60742187499997, 37.22250976562506],
                            [67.5172851562501, 37.26665039062499],
                            [67.44169921875007, 37.25800781250001],
                            [67.3197265625, 37.209570312500006],
                            [67.1955078125001, 37.23520507812498],
                            [67.06884765624997, 37.334814453125006],
                            [66.82773437500006, 37.37128906249998],
                            [66.52226562500007, 37.34848632812506],
                            [66.51064453125, 37.45869140625004],
                            [66.51132812500006, 37.59916992187496],
                            [66.52558593750004, 37.785742187500034],
                            [66.60625, 37.98671875000005],
                            [65.97119140624997, 38.244238281250006],
                            [65.612890625, 38.23857421875002],
                            [64.3099609375, 38.97729492187497],
                            [63.76367187500003, 39.16054687499999],
                            [62.48320312500002, 39.97563476562496],
                            [61.90283203124997, 41.09370117187501],
                            [61.496972656249994, 41.276074218749955],
                            [61.2423828125001, 41.18920898437503],
                            [60.454980468749994, 41.221630859374955],
                            [60.089648437500074, 41.39941406250003],
                            [60.07558593750005, 41.759667968749966],
                            [60.20078125000006, 41.803125],
                            [59.94179687499999, 41.97353515625002],
                            [59.98515625000002, 42.21171875],
                            [59.35429687500002, 42.32329101562496],
                            [58.5890625000001, 42.778466796874966],
                            [58.477148437500006, 42.66284179687503],
                            [58.15156250000004, 42.628076171874966],
                            [58.474414062500074, 42.29936523437496],
                            [58.02890625, 42.48764648437506],
                            [57.814257812500074, 42.18984375000005],
                            [57.290625, 42.123779296875],
                            [56.96406250000004, 41.856542968750006],
                            [57.11884765625004, 41.35029296874998],
                            [57.01796875, 41.26347656249996],
                            [55.97744140625005, 41.32221679687504],
                            [55.97568359375006, 44.99492187499996],
                            [58.555273437500006, 45.55537109375001],
                            [61.007910156250006, 44.39379882812497],
                            [61.99023437500003, 43.492138671874955],
                            [63.20703125000003, 43.62797851562502],
                            [64.44316406250007, 43.55117187499999],
                            [64.9054687500001, 43.714697265625006],
                            [65.49619140625, 43.310546875],
                            [65.80302734375002, 42.87695312500006],
                            [66.1002929687501, 42.99082031249998],
                            [66.00957031250007, 42.00488281250003],
                            [66.49863281250006, 41.99487304687503],
                            [66.70966796875004, 41.17915039062501],
                            [67.9357421875001, 41.19658203125002],
                            [68.11308593750007, 41.02861328124999],
                            [68.04765625000007, 40.80927734374998],
                            [68.29189453125, 40.656103515625034],
                            [68.57265625, 40.62265624999998],
                            [68.58408203125, 40.876269531250045],
                            [69.15361328125002, 41.42524414062498],
                            [70.94677734374997, 42.24868164062505],
                        ],
                    ],
                ],
            },
            properties: {
                name: "乌兹别克斯坦",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-61.17451171875001, 13.158105468749966],
                        [-61.268457031249966, 13.287695312499991],
                        [-61.13896484374996, 13.358740234374991],
                        [-61.17451171875001, 13.158105468749966],
                    ],
                ],
            },
            properties: {
                name: "圣文森特和格林纳丁斯",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-60.99790039062498, 8.867333984374966],
                            [-61.069189453125034, 8.947314453125003],
                            [-60.91582031249996, 9.070312500000014],
                            [-60.86142578124998, 8.949609375000037],
                            [-60.99790039062498, 8.867333984374966],
                        ],
                    ],
                    [
                        [
                            [-60.821191406249966, 9.138378906250026],
                            [-60.94140625000003, 9.105566406250006],
                            [-60.73583984374997, 9.203320312500026],
                            [-60.821191406249966, 9.138378906250026],
                        ],
                    ],
                    [
                        [
                            [-63.84936523437494, 11.131005859374994],
                            [-63.917626953124994, 10.887548828125048],
                            [-64.40234375, 10.981591796875023],
                            [-64.21367187500002, 11.086132812499997],
                            [-64.0283203125, 11.00185546874998],
                            [-63.84936523437494, 11.131005859374994],
                        ],
                    ],
                    [
                        [
                            [-60.742138671874926, 5.202050781250037],
                            [-60.71196289062499, 5.191552734375023],
                            [-60.671972656250034, 5.164355468749989],
                            [-60.603857421875006, 4.94936523437498],
                            [-61.00283203125002, 4.535253906249991],
                            [-61.28007812500002, 4.516894531249974],
                            [-61.82084960937496, 4.197021484375],
                            [-62.153125, 4.098388671874986],
                            [-62.41064453124994, 4.156738281249972],
                            [-62.71210937499998, 4.01791992187502],
                            [-62.85698242187502, 3.593457031249969],
                            [-63.33867187500002, 3.943896484375045],
                            [-64.02148437500003, 3.929101562500051],
                            [-64.19248046874995, 4.126855468750009],
                            [-64.57636718750001, 4.139892578125],
                            [-64.788671875, 4.276025390625023],
                            [-64.66899414062496, 4.01181640625002],
                            [-64.22109375000002, 3.587402343749972],
                            [-64.04658203124998, 2.502392578124997],
                            [-63.389257812500006, 2.411914062500045],
                            [-63.43251953124994, 2.155566406250045],
                            [-64.00849609374995, 1.931591796874969],
                            [-64.20502929687493, 1.52949218750004],
                            [-65.10375976562497, 1.108105468749983],
                            [-65.47338867187497, 0.691259765624977],
                            [-65.55605468750002, 0.687988281250014],
                            [-65.52299804687493, 0.843408203124966],
                            [-65.68144531249999, 0.983447265624989],
                            [-66.06005859375003, 0.78535156250004],
                            [-66.34711914062498, 0.7671875],
                            [-66.87602539062499, 1.223046875000037],
                            [-67.21083984375, 2.390136718750043],
                            [-67.61870117187496, 2.793603515624994],
                            [-67.85908203124998, 2.793603515624994],
                            [-67.3111328125, 3.41586914062502],
                            [-67.66162109375, 3.864257812499986],
                            [-67.85527343750002, 4.506884765624989],
                            [-67.82490234374995, 5.270458984375026],
                            [-67.47387695312503, 5.929980468750003],
                            [-67.48198242187499, 6.18027343750002],
                            [-67.85917968749999, 6.289892578124963],
                            [-68.47177734375, 6.156542968749974],
                            [-69.42714843749997, 6.123974609374997],
                            [-70.12919921874999, 6.95361328125],
                            [-70.73715820312503, 7.090039062499997],
                            [-71.12861328124993, 6.98671875],
                            [-72.00664062499993, 7.032617187500023],
                            [-72.20771484374995, 7.37026367187498],
                            [-72.47197265624996, 7.524267578124991],
                            [-72.39033203124995, 8.287060546874969],
                            [-72.66542968749994, 8.62758789062498],
                            [-72.79638671874997, 9.10898437499999],
                            [-73.05839843749999, 9.259570312500031],
                            [-73.36621093749997, 9.194140625000017],
                            [-73.00654296874998, 9.789160156250006],
                            [-72.86933593750001, 10.49125976562496],
                            [-72.690087890625, 10.835839843749994],
                            [-72.24848632812501, 11.196435546875009],
                            [-71.95810546875, 11.66640625],
                            [-71.31972656249997, 11.861914062500048],
                            [-71.95693359375002, 11.569921874999977],
                            [-71.835107421875, 11.190332031250009],
                            [-71.6416015625, 11.013525390625048],
                            [-71.73090820312498, 10.994677734375017],
                            [-71.59433593749995, 10.657373046875051],
                            [-72.11284179687499, 9.815576171874966],
                            [-71.61953124999994, 9.047949218749991],
                            [-71.24140625000001, 9.160449218750003],
                            [-71.08583984375002, 9.348242187499977],
                            [-71.05268554687501, 9.705810546874986],
                            [-71.49423828125, 10.533203124999972],
                            [-71.46953124999993, 10.964160156250017],
                            [-70.23251953124998, 11.372998046874997],
                            [-70.09711914062493, 11.519775390624972],
                            [-69.80478515624998, 11.47421875000002],
                            [-69.81733398437495, 11.672070312499997],
                            [-70.19257812499993, 11.62460937500002],
                            [-70.28652343749997, 11.886035156249989],
                            [-70.20278320312497, 12.098388671874986],
                            [-70.00395507812496, 12.177880859375023],
                            [-69.63159179687494, 11.479931640625026],
                            [-68.827978515625, 11.431738281249977],
                            [-68.39863281249995, 11.160986328124977],
                            [-68.29628906249997, 10.689355468749994],
                            [-68.13994140624999, 10.492724609374989],
                            [-66.24721679687497, 10.632226562499994],
                            [-65.85175781249995, 10.257763671874997],
                            [-65.12910156249998, 10.070068359375043],
                            [-64.85048828125, 10.098095703124969],
                            [-64.188330078125, 10.457812499999989],
                            [-63.73188476562501, 10.503417968750043],
                            [-64.24750976562498, 10.54257812500002],
                            [-64.298193359375, 10.635156249999966],
                            [-61.879492187500006, 10.741015625000031],
                            [-62.379980468750006, 10.546875],
                            [-62.91357421875, 10.531494140624986],
                            [-62.68583984374996, 10.289794921875043],
                            [-62.740576171875006, 10.056152343750043],
                            [-62.55034179687499, 10.200439453125043],
                            [-62.320410156250034, 9.783056640625006],
                            [-62.22114257812498, 9.882568359375028],
                            [-62.15336914062493, 9.821777343749986],
                            [-62.15532226562499, 9.979248046875014],
                            [-62.077099609374926, 9.97504882812504],
                            [-61.73593749999998, 9.631201171874977],
                            [-61.76591796874996, 9.813818359374963],
                            [-61.58886718749994, 9.894531249999986],
                            [-60.79248046874997, 9.360742187500037],
                            [-61.02314453124998, 9.15458984374996],
                            [-61.24726562499998, 8.600341796875014],
                            [-61.61870117187499, 8.59746093749996],
                            [-61.30400390624999, 8.410400390625043],
                            [-60.800976562499926, 8.592138671875034],
                            [-60.16748046875, 8.616992187500031],
                            [-60.01752929687501, 8.549316406250014],
                            [-59.83164062499998, 8.305957031250003],
                            [-59.84907226562498, 8.248681640624966],
                            [-59.96484375000003, 8.191601562499969],
                            [-59.99072265624997, 8.16201171874998],
                            [-60.032421874999926, 8.053564453125006],
                            [-60.51362304687501, 7.813183593749969],
                            [-60.71865234374994, 7.535937499999974],
                            [-60.606542968750006, 7.320849609375031],
                            [-60.63330078124997, 7.211083984374966],
                            [-60.58320312499998, 7.156201171874969],
                            [-60.523193359375, 7.143701171875009],
                            [-60.464941406250034, 7.166552734375045],
                            [-60.39238281249999, 7.164550781249986],
                            [-60.34506835937495, 7.15],
                            [-60.32548828124996, 7.133984374999983],
                            [-60.32207031249996, 7.092041015625043],
                            [-60.35209960937496, 7.002880859374997],
                            [-60.39501953125, 6.945361328125003],
                            [-60.717919921874966, 6.768310546875],
                            [-61.14560546874998, 6.694531249999983],
                            [-61.20361328124997, 6.588378906250028],
                            [-61.181591796874926, 6.513378906250026],
                            [-61.15102539062502, 6.446533203124986],
                            [-61.15229492187501, 6.385107421875006],
                            [-61.12871093749999, 6.214306640625026],
                            [-61.15947265624996, 6.174414062499977],
                            [-61.22495117187498, 6.129199218750003],
                            [-61.303125, 6.049511718750026],
                            [-61.39082031250001, 5.938769531250017],
                            [-61.376806640625006, 5.906982421875028],
                            [-61.167187499999926, 5.674218750000037],
                            [-60.95400390625002, 5.437402343750023],
                            [-60.742138671874926, 5.202050781250037],
                        ],
                    ],
                ],
            },
            properties: {
                name: "委内瑞拉",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-64.765625, 17.794335937499994],
                        [-64.58046874999994, 17.750195312499983],
                        [-64.88911132812495, 17.701708984375045],
                        [-64.765625, 17.794335937499994],
                    ],
                ],
            },
            properties: {
                name: "维尔京群岛，美属",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [104.06396484375003, 10.390820312500011],
                            [104.01845703125, 10.029199218749966],
                            [103.84951171875005, 10.371093749999986],
                            [104.06396484375003, 10.390820312500011],
                        ],
                    ],
                    [
                        [
                            [107.52128906250007, 20.926611328124977],
                            [107.39921875000007, 20.903466796874966],
                            [107.55126953125003, 21.034033203125006],
                            [107.52128906250007, 20.926611328124977],
                        ],
                    ],
                    [
                        [
                            [107.60273437500004, 21.21679687500003],
                            [107.40351562500004, 21.093652343749994],
                            [107.47626953125004, 21.268945312499994],
                            [107.60273437500004, 21.21679687500003],
                        ],
                    ],
                    [
                        [
                            [107.97265624999997, 21.507958984375023],
                            [107.40996093750002, 21.284814453125023],
                            [107.35429687500007, 21.055175781250057],
                            [107.1647460937501, 20.94873046875003],
                            [106.68339843750007, 21.000292968750074],
                            [106.75341796875003, 20.73505859375004],
                            [106.55078124999997, 20.52656250000001],
                            [106.57285156250012, 20.392187499999977],
                            [105.98408203125004, 19.939062500000034],
                            [105.62177734375004, 18.96630859375003],
                            [105.88828125000006, 18.502490234375045],
                            [106.49902343749997, 17.946435546874994],
                            [106.47890625000005, 17.719580078125063],
                            [106.3705078125, 17.746875],
                            [107.83378906250002, 16.322460937499983],
                            [108.02939453125012, 16.331103515625074],
                            [108.82128906249997, 15.377929687500028],
                            [109.30332031250012, 13.856445312500043],
                            [109.271875, 13.279345703124974],
                            [109.42392578125006, 12.955957031249994],
                            [109.44492187500006, 12.599609375000057],
                            [109.33554687500012, 12.751904296874997],
                            [109.21894531250004, 12.64580078124996],
                            [109.30468750000003, 12.391162109375045],
                            [109.20683593750007, 12.415380859375006],
                            [109.1986328125, 11.724853515625014],
                            [109.03964843750012, 11.592675781249994],
                            [108.98671875, 11.336376953124997],
                            [108.09492187500004, 10.897265624999989],
                            [108.0013671875, 10.720361328125009],
                            [107.26152343750007, 10.39838867187504],
                            [107.00664062500002, 10.66054687499998],
                            [106.94746093750004, 10.400341796874997],
                            [106.72734375000007, 10.535644531250028],
                            [106.605859375, 10.46494140625002],
                            [106.74121093750003, 10.444384765625003],
                            [106.75742187500006, 10.295800781250023],
                            [106.46406250000004, 10.298291015624997],
                            [106.78525390625012, 10.116455078124986],
                            [106.59560546875005, 9.859863281250028],
                            [106.1364257812501, 10.221679687500014],
                            [106.56435546875005, 9.715625],
                            [106.48408203125004, 9.559423828125006],
                            [105.83095703125005, 10.000732421875028],
                            [106.15859375, 9.59414062499998],
                            [106.16835937500005, 9.396728515625],
                            [105.50097656249997, 9.093212890624983],
                            [105.11435546875006, 8.629199218750031],
                            [104.77041015625, 8.59765625],
                            [104.89628906250007, 8.746630859374974],
                            [104.81855468750004, 8.801855468750034],
                            [104.84521484375003, 9.606152343750026],
                            [105.08447265625003, 9.99570312499999],
                            [104.8019531250001, 10.202734374999977],
                            [104.66347656250005, 10.169921875000043],
                            [104.42636718750006, 10.411230468749991],
                            [104.85058593749997, 10.534472656249974],
                            [105.04638671874997, 10.701660156250014],
                            [105.04570312500002, 10.911376953125014],
                            [105.3146484375001, 10.845166015625026],
                            [105.40576171875003, 10.95161132812504],
                            [105.75507812500004, 10.989990234375043],
                            [105.85332031250007, 10.86357421874996],
                            [106.16396484375005, 10.794921875],
                            [106.16093750000002, 11.037109375000057],
                            [105.85605468750006, 11.294287109375048],
                            [105.85146484375005, 11.635009765625],
                            [106.0060546875001, 11.758007812500011],
                            [106.39921875000007, 11.687011718750028],
                            [106.41386718750002, 11.9484375],
                            [106.70009765625, 11.979296874999974],
                            [107.21210937500004, 12.30400390624996],
                            [107.39335937500002, 12.260498046874972],
                            [107.50644531250006, 12.364550781250031],
                            [107.47539062500002, 13.030371093749963],
                            [107.60546874999997, 13.437792968750017],
                            [107.3314453125, 14.126611328125009],
                            [107.51943359375005, 14.705078125],
                            [107.51376953125012, 14.817382812500057],
                            [107.52451171875012, 14.871826171875043],
                            [107.50468750000007, 14.915917968749966],
                            [107.48037109375, 14.979882812500037],
                            [107.55527343750006, 15.057031250000023],
                            [107.58964843750002, 15.118457031250017],
                            [107.63369140625005, 15.18984375],
                            [107.653125, 15.255224609374991],
                            [107.62167968750006, 15.309863281250017],
                            [107.56425781250002, 15.391601562499972],
                            [107.45957031250012, 15.4658203125],
                            [107.33876953125, 15.560498046875011],
                            [107.27939453125006, 15.618701171875045],
                            [107.16591796875005, 15.802490234375028],
                            [107.1888671875, 15.838623046875],
                            [107.36064453125002, 15.921728515624977],
                            [107.3919921875, 15.951660156250028],
                            [107.39638671875, 16.04301757812499],
                            [106.93066406249997, 16.353125],
                            [106.8927734375001, 16.396533203125074],
                            [106.85107421875003, 16.515625],
                            [106.83242187500005, 16.526269531250023],
                            [106.79160156250006, 16.490332031250006],
                            [106.73955078125007, 16.452539062500023],
                            [106.6564453125001, 16.49262695312501],
                            [106.54619140625002, 16.650732421874977],
                            [106.53369140625003, 16.821044921875057],
                            [106.52597656250006, 16.876611328124994],
                            [106.50224609375002, 16.95410156249997],
                            [106.26953125000003, 17.21679687500003],
                            [106.00625, 17.415283203125057],
                            [105.69140625000003, 17.737841796875045],
                            [105.58847656250012, 17.983691406250045],
                            [105.51855468749997, 18.077441406250045],
                            [105.45820312500004, 18.15429687499997],
                            [105.11455078125002, 18.40527343750003],
                            [105.08701171875006, 18.496240234374994],
                            [105.14541015625, 18.616796875000063],
                            [105.14648437500003, 18.650976562500006],
                            [103.89160156250003, 19.304980468750017],
                            [103.89638671875, 19.339990234375023],
                            [103.93203125, 19.366064453125006],
                            [104.0275390625001, 19.42045898437499],
                            [104.062890625, 19.48256835937505],
                            [104.05156250000007, 19.564160156249955],
                            [104.01347656250007, 19.64648437500003],
                            [104.03203125000002, 19.675146484375006],
                            [104.06279296875007, 19.678417968749983],
                            [104.25986328125006, 19.685498046874983],
                            [104.5462890625, 19.61054687500001],
                            [104.58789062500003, 19.61875],
                            [104.74316406250003, 19.754736328124977],
                            [104.80175781249997, 19.83613281250004],
                            [104.81513671875004, 19.90400390625001],
                            [104.9279296875001, 20.01811523437499],
                            [104.92919921875003, 20.082812500000017],
                            [104.88867187500003, 20.169091796875023],
                            [104.84785156250004, 20.202441406250045],
                            [104.69873046875003, 20.20532226562503],
                            [104.67695312500004, 20.224707031249977],
                            [104.66191406250007, 20.28901367187501],
                            [104.65644531250004, 20.32851562499999],
                            [104.6188476562501, 20.37451171875003],
                            [104.49619140625006, 20.413671875],
                            [104.39218750000012, 20.424755859374955],
                            [104.36777343750012, 20.44140624999997],
                            [104.40781250000012, 20.48574218750005],
                            [104.47861328125006, 20.529589843750017],
                            [104.53271484374997, 20.55488281250001],
                            [104.58320312500004, 20.646679687499955],
                            [104.34960937499997, 20.821093750000074],
                            [104.19531249999997, 20.913964843749966],
                            [104.10136718750002, 20.94550781250001],
                            [103.63505859375007, 20.697070312500017],
                            [103.46357421875004, 20.779833984375017],
                            [103.21074218750002, 20.840625],
                            [103.10449218749997, 20.891650390625045],
                            [102.88378906250003, 21.202587890624983],
                            [102.85117187500006, 21.26591796874999],
                            [102.94960937500005, 21.681347656249983],
                            [102.84521484374997, 21.73476562500005],
                            [102.81591796874997, 21.807373046875],
                            [102.7982421875, 21.797949218750034],
                            [102.77109375000006, 21.709667968749983],
                            [102.73857421875002, 21.67792968750001],
                            [102.66201171875005, 21.67602539062497],
                            [102.58251953125003, 21.90429687500003],
                            [102.12744140624997, 22.379199218750045],
                            [102.1759765625001, 22.414648437500006],
                            [102.2370117187501, 22.466015624999983],
                            [102.40644531250004, 22.70800781249997],
                            [102.47089843750004, 22.75092773437501],
                            [102.98193359374997, 22.4482421875],
                            [103.32666015625003, 22.769775390625057],
                            [103.49296875000007, 22.587988281250034],
                            [103.62021484375006, 22.782031250000045],
                            [103.94150390625006, 22.540087890625045],
                            [104.14306640624997, 22.800146484375006],
                            [104.37177734375004, 22.704052734374983],
                            [104.68730468750002, 22.822216796874983],
                            [104.86474609375003, 23.136376953125023],
                            [105.27539062500003, 23.34521484375003],
                            [105.8429687500001, 22.922802734374955],
                            [106.14843749999997, 22.970068359375006],
                            [106.2790039062501, 22.857470703125045],
                            [106.54179687500007, 22.908349609375023],
                            [106.78027343749997, 22.778906250000034],
                            [106.55039062500006, 22.501367187499994],
                            [106.66357421875003, 21.97890625000005],
                            [106.97099609375002, 21.923925781250034],
                            [107.35117187500012, 21.60888671874997],
                            [107.75927734374997, 21.655029296875057],
                            [107.97265624999997, 21.507958984375023],
                        ],
                    ],
                ],
            },
            properties: {
                name: "越南",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [169.49130859375006, -19.54013671875002],
                            [169.34726562500006, -19.623535156249957],
                            [169.2174804687501, -19.476367187500003],
                            [169.24746093750005, -19.3447265625],
                            [169.49130859375006, -19.54013671875002],
                        ],
                    ],
                    [
                        [
                            [169.334375, -18.940234375000017],
                            [168.98691406250006, -18.87128906250001],
                            [169.01582031250004, -18.64375],
                            [169.14384765625002, -18.63105468750001],
                            [169.334375, -18.940234375000017],
                        ],
                    ],
                    [
                        [
                            [168.44580078124997, -17.54218750000004],
                            [168.58496093750003, -17.695898437500006],
                            [168.52460937500004, -17.798046875000026],
                            [168.15820312500003, -17.710546874999963],
                            [168.2731445312501, -17.552246093749957],
                            [168.44580078124997, -17.54218750000004],
                        ],
                    ],
                    [
                        [
                            [168.44677734375003, -16.778808593749957],
                            [168.18144531250002, -16.804003906250017],
                            [168.13535156250006, -16.636914062499997],
                            [168.44677734375003, -16.778808593749957],
                        ],
                    ],
                    [
                        [
                            [168.29667968750007, -16.33652343749999],
                            [167.92900390625002, -16.22871093749997],
                            [168.16386718750002, -16.081640625000034],
                            [168.29667968750007, -16.33652343749999],
                        ],
                    ],
                    [
                        [
                            [167.4125, -16.095898437499997],
                            [167.83662109375004, -16.449707031249957],
                            [167.44931640625012, -16.554980468750003],
                            [167.34921875000006, -16.15449218750004],
                            [167.15146484375006, -16.080468749999966],
                            [167.19951171875002, -15.885058593750031],
                            [167.33574218750007, -15.916699218749997],
                            [167.4125, -16.095898437499997],
                        ],
                    ],
                    [
                        [
                            [167.9113281250001, -15.435937500000023],
                            [167.67421875, -15.4515625],
                            [168.00253906250012, -15.283203124999986],
                            [167.9113281250001, -15.435937500000023],
                        ],
                    ],
                    [
                        [
                            [166.74580078125004, -14.826855468750011],
                            [166.81015625000012, -15.15742187500004],
                            [167.0755859375, -14.935644531249977],
                            [167.20078125000012, -15.443066406249969],
                            [167.0939453125001, -15.580859374999974],
                            [166.75830078125003, -15.631152343750003],
                            [166.63105468750004, -15.406054687499974],
                            [166.56738281250003, -14.641796874999969],
                            [166.74580078125004, -14.826855468750011],
                        ],
                    ],
                    [
                        [
                            [167.58486328125, -14.260937500000011],
                            [167.43027343750012, -14.294921875],
                            [167.41074218750006, -14.19746093750004],
                            [167.50644531250012, -14.142187499999977],
                            [167.58486328125, -14.260937500000011],
                        ],
                    ],
                    [
                        [
                            [167.48886718750006, -13.907226562499972],
                            [167.3917968750001, -13.788378906250017],
                            [167.48105468750006, -13.709472656250014],
                            [167.48886718750006, -13.907226562499972],
                        ],
                    ],
                ],
            },
            properties: {
                name: "瓦努阿图",
                childNum: 10,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-171.4541015625, -14.04648437500002],
                            [-171.9119140625, -14.001660156250026],
                            [-172.04589843750003, -13.857128906249983],
                            [-171.60390624999997, -13.879199218750045],
                            [-171.4541015625, -14.04648437500002],
                        ],
                    ],
                    [
                        [
                            [-172.33349609375, -13.46523437499999],
                            [-172.17685546874998, -13.68466796875002],
                            [-172.224951171875, -13.804296874999963],
                            [-172.535693359375, -13.791699218749983],
                            [-172.77851562499998, -13.516796875000011],
                            [-172.33349609375, -13.46523437499999],
                        ],
                    ],
                ],
            },
            properties: {
                name: "萨摩亚",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [53.76318359374997, 12.636816406249991],
                            [54.18740234375005, 12.664013671875026],
                            [54.511132812499994, 12.552783203125017],
                            [54.12949218750006, 12.360644531250045],
                            [53.71884765625006, 12.318994140624994],
                            [53.31582031250005, 12.533154296875011],
                            [53.53496093750002, 12.715771484374997],
                            [53.76318359374997, 12.636816406249991],
                        ],
                    ],
                    [
                        [
                            [42.75585937500003, 13.70429687500004],
                            [42.689746093750074, 13.673632812500017],
                            [42.7941406250001, 13.766113281250028],
                            [42.75585937500003, 13.70429687500004],
                        ],
                    ],
                    [
                        [
                            [42.787402343750074, 13.971484375000031],
                            [42.69404296875004, 14.007910156249991],
                            [42.76210937500005, 14.067480468750048],
                            [42.787402343750074, 13.971484375000031],
                        ],
                    ],
                    [
                        [
                            [53.08564453125004, 16.648388671874955],
                            [52.327734375, 16.293554687500063],
                            [52.17402343750004, 15.956835937500017],
                            [52.2174804687501, 15.655517578125],
                            [51.3224609375001, 15.22626953125004],
                            [49.34990234375002, 14.637792968749977],
                            [48.66835937499999, 14.050146484374977],
                            [47.9899414062501, 14.048095703125],
                            [47.40771484374997, 13.661621093750057],
                            [46.78886718750002, 13.465576171874986],
                            [45.65732421875006, 13.338720703124991],
                            [45.03867187500006, 12.815869140624969],
                            [44.617773437500006, 12.817236328124977],
                            [44.00585937499997, 12.607666015625],
                            [43.634375, 12.744482421874991],
                            [43.487597656250074, 12.69882812500002],
                            [43.23193359375003, 13.267089843750057],
                            [43.2824218750001, 13.692529296875037],
                            [43.08906250000004, 14.010986328125],
                            [42.93642578125005, 14.938574218749963],
                            [42.85566406250004, 15.132958984375037],
                            [42.65781250000006, 15.232812500000051],
                            [42.79902343750004, 15.326269531249991],
                            [42.71718750000005, 15.654638671875006],
                            [42.83964843750002, 16.032031250000074],
                            [42.79931640624997, 16.37177734375001],
                            [43.16503906249997, 16.689404296874955],
                            [43.19091796875003, 17.359375],
                            [43.41796875000003, 17.516259765625023],
                            [43.91699218749997, 17.32470703124997],
                            [45.14804687500006, 17.427441406249955],
                            [45.5353515625001, 17.30205078124999],
                            [46.72763671875006, 17.26557617187501],
                            [46.97568359375006, 16.953466796875034],
                            [47.14355468749997, 16.946679687499966],
                            [47.44179687499999, 17.111865234375045],
                            [47.57958984374997, 17.448339843750034],
                            [48.17216796875002, 18.156933593749983],
                            [49.04199218750003, 18.58178710937503],
                            [51.977636718750006, 18.996142578125074],
                            [53.08564453125004, 16.648388671874955],
                        ],
                    ],
                ],
            },
            properties: {
                name: "也门",
                childNum: 4,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [37.85693359375003, -46.94423828124998],
                            [37.5900390625001, -46.90800781250006],
                            [37.78955078124997, -46.8375],
                            [37.85693359375003, -46.94423828124998],
                        ],
                    ],
                    [
                        [
                            [31.799609375000017, -23.8921875],
                            [31.98583984374997, -24.460644531249983],
                            [31.921679687500017, -25.96875],
                            [31.335156250000097, -25.755566406249997],
                            [31.207324218750074, -25.843359375000034],
                            [31.08808593750004, -25.980664062500026],
                            [30.803320312500006, -26.41347656250001],
                            [30.806738281250006, -26.78525390624999],
                            [30.88330078124997, -26.792382812500023],
                            [30.938085937500006, -26.91582031250003],
                            [31.06337890625005, -27.1123046875],
                            [31.274023437500063, -27.23837890625002],
                            [31.469531250000017, -27.29550781250002],
                            [31.74257812500005, -27.309960937500037],
                            [31.95839843750005, -27.305859375],
                            [31.946093750000017, -27.173632812499974],
                            [31.96718750000005, -26.96064453125001],
                            [31.994726562500006, -26.817480468749977],
                            [32.024804687499994, -26.81113281250002],
                            [32.112890625, -26.83945312500002],
                            [32.19960937499999, -26.833496093749957],
                            [32.35351562499997, -26.861621093750003],
                            [32.7765625000001, -26.850976562499966],
                            [32.88613281250005, -26.849316406249983],
                            [32.53476562500006, -28.19970703125003],
                            [32.285742187500006, -28.62148437499998],
                            [31.335156250000097, -29.378125],
                            [29.97119140625003, -31.322070312500017],
                            [28.449414062500068, -32.62460937499999],
                            [27.077441406250074, -33.52119140625004],
                            [26.429492187500045, -33.75957031250002],
                            [25.80585937500001, -33.737109374999974],
                            [25.574218750000057, -34.03535156249998],
                            [25.00292968750003, -33.97363281250003],
                            [24.8271484375, -34.16894531250003],
                            [24.595507812500074, -34.17451171875],
                            [23.697851562500063, -33.99277343750002],
                            [23.268164062500006, -34.08115234374999],
                            [22.553808593750063, -34.01005859374999],
                            [22.24550781250005, -34.06914062500003],
                            [21.788964843750023, -34.37265624999996],
                            [20.529882812500034, -34.4630859375],
                            [20.020605468750006, -34.785742187500006],
                            [19.298242187500023, -34.61503906249996],
                            [19.330761718750068, -34.49238281250001],
                            [19.098339843750068, -34.350097656249986],
                            [18.831347656250017, -34.36406249999999],
                            [18.75214843750004, -34.08261718750002],
                            [18.50039062499999, -34.10927734375004],
                            [18.46162109375001, -34.346875],
                            [18.35205078124997, -34.1884765625],
                            [18.43300781250005, -33.71728515625003],
                            [17.851074218750057, -32.82744140625002],
                            [17.96523437500005, -32.70859374999996],
                            [18.125, -32.74912109374996],
                            [18.325292968750034, -32.50498046874996],
                            [18.21083984375008, -31.74248046874996],
                            [17.34707031250005, -30.44482421875],
                            [16.95, -29.40341796875002],
                            [16.739453124999983, -29.009375],
                            [16.447558593750045, -28.61757812499998],
                            [16.755761718750023, -28.45214843750003],
                            [16.7875, -28.39472656249997],
                            [16.81015625, -28.264550781249994],
                            [16.841210937500023, -28.21894531250004],
                            [16.875292968750045, -28.12792968749997],
                            [16.93330078125004, -28.06962890624999],
                            [17.05625, -28.03105468750003],
                            [17.1884765625, -28.13251953125001],
                            [17.358691406250017, -28.269433593750023],
                            [17.44794921875001, -28.698144531249966],
                            [18.310839843750017, -28.88623046875],
                            [19.16171875, -28.93876953124996],
                            [19.245800781250068, -28.90166015625003],
                            [19.31269531250004, -28.733300781250023],
                            [19.539843750000017, -28.574609375000023],
                            [19.98046875, -28.45126953125002],
                            [19.98046875, -28.310351562500003],
                            [19.98046875, -24.77675781249998],
                            [20.430664062500057, -25.14707031250002],
                            [20.79316406250001, -25.915625],
                            [20.641406250000017, -26.7421875],
                            [20.739843749999977, -26.84882812499997],
                            [21.694726562500023, -26.840917968749963],
                            [21.738085937500045, -26.806835937500026],
                            [21.788281250000068, -26.710058593750034],
                            [22.01093750000004, -26.635839843750006],
                            [22.090917968749977, -26.580175781250034],
                            [22.217578125000045, -26.38886718749997],
                            [22.47089843750004, -26.219042968750003],
                            [22.548632812500074, -26.178417968749997],
                            [22.59765625000003, -26.13271484375001],
                            [22.878808593750023, -25.457910156250023],
                            [23.148730468750017, -25.288671875],
                            [23.389257812500006, -25.291406250000023],
                            [23.89375, -25.600878906250017],
                            [23.96953124999999, -25.62607421874999],
                            [24.192968750000034, -25.632910156249963],
                            [24.33056640625, -25.742871093749983],
                            [25.21337890625, -25.75625],
                            [25.518164062500006, -25.66279296875001],
                            [25.91210937499997, -24.747460937499966],
                            [26.031835937500034, -24.70244140625003],
                            [26.130859375000057, -24.671484375000034],
                            [26.39716796875004, -24.61357421874996],
                            [26.451757812500063, -24.582714843749983],
                            [26.835058593750063, -24.240820312499963],
                            [27.085546875000034, -23.577929687500003],
                            [27.7685546875, -23.14892578125],
                            [27.812597656250006, -23.108007812500006],
                            [28.210156249999983, -22.693652343749974],
                            [28.83984375000003, -22.480859374999966],
                            [28.94580078125003, -22.39511718749999],
                            [29.013476562500045, -22.27841796875002],
                            [29.129882812500057, -22.21328125],
                            [29.364843750000063, -22.19394531250005],
                            [29.37744140625003, -22.19277343749998],
                            [29.66308593749997, -22.146289062500017],
                            [29.90234375000003, -22.184179687500006],
                            [30.19042968750003, -22.291113281250034],
                            [30.460156250000097, -22.329003906250023],
                            [30.71162109375004, -22.297851562499986],
                            [31.07343750000004, -22.30781249999997],
                            [31.19726562499997, -22.344921874999983],
                            [31.287890625000074, -22.402050781249983],
                            [31.54560546875004, -23.48232421874998],
                            [31.799609375000017, -23.8921875],
                        ],
                        [
                            [27.19355468750001, -29.94130859375001],
                            [27.364062500000017, -30.27919921875001],
                            [27.753125, -30.6],
                            [28.05683593750001, -30.63105468750001],
                            [28.128710937500017, -30.52509765625001],
                            [28.39208984375003, -30.14755859375002],
                            [28.646875, -30.1265625],
                            [29.09804687500005, -29.919042968750006],
                            [29.142187500000063, -29.70097656249999],
                            [29.293554687500006, -29.56689453125003],
                            [29.348828125000097, -29.441992187499977],
                            [29.38671874999997, -29.319726562500023],
                            [29.301367187500006, -29.08984375],
                            [28.625781250000017, -28.581738281250054],
                            [28.583398437499994, -28.59414062499999],
                            [28.471875, -28.615820312499977],
                            [28.23261718750004, -28.701269531249977],
                            [28.084375, -28.779980468750026],
                            [27.95986328125008, -28.87333984375003],
                            [27.73554687500004, -28.940039062500034],
                            [27.294531250000063, -29.519335937500017],
                            [27.056933593750074, -29.62558593749999],
                            [27.19355468750001, -29.94130859375001],
                        ],
                    ],
                ],
            },
            properties: {
                name: "南非",
                childNum: 2,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [33.148046875, -9.603515625],
                        [33.25, -9.759570312500003],
                        [33.35097656250002, -9.862207031250009],
                        [33.33710937500001, -9.954003906250009],
                        [33.3115234375, -10.037988281250009],
                        [33.52890625, -10.234667968750003],
                        [33.53759765625, -10.3515625],
                        [33.5537109375, -10.391308593750011],
                        [33.66152343750002, -10.553125],
                        [33.29277343750002, -10.85234375],
                        [33.37978515625002, -11.157910156250011],
                        [33.26835937500002, -11.40390625],
                        [33.23271484375002, -11.417675781250011],
                        [33.22636718750002, -11.534863281250011],
                        [33.30390625000001, -11.690820312500009],
                        [33.25234375000002, -12.112597656250003],
                        [33.34013671875002, -12.308300781250011],
                        [33.512304687500006, -12.347753906250006],
                        [32.975195312500006, -12.701367187500011],
                        [32.96757812500002, -13.225],
                        [32.67041015625, -13.590429687500006],
                        [32.797460937500006, -13.6884765625],
                        [32.98125, -14.009375],
                        [33.148046875, -13.94091796875],
                        [33.201757812500006, -14.013378906250011],
                        [30.231835937500023, -14.990332031250006],
                        [30.39609375, -15.64306640625],
                        [29.4873046875, -15.69677734375],
                        [28.9130859375, -15.98779296875],
                        [28.760546875000017, -16.53212890625001],
                        [27.932226562500006, -16.89619140625001],
                        [27.020800781250017, -17.95839843750001],
                        [26.779882812500006, -18.04150390625],
                        [26.333398437500023, -17.929296875],
                        [25.995898437500017, -17.969824218750006],
                        [25.2587890625, -17.793554687500006],
                        [25.001757812500017, -17.56855468750001],
                        [24.73291015625, -17.51777343750001],
                        [24.27490234375, -17.481054687500006],
                        [23.380664062500017, -17.640625],
                        [22.193945312500006, -16.628125],
                        [21.979785156250017, -15.95556640625],
                        [21.979394531250023, -14.440527343750006],
                        [21.979296875000017, -14.11962890625],
                        [21.979101562500006, -13.798730468750009],
                        [21.978906250000023, -13.0009765625],
                        [22.209570312500006, -13.0009765625],
                        [23.843164062500023, -13.0009765625],
                        [23.962988281250006, -12.988476562500011],
                        [23.882421875, -12.799023437500011],
                        [23.886523437500017, -12.743261718750006],
                        [23.909375, -12.636132812500009],
                        [23.98388671875, -11.725],
                        [23.96650390625001, -10.871777343750011],
                        [24.36572265625, -11.1298828125],
                        [24.3779296875, -11.417089843750006],
                        [25.28876953125001, -11.21240234375],
                        [25.349414062500017, -11.623046875],
                        [26.025976562500006, -11.89013671875],
                        [26.824023437500017, -11.965234375],
                        [27.1591796875, -11.579199218750006],
                        [27.573828125, -12.22705078125],
                        [28.412890625000017, -12.51806640625],
                        [28.550878906250006, -12.836132812500011],
                        [28.730078125, -12.925488281250011],
                        [29.014257812500006, -13.368847656250011],
                        [29.20185546875001, -13.398339843750009],
                        [29.55419921875, -13.248925781250009],
                        [29.775195312500017, -13.438085937500006],
                        [29.79511718750001, -12.155468750000011],
                        [29.508203125000023, -12.228222656250011],
                        [29.48554687500001, -12.41845703125],
                        [29.064355468750023, -12.348828125000011],
                        [28.482519531250006, -11.812109375],
                        [28.383398437500006, -11.566699218750003],
                        [28.6455078125, -10.550195312500009],
                        [28.60419921875001, -9.678808593750006],
                        [28.400683593750017, -9.224804687500011],
                        [28.869531250000023, -8.785839843750011],
                        [28.89814453125001, -8.485449218750006],
                        [30.75117187500001, -8.193652343750003],
                        [30.830664062500006, -8.385546875],
                        [30.891992187500023, -8.473730468750006],
                        [30.968359375, -8.550976562500011],
                        [31.07636718750001, -8.611914062500006],
                        [31.3505859375, -8.60703125],
                        [31.44921875, -8.65390625],
                        [31.53486328125001, -8.71328125],
                        [31.55625, -8.80546875],
                        [31.673632812500017, -8.908789062500006],
                        [31.91865234375001, -8.9421875],
                        [31.921875, -9.019433593750009],
                        [31.94257812500001, -9.054003906250003],
                        [32.75664062500002, -9.322265625],
                        [32.919921875, -9.407421875000011],
                        [32.99599609375002, -9.622851562500003],
                        [33.148046875, -9.603515625],
                    ],
                ],
            },
            properties: {
                name: "赞比亚",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [31.287890625000017, -22.40205078125001],
                        [31.07343750000001, -22.30781250000001],
                        [30.71162109375001, -22.2978515625],
                        [30.46015625000001, -22.32900390625001],
                        [30.1904296875, -22.291113281250006],
                        [29.90234375, -22.184179687500006],
                        [29.6630859375, -22.146289062500003],
                        [29.37744140625, -22.19277343750001],
                        [29.36484375, -22.193945312500006],
                        [29.315234375000017, -22.15771484375],
                        [29.237207031250023, -22.07949218750001],
                        [29.042382812500023, -22.018359375],
                        [29.02558593750001, -21.796875],
                        [28.014062500000023, -21.55419921875],
                        [27.66943359375, -21.064257812500003],
                        [27.679296875, -20.503027343750006],
                        [27.28076171875, -20.47871093750001],
                        [27.17822265625, -20.10097656250001],
                        [26.168066406250006, -19.53828125000001],
                        [25.939355468750023, -18.93867187500001],
                        [25.242285156250006, -17.969042968750003],
                        [25.2587890625, -17.793554687500006],
                        [25.995898437500017, -17.969824218750006],
                        [26.333398437500023, -17.929296875],
                        [26.779882812500006, -18.04150390625],
                        [27.020800781250017, -17.95839843750001],
                        [27.932226562500006, -16.89619140625001],
                        [28.760546875000017, -16.53212890625001],
                        [28.9130859375, -15.98779296875],
                        [29.4873046875, -15.69677734375],
                        [30.39609375, -15.64306640625],
                        [30.437792968750017, -15.995312500000011],
                        [31.236230468750023, -16.02363281250001],
                        [31.939843750000023, -16.428808593750006],
                        [32.94804687500002, -16.71230468750001],
                        [32.87626953125002, -16.88359375],
                        [32.99306640625002, -18.35957031250001],
                        [32.69970703125, -18.94091796875],
                        [32.84980468750001, -19.10439453125001],
                        [32.77763671875002, -19.388769531250006],
                        [32.992773437500006, -19.98486328125],
                        [32.49238281250001, -20.659765625],
                        [32.353613281250006, -21.136523437500003],
                        [32.429785156250006, -21.29707031250001],
                        [31.429492187500017, -22.298828125],
                        [31.287890625000017, -22.40205078125001],
                    ],
                ],
            },
            properties: {
                name: "津巴布韦",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [74.00809389139292, 33.25375789331485],
                        [73.19660141888893, 33.898124784580936],
                        [73.13410859949555, 34.82510160558277],
                        [72.31128647748268, 35.77290936638241],
                        [73.08203125000107, 36.43949943991182],
                        [73.08961802927895, 36.86435907947333],
                        [73.116796875, 36.868554687499994],
                        [74.03886718750002, 36.825732421874996],
                        [74.54140625000002, 37.02216796875],
                        [74.69218750000002, 37.0357421875],
                        [74.8892578125, 36.952441406249996],
                        [74.94912109375002, 36.968359375],
                        [75.05390625000001, 36.987158203125],
                        [75.14521484375001, 36.9732421875],
                        [75.3466796875, 36.913476562499994],
                        [75.37685546875002, 36.883691406249994],
                        [75.42421875000002, 36.738232421875],
                        [75.46025390625002, 36.725048828125],
                        [75.57373046875, 36.759326171874996],
                        [75.66718750000001, 36.741992187499996],
                        [75.77216796875001, 36.694921875],
                        [75.84023437500002, 36.649707031249996],
                        [75.88496093750001, 36.600732421874994],
                        [75.93300781250002, 36.52158203125],
                        [75.95185546875001, 36.45810546875],
                        [75.97441406250002, 36.382421875],
                        [75.91230468750001, 36.048974609374994],
                        [76.07089843750003, 35.9830078125],
                        [76.14785156250002, 35.829003906249994],
                        [76.17783203125003, 35.810546875],
                        [76.25166015625001, 35.8109375],
                        [76.3857421875, 35.837158203125],
                        [76.50205078125003, 35.878222656249996],
                        [76.55126953125, 35.887060546875],
                        [76.5634765625, 35.772998046874996],
                        [76.6318359375, 35.729394531249994],
                        [76.7275390625, 35.678662109375],
                        [76.76689453124999, 35.66171875],
                        [76.81279296874999, 35.571826171874996],
                        [76.88222656250002, 35.4357421875],
                        [76.927734375, 35.346630859375],
                        [77.04863281249999, 35.109912109374996],
                        [77.00087890625002, 34.991992187499996],
                        [76.78291015625001, 34.900195312499996],
                        [76.75751953125001, 34.877832031249994],
                        [76.7490234375, 34.847558593749994],
                        [76.6962890625, 34.786914062499996],
                        [76.59443359375001, 34.73583984375],
                        [76.45673828125001, 34.756103515625],
                        [76.17246093750003, 34.667724609375],
                        [76.041015625, 34.669921875],
                        [75.93828125000002, 34.612548828125],
                        [75.86210937500002, 34.56025390625],
                        [75.70917968750001, 34.503076171874994],
                        [74.300390625, 34.765380859375],
                        [74.17197265625, 34.7208984375],
                        [74.05585937500001, 34.6806640625],
                        [73.96123046875002, 34.653466796874994],
                        [73.79453125, 34.378222656249996],
                        [73.80996093750002, 34.325341796874994],
                        [73.92460937500002, 34.287841796875],
                        [73.97236328125001, 34.236621093749996],
                        [73.9794921875, 34.191308593749994],
                        [73.90390625, 34.1080078125],
                        [73.94990234375001, 34.018798828125],
                        [74.24648437500002, 33.990185546875],
                        [73.97646484375002, 33.7212890625],
                        [74.15, 33.506982421874994],
                        [74.00809389139292, 33.25375789331485],
                    ],
                ],
            },
            properties: {
                name: "",
                childNum: 1,
            },
        },
        {
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [78.49194250885338, 32.53122786149202],
                            [78.10154031239509, 32.87658365066666],
                            [77.71342088235082, 32.6917648744551],
                            [77.06655516561037, 33.301666835953235],
                            [76.62299010270264, 33.32014871357439],
                            [76.32728006076415, 32.87658365066666],
                            [75.73585997688717, 32.78417426256088],
                            [75.62496871116024, 32.28516356678968],
                            [75.32221348233018, 32.28516356678968],
                            [74.98730468749997, 32.46220703124996],
                            [74.78886718750013, 32.4578125],
                            [74.6857421875001, 32.493798828124994],
                            [74.66328125000004, 32.75766601562495],
                            [74.63242187499995, 32.770898437500136],
                            [74.58828125000011, 32.7532226562501],
                            [74.35458984375012, 32.76870117187505],
                            [74.30546875000007, 32.81044921875002],
                            [74.30361328125005, 32.991796875000034],
                            [73.98984375000006, 33.22119140625006],
                            [74.15, 33.506982421874994],
                            [73.97646484375016, 33.72128906249998],
                            [74.24648437500011, 33.99018554687504],
                            [73.9499023437501, 34.018798828125],
                            [73.90390625000012, 34.10800781250006],
                            [73.97949218750009, 34.191308593749966],
                            [73.97236328125004, 34.23662109374996],
                            [73.92460937500007, 34.287841796875114],
                            [73.80996093750016, 34.32534179687511],
                            [73.79453125000006, 34.378222656250045],
                            [73.96123046875007, 34.653466796874994],
                            [74.05585937500015, 34.68066406250003],
                            [74.17197265624995, 34.72089843750004],
                            [74.30039062500006, 34.76538085937506],
                            [75.70917968750004, 34.50307617187508],
                            [75.86210937500002, 34.56025390625001],
                            [75.93828125000019, 34.612548828125],
                            [76.04101562500014, 34.66992187499997],
                            [76.17246093750006, 34.66772460937506],
                            [76.4567382812501, 34.756103515625114],
                            [76.5944335937501, 34.73583984375006],
                            [76.69628906249997, 34.78691406249999],
                            [76.74902343750014, 34.84755859375008],
                            [76.7575195312501, 34.87783203125005],
                            [76.7829101562501, 34.90019531249999],
                            [77.00087890625011, 34.99199218750002],
                            [77.03066406250011, 35.06235351562498],
                            [77.04863281250007, 35.109912109375074],
                            [77.42343749999995, 35.30258789062506],
                            [77.57158203125002, 35.37875976562495],
                            [77.69697265625015, 35.443261718750136],
                            [77.79941406250006, 35.49589843750002],
                            [78.0426757812501, 35.4797851562501],
                            [78.07578125000006, 35.13491210937502],
                            [78.15849609375002, 34.94648437499998],
                            [78.32695312500007, 34.60639648437498],
                            [78.86484375000006, 34.39033203125001],
                            [78.93642578125, 34.35195312500002],
                            [78.97060546875011, 34.22822265625004],
                            [78.72666015625006, 34.013378906249955],
                            [78.78378906250006, 33.80878906250004],
                            [78.86503906250002, 33.43110351562501],
                            [78.94843750000004, 33.346533203125006],
                            [79.1125, 33.22626953125001],
                            [79.13515625000005, 33.17192382812496],
                            [79.10283203125007, 33.05253906249996],
                            [79.14550781250003, 33.00146484375006],
                            [79.16992187500003, 32.497216796874994],
                            [78.91894531249997, 32.3582031250001],
                            [78.75351562500012, 32.49926757812506],
                            [78.73671875, 32.55839843750002],
                            [78.49194250885338, 32.53122786149202],
                        ],
                    ],
                ],
            },
            properties: {
                name: "",
                childNum: 1,
            },
        },
    ],
};
export default worldData;